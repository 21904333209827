import {
  ADD_FACTORY_ORDER_DETAILS,
  ADD_FACTORY_ORDER_DETAILS_OK,
  ADD_FACTORY_ORDER_DETAILS_ERROR,
  BEGIN_FACTORY_ORDER_DETAILS_DOWNLOAD,
  FACTORY_ORDER_DETAILS_DOWNLOAD_OK,
  FACTORY_ORDER_DETAILS_DOWNLOAD_ERROR,
  RETRIEVE_FACTORY_ORDER_DETAILS_DELETE,
  FACTORY_ORDER_DETAILS_DELETED_OK,
  FACTORY_ORDER_DETAILS_DELETED_ERROR,
  RETRIEVE_FACTORY_ORDER_DETAILS_EDIT,
  BEGIN_EDIT_FACTORY_ORDER_DETAILS,
  FACTORY_ORDER_DETAILS_EDITED_OK,
  FACTORY_ORDER_DETAILS_EDITED_ERROR,
  RETRIEVE_FACTORY_ORDER_DETAILS_BULK_DELETE,
  FACTORY_ORDER_DETAILS_BULK_DELETED_OK,
  FACTORY_ORDER_DETAILS_BULK_DELETED_ERROR,
  BEGIN_ORDER_DETAILS_BY_FO_DOWNLOAD,
  ORDER_DETAILS_BY_FO_DOWNLOAD_OK,
  ORDER_DETAILS_BY_FO_DOWNLOAD_ERROR,
} from "../../constants/modules/factoryOrderDetailsConstants";

const initialState = {
  factoryOrderDetails: [],
  detailsByfo: [],
  error: false,
  loading: false,
  deleteFactoryOrderDetail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_FACTORY_ORDER_DETAILS_DOWNLOAD:
    case BEGIN_ORDER_DETAILS_BY_FO_DOWNLOAD:
    case ADD_FACTORY_ORDER_DETAILS:
    case RETRIEVE_FACTORY_ORDER_DETAILS_BULK_DELETE:
    case BEGIN_EDIT_FACTORY_ORDER_DETAILS:
      return {
        ...state,
        loading: action.payload,
        factoryOrderDetail: action.factoryOrderDetail,
      };

    case ADD_FACTORY_ORDER_DETAILS_OK:
    case FACTORY_ORDER_DETAILS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_FACTORY_ORDER_DETAILS_ERROR:
    case FACTORY_ORDER_DETAILS_DOWNLOAD_ERROR:
    case ORDER_DETAILS_BY_FO_DOWNLOAD_ERROR:
    case FACTORY_ORDER_DETAILS_DELETED_ERROR:
    case FACTORY_ORDER_DETAILS_BULK_DELETED_ERROR:
    case FACTORY_ORDER_DETAILS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FACTORY_ORDER_DETAILS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        factoryOrderDetails: action.payload,
      };

    case ORDER_DETAILS_BY_FO_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        detailsByfo: action.payload,
      };

    case RETRIEVE_FACTORY_ORDER_DETAILS_DELETE:
      return {
        ...state,
        deleteBank: action.payload,
      };

    case FACTORY_ORDER_DETAILS_DELETED_OK:
      return {
        ...state,
        factoryOrderDetails: state.factoryOrderDetails.data.filter(
          (factoryOrderDetail) =>
            factoryOrderDetail.id !== state.deleteFactoryOrderDetail
        ),
        deleteFactoryOrderDetail: null,
      };

    case RETRIEVE_FACTORY_ORDER_DETAILS_EDIT:
      return {
        ...state,
        editFactoryOrderDetail: action.payload,
      };

    case FACTORY_ORDER_DETAILS_EDITED_OK:
      return {
        ...state,
        editFactoryOrderDetail: null,
        factoryOrderDetails: state.factoryOrderDetails.data.map(
          (factoryOrderDetail) =>
            factoryOrderDetail.id === action.payload.id
              ? (factoryOrderDetail = action.payload)
              : factoryOrderDetail
        ),
      };

    default:
      return state;
  }
}
