import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_CURRENCIES,
  BEGIN_CURRENCIES_DOWNLOAD,
  BEGIN_EDIT_CURRENCIES,
  RETRIEVE_CURRENCIES_BULK_DELETE,
  RETRIEVE_CURRENCIES_DELETE
} from "../../constants/modules/currenciesConstants";

import {
  addCurrencyErrorAction,
  addCurrencyOkAction,
  bulkDeleteCurrencyErrorAction,
  bulkDeleteCurrencyOkAction,
  deleteCurrencyErrorAction,
  deleteCurrencyOkAction,
  downloadCurrenciesErrorAction,
  downloadCurrenciesOkAction,
  editCurrencyErrorAction,
  editCurrencyOkAction
} from "../../actions/modules/currenciesActions";

import {
  addCurrencyDB,
  bulkDeleteCurrencyDB,
  deleteCurrencyDB,
  editCurrencyDB,
  retrieveCurrenciesData
} from "../../../config/apiCalls/currencies";

// Retrieve products
// worker saga
export function* retrieveCurrencies() {
  try {
    const response = yield call(retrieveCurrenciesData);

    if (response.status === 200) {
      yield put(downloadCurrenciesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadCurrenciesErrorAction());
  }
}

// Create new product
// worker saga
export function* addCurrency(action) {
  const currency = action.currency;

  try {
    const response = yield call(addCurrencyDB, currency);

    if (response.status === 201) {
      yield delay(500);

      yield put(addCurrencyOkAction(currency));
      toast.success("Currency created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addCurrencyErrorAction(true));
    toast.error("Currency created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteCurrency(action) {
  const id = action.payload;
  try {
    yield call(deleteCurrencyDB, id);
    yield deleteCurrencyOkAction();
    toast.success("Currency deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteCurrencyErrorAction();
  }
}

// Edit product
// worker saga
export function* editCurrency(action) {
  const currency = action.currency;

  try {
    yield call(editCurrencyDB, currency);
    yield editCurrencyOkAction(currency);
    // Alert
    // Alert
    toast.success("Currency has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editCurrencyErrorAction();
  }
}

// BULK SAGAS
export function* bulkCurrencyDelete(action) {
  const currency = action.currency;

  try {
    const response = yield call(bulkDeleteCurrencyDB, currency);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteCurrencyOkAction(currency));
      toast.success("Bulk Currency deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteCurrencyErrorAction(true));
    toast.error("Bulk Currency deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchCurrenciesAsync() {
  yield takeLatest(BEGIN_CURRENCIES_DOWNLOAD, retrieveCurrencies);
  yield takeLatest(ADD_CURRENCIES, addCurrency);
  yield takeLatest(RETRIEVE_CURRENCIES_DELETE, deleteCurrency);
  yield takeLatest(BEGIN_EDIT_CURRENCIES, editCurrency);
  yield takeLatest(RETRIEVE_CURRENCIES_BULK_DELETE, bulkCurrencyDelete);
}
