import {
  ADD_EX_FACTORY_DETAILS,
  ADD_EX_FACTORY_DETAILS_OK,
  ADD_EX_FACTORY_DETAILS_ERROR,
  BEGIN_EX_FACTORY_DETAILS_DOWNLOAD,
  EX_FACTORY_DETAILS_DOWNLOAD_OK,
  EX_FACTORY_DETAILS_DOWNLOAD_ERROR,
  RETRIEVE_EX_FACTORY_DETAILS_DELETE,
  EX_FACTORY_DETAILS_DELETED_OK,
  EX_FACTORY_DETAILS_DELETED_ERROR,
  RETRIEVE_EX_FACTORY_DETAILS_EDIT,
  BEGIN_EDIT_EX_FACTORY_DETAILS,
  EX_FACTORY_DETAILS_EDITED_OK,
  EX_FACTORY_DETAILS_EDITED_ERROR,
  RETRIEVE_EX_FACTORY_DETAILS_BULK_DELETE,
  EX_FACTORY_DETAILS_BULK_DELETED_OK,
  EX_FACTORY_DETAILS_BULK_DELETED_ERROR,
  RESET,
} from "../../constants/modules/exFactoryDetailsConstants";

export const downloadExFactoryDetailsAction = (item) => ({
  type: BEGIN_EX_FACTORY_DETAILS_DOWNLOAD,
  payload: true,
  exFactoryDetail: item,
});

export const downloadExFactoryDetailsOkAction = (items) => ({
  type: EX_FACTORY_DETAILS_DOWNLOAD_OK,
  payload: items,
});

export const downloadExFactoryDetailsErrorAction = () => ({
  type: EX_FACTORY_DETAILS_DOWNLOAD_ERROR,
  payload: true,
});

export const addExFactoryDetailAction = (item) => ({
  type: ADD_EX_FACTORY_DETAILS,
  payload: true,
  exFactoryDetail: item,
});

export const addExFactoryDetailOkAction = () => ({
  type: ADD_EX_FACTORY_DETAILS_OK,
  payload: true,
});

export const addExFactoryDetailErrorAction = (state) => ({
  type: ADD_EX_FACTORY_DETAILS_ERROR,
  payload: state,
});

export const deleteExFactoryDetailAction = (id) => ({
  type: RETRIEVE_EX_FACTORY_DETAILS_DELETE,
  payload: id,
});

export const deleteExFactoryDetailOkAction = () => ({
  type: EX_FACTORY_DETAILS_DELETED_OK,
});

export const deleteExFactoryDetailErrorAction = () => ({
  type: EX_FACTORY_DETAILS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteExFactoryDetailAction = (item) => ({
  type: RETRIEVE_EX_FACTORY_DETAILS_BULK_DELETE,
  payload: true,
  exFactoryDetail: item,
});

export const bulkDeleteExFactoryDetailOkAction = () => ({
  type: EX_FACTORY_DETAILS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteExFactoryDetailErrorAction = (state) => ({
  type: EX_FACTORY_DETAILS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveExFactoryDetailAction = (item) => ({
  type: RETRIEVE_EX_FACTORY_DETAILS_EDIT,
  payload: item,
});

export const editExFactoryDetailAction = (item) => ({
  type: BEGIN_EDIT_EX_FACTORY_DETAILS,
  exFactoryDetail: item,
});

export const editExFactoryDetailOkAction = (item) => ({
  type: EX_FACTORY_DETAILS_EDITED_OK,
  payload: item,
});

export const editExFactoryDetailErrorAction = () => ({
  type: EX_FACTORY_DETAILS_EDITED_ERROR,
  payload: true,
});

export const ExFactoryDetailReset = () => ({
  type: RESET,
  payload: true,
});
