import axiosClient from "../axiosClient";

export async function retrieveOrderDetailDB(id) {
  console.log("id*******", JSON.stringify(id));

  return await axiosClient.post("/order_details/get_by_id", id);
}

export async function addOrderDetailDB(value) {
  return await axiosClient.post("/order_details", value);
}

export async function deleteOrderDetailDB(id) {
  return await axiosClient.delete(`/order_details/${id}`);
}

export async function bulkDeleteOrderDetailDB(ids) {
  return await axiosClient.post(`/order_details/bulk_delete`, ids);
}

export async function editOrderDetailDB(value) {
  return await axiosClient.put(`/order_details/${value.id}`, value);
}
