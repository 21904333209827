export const ADD_FACTORY_INVOICES = "ADD_FACTORY_INVOICES";
export const ADD_FACTORY_INVOICES_OK = "ADD_FACTORY_INVOICES_OK";
export const ADD_FACTORY_INVOICES_ERROR = "ADD_FACTORY_INVOICES_ERROR";

export const BEGIN_FACTORY_INVOICES_DOWNLOAD = "BEGIN_FACTORY_INVOICES_DOWNLOAD";
export const FACTORY_INVOICES_DOWNLOAD_OK = "FACTORY_INVOICES_DOWNLOAD_OK";
export const FACTORY_INVOICES_DOWNLOAD_ERROR = "FACTORY_INVOICES_DOWNLOAD_ERROR";

export const BEGIN_FACTORY_INVOICE_DOWNLOAD = "BEGIN_FACTORY_INVOICE_DOWNLOAD";
export const FACTORY_INVOICE_DOWNLOAD_OK = "FACTORY_INVOICE_DOWNLOAD_OK";
export const FACTORY_INVOICE_DOWNLOAD_ERROR = "FACTORY_INVOICE_DOWNLOAD_ERROR";

export const RETRIEVE_FACTORY_INVOICES_DELETE = "RETRIEVE_FACTORY_INVOICES_DELETE";
export const FACTORY_INVOICES_DELETED_OK = "FACTORY_INVOICES_DELETED_OK";
export const FACTORY_INVOICES_DELETED_ERROR = "FACTORY_INVOICES_DELETED_ERROR";

export const RETRIEVE_FACTORY_INVOICES_EDIT = "RETRIEVE_FACTORY_INVOICES_EDITED";
export const BEGIN_EDIT_FACTORY_INVOICES = "BEGIN_EDIT_FACTORY_INVOICES";
export const FACTORY_INVOICES_EDITED_OK = "FACTORY_INVOICES_EDITED_OK";
export const FACTORY_INVOICES_EDITED_ERROR = "FACTORY_INVOICES_EDITED_ERROR";

export const RETRIEVE_FACTORY_INVOICES_BULK_DELETE =
  "RETRIEVE_FACTORY_INVOICES_BULK_DELETE";
export const FACTORY_INVOICES_BULK_DELETED_OK = "FACTORY_INVOICES_BULK_DELETED_OK";
export const FACTORY_INVOICES_BULK_DELETED_ERROR =
  "FACTORY_INVOICES_BULK_DELETED_ERROR";

export const RESET = "RESET";
