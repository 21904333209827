export const ADD_SAMPLE_STYLES = "ADD_SAMPLE_STYLES";
export const ADD_SAMPLE_STYLES_OK = "ADD_SAMPLE_STYLES_OK";
export const ADD_SAMPLE_STYLES_ERROR = "ADD_SAMPLE_STYLES_ERROR";

export const BEGIN_SAMPLE_STYLES_DOWNLOAD = "BEGIN_SAMPLE_STYLES_DOWNLOAD";
export const SAMPLE_STYLES_DOWNLOAD_OK = "SAMPLE_STYLES_DOWNLOAD_OK";
export const SAMPLE_STYLES_DOWNLOAD_ERROR = "SAMPLE_STYLES_DOWNLOAD_ERROR";

export const RETRIEVE_SAMPLE_STYLES_DELETE = "RETRIEVE_SAMPLE_STYLES_DELETE";
export const SAMPLE_STYLES_DELETED_OK = "SAMPLE_STYLES_DELETED_OK";
export const SAMPLE_STYLES_DELETED_ERROR = "SAMPLE_STYLES_DELETED_ERROR";

export const RETRIEVE_SAMPLE_STYLES_EDIT = "RETRIEVE_SAMPLE_STYLES_EDITED";
export const BEGIN_EDIT_SAMPLE_STYLES = "BEGIN_EDIT_SAMPLE_STYLES";
export const SAMPLE_STYLES_EDITED_OK = "SAMPLE_STYLES_EDITED_OK";
export const SAMPLE_STYLES_EDITED_ERROR = "SAMPLE_STYLES_EDITED_ERROR";

export const RETRIEVE_SAMPLE_STYLES_BULK_DELETE = "RETRIEVE_SAMPLE_STYLES_BULK_DELETE";
export const SAMPLE_STYLES_BULK_DELETED_OK = "SAMPLE_STYLES_BULK_DELETED_OK";
export const SAMPLE_STYLES_BULK_DELETED_ERROR = "SAMPLE_STYLES_BULK_DELETED_ERROR";