import {
  ADD_BUYER_TERMS,
  ADD_BUYER_TERMS_ERROR,
  ADD_BUYER_TERMS_OK,
  BEGIN_BUYER_TERMS_DOWNLOAD,
  BEGIN_EDIT_BUYER_TERMS,
  BUYER_TERMS_BULK_DELETED_ERROR,
  BUYER_TERMS_BULK_DELETED_OK,
  BUYER_TERMS_DELETED_ERROR,
  BUYER_TERMS_DELETED_OK,
  BUYER_TERMS_DOWNLOAD_ERROR,
  BUYER_TERMS_DOWNLOAD_OK,
  BUYER_TERMS_EDITED_ERROR,
  BUYER_TERMS_EDITED_OK,
  RETRIEVE_BUYER_TERMS_BULK_DELETE,
  RETRIEVE_BUYER_TERMS_DELETE,
  RETRIEVE_BUYER_TERMS_EDIT,
} from "../../constants/modules/buyerTermsConstants.js";

const initialState = {
  buyerTerms: [],
  error: false,
  loading: false,
  deleteBuyerTerm: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_BUYER_TERMS_DOWNLOAD:
    case ADD_BUYER_TERMS:
    case RETRIEVE_BUYER_TERMS_BULK_DELETE:
    case BEGIN_EDIT_BUYER_TERMS:
      return {
        ...state,
        loading: action.payload,
        buyerTerm: action.buyerTerm,
      };

    case ADD_BUYER_TERMS_OK:
    case BUYER_TERMS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_BUYER_TERMS_ERROR:
    case BUYER_TERMS_DOWNLOAD_ERROR:
    case BUYER_TERMS_BULK_DELETED_ERROR:
    case BUYER_TERMS_DELETED_ERROR:
    case BUYER_TERMS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BUYER_TERMS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        buyerTerm: action.payload,
      };

    case RETRIEVE_BUYER_TERMS_DELETE:
      return {
        ...state,
        deleteBuyerTerm: action.payload,
      };

    case BUYER_TERMS_DELETED_OK:
      return {
        ...state,
        buyerTerms: state.buyerTerms.data.filter(
          (buyerTerm) => buyerTerm.id !== state.deleteBuyerTerm
        ),
        deleteBuyerTerm: null,
      };

    case RETRIEVE_BUYER_TERMS_EDIT:
      return {
        ...state,
        editBuyerTerm: action.payload,
      };

    case BUYER_TERMS_EDITED_OK:
      return {
        ...state,
        editBuyerTerm: null,
        buyerTerms: state.buyerTerms.data.map((buyerTerm) =>
          buyerTerm.id === action.payload.id
            ? (buyerTerm = action.payload)
            : buyerTerm
        ),
      };

    default:
      return state;
  }
}
