export const ADD_FABRICS = "ADD_FABRICS";
export const ADD_FABRICS_OK = "ADD_FABRICS_OK";
export const ADD_FABRICS_ERROR = "ADD_FABRICS_ERROR";

export const BEGIN_FABRICS_DOWNLOAD = "BEGIN_FABRICS_DOWNLOAD";
export const FABRICS_DOWNLOAD_OK = "FABRICS_DOWNLOAD_OK";
export const FABRICS_DOWNLOAD_ERROR = "FABRICS_DOWNLOAD_ERROR";

export const BEGIN_FABRICS_BY_FK_DOWNLOAD = "BEGIN_FABRICS_BY_FK_DOWNLOAD";
export const FABRICS_BY_FK_DOWNLOAD_OK = "FABRICS_BY_FK_DOWNLOAD_OK";
export const FABRICS_BY_FK_DOWNLOAD_ERROR = "FABRICS_BY_FK_DOWNLOAD_ERROR";

export const RETRIEVE_FABRICS_DELETE = "RETRIEVE_FABRICS_DELETE";
export const FABRICS_DELETED_OK = "FABRICS_DELETED_OK";
export const FABRICS_DELETED_ERROR = "FABRICS_DELETED_ERROR";

export const RETRIEVE_FABRICS_EDIT = "RETRIEVE_FABRICS_EDITED";
export const BEGIN_EDIT_FABRICS = "BEGIN_EDIT_FABRICS";
export const FABRICS_EDITED_OK = "FABRICS_EDITED_OK";
export const FABRICS_EDITED_ERROR = "FABRICS_EDITED_ERROR";

export const RETRIEVE_FABRICS_BULK_DELETE = "RETRIEVE_FABRICS_BULK_DELETE";
export const FABRICS_BULK_DELETED_OK = "FABRICS_BULK_DELETED_OK";
export const FABRICS_BULK_DELETED_ERROR = "FABRICS_BULK_DELETED_ERROR";
