import MuiListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";

const ModuleItemComponets = ({ ...props }) => {
  const { className, onClick, menu_link, parent_menu, children, id } = props;


  const ListItem = withStyles({
    root: {
      "&:hover": {
        backgroundColor: "#273746",
        color: "white",
        borderRadius: 2,
      },
      "&$selected:hover": {
        backgroundColor: "purple",
        color: "white",
      },
    },
    selected: {},
  })(MuiListItem);

  if (menu_link === "/") {
    return (
      <ListItem
        button
        name="event"
        className={className}
        children={children}
        onClick={onClick}
      />
    );
  }

  return (
    <ListItem
      button
      className={className}
      children={children}
      component={forwardRef((props) => {
        console.log("props7777", props);
        return (
          <NavLink
            {...props}
            isActive={(match, location) =>
              location.pathname.startsWith(props.to.pathname)
            }
          />
        );
      })}
      exact
      to={{
        pathname: `/app${menu_link}`,
        state: id,
      }}
      onClick={() => localStorage.setItem("menu_id", id)}
    />
  );
};

export default ModuleItemComponets;
