import {
  ADD_BANKS,
  ADD_BANKS_OK,
  ADD_BANKS_ERROR,
  BEGIN_BANKS_DOWNLOAD,
  BANKS_DOWNLOAD_OK,
  BANKS_DOWNLOAD_ERROR,
  RETRIEVE_BANKS_DELETE,
  BANKS_DELETED_OK,
  BANKS_DELETED_ERROR,
  RETRIEVE_BANKS_EDIT,
  BEGIN_EDIT_BANKS,
  BANKS_EDITED_OK,
  BANKS_EDITED_ERROR,
  RETRIEVE_BANKS_BULK_DELETE,
  BANKS_BULK_DELETED_OK,
  BANKS_BULK_DELETED_ERROR,
} from "../../constants/modules/banksConstants";

const initialState = {
  banks: [],
  error: false,
  loading: false,
  deleteBank: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_BANKS_DOWNLOAD:
    case ADD_BANKS:
    case RETRIEVE_BANKS_BULK_DELETE:
    case BEGIN_EDIT_BANKS:
      return {
        ...state,
        loading: action.payload,
        bank: action.bank,
      };

    case ADD_BANKS_OK:
    case BANKS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_BANKS_ERROR:
    case BANKS_DOWNLOAD_ERROR:
    case BANKS_DELETED_ERROR:
    case BANKS_BULK_DELETED_ERROR:
    case BANKS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BANKS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        banks: action.payload,
      };

    case RETRIEVE_BANKS_DELETE:
      return {
        ...state,
        deleteBank: action.payload,
      };

    case BANKS_DELETED_OK:
      return {
        ...state,
        banks: state.banks.data.filter((bank) => bank.id !== state.deleteBank),
        deleteUser: null,
      };

    case RETRIEVE_BANKS_EDIT:
      return {
        ...state,
        editBank: action.payload,
      };

    case BANKS_EDITED_OK:
      return {
        ...state,
        editBank: null,
        banks: state.banks.data.map((bank) =>
          bank.id === action.payload.id ? (bank = action.payload) : bank
        ),
      };

    default:
      return state;
  }
}
