import axiosClient from "../axiosClient";

export async function retrieveSizeSetDB() {
  return await axiosClient.get("/size_sets");
}

export async function sizeSetDetailDB(item) {
  console.log("item7777", item);
  return await axiosClient.post("/size_sets/get_detail", item);
}

export async function sizeSetByFkDB(id) {
  let reId = {
    id: id,
  };
  return await axiosClient.post("/size_sets/get_by_fk", reId);
}

export async function addSizeSetDB(value) {
  return await axiosClient.post("/size_sets", value);
}

export async function deleteSizeSetDB(id) {
  return await axiosClient.delete(`/size_sets/${id}`);
}

export async function bulkDeleteSizeSetDB(ids) {
  return await axiosClient.post(`/size_sets/bulk_delete`, ids);
}

export async function editSizeSetDB(value) {
  return await axiosClient.put(`/size_sets/${value.id}`, value);
}
