import {
    ADD_DISCOUNT_DETAILS,
    ADD_DISCOUNT_DETAILS_ERROR,
    ADD_DISCOUNT_DETAILS_OK,
    BEGIN_EDIT_DISCOUNT_DETAILS,
    RETRIEVE_DISCOUNT_DETAILS_DELETE,
    RETRIEVE_DISCOUNT_DETAILS_EDIT,
    DISCOUNT_DETAILS_DELETED_ERROR,
    DISCOUNT_DETAILS_DELETED_OK,
    DISCOUNT_DETAILS_DOWNLOAD_ERROR,
    DISCOUNT_DETAILS_DOWNLOAD_OK,
    DISCOUNT_DETAILS_EDITED_ERROR,
    DISCOUNT_DETAILS_EDITED_OK,
    BEGIN_DISCOUNT_DETAILS_DOWNLOAD
  } from "../../constants/modules/discountDetailsConstants";
  

  
  export const downloadDiscountDetailsAction = () => ({
    type: BEGIN_DISCOUNT_DETAILS_DOWNLOAD,
    payload: true,
  });
  
  export const downloadDiscountDetailsOkAction = (items) => ({
    type: DISCOUNT_DETAILS_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadDiscountDetailsErrorAction = () => ({
    type: DISCOUNT_DETAILS_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const addDiscountDetailAction = (item) => ({
    type: ADD_DISCOUNT_DETAILS,
    payload: true,
    discountDetail: item,
  });
  
  export const addDiscountDetailOkAction = () => ({
    type: ADD_DISCOUNT_DETAILS_OK,
    payload: true,
  });
  
  export const addDiscountDetailErrorAction = (state) => ({
    type: ADD_DISCOUNT_DETAILS_ERROR,
    payload: state,
  });
  
  export const deleteDiscountDetailAction = (id) => ({
    type: RETRIEVE_DISCOUNT_DETAILS_DELETE,
    payload: id,
  });
  
  export const deleteDiscountDetailOkAction = () => ({
    type: DISCOUNT_DETAILS_DELETED_OK,
  });
  
  export const deleteDiscountDetailErrorAction = () => ({
    type: DISCOUNT_DETAILS_DELETED_ERROR,
    payload: true,
  });
  
  export const retrieveDiscountDetailAction = (item) => ({
    type: RETRIEVE_DISCOUNT_DETAILS_EDIT,
    payload: item,
  });
  
  export const editDiscountDetailAction = (item) => ({
    type: BEGIN_EDIT_DISCOUNT_DETAILS,
    discountDetail: item,
  });
  
  export const editDiscountDetailOkAction = (item) => ({
    type: DISCOUNT_DETAILS_EDITED_OK,
    payload: item,
  });
  
  export const editDiscountDetailErrorAction = () => ({
    type: DISCOUNT_DETAILS_EDITED_ERROR,
    payload: true,
  });
  