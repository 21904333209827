export const ADD_SHIPMENT_DETAILS = "ADD_SHIPMENT_DETAILS";
export const ADD_SHIPMENT_DETAILS_OK = "ADD_SHIPMENT_DETAILS_OK";
export const ADD_SHIPMENT_DETAILS_ERROR = "ADD_SHIPMENT_DETAILS_ERROR";

export const BEGIN_SHIPMENT_DETAILS_DOWNLOAD =
  "BEGIN_SHIPMENT_DETAILS_DOWNLOAD";
export const SHIPMENT_DETAILS_DOWNLOAD_OK = "SHIPMENT_DETAILS_DOWNLOAD_OK";
export const SHIPMENT_DETAILS_DOWNLOAD_ERROR =
  "SHIPMENT_DETAILS_DOWNLOAD_ERROR";

export const RETRIEVE_SHIPMENT_DETAILS_EDIT =
  "RETRIEVE_SHIPMENT_DETAILS_EDITED";
export const BEGIN_EDIT_SHIPMENT_DETAILS = "BEGIN_EDIT_SHIPMENT_DETAILS";
export const SHIPMENT_DETAILS_EDITED_OK = "SHIPMENT_DETAILS_EDITED_OK";
export const SHIPMENT_DETAILS_EDITED_ERROR = "SHIPMENT_DETAILS_EDITED_ERROR";

export const RETRIEVE_SHIPMENT_DETAILS_DELETE =
  "RETRIEVE_SHIPMENT_DETAILS_DELETE";
export const SHIPMENT_DETAILS_DELETED_OK = "SHIPMENT_DETAILS_DELETED_OK";
export const SHIPMENT_DETAILS_DELETED_ERROR = "SHIPMENT_DETAILS_DELETED_ERROR";

export const RETRIEVE_SHIPMENT_DETAILS_BULK_DELETE =
  "RETRIEVE_SHIPMENT_DETAILS_BULK_DELETE";
export const SHIPMENT_DETAILS_BULK_DELETED_OK =
  "SHIPMENT_DETAILS_BULK_DELETED_OK";
export const SHIPMENT_DETAILS_BULK_DELETED_ERROR =
  "SHIPMENT_DETAILS_BULK_DELETED_ERROR";
