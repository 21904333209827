import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_FACTORY_ORDER_DETAILS,
  BEGIN_FACTORY_ORDER_DETAILS_DOWNLOAD,
  RETRIEVE_FACTORY_ORDER_DETAILS_DELETE,
  BEGIN_EDIT_FACTORY_ORDER_DETAILS,
  RETRIEVE_FACTORY_ORDER_DETAILS_BULK_DELETE,
  BEGIN_ORDER_DETAILS_BY_FO_DOWNLOAD,
} from "../../constants/modules/factoryOrderDetailsConstants";

import {
  downloadFactoryOrderDetailsOkAction,
  downloadFactoryOrderDetailsErrorAction,
  addFactoryOrderDetailOkAction,
  addFactoryOrderDetailErrorAction,
  deleteFactoryOrderDetailOkAction,
  deleteFactoryOrderDetailErrorAction,
  editFactoryOrderDetailOkAction,
  editFactoryOrderDetailErrorAction,
  bulkDeleteFactoryOrderDetailOkAction,
  bulkDeleteFactoryOrderDetailErrorAction,
  downloadFactoryOrderDetailsByFoOkAction,
  downloadFactoryOrderDetailsByFoErrorAction,
} from "../../actions/modules/factoryOrderDetailsActions";

import {
  retrieveFactoryOrderDetailData,
  addFactoryOrderDetailDB,
  deleteFactoryOrderDetailDB,
  editFactoryOrderDetailDB,
  bulkDeleteFactoryOrderDetailDB,
  retrieveFactoryOrderDetailByFoData,
} from "../../../config/apiCalls/factoryOrderDetails";

// Retrieve products
// worker saga
export function* retrieveFactoryOrderDetails() {
  try {
    const response = yield call(retrieveFactoryOrderDetailData);
    if (response.status === 200) {
      yield put(downloadFactoryOrderDetailsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFactoryOrderDetailsErrorAction());
  }
}

export function* retrieveFactoryOrderDetailsByFo(action) {
  const factoryOrderDetail = action.factoryOrderDetail;
  try {
    const response = yield call(
      retrieveFactoryOrderDetailByFoData,
      factoryOrderDetail
    );
    if (response.status === 200) {
      yield put(downloadFactoryOrderDetailsByFoOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFactoryOrderDetailsByFoErrorAction());
  }
}

// Create new product
// worker saga
export function* addFactoryOrderDetail(action) {
  const factoryOrderDetail = action.factoryOrderDetail;

  try {
    const response = yield call(addFactoryOrderDetailDB, factoryOrderDetail);

    if (response.status === 200) {
      yield delay(500);
      yield put(addFactoryOrderDetailOkAction(response.data));
      toast.success("Factory Order Detail Generated!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addFactoryOrderDetailErrorAction(true));
    toast.error("Factory OrderDetail Genarate failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteFactoryOrderDetail(action) {
  const id = action.payload;
  try {
    yield call(deleteFactoryOrderDetailDB, id);
    yield deleteFactoryOrderDetailOkAction();
    toast.success("Factory OrderDetail deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteFactoryOrderDetailErrorAction());
    toast.error("Factory OrderDetail deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkFactoryOrderDetailDelete(action) {
  const factoryOrderDetail = action.factoryOrderDetail;

  try {
    const response = yield call(
      bulkDeleteFactoryOrderDetailDB,
      factoryOrderDetail
    );
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteFactoryOrderDetailOkAction(factoryOrderDetail));
      toast.success("Factory OrderDetails deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteFactoryOrderDetailErrorAction(true));
    toast.error("Factory OrderDetails deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editFactoryOrderDetail(action) {
  const factoryOrderDetail = action.factoryOrderDetail;

  try {
    const response = yield call(editFactoryOrderDetailDB, factoryOrderDetail);

    if (response.status === 201) {
      yield delay(500);
      yield put(editFactoryOrderDetailOkAction(factoryOrderDetail));
      toast.success("Factory OrderDetail edited successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editFactoryOrderDetailErrorAction());
    toast.error("Factory OrderDetail deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchFactoryOrderDetailsAsync() {
  yield takeLatest(
    BEGIN_FACTORY_ORDER_DETAILS_DOWNLOAD,
    retrieveFactoryOrderDetails
  );
  yield takeLatest(
    BEGIN_ORDER_DETAILS_BY_FO_DOWNLOAD,
    retrieveFactoryOrderDetailsByFo
  );
  yield takeLatest(ADD_FACTORY_ORDER_DETAILS, addFactoryOrderDetail);
  yield takeLatest(
    RETRIEVE_FACTORY_ORDER_DETAILS_DELETE,
    deleteFactoryOrderDetail
  );
  yield takeLatest(BEGIN_EDIT_FACTORY_ORDER_DETAILS, editFactoryOrderDetail);
  yield takeLatest(
    RETRIEVE_FACTORY_ORDER_DETAILS_BULK_DELETE,
    bulkFactoryOrderDetailDelete
  );
}
