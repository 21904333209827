export const ADD_FOB_TYPES = "ADD_FOB_TYPES";
export const ADD_FOB_TYPES_OK = "ADD_FOB_TYPES_OK";
export const ADD_FOB_TYPES_ERROR = "ADD_FOB_TYPES_ERROR";

export const BEGIN_FOB_TYPES_DOWNLOAD = "BEGIN_FOB_TYPES_DOWNLOAD";
export const FOB_TYPES_DOWNLOAD_OK = "FOB_TYPES_DOWNLOAD_OK";
export const FOB_TYPES_DOWNLOAD_ERROR = "FOB_TYPES_DOWNLOAD_ERROR";

export const RETRIEVE_FOB_TYPES_DELETE = "RETRIEVE_FOB_TYPES_DELETE";
export const FOB_TYPES_DELETED_OK = "FOB_TYPES_DELETED_OK";
export const FOB_TYPES_DELETED_ERROR = "FOB_TYPES_DELETED_ERROR";

export const RETRIEVE_FOB_TYPES_EDIT = "RETRIEVE_FOB_TYPES_EDITED";
export const BEGIN_EDIT_FOB_TYPES = "BEGIN_EDIT_FOB_TYPES";
export const FOB_TYPES_EDITED_OK = "FOB_TYPES_EDITED_OK";
export const FOB_TYPES_EDITED_ERROR = "FOB_TYPES_EDITED_ERROR";

export const RETRIEVE_FOB_TYPES_BULK_DELETE = "RETRIEVE_FOB_TYPES_BULK_DELETE";
export const FOB_TYPES_BULK_DELETED_OK = "FOB_TYPES_BULK_DELETED_OK";
export const FOB_TYPES_BULK_DELETED_ERROR = "FOB_TYPES_BULK_DELETED_ERROR";