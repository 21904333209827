import axiosClient from "../axiosClient";

export async function bulkDeleteFabricTypeDB(ids) {
  return await axiosClient.post(`/fabric_types/bulk_delete`, ids);
}

export async function retrieveFabricTypesData() {
  return await axiosClient.get("/fabric_types");
}

export async function retrieveFabricTypesByFkDB(value) {
  console.log("retrieveFabricTypesByFkDB", value);
  return await axiosClient.post("/fabric_types/get_by_fk", value);
}

export async function addFabricTypeDB(value) {
  return await axiosClient.post("/fabric_types", value);
}

export async function deleteFabricTypeDB(id) {
  return await axiosClient.delete(`/fabric_types/${id}`);
}

export async function editFabricTypeDB(value) {
  return await axiosClient.put(`fabric_types/${value.id}`, value);
}
