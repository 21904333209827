import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    ADD_FACTORY_INVOICES,
    BEGIN_EDIT_FACTORY_INVOICES,
    BEGIN_FACTORY_INVOICES_DOWNLOAD,
    BEGIN_FACTORY_INVOICE_DOWNLOAD, RETRIEVE_FACTORY_INVOICES_DELETE
} from "../../constants/modules/factoryInvoiceConstant";

import {
    addFactoryInvoiceErrorAction,
    addFactoryInvoiceOkAction, deleteFactoryInvoiceErrorAction,
    deleteFactoryInvoiceOkAction,
    downloadFactoryInvoiceErrorAction,
    downloadFactoryInvoiceOkAction,
    downloadFactoryInvoicesErrorAction,
    downloadFactoryInvoicesOkAction,
    editFactoryInvoiceErrorAction,
    editFactoryInvoiceOkAction
} from "../../actions/modules/factoryInvoiceActions";

import {
    addFactoryInvoiceDB, deleteFactoryInvoiceDB,
    editFactoryInvoiceDB,
    retrieveFactoryInvoiceData,
    retrieveFactoryInvoicesData
} from "../../../config/apiCalls/factoryInvoiceAPICall";

// Retrieve products
// worker saga
export function* retrieveFactoryInvoices() {
  try {
    const response = yield call(retrieveFactoryInvoicesData);

    if (response.status === 200) {
      yield put(downloadFactoryInvoicesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFactoryInvoicesErrorAction());
  }
}

export function* retrieveFactoryInvoice(action) {
  const factoryInvoice = action.factoryInvoice;
  try {
    const response = yield call(retrieveFactoryInvoiceData, factoryInvoice);
    if (response.status === 200) {
      yield put(downloadFactoryInvoiceOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFactoryInvoiceErrorAction());
  }
}

// Create new product
// worker saga
export function* addFactoryInvoice(action) {
  const factoryInvoice = action.factoryInvoice;
  try {
    const response = yield call(addFactoryInvoiceDB, factoryInvoice);
    if (response.status === 200) {
      yield delay(500);
      yield put(addFactoryInvoiceOkAction(response.data));
      toast.success("Factory Invoice created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addFactoryInvoiceErrorAction(true));
    toast.error("Factory Invoice created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteFactoryInvoice(action) {
  const id = action.payload;
  try {
    yield call(deleteFactoryInvoiceDB, id);
    yield deleteFactoryInvoiceOkAction();
    toast.success("Factory Invoice deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteFactoryInvoiceErrorAction();
  }
}

// Edit product
// worker saga
export function* editFactoryInvoice(action) {
  const factoryInvoice = action.factoryInvoice;

  try {
    yield call(editFactoryInvoiceDB, factoryInvoice);
    yield editFactoryInvoiceOkAction(factoryInvoice);
    // Alert
    // Alert
    toast.success("Factory Invoice has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editFactoryInvoiceErrorAction();
  }
}


export function* watchFactoryInvoicesAsync() {
  yield takeLatest(BEGIN_FACTORY_INVOICES_DOWNLOAD, retrieveFactoryInvoices);
  yield takeLatest(BEGIN_FACTORY_INVOICE_DOWNLOAD, retrieveFactoryInvoice);
  yield takeLatest(ADD_FACTORY_INVOICES, addFactoryInvoice);
  yield takeLatest(RETRIEVE_FACTORY_INVOICES_DELETE, deleteFactoryInvoice);
  yield takeLatest(BEGIN_EDIT_FACTORY_INVOICES, editFactoryInvoice);
  
}
