export const ADD_COLORS = "ADD_COLORS";
export const ADD_COLORS_OK = "ADD_COLORS_OK";
export const ADD_COLORS_ERROR = "ADD_COLORS_ERROR";

export const BEGIN_COLORS_DOWNLOAD = "BEGIN_COLORS_DOWNLOAD";
export const COLORS_DOWNLOAD_OK = "COLORS_DOWNLOAD_OK";
export const COLORS_DOWNLOAD_ERROR = "COLORS_DOWNLOAD_ERROR";

export const RETRIEVE_COLORS_DELETE = "RETRIEVE_COLORS_DELETE";
export const COLORS_DELETED_OK = "COLORS_DELETED_OK";
export const COLORS_DELETED_ERROR = "COLORS_DELETED_ERROR";

export const RETRIEVE_COLORS_EDIT = "RETRIEVE_COLORS_EDITED";
export const BEGIN_EDIT_COLORS = "BEGIN_EDIT_COLORS";
export const COLORS_EDITED_OK = "COLORS_EDITED_OK";
export const COLORS_EDITED_ERROR = "COLORS_EDITED_ERROR";

export const RETRIEVE_COLORS_BULK_DELETE = "RETRIEVE_COLORS_BULK_DELETE";
export const COLORS_BULK_DELETED_OK = "COLORS_BULK_DELETED_OK";
export const COLORS_BULK_DELETED_ERROR = "COLORS_BULK_DELETED_ERROR";