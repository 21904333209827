import {
  ADD_EX_FACTORY_DETAILS,
  ADD_EX_FACTORY_DETAILS_OK,
  ADD_EX_FACTORY_DETAILS_ERROR,
  BEGIN_EX_FACTORY_DETAILS_DOWNLOAD,
  EX_FACTORY_DETAILS_DOWNLOAD_OK,
  EX_FACTORY_DETAILS_DOWNLOAD_ERROR,
  RETRIEVE_EX_FACTORY_DETAILS_DELETE,
  EX_FACTORY_DETAILS_DELETED_OK,
  EX_FACTORY_DETAILS_DELETED_ERROR,
  RETRIEVE_EX_FACTORY_DETAILS_EDIT,
  BEGIN_EDIT_EX_FACTORY_DETAILS,
  EX_FACTORY_DETAILS_EDITED_OK,
  EX_FACTORY_DETAILS_EDITED_ERROR,
  RETRIEVE_EX_FACTORY_DETAILS_BULK_DELETE,
  EX_FACTORY_DETAILS_BULK_DELETED_OK,
  EX_FACTORY_DETAILS_BULK_DELETED_ERROR,
  RESET,
} from "../../constants/modules/exFactoryDetailsConstants";

const initialState = {
  exFactoryDetails: [],
  error: false,
  loading: false,
  deleteExFactoryDetail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_EX_FACTORY_DETAILS_DOWNLOAD:
    case ADD_EX_FACTORY_DETAILS:
    case RETRIEVE_EX_FACTORY_DETAILS_BULK_DELETE:
    case BEGIN_EDIT_EX_FACTORY_DETAILS:
      return {
        ...state,
        loading: action.payload,
        exFactoryDetail: action.exFactoryDetail,
      };

    case ADD_EX_FACTORY_DETAILS_OK:
    case EX_FACTORY_DETAILS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_EX_FACTORY_DETAILS_ERROR:
    case EX_FACTORY_DETAILS_DOWNLOAD_ERROR:
    case EX_FACTORY_DETAILS_DELETED_ERROR:
    case EX_FACTORY_DETAILS_BULK_DELETED_ERROR:
    case EX_FACTORY_DETAILS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EX_FACTORY_DETAILS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        exFactoryDetails: action.payload,
      };

    case RETRIEVE_EX_FACTORY_DETAILS_DELETE:
      return {
        ...state,
        deleteExFactoryDetail: action.payload,
      };

    case EX_FACTORY_DETAILS_DELETED_OK:
      return {
        ...state,
        exFactoryDetails: state.exFactoryDetails.data.filter(
          (exFactoryDetail) =>
            exFactoryDetail.id !== state.deleteExFactoryDetail
        ),
        deleteExFactoryDetail: null,
      };

    case RETRIEVE_EX_FACTORY_DETAILS_EDIT:
      return {
        ...state,
        editExFactoryDetail: action.payload,
      };

    case EX_FACTORY_DETAILS_EDITED_OK:
      return {
        ...state,
        editExFactoryDetail: null,
        exFactoryDetails: state.exFactoryDetails.data.map((exFactoryDetail) =>
          exFactoryDetail.id === action.payload.id
            ? (exFactoryDetail = action.payload)
            : exFactoryDetail
        ),
      };

    case RESET:
      return {
        ...state,
        exFactoryDetails: [],
      };

    default:
      return state;
  }
}
