import {
  ADD_SAMPLE_TYPES, ADD_SAMPLE_TYPES_ERROR, ADD_SAMPLE_TYPES_OK, BEGIN_EDIT_SAMPLE_TYPES,
  BEGIN_SAMPLE_TYPES_DOWNLOAD,
  RETRIEVE_SAMPLE_TYPES_BULK_DELETE, RETRIEVE_SAMPLE_TYPES_DELETE, RETRIEVE_SAMPLE_TYPES_EDIT,
  SAMPLE_TYPES_BULK_DELETED_ERROR, SAMPLE_TYPES_BULK_DELETED_OK, SAMPLE_TYPES_DELETED_ERROR, SAMPLE_TYPES_DELETED_OK, SAMPLE_TYPES_DOWNLOAD_ERROR, SAMPLE_TYPES_DOWNLOAD_OK, SAMPLE_TYPES_EDITED_ERROR, SAMPLE_TYPES_EDITED_OK
} from "../../constants/modules/sampleTypesConstant";
    
    const initialState = {
      sampleTypes: [],
      error: false,
      loading: false,
      deleteSampleType: null,
    };
    
    export default function (state = initialState, action) {
      switch (action.type) {
        case BEGIN_SAMPLE_TYPES_DOWNLOAD:
        case RETRIEVE_SAMPLE_TYPES_BULK_DELETE:
        case ADD_SAMPLE_TYPES:
        case BEGIN_EDIT_SAMPLE_TYPES:
          return {
            ...state,
            loading: action.payload,
            sampleType: action.sampleType,
          };
    
        case ADD_SAMPLE_TYPES_OK:
        case SAMPLE_TYPES_BULK_DELETED_OK:
          return {
            ...state,
            loading: false,
          };
    
        case ADD_SAMPLE_TYPES_ERROR:
        case SAMPLE_TYPES_DOWNLOAD_ERROR:
        case SAMPLE_TYPES_BULK_DELETED_ERROR:
        case SAMPLE_TYPES_DELETED_ERROR:
        case SAMPLE_TYPES_EDITED_ERROR:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case SAMPLE_TYPES_DOWNLOAD_OK:
          return {
            ...state,
            loading: false,
            error: null,
            sampleTypes: action.payload,
          };
    
        case RETRIEVE_SAMPLE_TYPES_DELETE:
          return {
            ...state,
            deleteSampleType: action.paType,
          };
    
        case SAMPLE_TYPES_DELETED_OK:
          return {
            ...state,
            sampleTypes: state.sampleTypes.data.filter((sampleType) => sampleType.id !== state.deleteSampleType),
            deleteSampleType: null,
          };

          case RETRIEVE_SAMPLE_TYPES_EDIT:
            return {
              ...state,
              editSampleType: action.payload,
            };
    
        case SAMPLE_TYPES_EDITED_OK:
          return {
            ...state,
            editSampleType: null,
            sampleTypes: state.sampleTypes.data.map((sampleType) =>
              sampleType.id === action.payload.id ? (sampleType = action.payload) : sampleType
            ),
          };
    
        default:
          return state;
      }
    }
    