import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_ORDERS,
  BEGIN_ORDERS_DOWNLOAD,
  RETRIEVE_ORDERS_DELETE,
  BEGIN_EDIT_ORDERS,
  RETRIEVE_ORDERS_BULK_DELETE,
} from "../../constants/modules/ordersConstants";

import {
  downloadOrdersOkAction,
  downloadOrdersErrorAction,
  addOrderOkAction,
  addOrderErrorAction,
  deleteOrderOkAction,
  deleteOrderErrorAction,
  editOrderOkAction,
  editOrderErrorAction,
  bulkDeleteOrderOkAction,
  bulkDeleteOrderErrorAction,
} from "../../actions/modules/OrdersActions";

import {
  retrieveOrdersDB,
  addOrderDB,
  deleteOrderDB,
  editOrderDB,
  bulkDeleteOrderDB,
} from "../../../config/apiCalls/orders";

// Retrieve products
// worker saga
export function* retrieveOrders(action) {
  const order = action.order;
  try {
    const response = yield call(retrieveOrdersDB, order);
    console.log("response-order", response);
    if (response.status === 200) {
      yield put(downloadOrdersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadOrdersErrorAction());
  }
}

// Create new product
// worker saga
export function* addOrder(action) {
  const order = action.order;
  try {
    const response = yield call(addOrderDB, order);

    if (response.status === 200) {
      yield delay(500);
      yield put(addOrderOkAction(response.data));
      toast.success("Order created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addOrderErrorAction(true));
    toast.error("Order created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteOrder(action) {
  const id = action.payload;
  try {
    yield call(deleteOrderDB, id);
    yield deleteOrderOkAction();
    toast.success("Order deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteOrderErrorAction());
    toast.error("Order deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkOrderDelete(action) {
  const order = action.order;

  try {
    const response = yield call(bulkDeleteOrderDB, order);
    console.log("response67565", response);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteOrderOkAction(order));
      toast.success("Order Deleted Successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteOrderErrorAction(true));
    toast.error("Order Deleted Failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editOrder(action) {
  const order = action.order;

  try {
    const response = yield call(editOrderDB, order);

    if (response.status === 201) {
      yield delay(500);
      yield put(editOrderOkAction(order));
      toast.success("Order edited successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editOrderErrorAction());
    toast.error("Order deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchOrdersAsync() {
  yield takeLatest(BEGIN_ORDERS_DOWNLOAD, retrieveOrders);
  yield takeLatest(ADD_ORDERS, addOrder);
  yield takeLatest(RETRIEVE_ORDERS_DELETE, deleteOrder);
  yield takeLatest(BEGIN_EDIT_ORDERS, editOrder);
  yield takeLatest(RETRIEVE_ORDERS_BULK_DELETE, bulkOrderDelete);
}
