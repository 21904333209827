import {
  ADD_SUB_CATEGORIES,
  ADD_SUB_CATEGORIES_ERROR,
  ADD_SUB_CATEGORIES_OK,
  BEGIN_EDIT_SUB_CATEGORIES,
  BEGIN_SUB_CATEGORIES_DOWNLOAD,
  RETRIEVE_SUB_CATEGORIES_BULK_DELETE,
  RETRIEVE_SUB_CATEGORIES_DELETE,
  RETRIEVE_SUB_CATEGORIES_EDIT,
  SUB_CATEGORIES_BULK_DELETED_ERROR,
  SUB_CATEGORIES_BULK_DELETED_OK,
  SUB_CATEGORIES_DELETED_ERROR,
  SUB_CATEGORIES_DELETED_OK,
  SUB_CATEGORIES_DOWNLOAD_ERROR,
  SUB_CATEGORIES_DOWNLOAD_OK,
  SUB_CATEGORIES_EDITED_ERROR,
  SUB_CATEGORIES_EDITED_OK,
} from "../../constants/modules/subCategoriesConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteSubCategoryAction = (item) => ({
  type: RETRIEVE_SUB_CATEGORIES_BULK_DELETE,
  payload: true,
  subCategory: item,
});

export const bulkDeleteSubCategoryOkAction = () => ({
  type: SUB_CATEGORIES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteSubCategoryErrorAction = (state) => ({
  type: SUB_CATEGORIES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadSubCategoriesAction = () => ({
  type: BEGIN_SUB_CATEGORIES_DOWNLOAD,
  payload: true,
});

export const downloadSubCategoriesOkAction = (items) => ({
  type: SUB_CATEGORIES_DOWNLOAD_OK,
  payload: items,
});

export const downloadSubCategoriesErrorAction = () => ({
  type: SUB_CATEGORIES_DOWNLOAD_ERROR,
  payload: true,
});

export const addSubCategoryAction = (item) => ({
  type: ADD_SUB_CATEGORIES,
  payload: true,
  subCategory: item,
});

export const addSubCategoryOkAction = () => ({
  type: ADD_SUB_CATEGORIES_OK,
  payload: true,
});

export const addSubCategoryErrorAction = (state) => ({
  type: ADD_SUB_CATEGORIES_ERROR,
  payload: state,
});

export const deleteSubCategoryAction = (id) => ({
  type: RETRIEVE_SUB_CATEGORIES_DELETE,
  payload: id,
});

export const deleteSubCategoryOkAction = () => ({
  type: SUB_CATEGORIES_DELETED_OK,
});

export const deleteSubCategoryErrorAction = () => ({
  type: SUB_CATEGORIES_DELETED_ERROR,
  payload: true,
});

export const retrieveSubCategoryAction = (item) => ({
  type: RETRIEVE_SUB_CATEGORIES_EDIT,
  payload: item,
});

export const editSubCategoryAction = (item) => ({
  type: BEGIN_EDIT_SUB_CATEGORIES,
  subCategory: item,
});

export const editSubCategoryOkAction = (item) => ({
  type: SUB_CATEGORIES_EDITED_OK,
  payload: item,
});

export const editSubCategoryErrorAction = () => ({
  type: SUB_CATEGORIES_EDITED_ERROR,
  payload: true,
});
