import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_TEAMS,
  BEGIN_EDIT_TEAMS,
  BEGIN_TEAMS_BY_BUYER_DOWNLOAD,
  BEGIN_TEAMS_DOWNLOAD,
  RETRIEVE_TEAMS_BULK_DELETE,
  RETRIEVE_TEAMS_DELETE
} from "../../constants/modules/teamConstants";

import {
  addTeamErrorAction,
  addTeamOkAction,
  bulkDeleteTeamErrorAction,
  bulkDeleteTeamOkAction,
  deleteTeamErrorAction,
  deleteTeamOkAction,
  downloadTeamsByBuyerErrorAction,
  downloadTeamsByBuyerOkAction,
  downloadTeamsErrorAction,
  downloadTeamsOkAction,
  editTeamErrorAction,
  editTeamOkAction
} from "../../actions/modules/teamActions";

import {
  addTeamDB,
  bulkDeleteTeamDB,
  deleteTeamDB,
  editTeamDB,
  retrieveTeamsByBuyerData,
  retrieveTeamsData
} from "../../../config/apiCalls/teams";

// Retrieve products
// worker saga
export function* retrieveTeams() {
  try {
    const response = yield call(retrieveTeamsData);

    if (response.status === 200) {
      yield put(downloadTeamsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTeamsErrorAction());
  }
}

export function* retrieveTeamsByBuyer(action) {
  const team = action.team;
  try {
    const response = yield call(retrieveTeamsByBuyerData, team);

    if (response.status === 200) {
      yield put(downloadTeamsByBuyerOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTeamsByBuyerErrorAction());
  }
}

// Create new product
// worker saga
export function* addTeam(action) {
  const team = action.team;

  try {
    const response = yield call(addTeamDB, team);

    if (response.status === 201) {
      yield delay(500);

      yield put(addTeamOkAction(team));
      toast.success("Team created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addTeamErrorAction(true));
    toast.error("Team created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteTeam(action) {
  const id = action.payload;
  try {
    yield call(deleteTeamDB, id);
    yield deleteTeamOkAction();
    toast.success("Team deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteTeamErrorAction();
    toast.success("Team deleted Failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editTeam(action) {
  const team = action.team;

  try {
    yield call(editTeamDB, team);
    yield editTeamOkAction(team);
    // Alert
    toast.success("Team has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editTeamErrorAction();
  }
}

// BULK SAGAS
export function* bulkTeamDelete(action) {
  const team = action.team;

  try {
    const response = yield call(bulkDeleteTeamDB, team);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteTeamOkAction(team));
      toast.success("Bulk Team deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteTeamErrorAction(true));
    toast.error("Bulk Team deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchTeamsAsync() {
  yield takeLatest(BEGIN_TEAMS_DOWNLOAD, retrieveTeams);
  yield takeLatest(BEGIN_TEAMS_BY_BUYER_DOWNLOAD, retrieveTeamsByBuyer);
  yield takeLatest(ADD_TEAMS, addTeam);
  yield takeLatest(RETRIEVE_TEAMS_DELETE, deleteTeam);
  yield takeLatest(BEGIN_EDIT_TEAMS, editTeam);
  yield takeLatest(RETRIEVE_TEAMS_BULK_DELETE, bulkTeamDelete);
}
