import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*              TNA S API CALLS              */
/*-------------------------------------------------*/
export async function bulkDeleteTnaTemplateDB(ids) {
  return await axiosClient.post(`/tna_templates/bulk_delete`, ids);
}

export async function retrieveTnaTemplatesData(value) {
  let body = {
    p_template_for: value,
  };

  return await axiosClient.post("/tna_templates/get_all", body);
}

export async function retrieveTnaTemplateData(value) {
  return await axiosClient.post("/tna_templates/get", value);
}

export async function addTnaTemplateDB(value) {
  return await axiosClient.post("/tna_templates", value);
}

export async function deleteTnaTemplateDB(id) {
  return await axiosClient.delete(`/tna_templates/${id}`);
}

export async function editTnaTemplateDB(value) {
  return await axiosClient.put(`/tna_templates/${value.id}`, value);
}
