import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_EX_FACTORY_DETAILS,
  BEGIN_EX_FACTORY_DETAILS_DOWNLOAD,
  RETRIEVE_EX_FACTORY_DETAILS_DELETE,
  BEGIN_EDIT_EX_FACTORY_DETAILS,
  RETRIEVE_EX_FACTORY_DETAILS_BULK_DELETE,
} from "../../constants/modules/exFactoryDetailsConstants";

import {
  downloadExFactoryDetailsOkAction,
  downloadExFactoryDetailsErrorAction,
  addExFactoryDetailOkAction,
  addExFactoryDetailErrorAction,
  deleteExFactoryDetailOkAction,
  deleteExFactoryDetailErrorAction,
  editExFactoryDetailOkAction,
  editExFactoryDetailErrorAction,
  bulkDeleteExFactoryDetailOkAction,
  bulkDeleteExFactoryDetailErrorAction,
} from "../../actions/modules/exFactoryDetailsActions";

import {
  retrieveExFactoryDetailDB,
  addExFactoryDetailDB,
  deleteExFactoryDetailDB,
  editExFactoryDetailDB,
  bulkDeleteExFactoryDetailDB,
} from "../../../config/apiCalls/exFactoryDetails";

// Retrieve products
// worker saga
export function* retrieveExFactoryDetails(action) {
  const exFactoryDetail = action.exFactoryDetail;
  try {
    const response = yield call(retrieveExFactoryDetailDB, exFactoryDetail);
    if (response.status === 200) {
      yield put(downloadExFactoryDetailsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadExFactoryDetailsErrorAction());
  }
}

// Create new product
// worker saga
export function* addExFactoryDetail(action) {
  const exFactoryDetail = action.exFactoryDetail;

  try {
    const response = yield call(addExFactoryDetailDB, exFactoryDetail);

    if (response.status === 200) {
      yield delay(500);
      yield put(addExFactoryDetailOkAction(exFactoryDetail));
      toast.success("Order Detail created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addExFactoryDetailErrorAction(true));
    toast.error("Order Detail created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteExFactoryDetail(action) {
  const id = action.payload;
  try {
    yield call(deleteExFactoryDetailDB, id);
    yield deleteExFactoryDetailOkAction();
    toast.success("Order Detail deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteExFactoryDetailErrorAction());
    toast.error("Order Detail deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkExFactoryDetailDelete(action) {
  const exFactoryDetail = action.exFactoryDetail;

  try {
    const response = yield call(bulkDeleteExFactoryDetailDB, exFactoryDetail);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteExFactoryDetailOkAction(exFactoryDetail));
      toast.success("Order Details deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteExFactoryDetailErrorAction(true));
    toast.error("Order Details deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editExFactoryDetail(action) {
  const exFactoryDetail = action.exFactoryDetail;

  try {
    const response = yield call(editExFactoryDetailDB, exFactoryDetail);

    if (response.status === 201) {
      yield delay(500);
      yield put(editExFactoryDetailOkAction(exFactoryDetail));
      toast.success("Order Detail edited successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editExFactoryDetailErrorAction());
    toast.error("Order Detail deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchExFactoryDetailsAsync() {
  yield takeLatest(BEGIN_EX_FACTORY_DETAILS_DOWNLOAD, retrieveExFactoryDetails);
  yield takeLatest(ADD_EX_FACTORY_DETAILS, addExFactoryDetail);
  yield takeLatest(RETRIEVE_EX_FACTORY_DETAILS_DELETE, deleteExFactoryDetail);
  yield takeLatest(BEGIN_EDIT_EX_FACTORY_DETAILS, editExFactoryDetail);
  yield takeLatest(
    RETRIEVE_EX_FACTORY_DETAILS_BULK_DELETE,
    bulkExFactoryDetailDelete
  );
}
