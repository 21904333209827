import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_DISCOUNT_DETAILS,
  BEGIN_EDIT_DISCOUNT_DETAILS,
  BEGIN_DISCOUNT_DETAILS_DOWNLOAD,
  RETRIEVE_DISCOUNT_DETAILS_DELETE
} from "../../constants/modules/discountDetailsConstants";

import {
  addDiscountDetailOkAction,
  deleteDiscountDetailErrorAction,
  deleteDiscountDetailOkAction,
  downloadDiscountDetailsErrorAction,
  downloadDiscountDetailsOkAction,
  editDiscountDetailErrorAction,
  editDiscountDetailOkAction,
  addDiscountDetailErrorAction 
} from "../../actions/modules/discountDetailsAction";

import {
  addDiscountDetailDB,
  deleteDiscountDetailDB,
  editDiscountDetailDB,
  retrieveDiscountDetailsData
} from "../../../config/apiCalls/discountDetailsAPICall";


// Retrieve products
// worker saga
export function* retrieveDiscountDetails() {
  try {
    const response = yield call(retrieveDiscountDetailsData);

    if (response.status === 200) {
      yield put(downloadDiscountDetailsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadDiscountDetailsErrorAction());
  }
}

// Create new product
// worker saga
export function* addDiscountDetails(action) {
  const discountDetail = action.discountDetail;

  try {
    const response = yield call(addDiscountDetailDB, discountDetail);

    if (response.status === 200) {
      yield delay(500);

      yield put(addDiscountDetailOkAction(discountDetail));
      toast.success("Discount Detail created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addDiscountDetailErrorAction(true));
    toast.error("Discount Detail created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteDiscountDetail(action) {
  const id = action.payload;
  try {
    yield call(deleteDiscountDetailDB, id);
    yield deleteDiscountDetailOkAction();
    toast.success("Discount Detail deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteDiscountDetailErrorAction();
    toast.error("Discount Detail deleted failed!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }
}

// Edit product
// worker saga
export function* editDiscountDetail(action) {
  const discountDetail = action.discountDetail;

  try {
    yield call(editDiscountDetailDB, discountDetail);
    yield editDiscountDetailOkAction(discountDetail);
    // Alert
    // Alert
    toast.success("Discount Detail has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editDiscountDetailErrorAction();
    toast.error("Discount Detail update failed!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }
}


export function* watchDiscountDetailsAsync() {
  yield takeLatest(
    BEGIN_DISCOUNT_DETAILS_DOWNLOAD,
    retrieveDiscountDetails
  );
  yield takeLatest(ADD_DISCOUNT_DETAILS, addDiscountDetails);
  yield takeLatest(
    RETRIEVE_DISCOUNT_DETAILS_DELETE,
    deleteDiscountDetail
  );
  yield takeLatest(BEGIN_EDIT_DISCOUNT_DETAILS, editDiscountDetail);
  
}
