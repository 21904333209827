import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_FREIGHT_TERMS,
  BEGIN_EDIT_FREIGHT_TERMS,
  BEGIN_FREIGHT_TERMS_DOWNLOAD,
  RETRIEVE_FREIGHT_TERMS_BULK_DELETE,
  RETRIEVE_FREIGHT_TERMS_DELETE
} from "../../constants/modules/freightTermConstant";

import {
  addFreightTermErrorAction,
  addFreightTermOkAction,
  bulkDeleteFreightTermErrorAction,
  bulkDeleteFreightTermOkAction,
  deleteFreightTermErrorAction,
  deleteFreightTermOkAction,
  downloadFreightTermsErrorAction,
  downloadFreightTermsOkAction,
  editFreightTermErrorAction,
  editFreightTermOkAction
} from "../../actions/modules/freightTermActions";

import {
  addFreightTermDB,
  bulkDeleteFreightTermDB,
  deleteFreightTermDB,
  editFreightTermDB,
  retrieveFreightTermsData
} from "../../../config/apiCalls/freightTermAPICall";

// Retrieve products
// worker saga
export function* retrieveFreightTerms() {
  try {
    const response = yield call(retrieveFreightTermsData);

    if (response.status === 200) {
      yield put(downloadFreightTermsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFreightTermsErrorAction());
  }
}

// Create new product
// worker saga
export function* addFreightTerm(action) {
  const freightTerm = action.freightTerm;

  try {
    const response = yield call(addFreightTermDB, freightTerm);

    if (response.status === 201) {
      yield delay(500);

      yield put(addFreightTermOkAction(freightTerm));
      toast.success("Freight Term created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addFreightTermErrorAction(true));
    toast.error("Freight Term created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteFreightTerm(action) {
  const id = action.payload;
  try {
    yield call(deleteFreightTermDB, id);
    yield deleteFreightTermOkAction();
    toast.success("Freight Term deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteFreightTermErrorAction();
  }
}

// Edit product
// worker saga
export function* editFreightTerm(action) {
  const freightTerm = action.freightTerm;

  try {
    yield call(editFreightTermDB, freightTerm);
    yield editFreightTermOkAction(freightTerm);
    // Alert
    // Alert
    toast.success("Freight Term has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editFreightTermErrorAction();
  }
}

// BULK SAGAS
export function* bulkFreightTermDelete(action) {
  const freightTerm = action.freightTerm;

  try {
    const response = yield call(bulkDeleteFreightTermDB, freightTerm);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteFreightTermOkAction(freightTerm));
      toast.success("Bulk Freight Term deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteFreightTermErrorAction(true));
    toast.error("Bulk Freight Term deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchFreightTermsAsync() {
  yield takeLatest(BEGIN_FREIGHT_TERMS_DOWNLOAD, retrieveFreightTerms);
  yield takeLatest(ADD_FREIGHT_TERMS, addFreightTerm);
  yield takeLatest(RETRIEVE_FREIGHT_TERMS_DELETE, deleteFreightTerm);
  yield takeLatest(BEGIN_EDIT_FREIGHT_TERMS, editFreightTerm);
  yield takeLatest(RETRIEVE_FREIGHT_TERMS_BULK_DELETE, bulkFreightTermDelete);
}
