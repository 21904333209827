export const ADD_EX_FACTORY_HEADER = "ADD_EX_FACTORY_HEADER";
export const ADD_EX_FACTORY_HEADER_OK = "ADD_EX_FACTORY_HEADER_OK";
export const ADD_EX_FACTORY_HEADER_ERROR = "ADD_EX_FACTORY_HEADER_ERROR";

export const BEGIN_EX_FACTORY_HEADER_DOWNLOAD =
  "BEGIN_EX_FACTORY_HEADER_DOWNLOAD";
export const EX_FACTORY_HEADER_DOWNLOAD_OK = "EX_FACTORY_HEADER_DOWNLOAD_OK";
export const EX_FACTORY_HEADER_DOWNLOAD_ERROR =
  "EX_FACTORY_HEADER_DOWNLOAD_ERROR";

export const RETRIEVE_EX_FACTORY_HEADER_DELETE =
  "RETRIEVE_EX_FACTORY_HEADER_DELETE";
export const EX_FACTORY_HEADER_DELETED_OK = "EX_FACTORY_HEADER_DELETED_OK";
export const EX_FACTORY_HEADER_DELETED_ERROR =
  "EX_FACTORY_HEADER_DELETED_ERROR";

export const ADD_EX_FACTORY_DETAIL = "ADD_EX_FACTORY_DETAIL";
export const ADD_EX_FACTORY_DETAIL_OK = "ADD_EX_FACTORY_DETAIL_OK";
export const ADD_EX_FACTORY_DETAIL_ERROR = "ADD_EX_FACTORY_DETAIL_ERROR";

export const BEGIN_EX_FACTORY_DETAIL_DOWNLOAD =
  "BEGIN_EX_FACTORY_DETAIL_DOWNLOAD";
export const EX_FACTORY_DETAIL_DOWNLOAD_OK = "EX_FACTORY_DETAIL_DOWNLOAD_OK";
export const EX_FACTORY_DETAIL_DOWNLOAD_ERROR =
  "EX_FACTORY_DETAIL_DOWNLOAD_ERROR";

export const RETRIEVE_EX_FACTORY_DETAIL_DELETE =
  "RETRIEVE_EX_FACTORY_DETAIL_DELETE";
export const EX_FACTORY_DETAIL_DELETED_OK = "EX_FACTORY_DETAIL_DELETED_OK";
export const EX_FACTORY_DETAIL_DELETED_ERROR =
  "EX_FACTORY_DETAIL_DELETED_ERROR";

export const ADD_EX_FACTORY_SHIPMENT_DETAIL = "ADD_EX_FACTORY_SHIPMENT_DETAIL";
export const ADD_EX_FACTORY_SHIPMENT_DETAIL_OK =
  "ADD_EX_FACTORY_SHIPMENT_DETAIL_OK";
export const ADD_EX_FACTORY_SHIPMENT_DETAIL_ERROR =
  "ADD_EX_FACTORY_SHIPMENT_DETAIL_ERROR";

export const BEGIN_EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD =
  "BEGIN_EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD";
export const EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD_OK =
  "EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD_OK";
export const EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD_ERROR =
  "EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD_ERROR";
