import {
    ADD_FACTORY_INVOICES,
    ADD_FACTORY_INVOICES_ERROR,
    ADD_FACTORY_INVOICES_OK,
    BEGIN_EDIT_FACTORY_INVOICES,
    BEGIN_FACTORY_INVOICES_DOWNLOAD,
    BEGIN_FACTORY_INVOICE_DOWNLOAD, FACTORY_INVOICES_BULK_DELETED_ERROR,
    FACTORY_INVOICES_BULK_DELETED_OK,
    FACTORY_INVOICES_DELETED_ERROR,
    FACTORY_INVOICES_DELETED_OK,
    FACTORY_INVOICES_DOWNLOAD_ERROR,
    FACTORY_INVOICES_DOWNLOAD_OK,
    FACTORY_INVOICES_EDITED_ERROR,
    FACTORY_INVOICES_EDITED_OK,
    FACTORY_INVOICE_DOWNLOAD_ERROR,
    FACTORY_INVOICE_DOWNLOAD_OK, RESET,
    RETRIEVE_FACTORY_INVOICES_BULK_DELETE,
    RETRIEVE_FACTORY_INVOICES_DELETE,
    RETRIEVE_FACTORY_INVOICES_EDIT
} from "../../constants/modules/factoryInvoiceConstant";
  
  export const bulkDeleteFactoryInvoiceAction = (item) => ({
    type: RETRIEVE_FACTORY_INVOICES_BULK_DELETE,
    payload: true,
    factoryInvoice: item,
  });
  
  export const bulkDeleteFactoryInvoiceOkAction = () => ({
    type: FACTORY_INVOICES_BULK_DELETED_OK,
    payload: true,
  });
  
  export const bulkDeleteFactoryInvoiceErrorAction = (state) => ({
    type: FACTORY_INVOICES_BULK_DELETED_ERROR,
    payload: state,
  });
  
  export const downloadFactoryInvoicesAction = () => ({
    type: BEGIN_FACTORY_INVOICES_DOWNLOAD,
    payload: true,
  });
  
  export const downloadFactoryInvoicesOkAction = (items) => ({
    type: FACTORY_INVOICES_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadFactoryInvoicesErrorAction = () => ({
    type: FACTORY_INVOICES_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const downloadFactoryInvoiceAction = (item) => ({
    type: BEGIN_FACTORY_INVOICE_DOWNLOAD,
    payload: true,
    factoryInvoice: item,
  });
  
  export const downloadFactoryInvoiceOkAction = (items) => ({
    type: FACTORY_INVOICE_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadFactoryInvoiceErrorAction = () => ({
    type: FACTORY_INVOICE_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const addFactoryInvoiceAction = (item) => ({
    type: ADD_FACTORY_INVOICES,
    payload: true,
    factoryInvoice: item,
  });
  
  export const addFactoryInvoiceOkAction = (items) => ({
    type: ADD_FACTORY_INVOICES_OK,
    payload: items,
  });
  
  export const addFactoryInvoiceErrorAction = (state) => ({
    type: ADD_FACTORY_INVOICES_ERROR,
    payload: state,
  });
  
  export const deleteFactoryInvoiceAction = (id) => ({
    type: RETRIEVE_FACTORY_INVOICES_DELETE,
    payload: id,
  });
  
  export const deleteFactoryInvoiceOkAction = () => ({
    type: FACTORY_INVOICES_DELETED_OK,
  });
  
  export const deleteFactoryInvoiceErrorAction = () => ({
    type: FACTORY_INVOICES_DELETED_ERROR,
    payload: true,
  });
  
  export const retrieveFactoryInvoiceAction = (item) => ({
    type: RETRIEVE_FACTORY_INVOICES_EDIT,
    payload: item,
  });
  
  export const editFactoryInvoiceAction = (item) => ({
    type: BEGIN_EDIT_FACTORY_INVOICES,
    factoryInvoice: item,
  });
  
  export const editFactoryInvoiceOkAction = (item) => ({
    type: FACTORY_INVOICES_EDITED_OK,
    payload: item,
  });
  
  export const editFactoryInvoiceErrorAction = () => ({
    type: FACTORY_INVOICES_EDITED_ERROR,
    payload: true,
  });
  
  export const FactoryInvoiceReset = () => ({
    type: RESET,
    payload: true,
  });
  