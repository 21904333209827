import {
  ADD_SALES_CONTRACTS,
  ADD_SALES_CONTRACTS_ERROR,
  ADD_SALES_CONTRACTS_OK,
  BEGIN_EDIT_SALES_CONTRACTS,
  BEGIN_SALES_CONTRACTS_DOWNLOAD,
  BEGIN_SALES_CONTRACT_DOWNLOAD,
  RESET,
  RETRIEVE_SALES_CONTRACTS_BULK_DELETE,
  RETRIEVE_SALES_CONTRACTS_DELETE,
  RETRIEVE_SALES_CONTRACTS_EDIT,
  SALES_CONTRACTS_BULK_DELETED_ERROR,
  SALES_CONTRACTS_BULK_DELETED_OK,
  SALES_CONTRACTS_DELETED_ERROR,
  SALES_CONTRACTS_DELETED_OK,
  SALES_CONTRACTS_DOWNLOAD_ERROR,
  SALES_CONTRACTS_DOWNLOAD_OK,
  SALES_CONTRACTS_EDITED_ERROR,
  SALES_CONTRACTS_EDITED_OK,
  SALES_CONTRACT_DOWNLOAD_ERROR,
  SALES_CONTRACT_DOWNLOAD_OK,
} from "../../constants/modules/salesContractConstants";

export const bulkDeleteSalesContractAction = (item) => ({
  type: RETRIEVE_SALES_CONTRACTS_BULK_DELETE,
  payload: true,
  salesContract: item,
});

export const bulkDeleteSalesContractOkAction = () => ({
  type: SALES_CONTRACTS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteSalesContractErrorAction = (state) => ({
  type: SALES_CONTRACTS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadSalesContractsAction = (item) => ({
  type: BEGIN_SALES_CONTRACTS_DOWNLOAD,
  payload: true,
  salesContract: item,
});

export const downloadSalesContractsOkAction = (items) => ({
  type: SALES_CONTRACTS_DOWNLOAD_OK,
  payload: items,
});

export const downloadSalesContractsErrorAction = () => ({
  type: SALES_CONTRACTS_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadSalesContractAction = (item) => ({
  type: BEGIN_SALES_CONTRACT_DOWNLOAD,
  payload: true,
  salesContract: item,
});

export const downloadSalesContractOkAction = (items) => ({
  type: SALES_CONTRACT_DOWNLOAD_OK,
  payload: items,
});

export const downloadSalesContractErrorAction = () => ({
  type: SALES_CONTRACT_DOWNLOAD_ERROR,
  payload: true,
});

export const addSalesContractAction = (item) => ({
  type: ADD_SALES_CONTRACTS,
  payload: true,
  salesContract: item,
});

export const addSalesContractOkAction = (items) => ({
  type: ADD_SALES_CONTRACTS_OK,
  payload: items,
});

export const addSalesContractErrorAction = (state) => ({
  type: ADD_SALES_CONTRACTS_ERROR,
  payload: state,
});

export const deleteSalesContractAction = (id) => ({
  type: RETRIEVE_SALES_CONTRACTS_DELETE,
  payload: id,
});

export const deleteSalesContractOkAction = () => ({
  type: SALES_CONTRACTS_DELETED_OK,
});

export const deleteSalesContractErrorAction = () => ({
  type: SALES_CONTRACTS_DELETED_ERROR,
  payload: true,
});

export const retrieveSalesContractAction = (item) => ({
  type: RETRIEVE_SALES_CONTRACTS_EDIT,
  payload: item,
});

export const editSalesContractAction = (item) => ({
  type: BEGIN_EDIT_SALES_CONTRACTS,
  salesContract: item,
});

export const editSalesContractOkAction = (item) => ({
  type: SALES_CONTRACTS_EDITED_OK,
  payload: item,
});

export const editSalesContractErrorAction = () => ({
  type: SALES_CONTRACTS_EDITED_ERROR,
  payload: true,
});

export const salesContractReset = () => ({
  type: RESET,
  payload: true,
});
