import {
  ADD_COURIERS,
  ADD_COURIERS_ERROR,
  ADD_COURIERS_OK,
  BEGIN_COURIERS_DOWNLOAD,
  BEGIN_EDIT_COURIERS,
  COURIERS_BULK_DELETED_ERROR,
  COURIERS_BULK_DELETED_OK,
  COURIERS_DELETED_ERROR,
  COURIERS_DELETED_OK,
  COURIERS_DOWNLOAD_ERROR,
  COURIERS_DOWNLOAD_OK,
  COURIERS_EDITED_ERROR,
  COURIERS_EDITED_OK,
  RETRIEVE_COURIERS_BULK_DELETE,
  RETRIEVE_COURIERS_DELETE,
  RETRIEVE_COURIERS_EDIT,
} from "../../constants/modules/couriersConstants.js";

const initialState = {
  couriers: [],
  error: false,
  loading: false,
  deleteCourier: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_COURIERS_DOWNLOAD:
    case ADD_COURIERS:
    case RETRIEVE_COURIERS_BULK_DELETE:
    case BEGIN_EDIT_COURIERS:
      return {
        ...state,
        loading: action.payload,
        courier: action.courier,
      };

    case ADD_COURIERS_OK:
    case COURIERS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_COURIERS_ERROR:
    case COURIERS_DOWNLOAD_ERROR:
    case COURIERS_BULK_DELETED_ERROR:
    case COURIERS_DELETED_ERROR:
    case COURIERS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COURIERS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        courier: action.payload,
      };

    case RETRIEVE_COURIERS_DELETE:
      return {
        ...state,
        deleteCourier: action.payload,
      };

    case COURIERS_DELETED_OK:
      return {
        ...state,
        couriers: state.couriers.data.filter(
          (courier) => courier.id !== state.deleteCourier
        ),
        deleteCourier: null,
      };

    case RETRIEVE_COURIERS_EDIT:
      return {
        ...state,
        editCourier: action.payload,
      };

    case COURIERS_EDITED_OK:
      return {
        ...state,
        editCourier: null,
        couriers: state.couriers.data.map((courier) =>
          courier.id === action.payload.id
            ? (courier = action.payload)
            : courier
        ),
      };

    default:
      return state;
  }
}
