import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_CATEGORIES,
  BEGIN_CATEGORIES_DOWNLOAD,
  BEGIN_EDIT_CATEGORIES,
  RETRIEVE_CATEGORIES_BULK_DELETE,
  RETRIEVE_CATEGORIES_DELETE,
} from "../../constants/modules/categoriesConstants";

import {
  addCategoryErrorAction,
  addCategoryOkAction,
  bulkDeleteCategoryErrorAction,
  bulkDeleteCategoryOkAction,
  deleteCategoryErrorAction,
  deleteCategoryOkAction,
  downloadCategoriesErrorAction,
  downloadCategoriesOkAction,
  editCategoryErrorAction,
  editCategoryOkAction,
} from "../../actions/modules/categoriesActions";

import {
  addCategoryDB,
  bulkDeleteCategoryDB,
  deleteCategoryDB,
  editCategoryDB,
  retrieveCategoriesData,
} from "../../../config/apiCalls/categoriesAPICall";

// Retrieve products
// worker saga
export function* retrieveCategories() {
  try {
    const response = yield call(retrieveCategoriesData);

    if (response.status === 200) {
      yield put(downloadCategoriesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadCategoriesErrorAction());
  }
}

// Create new product
// worker saga
export function* addCategory(action) {
  const category = action.category;

  try {
    const response = yield call(addCategoryDB, category);

    if (response.status === 201) {
      yield delay(500);

      yield put(addCategoryOkAction(category));
      toast.success("Category created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addCategoryErrorAction(true));
    toast.error("Category created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteCategory(action) {
  const id = action.payload;
  try {
    yield call(deleteCategoryDB, id);
    yield deleteCategoryOkAction();
    toast.success("Category deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteCategoryErrorAction();
  }
}

// Edit product
// worker saga
export function* editCategory(action) {
  const category = action.category;

  try {
    yield call(editCategoryDB, category);
    yield editCategoryOkAction(category);
    // Alert
    // Alert
    toast.success("Category has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editCategoryErrorAction();
    toast.error("Category Update failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// BULK SAGAS
export function* bulkCategoryDelete(action) {
  const category = action.category;

  try {
    const response = yield call(bulkDeleteCategoryDB, category);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteCategoryOkAction(category));
      toast.success("Bulk Category deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteCategoryErrorAction(true));
    toast.error("Bulk Category deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchCategoriesAsync() {
  yield takeLatest(BEGIN_CATEGORIES_DOWNLOAD, retrieveCategories);
  yield takeLatest(ADD_CATEGORIES, addCategory);
  yield takeLatest(RETRIEVE_CATEGORIES_DELETE, deleteCategory);
  yield takeLatest(BEGIN_EDIT_CATEGORIES, editCategory);
  yield takeLatest(RETRIEVE_CATEGORIES_BULK_DELETE, bulkCategoryDelete);
}
