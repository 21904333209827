export const ADD_SAMPLE_ORDERS = "ADD_SAMPLE_ORDERS";
export const ADD_SAMPLE_ORDERS_OK = "ADD_SAMPLE_ORDERS_OK";
export const ADD_SAMPLE_ORDERS_ERROR = "ADD_SAMPLE_ORDERS_ERROR";

export const BEGIN_SAMPLE_ORDERS_DOWNLOAD = "BEGIN_SAMPLE_ORDERS_DOWNLOAD";
export const SAMPLE_ORDERS_DOWNLOAD_OK = "SAMPLE_ORDERS_DOWNLOAD_OK";
export const SAMPLE_ORDERS_DOWNLOAD_ERROR = "SAMPLE_ORDERS_DOWNLOAD_ERROR";

export const RETRIEVE_SAMPLE_ORDERS_DELETE = "RETRIEVE_SAMPLE_ORDERS_DELETE";
export const SAMPLE_ORDERS_DELETED_OK = "SAMPLE_ORDERS_DELETED_OK";
export const SAMPLE_ORDERS_DELETED_ERROR = "SAMPLE_ORDERS_DELETED_ERROR";

export const RETRIEVE_SAMPLE_ORDERS_EDIT = "RETRIEVE_SAMPLE_ORDERS_EDITED";
export const BEGIN_EDIT_SAMPLE_ORDERS = "BEGIN_EDIT_SAMPLE_ORDERS";
export const SAMPLE_ORDERS_EDITED_OK = "SAMPLE_ORDERS_EDITED_OK";
export const SAMPLE_ORDERS_EDITED_ERROR = "SAMPLE_ORDERS_EDITED_ERROR";

export const RETRIEVE_SAMPLE_ORDERS_BULK_DELETE = "RETRIEVE_SAMPLE_ORDERS_BULK_DELETE";
export const SAMPLE_ORDERS_BULK_DELETED_OK = "SAMPLE_ORDERS_BULK_DELETED_OK";
export const SAMPLE_ORDERS_BULK_DELETED_ERROR = "SAMPLE_ORDERS_BULK_DELETED_ERROR";