import {
  ADD_USER_LEVELS,
  ADD_USER_LEVELS_ERROR,
  ADD_USER_LEVELS_OK,
  BEGIN_EDIT_USER_LEVELS,
  BEGIN_USER_LEVELS_DOWNLOAD,
  RETRIEVE_USER_LEVELS_BULK_DELETE,
  RETRIEVE_USER_LEVELS_DELETE,
  RETRIEVE_USER_LEVELS_EDIT,
  USER_LEVELS_BULK_DELETED_ERROR,
  USER_LEVELS_BULK_DELETED_OK,
  USER_LEVELS_DELETED_ERROR,
  USER_LEVELS_DELETED_OK,
  USER_LEVELS_DOWNLOAD_ERROR,
  USER_LEVELS_DOWNLOAD_OK,
  USER_LEVELS_EDITED_ERROR,
  USER_LEVELS_EDITED_OK
} from "../../constants/modules/userLevelsConstant";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteUserLevelAction = (item) => ({
  type: RETRIEVE_USER_LEVELS_BULK_DELETE,
  payload: true,
  userLevel: item,
});

export const bulkDeleteUserLevelOkAction = () => ({
  type: USER_LEVELS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteUserLevelErrorAction = (state) => ({
  type: USER_LEVELS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadUserLevelsAction = () => ({
  type: BEGIN_USER_LEVELS_DOWNLOAD,
  payload: true,
});

export const downloadUserLevelsOkAction = (items) => ({
  type: USER_LEVELS_DOWNLOAD_OK,
  payload: items,
});

export const downloadUserLevelsErrorAction = () => ({
  type: USER_LEVELS_DOWNLOAD_ERROR,
  payload: true,
});

export const addUserLevelAction = (item) => ({
  type: ADD_USER_LEVELS,
  payload: true,
  userLevel: item,
});

export const addUserLevelOkAction = () => ({
  type: ADD_USER_LEVELS_OK,
  payload: true,
});

export const addUserLevelErrorAction = (state) => ({
  type: ADD_USER_LEVELS_ERROR,
  payload: state,
});

export const deleteUserLevelAction = (id) => ({
  type: RETRIEVE_USER_LEVELS_DELETE,
  payload: id,
});

export const deleteUserLevelOkAction = () => ({
  type: USER_LEVELS_DELETED_OK,
});

export const deleteUserLevelErrorAction = () => ({
  type: USER_LEVELS_DELETED_ERROR,
  payload: true,
});

export const retrieveUserLevelAction = (item) => ({
  type: RETRIEVE_USER_LEVELS_EDIT,
  payload: item,
});

export const editUserLevelAction = (item) => ({
  type: BEGIN_EDIT_USER_LEVELS,
  userLevel: item,
});

export const editUserLevelOkAction = (item) => ({
  type: USER_LEVELS_EDITED_OK,
  payload: item,
});

export const editUserLevelErrorAction = () => ({
  type: USER_LEVELS_EDITED_ERROR,
  payload: true,
});
