import {
  ADD_FACTORY_SHIPMENT_DETAILS,
  ADD_FACTORY_SHIPMENT_DETAILS_OK,
  ADD_FACTORY_SHIPMENT_DETAILS_ERROR,
  BEGIN_FACTORY_SHIPMENT_DETAILS_DOWNLOAD,
  FACTORY_SHIPMENT_DETAILS_DOWNLOAD_OK,
  FACTORY_SHIPMENT_DETAILS_DOWNLOAD_ERROR,
  RETRIEVE_FACTORY_SHIPMENT_DETAILS_DELETE,
  FACTORY_SHIPMENT_DETAILS_DELETED_OK,
  FACTORY_SHIPMENT_DETAILS_DELETED_ERROR,
  RETRIEVE_FACTORY_SHIPMENT_DETAILS_EDIT,
  BEGIN_EDIT_FACTORY_SHIPMENT_DETAILS,
  FACTORY_SHIPMENT_DETAILS_EDITED_OK,
  FACTORY_SHIPMENT_DETAILS_EDITED_ERROR,
  RETRIEVE_FACTORY_SHIPMENT_DETAILS_BULK_DELETE,
  FACTORY_SHIPMENT_DETAILS_BULK_DELETED_OK,
  FACTORY_SHIPMENT_DETAILS_BULK_DELETED_ERROR,
  BEGIN_SHIPMENT_DETAILS_BY_FOD_DOWNLOAD,
  SHIPMENT_DETAILS_BY_FOD_DOWNLOAD_OK,
  SHIPMENT_DETAILS_BY_FOD_DOWNLOAD_ERROR,
} from "../../constants/modules/factoryShipmentDetailsConstants";

const initialState = {
  factoryShipmentDetails: [],
  detailsByfob: [],
  error: false,
  loading: false,
  deleteFactoryShipmentDetail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_FACTORY_SHIPMENT_DETAILS_DOWNLOAD:
    case BEGIN_SHIPMENT_DETAILS_BY_FOD_DOWNLOAD:
    case ADD_FACTORY_SHIPMENT_DETAILS:
    case RETRIEVE_FACTORY_SHIPMENT_DETAILS_BULK_DELETE:
    case BEGIN_EDIT_FACTORY_SHIPMENT_DETAILS:
      return {
        ...state,
        loading: action.payload,
        factoryShipmentDetail: action.factoryShipmentDetail,
      };

    case ADD_FACTORY_SHIPMENT_DETAILS_OK:
    case FACTORY_SHIPMENT_DETAILS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_FACTORY_SHIPMENT_DETAILS_ERROR:
    case FACTORY_SHIPMENT_DETAILS_DOWNLOAD_ERROR:
    case SHIPMENT_DETAILS_BY_FOD_DOWNLOAD_ERROR:
    case FACTORY_SHIPMENT_DETAILS_DELETED_ERROR:
    case FACTORY_SHIPMENT_DETAILS_BULK_DELETED_ERROR:
    case FACTORY_SHIPMENT_DETAILS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FACTORY_SHIPMENT_DETAILS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        factoryShipmentDetails: action.payload,
      };

    case SHIPMENT_DETAILS_BY_FOD_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        detailsByfod: action.payload,
      };

    case RETRIEVE_FACTORY_SHIPMENT_DETAILS_DELETE:
      return {
        ...state,
        deleteBank: action.payload,
      };

    case FACTORY_SHIPMENT_DETAILS_DELETED_OK:
      return {
        ...state,
        factoryShipmentDetails: state.factoryShipmentDetails.data.filter(
          (factoryShipmentDetail) =>
            factoryShipmentDetail.id !== state.deleteFactoryShipmentDetail
        ),
        deleteFactoryShipmentDetail: null,
      };

    case RETRIEVE_FACTORY_SHIPMENT_DETAILS_EDIT:
      return {
        ...state,
        editFactoryShipmentDetail: action.payload,
      };

    case FACTORY_SHIPMENT_DETAILS_EDITED_OK:
      return {
        ...state,
        editFactoryShipmentDetail: null,
        factoryShipmentDetails: state.factoryShipmentDetails.data.map(
          (factoryShipmentDetail) =>
            factoryShipmentDetail.id === action.payload.id
              ? (factoryShipmentDetail = action.payload)
              : factoryShipmentDetail
        ),
      };

    default:
      return state;
  }
}
