import {
  ADD_FACTORY_ORDERS,
  ADD_FACTORY_ORDERS_OK,
  ADD_FACTORY_ORDERS_ERROR,
  BEGIN_FACTORY_ORDERS_DOWNLOAD,
  FACTORY_ORDERS_DOWNLOAD_OK,
  FACTORY_ORDERS_DOWNLOAD_ERROR,
  RETRIEVE_FACTORY_ORDERS_DELETE,
  FACTORY_ORDERS_DELETED_OK,
  FACTORY_ORDERS_DELETED_ERROR,
  RETRIEVE_FACTORY_ORDERS_EDIT,
  BEGIN_EDIT_FACTORY_ORDERS,
  FACTORY_ORDERS_EDITED_OK,
  FACTORY_ORDERS_EDITED_ERROR,
  RETRIEVE_FACTORY_ORDERS_BULK_DELETE,
  FACTORY_ORDERS_BULK_DELETED_OK,
  FACTORY_ORDERS_BULK_DELETED_ERROR,
  BEGIN_FACTORY_BY_ORDER_DOWNLOAD,
  FACTORY_BY_ORDER_DOWNLOAD_OK,
  FACTORY_BY_ORDER_DOWNLOAD_ERROR,
} from "../../constants/modules/factoryOrdersConstants";

export const downloadFactoryOrdersAction = (item) => ({
  type: BEGIN_FACTORY_ORDERS_DOWNLOAD,
  payload: true,
  factoryOrder: item,
});

export const downloadFactoryOrdersOkAction = (items) => ({
  type: FACTORY_ORDERS_DOWNLOAD_OK,
  payload: items,
});

export const downloadFactoryOrdersErrorAction = () => ({
  type: FACTORY_ORDERS_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadFactoryByOrdersAction = (item) => ({
  type: BEGIN_FACTORY_BY_ORDER_DOWNLOAD,
  payload: true,
  factoryOrder: item,
});

export const downloadFactoryByOrdersOkAction = (items) => ({
  type: FACTORY_BY_ORDER_DOWNLOAD_OK,
  payload: items,
});

export const downloadFactoryByOrdersErrorAction = () => ({
  type: FACTORY_BY_ORDER_DOWNLOAD_ERROR,
  payload: true,
});

export const addFactoryOrderAction = (item) => ({
  type: ADD_FACTORY_ORDERS,
  payload: true,
  factoryOrder: item,
});

export const addFactoryOrderOkAction = (items) => ({
  type: ADD_FACTORY_ORDERS_OK,
  payload: items,
});

export const addFactoryOrderErrorAction = (state) => ({
  type: ADD_FACTORY_ORDERS_ERROR,
  payload: state,
});

export const deleteFactoryOrderAction = (id) => ({
  type: RETRIEVE_FACTORY_ORDERS_DELETE,
  payload: id,
});

export const deleteFactoryOrderOkAction = () => ({
  type: FACTORY_ORDERS_DELETED_OK,
});

export const deleteFactoryOrderErrorAction = () => ({
  type: FACTORY_ORDERS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteFactoryOrderAction = (item) => ({
  type: RETRIEVE_FACTORY_ORDERS_BULK_DELETE,
  payload: true,
  factoryOrder: item,
});

export const bulkDeleteFactoryOrderOkAction = () => ({
  type: FACTORY_ORDERS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteFactoryOrderErrorAction = (state) => ({
  type: FACTORY_ORDERS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveFactoryOrderAction = (item) => ({
  type: RETRIEVE_FACTORY_ORDERS_EDIT,
  payload: item,
});

export const editFactoryOrderAction = (item) => ({
  type: BEGIN_EDIT_FACTORY_ORDERS,
  factoryOrder: item,
});

export const editFactoryOrderOkAction = (item) => ({
  type: FACTORY_ORDERS_EDITED_OK,
  payload: item,
});

export const editFactoryOrderErrorAction = () => ({
  type: FACTORY_ORDERS_EDITED_ERROR,
  payload: true,
});
