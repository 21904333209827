import {
  ADD_FACTORY_ORDERS,
  ADD_FACTORY_ORDERS_OK,
  ADD_FACTORY_ORDERS_ERROR,
  BEGIN_FACTORY_ORDERS_DOWNLOAD,
  FACTORY_ORDERS_DOWNLOAD_OK,
  FACTORY_ORDERS_DOWNLOAD_ERROR,
  RETRIEVE_FACTORY_ORDERS_DELETE,
  FACTORY_ORDERS_DELETED_OK,
  FACTORY_ORDERS_DELETED_ERROR,
  RETRIEVE_FACTORY_ORDERS_EDIT,
  BEGIN_EDIT_FACTORY_ORDERS,
  FACTORY_ORDERS_EDITED_OK,
  FACTORY_ORDERS_EDITED_ERROR,
  RETRIEVE_FACTORY_ORDERS_BULK_DELETE,
  FACTORY_ORDERS_BULK_DELETED_OK,
  FACTORY_ORDERS_BULK_DELETED_ERROR,
  BEGIN_FACTORY_BY_ORDER_DOWNLOAD,
  FACTORY_BY_ORDER_DOWNLOAD_OK,
  FACTORY_BY_ORDER_DOWNLOAD_ERROR,
} from "../../constants/modules/factoryOrdersConstants";

const initialState = {
  factoryOrders: [],
  factoryByOrder: [],
  factoryAddInfo: null,
  error: false,
  loading: false,
  deleteFactoryOrder: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_FACTORY_ORDERS_DOWNLOAD:
    case BEGIN_FACTORY_BY_ORDER_DOWNLOAD:
    case ADD_FACTORY_ORDERS:
    case RETRIEVE_FACTORY_ORDERS_BULK_DELETE:
    case BEGIN_EDIT_FACTORY_ORDERS:
      return {
        ...state,
        loading: action.payload,
        factoryOrder: action.factoryOrder,
      };

    case FACTORY_ORDERS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_FACTORY_ORDERS_ERROR:
    case FACTORY_ORDERS_DOWNLOAD_ERROR:
    case FACTORY_ORDERS_DELETED_ERROR:
    case FACTORY_BY_ORDER_DOWNLOAD_ERROR:
    case FACTORY_ORDERS_BULK_DELETED_ERROR:
    case FACTORY_ORDERS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FACTORY_ORDERS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        factoryOrders: action.payload,
      };

    case ADD_FACTORY_ORDERS_OK:
      return {
        ...state,
        loading: false,
        error: null,
        factoryAddInfo: action.payload,
      };

    case FACTORY_BY_ORDER_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        factoryByOrder: action.payload,
      };

    case RETRIEVE_FACTORY_ORDERS_DELETE:
      return {
        ...state,
        deleteBank: action.payload,
      };

    case FACTORY_ORDERS_DELETED_OK:
      return {
        ...state,
        factoryOrders: state.factoryOrders.data.filter(
          (factoryOrder) => factoryOrder.id !== state.deleteFactoryOrder
        ),
        deleteFactoryOrder: null,
      };

    case RETRIEVE_FACTORY_ORDERS_EDIT:
      return {
        ...state,
        editFactoryOrder: action.payload,
      };

    case FACTORY_ORDERS_EDITED_OK:
      return {
        ...state,
        editFactoryOrder: null,
        factoryOrders: state.factoryOrders.data.map((factoryOrder) =>
          factoryOrder.id === action.payload.id
            ? (factoryOrder = action.payload)
            : factoryOrder
        ),
      };

    default:
      return state;
  }
}
