import {
  ADD_SAMPLE_COMMENTS,
  ADD_SAMPLE_COMMENTS_ERROR,
  ADD_SAMPLE_COMMENTS_OK,
  BEGIN_EDIT_SAMPLE_COMMENTS,
  BEGIN_SAMPLE_COMMENTS_DOWNLOAD,
  RETRIEVE_SAMPLE_COMMENTS_BULK_DELETE,
  RETRIEVE_SAMPLE_COMMENTS_DELETE,
  RETRIEVE_SAMPLE_COMMENTS_EDIT,
  SAMPLE_COMMENTS_BULK_DELETED_ERROR,
  SAMPLE_COMMENTS_BULK_DELETED_OK,
  SAMPLE_COMMENTS_DELETED_ERROR,
  SAMPLE_COMMENTS_DELETED_OK,
  SAMPLE_COMMENTS_DOWNLOAD_ERROR,
  SAMPLE_COMMENTS_DOWNLOAD_OK,
  SAMPLE_COMMENTS_EDITED_ERROR,
  SAMPLE_COMMENTS_EDITED_OK,
} from "../../constants/modules/sampleCommentsConstant";

const initialState = {
  sampleComments: [],
  error: false,
  loading: false,
  deleteSampleComment: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_SAMPLE_COMMENTS_DOWNLOAD:
    case RETRIEVE_SAMPLE_COMMENTS_BULK_DELETE:
    case ADD_SAMPLE_COMMENTS:
    case BEGIN_EDIT_SAMPLE_COMMENTS:
      return {
        ...state,
        loading: action.payload,
        sampleComment: action.sampleComment,
      };

    case ADD_SAMPLE_COMMENTS_OK:
    case SAMPLE_COMMENTS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_SAMPLE_COMMENTS_ERROR:
    case SAMPLE_COMMENTS_DOWNLOAD_ERROR:
    case SAMPLE_COMMENTS_BULK_DELETED_ERROR:
    case SAMPLE_COMMENTS_DELETED_ERROR:
    case SAMPLE_COMMENTS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SAMPLE_COMMENTS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        sampleComments: action.payload,
      };

    case RETRIEVE_SAMPLE_COMMENTS_DELETE:
      return {
        ...state,
        deleteSampleComment: action.payload,
      };

    case SAMPLE_COMMENTS_DELETED_OK:
      return {
        ...state,
        sampleComments: state.sampleComments.data.filter(
          (sampleComment) => sampleComment.id !== state.deleteSampleComment
        ),
        deleteSampleComment: null,
      };

    case RETRIEVE_SAMPLE_COMMENTS_EDIT:
      return {
        ...state,
        editSampleComment: action.payload,
      };

    case SAMPLE_COMMENTS_EDITED_OK:
      return {
        ...state,
        editSampleComment: null,
        sampleComments: state.sampleComments.data.map((sampleComment) =>
          sampleComment.id === action.payload.id
            ? (sampleComment = action.payload)
            : sampleComment
        ),
      };

    default:
      return state;
  }
}
