import {
  ADD_BUYER_TERMS,
  ADD_BUYER_TERMS_ERROR,
  ADD_BUYER_TERMS_OK,
  BEGIN_BUYER_TERMS_DOWNLOAD,
  BEGIN_EDIT_BUYER_TERMS,
  BUYER_TERMS_BULK_DELETED_ERROR,
  BUYER_TERMS_BULK_DELETED_OK,
  BUYER_TERMS_DELETED_ERROR,
  BUYER_TERMS_DELETED_OK,
  BUYER_TERMS_DOWNLOAD_ERROR,
  BUYER_TERMS_DOWNLOAD_OK,
  BUYER_TERMS_EDITED_ERROR,
  BUYER_TERMS_EDITED_OK,
  RETRIEVE_BUYER_TERMS_BULK_DELETE,
  RETRIEVE_BUYER_TERMS_DELETE,
  RETRIEVE_BUYER_TERMS_EDIT
} from "../../constants/modules/buyerTermsConstants";

export const downloadBuyerTermsAction = () => ({
  type: BEGIN_BUYER_TERMS_DOWNLOAD,
  payload: true,
});

export const downloadBuyerTermsOkAction = (items) => ({
  type: BUYER_TERMS_DOWNLOAD_OK,
  payload: items,
});

export const downloadBuyerTermsErrorAction = () => ({
  type: BUYER_TERMS_DOWNLOAD_ERROR,
  payload: true,
});

export const addBuyerTermAction = (item) => ({
  type: ADD_BUYER_TERMS,
  payload: true,
  buyerTerm: item,
});

export const addBuyerTermOkAction = () => ({
  type: ADD_BUYER_TERMS_OK,
  payload: true,
});

export const addBuyerTermErrorAction = (state) => ({
  type: ADD_BUYER_TERMS_ERROR,
  payload: state,
});

export const deleteBuyerTermAction = (id) => ({
  type: RETRIEVE_BUYER_TERMS_DELETE,
  payload: id,
});

export const deleteBuyerTermOkAction = () => ({
  type: BUYER_TERMS_DELETED_OK,
});

export const deleteBuyerTermErrorAction = () => ({
  type: BUYER_TERMS_DELETED_ERROR,
  payload: true,
});

export const retrieveBuyerTermAction = (item) => ({
  type: RETRIEVE_BUYER_TERMS_EDIT,
  payload: item,
});

export const editBuyerTermAction = (item) => ({
  type: BEGIN_EDIT_BUYER_TERMS,
  buyerTerm: item,
});

export const editBuyerTermOkAction = (item) => ({
  type: BUYER_TERMS_EDITED_OK,
  payload: item,
});

export const editBuyerTermErrorAction = () => ({
  type: BUYER_TERMS_EDITED_ERROR,
  payload: true,
});


export const bulkDeleteBuyerTermAction = (item) => ({
  type: RETRIEVE_BUYER_TERMS_BULK_DELETE,
  payload: true,
  buyerTerm: item,
});

export const bulkDeleteBuyerTermOkAction = () => ({
  type: BUYER_TERMS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteBuyerTermErrorAction = (state) => ({
  type: BUYER_TERMS_BULK_DELETED_ERROR,
  payload: state,
});