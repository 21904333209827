export const ADD_ACCOUNTS = "ADD_ACCOUNTS";
export const ADD_ACCOUNTS_OK = "ADD_ACCOUNTS_OK";
export const ADD_ACCOUNTS_ERROR = "ADD_ACCOUNTS_ERROR";

export const BEGIN_ACCOUNTS_DOWNLOAD = "BEGIN_ACCOUNTS_DOWNLOAD";
export const ACCOUNTS_DOWNLOAD_OK = "ACCOUNTS_DOWNLOAD_OK";
export const ACCOUNTS_DOWNLOAD_ERROR = "ACCOUNTS_DOWNLOAD_ERROR";

export const RETRIEVE_ACCOUNTS_DELETE = "RETRIEVE_ACCOUNTS_DELETE";
export const ACCOUNTS_DELETED_OK = "ACCOUNTS_DELETED_OK";
export const ACCOUNTS_DELETED_ERROR = "ACCOUNTS_DELETED_ERROR";

export const RETRIEVE_ACCOUNTS_EDIT = "RETRIEVE_ACCOUNTS_EDITED";
export const BEGIN_EDIT_ACCOUNTS = "BEGIN_EDIT_ACCOUNTS";
export const ACCOUNTS_EDITED_OK = "ACCOUNTS_EDITED_OK";
export const ACCOUNTS_EDITED_ERROR = "ACCOUNTS_EDITED_ERROR";

export const RETRIEVE_ACCOUNTS_BULK_DELETE = "RETRIEVE_ACCOUNTS_BULK_DELETE";
export const ACCOUNTS_BULK_DELETED_OK = "ACCOUNTS_BULK_DELETED_OK";
export const ACCOUNTS_BULK_DELETED_ERROR = "ACCOUNTS_BULK_DELETED_ERROR";