import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    ADD_DISCOUNTS, BEGIN_DISCOUNTS_DOWNLOAD, BEGIN_DISCOUNT_DOWNLOAD, BEGIN_EDIT_DISCOUNTS, RETRIEVE_DISCOUNTS_BULK_DELETE,
    RETRIEVE_DISCOUNTS_DELETE
} from "../../constants/modules/discountsContants";

import {
    addDiscountErrorAction,
    addDiscountOkAction,
    bulkDeleteDiscountErrorAction,
    bulkDeleteDiscountOkAction,
    deleteDiscountErrorAction,
    deleteDiscountOkAction,
    downloadDiscountErrorAction,
    downloadDiscountOkAction,
    downloadDiscountsErrorAction,
    editDiscountErrorAction,
    editDiscountOkAction,
    downloadDiscountsOkAction
} from "../../actions/modules/discountsActions";

import {
    addDiscountDB,
    bulkDeleteDiscountDB,
    deleteDiscountDB,
    editDiscountDB,
    retrieveDiscountData,
    retrieveDiscountsData 
} from "../../../config/apiCalls/discountAPICall";



// Retrieve products
// worker saga
export function* retrieveDiscounts() {
  try {
    const response = yield call(retrieveDiscountsData);

    if (response.status === 200) {
      yield put(downloadDiscountsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadDiscountsErrorAction());
  }
}

export function* retrieveDiscount(action) {
  const discount = action.discount;
  try {
    const response = yield call(retrieveDiscountData, discount);
    if (response.status === 200) {
      yield put(downloadDiscountOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadDiscountErrorAction());
  }
}

// Create new product
// worker saga
export function* addDiscount(action) {
  const discount = action.discount;
  try {
    const response = yield call(addDiscountDB, discount);
    if (response.status === 200) {
      yield delay(500);
      yield put(addDiscountOkAction(response.data));
      toast.success("Discount created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addDiscountErrorAction(true));
    toast.error("Discount created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteDiscount(action) {
  const id = action.payload;
  try {
    yield call(deleteDiscountDB, id);
    yield deleteDiscountOkAction();
    toast.success("Discount deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteDiscountErrorAction();
  }
}

// Edit product
// worker saga
export function* editDiscount(action) {
  const discount = action.discount;

  try {
    yield call(editDiscountDB, discount);
    yield editDiscountOkAction(discount);
    // Alert
    // Alert
    toast.success("Discount has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editDiscountErrorAction();
  }
}

// BULK SAGAS
export function* bulkDiscountDelete(action) {
  const discount = action.discount;

  try {
    const response = yield call(bulkDeleteDiscountDB, discount);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteDiscountOkAction(discount));
      // toast.success("Tna Actions deleted successfully!", {
      toast.success("Bulk Discount deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteDiscountErrorAction(true));
    // toast.error("Tna Actions deleted failed!", {
    toast.error("Bulk Discount deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchDiscountsAsync() {
  yield takeLatest(BEGIN_DISCOUNTS_DOWNLOAD, retrieveDiscounts);
  yield takeLatest(BEGIN_DISCOUNT_DOWNLOAD, retrieveDiscount);
  yield takeLatest(ADD_DISCOUNTS, addDiscount);
  yield takeLatest(RETRIEVE_DISCOUNTS_DELETE, deleteDiscount);
  yield takeLatest(BEGIN_EDIT_DISCOUNTS, editDiscount);
  yield takeLatest(
    RETRIEVE_DISCOUNTS_BULK_DELETE,
    bulkDiscountDelete
  );
}
