export const ADD_LC_HEADER_DETAILS = "ADD_LC_HEADER_DETAILS";
export const ADD_LC_HEADER_DETAILS_OK = "ADD_LC_HEADER_DETAILS_OK";
export const ADD_LC_HEADER_DETAILS_ERROR =
  "ADD_LC_HEADER_DETAILS_ERROR";

export const BEGIN_LC_HEADER_DETAILS_DOWNLOAD =
  "BEGIN_LC_HEADER_DETAILS_DOWNLOAD";
export const LC_HEADER_DETAILS_DOWNLOAD_OK =
  "LC_HEADER_DETAILS_DOWNLOAD_OK";
export const LC_HEADER_DETAILS_DOWNLOAD_ERROR =
  "LC_HEADER_DETAILS_DOWNLOAD_ERROR";

export const RETRIEVE_LC_HEADER_DETAILS_DELETE =
  "RETRIEVE_LC_HEADER_DETAILS_DELETE";
export const LC_HEADER_DETAILS_DELETED_OK =
  "LC_HEADER_DETAILS_DELETED_OK";
export const LC_HEADER_DETAILS_DELETED_ERROR =
  "LC_HEADER_DETAILS_DELETED_ERROR";

export const RETRIEVE_LC_HEADER_DETAILS_EDIT =
  "RETRIEVE_LC_HEADER_DETAILS_EDITED";
export const BEGIN_EDIT_LC_HEADER_DETAILS =
  "BEGIN_EDIT_LC_HEADER_DETAILS";
export const LC_HEADER_DETAILS_EDITED_OK =
  "LC_HEADER_DETAILS_EDITED_OK";
export const LC_HEADER_DETAILS_EDITED_ERROR =
  "LC_HEADER_DETAILS_EDITED_ERROR";


