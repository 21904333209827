import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*              TNA ACTIONS API CALLS              */
/*-------------------------------------------------*/
export async function bulkDeleteTnaActionDB(ids) {
    return await axiosClient.post(`/tna_actions/bulk_delete`, ids);
  }
  
  export async function retrieveTnaActionsData() {
    return await axiosClient.get("/tna_actions");
  }
  
  export async function addTnaActionDB(value) {
    return await axiosClient.post("/tna_actions", value);
  }
  
  export async function deleteTnaActionDB(id) {
    return await axiosClient.delete(`/tna_actions/${id}`);
  }
  
  export async function editTnaActionDB(value) {
    return await axiosClient.put(`/tna_actions/${value.id}`, value);
  }