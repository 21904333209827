import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*              FREIGHT TERMS API CALLS            */
/*-------------------------------------------------*/
export async function bulkDeleteFreightTermDB(ids) {
    return await axiosClient.post(`/freight_terms/bulk_delete`, ids);
  }
  
  export async function retrieveFreightTermsData() {
    return await axiosClient.get("/freight_terms");
  }
  
  export async function addFreightTermDB(value) {
    return await axiosClient.post("/freight_terms", value);
  }
  
  export async function deleteFreightTermDB(id) {
    return await axiosClient.delete(`/freight_terms/${id}`);
  }
  
  export async function editFreightTermDB(value) {
    return await axiosClient.put(`/freight_terms/${value.id}`, value);
  }