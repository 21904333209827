import {
  ADD_TNA_ACTIONS,
  ADD_TNA_ACTIONS_ERROR,
  ADD_TNA_ACTIONS_OK,
  BEGIN_EDIT_TNA_ACTIONS,
  BEGIN_TNA_ACTIONS_DOWNLOAD,
  RETRIEVE_TNA_ACTIONS_BULK_DELETE,
  RETRIEVE_TNA_ACTIONS_DELETE,
  RETRIEVE_TNA_ACTIONS_EDIT,
  TNA_ACTIONS_BULK_DELETED_ERROR,
  TNA_ACTIONS_BULK_DELETED_OK,
  TNA_ACTIONS_DELETED_ERROR,
  TNA_ACTIONS_DELETED_OK,
  TNA_ACTIONS_DOWNLOAD_ERROR,
  TNA_ACTIONS_DOWNLOAD_OK,
  TNA_ACTIONS_EDITED_ERROR,
  TNA_ACTIONS_EDITED_OK,
} from "../../constants/modules/tnaActionsConstants.js";

const initialState = {
  tnaActions: [],
  error: false,
  loading: false,
  deleteTnaAction: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_TNA_ACTIONS_DOWNLOAD:
    case RETRIEVE_TNA_ACTIONS_BULK_DELETE:
    case ADD_TNA_ACTIONS:
    case BEGIN_EDIT_TNA_ACTIONS:
      return {
        ...state,
        loading: action.payload,
        tnaAction: action.tnaAction,
      };

    case ADD_TNA_ACTIONS_OK:
    case TNA_ACTIONS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_TNA_ACTIONS_ERROR:
    case TNA_ACTIONS_DOWNLOAD_ERROR:
    case TNA_ACTIONS_BULK_DELETED_ERROR:
    case TNA_ACTIONS_DELETED_ERROR:
    case TNA_ACTIONS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TNA_ACTIONS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        tnaActions: action.payload,
      };

    case RETRIEVE_TNA_ACTIONS_DELETE:
      return {
        ...state,
        deleteTnaAction: action.payload,
      };

    case TNA_ACTIONS_DELETED_OK:
      return {
        ...state,
        tnaActions: state.tnaActions.data.filter(
          (tnaAction) => tnaAction.id !== state.deleteTnaAction
        ),
        deleteTnaAction: null,
      };

    case RETRIEVE_TNA_ACTIONS_EDIT:
      return {
        ...state,
        editTnaAction: action.payload,
      };

    case TNA_ACTIONS_EDITED_OK:
      return {
        ...state,
        editTnaAction: null,
        tnaActions: state.tnaActions.data.map((tnaAction) =>
          tnaAction.id === action.payload.id
            ? (tnaAction = action.payload)
            : tnaAction
        ),
      };

    default:
      return state;
  }
}
