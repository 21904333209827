import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_FABRICS,
  BEGIN_EDIT_FABRICS,
  BEGIN_FABRICS_BY_FK_DOWNLOAD,
  BEGIN_FABRICS_DOWNLOAD,
  RETRIEVE_FABRICS_BULK_DELETE,
  RETRIEVE_FABRICS_DELETE
} from "../../constants/modules/fabricsConstants";

import {
  addFabricErrorAction,
  addFabricOkAction,
  bulkDeleteFabricErrorAction,
  bulkDeleteFabricOkAction,
  deleteFabricErrorAction,
  deleteFabricOkAction,
  downloadFabricByFKErrorAction,
  downloadFabricByFKOkAction,
  downloadFabricsErrorAction,
  downloadFabricsOkAction,
  editFabricErrorAction,
  editFabricOkAction
} from "../../actions/modules/fabricsActions";

import {
  addFabricDB,
  bulkDeleteFabricDB,
  deleteFabricDB,
  editFabricDB,
  retrieveFabricsByFkDB,
  retrieveFabricsData
} from "../../../config/apiCalls/fabrics";

// Retrieve products
// worker saga
export function* retrieveFabrics() {
  try {
    const response = yield call(retrieveFabricsData);

    if (response.status === 200) {
      yield put(downloadFabricsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFabricsErrorAction());
  }
}

export function* retrieveFabricsByFK(action) {
  const fabric = action.fabric;

  try {
    const response = yield call(retrieveFabricsByFkDB, fabric);

    if (response.status === 200) {
      yield put(downloadFabricByFKOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFabricByFKErrorAction());
  }
}

// Create new product
// worker saga
export function* addFabric(action) {
  const fabric = action.fabric;

  try {
    const response = yield call(addFabricDB, fabric);

    if (response.status === 201) {
      yield delay(2000);

      yield put(addFabricOkAction(fabric));
      toast.success("Fabric created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addFabricErrorAction(true));
    toast.error("Fabric created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkFabricDelete(action) {
  const fabric = action.fabric;

  try {
    const response = yield call(bulkDeleteFabricDB, fabric);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteFabricOkAction(fabric));
      toast.success("Bulk Fabric deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteFabricErrorAction(true));
    toast.error("Bulk Fabric deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteFabric(action) {
  const id = action.payload;
  try {
    yield call(deleteFabricDB, id);
    yield deleteFabricOkAction();
    toast.success("Fabric deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteFabricErrorAction();
  }
}

// Edit product
// worker saga
export function* editFabric(action) {
  const fabric = action.fabric;
 console.log("update-date", fabric)
  try {
    yield call(editFabricDB, fabric);
    yield editFabricOkAction(fabric);
    // Alert
    toast.success("Fabric has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editFabricErrorAction();
  }
}

export function* watchFabricsAsync() {
  yield takeLatest(BEGIN_FABRICS_DOWNLOAD, retrieveFabrics);
  yield takeLatest(BEGIN_FABRICS_BY_FK_DOWNLOAD, retrieveFabricsByFK);
  yield takeLatest(ADD_FABRICS, addFabric);
  yield takeLatest(RETRIEVE_FABRICS_DELETE, deleteFabric);
  yield takeLatest(BEGIN_EDIT_FABRICS, editFabric);
  yield takeLatest(RETRIEVE_FABRICS_BULK_DELETE, bulkFabricDelete);
}
