import {
  ADD_TEAMS,
  ADD_TEAMS_ERROR,
  ADD_TEAMS_OK,
  BEGIN_EDIT_TEAMS,
  BEGIN_TEAMS_DOWNLOAD,
  RETRIEVE_TEAMS_BULK_DELETE,
  RETRIEVE_TEAMS_DELETE,
  RETRIEVE_TEAMS_EDIT,
  TEAMS_BULK_DELETED_ERROR,
  TEAMS_BULK_DELETED_OK,
  TEAMS_DELETED_ERROR,
  TEAMS_DELETED_OK,
  TEAMS_DOWNLOAD_ERROR,
  TEAMS_DOWNLOAD_OK,
  TEAMS_EDITED_ERROR,
  TEAMS_EDITED_OK,
  BEGIN_TEAMS_BY_BUYER_DOWNLOAD,
  TEAMS_BY_BUYER_DOWNLOAD_OK,
  TEAMS_BY_BUYER_DOWNLOAD_ERROR,
} from "../../constants/modules/teamConstants.js";

const initialState = {
  teams: [],
  error: false,
  loading: false,
  deleteTeam: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_TEAMS_DOWNLOAD:
    case BEGIN_TEAMS_BY_BUYER_DOWNLOAD:
    case RETRIEVE_TEAMS_BULK_DELETE:
    case ADD_TEAMS:
    case BEGIN_EDIT_TEAMS:
      return {
        ...state,
        loading: action.payload,
        team: action.team,
      };

    case ADD_TEAMS_OK:
    case TEAMS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_TEAMS_ERROR:
    case TEAMS_DOWNLOAD_ERROR:
    case TEAMS_BY_BUYER_DOWNLOAD_ERROR:
    case TEAMS_BULK_DELETED_ERROR:
    case TEAMS_DELETED_ERROR:
    case TEAMS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TEAMS_DOWNLOAD_OK:
    case TEAMS_BY_BUYER_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        teams: action.payload,
      };

    case RETRIEVE_TEAMS_DELETE:
      return {
        ...state,
        deleteTeam: action.payload,
      };

    case TEAMS_DELETED_OK:
      return {
        ...state,
        teams: state.teams.data.filter((team) => team.id !== state.deleteTeam),
        deleteTeam: null,
      };

    case RETRIEVE_TEAMS_EDIT:
      return {
        ...state,
        editTeam: action.payload,
      };

    case TEAMS_EDITED_OK:
      return {
        ...state,
        editTeam: null,
        teams: state.teams.data.map((team) =>
          team.id === action.payload.id ? (team = action.payload) : team
        ),
      };

    default:
      return state;
  }
}
