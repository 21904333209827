export const ADD_BUYER_BRANDS = "ADD_BUYER_BRANDS";
export const ADD_BUYER_BRANDS_OK = "ADD_BUYER_BRANDS_OK";
export const ADD_BUYER_BRANDS_ERROR = "ADD_BUYER_BRANDS_ERROR";

export const BEGIN_BUYER_BRANDS_DOWNLOAD = "BEGIN_BUYER_BRANDS_DOWNLOAD";
export const BUYER_BRANDS_DOWNLOAD_OK = "BUYER_BRANDS_DOWNLOAD_OK";
export const BUYER_BRANDS_DOWNLOAD_ERROR = "BUYER_BRANDS_DOWNLOAD_ERROR";

export const BEGIN_BUYER_BRANDS_BY_BUYER_DOWNLOAD =
  "BEGIN_BUYER_BRANDS_BY_BUYER_DOWNLOAD";
export const BUYER_BRANDS_BY_BUYER_DOWNLOAD_OK =
  "BUYER_BRANDS_BY_BUYER_DOWNLOAD_OK";
export const BUYER_BRANDS_BY_BUYER_DOWNLOAD_ERROR =
  "BUYER_BRANDS_BY_BUYER_DOWNLOAD_ERROR";

export const RETRIEVE_BUYER_BRANDS_EDIT = "RETRIEVE_BUYER_BRANDS_EDITED";
export const BEGIN_EDIT_BUYER_BRANDS = "BEGIN_EDIT_BUYER_BRANDS";
export const BUYER_BRANDS_EDITED_OK = "BUYER_BRANDS_EDITED_OK";
export const BUYER_BRANDS_EDITED_ERROR = "BUYER_BRANDS_EDITED_ERROR";

export const RETRIEVE_BUYER_BRANDS_DELETE = "RETRIEVE_BUYER_BRANDS_DELETE";
export const BUYER_BRANDS_DELETED_OK = "BUYER_BRANDS_DELETED_OK";
export const BUYER_BRANDS_DELETED_ERROR = "BUYER_BRANDS_DELETED_ERROR";

export const RETRIEVE_BUYER_BRANDS_BULK_DELETE =
  "RETRIEVE_BUYER_BRANDS_BULK_DELETE";
export const BUYER_BRANDS_BULK_DELETED_OK = "BUYER_BRANDS_BULK_DELETED_OK";
export const BUYER_BRANDS_BULK_DELETED_ERROR = "BUYERS_BULK_DELETED_ERROR";
