import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_USER_LEVELS,
  BEGIN_EDIT_USER_LEVELS,
  BEGIN_USER_LEVELS_DOWNLOAD,
  RETRIEVE_USER_LEVELS_BULK_DELETE,
  RETRIEVE_USER_LEVELS_DELETE
} from "../../constants/modules/userLevelsConstant";

import {
  addUserLevelErrorAction,
  addUserLevelOkAction,
  bulkDeleteUserLevelErrorAction,
  bulkDeleteUserLevelOkAction,
  deleteUserLevelErrorAction,
  deleteUserLevelOkAction,
  downloadUserLevelsErrorAction,
  downloadUserLevelsOkAction,
  editUserLevelErrorAction,
  editUserLevelOkAction
} from "../../actions/modules/userLevelsAction";

import {
  addUserLevelDB,
  bulkDeleteUserLevelDB,
  deleteUserLevelDB,
  editUserLevelDB,
  retrieveUserLevelsData
} from "../../../config/apiCalls/userLevesAPICall";

// Retrieve products
// worker saga
export function* retrieveUserLevels() {
  try {
    const response = yield call(retrieveUserLevelsData);
    console.log("response$$$$$ : ", response);
    if (response.status === 200) {
      yield put(downloadUserLevelsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadUserLevelsErrorAction());
  }
}

// Create new product
// worker saga
export function* addUserLevel(action) {
  const userLevel = action.userLevel;

  try {
    const response = yield call(addUserLevelDB, userLevel);

    if (response.status === 201) {
      yield delay(500);

      yield put(addUserLevelOkAction(userLevel));
      toast.success("User Level created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addUserLevelErrorAction(true));
    toast.error("User Level created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteUserLevel(action) {
  const id = action.payload;
  try {
    yield call(deleteUserLevelDB, id);
    yield deleteUserLevelOkAction();
    toast.success("User Level deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteUserLevelErrorAction();
  }
}

// Edit product
// worker saga
export function* editUserLevel(action) {
  const userLevel = action.userLevel;

  try {
    yield call(editUserLevelDB, userLevel);
    yield editUserLevelOkAction(userLevel);
    // Alert
    // Alert
    toast.success("User Level has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editUserLevelErrorAction();
  }
}

// BULK SAGAS
export function* bulkUserLevelDelete(action) {
  const userLevel = action.userLevel;

  try {
    const response = yield call(bulkDeleteUserLevelDB, userLevel);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteUserLevelOkAction(userLevel));
      toast.success("Bulk User Level deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteUserLevelErrorAction(true));
    toast.error("Bulk User Level deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchUserLevelsAsync() {
  yield takeLatest(BEGIN_USER_LEVELS_DOWNLOAD, retrieveUserLevels);
  yield takeLatest(ADD_USER_LEVELS, addUserLevel);
  yield takeLatest(RETRIEVE_USER_LEVELS_DELETE, deleteUserLevel);
  yield takeLatest(BEGIN_EDIT_USER_LEVELS, editUserLevel);
  yield takeLatest(RETRIEVE_USER_LEVELS_BULK_DELETE, bulkUserLevelDelete);
}
