import {
  ADD_LC_HEADERS,
  ADD_LC_HEADERS_ERROR,
  ADD_LC_HEADERS_OK,
  BEGIN_EDIT_LC_HEADERS,
  BEGIN_LC_HEADERS_DOWNLOAD,
  BEGIN_LC_HEADER_DOWNLOAD,
  LC_HEADERS_BULK_DELETED_ERROR,
  LC_HEADERS_BULK_DELETED_OK,
  LC_HEADERS_DELETED_ERROR,
  LC_HEADERS_DELETED_OK,
  LC_HEADERS_DOWNLOAD_ERROR,
  LC_HEADERS_DOWNLOAD_OK,
  LC_HEADERS_EDITED_ERROR,
  LC_HEADERS_EDITED_OK,
  LC_HEADER_DOWNLOAD_ERROR,
  LC_HEADER_DOWNLOAD_OK,
  RESET,
  RETRIEVE_LC_HEADERS_BULK_DELETE,
  RETRIEVE_LC_HEADERS_DELETE,
  RETRIEVE_LC_HEADERS_EDIT,
} from "../../constants/modules/lcHeaderConstants";

export const bulkDeleteLCHeaderAction = (item) => ({
  type: RETRIEVE_LC_HEADERS_BULK_DELETE,
  payload: true,
  lcHeader: item,
});

export const bulkDeleteLCHeaderOkAction = () => ({
  type: LC_HEADERS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteLCHeaderErrorAction = (state) => ({
  type: LC_HEADERS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadLCHeadersAction = (item) => ({
  type: BEGIN_LC_HEADERS_DOWNLOAD,
  payload: true,
  lcHeader: item,
});

export const downloadLCHeadersOkAction = (items) => ({
  type: LC_HEADERS_DOWNLOAD_OK,
  payload: items,
});

export const downloadLCHeadersErrorAction = () => ({
  type: LC_HEADERS_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadLCHeaderAction = (item) => ({
  type: BEGIN_LC_HEADER_DOWNLOAD,
  payload: true,
  lcHeader: item,
});

export const downloadLCHeaderOkAction = (items) => ({
  type: LC_HEADER_DOWNLOAD_OK,
  payload: items,
});

export const downloadLCHeaderErrorAction = () => ({
  type: LC_HEADER_DOWNLOAD_ERROR,
  payload: true,
});

export const addLCHeaderAction = (item) => ({
  type: ADD_LC_HEADERS,
  payload: true,
  lcHeader: item,
});

export const addLCHeaderOkAction = (items) => ({
  type: ADD_LC_HEADERS_OK,
  payload: items,
});

export const addLCHeaderErrorAction = (state) => ({
  type: ADD_LC_HEADERS_ERROR,
  payload: state,
});

export const deleteLCHeaderAction = (id) => ({
  type: RETRIEVE_LC_HEADERS_DELETE,
  payload: id,
});

export const deleteLCHeaderOkAction = () => ({
  type: LC_HEADERS_DELETED_OK,
});

export const deleteLCHeaderErrorAction = () => ({
  type: LC_HEADERS_DELETED_ERROR,
  payload: true,
});

export const retrieveLCHeaderAction = (item) => ({
  type: RETRIEVE_LC_HEADERS_EDIT,
  payload: item,
});

export const editLCHeaderAction = (item) => ({
  type: BEGIN_EDIT_LC_HEADERS,
  lcHeader: item,
});

export const editLCHeaderOkAction = (item) => ({
  type: LC_HEADERS_EDITED_OK,
  payload: item,
});

export const editLCHeaderErrorAction = () => ({
  type: LC_HEADERS_EDITED_ERROR,
  payload: true,
});

export const LCHeaderReset = () => ({
  type: RESET,
  payload: true,
});
