export const ADD_PROCEED_REALIZATIONS = "ADD_PROCEED_REALIZATIONS";
export const ADD_PROCEED_REALIZATIONS_OK = "ADD_PROCEED_REALIZATIONS_OK";
export const ADD_PROCEED_REALIZATIONS_ERROR = "ADD_PROCEED_REALIZATIONS_ERROR";

export const BEGIN_PROCEED_REALIZATIONS_DOWNLOAD = "BEGIN_PROCEED_REALIZATIONS_DOWNLOAD";
export const PROCEED_REALIZATIONS_DOWNLOAD_OK = "PROCEED_REALIZATIONS_DOWNLOAD_OK";
export const PROCEED_REALIZATIONS_DOWNLOAD_ERROR = "PROCEED_REALIZATIONS_DOWNLOAD_ERROR";

export const BEGIN_PROCEED_REALIZATION_DOWNLOAD =
  "BEGIN_PROCEED_REALIZATION_DOWNLOAD";
export const PROCEED_REALIZATION_DOWNLOAD_OK = "PROCEED_REALIZATION_DOWNLOAD_OK";
export const PROCEED_REALIZATION_DOWNLOAD_ERROR =
  "PROCEED_REALIZATION_DOWNLOAD_ERROR";

export const RETRIEVE_PROCEED_REALIZATIONS_EDIT = "RETRIEVE_PROCEED_REALIZATIONS_EDITED";
export const BEGIN_EDIT_PROCEED_REALIZATIONS = "BEGIN_EDIT_PROCEED_REALIZATIONS";
export const PROCEED_REALIZATIONS_EDITED_OK = "PROCEED_REALIZATIONS_EDITED_OK";
export const PROCEED_REALIZATIONS_EDITED_ERROR = "PROCEED_REALIZATIONS_EDITED_ERROR";

export const RETRIEVE_PROCEED_REALIZATIONS_DELETE = "RETRIEVE_PROCEED_REALIZATIONS_DELETE";
export const PROCEED_REALIZATIONS_DELETED_OK = "PROCEED_REALIZATIONS_DELETED_OK";
export const PROCEED_REALIZATIONS_DELETED_ERROR = "PROCEED_REALIZATIONS_DELETED_ERROR";

export const RETRIEVE_PROCEED_REALIZATIONS_BULK_DELETE =
  "RETRIEVE_PROCEED_REALIZATIONS_BULK_DELETE";
export const PROCEED_REALIZATIONS_BULK_DELETED_OK = "PROCEED_REALIZATIONS_BULK_DELETED_OK";
export const PROCEED_REALIZATIONS_BULK_DELETED_ERROR =
  "PROCEED_REALIZATIONS_BULK_DELETED_ERROR";

export const RESET = "RESET";