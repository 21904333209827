export const ADD_APPROVAL_PANELS = "ADD_APPROVAL_PANELS";
export const ADD_APPROVAL_PANELS_OK = "ADD_APPROVAL_PANELS_OK";
export const ADD_APPROVAL_PANELS_ERROR = "ADD_APPROVAL_PANELS_ERROR";

export const BEGIN_APPROVAL_PANELS_DOWNLOAD = "BEGIN_APPROVAL_PANELS_DOWNLOAD";
export const APPROVAL_PANELS_DOWNLOAD_OK = "APPROVAL_PANELS_DOWNLOAD_OK";
export const APPROVAL_PANELS_DOWNLOAD_ERROR = "APPROVAL_PANELS_DOWNLOAD_ERROR";

export const RETRIEVE_APPROVAL_PANELS_DELETE = "RETRIEVE_APPROVAL_PANELS_DELETE";
export const APPROVAL_PANELS_DELETED_OK = "APPROVAL_PANELS_DELETED_OK";
export const APPROVAL_PANELS_DELETED_ERROR = "APPROVAL_PANELS_DELETED_ERROR";

export const RETRIEVE_APPROVAL_PANELS_EDIT = "RETRIEVE_APPROVAL_PANELS_EDITED";
export const BEGIN_EDIT_APPROVAL_PANELS = "BEGIN_EDIT_APPROVAL_PANELS";
export const APPROVAL_PANELS_EDITED_OK = "APPROVAL_PANELS_EDITED_OK";
export const APPROVAL_PANELS_EDITED_ERROR = "APPROVAL_PANELS_EDITED_ERROR";

export const RETRIEVE_APPROVAL_PANELS_BULK_DELETE = "RETRIEVE_APPROVAL_PANELS_BULK_DELETE";
export const APPROVAL_PANELS_BULK_DELETED_OK = "APPROVAL_PANELS_BULK_DELETED_OK";
export const APPROVAL_PANELS_BULK_DELETED_ERROR = "APPROVAL_PANELS_BULK_DELETED_ERROR";