import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*             FactoryInvoice API CALLS            */
/*-------------------------------------------------*/

export async function retrieveFactoryInvoicesData() {
  return await axiosClient.post("/factory_invoice_hdrs/all");
}

export async function retrieveFactoryInvoiceData(value) {
  return await axiosClient.post("factory_invoice_hdrs", value);
}

export async function addFactoryInvoiceDB(value) {
  return await axiosClient.post("/factory_invoice_hdrs/create", value);
}

export async function deleteFactoryInvoiceDB(id) {
  return await axiosClient.delete(`/factory_invoice_hdrs/${id}`);
}

export async function editFactoryInvoiceDB(value) {
  return await axiosClient.put(`/factory_invoice_hdrs/${value.id}`, value);
}
