export const ADD_TNA_ACTIONS = "ADD_TNA_ACTIONS";
export const ADD_TNA_ACTIONS_OK = "ADD_TNA_ACTIONS_OK";
export const ADD_TNA_ACTIONS_ERROR = "ADD_TNA_ACTIONS_ERROR";

export const BEGIN_TNA_ACTIONS_DOWNLOAD = "BEGIN_TNA_ACTIONS_DOWNLOAD";
export const TNA_ACTIONS_DOWNLOAD_OK = "TNA_ACTIONS_DOWNLOAD_OK";
export const TNA_ACTIONS_DOWNLOAD_ERROR = "TNA_ACTIONS_DOWNLOAD_ERROR";

export const RETRIEVE_TNA_ACTIONS_DELETE = "RETRIEVE_TNA_ACTIONS_DELETE";
export const TNA_ACTIONS_DELETED_OK = "TNA_ACTIONS_DELETED_OK";
export const TNA_ACTIONS_DELETED_ERROR = "TNA_ACTIONS_DELETED_ERROR";

export const RETRIEVE_TNA_ACTIONS_EDIT = "RETRIEVE_TNA_ACTIONS_EDITED";
export const BEGIN_EDIT_TNA_ACTIONS = "BEGIN_EDIT_TNA_ACTIONS";
export const TNA_ACTIONS_EDITED_OK = "TNA_ACTIONS_EDITED_OK";
export const TNA_ACTIONS_EDITED_ERROR = "TNA_ACTIONS_EDITED_ERROR";

export const RETRIEVE_TNA_ACTIONS_BULK_DELETE = "RETRIEVE_TNA_ACTIONS_BULK_DELETE";
export const TNA_ACTIONS_BULK_DELETED_OK = "TNA_ACTIONS_BULK_DELETED_OK";
export const TNA_ACTIONS_BULK_DELETED_ERROR = "TNA_ACTIONS_BULK_DELETED_ERROR";