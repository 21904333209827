import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_PRODUCT_BRANDS,
  BEGIN_EDIT_PRODUCT_BRANDS,
  BEGIN_PRODUCT_BRANDS_DOWNLOAD,
  RETRIEVE_PRODUCT_BRANDS_BULK_DELETE,
  RETRIEVE_PRODUCT_BRANDS_DELETE
} from "../../constants/modules/productBrandsConstants";

import {
  addProductBrandErrorAction,
  addProductBrandOkAction,
  bulkDeleteProductBrandErrorAction,
  bulkDeleteProductBrandOkAction,
  deleteProductBrandErrorAction,
  deleteProductBrandOkAction,
  downloadProductBrandsErrorAction,
  downloadProductBrandsOkAction,
  editProductBrandErrorAction,
  editProductBrandOkAction
} from "../../actions/modules/productBrandsActions";

import {
  addProductBrandDB,
  bulkDeleteProductBrandDB,
  deleteProductBrandDB,
  editProductBrandDB,
  retrieveProductBrandsData
} from "../../../config/apiCalls/productBrandsAPICall";

// Retrieve products
// worker saga
export function* retrieveProductBrands() {
  try {
    const response = yield call(retrieveProductBrandsData);

    if (response.status === 200) {
      yield put(downloadProductBrandsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadProductBrandsErrorAction());
  }
}

// Create new product
// worker saga
export function* addProductBrand(action) {
  const productBrand = action.productBrand;
  try {
    const response = yield call(addProductBrandDB, productBrand);
    if (response.status === 201) {
      yield delay(500);

      yield put(addProductBrandOkAction(productBrand));
      toast.success("Product Brand created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addProductBrandErrorAction(true));
    toast.error("Product Brand created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteProductBrand(action) {
  const id = action.payload;
  try {
    yield call(deleteProductBrandDB, id);
    yield deleteProductBrandOkAction();
    toast.success("Product Brand deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteProductBrandErrorAction();
  }
}

// Edit product
// worker saga
export function* editProductBrand(action) {
  const productBrand = action.productBrand;

  try {
    yield call(editProductBrandDB, productBrand);
    yield editProductBrandOkAction(productBrand);
    // Alert
    toast.success("Product Brand has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editProductBrandErrorAction();
  }
}

// BULK SAGAS
export function* bulkProductBrandDelete(action) {
  const productBrand = action.productBrand;

  try {
    const response = yield call(bulkDeleteProductBrandDB, productBrand);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteProductBrandOkAction(productBrand));
      toast.success("Bulk Product Brand deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteProductBrandErrorAction(true));
    toast.error("Bulk Product Brand deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchProductBrandsAsync() {
  yield takeLatest(BEGIN_PRODUCT_BRANDS_DOWNLOAD, retrieveProductBrands);
  yield takeLatest(ADD_PRODUCT_BRANDS, addProductBrand);
  yield takeLatest(RETRIEVE_PRODUCT_BRANDS_DELETE, deleteProductBrand);
  yield takeLatest(BEGIN_EDIT_PRODUCT_BRANDS, editProductBrand);
  yield takeLatest(RETRIEVE_PRODUCT_BRANDS_BULK_DELETE, bulkProductBrandDelete);
}
