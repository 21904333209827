import {
  ADD_PRODUCTS,
  ADD_PRODUCTS_ERROR,
  ADD_PRODUCTS_OK,
  BEGIN_EDIT_PRODUCTS,
  BEGIN_PRODUCTS_DOWNLOAD,
  PRODUCTS_BULK_DELETED_ERROR,
  PRODUCTS_BULK_DELETED_OK,
  PRODUCTS_DELETED_ERROR,
  PRODUCTS_DELETED_OK,
  PRODUCTS_DOWNLOAD_ERROR,
  PRODUCTS_DOWNLOAD_OK,
  PRODUCTS_EDITED_ERROR,
  PRODUCTS_EDITED_OK,
  RETRIEVE_PRODUCTS_BULK_DELETE,
  RETRIEVE_PRODUCTS_DELETE,
  RETRIEVE_PRODUCTS_EDIT,
  BEGIN_PRODUCTS_BY_FK_DOWNLOAD,
  PRODUCTS_BY_FK_DOWNLOAD_OK,
  PRODUCTS_BY_FK_DOWNLOAD_ERROR,
  RESET,
} from "../../constants/modules/productConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteProductAction = (item) => ({
  type: RETRIEVE_PRODUCTS_BULK_DELETE,
  payload: true,
  product: item,
});

export const bulkDeleteProductOkAction = () => ({
  type: PRODUCTS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteProductErrorAction = (state) => ({
  type: PRODUCTS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadProductsAction = () => ({
  type: BEGIN_PRODUCTS_DOWNLOAD,
  payload: true,
});

export const downloadProductsOkAction = (items) => ({
  type: PRODUCTS_DOWNLOAD_OK,
  payload: items,
});

export const downloadProductsErrorAction = () => ({
  type: PRODUCTS_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadProductsByFKAction = (item) => ({
  type: BEGIN_PRODUCTS_BY_FK_DOWNLOAD,
  payload: true,
  product: item,
});

export const downloadProductsByFKOkAction = (items) => ({
  type: PRODUCTS_BY_FK_DOWNLOAD_OK,
  payload: items,
});

export const downloadProductsByFKErrorAction = () => ({
  type: PRODUCTS_BY_FK_DOWNLOAD_ERROR,
  payload: true,
});

export const addProductAction = (item) => ({
  type: ADD_PRODUCTS,
  payload: true,
  product: item,
});

export const addProductOkAction = () => ({
  type: ADD_PRODUCTS_OK,
  payload: true,
});

export const addProductErrorAction = (state) => ({
  type: ADD_PRODUCTS_ERROR,
  payload: state,
});

export const deleteProductAction = (id) => ({
  type: RETRIEVE_PRODUCTS_DELETE,
  payload: id,
});

export const deleteProductOkAction = () => ({
  type: PRODUCTS_DELETED_OK,
});

export const deleteProductErrorAction = () => ({
  type: PRODUCTS_DELETED_ERROR,
  payload: true,
});

export const retrieveProductAction = (item) => ({
  type: RETRIEVE_PRODUCTS_EDIT,
  payload: item,
});

export const editProductAction = (item) => ({
  type: BEGIN_EDIT_PRODUCTS,
  product: item,
});

export const editProductOkAction = (item) => ({
  type: PRODUCTS_EDITED_OK,
  payload: item,
});

export const editProductErrorAction = () => ({
  type: PRODUCTS_EDITED_ERROR,
  payload: true,
});

export const productByFkReset = () => ({
  type: RESET,
  payload: true,
});
