import {
  ADD_LOADING_POINTS,
  ADD_LOADING_POINTS_ERROR,
  ADD_LOADING_POINTS_OK,
  BEGIN_EDIT_LOADING_POINTS,
  BEGIN_LOADING_POINTS_DOWNLOAD,
  LOADING_POINTS_BULK_DELETED_ERROR,
  LOADING_POINTS_BULK_DELETED_OK,
  LOADING_POINTS_DELETED_ERROR,
  LOADING_POINTS_DELETED_OK,
  LOADING_POINTS_DOWNLOAD_ERROR,
  LOADING_POINTS_DOWNLOAD_OK,
  LOADING_POINTS_EDITED_ERROR,
  LOADING_POINTS_EDITED_OK,
  RETRIEVE_LOADING_POINTS_BULK_DELETE,
  RETRIEVE_LOADING_POINTS_DELETE,
  RETRIEVE_LOADING_POINTS_EDIT,
} from "../../constants/modules/loadingPointConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteLoadingPointAction = (item) => ({
  type: RETRIEVE_LOADING_POINTS_BULK_DELETE,
  payload: true,
  loadingPoint: item,
});

export const bulkDeleteLoadingPointOkAction = () => ({
  type: LOADING_POINTS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteLoadingPointErrorAction = (state) => ({
  type: LOADING_POINTS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadLoadingPointsAction = () => ({
  type: BEGIN_LOADING_POINTS_DOWNLOAD,
  payload: true,
});

export const downloadLoadingPointsOkAction = (items) => ({
  type: LOADING_POINTS_DOWNLOAD_OK,
  payload: items,
});

export const downloadLoadingPointsErrorAction = () => ({
  type: LOADING_POINTS_DOWNLOAD_ERROR,
  payload: true,
});

export const addLoadingPointAction = (item) => ({
  type: ADD_LOADING_POINTS,
  payload: true,
  loadingPoint: item,
});

export const addLoadingPointOkAction = () => ({
  type: ADD_LOADING_POINTS_OK,
  payload: true,
});

export const addLoadingPointErrorAction = (state) => ({
  type: ADD_LOADING_POINTS_ERROR,
  payload: state,
});

export const deleteLoadingPointAction = (id) => ({
  type: RETRIEVE_LOADING_POINTS_DELETE,
  payload: id,
});

export const deleteLoadingPointOkAction = () => ({
  type: LOADING_POINTS_DELETED_OK,
});

export const deleteLoadingPointErrorAction = () => ({
  type: LOADING_POINTS_DELETED_ERROR,
  payload: true,
});

export const retrieveLoadingPointAction = (item) => ({
  type: RETRIEVE_LOADING_POINTS_EDIT,
  payload: item,
});

export const editLoadingPointAction = (item) => ({
  type: BEGIN_EDIT_LOADING_POINTS,
  loadingPoint: item,
});

export const editLoadingPointOkAction = (item) => ({
  type: LOADING_POINTS_EDITED_OK,
  payload: item,
});

export const editLoadingPointErrorAction = () => ({
  type: LOADING_POINTS_EDITED_ERROR,
  payload: true,
});
