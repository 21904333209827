import {
  ADD_USER_ROLE,
  ADD_USER_ROLE_OK,
  ADD_USER_ROLE_ERROR,
  BEGIN_USER_ROLE_DOWNLOAD,
  USER_ROLE_DOWNLOAD_OK,
  USER_ROLE_DOWNLOAD_ERROR,
  RETRIEVE_USER_ROLE_DELETE,
  USER_ROLE_DELETED_OK,
  USER_ROLE_DELETED_ERROR,
  RETRIEVE_USER_ROLE_EDIT,
  BEGIN_EDIT_USER_ROLE,
  USER_ROLE_EDITED_OK,
  USER_ROLE_EDITED_ERROR,
  RETRIEVE_USER_ROLE_BULK_DELETE,
  USER_ROLE_BULK_DELETED_OK,
  USER_ROLE_BULK_DELETED_ERROR,
} from "../../constants/modules/userRolesConstants";

const initialState = {
  userRoles: [],
  error: false,
  loading: false,
  deleteUserRole: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_USER_ROLE_DOWNLOAD:
    case ADD_USER_ROLE:
    case RETRIEVE_USER_ROLE_BULK_DELETE:
    case BEGIN_EDIT_USER_ROLE:
      return {
        ...state,
        loading: action.payload,
        userRole: action.userRole,
      };

    case ADD_USER_ROLE_OK:
    case USER_ROLE_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_USER_ROLE_ERROR:
    case USER_ROLE_DOWNLOAD_ERROR:
    case USER_ROLE_DELETED_ERROR:
    case USER_ROLE_BULK_DELETED_ERROR:
    case USER_ROLE_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USER_ROLE_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        userRoles: action.payload,
      };

    case RETRIEVE_USER_ROLE_DELETE:
      return {
        ...state,
        deleteUserRole: action.payload,
      };

    case USER_ROLE_DELETED_OK:
      return {
        ...state,
        userRoles: state.userRoles.data.filter(
          (userRole) => userRole.id !== state.deleteUserRole
        ),
        deleteUserRole: null,
      };

    case RETRIEVE_USER_ROLE_EDIT:
      return {
        ...state,
        editUserRole: action.payload,
      };

    case USER_ROLE_EDITED_OK:
      return {
        ...state,
        editUserRole: null,
        userRoles: state.userRoles.data.map((userRole) =>
          userRole.id === action.payload.id
            ? (userRole = action.payload)
            : userRole
        ),
      };

    default:
      return state;
  }
}
