import axiosClient from "../axiosClient";
/*-------------------------------------------------*/
/*              MEASURING UNITS API CALLS          */
/*-------------------------------------------------*/

export async function retrieveMeasuringUnitsData() {
  return await axiosClient.get("/measuring_units");
}

export async function addMeasuringUnitDB(value) {
  return await axiosClient.post("/measuring_units", value);
}

export async function deleteMeasuringUnitDB(id) {
  return await axiosClient.delete(`/measuring_units/${id}`);
}

export async function bulkDeleteMeasuringUnitDB(ids) {
  return await axiosClient.post(`/measuring_units/bulk_delete`, ids);
}

export async function editMeasuringUnitDB(value) {

  return await axiosClient.put(`/measuring_units/${value.id}`, value);
}
