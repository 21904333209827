import axiosClient from "../axiosClient";




export async function bulkDeleteUserLevelDB(ids) {
  return await axiosClient.post(`/user_levels/bulk_delete`, ids);
}

export async function retrieveUserLevelsData() {
  return await axiosClient.get("/user_levels");
}

export async function addUserLevelDB(value) {
  return await axiosClient.post("/user_levels", value);
}

export async function deleteUserLevelDB(id) {
  return await axiosClient.delete(`/user_levels/${id}`);
}

export async function editUserLevelDB(value) {
  return await axiosClient.put(`/user_levels/${value.id}`, value);
}
