import {
  BEGIN_MENUS_DOWNLOAD,
  MENUS_DOWNLOAD_OK,
  MENUS_DOWNLOAD_ERROR,
  BEGIN_GLOBAL_MENUS_DOWNLOAD,
  GLOBAL_MENUS_DOWNLOAD_OK,
  GLOBAL_MENUS_DOWNLOAD_ERROR,
} from "../constants/menuConstants";

const initialState = {
  modules: [],
  error: false,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_MENUS_DOWNLOAD:
      return {
        ...state,
        loading: action.payload,
        menus: action.menus,
      };

    case MENUS_DOWNLOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MENUS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        modules: action.payload,
      };

    default:
      return state;
  }
}
