import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_BUYER_DEPT,
  BEGIN_BUYER_DEPT_DOWNLOAD,
  RETRIEVE_BUYER_DEPT_DELETE,
  BEGIN_EDIT_BUYER_DEPT,
  RETRIEVE_BUYER_DEPT_BULK_DELETE,
  BEGIN_BUYER_DEPT_BY_BRAND_DOWNLOAD,
} from "../../constants/modules/buyerDeptConstants";

import {
  downloadBuyerDepartmentsOkAction,
  downloadBuyerDepartmentsErrorAction,
  addBuyerDepartmentOkAction,
  addBuyerDepartmentErrorAction,
  deleteBuyerDepartmentOkAction,
  deleteBuyerDepartmentErrorAction,
  editBuyerDepartmentOkAction,
  editBuyerDepartmentErrorAction,
  bulkDeleteBuyerDepartmentOkAction,
  bulkDeleteBuyerDepartmentErrorAction,
  downloadBuyerDepartmentsByBrandOkAction,
  downloadBuyerDepartmentsByBrandErrorAction,
} from "../../actions/modules/buyerDeptActions";

import {
  retrieveBuyerDepartmentDB,
  addBuyerDepartmentDB,
  deleteBuyerDepartmentDB,
  editBuyerDepartmentDB,
  bulkDeleteBuyerDepartmentDB,
  retrieveBuyerDepartmentByBrandDB,
} from "../../../config/apiCalls/buyerDepartments";

// Retrieve products
// worker saga
export function* retrieveBuyerDepartments() {
  try {
    const response = yield call(retrieveBuyerDepartmentDB);
    if (response.status === 200) {
      yield put(downloadBuyerDepartmentsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadBuyerDepartmentsErrorAction());
  }
}

export function* retrieveBuyerDepartmentsByBrand(action) {
  const buyerDepartment = action.buyerDepartment;

  console.log("action******", buyerDepartment);
  try {
    const response = yield call(
      retrieveBuyerDepartmentByBrandDB,
      buyerDepartment
    );
    if (response.status === 200) {
      yield put(downloadBuyerDepartmentsByBrandOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadBuyerDepartmentsByBrandErrorAction());
  }
}

// Create new product
// worker saga
export function* addBuyerDepartment(action) {
  const buyerDepartment = action.buyerDepartment;

  try {
    const response = yield call(addBuyerDepartmentDB, buyerDepartment);
    if (response.status === 201) {
      yield delay(500);
      yield put(addBuyerDepartmentOkAction(buyerDepartment));
      toast.success("Buyer Department created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addBuyerDepartmentErrorAction(true));
    toast.error("Buyer Department created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteBuyerDepartment(action) {
  const id = action.payload;
  try {
    yield call(deleteBuyerDepartmentDB, id);
    yield deleteBuyerDepartmentOkAction();
    toast.success("Buyer Department deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteBuyerDepartmentErrorAction());
    toast.error("Buyer Department deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkBuyerDepartmentDelete(action) {
  const buyerDepartment = action.buyerDepartment;

  try {
    const response = yield call(bulkDeleteBuyerDepartmentDB, buyerDepartment);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteBuyerDepartmentOkAction(buyerDepartment));
      toast.success("Buyer Department deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteBuyerDepartmentErrorAction(true));
    toast.error("Buyers Department deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editBuyerDepartment(action) {
  const buyerDepartment = action.buyerDepartment;

  try {
    const response = yield call(editBuyerDepartmentDB, buyerDepartment);

    if (response.status === 201) {
      yield delay(500);
      yield put(editBuyerDepartmentOkAction(buyerDepartment));
      toast.success("Buyer Department edited successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editBuyerDepartmentErrorAction());
    toast.error("Bank Department deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchBuyerDepartmentsAsync() {
  yield takeLatest(BEGIN_BUYER_DEPT_DOWNLOAD, retrieveBuyerDepartments);
  yield takeLatest(
    BEGIN_BUYER_DEPT_BY_BRAND_DOWNLOAD,
    retrieveBuyerDepartmentsByBrand
  );
  yield takeLatest(ADD_BUYER_DEPT, addBuyerDepartment);
  yield takeLatest(RETRIEVE_BUYER_DEPT_DELETE, deleteBuyerDepartment);
  yield takeLatest(BEGIN_EDIT_BUYER_DEPT, editBuyerDepartment);
  yield takeLatest(RETRIEVE_BUYER_DEPT_BULK_DELETE, bulkBuyerDepartmentDelete);
}
