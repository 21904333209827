export const ADD_DISCOUNT_DETAILS = "ADD_DISCOUNT_DETAILS";
export const ADD_DISCOUNT_DETAILS_OK = "ADD_DISCOUNT_DETAILS_OK";
export const ADD_DISCOUNT_DETAILS_ERROR =
  "ADD_DISCOUNT_DETAILS_ERROR";

export const BEGIN_DISCOUNT_DETAILS_DOWNLOAD =
  "BEGIN_DISCOUNT_DETAILS_DOWNLOAD";
export const DISCOUNT_DETAILS_DOWNLOAD_OK =
  "DISCOUNT_DETAILS_DOWNLOAD_OK";
export const DISCOUNT_DETAILS_DOWNLOAD_ERROR =
  "DISCOUNT_DETAILS_DOWNLOAD_ERROR";

export const RETRIEVE_DISCOUNT_DETAILS_DELETE =
  "RETRIEVE_DISCOUNT_DETAILS_DELETE";
export const DISCOUNT_DETAILS_DELETED_OK =
  "DISCOUNT_DETAILS_DELETED_OK";
export const DISCOUNT_DETAILS_DELETED_ERROR =
  "DISCOUNT_DETAILS_DELETED_ERROR";

export const RETRIEVE_DISCOUNT_DETAILS_EDIT =
  "RETRIEVE_DISCOUNT_DETAILS_EDITED";
export const BEGIN_EDIT_DISCOUNT_DETAILS =
  "BEGIN_EDIT_DISCOUNT_DETAILS";
export const DISCOUNT_DETAILS_EDITED_OK =
  "DISCOUNT_DETAILS_EDITED_OK";
export const DISCOUNT_DETAILS_EDITED_ERROR =
  "DISCOUNT_DETAILS_EDITED_ERROR";

