import {
  ADD_SAMPLE_STYLES, ADD_SAMPLE_STYLES_ERROR, ADD_SAMPLE_STYLES_OK, BEGIN_EDIT_SAMPLE_STYLES,
  BEGIN_SAMPLE_STYLES_DOWNLOAD,
  RETRIEVE_SAMPLE_STYLES_BULK_DELETE, RETRIEVE_SAMPLE_STYLES_DELETE, RETRIEVE_SAMPLE_STYLES_EDIT,
  SAMPLE_STYLES_BULK_DELETED_ERROR, SAMPLE_STYLES_BULK_DELETED_OK, SAMPLE_STYLES_DELETED_ERROR, SAMPLE_STYLES_DELETED_OK, SAMPLE_STYLES_DOWNLOAD_ERROR, SAMPLE_STYLES_DOWNLOAD_OK, SAMPLE_STYLES_EDITED_ERROR, SAMPLE_STYLES_EDITED_OK
} from "../../constants/modules/sampleStylesConstant";
    
    const initialState = {
      sampleStyles: [],
      error: false,
      loading: false,
      deleteSampleStyle: null,
    };
    
    export default function (state = initialState, action) {
      switch (action.type) {
        case BEGIN_SAMPLE_STYLES_DOWNLOAD:
        case RETRIEVE_SAMPLE_STYLES_BULK_DELETE:
        case ADD_SAMPLE_STYLES:
        case BEGIN_EDIT_SAMPLE_STYLES:
          return {
            ...state,
            loading: action.payload,
            sampleStyle: action.sampleStyle,
          };
    
        case ADD_SAMPLE_STYLES_OK:
        case SAMPLE_STYLES_BULK_DELETED_OK:
          return {
            ...state,
            loading: false,
          };
    
        case ADD_SAMPLE_STYLES_ERROR:
        case SAMPLE_STYLES_DOWNLOAD_ERROR:
        case SAMPLE_STYLES_BULK_DELETED_ERROR:
        case SAMPLE_STYLES_DELETED_ERROR:
        case SAMPLE_STYLES_EDITED_ERROR:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case SAMPLE_STYLES_DOWNLOAD_OK:
          return {
            ...state,
            loading: false,
            error: null,
            sampleStyles: action.payload,
          };
    
        case RETRIEVE_SAMPLE_STYLES_DELETE:
          return {
            ...state,
            deleteSampleStyle: action.payload,
          };
    
        case SAMPLE_STYLES_DELETED_OK:
          return {
            ...state,
            sampleStyles: state.sampleStyles.data.filter((sampleStyle) => sampleStyle.id !== state.deleteSampleStyle),
            deleteSampleStyle: null,
          };

          case RETRIEVE_SAMPLE_STYLES_EDIT:
            return {
              ...state,
              editSampleStyle: action.payload,
            };
    
        case SAMPLE_STYLES_EDITED_OK:
          return {
            ...state,
            editSampleStyle: null,
            sampleStyles: state.sampleStyles.data.map((sampleStyle) =>
              sampleStyle.id === action.payload.id ? (sampleStyle = action.payload) : sampleStyle
            ),
          };
    
        default:
          return state;
      }
    }
    