import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_COURIERS,
  BEGIN_COURIERS_DOWNLOAD,
  BEGIN_EDIT_COURIERS,
  RETRIEVE_COURIERS_BULK_DELETE,
  RETRIEVE_COURIERS_DELETE
} from "../../constants/modules/couriersConstants";

import {
  addCourierErrorAction,
  addCourierOkAction,
  bulkDeleteCourierErrorAction,
  bulkDeleteCourierOkAction,
  deleteCourierErrorAction,
  deleteCourierOkAction,
  downloadCouriersErrorAction,
  downloadCouriersOkAction,
  editCourierErrorAction,
  editCourierOkAction
} from "../../actions/modules/couriersActions";

import {
  addCourierDB,
  bulkDeleteCourierDB,
  deleteCourierDB,
  editCourierDB,
  retrieveCouriersData
} from "../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveCouriers() {
  try {
    const response = yield call(retrieveCouriersData);

    if (response.status === 200) {
      yield put(downloadCouriersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadCouriersErrorAction());
  }
}

// Create new product
// worker saga
export function* addCourier(action) {
  const courier = action.courier;

  try {
    const response = yield call(addCourierDB, courier);

    if (response.status === 201) {
      yield delay(2000);

      yield put(addCourierOkAction(courier));
      toast.success("Courier created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addCourierErrorAction(true));
    toast.error("Courier created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}
// BULK SAGAS
export function* bulkCourierDelete(action) {
  const courier = action.courier;

  try {
    const response = yield call(bulkDeleteCourierDB, courier);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteCourierOkAction(courier));
      toast.success("Bulk Courier deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteCourierErrorAction(true));
    toast.error("Bulk Courier deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteCourier(action) {
  const id = action.payload;
  try {
    yield call(deleteCourierDB, id);
    yield deleteCourierOkAction();
    toast.success("Courier deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteCourierErrorAction();
    toast.success("Courier deleted Failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editCourier(action) {
  const courier = action.courier;

  try {
    yield call(editCourierDB, courier);
    yield editCourierOkAction(courier);
    toast.success("Courier has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editCourierErrorAction();
    toast.success("Courier has been Failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchCouriersAsync() {
  yield takeLatest(BEGIN_COURIERS_DOWNLOAD, retrieveCouriers);
  yield takeLatest(ADD_COURIERS, addCourier);
  yield takeLatest(RETRIEVE_COURIERS_DELETE, deleteCourier);
  yield takeLatest(BEGIN_EDIT_COURIERS, editCourier);
  yield takeLatest(RETRIEVE_COURIERS_BULK_DELETE, bulkCourierDelete);
}
