import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_COUNTRIES,
  BEGIN_COUNTRIES_DOWNLOAD,
  BEGIN_EDIT_COUNTRIES,
  RETRIEVE_COUNTRIES_BULK_DELETE,
  RETRIEVE_COUNTRIES_DELETE
} from "../../constants/modules/countriesConstants";

import {
  addCountryErrorAction,
  addCountryOkAction,
  bulkDeleteCountryErrorAction,
  bulkDeleteCountryOkAction,
  deleteCountryErrorAction,
  deleteCountryOkAction,
  downloadCountriesErrorAction,
  downloadCountriesOkAction,
  editCountryErrorAction,
  editCountryOkAction
} from "../../actions/modules/countriesActions";

import {
  addCountryDB,
  bulkDeleteCountryDB,
  deleteCountryDB,
  editCountryDB,
  retrieveCountriesData
} from "../../../config/apiCalls/countries";

// Retrieve products
// worker saga
export function* retrieveCountries() {
  try {
    const response = yield call(retrieveCountriesData);

    if (response.status === 200) {
      yield put(downloadCountriesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadCountriesErrorAction());
  }
}

// Create new product
// worker saga
export function* addCountry(action) {
  const country = action.country;

  console.log(country);

  try {
    const response = yield call(addCountryDB, country);

    if (response.status === 201) {
      yield delay(500);

      yield put(addCountryOkAction(country));
      toast.success("Country created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addCountryErrorAction(true));
    toast.error("Country created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteCountry(action) {
  const id = action.payload;
  try {
    yield call(deleteCountryDB, id);
    yield deleteCountryOkAction();
    toast.success("Country deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteCountryErrorAction();
  }
}

// Edit product
// worker saga
export function* editCountry(action) {
  const country = action.country;

  try {
    yield call(editCountryDB, country);
    yield editCountryOkAction(country);
    // Alert
    // Alert
    toast.success("Country has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editCountryErrorAction();
  }
}

// BULK SAGAS
export function* bulkCountryDelete(action) {
  const country = action.country;

  try {
    const response = yield call(bulkDeleteCountryDB, country);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteCountryOkAction(country));
      toast.success("Bulk Country deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteCountryErrorAction(true));
    toast.error("Bulk Country deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchCountriesAsync() {
  yield takeLatest(BEGIN_COUNTRIES_DOWNLOAD, retrieveCountries);
  yield takeLatest(ADD_COUNTRIES, addCountry);
  yield takeLatest(RETRIEVE_COUNTRIES_DELETE, deleteCountry);
  yield takeLatest(BEGIN_EDIT_COUNTRIES, editCountry);
  yield takeLatest(RETRIEVE_COUNTRIES_BULK_DELETE, bulkCountryDelete);
}
