import axiosClient from "../axiosClient";


/*-------------------------------------------------------*/
/*               ApprovalFormSetup API CALLS             */
/*-------------------------------------------------------*/
  
  export async function retrieveApprovalFormSetupsData() {
    return await axiosClient.get("/approval_form_setup");
  }
  
  export async function addApprovalFormSetupDB(value) {
    return await axiosClient.post("/approval_form_setup", value);
  }
  
  export async function deleteApprovalFormSetupDB(id) {
    return await axiosClient.delete(`/approval_form_setup/${id}`);
  }
  
  export async function editApprovalFormSetupDB(value) {
    return await axiosClient.put(`/approval_form_setup/${value.id}`, value);
  }