import {
    ADD_TT_ADVANCE_DETAILS,
    ADD_TT_ADVANCE_DETAILS_ERROR,
    ADD_TT_ADVANCE_DETAILS_OK,
    BEGIN_EDIT_TT_ADVANCE_DETAILS,
    BEGIN_TT_ADVANCE_DETAILS_DOWNLOAD,
    BEGIN_TT_ADVANCE_DETAIL_DOWNLOAD,
    RESET,
    RETRIEVE_TT_ADVANCE_DETAILS_DELETE,
    RETRIEVE_TT_ADVANCE_DETAILS_EDIT, TT_ADVANCE_DETAILS_DELETED_ERROR,
    TT_ADVANCE_DETAILS_DELETED_OK,
    TT_ADVANCE_DETAILS_DOWNLOAD_ERROR,
    TT_ADVANCE_DETAILS_DOWNLOAD_OK,
    TT_ADVANCE_DETAILS_EDITED_ERROR,
    TT_ADVANCE_DETAILS_EDITED_OK,
    TT_ADVANCE_DETAIL_DOWNLOAD_ERROR,
    TT_ADVANCE_DETAIL_DOWNLOAD_OK
} from "../../constants/modules/TTAdvanceDetailConstant";

const initialState = {
  ttAdvanceDetails: [],
  getTTAdvanceDetail: null,
  ttAdvanceDetailAddInfo: null,
  error: false,
  loading: false,
  deleteTTAdvanceDetail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_TT_ADVANCE_DETAILS_DOWNLOAD:
    case BEGIN_TT_ADVANCE_DETAIL_DOWNLOAD:
    case ADD_TT_ADVANCE_DETAILS:
    case BEGIN_EDIT_TT_ADVANCE_DETAILS:
      return {
        ...state,
        loading: action.payload,
        ttAdvanceDetail: action.ttAdvanceDetail,
      };



    case ADD_TT_ADVANCE_DETAILS_ERROR:
    case TT_ADVANCE_DETAILS_DOWNLOAD_ERROR:
    case TT_ADVANCE_DETAIL_DOWNLOAD_ERROR:
    case TT_ADVANCE_DETAILS_DELETED_ERROR:
    case TT_ADVANCE_DETAILS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TT_ADVANCE_DETAILS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        ttAdvanceDetails: action.payload,
      };

    case TT_ADVANCE_DETAIL_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        getTTAdvanceDetail: action.payload,
      };

    case ADD_TT_ADVANCE_DETAILS_OK:
      return {
        ...state,
        loading: false,
        error: null,
        ttAdvanceDetailAddInfo: action.payload,
      };

    case RETRIEVE_TT_ADVANCE_DETAILS_DELETE:
      return {
        ...state,
        deleteTTAdvanceDetail: action.payload,
      };

    case TT_ADVANCE_DETAILS_DELETED_OK:
      return {
        ...state,
        ttAdvanceDetails: state.ttAdvanceDetails.data.filter(
          (ttAdvanceDetail) =>
            ttAdvanceDetail.id !== state.deleteTTAdvanceDetail
        ),
        deleteTTAdvanceDetail: null,
      };

    case RETRIEVE_TT_ADVANCE_DETAILS_EDIT:
      return {
        ...state,
        editTTAdvanceDetail: action.payload,
      };

    case TT_ADVANCE_DETAILS_EDITED_OK:
      return {
        ...state,
        editTTAdvanceDetails: null,
        ttAdvanceDetails: state.ttAdvanceDetails.data.map((ttAdvanceDetail) =>
          ttAdvanceDetail.id === action.payload.id
            ? (ttAdvanceDetail = action.payload)
            : ttAdvanceDetail
        ),
      };

    case RESET:
      return {
        ...state,
        getTTAdvanceDetail: null,
        ttAdvanceDetailAddInfo: null,
      };

    default:
      return state;
  }
}
