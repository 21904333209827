import {
  ADD_COUNTRIES, ADD_COUNTRIES_ERROR, ADD_COUNTRIES_OK, BEGIN_COUNTRIES_DOWNLOAD, BEGIN_EDIT_COUNTRIES, COUNTRIES_BULK_DELETED_ERROR, COUNTRIES_BULK_DELETED_OK, COUNTRIES_DELETED_ERROR, COUNTRIES_DELETED_OK, COUNTRIES_DOWNLOAD_ERROR, COUNTRIES_DOWNLOAD_OK, COUNTRIES_EDITED_ERROR, COUNTRIES_EDITED_OK, RETRIEVE_COUNTRIES_BULK_DELETE, RETRIEVE_COUNTRIES_DELETE, RETRIEVE_COUNTRIES_EDIT
} from "../../constants/modules/countriesConstants";
    
  /*-----------------------------------*/
  /*             BULK ACTIONS          */
  /*-----------------------------------*/
  export const bulkDeleteCountryAction = (item) => ({
    type: RETRIEVE_COUNTRIES_BULK_DELETE,
    payload: true,
    country: item,
  });
  
  export const bulkDeleteCountryOkAction = () => ({
    type: COUNTRIES_BULK_DELETED_OK,
    payload: true,
  });
  
  export const bulkDeleteCountryErrorAction = (state) => ({
    type: COUNTRIES_BULK_DELETED_ERROR,
    payload: state,
  });
    
    export const downloadCountriesAction = () => ({
      type: BEGIN_COUNTRIES_DOWNLOAD,
      payload: true,
    });
    
    export const downloadCountriesOkAction = (items) => ({
      type: COUNTRIES_DOWNLOAD_OK,
      payload: items,
    });
    
    export const downloadCountriesErrorAction = () => ({
      type: COUNTRIES_DOWNLOAD_ERROR,
      payload: true,
    });
    
    
    export const addCountryAction = (item) => ({
      type: ADD_COUNTRIES,
      payload: true,
      country: item,
    });
    
    export const addCountryOkAction = () => ({
      type: ADD_COUNTRIES_OK,
      payload: true,
    });
    
    export const addCountryErrorAction = (state) => ({
      type: ADD_COUNTRIES_ERROR,
      payload: state,
    });
    
    
    export const deleteCountryAction = (id) => ({
      type: RETRIEVE_COUNTRIES_DELETE,
      payload: id,
    });
    
    export const deleteCountryOkAction = () => ({
      type: COUNTRIES_DELETED_OK,
    });
    
    export const deleteCountryErrorAction = () => ({
      type: COUNTRIES_DELETED_ERROR,
      payload: true,
    });
    
    
    export const retrieveCountryAction = (item) => ({
      type: RETRIEVE_COUNTRIES_EDIT,
      payload: item,
    });
    
    export const editCountryAction = (item) => ({
      type: BEGIN_EDIT_COUNTRIES,
      country: item,
    });
    
    export const editCountryOkAction = (item) => ({
      type: COUNTRIES_EDITED_OK,
      payload: item,
    });
    
    export const editCountryErrorAction = () => ({
      type: COUNTRIES_EDITED_ERROR,
      payload: true,
    });
    
    