import {
  ADD_SAMPLE_TYPES,
  ADD_SAMPLE_TYPES_ERROR,
  ADD_SAMPLE_TYPES_OK,
  BEGIN_EDIT_SAMPLE_TYPES,
  BEGIN_SAMPLE_TYPES_DOWNLOAD,
  RETRIEVE_SAMPLE_TYPES_BULK_DELETE,
  RETRIEVE_SAMPLE_TYPES_DELETE,
  RETRIEVE_SAMPLE_TYPES_EDIT,
  SAMPLE_TYPES_BULK_DELETED_ERROR,
  SAMPLE_TYPES_BULK_DELETED_OK,
  SAMPLE_TYPES_DELETED_ERROR,
  SAMPLE_TYPES_DELETED_OK,
  SAMPLE_TYPES_DOWNLOAD_ERROR,
  SAMPLE_TYPES_DOWNLOAD_OK,
  SAMPLE_TYPES_EDITED_ERROR,
  SAMPLE_TYPES_EDITED_OK,
} from "../../constants/modules/sampleTypesConstant";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteSampleTypeAction = (item) => ({
  type: RETRIEVE_SAMPLE_TYPES_BULK_DELETE,
  payload: true,
  sampleType: item,
});

export const bulkDeleteSampleTypeOkAction = () => ({
  type: SAMPLE_TYPES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteSampleTypeErrorAction = (state) => ({
  type: SAMPLE_TYPES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadSampleTypesAction = () => ({
  type: BEGIN_SAMPLE_TYPES_DOWNLOAD,
  payload: true,
});

export const downloadSampleTypesOkAction = (items) => ({
  type: SAMPLE_TYPES_DOWNLOAD_OK,
  payload: items,
});

export const downloadSampleTypesErrorAction = () => ({
  type: SAMPLE_TYPES_DOWNLOAD_ERROR,
  payload: true,
});

export const addSampleTypeAction = (item) => ({
  type: ADD_SAMPLE_TYPES,
  payload: true,
  sampleType: item,
});

export const addSampleTypeOkAction = () => ({
  type: ADD_SAMPLE_TYPES_OK,
  payload: true,
});

export const addSampleTypeErrorAction = (state) => ({
  type: ADD_SAMPLE_TYPES_ERROR,
  payload: state,
});

export const deleteSampleTypeAction = (id) => ({
  type: RETRIEVE_SAMPLE_TYPES_DELETE,
  payload: id,
});

export const deleteSampleTypeOkAction = () => ({
  type: SAMPLE_TYPES_DELETED_OK,
});

export const deleteSampleTypeErrorAction = () => ({
  type: SAMPLE_TYPES_DELETED_ERROR,
  payload: true,
});

export const retrieveSampleTypeAction = (item) => ({
  type: RETRIEVE_SAMPLE_TYPES_EDIT,
  payload: item,
});

export const editSampleTypeAction = (item) => ({
  type: BEGIN_EDIT_SAMPLE_TYPES,
  sampleType: item,
});

export const editSampleTypeOkAction = (item) => ({
  type: SAMPLE_TYPES_EDITED_OK,
  payload: item,
});

export const editSampleTypeErrorAction = () => ({
  type: SAMPLE_TYPES_EDITED_ERROR,
  payload: true,
});
