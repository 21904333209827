import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import { LOGIN_REQUEST } from "../../constants/auth/authConstants";

import {
  loginOkAction,
  loginErrorAction,
} from "../../actions/auth/authActions";

import { loginDB } from "../../../config/apiCalls";

export function* login(action) {
  const token = action.token;
  try {
    const response = yield call(loginDB, token);

    if (response.status === 200) {
      yield delay(500);
      yield put(loginOkAction(response.data));
      localStorage.setItem("dm-userInfo", JSON.stringify(response.data));
      toast.success("User Login successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(loginErrorAction(true));
    toast.error("User Login failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchLoginAsync() {
  yield takeLatest(LOGIN_REQUEST, login);
}
