import produce from "immer";

import {
  TOGGLE_SIDEBAR,
  OPEN_MENU,
  CLOSE_MENU,
  OPEN_SUBMENU,
  CHANGE_THEME,
  CHANGE_RANDOM_THEME,
  CHANGE_MODE,
  CHANGE_GRADIENT,
  CHANGE_DECO,
  CHANGE_BG_POSITION,
  CHANGE_LAYOUT,
  CHANGE_DIRECTION,
  LOAD_PAGE,
} from "../constants/uiConstants";

const initialState = {
  theme: "skyBlueTheme",
  direction: "ltr",
  type: "light", // light or dark
  gradient: true, // true or false
  decoration: true, // true or false
  bgPosition: "half", // half, header, full
  layout: "left-sidebar", // big-sidebar, left-sidebar, top-navigation, mega-menu
  /* End settings */
  palette: [
    { name: "Ocean Sky", value: "skyBlueTheme" },
    { name: "Purple", value: "purpleRedTheme" },
    { name: "Rose Gold", value: "magentaTheme" },
    { name: "Leaf", value: "cyanTheme" },
    { name: "Mint", value: "blueCyanTheme" },
    { name: "Ubuntu", value: "orangeTheme" },
    { name: "Ultra Violet", value: "purpleTheme" },
    { name: "Vintage", value: "yellowCyanTheme" },
    { name: "Fruit", value: "greenOrangeTheme" },
    { name: "Botani", value: "pinkGreenTheme" },
    { name: "Deep Ocean", value: "blueTheme" },
    { name: "School", value: "yellowBlueTheme" },
    { name: "Queen", value: "pinkBlueTheme" },
    { name: "Joker", value: "greenPurpleTheme" },
    { name: "Ruby", value: "redTheme" },
    { name: "Sultan", value: "goldTheme" },
    { name: "Monochrome", value: "greyTheme" },
  ],
  sidebarOpen: true,
  pageLoaded: false,
  subMenuOpen: [],
};

export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case TOGGLE_SIDEBAR: {
        draft.sidebarOpen = !draft.sidebarOpen;
        break;
      }

      case OPEN_MENU: {
        draft.sidebarOpen = true;
        break;
      }

      case CLOSE_MENU: {
        draft.sidebarOpen = false;
        break;
      }

      case CHANGE_THEME: {
        draft.theme = action.payload.theme;
        break;
      }

      case CHANGE_MODE: {
        draft.type = action.payload.mode;
        break;
      }

      case CHANGE_GRADIENT: {
        draft.gradient = action.payload.gradient;
        break;
      }

      case CHANGE_DECO: {
        draft.decoration = action.payload.deco;
        break;
      }

      case CHANGE_BG_POSITION: {
        draft.bgPosition = action.payload.position;
        break;
      }

      case CHANGE_LAYOUT: {
        draft.layout = action.payload.layout;
        break;
      }

      case CHANGE_DIRECTION: {
        draft.direction = action.payload.direction;
        break;
      }

      case LOAD_PAGE: {
        draft.pageLoaded = action.payload.isLoaded;
        break;
      }

      default:
    }
  });
}
