import axiosClient from "../axiosClient";

export async function bulkDeletePaymentTermDB(ids) {
  return await axiosClient.post(`/payment_terms/bulk_delete`, ids);
}

export async function retrievePaymentTermsData() {
  return await axiosClient.get("/payment_terms");
}

export async function addPaymentTermDB(value) {
  return await axiosClient.post("/payment_terms", value);
}

export async function deletePaymentTermDB(id) {
  return await axiosClient.delete(`/payment_terms/${id}`);
}

export async function editPaymentTermDB(value) {
  return await axiosClient.put(`/payment_terms/${value.id}`, value);
}
