import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_SUB_CATEGORIES,
  BEGIN_EDIT_SUB_CATEGORIES,
  BEGIN_SUB_CATEGORIES_DOWNLOAD,
  RETRIEVE_SUB_CATEGORIES_BULK_DELETE,
  RETRIEVE_SUB_CATEGORIES_DELETE,
} from "../../constants/modules/subCategoriesConstants";

import {
  addSubCategoryErrorAction,
  addSubCategoryOkAction,
  bulkDeleteSubCategoryErrorAction,
  bulkDeleteSubCategoryOkAction,
  deleteSubCategoryErrorAction,
  deleteSubCategoryOkAction,
  downloadSubCategoriesErrorAction,
  downloadSubCategoriesOkAction,
  editSubCategoryErrorAction,
  editSubCategoryOkAction,
} from "../../actions/modules/subCategoriesActions";

import {
  addSubCategoryDB,
  bulkDeleteSubCategoryDB,
  deleteSubCategoryDB,
  editSubCategoryDB,
  retrieveSubCategoriesData,
} from "../../../config/apiCalls/subCategoriesAPICall";

// Retrieve products
// worker saga
export function* retrieveSubCategories() {
  try {
    const response = yield call(retrieveSubCategoriesData);

    if (response.status === 200) {
      yield put(downloadSubCategoriesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSubCategoriesErrorAction());
  }
}

// Create new product
// worker saga
export function* addSubCategory(action) {
  const subCategory = action.subCategory;

  try {
    const response = yield call(addSubCategoryDB, subCategory);

    if (response.status === 201) {
      yield delay(500);

      yield put(addSubCategoryOkAction(subCategory));
      toast.success("Sub-Category created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addSubCategoryErrorAction(true));
    toast.error("Sub-Category created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteSubCategory(action) {
  const id = action.payload;
  try {
    yield call(deleteSubCategoryDB, id);
    yield deleteSubCategoryOkAction();
    toast.success("Sub-Category deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteSubCategoryErrorAction();
  }
}

// Edit product
// worker saga
export function* editSubCategory(action) {
  const subCategory = action.subCategory;

  try {
    yield call(editSubCategoryDB, subCategory);
    yield editSubCategoryOkAction(subCategory);
    // Alert
    // Alert
    toast.success("Sub-Category has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editSubCategoryErrorAction();
    toast.error("Sub-Category Update failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// BULK SAGAS
export function* bulkSubCategoryDelete(action) {
  const subCategory = action.subCategory;

  try {
    const response = yield call(bulkDeleteSubCategoryDB, subCategory);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteSubCategoryOkAction(subCategory));
      toast.success("Bulk Sub-Category deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteSubCategoryErrorAction(true));
    toast.error("Bulk Sub-Category deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchSubCategoriesAsync() {
  yield takeLatest(BEGIN_SUB_CATEGORIES_DOWNLOAD, retrieveSubCategories);
  yield takeLatest(ADD_SUB_CATEGORIES, addSubCategory);
  yield takeLatest(RETRIEVE_SUB_CATEGORIES_DELETE, deleteSubCategory);
  yield takeLatest(BEGIN_EDIT_SUB_CATEGORIES, editSubCategory);
  yield takeLatest(RETRIEVE_SUB_CATEGORIES_BULK_DELETE, bulkSubCategoryDelete);
}
