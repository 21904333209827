export const ADD_SAMPLE_STATUS = "ADD_SAMPLE_STATUS";
export const ADD_SAMPLE_STATUS_OK = "ADD_SAMPLE_STATUS_OK";
export const ADD_SAMPLE_STATUS_ERROR = "ADD_SAMPLE_STATUS_ERROR";

export const BEGIN_SAMPLE_STATUS_DOWNLOAD = "BEGIN_SAMPLE_STATUS_DOWNLOAD";
export const SAMPLE_STATUS_DOWNLOAD_OK = "SAMPLE_STATUS_DOWNLOAD_OK";
export const SAMPLE_STATUS_DOWNLOAD_ERROR = "SAMPLE_STATUS_DOWNLOAD_ERROR";

export const RETRIEVE_SAMPLE_STATUS_DELETE = "RETRIEVE_SAMPLE_STATUS_DELETE";
export const SAMPLE_STATUS_DELETED_OK = "SAMPLE_STATUS_DELETED_OK";
export const SAMPLE_STATUS_DELETED_ERROR = "SAMPLE_STATUS_DELETED_ERROR";

export const RETRIEVE_SAMPLE_STATUS_EDIT = "RETRIEVE_SAMPLE_STATUS_EDITED";
export const BEGIN_EDIT_SAMPLE_STATUS = "BEGIN_EDIT_SAMPLE_STATUS";
export const SAMPLE_STATUS_EDITED_OK = "SAMPLE_STATUS_EDITED_OK";
export const SAMPLE_STATUS_EDITED_ERROR = "SAMPLE_STATUS_EDITED_ERROR";

export const RETRIEVE_SAMPLE_STATUS_BULK_DELETE = "RETRIEVE_SAMPLE_STATUS_BULK_DELETE";
export const SAMPLE_STATUS_BULK_DELETED_OK = "SAMPLE_STATUS_BULK_DELETED_OK";
export const SAMPLE_STATUS_BULK_DELETED_ERROR = "SAMPLE_STATUS_BULK_DELETED_ERROR";