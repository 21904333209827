import {
  ADD_BUYER_BRANDS,
  ADD_BUYER_BRANDS_OK,
  ADD_BUYER_BRANDS_ERROR,
  BEGIN_BUYER_BRANDS_DOWNLOAD,
  BUYER_BRANDS_DOWNLOAD_OK,
  BUYER_BRANDS_DOWNLOAD_ERROR,
  RETRIEVE_BUYER_BRANDS_DELETE,
  BUYER_BRANDS_DELETED_OK,
  BUYER_BRANDS_DELETED_ERROR,
  RETRIEVE_BUYER_BRANDS_EDIT,
  BEGIN_EDIT_BUYER_BRANDS,
  BUYER_BRANDS_EDITED_OK,
  BUYER_BRANDS_EDITED_ERROR,
  RETRIEVE_BUYER_BRANDS_BULK_DELETE,
  BUYER_BRANDS_BULK_DELETED_OK,
  BUYER_BRANDS_BULK_DELETED_ERROR,
  BEGIN_BUYER_BRANDS_BY_BUYER_DOWNLOAD,
  BUYER_BRANDS_BY_BUYER_DOWNLOAD_OK,
  BUYER_BRANDS_BY_BUYER_DOWNLOAD_ERROR,
} from "../../constants/modules/buyerBrandsConstants";

const initialState = {
  buyerBrands: [],
  buyerBrandsByid: [],
  error: false,
  loading: false,
  deleteBuyerBrand: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_BUYER_BRANDS_DOWNLOAD:
    case BEGIN_BUYER_BRANDS_BY_BUYER_DOWNLOAD:
    case ADD_BUYER_BRANDS:
    case RETRIEVE_BUYER_BRANDS_BULK_DELETE:
    case BEGIN_EDIT_BUYER_BRANDS:
      return {
        ...state,
        loading: action.payload,
        buyerBrand: action.buyerBrand,
      };

    case ADD_BUYER_BRANDS_OK:
    case BUYER_BRANDS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_BUYER_BRANDS_ERROR:
    case BUYER_BRANDS_DOWNLOAD_ERROR:
    case BUYER_BRANDS_BY_BUYER_DOWNLOAD_ERROR:
    case BUYER_BRANDS_DELETED_ERROR:
    case BUYER_BRANDS_BULK_DELETED_ERROR:
    case BUYER_BRANDS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BUYER_BRANDS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        buyerBrands: action.payload,
      };

    case BUYER_BRANDS_BY_BUYER_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        buyerBrandsByid: action.payload,
      };

    case RETRIEVE_BUYER_BRANDS_DELETE:
      return {
        ...state,
        deleteBuyerBrand: action.payload,
      };

    case BUYER_BRANDS_DELETED_OK:
      return {
        ...state,
        buyerBrands: state.buyerBrands.data.filter(
          (buyerBrand) => buyerBrand.id !== state.deleteBuyerBrand
        ),
        deleteBuyerBrand: null,
      };

    case RETRIEVE_BUYER_BRANDS_EDIT:
      return {
        ...state,
        editBuyerBrand: action.payload,
      };

    case BUYER_BRANDS_EDITED_OK:
      return {
        ...state,
        editBuyerBrand: null,
        buyerBrands: state.buyerBrands.data.map((buyerBrand) =>
          buyerBrand.id === action.payload.id
            ? (buyerBrand = action.payload)
            : buyerBrand
        ),
      };

    default:
      return state;
  }
}
