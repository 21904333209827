import {
  ADD_USERS,
  ADD_USERS_ERROR,
  ADD_USERS_OK,
  BEGIN_EDIT_USERS,
  BEGIN_USERS_DOWNLOAD,
  RETRIEVE_USERS_BULK_DELETE,
  RETRIEVE_USERS_DELETE,
  RETRIEVE_USERS_EDIT,
  USERS_BULK_DELETED_ERROR,
  USERS_BULK_DELETED_OK,
  USERS_DELETED_ERROR,
  USERS_DELETED_OK,
  USERS_DOWNLOAD_ERROR,
  USERS_DOWNLOAD_OK,
  USERS_EDITED_ERROR,
  USERS_EDITED_OK,
} from "../../../constants/modules/AdminPanel/userConstants";

export const bulkDeleteUserAction = (item) => ({
  type: RETRIEVE_USERS_BULK_DELETE,
  payload: true,
  user: item,
});

export const bulkDeleteUserOkAction = () => ({
  type: USERS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteUserErrorAction = (state) => ({
  type: USERS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadUsersAction = () => ({
  type: BEGIN_USERS_DOWNLOAD,
  payload: true,
});

export const downloadUsersOkAction = (items) => ({
  type: USERS_DOWNLOAD_OK,
  payload: items,
});

export const downloadUsersErrorAction = () => ({
  type: USERS_DOWNLOAD_ERROR,
  payload: true,
});

export const addUserAction = (item) => ({
  type: ADD_USERS,
  payload: true,
  user: item,
});

export const addUserOkAction = () => ({
  type: ADD_USERS_OK,
  payload: true,
});

export const addUserErrorAction = (state) => ({
  type: ADD_USERS_ERROR,
  payload: state,
});

export const deleteUserAction = (id) => ({
  type: RETRIEVE_USERS_DELETE,
  payload: id,
});

export const deleteUserOkAction = () => ({
  type: USERS_DELETED_OK,
});

export const deleteUserErrorAction = () => ({
  type: USERS_DELETED_ERROR,
  payload: true,
});

export const retrieveUserAction = (item) => ({
  type: RETRIEVE_USERS_EDIT,
  payload: item,
});

export const editUserAction = (item) => ({
  type: BEGIN_EDIT_USERS,
  user: item,
});

export const editUserOkAction = (item) => ({
  type: USERS_EDITED_OK,
  payload: item,
});

export const editUserErrorAction = () => ({
  type: USERS_EDITED_ERROR,
  payload: true,
});
