import {
  ADD_PORTS,
  ADD_PORTS_ERROR,
  ADD_PORTS_OK,
  BEGIN_EDIT_PORTS,
  BEGIN_PORTS_DOWNLOAD,
  PORTS_BULK_DELETED_ERROR,
  PORTS_BULK_DELETED_OK,
  PORTS_DELETED_ERROR,
  PORTS_DELETED_OK,
  PORTS_DOWNLOAD_ERROR,
  PORTS_DOWNLOAD_OK,
  PORTS_EDITED_ERROR,
  PORTS_EDITED_OK,
  RETRIEVE_PORTS_BULK_DELETE,
  RETRIEVE_PORTS_DELETE,
  RETRIEVE_PORTS_EDIT
} from "../../constants/modules/portsConstants";

export const bulkDeletePortAction = (item) => ({
  type: RETRIEVE_PORTS_BULK_DELETE,
  payload: true,
  port: item,
});

export const bulkDeletePortOkAction = () => ({
  type: PORTS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeletePortErrorAction = (state) => ({
  type: PORTS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadPortsAction = () => ({
  type: BEGIN_PORTS_DOWNLOAD,
  payload: true,
});

export const downloadPortsOkAction = (items) => ({
  type: PORTS_DOWNLOAD_OK,
  payload: items,
});

export const downloadPortsErrorAction = () => ({
  type: PORTS_DOWNLOAD_ERROR,
  payload: true,
});

export const addPortAction = (item) => ({
  type: ADD_PORTS,
  payload: true,
  port: item,
});

export const addPortOkAction = () => ({
  type: ADD_PORTS_OK,
  payload: true,
});

export const addPortErrorAction = (state) => ({
  type: ADD_PORTS_ERROR,
  payload: state,
});

export const deletePortAction = (id) => ({
  type: RETRIEVE_PORTS_DELETE,
  payload: id,
});

export const deletePortOkAction = () => ({
  type: PORTS_DELETED_OK,
});

export const deletePortErrorAction = () => ({
  type: PORTS_DELETED_ERROR,
  payload: true,
});

export const retrievePortAction = (item) => ({
  type: RETRIEVE_PORTS_EDIT,
  payload: item,
});

export const editPortAction = (item) => ({
  type: BEGIN_EDIT_PORTS,
  port: item,
});

export const editPortOkAction = (item) => ({
  type: PORTS_EDITED_OK,
  payload: item,
});

export const editPortErrorAction = () => ({
  type: PORTS_EDITED_ERROR,
  payload: true,
});
