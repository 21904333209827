import axiosClient from "../axiosClient";

export async function bulkDeleteFabricDB(ids) {
  return await axiosClient.post(`/fabrics/bulk_delete`, ids);
}

export async function retrieveFabricsData() {
  return await axiosClient.get("/fabrics");
}

export async function retrieveFabricsByFkDB(value) {
  console.log("valuee8888", value);
  return await axiosClient.post("/fabrics/get_by_fk", value);
}

export async function addFabricDB(value) {
  return await axiosClient.post("/fabrics", value);
}

export async function deleteFabricDB(id) {
  return await axiosClient.delete(`/fabrics/${id}`);
}

export async function editFabricDB(value) {
  return await axiosClient.put(`/fabrics/${value.id}`, value);
}
