import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    ADD_PROCEED_REALIZATION_DETAILS,
    BEGIN_EDIT_PROCEED_REALIZATION_DETAILS,
    BEGIN_PROCEED_REALIZATION_DETAILS_DOWNLOAD,
    BEGIN_PROCEED_REALIZATION_DETAIL_DOWNLOAD,
    RETRIEVE_PROCEED_REALIZATION_DETAILS_DELETE
} from "../../constants/modules/proceedRealizationDetailsConstant";

import {
    addProceedRealizationDetailErrorAction,
    addProceedRealizationDetailOkAction,
    deleteProceedRealizationDetailErrorAction,
    deleteProceedRealizationDetailOkAction, downloadProceedRealizationDetailsErrorAction,
    downloadProceedRealizationDetailsOkAction,
    editProceedRealizationDetailErrorAction,
    editProceedRealizationDetailOkAction
} from "../../actions/modules/proceedRealizationDetailsAction";

import {
    addProceedRealizationDetailDB,
    deleteProceedRealizationDetailDB,
    editProceedRealizationDetailDB,
    retrieveProceedRealizationDetailData,
    retrieveProceedRealizationDetailsData
} from "../../../config/apiCalls/proceedRealizationDetailsAPICall";

// Retrieve products
// worker saga
export function* retrieveProceedRealizationDetails() {
  try {
    const response = yield call(retrieveProceedRealizationDetailsData);

    if (response.status === 200) {
      yield put(downloadProceedRealizationDetailsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadProceedRealizationDetailsErrorAction());
  }
}

export function* retrieveProceedRealizationDetail(action) {
  const proceedRealizationDetail = action.proceedRealizationDetail;
  try {
    const response = yield call(
      retrieveProceedRealizationDetailData,
      proceedRealizationDetail
    );
    if (response.status === 200) {
      yield put(downloadProceedRealizationDetailsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadProceedRealizationDetailsErrorAction());
  }
}

// Create new product
// worker saga
export function* addProceedRealizationDetail(action) {
  const proceedRealizationDetail = action.proceedRealizationDetail;
  try {
    const response = yield call(
      addProceedRealizationDetailDB,
      proceedRealizationDetail
    );
    if (response.status === 200) {
      yield delay(500);
      yield put(addProceedRealizationDetailOkAction(response.data));
      toast.success("Proceed Realization Detail created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addProceedRealizationDetailErrorAction(true));
    toast.error("Proceed Realization Detail created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteProceedRealizationDetail(action) {
  const id = action.payload;
  try {
    yield call(deleteProceedRealizationDetailDB, id);
    yield deleteProceedRealizationDetailOkAction();
    toast.success("Proceed Realization Detail deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteProceedRealizationDetailErrorAction();
  }
}

// Edit product
// worker saga
export function* editProceedRealizationDetail(action) {
  const proceedRealizationDetail = action.proceedRealizationDetail;

  try {
    yield call(editProceedRealizationDetailDB, proceedRealizationDetail);
    yield editProceedRealizationDetailOkAction(proceedRealizationDetail);
    // Alert
    // Alert
    toast.success("Proceed Realization Detail has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editProceedRealizationDetailErrorAction();
  }
}

export function* watchProceedRealizationDetailsAsync() {
  yield takeLatest(
    BEGIN_PROCEED_REALIZATION_DETAILS_DOWNLOAD,
    retrieveProceedRealizationDetails
  );
  yield takeLatest(
    BEGIN_PROCEED_REALIZATION_DETAIL_DOWNLOAD,
    retrieveProceedRealizationDetail
  );
  yield takeLatest(
    ADD_PROCEED_REALIZATION_DETAILS,
    addProceedRealizationDetail
  );
  yield takeLatest(
    RETRIEVE_PROCEED_REALIZATION_DETAILS_DELETE,
    deleteProceedRealizationDetail
  );
  yield takeLatest(
    BEGIN_EDIT_PROCEED_REALIZATION_DETAILS,
    editProceedRealizationDetail
  );
}
