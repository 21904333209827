export const ADD_TT_ADVANCES = "ADD_TT_ADVANCES";
export const ADD_TT_ADVANCES_OK = "ADD_TT_ADVANCES_OK";
export const ADD_TT_ADVANCES_ERROR = "ADD_TT_ADVANCES_ERROR";

export const BEGIN_TT_ADVANCES_DOWNLOAD = "BEGIN_TT_ADVANCES_DOWNLOAD";
export const TT_ADVANCES_DOWNLOAD_OK = "TT_ADVANCES_DOWNLOAD_OK";
export const TT_ADVANCES_DOWNLOAD_ERROR = "TT_ADVANCES_DOWNLOAD_ERROR";

export const BEGIN_TT_ADVANCE_DOWNLOAD = "BEGIN_TT_ADVANCE_DOWNLOAD";
export const TT_ADVANCE_DOWNLOAD_OK = "TT_ADVANCE_DOWNLOAD_OK";
export const TT_ADVANCE_DOWNLOAD_ERROR = "TT_ADVANCE_DOWNLOAD_ERROR";

export const RETRIEVE_TT_ADVANCES_DELETE = "RETRIEVE_TT_ADVANCES_DELETE";
export const TT_ADVANCES_DELETED_OK = "TT_ADVANCES_DELETED_OK";
export const TT_ADVANCES_DELETED_ERROR = "TT_ADVANCES_DELETED_ERROR";

export const RETRIEVE_TT_ADVANCES_EDIT = "RETRIEVE_TT_ADVANCES_EDITED";
export const BEGIN_EDIT_TT_ADVANCES = "BEGIN_EDIT_TT_ADVANCES";
export const TT_ADVANCES_EDITED_OK = "TT_ADVANCES_EDITED_OK";
export const TT_ADVANCES_EDITED_ERROR = "TT_ADVANCES_EDITED_ERROR";


export const RESET = "RESET";
