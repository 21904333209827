import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_COMMISSION,
  BEGIN_COMMISSION_DOWNLOAD,
} from "../../constants/modules/commissionConstants";

import {
  downloadHeaderOkAction,
  downloadHeaderErrorAction,
  addHeaderOkAction,
  addHeaderErrorAction,
} from "../../actions/modules/commissionsActions";

import {
  retrieveHeaderDB,
  retrieveDetailDB,
  addHeaderDB,
} from "../../../config/apiCalls/commissions";

// Retrieve products
// worker saga
export function* retrieveHeaders(action) {
  const commission = action.commission;
  try {
    const response = yield call(retrieveHeaderDB, commission);

    if (response.status === 200) {
      yield put(downloadHeaderOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadHeaderErrorAction());
  }
}

// Create new product
// worker saga
export function* addHeader(action) {
  const commission = action.commission;

  try {
    const response = yield call(addHeaderDB, commission);

    if (response.status === 201) {
      yield delay(500);
      yield put(addHeaderOkAction(response.data));
      toast.success("Header created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addHeaderErrorAction(true));
    toast.error("Header created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
// export function* deleteBank(action) {
//   const id = action.payload;
//   try {
//     yield call(deleteBankDB, id);
//     yield deleteBankOkAction();
//     toast.success("Bank deleted successfully!", {
//       position: "top-right",
//       autoClose: 2000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//   } catch (error) {
//     yield put(deleteBankErrorAction());
//     toast.error("Bank deleted failed!", {
//       position: "top-right",
//       autoClose: 2000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//   }
// }

// export function* bulkBankDelete(action) {
//   const bank = action.bank;

//   try {
//     const response = yield call(bulkDeleteBankDB, bank);
//     if (response.status === 200) {
//       yield delay(500);
//       yield put(bulkDeleteBankOkAction(bank));
//       toast.success("Banks deleted successfully!", {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//     }
//   } catch (error) {
//     yield put(bulkDeleteBankErrorAction(true));
//     toast.error("Banks deleted failed!", {
//       position: "top-right",
//       autoClose: 2000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//   }
// }

// Edit product
// worker saga
// export function* editBank(action) {
//   const bank = action.bank;

//   try {
//     const response = yield call(editBankDB, bank);

//     if (response.status === 201) {
//       yield delay(500);
//       yield put(editBankOkAction(bank));
//       toast.success("Bank edited successfully!", {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//     }
//   } catch (error) {
//     yield put(editBankErrorAction());
//     toast.error("Bank deleted failed!", {
//       position: "top-right",
//       autoClose: 2000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//   }
// }

export function* watchCommissionsAsync() {
  yield takeLatest(BEGIN_COMMISSION_DOWNLOAD, retrieveHeaders);
  yield takeLatest(ADD_COMMISSION, addHeader);
}
