export const ADD_COMPANIES = "ADD_COMPANIES";
export const ADD_COMPANIES_OK = "ADD_COMPANIES_OK";
export const ADD_COMPANIES_ERROR = "ADD_COMPANIES_ERROR";

export const BEGIN_COMPANIES_DOWNLOAD = "BEGIN_COMPANIES_DOWNLOAD";
export const COMPANIES_DOWNLOAD_OK = "COMPANIES_DOWNLOAD_OK";
export const COMPANIES_DOWNLOAD_ERROR = "COMPANIES_DOWNLOAD_ERROR";

export const RETRIEVE_COMPANIES_DELETE = "RETRIEVE_COMPANIES_DELETE";
export const COMPANIES_DELETED_OK = "COMPANIES_DELETED_OK";
export const COMPANIES_DELETED_ERROR = "COMPANIES_DELETED_ERROR";

export const RETRIEVE_COMPANIES_EDIT = "RETRIEVE_COMPANIES_EDITED";
export const BEGIN_EDIT_COMPANIES = "BEGIN_EDIT_COMPANIES";
export const COMPANIES_EDITED_OK = "COMPANIES_EDITED_OK";
export const COMPANIES_EDITED_ERROR = "COMPANIES_EDITED_ERROR";

export const RETRIEVE_COMPANIES_BULK_DELETE = "RETRIEVE_COMPANIES_BULK_DELETE";
export const COMPANIES_BULK_DELETED_OK = "COMPANIES_BULK_DELETED_OK";
export const COMPANIES_BULK_DELETED_ERROR = "COMPANIES_BULK_DELETED_ERROR";