import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*              RDL Invoice API CALLS              */
/*-------------------------------------------------*/

export async function retrieveRDLInvoicesData() {
  return await axiosClient.post("/rdl_invoice_hdrs/all");
}

export async function retrieveRDLInvoiceData(value) {
  return await axiosClient.post("/rdl_invoice_hdrs", value);
}

export async function addRDLInvoiceDB(value) {
  return await axiosClient.post("/rdl_invoice_hdrs/create", value);
}

export async function deleteRDLInvoiceDB(id) {
  return await axiosClient.delete(`/rdl_invoice_hdrs/delete/${id}`);
}

export async function editRDLInvoiceDB(value) {
  return await axiosClient.put(`/rdl_invoice_hdrs/${value.id}`, value);
}
