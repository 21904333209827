import {
  ADD_USER_ACCESS,
  ADD_USER_ACCESS_OK,
  ADD_USER_ACCESS_ERROR,
  BEGIN_USER_ACCESS_DOWNLOAD,
  USER_ACCESS_DOWNLOAD_OK,
  USER_ACCESS_DOWNLOAD_ERROR,
  RETRIEVE_USER_ACCESS_DELETE,
  USER_ACCESS_DELETED_OK,
  USER_ACCESS_DELETED_ERROR,
  RETRIEVE_USER_ACCESS_EDIT,
  BEGIN_EDIT_USER_ACCESS,
  USER_ACCESS_EDITED_OK,
  USER_ACCESS_EDITED_ERROR,
  RETRIEVE_USER_ACCESS_BULK_DELETE,
  USER_ACCESS_BULK_DELETED_OK,
  USER_ACCESS_BULK_DELETED_ERROR,
  RETRIEVE_USER_ACCESS_BY_ID,
  RETRIEVE_USER_ACCESS_BY_ID_OK,
  RETRIEVE_USER_ACCESS_BY_ID_ERROR,
} from "../../constants/modules/userAccessConstants";

export const downloadUserAccessesAction = () => ({
  type: BEGIN_USER_ACCESS_DOWNLOAD,
  payload: true,
});

export const downloadUserAccessesOkAction = (items) => ({
  type: USER_ACCESS_DOWNLOAD_OK,
  payload: items,
});

export const downloadUserAccessesErrorAction = () => ({
  type: USER_ACCESS_DOWNLOAD_ERROR,
  payload: true,
});

//
export const getUserAccessByIdAction = (item) => ({
  type: RETRIEVE_USER_ACCESS_BY_ID,
  payload: true,
  userAccess: item,
});

export const getUserAccessByIdOkAction = (items) => ({
  type: RETRIEVE_USER_ACCESS_BY_ID_OK,
  payload: items,
});

export const getUserAccessByIdErrorAction = (state) => ({
  type: RETRIEVE_USER_ACCESS_BY_ID_ERROR,
  payload: state,
});

export const addUserAccessAction = (item) => ({
  type: ADD_USER_ACCESS,
  payload: true,
  userAccess: item,
});

export const addUserAccessOkAction = () => ({
  type: ADD_USER_ACCESS_OK,
  payload: true,
});

export const addUserAccessErrorAction = (state) => ({
  type: ADD_USER_ACCESS_ERROR,
  payload: state,
});

export const deleteUserAccessAction = (id) => ({
  type: RETRIEVE_USER_ACCESS_DELETE,
  payload: id,
});

export const deleteUserAccessOkAction = () => ({
  type: USER_ACCESS_DELETED_OK,
});

export const deleteUserAccessErrorAction = () => ({
  type: USER_ACCESS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteUserAccessAction = (item) => ({
  type: RETRIEVE_USER_ACCESS_BULK_DELETE,
  payload: true,
  userAccess: item,
});

export const bulkDeleteUserAccessOkAction = () => ({
  type: USER_ACCESS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteUserAccessErrorAction = (state) => ({
  type: USER_ACCESS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveUserAccessAction = (item) => ({
  type: RETRIEVE_USER_ACCESS_EDIT,
  payload: item,
});

export const editUserAccessAction = (item) => ({
  type: BEGIN_EDIT_USER_ACCESS,
  userAccess: item,
});

export const editUserAccessOkAction = (item) => ({
  type: USER_ACCESS_EDITED_OK,
  payload: item,
});

export const editUserAccessErrorAction = () => ({
  type: USER_ACCESS_EDITED_ERROR,
  payload: true,
});
