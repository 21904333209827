export const ADD_SEASONS = "ADD_SEASONS";
export const ADD_SEASONS_OK = "ADD_SEASONS_OK";
export const ADD_SEASONS_ERROR = "ADD_SEASONS_ERROR";

export const BEGIN_SEASONS_DOWNLOAD = "BEGIN_SEASONS_DOWNLOAD";
export const SEASONS_DOWNLOAD_OK = "SEASONS_DOWNLOAD_OK";
export const SEASONS_DOWNLOAD_ERROR = "SEASONS_DOWNLOAD_ERROR";

export const RETRIEVE_SEASONS_DELETE = "RETRIEVE_SEASONS_DELETE";
export const SEASONS_DELETED_OK = "SEASONS_DELETED_OK";
export const SEASONS_DELETED_ERROR = "SEASONS_DELETED_ERROR";

export const RETRIEVE_SEASONS_EDIT = "RETRIEVE_SEASONS_EDITED";
export const BEGIN_EDIT_SEASONS = "BEGIN_EDIT_SEASONS";
export const SEASONS_EDITED_OK = "SEASONS_EDITED_OK";
export const SEASONS_EDITED_ERROR = "SEASONS_EDITED_ERROR";


export const RETRIEVE_SEASONS_BULK_DELETE = "RETRIEVE_SEASONS_BULK_DELETE";
export const SEASONS_BULK_DELETED_OK = "SEASONS_BULK_DELETED_OK";
export const SEASONS_BULK_DELETED_ERROR = "SEASONS_BULK_DELETED_ERROR";