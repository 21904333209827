import {
    ADD_FREIGHT_TERMS,
    ADD_FREIGHT_TERMS_ERROR,
    ADD_FREIGHT_TERMS_OK,
    BEGIN_EDIT_FREIGHT_TERMS,
    BEGIN_FREIGHT_TERMS_DOWNLOAD,
    FREIGHT_TERMS_BULK_DELETED_ERROR,
    FREIGHT_TERMS_BULK_DELETED_OK,
    FREIGHT_TERMS_DELETED_ERROR,
    FREIGHT_TERMS_DELETED_OK,
    FREIGHT_TERMS_DOWNLOAD_ERROR,
    FREIGHT_TERMS_DOWNLOAD_OK,
    FREIGHT_TERMS_EDITED_ERROR,
    FREIGHT_TERMS_EDITED_OK,
    RETRIEVE_FREIGHT_TERMS_BULK_DELETE,
    RETRIEVE_FREIGHT_TERMS_DELETE,
    RETRIEVE_FREIGHT_TERMS_EDIT
} from "../../constants/modules/freightTermConstant";

const initialState = {
  freightTerms: [],
  error: false,
  loading: false,
  deleteFreightTerm: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_FREIGHT_TERMS_DOWNLOAD:
    case RETRIEVE_FREIGHT_TERMS_BULK_DELETE:
    case ADD_FREIGHT_TERMS:
    case BEGIN_EDIT_FREIGHT_TERMS:
      return {
        ...state,
        loading: action.payload,
        freightTerm: action.freightTerm,
      };

    case ADD_FREIGHT_TERMS_OK:
    case FREIGHT_TERMS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_FREIGHT_TERMS_ERROR:
    case FREIGHT_TERMS_DOWNLOAD_ERROR:
    case FREIGHT_TERMS_BULK_DELETED_ERROR:
    case FREIGHT_TERMS_DELETED_ERROR:
    case FREIGHT_TERMS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FREIGHT_TERMS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        freightTerms: action.payload,
      };

    case RETRIEVE_FREIGHT_TERMS_DELETE:
      return {
        ...state,
        deleteFreightTerm: action.payload,
      };

    case FREIGHT_TERMS_DELETED_OK:
      return {
        ...state,
        freightTerms: state.freightTerms.data.filter(
          (freightTerm) => freightTerm.id !== state.deleteFreightTerm
        ),
        deleteFreightTerm: null,
      };

    case RETRIEVE_FREIGHT_TERMS_EDIT:
      return {
        ...state,
        editFreightTerm: action.payload,
      };

    case FREIGHT_TERMS_EDITED_OK:
      return {
        ...state,
        editFreightTerm: null,
        freightTerms: state.freightTerms.data.map((freightTerm) =>
          freightTerm.id === action.payload.id
            ? (freightTerm = action.payload)
            : freightTerm
        ),
      };

    default:
      return state;
  }
}
