import {
  ADD_ORDERS,
  ADD_ORDERS_OK,
  ADD_ORDERS_ERROR,
  BEGIN_ORDERS_DOWNLOAD,
  ORDERS_DOWNLOAD_OK,
  ORDERS_DOWNLOAD_ERROR,
  RETRIEVE_ORDERS_DELETE,
  ORDERS_DELETED_OK,
  ORDERS_DELETED_ERROR,
  RETRIEVE_ORDERS_EDIT,
  BEGIN_EDIT_ORDERS,
  ORDERS_EDITED_OK,
  ORDERS_EDITED_ERROR,
  RETRIEVE_ORDERS_BULK_DELETE,
  ORDERS_BULK_DELETED_OK,
  ORDERS_BULK_DELETED_ERROR,
  RESET,
} from "../../constants/modules/ordersConstants";

export const downloadOrdersAction = (item) => ({
  type: BEGIN_ORDERS_DOWNLOAD,
  payload: true,
  order: item,
});

export const downloadOrdersOkAction = (items) => ({
  type: ORDERS_DOWNLOAD_OK,
  payload: items,
});

export const downloadOrdersErrorAction = () => ({
  type: ORDERS_DOWNLOAD_ERROR,
  payload: true,
});

export const addOrderAction = (item) => ({
  type: ADD_ORDERS,
  payload: true,
  order: item,
});

export const addOrderOkAction = (items) => ({
  type: ADD_ORDERS_OK,
  payload: items,
});

export const addOrderErrorAction = (state) => ({
  type: ADD_ORDERS_ERROR,
  payload: state,
});

export const deleteOrderAction = (id) => ({
  type: RETRIEVE_ORDERS_DELETE,
  payload: id,
});

export const deleteOrderOkAction = () => ({
  type: ORDERS_DELETED_OK,
});

export const deleteOrderErrorAction = () => ({
  type: ORDERS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteOrderAction = (item) => ({
  type: RETRIEVE_ORDERS_BULK_DELETE,
  payload: true,
  order: item,
});

export const bulkDeleteOrderOkAction = () => ({
  type: ORDERS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteOrderErrorAction = (state) => ({
  type: ORDERS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveOrderAction = (item) => ({
  type: RETRIEVE_ORDERS_EDIT,
  payload: item,
});

export const editOrderAction = (item) => ({
  type: BEGIN_EDIT_ORDERS,
  bank: item,
});

export const editOrderOkAction = (item) => ({
  type: ORDERS_EDITED_OK,
  payload: item,
});

export const editOrderErrorAction = () => ({
  type: ORDERS_EDITED_ERROR,
  payload: true,
});

export const orderReset = () => ({
  type: RESET,
  payload: true,
});
