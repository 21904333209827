export const ADD_RDL_INVOICES = "ADD_RDL_INVOICES";
export const ADD_RDL_INVOICES_OK = "ADD_RDL_INVOICES_OK";
export const ADD_RDL_INVOICES_ERROR = "ADD_RDL_INVOICES_ERROR";

export const BEGIN_RDL_INVOICES_DOWNLOAD = "BEGIN_RDL_INVOICES_DOWNLOAD";
export const RDL_INVOICES_DOWNLOAD_OK = "RDL_INVOICES_DOWNLOAD_OK";
export const RDL_INVOICES_DOWNLOAD_ERROR = "RDL_INVOICES_DOWNLOAD_ERROR";

export const BEGIN_RDL_INVOICE_DOWNLOAD = "BEGIN_RDL_INVOICE_DOWNLOAD";
export const RDL_INVOICE_DOWNLOAD_OK = "RDL_INVOICE_DOWNLOAD_OK";
export const RDL_INVOICE_DOWNLOAD_ERROR = "RDL_INVOICE_DOWNLOAD_ERROR";

export const RETRIEVE_RDL_INVOICES_DELETE = "RETRIEVE_RDL_INVOICES_DELETE";
export const RDL_INVOICES_DELETED_OK = "RDL_INVOICES_DELETED_OK";
export const RDL_INVOICES_DELETED_ERROR = "RDL_INVOICES_DELETED_ERROR";

export const RETRIEVE_RDL_INVOICES_EDIT = "RETRIEVE_RDL_INVOICES_EDITED";
export const BEGIN_EDIT_RDL_INVOICES = "BEGIN_EDIT_RDL_INVOICES";
export const RDL_INVOICES_EDITED_OK = "RDL_INVOICES_EDITED_OK";
export const RDL_INVOICES_EDITED_ERROR = "RDL_INVOICES_EDITED_ERROR";

export const RETRIEVE_RDL_INVOICES_BULK_DELETE =
  "RETRIEVE_RDL_INVOICES_BULK_DELETE";
export const RDL_INVOICES_BULK_DELETED_OK = "RDL_INVOICES_BULK_DELETED_OK";
export const RDL_INVOICES_BULK_DELETED_ERROR =
  "RDL_INVOICES_BULK_DELETED_ERROR";

export const RESET = "RESET";
