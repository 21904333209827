import {
  ADD_SALES_CONTRACT_ACTIONS,
  ADD_SALES_CONTRACT_ACTIONS_ERROR,
  ADD_SALES_CONTRACT_ACTIONS_OK,
  BEGIN_EDIT_SALES_CONTRACT_ACTIONS,
  BEGIN_SALES_CONTRACT_ACTIONS_DOWNLOAD,
  RETRIEVE_SALES_CONTRACT_ACTIONS_BULK_DELETE,
  RETRIEVE_SALES_CONTRACT_ACTIONS_DELETE,
  RETRIEVE_SALES_CONTRACT_ACTIONS_EDIT,
  SALES_CONTRACT_ACTIONS_BULK_DELETED_ERROR,
  SALES_CONTRACT_ACTIONS_BULK_DELETED_OK,
  SALES_CONTRACT_ACTIONS_DELETED_ERROR,
  SALES_CONTRACT_ACTIONS_DELETED_OK,
  SALES_CONTRACT_ACTIONS_DOWNLOAD_ERROR,
  SALES_CONTRACT_ACTIONS_DOWNLOAD_OK,
  SALES_CONTRACT_ACTIONS_EDITED_ERROR,
  SALES_CONTRACT_ACTIONS_EDITED_OK
} from "../../constants/modules/salesContractActionConstants";

export const bulkDeleteSalesContractActionAction = (item) => ({
  type: RETRIEVE_SALES_CONTRACT_ACTIONS_BULK_DELETE,
  payload: true,
  salesContractAction: item,
});

export const bulkDeleteSalesContractActionOkAction = () => ({
  type: SALES_CONTRACT_ACTIONS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteSalesContractActionErrorAction = (state) => ({
  type: SALES_CONTRACT_ACTIONS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadSalesContractActionsAction = () => ({
  type: BEGIN_SALES_CONTRACT_ACTIONS_DOWNLOAD,
  payload: true,
});

export const downloadSalesContractActionsOkAction = (items) => ({
  type: SALES_CONTRACT_ACTIONS_DOWNLOAD_OK,
  payload: items,
});

export const downloadSalesContractActionsErrorAction = () => ({
  type: SALES_CONTRACT_ACTIONS_DOWNLOAD_ERROR,
  payload: true,
});

export const addSalesContractActionAction = (item) => ({
  type: ADD_SALES_CONTRACT_ACTIONS,
  payload: true,
  salesContractAction: item,
});

export const addSalesContractActionOkAction = () => ({
  type: ADD_SALES_CONTRACT_ACTIONS_OK,
  payload: true,
});

export const addSalesContractActionErrorAction = (state) => ({
  type: ADD_SALES_CONTRACT_ACTIONS_ERROR,
  payload: state,
});

export const deleteSalesContractActionAction = (id) => ({
  type: RETRIEVE_SALES_CONTRACT_ACTIONS_DELETE,
  payload: id,
});

export const deleteSalesContractActionOkAction = () => ({
  type: SALES_CONTRACT_ACTIONS_DELETED_OK,
});

export const deleteSalesContractActionErrorAction = () => ({
  type: SALES_CONTRACT_ACTIONS_DELETED_ERROR,
  payload: true,
});

export const retrieveSalesContractActionAction = (item) => ({
  type: RETRIEVE_SALES_CONTRACT_ACTIONS_EDIT,
  payload: item,
});

export const editSalesContractActionAction = (item) => ({
  type: BEGIN_EDIT_SALES_CONTRACT_ACTIONS,
  salesContractAction: item,
});

export const editSalesContractActionOkAction = (item) => ({
  type: SALES_CONTRACT_ACTIONS_EDITED_OK,
  payload: item,
});

export const editSalesContractActionErrorAction = () => ({
  type: SALES_CONTRACT_ACTIONS_EDITED_ERROR,
  payload: true,
});
