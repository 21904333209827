import {
  PERMISSIONS_REQUEST,
  PERMISSIONS_REQUEST_OK,
  PERMISSIONS_REQUEST_ERROR,
} from "../../constants/auth/permissionsConstants";

export const permissionAction = (item) => ({
  type: PERMISSIONS_REQUEST,
  payload: true,
  permission: item,
});

export const permissionOkAction = (items) => ({
  type: PERMISSIONS_REQUEST_OK,
  payload: items,
});

export const permissionErrorAction = (state) => ({
  type: PERMISSIONS_REQUEST_ERROR,
  payload: state,
});
