import {
  ADD_COLORS, ADD_COLORS_ERROR, ADD_COLORS_OK, BEGIN_COLORS_DOWNLOAD, BEGIN_EDIT_COLORS, COLORS_BULK_DELETED_ERROR, COLORS_BULK_DELETED_OK, COLORS_DELETED_ERROR, COLORS_DELETED_OK, COLORS_DOWNLOAD_ERROR, COLORS_DOWNLOAD_OK, COLORS_EDITED_ERROR, COLORS_EDITED_OK, RETRIEVE_COLORS_BULK_DELETE, RETRIEVE_COLORS_DELETE, RETRIEVE_COLORS_EDIT
} from "../../constants/modules/colorsConstants.js";
  
  const initialState = {
    colors: [],
    error: false,
    loading: false,
    deleteColor: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_COLORS_DOWNLOAD:
      case RETRIEVE_COLORS_BULK_DELETE:
      case ADD_COLORS:
      case BEGIN_EDIT_COLORS:
        return {
          ...state,
          loading: action.payload,
          color: action.color,
        };
  
      case ADD_COLORS_OK:
      case COLORS_BULK_DELETED_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_COLORS_ERROR:
      case COLORS_DOWNLOAD_ERROR:
      case COLORS_BULK_DELETED_ERROR:
      case COLORS_DELETED_ERROR:
      case COLORS_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case COLORS_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          colors: action.payload,
        };
  
      case RETRIEVE_COLORS_DELETE:
        return {
          ...state,
          deleteColor: action.payload,
        };
  
      case COLORS_DELETED_OK:
        return {
          ...state,
          colors: state.colors.data.filter((color) => color.id !== state.deleteColor),
          deleteColor: null,
        };
  
      case RETRIEVE_COLORS_EDIT:
        return {
          ...state,
          editColor: action.payload,
        };
  
      case COLORS_EDITED_OK:
        return {
          ...state,
          editColor: null,
          colors: state.colors.data.map((color) =>
            color.id === action.payload.id ? (color = action.payload) : color
          ),
        };
  
      default:
        return state;
    }
  }
  