import {
    ADD_DISCOUNTS,
    ADD_DISCOUNTS_ERROR,
    ADD_DISCOUNTS_OK, BEGIN_DISCOUNTS_DOWNLOAD, BEGIN_DISCOUNT_DOWNLOAD, BEGIN_EDIT_DISCOUNTS, DISCOUNTS_BULK_DELETED_ERROR,
    DISCOUNTS_BULK_DELETED_OK,
    DISCOUNTS_DELETED_ERROR,
    DISCOUNTS_DELETED_OK,
    DISCOUNTS_DOWNLOAD_ERROR,
    DISCOUNTS_DOWNLOAD_OK,
    DISCOUNTS_EDITED_ERROR,
    DISCOUNTS_EDITED_OK, DISCOUNT_DOWNLOAD_ERROR,
    DISCOUNT_DOWNLOAD_OK, RESET, RETRIEVE_DISCOUNTS_BULK_DELETE, RETRIEVE_DISCOUNTS_DELETE, RETRIEVE_DISCOUNTS_EDIT
} from "../../constants/modules/discountsContants";
  
  export const bulkDeleteDiscountAction = (item) => ({
    type: RETRIEVE_DISCOUNTS_BULK_DELETE,
    payload: true,
    discount: item,
  });
  
  export const bulkDeleteDiscountOkAction = () => ({
    type: DISCOUNTS_BULK_DELETED_OK,
    payload: true,
  });
  
  export const bulkDeleteDiscountErrorAction = (state) => ({
    type: DISCOUNTS_BULK_DELETED_ERROR,
    payload: state,
  });
  
  export const downloadDiscountsAction = () => ({
    type: BEGIN_DISCOUNTS_DOWNLOAD,
    payload: true,
  });
  
  export const downloadDiscountsOkAction = (items) => ({
    type: DISCOUNTS_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadDiscountsErrorAction = () => ({
    type: DISCOUNTS_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const downloadDiscountAction = (item) => ({
    type: BEGIN_DISCOUNT_DOWNLOAD,
    payload: true,
    discount: item,
  });
  
  export const downloadDiscountOkAction = (items) => ({
    type: DISCOUNT_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadDiscountErrorAction = () => ({
    type: DISCOUNT_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const addDiscountAction = (item) => ({
    type: ADD_DISCOUNTS,
    payload: true,
    discount: item,
  });
  
  export const addDiscountOkAction = (items) => ({
    type: ADD_DISCOUNTS_OK,
    payload: items,
  });
  
  export const addDiscountErrorAction = (state) => ({
    type: ADD_DISCOUNTS_ERROR,
    payload: state,
  });
  
  export const deleteDiscountAction = (id) => ({
    type: RETRIEVE_DISCOUNTS_DELETE,
    payload: id,
  });
  
  export const deleteDiscountOkAction = () => ({
    type: DISCOUNTS_DELETED_OK,
  });
  
  export const deleteDiscountErrorAction = () => ({
    type: DISCOUNTS_DELETED_ERROR,
    payload: true,
  });
  
  export const retrieveDiscountAction = (item) => ({
    type: RETRIEVE_DISCOUNTS_EDIT,
    payload: item,
  });
  
  export const editDiscountAction = (item) => ({
    type: BEGIN_EDIT_DISCOUNTS,
    discount: item,
  });
  
  export const editDiscountOkAction = (item) => ({
    type: DISCOUNTS_EDITED_OK,
    payload: item,
  });
  
  export const editDiscountErrorAction = () => ({
    type: DISCOUNTS_EDITED_ERROR,
    payload: true,
  });
  
  export const DiscountReset = () => ({
    type: RESET,
    payload: true,
  });
  