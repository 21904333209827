import axiosClient from "../axiosClient";

export async function bulkDeleteFactoryShipmentDetailDB(ids) {
  return await axiosClient.post(`/factory_shipment_details/bulk_delete`, ids);
}

export async function retrieveFactoryShipmentDetailData() {
  return await axiosClient.get("/factory_shipment_details");
}

export async function retrieveFactoryShipmentDetailByFodData(id) {
  let reId = {
    id: id,
  };
  return await axiosClient.post("/factory_shipment_details/by_fod", reId);
}

export async function addFactoryShipmentDetailDB(value) {
  return await axiosClient.post("/factory_shipment_details", value);
}

export async function deleteFactoryShipmentDetailDB(id) {
  return await axiosClient.delete(`/factory_shipment_details/${id}`);
}

export async function editFactoryShipmentDetailDB(value) {
  return await axiosClient.put(`/factory_shipment_details/${value.id}`, value);
}
