export const ADD_USER_LEVEL_PRIVILEGES = "ADD_USER_LEVEL_PRIVILEGES";
export const ADD_USER_LEVEL_PRIVILEGES_OK = "ADD_USER_LEVEL_PRIVILEGES_OK";
export const ADD_USER_LEVEL_PRIVILEGES_ERROR = "ADD_USER_LEVEL_PRIVILEGES_ERROR";

export const BEGIN_USER_LEVEL_PRIVILEGES_DOWNLOAD = "BEGIN_USER_LEVEL_PRIVILEGES_DOWNLOAD";
export const USER_LEVEL_PRIVILEGES_DOWNLOAD_OK = "USER_LEVEL_PRIVILEGES_DOWNLOAD_OK";
export const USER_LEVEL_PRIVILEGES_DOWNLOAD_ERROR = "USER_LEVEL_PRIVILEGES_DOWNLOAD_ERROR";

export const RETRIEVE_USER_LEVEL_PRIVILEGES_DELETE = "RETRIEVE_USER_LEVEL_PRIVILEGES_DELETE";
export const USER_LEVEL_PRIVILEGES_DELETED_OK = "USER_LEVEL_PRIVILEGES_DELETED_OK";
export const USER_LEVEL_PRIVILEGES_DELETED_ERROR = "USER_LEVEL_PRIVILEGES_DELETED_ERROR";

export const RETRIEVE_USER_LEVEL_PRIVILEGES_EDIT = "RETRIEVE_USER_LEVEL_PRIVILEGES_EDITED";
export const BEGIN_EDIT_USER_LEVEL_PRIVILEGES = "BEGIN_EDIT_USER_LEVEL_PRIVILEGES";
export const USER_LEVEL_PRIVILEGES_EDITED_OK = "USER_LEVEL_PRIVILEGES_EDITED_OK";
export const USER_LEVEL_PRIVILEGES_EDITED_ERROR = "USER_LEVEL_PRIVILEGES_EDITED_ERROR";

export const RETRIEVE_USER_LEVEL_PRIVILEGES_BULK_DELETE = "RETRIEVE_USER_LEVEL_PRIVILEGES_BULK_DELETE";
export const USER_LEVEL_PRIVILEGES_BULK_DELETED_OK = "USER_LEVEL_PRIVILEGES_BULK_DELETED_OK";
export const USER_LEVEL_PRIVILEGES_BULK_DELETED_ERROR = "USER_LEVEL_PRIVILEGES_BULK_DELETED_ERROR";