import {
  ADD_LOADING_POINTS,
  ADD_LOADING_POINTS_ERROR, ADD_LOADING_POINTS_OK, BEGIN_EDIT_LOADING_POINTS, BEGIN_LOADING_POINTS_DOWNLOAD,
  LOADING_POINTS_BULK_DELETED_ERROR, LOADING_POINTS_BULK_DELETED_OK, LOADING_POINTS_DELETED_ERROR, LOADING_POINTS_DELETED_OK, LOADING_POINTS_DOWNLOAD_ERROR, LOADING_POINTS_DOWNLOAD_OK, LOADING_POINTS_EDITED_ERROR, LOADING_POINTS_EDITED_OK,
  RETRIEVE_LOADING_POINTS_BULK_DELETE, RETRIEVE_LOADING_POINTS_DELETE, RETRIEVE_LOADING_POINTS_EDIT
} from "../../constants/modules/loadingPointConstants";
  
  const initialState = {
    loadingPoints: [],
    error: false,
    loading: false,
    deleteLoadingPoint: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_LOADING_POINTS_DOWNLOAD:
      case RETRIEVE_LOADING_POINTS_BULK_DELETE:
      case ADD_LOADING_POINTS:
      case BEGIN_EDIT_LOADING_POINTS:
        return {
          ...state,
          loading: action.payload,
          loadingPoint: action.loadingPoint,
        };
  
      case ADD_LOADING_POINTS_OK:
      case LOADING_POINTS_BULK_DELETED_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_LOADING_POINTS_ERROR:
      case LOADING_POINTS_DOWNLOAD_ERROR:
      case LOADING_POINTS_BULK_DELETED_ERROR:
      case LOADING_POINTS_DELETED_ERROR:
      case LOADING_POINTS_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case LOADING_POINTS_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          loadingPoints: action.payload,
        };
  
      case RETRIEVE_LOADING_POINTS_DELETE:
        return {
          ...state,
          deleteLoadingPoint: action.payload,
        };
  
      case LOADING_POINTS_DELETED_OK:
        return {
          ...state,
          loadingPoints: state.loadingPoints.data.filter((loadingPoint) => loadingPoint.id !== state.deleteLoadingPoint),
          deleteLoadingPoint: null,
        };
  
      case RETRIEVE_LOADING_POINTS_EDIT:
        return {
          ...state,
          editLoadingPoint: action.payload,
        };
  
      case LOADING_POINTS_EDITED_OK:
        return {
          ...state,
          editLoadingPoint: null,
          loadingPoints: state.loadingPoints.data.map((loadingPoint) =>
          loadingPoint.id === action.payload.id ? (loadingPoint = action.payload) : loadingPoint
          ),
        };
  
      default:
        return state;
    }
  }
  