import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*         Sales Contact ACTIONS API CALLS         */
/*-------------------------------------------------*/
export async function bulkDeleteSalesContractActionDB(ids) {
  return await axiosClient.post(`/sales_contract_details/bulk_delete`, ids);
}

export async function retrieveSalesContractActionsData() {
  return await axiosClient.get("/sales_contract_details");
}

export async function addSalesContractActionDB(value) {
  return await axiosClient.post("/sales_contract_details", value);
}

export async function deleteSalesContractActionDB(id) {
  return await axiosClient.delete(`/sales_contract_details/${id}`);
}

export async function editSalesContractActionDB(value) {
  return await axiosClient.put(`/sales_contract_details/${value.id}`, value);
}
