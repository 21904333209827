import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_SAMPLE_COMMENTS,
  BEGIN_EDIT_SAMPLE_COMMENTS,
  BEGIN_SAMPLE_COMMENTS_DOWNLOAD,
  RETRIEVE_SAMPLE_COMMENTS_BULK_DELETE,
  RETRIEVE_SAMPLE_COMMENTS_DELETE
} from "../../constants/modules/sampleCommentsConstant";

import {
  addSampleCommentErrorAction,
  addSampleCommentOkAction,
  bulkDeleteSampleCommentErrorAction,
  bulkDeleteSampleCommentOkAction,
  deleteSampleCommentErrorAction,
  deleteSampleCommentOkAction,
  downloadSampleCommentsErrorAction,
  downloadSampleCommentsOkAction,
  editSampleCommentErrorAction,
  editSampleCommentOkAction
} from "../../actions/modules/sampleCommentsActions";

import {
  addSampleCommentDB,
  bulkDeleteSampleCommentDB,
  deleteSampleCommentDB,
  editSampleCommentDB,
  retrieveSampleCommentsData
} from "../../../config/apiCalls/sampleCommentsAPICall";


// Retrieve products
// worker saga
export function* retrieveSampleComment() {
  try {
    const response = yield call(retrieveSampleCommentsData);

    if (response.status === 200) {
      yield put(downloadSampleCommentsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSampleCommentsErrorAction());
  }
}

// Create new product
// worker saga
export function* addSampleComment(action) {
  const sampleComment = action.sampleComment;

  console.log(sampleComment);

  try {
    const response = yield call(addSampleCommentDB, sampleComment);

    if (response.status === 201) {
      yield delay(500);

      yield put(addSampleCommentOkAction(sampleComment));
      toast.success("SampleComment created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addSampleCommentErrorAction(true));
    toast.error("SampleComment created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteSampleComment(action) {
  const id = action.payload;
  try {
    yield call(deleteSampleCommentDB, id);
    yield deleteSampleCommentOkAction();
    toast.success("SampleComment deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteSampleCommentErrorAction();
  }
}

// Edit product
// worker saga
export function* editSampleComment(action) {
  const sampleComment = action.sampleComment;

  try {
    yield call(editSampleCommentDB, sampleComment);
    yield editSampleCommentOkAction(sampleComment);
    // Alert
    // Alert
    toast.success("SampleComment updated Successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editSampleCommentErrorAction();
  }
}

// BULK SAGAS
export function* bulkSampleCommentDelete(action) {
  const sampleComment = action.sampleComment;

  try {
    const response = yield call(bulkDeleteSampleCommentDB, sampleComment);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteSampleCommentOkAction(sampleComment));
      toast.success("Bulk SampleComment deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteSampleCommentErrorAction(true));
    toast.error("Bulk SampleComment deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchSampleCommentsAsync() {
  yield takeLatest(BEGIN_SAMPLE_COMMENTS_DOWNLOAD, retrieveSampleComment);
  yield takeLatest(ADD_SAMPLE_COMMENTS, addSampleComment);
  yield takeLatest(RETRIEVE_SAMPLE_COMMENTS_DELETE, deleteSampleComment);
  yield takeLatest(BEGIN_EDIT_SAMPLE_COMMENTS, editSampleComment);
  yield takeLatest(RETRIEVE_SAMPLE_COMMENTS_BULK_DELETE, bulkSampleCommentDelete);
}
