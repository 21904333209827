export const ADD_PAYMENT_TERMS = "ADD_PAYMENT_TERMS";
export const ADD_PAYMENT_TERMS_OK = "ADD_PAYMENT_TERMS_OK";
export const ADD_PAYMENT_TERMS_ERROR = "ADD_PAYMENT_TERMS_ERROR";

export const BEGIN_PAYMENT_TERMS_DOWNLOAD = "BEGIN_PAYMENT_TERMS_DOWNLOAD";
export const PAYMENT_TERMS_DOWNLOAD_OK = "PAYMENT_TERMS_DOWNLOAD_OK";
export const PAYMENT_TERMS_DOWNLOAD_ERROR = "PAYMENT_TERMS_DOWNLOAD_ERROR";

export const RETRIEVE_PAYMENT_TERMS_DELETE = "RETRIEVE_PAYMENT_TERMS_DELETE";
export const PAYMENT_TERMS_DELETED_OK = "PAYMENT_TERMS_DELETED_OK";
export const PAYMENT_TERMS_DELETED_ERROR = "PAYMENT_TERMS_DELETED_ERROR";

export const RETRIEVE_PAYMENT_TERMS_EDIT = "RETRIEVE_PAYMENT_TERMS_EDITED";
export const BEGIN_EDIT_PAYMENT_TERMS = "BEGIN_EDIT_PAYMENT_TERMS";
export const PAYMENT_TERMS_EDITED_OK = "PAYMENT_TERMS_EDITED_OK";
export const PAYMENT_TERMS_EDITED_ERROR = "PAYMENT_TERMS_EDITED_ERROR";

export const RETRIEVE_PAYMENT_TERMS_BULK_DELETE = "RETRIEVE_PAYMENT_TERMS_BULK_DELETE";
export const PAYMENT_TERMS_BULK_DELETED_OK = "PAYMENT_TERMS_BULK_DELETED_OK";
export const PAYMENT_TERMS_BULK_DELETED_ERROR = "PAYMENT_TERMS_BULK_DELETED_ERROR";