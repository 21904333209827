import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_BANKS,
  BEGIN_BANKS_DOWNLOAD,
  BEGIN_EDIT_BANKS,
  RETRIEVE_BANKS_BULK_DELETE,
  RETRIEVE_BANKS_DELETE
} from "../../constants/modules/banksConstants";

import {
  addBankErrorAction,
  addBankOkAction,
  bulkDeleteBankErrorAction,
  bulkDeleteBankOkAction,
  deleteBankErrorAction,
  deleteBankOkAction,
  downloadBanksErrorAction,
  downloadBanksOkAction,
  editBankErrorAction,
  editBankOkAction
} from "../../actions/modules/banksActions";

import {
  addBankDB,
  bulkDeleteBankDB,
  deleteBankDB,
  editBankDB,
  retrieveBankDB
} from "../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveBanks() {
  try {
    const response = yield call(retrieveBankDB);
    if (response.status === 200) {
      yield put(downloadBanksOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadBanksErrorAction());
  }
}

// Create new product
// worker saga
export function* addBank(action) {
  const bank = action.bank;
  try {
    const response = yield call(addBankDB, bank);
    if (response.status === 201) {
      yield delay(500);
      yield put(addBankOkAction(bank));
      toast.success("Bank created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addBankErrorAction(true));
    toast.error("Bank created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteBank(action) {
  const id = action.payload;
  try {
    yield call(deleteBankDB, id);
    yield deleteBankOkAction();
    toast.success("Bank deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteBankErrorAction());
    toast.error("Bank deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkBankDelete(action) {
  const bank = action.bank;

  try {
    const response = yield call(bulkDeleteBankDB, bank);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteBankOkAction(bank));
      toast.success("Bulk Bank deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteBankErrorAction(true));
    toast.error("Bulk Bank deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editBank(action) {
  const bank = action.bank;

  try {
    const response = yield call(editBankDB, bank);

    if (response.status === 201) {
      yield put(editBankOkAction(bank));
      toast.success("Bank edited successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editBankErrorAction());
    toast.error("Bank deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchBanksAsync() {
  yield takeLatest(BEGIN_BANKS_DOWNLOAD, retrieveBanks);
  yield takeLatest(ADD_BANKS, addBank);
  yield takeLatest(RETRIEVE_BANKS_DELETE, deleteBank);
  yield takeLatest(BEGIN_EDIT_BANKS, editBank);
  yield takeLatest(RETRIEVE_BANKS_BULK_DELETE, bulkBankDelete);
}
