import axiosClient from "../axiosClient";

export async function retrieveBuyerDepartmentDB() {
  return await axiosClient.get("/buyer_departments");
}

export async function addBuyerDepartmentDB(value) {
  return await axiosClient.post("/buyer_departments", value);
}

export async function retrieveBuyerDepartmentByBrandDB(id) {
  let reId = {
    id: id,
  };
  return await axiosClient.post("/buyer_departments/get_by_id", reId);
}

export async function deleteBuyerDepartmentDB(id) {
  return await axiosClient.delete(`/buyer_departments/${id}`);
}

export async function bulkDeleteBuyerDepartmentDB(ids) {
  return await axiosClient.post(`/buyer_departments/bulk_delete`, ids);
}

export async function editBuyerDepartmentDB(value) {
  return await axiosClient.put(`/buyer_departments/${value.id}`, value);
}
