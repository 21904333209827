import {
    ADD_TT_ADVANCE_DETAILS,
    ADD_TT_ADVANCE_DETAILS_ERROR,
    ADD_TT_ADVANCE_DETAILS_OK,
    BEGIN_EDIT_TT_ADVANCE_DETAILS,
    BEGIN_TT_ADVANCE_DETAILS_DOWNLOAD,
    BEGIN_TT_ADVANCE_DETAIL_DOWNLOAD,
    RESET,
    RETRIEVE_TT_ADVANCE_DETAILS_DELETE,
    RETRIEVE_TT_ADVANCE_DETAILS_EDIT,
    TT_ADVANCE_DETAILS_DELETED_ERROR,
    TT_ADVANCE_DETAILS_DELETED_OK,
    TT_ADVANCE_DETAILS_DOWNLOAD_ERROR,
    TT_ADVANCE_DETAILS_DOWNLOAD_OK,
    TT_ADVANCE_DETAILS_EDITED_ERROR,
    TT_ADVANCE_DETAILS_EDITED_OK,
    TT_ADVANCE_DETAIL_DOWNLOAD_ERROR,
    TT_ADVANCE_DETAIL_DOWNLOAD_OK
} from "../../constants/modules/TTAdvanceDetailConstant";

export const downloadTTAdvanceDetailsAction = () => ({
  type: BEGIN_TT_ADVANCE_DETAILS_DOWNLOAD,
  payload: true,
});

export const downloadTTAdvanceDetailsOkAction = (items) => ({
  type: TT_ADVANCE_DETAILS_DOWNLOAD_OK,
  payload: items,
});

export const downloadTTAdvanceDetailsErrorAction = () => ({
  type: TT_ADVANCE_DETAILS_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadTTAdvanceDetailAction = (item) => ({
  type: BEGIN_TT_ADVANCE_DETAIL_DOWNLOAD,
  payload: true,
  ttAdvanceDetail: item,
});

export const downloadTTAdvanceDetailOkAction = (items) => ({
  type: TT_ADVANCE_DETAIL_DOWNLOAD_OK,
  payload: items,
});

export const downloadTTAdvanceDetailErrorAction = () => ({
  type: TT_ADVANCE_DETAIL_DOWNLOAD_ERROR,
  payload: true,
});

export const addTTAdvanceDetailAction = (item) => ({
  type: ADD_TT_ADVANCE_DETAILS,
  payload: true,
  ttAdvanceDetail: item,
});

export const addTTAdvanceDetailOkAction = (items) => ({
  type: ADD_TT_ADVANCE_DETAILS_OK,
  payload: items,
});

export const addTTAdvanceDetailErrorAction = (state) => ({
  type: ADD_TT_ADVANCE_DETAILS_ERROR,
  payload: state,
});

export const deleteTTAdvanceDetailAction = (id) => ({
  type: RETRIEVE_TT_ADVANCE_DETAILS_DELETE,
  payload: id,
});

export const deleteTTAdvanceDetailOkAction = () => ({
  type: TT_ADVANCE_DETAILS_DELETED_OK,
});

export const deleteTTAdvanceDetailErrorAction = () => ({
  type: TT_ADVANCE_DETAILS_DELETED_ERROR,
  payload: true,
});

export const retrieveTTAdvanceDetailAction = (item) => ({
  type: RETRIEVE_TT_ADVANCE_DETAILS_EDIT,
  payload: item,
});

export const editTTAdvanceDetailAction = (item) => ({
  type: BEGIN_EDIT_TT_ADVANCE_DETAILS,
  ttAdvanceDetail: item,
});

export const editTTAdvanceDetailOkAction = (item) => ({
  type: TT_ADVANCE_DETAILS_EDITED_OK,
  payload: item,
});

export const editTTAdvanceDetailErrorAction = () => ({
  type: TT_ADVANCE_DETAILS_EDITED_ERROR,
  payload: true,
});

export const TTAdvanceDetailReset = () => ({
  type: RESET,
  payload: true,
});
