import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_FACTORY_SHIPMENT_DETAILS,
  BEGIN_FACTORY_SHIPMENT_DETAILS_DOWNLOAD,
  RETRIEVE_FACTORY_SHIPMENT_DETAILS_DELETE,
  BEGIN_EDIT_FACTORY_SHIPMENT_DETAILS,
  RETRIEVE_FACTORY_SHIPMENT_DETAILS_BULK_DELETE,
  BEGIN_SHIPMENT_DETAILS_BY_FOD_DOWNLOAD,
} from "../../constants/modules/factoryShipmentDetailsConstants";

import {
  downloadFactoryShipmentDetailsOkAction,
  downloadFactoryShipmentDetailsErrorAction,
  addFactoryShipmentDetailOkAction,
  addFactoryShipmentDetailErrorAction,
  deleteFactoryShipmentDetailOkAction,
  deleteFactoryShipmentDetailErrorAction,
  editFactoryShipmentDetailOkAction,
  editFactoryShipmentDetailErrorAction,
  bulkDeleteFactoryShipmentDetailOkAction,
  bulkDeleteFactoryShipmentDetailErrorAction,
  downloadFactoryShipmentDetailsByFodOkAction,
  downloadFactoryShipmentDetailsByFodErrorAction,
} from "../../actions/modules/factoryShipmentDetailsActions";

import {
  retrieveFactoryShipmentDetailData,
  addFactoryShipmentDetailDB,
  deleteFactoryShipmentDetailDB,
  editFactoryShipmentDetailDB,
  bulkDeleteFactoryShipmentDetailDB,
  retrieveFactoryShipmentDetailByFodData,
} from "../../../config/apiCalls/factoryShipmentDetails";

// Retrieve products
// worker saga
export function* retrieveFactoryShipmentDetails() {
  try {
    const response = yield call(retrieveFactoryShipmentDetailData);
    if (response.status === 200) {
      yield put(downloadFactoryShipmentDetailsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFactoryShipmentDetailsErrorAction());
  }
}

export function* retrieveFactoryShipmentDetailsByFod(action) {
  const factoryShipmentDetail = action.factoryShipmentDetail;
  try {
    const response = yield call(
      retrieveFactoryShipmentDetailByFodData,
      factoryShipmentDetail
    );
    if (response.status === 200) {
      yield put(downloadFactoryShipmentDetailsByFodOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFactoryShipmentDetailsByFodErrorAction());
  }
}

// Create new product
// worker saga
export function* addFactoryShipmentDetail(action) {
  const factoryShipmentDetail = action.factoryShipmentDetail;

  try {
    const response = yield call(
      addFactoryShipmentDetailDB,
      factoryShipmentDetail
    );

    if (response.status === 200) {
      yield delay(500);
      yield put(addFactoryShipmentDetailOkAction(response.data));
      toast.success("Factory Shipment Detail Updated!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addFactoryShipmentDetailErrorAction(true));
    toast.error("Factory Shipment Detail Update failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteFactoryShipmentDetail(action) {
  const id = action.payload;
  try {
    yield call(deleteFactoryShipmentDetailDB, id);
    yield deleteFactoryShipmentDetailOkAction();
    toast.success("Factory ShipmentDetail deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteFactoryShipmentDetailErrorAction());
    toast.error("Factory ShipmentDetail deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkFactoryShipmentDetailDelete(action) {
  const factoryShipmentDetail = action.factoryShipmentDetail;

  try {
    const response = yield call(
      bulkDeleteFactoryShipmentDetailDB,
      factoryShipmentDetail
    );
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteFactoryShipmentDetailOkAction(factoryShipmentDetail));
      toast.success("Factory ShipmentDetails deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteFactoryShipmentDetailErrorAction(true));
    toast.error("Factory ShipmentDetails deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editFactoryShipmentDetail(action) {
  const factoryShipmentDetail = action.factoryShipmentDetail;

  try {
    const response = yield call(
      editFactoryShipmentDetailDB,
      factoryShipmentDetail
    );

    if (response.status === 201) {
      yield delay(500);
      yield put(editFactoryShipmentDetailOkAction(factoryShipmentDetail));
      toast.success("Factory ShipmentDetail edited successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editFactoryShipmentDetailErrorAction());
    toast.error("Factory ShipmentDetail deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchFactoryShipmentDetailsAsync() {
  yield takeLatest(
    BEGIN_FACTORY_SHIPMENT_DETAILS_DOWNLOAD,
    retrieveFactoryShipmentDetails
  );
  yield takeLatest(
    BEGIN_SHIPMENT_DETAILS_BY_FOD_DOWNLOAD,
    retrieveFactoryShipmentDetailsByFod
  );
  yield takeLatest(ADD_FACTORY_SHIPMENT_DETAILS, addFactoryShipmentDetail);
  yield takeLatest(
    RETRIEVE_FACTORY_SHIPMENT_DETAILS_DELETE,
    deleteFactoryShipmentDetail
  );
  yield takeLatest(
    BEGIN_EDIT_FACTORY_SHIPMENT_DETAILS,
    editFactoryShipmentDetail
  );
  yield takeLatest(
    RETRIEVE_FACTORY_SHIPMENT_DETAILS_BULK_DELETE,
    bulkFactoryShipmentDetailDelete
  );
}
