import {
  ADD_SUB_CATEGORIES,
  ADD_SUB_CATEGORIES_ERROR,
  ADD_SUB_CATEGORIES_OK,
  BEGIN_EDIT_SUB_CATEGORIES,
  BEGIN_SUB_CATEGORIES_DOWNLOAD,
  RETRIEVE_SUB_CATEGORIES_BULK_DELETE,
  RETRIEVE_SUB_CATEGORIES_DELETE,
  RETRIEVE_SUB_CATEGORIES_EDIT,
  SUB_CATEGORIES_BULK_DELETED_ERROR,
  SUB_CATEGORIES_BULK_DELETED_OK,
  SUB_CATEGORIES_DELETED_ERROR,
  SUB_CATEGORIES_DELETED_OK,
  SUB_CATEGORIES_DOWNLOAD_ERROR,
  SUB_CATEGORIES_DOWNLOAD_OK,
  SUB_CATEGORIES_EDITED_ERROR,
  SUB_CATEGORIES_EDITED_OK,
} from "../../constants/modules/subCategoriesConstants";

const initialState = {
  subCategories: [],
  error: false,
  loading: false,
  deleteSubCategory: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_SUB_CATEGORIES_DOWNLOAD:
    case RETRIEVE_SUB_CATEGORIES_BULK_DELETE:
    case ADD_SUB_CATEGORIES:
    case BEGIN_EDIT_SUB_CATEGORIES:
      return {
        ...state,
        loading: action.payload,
        subCategory: action.subCategory,
      };

    case ADD_SUB_CATEGORIES_OK:
    case SUB_CATEGORIES_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_SUB_CATEGORIES_ERROR:
    case SUB_CATEGORIES_DOWNLOAD_ERROR:
    case SUB_CATEGORIES_BULK_DELETED_ERROR:
    case SUB_CATEGORIES_DELETED_ERROR:
    case SUB_CATEGORIES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SUB_CATEGORIES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        subCategories: action.payload,
      };

    case RETRIEVE_SUB_CATEGORIES_DELETE:
      return {
        ...state,
        deleteSubCategory: action.payload,
      };

    case SUB_CATEGORIES_DELETED_OK:
      return {
        ...state,
        subCategories: state.subCategories.data.filter(
          (subCategory) => subCategory.id !== state.deleteSubCategory
        ),
        deleteSubCategory: null,
      };

    case RETRIEVE_SUB_CATEGORIES_EDIT:
      return {
        ...state,
        editSubCategory: action.payload,
      };

    case SUB_CATEGORIES_EDITED_OK:
      return {
        ...state,
        editSubCategory: null,
        subCategories: state.subCategories.data.map((subCategory) =>
          subCategory.id === action.payload.id
            ? (subCategory = action.payload)
            : subCategory
        ),
      };

    default:
      return state;
  }
}
