import {
    ADD_COMMISSION_INVOICES,
    ADD_COMMISSION_INVOICES_ERROR,
    ADD_COMMISSION_INVOICES_OK,
    BEGIN_COMMISSION_INVOICES_DOWNLOAD,
    BEGIN_EDIT_COMMISSION_INVOICES,
    COMMISSION_INVOICES_BULK_DELETED_ERROR,
    COMMISSION_INVOICES_BULK_DELETED_OK,
    COMMISSION_INVOICES_DELETED_ERROR,
    COMMISSION_INVOICES_DELETED_OK,
    COMMISSION_INVOICES_DOWNLOAD_ERROR,
    COMMISSION_INVOICES_DOWNLOAD_OK,
    COMMISSION_INVOICES_EDITED_ERROR,
    COMMISSION_INVOICES_EDITED_OK,
    RETRIEVE_COMMISSION_INVOICES_BULK_DELETE,
    RETRIEVE_COMMISSION_INVOICES_DELETE,
    RETRIEVE_COMMISSION_INVOICES_EDIT
} from "../../constants/modules/commissionInvoiceConstant";

/*------------------------------------------------------------*/
/*                       BULK ACTIONS                         */
/*------------------------------------------------------------*/
export const bulkDeleteCommissionInvoiceAction = (item) => ({
  type: RETRIEVE_COMMISSION_INVOICES_BULK_DELETE,
  payload: true,
  commissionInvoice: item,
});

export const bulkDeleteCommissionInvoiceOkAction = () => ({
  type: COMMISSION_INVOICES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteCommissionInvoiceErrorAction = (state) => ({
  type: COMMISSION_INVOICES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadCommissionInvoicesAction = () => ({
  type: BEGIN_COMMISSION_INVOICES_DOWNLOAD,
  payload: true,
});

export const downloadCommissionInvoicesOkAction = (items) => ({
  type: COMMISSION_INVOICES_DOWNLOAD_OK,
  payload: items,
});

export const downloadCommissionInvoicesErrorAction = () => ({
  type: COMMISSION_INVOICES_DOWNLOAD_ERROR,
  payload: true,
});

export const addCommissionInvoiceAction = (item) => ({
  type: ADD_COMMISSION_INVOICES,
  payload: true,
  commissionInvoice: item,
});

export const addCommissionInvoiceOkAction = () => ({
  type: ADD_COMMISSION_INVOICES_OK,
  payload: true,
});

export const addCommissionInvoiceErrorAction = (state) => ({
  type: ADD_COMMISSION_INVOICES_ERROR,
  payload: state,
});

export const deleteCommissionInvoiceAction = (id) => ({
  type: RETRIEVE_COMMISSION_INVOICES_DELETE,
  payload: id,
});

export const deleteCommissionInvoiceOkAction = () => ({
  type: COMMISSION_INVOICES_DELETED_OK,
});

export const deleteCommissionInvoiceErrorAction = () => ({
  type: COMMISSION_INVOICES_DELETED_ERROR,
  payload: true,
});

export const retrieveCommissionInvoiceAction = (item) => ({
  type: RETRIEVE_COMMISSION_INVOICES_EDIT,
  payload: item,
});

export const editCommissionInvoiceAction = (item) => ({
  type: BEGIN_EDIT_COMMISSION_INVOICES,
  commissionInvoice: item,
});

export const editCommissionInvoiceOkAction = (item) => ({
  type: COMMISSION_INVOICES_EDITED_OK,
  payload: item,
});

export const editCommissionInvoiceErrorAction = () => ({
  type: COMMISSION_INVOICES_EDITED_ERROR,
  payload: true,
});
