import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";

import {
  addFabricTypeErrorAction,
  addFabricTypeOkAction,
  bulkDeleteFabricTypeErrorAction,
  bulkDeleteFabricTypeOkAction,
  deleteFabricTypeErrorAction,
  deleteFabricTypeOkAction,
  downloadFabricTypesByFKErrorAction,
  downloadFabricTypesByFKOkAction,
  downloadFabricTypesErrorAction,
  downloadFabricTypesOkAction,
  editFabricTypeErrorAction,
  editFabricTypeOkAction
} from "../../actions/modules/fabricTypesActions";

import {
  addFabricTypeDB,
  bulkDeleteFabricTypeDB,
  deleteFabricTypeDB,
  editFabricTypeDB,
  retrieveFabricTypesByFkDB,
  retrieveFabricTypesData
} from "../../../config/apiCalls/fabricTypes";

import {
  ADD_FABRIC_TYPES,
  BEGIN_EDIT_FABRIC_TYPES,
  BEGIN_FABRIC_TYPES_BY_FK_DOWNLOAD,
  BEGIN_FABRIC_TYPES_DOWNLOAD,
  RETRIEVE_FABRIC_TYPES_BULK_DELETE,
  RETRIEVE_FABRIC_TYPES_DELETE
} from "../../constants/modules/fabricsTypeConstants";

// Retrieve products
// worker saga
export function* retrieveFabricTypes() {
  try {
    const response = yield call(retrieveFabricTypesData);

    if (response.status === 200) {
      yield put(downloadFabricTypesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFabricTypesErrorAction());
  }
}

export function* retrieveFabricTypesByFk(action) {
  const fabricType = action.fabricType;
  try {
    const response = yield call(retrieveFabricTypesByFkDB, fabricType);

    if (response.status === 200) {
      yield put(downloadFabricTypesByFKOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFabricTypesByFKErrorAction());
  }
}

// Create new product
// worker saga
export function* addFabricType(action) {
  const fabricType = action.fabricType;

  try {
    const response = yield call(addFabricTypeDB, fabricType);

    if (response.status === 201) {
      yield delay(2000);

      yield put(addFabricTypeOkAction(fabricType));
      toast.success("Fabric Type created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addFabricTypeErrorAction(true));
    toast.error("Fabric Type created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkFabricTypeDelete(action) {
  const fabricType = action.fabricType;

  try {
    const response = yield call(bulkDeleteFabricTypeDB, fabricType);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteFabricTypeOkAction(fabricType));
      toast.success("Bulk Fabric Type deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteFabricTypeErrorAction(true));
    toast.error("Bulk Fabric Type deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteFabricType(action) {
  const id = action.payload;
  try {
    yield call(deleteFabricTypeDB, id);
    yield deleteFabricTypeOkAction();
    toast.success("Fabric Type deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteFabricTypeErrorAction();
  }
}

// Edit product
// worker saga
export function* editFabricType(action) {
  const fabricType = action.fabricType;

  try {
    yield call(editFabricTypeDB, fabricType);
    yield editFabricTypeOkAction(fabricType);
    // Alert
    toast.success("Fabric Type has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editFabricTypeErrorAction();
  }
}

export function* watchFabricTypesAsync() {
  yield takeLatest(BEGIN_FABRIC_TYPES_DOWNLOAD, retrieveFabricTypes);
  yield takeLatest(BEGIN_FABRIC_TYPES_BY_FK_DOWNLOAD, retrieveFabricTypesByFk);
  yield takeLatest(ADD_FABRIC_TYPES, addFabricType);
  yield takeLatest(RETRIEVE_FABRIC_TYPES_DELETE, deleteFabricType);
  yield takeLatest(BEGIN_EDIT_FABRIC_TYPES, editFabricType);
  yield takeLatest(RETRIEVE_FABRIC_TYPES_BULK_DELETE, bulkFabricTypeDelete);
}
