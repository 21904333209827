export const ADD_FABRIC_TYPES = "ADD_FABRIC_TYPES";
export const ADD_FABRIC_TYPES_OK = "ADD_FABRIC_TYPES_OK";
export const ADD_FABRIC_TYPES_ERROR = "ADD_FABRIC_TYPES_ERROR";

export const BEGIN_FABRIC_TYPES_DOWNLOAD = "BEGIN_FABRIC_TYPES_DOWNLOAD";
export const FABRIC_TYPES_DOWNLOAD_OK = "FABRIC_TYPES_DOWNLOAD_OK";
export const FABRIC_TYPES_DOWNLOAD_ERROR = "FABRIC_TYPES_DOWNLOAD_ERROR";

export const BEGIN_FABRIC_TYPES_BY_FK_DOWNLOAD =
  "BEGIN_FABRIC_TYPES_BY_FK_DOWNLOAD";
export const FABRIC_TYPES_BY_FK_DOWNLOAD_OK = "FABRIC_TYPES_BY_FK_DOWNLOAD_OK";
export const FABRIC_TYPES_BY_FK_DOWNLOAD_ERROR =
  "FABRIC_TYPES_BY_FK_DOWNLOAD_ERROR";

export const RETRIEVE_FABRIC_TYPES_DELETE = "RETRIEVE_FABRIC_TYPES_DELETE";
export const FABRIC_TYPES_DELETED_OK = "FABRIC_TYPES_DELETED_OK";
export const FABRIC_TYPES_DELETED_ERROR = "FABRIC_TYPES_DELETED_ERROR";

export const RETRIEVE_FABRIC_TYPES_EDIT = "RETRIEVE_FABRIC_TYPES_EDITED";
export const BEGIN_EDIT_FABRIC_TYPES = "BEGIN_EDIT_FABRIC_TYPES";
export const FABRIC_TYPES_EDITED_OK = "FABRIC_TYPES_EDITED_OK";
export const FABRIC_TYPES_EDITED_ERROR = "FABRIC_TYPES_EDITED_ERROR";

export const RETRIEVE_FABRIC_TYPES_BULK_DELETE =
  "RETRIEVE_FABRIC_TYPES_BULK_DELETE";
export const FABRIC_TYPES_BULK_DELETED_OK = "FABRIC_TYPES_BULK_DELETED_OK";
export const FABRIC_TYPES_BULK_DELETED_ERROR =
  "FABRIC_TYPES_BULK_DELETED_ERROR";
