export const ADD_OVERSEAS_OFFICES = "ADD_OVERSEAS_OFFICES";
export const ADD_OVERSEAS_OFFICES_OK = "ADD_OVERSEAS_OFFICES_OK";
export const ADD_OVERSEAS_OFFICES_ERROR = "ADD_OVERSEAS_OFFICES_ERROR";

export const BEGIN_OVERSEAS_OFFICES_DOWNLOAD = "BEGIN_OVERSEAS_OFFICES_DOWNLOAD";
export const OVERSEAS_OFFICES_DOWNLOAD_OK = "OVERSEAS_OFFICES_DOWNLOAD_OK";
export const OVERSEAS_OFFICES_DOWNLOAD_ERROR = "OVERSEAS_OFFICES_DOWNLOAD_ERROR";

export const RETRIEVE_OVERSEAS_OFFICES_DELETE = "RETRIEVE_OVERSEAS_OFFICES_DELETE";
export const OVERSEAS_OFFICES_DELETED_OK = "OVERSEAS_OFFICES_DELETED_OK";
export const OVERSEAS_OFFICES_DELETED_ERROR = "OVERSEAS_OFFICES_DELETED_ERROR";

export const RETRIEVE_OVERSEAS_OFFICES_EDIT = "RETRIEVE_OVERSEAS_OFFICES_EDITED";
export const BEGIN_EDIT_OVERSEAS_OFFICES = "BEGIN_EDIT_OVERSEAS_OFFICES";
export const OVERSEAS_OFFICES_EDITED_OK = "OVERSEAS_OFFICES_EDITED_OK";
export const OVERSEAS_OFFICES_EDITED_ERROR = "OVERSEAS_OFFICES_EDITED_ERROR";


export const RETRIEVE_OVERSEAS_OFFICES_BULK_DELETE = "RETRIEVE_OVERSEAS_OFFICES_BULK_DELETE";
export const OVERSEAS_OFFICES_BULK_DELETED_OK = "OVERSEAS_OFFICES_BULK_DELETED_OK";
export const OVERSEAS_OFFICES_BULK_DELETED_ERROR = "OVERSEAS_OFFICES_BULK_DELETED_ERROR";