import {
  ADD_EX_FACTORIES,
  ADD_EX_FACTORIES_ERROR,
  ADD_EX_FACTORIES_OK,
  BEGIN_EDIT_EX_FACTORIES,
  BEGIN_EX_FACTORIES_DOWNLOAD,
  EX_FACTORIES_BULK_DELETED_ERROR,
  EX_FACTORIES_BULK_DELETED_OK,
  EX_FACTORIES_DELETED_ERROR,
  EX_FACTORIES_DELETED_OK,
  EX_FACTORIES_DOWNLOAD_ERROR,
  EX_FACTORIES_DOWNLOAD_OK,
  EX_FACTORIES_EDITED_ERROR,
  EX_FACTORIES_EDITED_OK,
  RETRIEVE_EX_FACTORIES_BULK_DELETE,
  RETRIEVE_EX_FACTORIES_DELETE,
  RETRIEVE_EX_FACTORIES_EDIT,
} from "../../constants/modules/exFactoriesConstants.js";

const initialState = {
  exFactories: [],
  error: false,
  loading: false,
  deleteExFactory: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_EX_FACTORIES_DOWNLOAD:
    case ADD_EX_FACTORIES:
    case RETRIEVE_EX_FACTORIES_BULK_DELETE:
    case BEGIN_EDIT_EX_FACTORIES:
      return {
        ...state,
        loading: action.payload,
        exFactory: action.exFactory,
      };

    case ADD_EX_FACTORIES_OK:
    case EX_FACTORIES_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_EX_FACTORIES_ERROR:
    case EX_FACTORIES_DOWNLOAD_ERROR:
    case EX_FACTORIES_BULK_DELETED_ERROR:
    case EX_FACTORIES_DELETED_ERROR:
    case EX_FACTORIES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EX_FACTORIES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        exFactory: action.payload,
      };

    case RETRIEVE_EX_FACTORIES_DELETE:
      return {
        ...state,
        deleteExFactory: action.payload,
      };

    case EX_FACTORIES_DELETED_OK:
      return {
        ...state,
        exFactories: state.exFactories.data.filter(
          (exFactory) => exFactory.id !== state.deleteExFactory
        ),
        deleteExFactory: null,
      };

    case RETRIEVE_EX_FACTORIES_EDIT:
      return {
        ...state,
        editExFactory: action.payload,
      };

    case EX_FACTORIES_EDITED_OK:
      return {
        ...state,
        editExFactory: null,
        exFactories: state.exFactories.data.map((exFactory) =>
          exFactory.id === action.payload.id
            ? (exFactory = action.payload)
            : exFactory
        ),
      };

    default:
      return state;
  }
}
