import {
  ADD_ORDER_DETAILS,
  ADD_ORDER_DETAILS_OK,
  ADD_ORDER_DETAILS_ERROR,
  BEGIN_ORDER_DETAILS_DOWNLOAD,
  ORDER_DETAILS_DOWNLOAD_OK,
  ORDER_DETAILS_DOWNLOAD_ERROR,
  RETRIEVE_ORDER_DETAILS_DELETE,
  ORDER_DETAILS_DELETED_OK,
  ORDER_DETAILS_DELETED_ERROR,
  RETRIEVE_ORDER_DETAILS_EDIT,
  BEGIN_EDIT_ORDER_DETAILS,
  ORDER_DETAILS_EDITED_OK,
  ORDER_DETAILS_EDITED_ERROR,
  RETRIEVE_ORDER_DETAILS_BULK_DELETE,
  ORDER_DETAILS_BULK_DELETED_OK,
  ORDER_DETAILS_BULK_DELETED_ERROR,
  RESET,
} from "../../constants/modules/orderDetailsConstants";

export const downloadOrderDetailsAction = (item) => ({
  type: BEGIN_ORDER_DETAILS_DOWNLOAD,
  payload: true,
  orderDetail: item,
});

export const downloadOrderDetailsOkAction = (items) => ({
  type: ORDER_DETAILS_DOWNLOAD_OK,
  payload: items,
});

export const downloadOrderDetailsErrorAction = () => ({
  type: ORDER_DETAILS_DOWNLOAD_ERROR,
  payload: true,
});

export const addOrderDetailAction = (item) => ({
  type: ADD_ORDER_DETAILS,
  payload: true,
  orderDetail: item,
});

export const addOrderDetailOkAction = () => ({
  type: ADD_ORDER_DETAILS_OK,
  payload: true,
});

export const addOrderDetailErrorAction = (state) => ({
  type: ADD_ORDER_DETAILS_ERROR,
  payload: state,
});

export const deleteOrderDetailAction = (id) => ({
  type: RETRIEVE_ORDER_DETAILS_DELETE,
  payload: id,
});

export const deleteOrderDetailOkAction = () => ({
  type: ORDER_DETAILS_DELETED_OK,
});

export const deleteOrderDetailErrorAction = () => ({
  type: ORDER_DETAILS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteOrderDetailAction = (item) => ({
  type: RETRIEVE_ORDER_DETAILS_BULK_DELETE,
  payload: true,
  orderDetail: item,
});

export const bulkDeleteOrderDetailOkAction = () => ({
  type: ORDER_DETAILS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteOrderDetailErrorAction = (state) => ({
  type: ORDER_DETAILS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveOrderDetailAction = (item) => ({
  type: RETRIEVE_ORDER_DETAILS_EDIT,
  payload: item,
});

export const editOrderDetailAction = (item) => ({
  type: BEGIN_EDIT_ORDER_DETAILS,
  orderDetail: item,
});

export const editOrderDetailOkAction = (item) => ({
  type: ORDER_DETAILS_EDITED_OK,
  payload: item,
});

export const editOrderDetailErrorAction = () => ({
  type: ORDER_DETAILS_EDITED_ERROR,
  payload: true,
});

export const orderDetailReset = () => ({
  type: RESET,
  payload: true,
});
