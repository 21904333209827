import {
  ADD_FABRICS,
  ADD_FABRICS_ERROR,
  ADD_FABRICS_OK,
  BEGIN_EDIT_FABRICS,
  BEGIN_FABRICS_DOWNLOAD,
  FABRICS_BULK_DELETED_ERROR,
  FABRICS_BULK_DELETED_OK,
  FABRICS_DELETED_ERROR,
  FABRICS_DELETED_OK,
  FABRICS_DOWNLOAD_ERROR,
  FABRICS_DOWNLOAD_OK,
  FABRICS_EDITED_ERROR,
  FABRICS_EDITED_OK,
  RETRIEVE_FABRICS_BULK_DELETE,
  RETRIEVE_FABRICS_DELETE,
  RETRIEVE_FABRICS_EDIT,
  BEGIN_FABRICS_BY_FK_DOWNLOAD,
  FABRICS_BY_FK_DOWNLOAD_OK,
  FABRICS_BY_FK_DOWNLOAD_ERROR,
} from "../../constants/modules/fabricsConstants.js";

const initialState = {
  fabrics: [],
  fabricsByFk: [],
  error: false,
  loading: false,
  deleteFabric: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_FABRICS_DOWNLOAD:
    case BEGIN_FABRICS_BY_FK_DOWNLOAD:
    case ADD_FABRICS:
    case RETRIEVE_FABRICS_BULK_DELETE:
    case BEGIN_EDIT_FABRICS:
      return {
        ...state,
        loading: action.payload,
        fabric: action.fabric,
      };

    case ADD_FABRICS_OK:
    case FABRICS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_FABRICS_ERROR:
    case FABRICS_DOWNLOAD_ERROR:
    case FABRICS_BY_FK_DOWNLOAD_ERROR:
    case FABRICS_DELETED_ERROR:
    case FABRICS_BULK_DELETED_ERROR:
    case FABRICS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FABRICS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        fabric: action.payload,
      };

    case FABRICS_BY_FK_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        fabricsByFk: action.payload,
      };

    case RETRIEVE_FABRICS_DELETE:
      return {
        ...state,
        deleteFabric: action.payload,
      };

    case FABRICS_DELETED_OK:
      return {
        ...state,
        fabrics: state.fabrics.data.filter(
          (fabric) => fabric.id !== state.deleteFabric
        ),
        deleteFabric: null,
      };

    case RETRIEVE_FABRICS_EDIT:
      return {
        ...state,
        editFabric: action.payload,
      };

    case FABRICS_EDITED_OK:
      return {
        ...state,
        editFabric: null,
        fabrics: state.fabrics.data.map((fabric) =>
          fabric.id === action.payload.id ? (fabric = action.payload) : fabric
        ),
      };

    default:
      return state;
  }
}
