import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*             LOADING POINTS API CALL             */
/*-------------------------------------------------*/

export async function retrieveLoadingPointsData() {
    return await axiosClient.get("/loading_points");
  }
  
  export async function addLoadingPointDB(value) {
  
    return await axiosClient.post("/loading_points", value);
  }
  
  export async function deleteLoadingPointDB(id) {
    return await axiosClient.delete(`/loading_points/${id}`);
  }
  
  export async function bulkDeleteLoadingPointDB(ids) {
    
    return await axiosClient.post(`/loading_points/bulk_delete`, ids);
  }
  
  export async function editLoadingPointDB(value) {
   
    return await axiosClient.put(`/loading_points/${value.id}`, value);
  }
  