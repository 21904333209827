import {
  ACCOUNT_TYPES_BULK_DELETED_ERROR,
  ACCOUNT_TYPES_BULK_DELETED_OK,
  ACCOUNT_TYPES_DELETED_ERROR,
  ACCOUNT_TYPES_DELETED_OK,
  ACCOUNT_TYPES_DOWNLOAD_ERROR,
  ACCOUNT_TYPES_DOWNLOAD_OK,
  ACCOUNT_TYPES_EDITED_ERROR,
  ACCOUNT_TYPES_EDITED_OK,
  ADD_ACCOUNT_TYPES,
  ADD_ACCOUNT_TYPES_ERROR,
  ADD_ACCOUNT_TYPES_OK,
  BEGIN_ACCOUNT_TYPES_DOWNLOAD,
  BEGIN_EDIT_ACCOUNT_TYPES,
  RETRIEVE_ACCOUNT_TYPES_BULK_DELETE,
  RETRIEVE_ACCOUNT_TYPES_DELETE,
  RETRIEVE_ACCOUNT_TYPES_EDIT,
} from "../../constants/modules/accountTypesConstants";

const initialState = {
  accountTypes: [],
  error: false,
  loading: false,
  deleteAccountType: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_ACCOUNT_TYPES_DOWNLOAD:
    case ADD_ACCOUNT_TYPES:
    case RETRIEVE_ACCOUNT_TYPES_BULK_DELETE:
    case BEGIN_EDIT_ACCOUNT_TYPES:
      return {
        ...state,
        loading: action.payload,
        accountType: action.accountType,
      };

    case ADD_ACCOUNT_TYPES_OK:
    case ACCOUNT_TYPES_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_ACCOUNT_TYPES_ERROR:
    case ACCOUNT_TYPES_DOWNLOAD_ERROR:
    case ACCOUNT_TYPES_DELETED_ERROR:
    case ACCOUNT_TYPES_BULK_DELETED_ERROR:
    case ACCOUNT_TYPES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ACCOUNT_TYPES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        accountTypes: action.payload,
      };

    case RETRIEVE_ACCOUNT_TYPES_DELETE:
      return {
        ...state,
        deleteAccountType: action.payload,
      };

    case ACCOUNT_TYPES_DELETED_OK:
      return {
        ...state,
        accountTypes: state.accountTypes.data.filter(
          (accountType) => accountType.id !== state.deleteAccountType
        ),
        deleteAccountType: null,
      };

    case RETRIEVE_ACCOUNT_TYPES_EDIT:
      return {
        ...state,
        editAccountType: action.payload,
      };

    case ACCOUNT_TYPES_EDITED_OK:
      return {
        ...state,
        editAccountType: null,
        accountTypes: state.accountTypes.data.map((accountType) =>
          accountType.id === action.payload.id
            ? (accountType = action.payload)
            : accountType
        ),
      };

    default:
      return state;
  }
}
