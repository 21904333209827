import {
    ADD_CURRENCIES, ADD_CURRENCIES_ERROR, ADD_CURRENCIES_OK, BEGIN_CURRENCIES_DOWNLOAD, BEGIN_EDIT_CURRENCIES, CURRENCIES_BULK_DELETED_ERROR, CURRENCIES_BULK_DELETED_OK, CURRENCIES_DELETED_ERROR, CURRENCIES_DELETED_OK, CURRENCIES_DOWNLOAD_ERROR, CURRENCIES_DOWNLOAD_OK, CURRENCIES_EDITED_ERROR, CURRENCIES_EDITED_OK, RETRIEVE_CURRENCIES_BULK_DELETE, RETRIEVE_CURRENCIES_DELETE, RETRIEVE_CURRENCIES_EDIT
} from "../../constants/modules/currenciesConstants.js";
    
    const initialState = {
        currencies: [],
      error: false,
      loading: false,
      deleteCurrency: null,
    };
    
    export default function (state = initialState, action) {
      switch (action.type) {
        case BEGIN_CURRENCIES_DOWNLOAD:
        case RETRIEVE_CURRENCIES_BULK_DELETE:
        case ADD_CURRENCIES:
        case BEGIN_EDIT_CURRENCIES:
          return {
            ...state,
            loading: action.payload,
            currency: action.currency,
          };
    
        case ADD_CURRENCIES_OK:
        case CURRENCIES_BULK_DELETED_OK:
          return {
            ...state,
            loading: false,
          };
    
        case ADD_CURRENCIES_ERROR:
        case CURRENCIES_DOWNLOAD_ERROR:
        case CURRENCIES_BULK_DELETED_ERROR:
        case CURRENCIES_DELETED_ERROR:
        case CURRENCIES_EDITED_ERROR:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case CURRENCIES_DOWNLOAD_OK:
          return {
            ...state,
            loading: false,
            error: null,
            currencies: action.payload,
          };
    
        case RETRIEVE_CURRENCIES_DELETE:
          return {
            ...state,
            deleteCurrency: action.payload,
          };
    
        case CURRENCIES_DELETED_OK:
          return {
            ...state,
            currencies: state.currencies.data.filter((currency) => currency.id !== state.deleteCurrency),
            deleteCurrency: null,
          };
    
        case RETRIEVE_CURRENCIES_EDIT:
          return {
            ...state,
            editCurrency: action.payload,
          };
    
        case CURRENCIES_EDITED_OK:
          return {
            ...state,
            editCurrency: null,
            currencies: state.currencies.data.map((currency) =>
              currency.id === action.payload.id ? (currency = action.payload) : currency
            ),
          };
    
        default:
          return state;
      }
    }
    