import {
    ADD_PROCEED_REALIZATIONS,
    ADD_PROCEED_REALIZATIONS_ERROR,
    ADD_PROCEED_REALIZATIONS_OK,
    BEGIN_EDIT_PROCEED_REALIZATIONS, BEGIN_PROCEED_REALIZATIONS_DOWNLOAD, BEGIN_PROCEED_REALIZATION_DOWNLOAD, PROCEED_REALIZATIONS_BULK_DELETED_ERROR,
    PROCEED_REALIZATIONS_BULK_DELETED_OK,
    PROCEED_REALIZATIONS_DELETED_ERROR,
    PROCEED_REALIZATIONS_DELETED_OK,
    PROCEED_REALIZATIONS_DOWNLOAD_ERROR,
    PROCEED_REALIZATIONS_DOWNLOAD_OK,
    PROCEED_REALIZATIONS_EDITED_ERROR,
    PROCEED_REALIZATIONS_EDITED_OK,
    PROCEED_REALIZATION_DOWNLOAD_ERROR,
    PROCEED_REALIZATION_DOWNLOAD_OK, RESET,
    RETRIEVE_PROCEED_REALIZATIONS_BULK_DELETE,
    RETRIEVE_PROCEED_REALIZATIONS_DELETE,
    RETRIEVE_PROCEED_REALIZATIONS_EDIT
} from "../../constants/modules/proceedRealizationConstant";
  
  const initialState = {
    proceedRealizations: [],
    getProceedRealization: null,
    proceedRealizationAddInfo: null,
    error: false,
    loading: false,
    deleteProceedRealization: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_PROCEED_REALIZATIONS_DOWNLOAD:
      case BEGIN_PROCEED_REALIZATION_DOWNLOAD:
      case RETRIEVE_PROCEED_REALIZATIONS_BULK_DELETE:
      case ADD_PROCEED_REALIZATIONS:
      case BEGIN_EDIT_PROCEED_REALIZATIONS:
        return {
          ...state,
          loading: action.payload,
          proceedRealization: action.proceedRealization,
        };
  
      case PROCEED_REALIZATIONS_BULK_DELETED_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_PROCEED_REALIZATIONS_ERROR:
      case PROCEED_REALIZATIONS_DOWNLOAD_ERROR:
      case PROCEED_REALIZATIONS_BULK_DELETED_ERROR:
      case PROCEED_REALIZATION_DOWNLOAD_ERROR:
      case PROCEED_REALIZATIONS_DELETED_ERROR:
      case PROCEED_REALIZATIONS_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case PROCEED_REALIZATIONS_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          proceedRealizations: action.payload,
        };
  
      case PROCEED_REALIZATION_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          getProceedRealization: action.payload,
        };
  
      case ADD_PROCEED_REALIZATIONS_OK:
        return {
          ...state,
          loading: false,
          error: null,
          proceedRealizationAddInfo: action.payload,
        };
  
      case RETRIEVE_PROCEED_REALIZATIONS_DELETE:
        return {
          ...state,
          deleteProceedRealization: action.payload,
        };
  
      case PROCEED_REALIZATIONS_DELETED_OK:
        return {
          ...state,
          proceedRealizations: state.proceedRealizations.data.filter(
            (proceedRealization) => proceedRealization.id !== state.deleteProceedRealization
          ),
          deleteProceedRealization: null,
        };
  
      case RETRIEVE_PROCEED_REALIZATIONS_EDIT:
        return {
          ...state,
          editProceedRealization: action.payload,
        };
  
      case PROCEED_REALIZATIONS_EDITED_OK:
        return {
          ...state,
          editProceedRealizations: null,
          proceedRealizations: state.proceedRealizations.data.map((proceedRealization) =>
            proceedRealization.id === action.payload.id
              ? (proceedRealization = action.payload)
              : proceedRealization
          ),
        };
  
      case RESET:
        return {
          ...state,
          getProceedRealization: null,
          proceedRealizationAddInfo: null,
        };
  
      default:
        return state;
    }
  }
  