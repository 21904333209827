import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_SAMPLE_ORDERS,
  BEGIN_EDIT_SAMPLE_ORDERS,
  BEGIN_SAMPLE_ORDERS_DOWNLOAD,
  RETRIEVE_SAMPLE_ORDERS_BULK_DELETE,
  RETRIEVE_SAMPLE_ORDERS_DELETE
} from "../../constants/modules/sampleOrdersConstants";

import {
  addSampleOrderErrorAction,
  addSampleOrderOkAction,
  bulkDeleteSampleOrderErrorAction,
  bulkDeleteSampleOrderOkAction,
  deleteSampleOrderErrorAction,
  deleteSampleOrderOkAction,
  downloadSampleOrdersErrorAction,
  downloadSampleOrdersOkAction,
  editSampleOrderErrorAction,
  editSampleOrderOkAction
} from "../../actions/modules/sampleOrdersAction";

import {
  addSampleOrderDB,
  bulkDeleteSampleOrderDB,
  deleteSampleOrderDB,
  editSampleOrderDB,
  retrieveSampleOrdersData
} from "../../../config/apiCalls/sampleOrdersAPICall";


// Retrieve products
// worker saga
export function* retrieveSampleOrder() {
  try {
    const response = yield call(retrieveSampleOrdersData);

    if (response.status === 200) {
      yield put(downloadSampleOrdersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSampleOrdersErrorAction());
  }
}

// Create new product
// worker saga
export function* addSampleOrder(action) {
  const sampleOrder = action.sampleOrder;

  try {
    const response = yield call(addSampleOrderDB, sampleOrder);

    if (response.status === 201) {
      yield delay(2000);

      yield put(addSampleOrderOkAction(sampleOrder));
      toast.success("SampleOrder created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addSampleOrderErrorAction(true));
    toast.error("SampleOrder created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkSampleOrderDelete(action) {
  const sampleOrder = action.sampleOrder;

  try {
    const response = yield call(bulkDeleteSampleOrderDB, sampleOrder);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteSampleOrderOkAction(sampleOrder));
      toast.success("Bulk SampleOrder deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteSampleOrderErrorAction(true));
    toast.error("Bulk SampleOrder deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteSampleOrder(action) {
  const id = action.payload;
  try {
    yield call(deleteSampleOrderDB, id);
    yield deleteSampleOrderOkAction();
    toast.success("SampleOrder deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteSampleOrderErrorAction();
  }
}

// Edit product
// worker saga
export function* editSampleOrder(action) {
  const sampleOrder = action.sampleOrder;

  try {
    yield call(editSampleOrderDB, sampleOrder);
    yield editSampleOrderOkAction(sampleOrder);
    // Alert
    // Alert
    toast.success("SampleOrder updated successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editSampleOrderErrorAction();
  }
}

export function* watchsSampleOrdersAsync() {
  yield takeLatest(BEGIN_SAMPLE_ORDERS_DOWNLOAD, retrieveSampleOrder);
  yield takeLatest(ADD_SAMPLE_ORDERS, addSampleOrder);
  yield takeLatest(RETRIEVE_SAMPLE_ORDERS_DELETE, deleteSampleOrder);
  yield takeLatest(BEGIN_EDIT_SAMPLE_ORDERS, editSampleOrder);
  yield takeLatest(RETRIEVE_SAMPLE_ORDERS_BULK_DELETE, bulkSampleOrderDelete);
}
