export const ADD_TEAMS = "ADD_TEAMS";
export const ADD_TEAMS_OK = "ADD_TEAMS_OK";
export const ADD_TEAMS_ERROR = "ADD_TEAMS_ERROR";

export const BEGIN_TEAMS_DOWNLOAD = "BEGIN_TEAMS_DOWNLOAD";
export const TEAMS_DOWNLOAD_OK = "TEAMS_DOWNLOAD_OK";
export const TEAMS_DOWNLOAD_ERROR = "TEAMS_DOWNLOAD_ERROR";

export const BEGIN_TEAMS_BY_BUYER_DOWNLOAD = "BEGIN_TEAMS_BY_BUYER_DOWNLOAD";
export const TEAMS_BY_BUYER_DOWNLOAD_OK = "TEAMS_BY_BUYER_DOWNLOAD_OK";
export const TEAMS_BY_BUYER_DOWNLOAD_ERROR = "TEAMS_BY_BUYER_DOWNLOAD_ERROR";

export const RETRIEVE_TEAMS_DELETE = "RETRIEVE_TEAMS_DELETE";
export const TEAMS_DELETED_OK = "TEAMS_DELETED_OK";
export const TEAMS_DELETED_ERROR = "TEAMS_DELETED_ERROR";

export const RETRIEVE_TEAMS_EDIT = "RETRIEVE_TEAMS_EDITED";
export const BEGIN_EDIT_TEAMS = "BEGIN_EDIT_TEAMS";
export const TEAMS_EDITED_OK = "TEAMS_EDITED_OK";
export const TEAMS_EDITED_ERROR = "TEAMS_EDITED_ERROR";

export const RETRIEVE_TEAMS_BULK_DELETE = "RETRIEVE_TEAMS_BULK_DELETE";
export const TEAMS_BULK_DELETED_OK = "TEAMS_BULK_DELETED_OK";
export const TEAMS_BULK_DELETED_ERROR = "TEAMS_BULK_DELETED_ERROR";
