import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_COMPANIES,
  BEGIN_COMPANIES_DOWNLOAD,
  BEGIN_EDIT_COMPANIES,
  RETRIEVE_COMPANIES_BULK_DELETE,
  RETRIEVE_COMPANIES_DELETE
} from "../../constants/modules/companiesConstants";

import {
  addCompanyErrorAction,
  addCompanyOkAction,
  bulkDeleteCompanyErrorAction,
  bulkDeleteCompanyOkAction,
  deleteCompanyErrorAction,
  deleteCompanyOkAction,
  downloadCompaniesErrorAction,
  downloadCompaniesOkAction,
  editCompanyErrorAction,
  editCompanyOkAction
} from "../../actions/modules/companiesActions";

import {
  addCompanyDB,
  bulkDeleteCompanyDB,
  deleteCompanyDB,
  editCompanyDB,
  retrieveCompaniesData
} from "../../../config/apiCalls/companiesAPICall";

// Retrieve products
// worker saga
export function* retrieveCompanies() {
  try {
    const response = yield call(retrieveCompaniesData);

    if (response.status === 200) {
      yield put(downloadCompaniesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadCompaniesErrorAction());
  }
}

// Create new product
// worker saga
export function* addCompany(action) {
  const company = action.company;

  try {
    const response = yield call(addCompanyDB, company);

    if (response.status === 201) {
      yield delay(2000);

      yield put(addCompanyOkAction(company));
      toast.success("Company created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addCompanyErrorAction(true));
    toast.error("Company created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkCompanyDelete(action) {
  const company = action.company;

  try {
    const response = yield call(bulkDeleteCompanyDB, company);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteCompanyOkAction(company));
      toast.success("Bulk Company deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteCompanyErrorAction(true));
    toast.error("Bulk Company deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteCompany(action) {
  const id = action.payload;
  try {
    yield call(deleteCompanyDB, id);
    yield deleteCompanyOkAction();
    toast.success("Company deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteCompanyErrorAction();
  }
}

// Edit product
// worker saga
export function* editCompany(action) {
  const company = action.company;

  try {
    yield call(editCompanyDB, company);
    yield editCompanyOkAction(company);
    // Alert
    // Alert
    toast.success("Company has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editCompanyErrorAction();
  }
}

export function* watchCompaniesAsync() {
  yield takeLatest(BEGIN_COMPANIES_DOWNLOAD, retrieveCompanies);
  yield takeLatest(ADD_COMPANIES, addCompany);
  yield takeLatest(RETRIEVE_COMPANIES_DELETE, deleteCompany);
  yield takeLatest(BEGIN_EDIT_COMPANIES, editCompany);
  yield takeLatest(RETRIEVE_COMPANIES_BULK_DELETE, bulkCompanyDelete);
}
