import {
  ADD_SIZE_SETS,
  ADD_SIZE_SETS_ERROR,
  ADD_SIZE_SETS_OK,
  BEGIN_SIZE_SETS_DOWNLOAD,
  BEGIN_EDIT_SIZE_SETS,
  SIZE_SETS_DELETED_ERROR,
  SIZE_SETS_DELETED_OK,
  SIZE_SETS_DOWNLOAD_ERROR,
  SIZE_SETS_DOWNLOAD_OK,
  SIZE_SETS_EDITED_ERROR,
  SIZE_SETS_EDITED_OK,
  RETRIEVE_SIZE_SETS_DELETE,
  RETRIEVE_SIZE_SETS_EDIT,
  GET_SIZE_SET_DETAIL,
  GET_SIZE_SET_DETAIL_OK,
  GET_SIZE_SET_DETAIL_ERROR,
  BEGIN_SIZE_SETS_BY_FK_DOWNLOAD,
  SIZE_SETS_BY_FK_DOWNLOAD_OK,
  SIZE_SETS_BY_FK_DOWNLOAD_ERROR,
  RESET,
} from "../../constants/modules/sizeSetsConstants";

const initialState = {
  sizeSets: [],
  getSizeSet: [],
  getByFk: [],
  error: false,
  loading: false,
  deleteSizeSet: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_SIZE_SETS_DOWNLOAD:
    case BEGIN_SIZE_SETS_BY_FK_DOWNLOAD:
    case ADD_SIZE_SETS:
    case GET_SIZE_SET_DETAIL:
    case BEGIN_EDIT_SIZE_SETS:
      return {
        ...state,
        loading: action.payload,
        sizeSet: action.sizeSet,
      };

    case ADD_SIZE_SETS_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_SIZE_SETS_ERROR:
    case SIZE_SETS_DOWNLOAD_ERROR:
    case SIZE_SETS_BY_FK_DOWNLOAD_ERROR:
    case GET_SIZE_SET_DETAIL_ERROR:
    case SIZE_SETS_DELETED_ERROR:
    case SIZE_SETS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SIZE_SETS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        sizeSets: action.payload,
      };

    case GET_SIZE_SET_DETAIL_OK:
      return {
        ...state,
        loading: false,
        error: null,
        getSizeSet: action.payload,
      };

    case SIZE_SETS_BY_FK_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        getByFk: action.payload,
      };

    case RETRIEVE_SIZE_SETS_DELETE:
      return {
        ...state,
        deleteSizeSet: action.payload,
      };

    case SIZE_SETS_DELETED_OK:
      return {
        ...state,
        sizeSets: state.sizeSets.data.filter(
          (sizeSet) => sizeSet.id !== state.deleteSizeSet
        ),
        deleteSizeSet: null,
      };

    case RETRIEVE_SIZE_SETS_EDIT:
      return {
        ...state,
        editSizeSet: action.payload,
      };

    case SIZE_SETS_EDITED_OK:
      return {
        ...state,
        editSizeSet: null,
        sizeSets: state.sizeSets.data.map((sizeSet) =>
          sizeSet.id === action.payload.id
            ? (sizeSet = action.payload)
            : sizeSet
        ),
      };

    case RESET:
      return {
        ...state,
        getByFk: [],
      };

    default:
      return state;
  }
}
