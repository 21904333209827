import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AppProvider from "./components/AppProvider/AppProvider";
import Dashboard from "./containers/Dashboard";
import "./index.css";
import { Signin } from "./pages";
/////stepUp Reoports
import CashBankBook from "./pages/Reports/CashBankBook/Reports";
import CustomerLedger from "./pages/Reports/CustomerLedger/Reports";
import SupplierLedger from "./pages/Reports/SupplierLedger/Reports";


import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import store from "./redux/store";
import registerServiceWorker from "./registerServiceWorker";
import PrivateRoute from "./utils/router/PrivateRoute";

render(
  <Provider store={store}>
    <AppProvider>
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/404" component={NotFound} />
          <Route exact path="/500" component={BackendError} /> */}
          {/* <Route exact path="/Lockscreen" component={Lockscreen} /> */}
          <Route exact path="/signin" component={Signin} />
          <PrivateRoute path="/app" component={Dashboard} />
          <Redirect exact from="/" to="/signin" />

          <Route exact path="/doc/supplier_ledger" component={SupplierLedger} />
          <Route exact path="/doc/customer_ledger" component={CustomerLedger} />
          <Route exact path="/doc/cashBank_book" component={CashBankBook} />
          
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </AppProvider>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
