import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_TNA_PLAN_DETAIL,
  RETRIEVE_TNA_PLAN_DETAIL_DELETE,
} from "../../constants/modules/tnaPlanDetailsConstants";

import {
  addTnaPlanDetailErrorAction,
  addTnaPlanDetailOkAction,
  deleteTnaPlanDetailErrorAction,
  deleteTnaPlanDetailOkAction,
} from "../../actions/modules/tnaPlanDetailsActions";

import {
  addTnaPlanDetailDB,
  deleteTnaPlanDetailDB,
} from "../../../config/apiCalls/tnaPlanDetails";

// Create new product
// worker saga
export function* addTnaPlanDetail(action) {
  const tnaPlanDetail = action.tnaPlanDetail;

  try {
    const response = yield call(addTnaPlanDetailDB, tnaPlanDetail);
    if (response.status === 200) {
      yield delay(500);
      yield put(addTnaPlanDetailOkAction(response.data));
      toast.success("Tna Plan Updated!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addTnaPlanDetailErrorAction(true));
    toast.error("Tna Plan update failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// BULK SAGAS
export function* tnaPlanDetailDelete(action) {
  const tnaPlanDetail = action.tnaPlanDetail;

  try {
    const response = yield call(deleteTnaPlanDetailDB, tnaPlanDetail);
    if (response.status === 200) {
      yield delay(2000);
      yield put(deleteTnaPlanDetailOkAction(tnaPlanDetail));
      toast.success("Tna Actions deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(deleteTnaPlanDetailErrorAction(true));
    toast.error("Tna Actions deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchTnaPlanDetailAsync() {
  yield takeLatest(ADD_TNA_PLAN_DETAIL, addTnaPlanDetail);
  yield takeLatest(RETRIEVE_TNA_PLAN_DETAIL_DELETE, tnaPlanDetailDelete);
}
