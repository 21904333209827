import {
  ADD_EX_FACTORY_HEADER,
  ADD_EX_FACTORY_HEADER_OK,
  ADD_EX_FACTORY_HEADER_ERROR,
  BEGIN_EX_FACTORY_HEADER_DOWNLOAD,
  EX_FACTORY_HEADER_DOWNLOAD_OK,
  EX_FACTORY_HEADER_DOWNLOAD_ERROR,
  RETRIEVE_EX_FACTORY_HEADER_DELETE,
  EX_FACTORY_HEADER_DELETED_OK,
  EX_FACTORY_HEADER_DELETED_ERROR,
  ////////
  ADD_EX_FACTORY_DETAIL,
  ADD_EX_FACTORY_DETAIL_OK,
  ADD_EX_FACTORY_DETAIL_ERROR,
  BEGIN_EX_FACTORY_DETAIL_DOWNLOAD,
  EX_FACTORY_DETAIL_DOWNLOAD_OK,
  EX_FACTORY_DETAIL_DOWNLOAD_ERROR,
  RETRIEVE_EX_FACTORY_DETAIL_DELETE,
  EX_FACTORY_DETAIL_DELETED_OK,
  EX_FACTORY_DETAIL_DELETED_ERROR,
  ////////
  ADD_EX_FACTORY_SHIPMENT_DETAIL,
  ADD_EX_FACTORY_SHIPMENT_DETAIL_OK,
  ADD_EX_FACTORY_SHIPMENT_DETAIL_ERROR,
  BEGIN_EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD,
  EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD_OK,
  EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD_ERROR,
} from "../../constants/modules/exFactoryInfoConstants";

const initialState = {
  exFactoryHeaders: [],
  exFactoryDetails: [],
  exFactoryShipmentDetails: [],
  error: false,
  loading: false,
  deleteHeader: null,
  deleteDetail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_EX_FACTORY_HEADER_DOWNLOAD:
    case RETRIEVE_EX_FACTORY_HEADER_DELETE:
    case ADD_EX_FACTORY_HEADER:
    ////
    case BEGIN_EX_FACTORY_DETAIL_DOWNLOAD:
    case RETRIEVE_EX_FACTORY_DETAIL_DELETE:
    case ADD_EX_FACTORY_DETAIL:
    ////
    case ADD_EX_FACTORY_SHIPMENT_DETAIL:
    case BEGIN_EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD:
      return {
        ...state,
        loading: action.payload,
        exFactoryInfo: action.exFactoryInfo,
      };

    case ADD_EX_FACTORY_HEADER_OK:
    case EX_FACTORY_HEADER_DELETED_OK:
    case ADD_EX_FACTORY_DETAIL_OK:
    case EX_FACTORY_DETAIL_DOWNLOAD_OK:
    case ADD_EX_FACTORY_SHIPMENT_DETAIL_OK:
    case EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_EX_FACTORY_HEADER_ERROR:
    case EX_FACTORY_HEADER_DOWNLOAD_ERROR:
    case EX_FACTORY_HEADER_DELETED_ERROR:
    case ADD_EX_FACTORY_DETAIL_ERROR:
    case EX_FACTORY_DETAIL_DOWNLOAD_ERROR:
    case EX_FACTORY_DETAIL_DELETED_ERROR:
    case ADD_EX_FACTORY_SHIPMENT_DETAIL_ERROR:
    case EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EX_FACTORY_HEADER_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        exFactoryHeaders: action.payload,
      };

    case EX_FACTORY_DETAIL_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        exFactoryDetails: action.payload,
      };

    case EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        exFactoryShipmentDetails: action.payload,
      };

    default:
      return state;
  }
}
