export const ADD_TNA_TEMPLATES = "ADD_TNA_TEMPLATES";
export const ADD_TNA_TEMPLATES_OK = "ADD_TNA_TEMPLATES_OK";
export const ADD_TNA_TEMPLATES_ERROR = "ADD_TNA_TEMPLATES_ERROR";

export const BEGIN_TNA_TEMPLATES_DOWNLOAD = "BEGIN_TNA_TEMPLATES_DOWNLOAD";
export const TNA_TEMPLATES_DOWNLOAD_OK = "TNA_TEMPLATES_DOWNLOAD_OK";
export const TNA_TEMPLATES_DOWNLOAD_ERROR = "TNA_TEMPLATES_DOWNLOAD_ERROR";

export const BEGIN_TNA_TEMPLATE_DOWNLOAD = "BEGIN_TNA_TEMPLATE_DOWNLOAD";
export const TNA_TEMPLATE_DOWNLOAD_OK = "TNA_TEMPLATE_DOWNLOAD_OK";
export const TNA_TEMPLATE_DOWNLOAD_ERROR = "TNA_TEMPLATE_DOWNLOAD_ERROR";

export const RETRIEVE_TNA_TEMPLATES_DELETE = "RETRIEVE_TNA_TEMPLATES_DELETE";
export const TNA_TEMPLATES_DELETED_OK = "TNA_TEMPLATES_DELETED_OK";
export const TNA_TEMPLATES_DELETED_ERROR = "TNA_TEMPLATES_DELETED_ERROR";

export const RETRIEVE_TNA_TEMPLATES_EDIT = "RETRIEVE_TNA_TEMPLATES_EDITED";
export const BEGIN_EDIT_TNA_TEMPLATES = "BEGIN_EDIT_TNA_TEMPLATES";
export const TNA_TEMPLATES_EDITED_OK = "TNA_TEMPLATES_EDITED_OK";
export const TNA_TEMPLATES_EDITED_ERROR = "TNA_TEMPLATES_EDITED_ERROR";

export const RETRIEVE_TNA_TEMPLATES_BULK_DELETE =
  "RETRIEVE_TNA_TEMPLATES_BULK_DELETE";
export const TNA_TEMPLATES_BULK_DELETED_OK = "TNA_TEMPLATES_BULK_DELETED_OK";
export const TNA_TEMPLATES_BULK_DELETED_ERROR =
  "TNA_TEMPLATES_BULK_DELETED_ERROR";

export const RESET = "RESET";
