import {
  ADD_FABRICS,
  ADD_FABRICS_ERROR,
  ADD_FABRICS_OK,
  BEGIN_EDIT_FABRICS,
  BEGIN_FABRICS_DOWNLOAD,
  FABRICS_BULK_DELETED_ERROR,
  FABRICS_BULK_DELETED_OK,
  FABRICS_DELETED_ERROR,
  FABRICS_DELETED_OK,
  FABRICS_DOWNLOAD_ERROR,
  FABRICS_DOWNLOAD_OK,
  FABRICS_EDITED_ERROR,
  FABRICS_EDITED_OK,
  RETRIEVE_FABRICS_BULK_DELETE,
  RETRIEVE_FABRICS_DELETE,
  RETRIEVE_FABRICS_EDIT,
  BEGIN_FABRICS_BY_FK_DOWNLOAD,
  FABRICS_BY_FK_DOWNLOAD_OK,
  FABRICS_BY_FK_DOWNLOAD_ERROR,
} from "../../constants/modules/fabricsConstants";

export const bulkDeleteFabricAction = (item) => ({
  type: RETRIEVE_FABRICS_BULK_DELETE,
  payload: true,
  fabric: item,
});

export const bulkDeleteFabricOkAction = () => ({
  type: FABRICS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteFabricErrorAction = (state) => ({
  type: FABRICS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadFabricByFKAction = (item) => ({
  type: BEGIN_FABRICS_BY_FK_DOWNLOAD,
  payload: true,
  fabric: item,
});

export const downloadFabricByFKOkAction = (items) => ({
  type: FABRICS_BY_FK_DOWNLOAD_OK,
  payload: items,
});

export const downloadFabricByFKErrorAction = () => ({
  type: FABRICS_BY_FK_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadFabricsAction = () => ({
  type: BEGIN_FABRICS_DOWNLOAD,
  payload: true,
});

export const downloadFabricsOkAction = (items) => ({
  type: FABRICS_DOWNLOAD_OK,
  payload: items,
});

export const downloadFabricsErrorAction = () => ({
  type: FABRICS_DOWNLOAD_ERROR,
  payload: true,
});

export const addFabricAction = (item) => ({
  type: ADD_FABRICS,
  payload: true,
  fabric: item,
});

export const addFabricOkAction = () => ({
  type: ADD_FABRICS_OK,
  payload: true,
});

export const addFabricErrorAction = (state) => ({
  type: ADD_FABRICS_ERROR,
  payload: state,
});

export const deleteFabricAction = (id) => ({
  type: RETRIEVE_FABRICS_DELETE,
  payload: id,
});

export const deleteFabricOkAction = () => ({
  type: FABRICS_DELETED_OK,
});

export const deleteFabricErrorAction = () => ({
  type: FABRICS_DELETED_ERROR,
  payload: true,
});

export const retrieveFabricAction = (item) => ({
  type: RETRIEVE_FABRICS_EDIT,
  payload: item,
});

export const editFabricAction = (item) => ({
  type: BEGIN_EDIT_FABRICS,
  fabric: item,
});

export const editFabricOkAction = (item) => ({
  type: FABRICS_EDITED_OK,
  payload: item,
});

export const editFabricErrorAction = () => ({
  type: FABRICS_EDITED_ERROR,
  payload: true,
});
