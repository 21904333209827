import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_EX_FACTORIES,
  BEGIN_EDIT_EX_FACTORIES,
  BEGIN_EX_FACTORIES_DOWNLOAD,
  RETRIEVE_EX_FACTORIES_BULK_DELETE,
  RETRIEVE_EX_FACTORIES_DELETE
} from "../../constants/modules/exFactoriesConstants";

import {
  addExFactoryErrorAction,
  addExFactoryOkAction,
  bulkDeleteExFactoryErrorAction,
  bulkDeleteExFactoryOkAction,
  deleteExFactoryErrorAction,
  deleteExFactoryOkAction,
  downloadExFactoriesErrorAction,
  downloadExFactoriesOkAction,
  editExFactoryErrorAction,
  editExFactoryOkAction
} from "../../actions/modules/exFactoriesActions";

import {
  addExFactoryDB,
  bulkDeleteExFactoryDB,
  deleteExFactoryDB,
  editExFactoryDB,
  retrieveExFactoriesData
} from "../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveExFactories() {
  try {
    const response = yield call(retrieveExFactoriesData);

    if (response.status === 200) {
      yield put(downloadExFactoriesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadExFactoriesErrorAction());
  }
}

// Create new product
// worker saga
export function* addExFactory(action) {
  const exFactory = action.exFactory;

  try {
    const response = yield call(addExFactoryDB, exFactory);

    if (response.status === 201) {
      yield delay(2000);

      yield put(addExFactoryOkAction(exFactory));
      toast.success("Ex-Factory created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addExFactoryErrorAction(true));
    toast.error("Ex-Factory created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkExFactoryDelete(action) {
  const exFactory = action.exFactory;

  try {
    const response = yield call(bulkDeleteExFactoryDB, exFactory);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteExFactoryOkAction(exFactory));
      toast.success("Bulk Ex-Factory deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteExFactoryErrorAction(true));
    toast.error("Bulk Ex-Factory deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteExFactory(action) {
  const id = action.payload;
  try {
    yield call(deleteExFactoryDB, id);
    yield deleteExFactoryOkAction();
    toast.success("Ex-Factory deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteExFactoryErrorAction();
  }
}

// Edit product
// worker saga
export function* editExFactory(action) {
  const exFactory = action.exFactory;

  try {
    yield call(editExFactoryDB, exFactory);
    yield editExFactoryOkAction(exFactory);
    // Alert
    toast.success("Ex-Factory has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editExFactoryErrorAction();
  }
}

export function* watchExFactoriesAsync() {
  yield takeLatest(BEGIN_EX_FACTORIES_DOWNLOAD, retrieveExFactories);
  yield takeLatest(ADD_EX_FACTORIES, addExFactory);
  yield takeLatest(RETRIEVE_EX_FACTORIES_DELETE, deleteExFactory);
  yield takeLatest(BEGIN_EDIT_EX_FACTORIES, editExFactory);
  yield takeLatest(RETRIEVE_EX_FACTORIES_BULK_DELETE, bulkExFactoryDelete);
}
