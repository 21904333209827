export const ADD_DISCOUNTS = "ADD_DISCOUNTS";
export const ADD_DISCOUNTS_OK = "ADD_DISCOUNTS_OK";
export const ADD_DISCOUNTS_ERROR = "ADD_DISCOUNTS_ERROR";

export const BEGIN_DISCOUNTS_DOWNLOAD = "BEGIN_DISCOUNTS_DOWNLOAD";
export const DISCOUNTS_DOWNLOAD_OK = "DISCOUNTS_DOWNLOAD_OK";
export const DISCOUNTS_DOWNLOAD_ERROR = "DISCOUNTS_DOWNLOAD_ERROR";

export const BEGIN_DISCOUNT_DOWNLOAD = "BEGIN_DISCOUNT_DOWNLOAD";
export const DISCOUNT_DOWNLOAD_OK = "DISCOUNT_DOWNLOAD_OK";
export const DISCOUNT_DOWNLOAD_ERROR = "DISCOUNT_DOWNLOAD_ERROR";

export const RETRIEVE_DISCOUNTS_DELETE = "RETRIEVE_DISCOUNTS_DELETE";
export const DISCOUNTS_DELETED_OK = "DISCOUNTS_DELETED_OK";
export const DISCOUNTS_DELETED_ERROR = "DISCOUNTS_DELETED_ERROR";

export const RETRIEVE_DISCOUNTS_EDIT = "RETRIEVE_DISCOUNTS_EDITED";
export const BEGIN_EDIT_DISCOUNTS = "BEGIN_EDIT_DISCOUNTS";
export const DISCOUNTS_EDITED_OK = "DISCOUNTS_EDITED_OK";
export const DISCOUNTS_EDITED_ERROR = "DISCOUNTS_EDITED_ERROR";

export const RETRIEVE_DISCOUNTS_BULK_DELETE =
  "RETRIEVE_DISCOUNTS_BULK_DELETE";
export const DISCOUNTS_BULK_DELETED_OK = "DISCOUNTS_BULK_DELETED_OK";
export const DISCOUNTS_BULK_DELETED_ERROR =
  "DISCOUNTS_BULK_DELETED_ERROR";

export const RESET = "RESET";
