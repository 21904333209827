import {
  ACCOUNT_TYPES_BULK_DELETED_ERROR,
  ACCOUNT_TYPES_BULK_DELETED_OK,
  ACCOUNT_TYPES_DELETED_ERROR,
  ACCOUNT_TYPES_DELETED_OK,
  ACCOUNT_TYPES_DOWNLOAD_ERROR,
  ACCOUNT_TYPES_DOWNLOAD_OK,
  ACCOUNT_TYPES_EDITED_ERROR,
  ACCOUNT_TYPES_EDITED_OK,
  ADD_ACCOUNT_TYPES,
  ADD_ACCOUNT_TYPES_ERROR,
  ADD_ACCOUNT_TYPES_OK,
  BEGIN_ACCOUNT_TYPES_DOWNLOAD,
  BEGIN_EDIT_ACCOUNT_TYPES,
  RETRIEVE_ACCOUNT_TYPES_BULK_DELETE,
  RETRIEVE_ACCOUNT_TYPES_DELETE,
  RETRIEVE_ACCOUNT_TYPES_EDIT
} from "../../constants/modules/accountTypesConstants";

export const bulkDeleteAccountTypeAction = (item) => ({
  type: RETRIEVE_ACCOUNT_TYPES_BULK_DELETE,
  payload: true,
  accountType: item,
});

export const bulkDeleteAccountTypeOkAction = () => ({
  type: ACCOUNT_TYPES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteAccountTypeErrorAction = (state) => ({
  type: ACCOUNT_TYPES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadAccountTypesAction = () => ({
  type: BEGIN_ACCOUNT_TYPES_DOWNLOAD,
  payload: true,
});

export const downloadAccountTypesOkAction = (items) => ({
  type: ACCOUNT_TYPES_DOWNLOAD_OK,
  payload: items,
});

export const downloadAccountTypesErrorAction = () => ({
  type: ACCOUNT_TYPES_DOWNLOAD_ERROR,
  payload: true,
});

export const addAccountTypeAction = (item) => ({
  type: ADD_ACCOUNT_TYPES,
  payload: true,
  accountType: item,
});

export const addAccountTypeOkAction = () => ({
  type: ADD_ACCOUNT_TYPES_OK,
  payload: true,
});

export const addAccountTypeErrorAction = (state) => ({
  type: ADD_ACCOUNT_TYPES_ERROR,
  payload: state,
});

export const deleteAccountTypeAction = (id) => ({
  type: RETRIEVE_ACCOUNT_TYPES_DELETE,
  payload: id,
});

export const deleteAccountTypeOkAction = () => ({
  type: ACCOUNT_TYPES_DELETED_OK,
});

export const deleteAccountTypeErrorAction = () => ({
  type: ACCOUNT_TYPES_DELETED_ERROR,
  payload: true,
});

export const retrieveAccountTypeAction = (item) => ({
  type: RETRIEVE_ACCOUNT_TYPES_EDIT,
  payload: item,
});

export const editAccountTypeAction = (item) => ({
  type: BEGIN_EDIT_ACCOUNT_TYPES,
  accountType: item,
});

export const editAccountTypeOkAction = (item) => ({
  type: ACCOUNT_TYPES_EDITED_OK,
  payload: item,
});

export const editAccountTypeErrorAction = () => ({
  type: ACCOUNT_TYPES_EDITED_ERROR,
  payload: true,
});
