import {
  ADD_TNA_TEMPLATE_ACTIONS,
  ADD_TNA_TEMPLATE_ACTIONS_ERROR,
  ADD_TNA_TEMPLATE_ACTIONS_OK,
  BEGIN_EDIT_TNA_TEMPLATE_ACTIONS,
  BEGIN_TNA_TEMPLATE_ACTIONS_DOWNLOAD,
  RETRIEVE_TNA_TEMPLATE_ACTIONS_BULK_DELETE,
  RETRIEVE_TNA_TEMPLATE_ACTIONS_DELETE,
  RETRIEVE_TNA_TEMPLATE_ACTIONS_EDIT,
  TNA_TEMPLATE_ACTIONS_BULK_DELETED_ERROR,
  TNA_TEMPLATE_ACTIONS_BULK_DELETED_OK,
  TNA_TEMPLATE_ACTIONS_DELETED_ERROR,
  TNA_TEMPLATE_ACTIONS_DELETED_OK,
  TNA_TEMPLATE_ACTIONS_DOWNLOAD_ERROR,
  TNA_TEMPLATE_ACTIONS_DOWNLOAD_OK,
  TNA_TEMPLATE_ACTIONS_EDITED_ERROR,
  TNA_TEMPLATE_ACTIONS_EDITED_OK,
} from "../../constants/modules/tnaTemplatesActionsConstants";

export const bulkDeleteTnaTemplateActionAction = (item) => ({
  type: RETRIEVE_TNA_TEMPLATE_ACTIONS_BULK_DELETE,
  payload: true,
  tnaTemplateAction: item,
});

export const bulkDeleteTnaTemplateActionOkAction = () => ({
  type: TNA_TEMPLATE_ACTIONS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteTnaTemplateActionErrorAction = (state) => ({
  type: TNA_TEMPLATE_ACTIONS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadTnaTemplateActionsAction = () => ({
  type: BEGIN_TNA_TEMPLATE_ACTIONS_DOWNLOAD,
  payload: true,
});

export const downloadTnaTemplateActionsOkAction = (items) => ({
  type: TNA_TEMPLATE_ACTIONS_DOWNLOAD_OK,
  payload: items,
});

export const downloadTnaTemplateActionsErrorAction = () => ({
  type: TNA_TEMPLATE_ACTIONS_DOWNLOAD_ERROR,
  payload: true,
});

export const addTnaTemplateActionAction = (item) => ({
  type: ADD_TNA_TEMPLATE_ACTIONS,
  payload: true,
  tnaTemplateAction: item,
});

export const addTnaTemplateActionOkAction = () => ({
  type: ADD_TNA_TEMPLATE_ACTIONS_OK,
  payload: true,
});

export const addTnaTemplateActionErrorAction = (state) => ({
  type: ADD_TNA_TEMPLATE_ACTIONS_ERROR,
  payload: state,
});

export const deleteTnaTemplateActionAction = (id) => ({
  type: RETRIEVE_TNA_TEMPLATE_ACTIONS_DELETE,
  payload: id,
});

export const deleteTnaTemplateActionOkAction = () => ({
  type: TNA_TEMPLATE_ACTIONS_DELETED_OK,
});

export const deleteTnaTemplateActionErrorAction = () => ({
  type: TNA_TEMPLATE_ACTIONS_DELETED_ERROR,
  payload: true,
});

export const retrieveTnaTemplateActionAction = (item) => ({
  type: RETRIEVE_TNA_TEMPLATE_ACTIONS_EDIT,
  payload: item,
});

export const editTnaTemplateActionAction = (item) => ({
  type: BEGIN_EDIT_TNA_TEMPLATE_ACTIONS,
  tnaTemplateAction: item,
});

export const editTnaTemplateActionOkAction = (item) => ({
  type: TNA_TEMPLATE_ACTIONS_EDITED_OK,
  payload: item,
});

export const editTnaTemplateActionErrorAction = () => ({
  type: TNA_TEMPLATE_ACTIONS_EDITED_ERROR,
  payload: true,
});
