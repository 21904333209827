import {
  ADD_COMMISSION,
  ADD_COMMISSION_OK,
  ADD_COMMISSION_ERROR,
  BEGIN_COMMISSION_DOWNLOAD,
  COMMISSION_DOWNLOAD_OK,
  COMMISSION_DOWNLOAD_ERROR,
  RETRIEVE_COMMISSION_DELETE,
  COMMISSION_DELETED_OK,
  COMMISSION_DELETED_ERROR,
} from "../../constants/modules/commissionConstants";

const initialState = {
  commissions: [],
  error: false,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_COMMISSION_DOWNLOAD:
    case RETRIEVE_COMMISSION_DELETE:
    case ADD_COMMISSION:
      return {
        ...state,
        loading: action.payload,
        commission: action.commission,
      };

    case ADD_COMMISSION_OK:
    case COMMISSION_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_COMMISSION_ERROR:
    case COMMISSION_DOWNLOAD_ERROR:
    case COMMISSION_DELETED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COMMISSION_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        commissions: action.payload,
      };

    default:
      return state;
  }
}
