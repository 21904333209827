import {
  ADD_TNA_HEADER,
  ADD_TNA_HEADER_ERROR,
  ADD_TNA_HEADER_OK,
  BEGIN_EDIT_TNA_HEADER,
  BEGIN_TNA_HEADER_DOWNLOAD,
  RETRIEVE_TNA_HEADER_BULK_DELETE,
  RETRIEVE_TNA_HEADER_DELETE,
  RETRIEVE_TNA_HEADER_EDIT,
  TNA_HEADER_BULK_DELETED_ERROR,
  TNA_HEADER_BULK_DELETED_OK,
  TNA_HEADER_DELETED_ERROR,
  TNA_HEADER_DELETED_OK,
  TNA_HEADER_DOWNLOAD_ERROR,
  TNA_HEADER_DOWNLOAD_OK,
  TNA_HEADER_EDITED_ERROR,
  TNA_HEADER_EDITED_OK,
  BEGIN_TNA_HEADER_BY_ID_DOWNLOAD,
  TNA_HEADER_BY_ID_DOWNLOAD_OK,
  TNA_HEADER_BY_ID_DOWNLOAD_ERROR,
  BEGIN_TNA_FACTORY_BY_ORDER_DOWNLOAD,
  TNA_FACTORY_BY_ORDER_DOWNLOAD_OK,
  TNA_FACTORY_BY_ORDER_DOWNLOAD_ERROR,
  BEGIN_TNA_STYLE_BY_FACTORY_DOWNLOAD,
  TNA_STYLE_BY_FACTORY_DOWNLOAD_OK,
  TNA_STYLE_BY_FACTORY_DOWNLOAD_ERROR,
  BEGIN_TNA_TEMPLATE_BY_BUYER_DOWNLOAD,
  TNA_TEMPLATE_BY_BUYER_DOWNLOAD_OK,
  TNA_TEMPLATE_BY_BUYER_DOWNLOAD_ERROR,
  RESET,
} from "../../constants/modules/tnaPlanHeaderConstants";

const initialState = {
  tnaHeaders: [],
  getTnaHeader: null,
  tnaHeaderAddInfo: null,
  factoryByOrder: null,
  styleByFactory: null,
  templateByBuyer: null,
  error: false,
  loading: false,
  deleteTnaHeader: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_TNA_HEADER_DOWNLOAD:
    case BEGIN_TNA_HEADER_BY_ID_DOWNLOAD:
    case RETRIEVE_TNA_HEADER_BULK_DELETE:
    case ADD_TNA_HEADER:
    case BEGIN_EDIT_TNA_HEADER:
    case BEGIN_TNA_FACTORY_BY_ORDER_DOWNLOAD:
    case BEGIN_TNA_STYLE_BY_FACTORY_DOWNLOAD:
    case BEGIN_TNA_TEMPLATE_BY_BUYER_DOWNLOAD:
      return {
        ...state,
        loading: action.payload,
        tnaHeader: action.tnaHeader,
      };

    case TNA_HEADER_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_TNA_HEADER_ERROR:
    case TNA_HEADER_DOWNLOAD_ERROR:
    case TNA_HEADER_BULK_DELETED_ERROR:
    case TNA_HEADER_BY_ID_DOWNLOAD_ERROR:
    case TNA_HEADER_DELETED_ERROR:
    case TNA_HEADER_EDITED_ERROR:
    case TNA_FACTORY_BY_ORDER_DOWNLOAD_ERROR:
    case TNA_STYLE_BY_FACTORY_DOWNLOAD_ERROR:
    case TNA_TEMPLATE_BY_BUYER_DOWNLOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TNA_HEADER_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        tnaHeaders: action.payload,
      };

    case TNA_HEADER_BY_ID_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        getTnaHeader: action.payload,
      };

    case ADD_TNA_HEADER_OK:
      return {
        ...state,
        loading: false,
        error: null,
        tnaHeaderAddInfo: action.payload,
      };

    case TNA_FACTORY_BY_ORDER_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        factoryByOrder: action.payload,
      };

    case TNA_STYLE_BY_FACTORY_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        styleByFactory: action.payload,
      };

    case TNA_TEMPLATE_BY_BUYER_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        templateByBuyer: action.payload,
      };

    case RETRIEVE_TNA_HEADER_DELETE:
      return {
        ...state,
        deleteTnaHeader: action.payload,
      };

    case TNA_HEADER_DELETED_OK:
      return {
        ...state,
        tnaHeaders: state.tnaHeaders.data.filter(
          (tnaHeader) => tnaHeader.id !== state.deleteTnaHeader
        ),
        deleteTnaHeader: null,
      };

    case RETRIEVE_TNA_HEADER_EDIT:
      return {
        ...state,
        editTnaHeader: action.payload,
      };

    case TNA_HEADER_EDITED_OK:
      return {
        ...state,
        editTnaHeader: null,
        tnaHeaders: state.tnaHeaders.data.map((tnaHeader) =>
          tnaHeader.id === action.payload.id
            ? (tnaHeader = action.payload)
            : tnaHeader
        ),
      };

    case RESET:
      return {
        ...state,
        getTnaHeader: null,
        tnaHeaderAddInfo: null,
        factoryByOrder: null,
        styleByFactory: null,
        templateByBuyer: null,
      };

    default:
      return state;
  }
}
