import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_TNA_ACTIONS,
  BEGIN_EDIT_TNA_ACTIONS,
  BEGIN_TNA_ACTIONS_DOWNLOAD,
  RETRIEVE_TNA_ACTIONS_BULK_DELETE,
  RETRIEVE_TNA_ACTIONS_DELETE
} from "../../constants/modules/tnaActionsConstants";

import {
  addTnaActionErrorAction,
  addTnaActionOkAction,
  bulkDeleteTnaActionErrorAction,
  bulkDeleteTnaActionOkAction,
  deleteTnaActionErrorAction,
  deleteTnaActionOkAction,
  downloadTnaActionsErrorAction,
  downloadTnaActionsOkAction,
  editTnaActionErrorAction,
  editTnaActionOkAction
} from "../../actions/modules/tnaActionsAction";

import {
  addTnaActionDB,
  bulkDeleteTnaActionDB,
  deleteTnaActionDB,
  editTnaActionDB,
  retrieveTnaActionsData
} from "../../../config/apiCalls/tnaActionsApiCalls";

// Retrieve products
// worker saga
export function* retrieveTnaActions() {
  try {
    const response = yield call(retrieveTnaActionsData);

    if (response.status === 200) {
      yield put(downloadTnaActionsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTnaActionsErrorAction());
  }
}

// Create new product
// worker saga
export function* addTnaAction(action) {
  const tnaAction = action.tnaAction;

  try {
    const response = yield call(addTnaActionDB, tnaAction);

    if (response.status === 201) {
      yield delay(500);

      yield put(addTnaActionOkAction(tnaAction));
      toast.success("TNA Action created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addTnaActionErrorAction(true));
    toast.error("TNA Action created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteTnaAction(action) {
  const id = action.payload;
  try {
    yield call(deleteTnaActionDB, id);
    yield deleteTnaActionOkAction();
    toast.success("TNA Action deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteTnaActionErrorAction();
  }
}

// Edit product
// worker saga
export function* editTnaAction(action) {
  const tnaAction = action.tnaAction;

  try {
    yield call(editTnaActionDB, tnaAction);
    yield editTnaActionOkAction(tnaAction);
    // Alert
    // Alert
    toast.success("TNA Action has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editTnaActionErrorAction();
  }
}

// BULK SAGAS
export function* bulkTnaActionDelete(action) {
  const tnaAction = action.tnaAction;

  try {
    const response = yield call(bulkDeleteTnaActionDB, tnaAction);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteTnaActionOkAction(tnaAction));
      toast.success("Bulk TNA Action deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteTnaActionErrorAction(true));
    toast.error("Bulk TNA Action deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchTnaActionsAsync() {
  yield takeLatest(BEGIN_TNA_ACTIONS_DOWNLOAD, retrieveTnaActions);
  yield takeLatest(ADD_TNA_ACTIONS, addTnaAction);
  yield takeLatest(RETRIEVE_TNA_ACTIONS_DELETE, deleteTnaAction);
  yield takeLatest(BEGIN_EDIT_TNA_ACTIONS, editTnaAction);
  yield takeLatest(RETRIEVE_TNA_ACTIONS_BULK_DELETE, bulkTnaActionDelete);
}
