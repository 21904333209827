import {
  ADD_USER_LEVELS,
  ADD_USER_LEVELS_ERROR,
  ADD_USER_LEVELS_OK,
  BEGIN_EDIT_USER_LEVELS,
  BEGIN_USER_LEVELS_DOWNLOAD,
  RETRIEVE_USER_LEVELS_BULK_DELETE,
  RETRIEVE_USER_LEVELS_DELETE,
  RETRIEVE_USER_LEVELS_EDIT,
  USER_LEVELS_BULK_DELETED_ERROR,
  USER_LEVELS_BULK_DELETED_OK,
  USER_LEVELS_DELETED_ERROR,
  USER_LEVELS_DELETED_OK,
  USER_LEVELS_DOWNLOAD_ERROR,
  USER_LEVELS_DOWNLOAD_OK,
  USER_LEVELS_EDITED_ERROR,
  USER_LEVELS_EDITED_OK
} from "../../constants/modules/userLevelsConstant";

  const initialState = {
    userLevels: [],
    error: false,
    loading: false,
    deleteUserLevel: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_USER_LEVELS_DOWNLOAD:
      case RETRIEVE_USER_LEVELS_BULK_DELETE:
      case ADD_USER_LEVELS:
      case BEGIN_EDIT_USER_LEVELS:
        return {
          ...state,
          loading: action.payload,
          userLevel: action.userLevel,
        };
  
      case ADD_USER_LEVELS_OK:
      case USER_LEVELS_BULK_DELETED_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_USER_LEVELS_ERROR:
      case USER_LEVELS_DOWNLOAD_ERROR:
      case USER_LEVELS_BULK_DELETED_ERROR:
      case USER_LEVELS_DELETED_ERROR:
      case USER_LEVELS_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case USER_LEVELS_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          userLevels: action.payload,
        };
  
      case RETRIEVE_USER_LEVELS_DELETE:
        return {
          ...state,
          deleteUserLevel: action.payload,
        };
  
      case USER_LEVELS_DELETED_OK:
        return {
          ...state,
          userLevels: state.userLevels.data.filter((userLevel) => userLevel.id !== state.deleteUserLevel),
          deleteUserLevel: null,
        };
  
      case RETRIEVE_USER_LEVELS_EDIT:
        return {
          ...state,
          editUserLevel: action.payload,
        };
  
      case USER_LEVELS_EDITED_OK:
        return {
          ...state,
          editUserLevel: null,
          userLevels: state.userLevels.data.map((userLevel) =>
            userLevel.id === action.payload.id ? (userLevel = action.payload) : userLevel
          ),
        };
  
      default:
        return state;
    }
  }
  