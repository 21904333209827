import {
    ADD_APPROVAL_PANELS,
    ADD_APPROVAL_PANELS_ERROR,
    ADD_APPROVAL_PANELS_OK,
    APPROVAL_PANELS_BULK_DELETED_ERROR,
    APPROVAL_PANELS_BULK_DELETED_OK,
    APPROVAL_PANELS_DELETED_ERROR,
    APPROVAL_PANELS_DELETED_OK,
    APPROVAL_PANELS_DOWNLOAD_ERROR,
    APPROVAL_PANELS_DOWNLOAD_OK,
    APPROVAL_PANELS_EDITED_ERROR,
    APPROVAL_PANELS_EDITED_OK,
    BEGIN_APPROVAL_PANELS_DOWNLOAD,
    BEGIN_EDIT_APPROVAL_PANELS,
    RETRIEVE_APPROVAL_PANELS_BULK_DELETE,
    RETRIEVE_APPROVAL_PANELS_DELETE,
    RETRIEVE_APPROVAL_PANELS_EDIT
} from "../../constants/modules/approvalPanelsConstants.js";

const initialState = {
  approvalPanels: [],
  error: false,
  loading: false,
  deleteApprovalPanel: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_APPROVAL_PANELS_DOWNLOAD:
    case RETRIEVE_APPROVAL_PANELS_BULK_DELETE:
    case ADD_APPROVAL_PANELS:
    case BEGIN_EDIT_APPROVAL_PANELS:
      return {
        ...state,
        loading: action.payload,
        approvalPanel: action.approvalPanel,
      };

    case ADD_APPROVAL_PANELS_OK:
    case APPROVAL_PANELS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_APPROVAL_PANELS_ERROR:
    case APPROVAL_PANELS_DOWNLOAD_ERROR:
    case APPROVAL_PANELS_BULK_DELETED_ERROR:
    case APPROVAL_PANELS_DELETED_ERROR:
    case APPROVAL_PANELS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case APPROVAL_PANELS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        approvalPanels: action.payload,
      };

    case RETRIEVE_APPROVAL_PANELS_DELETE:
      return {
        ...state,
        deleteApprovalPanel: action.payload,
      };

    case APPROVAL_PANELS_DELETED_OK:
      return {
        ...state,
        approvalPanels: state.approvalPanels.data.filter(
          (approvalPanel) => approvalPanel.id !== state.deleteApprovalPanel
        ),
        deleteApprovalPanel: null,
      };

    case RETRIEVE_APPROVAL_PANELS_EDIT:
      return {
        ...state,
        editApprovalPanel: action.payload,
      };

    case APPROVAL_PANELS_EDITED_OK:
      return {
        ...state,
        editApprovalPanel: null,
        approvalPanels: state.approvalPanels.data.map((approvalPanel) =>
          approvalPanel.id === action.payload.id
            ? (approvalPanel = action.payload)
            : approvalPanel
        ),
      };

    default:
      return state;
  }
}
