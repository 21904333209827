import {
  ADD_LC_HEADERS,
  ADD_LC_HEADERS_ERROR,
  ADD_LC_HEADERS_OK,
  BEGIN_EDIT_LC_HEADERS,
  BEGIN_LC_HEADERS_DOWNLOAD,
  BEGIN_LC_HEADER_DOWNLOAD,
  LC_HEADERS_BULK_DELETED_ERROR,
  LC_HEADERS_BULK_DELETED_OK,
  LC_HEADERS_DELETED_ERROR,
  LC_HEADERS_DELETED_OK,
  LC_HEADERS_DOWNLOAD_ERROR,
  LC_HEADERS_DOWNLOAD_OK,
  LC_HEADERS_EDITED_ERROR,
  LC_HEADERS_EDITED_OK,
  LC_HEADER_DOWNLOAD_ERROR,
  LC_HEADER_DOWNLOAD_OK, RESET,
  RETRIEVE_LC_HEADERS_BULK_DELETE,
  RETRIEVE_LC_HEADERS_DELETE,
  RETRIEVE_LC_HEADERS_EDIT
} from "../../constants/modules/lcHeaderConstants";
  
  const initialState = {
    lcHeaders: [],
    getLCHeader: null,
    lcHeaderAddInfo: null,
    error: false,
    loading: false,
    deleteLCHeader: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_LC_HEADERS_DOWNLOAD:
      case BEGIN_LC_HEADER_DOWNLOAD:
      case RETRIEVE_LC_HEADERS_BULK_DELETE:
      case ADD_LC_HEADERS:
      case BEGIN_EDIT_LC_HEADERS:
        return {
          ...state,
          loading: action.payload,
          lcHeader: action.lcHeader,
        };
  
      case LC_HEADERS_BULK_DELETED_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_LC_HEADERS_ERROR:
      case LC_HEADERS_DOWNLOAD_ERROR:
      case LC_HEADERS_BULK_DELETED_ERROR:
      case LC_HEADER_DOWNLOAD_ERROR:
      case LC_HEADERS_DELETED_ERROR:
      case LC_HEADERS_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case LC_HEADERS_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          lcHeaders: action.payload,
        };
  
      case LC_HEADER_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          getLCHeader: action.payload,
        };
  
      case ADD_LC_HEADERS_OK:
        return {
          ...state,
          loading: false,
          error: null,
          lcHeaderAddInfo: action.payload,
        };
  
      case RETRIEVE_LC_HEADERS_DELETE:
        return {
          ...state,
          deleteLCHeader: action.payload,
        };
  
      case LC_HEADERS_DELETED_OK:
        return {
          ...state,
          lcHeaders: state.lcHeaders.data.filter(
            (lcHeader) => lcHeader.id !== state.deleteLCHeader
          ),
          deleteLCHeader: null,
        };
  
      case RETRIEVE_LC_HEADERS_EDIT:
        return {
          ...state,
          editLCHeader: action.payload,
        };
  
      case LC_HEADERS_EDITED_OK:
        return {
          ...state,
          editLCHeader: null,
          lcHeaders: state.lcHeaders.data.map((lcHeader) =>
            lcHeader.id === action.payload.id
              ? (lcHeader = action.payload)
              : lcHeader
          ),
        };
  
      case RESET:
        return {
          ...state,
          getLCHeader: null,
          lcHeaderAddInfo: null,
        };
  
      default:
        return state;
    }
  }
  