import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    ADD_TT_ADVANCES,
    BEGIN_EDIT_TT_ADVANCES,
    BEGIN_TT_ADVANCES_DOWNLOAD,
    BEGIN_TT_ADVANCE_DOWNLOAD,
    RETRIEVE_TT_ADVANCES_DELETE
} from "../../constants/modules/TTAdvanceConstant";

import {
    addTTAdvanceErrorAction,
    addTTAdvanceOkAction,
    deleteTTAdvanceErrorAction,
    deleteTTAdvanceOkAction,
    downloadTTAdvanceErrorAction,
    downloadTTAdvanceOkAction,
    downloadTTAdvancesErrorAction,
    downloadTTAdvancesOkAction,
    editTTAdvanceErrorAction,
    editTTAdvanceOkAction
} from "../../actions/modules/TTAdvanceAction";

import {
    addTTAdvanceDB,
    deleteTTAdvanceDB,
    editTTAdvanceDB,
    retrieveTTAdvanceData,
    retrieveTTAdvancesData
} from "../../../config/apiCalls/TTAdvanceAPICall";

// Retrieve products
// worker saga
export function* retrieveTTAdvances() {
  try {
    const response = yield call(retrieveTTAdvancesData);

    if (response.status === 200) {
      yield put(downloadTTAdvancesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTTAdvancesErrorAction());
  }
}

export function* retrieveTTAdvance(action) {
  const ttAdvance = action.ttAdvance;
  try {
    const response = yield call(retrieveTTAdvanceData, ttAdvance);
    if (response.status === 200) {
      yield put(downloadTTAdvanceOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTTAdvanceErrorAction());
  }
}

// Create new product
// worker saga
export function* addTTAdvance(action) {
  const ttAdvance = action.ttAdvance;
  try {
    const response = yield call(addTTAdvanceDB, ttAdvance);
    if (response.status === 200) {
      yield delay(500);
      yield put(addTTAdvanceOkAction(response.data));
      toast.success("TT Advance created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addTTAdvanceErrorAction(true));
    toast.error("TT Advance created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteTTAdvance(action) {
  const id = action.payload;
  try {
    yield call(deleteTTAdvanceDB, id);
    yield deleteTTAdvanceOkAction();
    toast.success("TT Advance deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteTTAdvanceErrorAction();
  }
}

// Edit product
// worker saga
export function* editTTAdvance(action) {
  const ttAdvance = action.ttAdvance;

  try {
    yield call(editTTAdvanceDB, ttAdvance);
    yield editTTAdvanceOkAction(ttAdvance);
    // Alert
    // Alert
    toast.success("TT Advance has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editTTAdvanceErrorAction();
  }
}

export function* watchTTAdvancesAsync() {
  yield takeLatest(BEGIN_TT_ADVANCES_DOWNLOAD, retrieveTTAdvances);
  yield takeLatest(BEGIN_TT_ADVANCE_DOWNLOAD, retrieveTTAdvance);
  yield takeLatest(ADD_TT_ADVANCES, addTTAdvance);
  yield takeLatest(RETRIEVE_TT_ADVANCES_DELETE, deleteTTAdvance);
  yield takeLatest(BEGIN_EDIT_TT_ADVANCES, editTTAdvance);
}
