import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_FOB_TYPES,
  BEGIN_EDIT_FOB_TYPES,
  BEGIN_FOB_TYPES_DOWNLOAD,
  RETRIEVE_FOB_TYPES_BULK_DELETE,
  RETRIEVE_FOB_TYPES_DELETE
} from "../../constants/modules/fobTypesConstants";

import {
  addFobTypeErrorAction,
  addFobTypeOkAction,
  bulkDeleteFobTypeErrorAction,
  bulkDeleteFobTypeOkAction,
  deleteFobTypeErrorAction,
  deleteFobTypeOkAction,
  downloadFobTypesErrorAction,
  downloadFobTypesOkAction,
  editFobTypeErrorAction,
  editFobTypeOkAction
} from "../../actions/modules/fobTypesActions";

import {
  addFobTypeDB,
  bulkDeleteFobTypeDB,
  deleteFobTypeDB,
  editFobTypeDB,
  retrieveFobTypesData
} from "../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveFobTypes() {
  try {
    const response = yield call(retrieveFobTypesData);

    if (response.status === 200) {
      yield put(downloadFobTypesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFobTypesErrorAction());
  }
}

// Create new product
// worker saga
export function* addFobType(action) {
  const fobType = action.fobType;

  try {
    const response = yield call(addFobTypeDB, fobType);

    if (response.status === 201) {
      yield delay(500);

      yield put(addFobTypeOkAction(fobType));
      toast.success("Fob-Type created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addFobTypeErrorAction(true));
    toast.error("Fob-Type created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteFobType(action) {
  const id = action.payload;
  try {
    yield call(deleteFobTypeDB, id);
    yield deleteFobTypeOkAction();
    toast.success("Fob-Type deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteFobTypeErrorAction();
  }
}

// Edit product
// worker saga
export function* editFobType(action) {
  const fobType = action.fobType;

  try {
    yield call(editFobTypeDB, fobType);
    yield editFobTypeOkAction(fobType);
    // Alert
    toast.success("Fob-Type has been updated!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editFobTypeErrorAction();
  }
}

// BULK SAGAS
export function* bulkFobTypeDelete(action) {
  const fobType = action.fobType;

  try {
    const response = yield call(bulkDeleteFobTypeDB, fobType);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteFobTypeOkAction(fobType));
      toast.success("Bulk Fob-Type deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteFobTypeErrorAction(true));
    toast.error("Bulk Fob-Type deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchFobTypesAsync() {
  yield takeLatest(BEGIN_FOB_TYPES_DOWNLOAD, retrieveFobTypes);
  yield takeLatest(ADD_FOB_TYPES, addFobType);
  yield takeLatest(RETRIEVE_FOB_TYPES_DELETE, deleteFobType);
  yield takeLatest(BEGIN_EDIT_FOB_TYPES, editFobType);
  yield takeLatest(RETRIEVE_FOB_TYPES_BULK_DELETE, bulkFobTypeDelete);
}
