export const ADD_USER_ROLE = "ADD_USER_ROLE";
export const ADD_USER_ROLE_OK = "ADD_USER_ROLE_OK";
export const ADD_USER_ROLE_ERROR = "ADD_USER_ROLE_ERROR";

export const BEGIN_USER_ROLE_DOWNLOAD = "BEGIN_USER_ROLE_DOWNLOAD";
export const USER_ROLE_DOWNLOAD_OK = "USER_ROLE_DOWNLOAD_OK";
export const USER_ROLE_DOWNLOAD_ERROR = "USER_ROLE_DOWNLOAD_ERROR";

export const RETRIEVE_USER_ROLE_EDIT = "RETRIEVE_USER_ROLE_EDITED";
export const BEGIN_EDIT_USER_ROLE = "BEGIN_EDIT_USER_ROLE";
export const USER_ROLE_EDITED_OK = "USER_ROLE_EDITED_OK";
export const USER_ROLE_EDITED_ERROR = "USER_ROLE_EDITED_ERROR";

export const RETRIEVE_USER_ROLE_DELETE = "RETRIEVE_USER_ROLE_DELETE";
export const USER_ROLE_DELETED_OK = "USER_ROLE_DELETED_OK";
export const USER_ROLE_DELETED_ERROR = "USER_ROLE_DELETED_ERROR";

export const RETRIEVE_USER_ROLE_BULK_DELETE = "RETRIEVE_USER_ROLE_BULK_DELETE";
export const USER_ROLE_BULK_DELETED_OK = "USER_ROLE_BULK_DELETED_OK";
export const USER_ROLE_BULK_DELETED_ERROR = "USER_ROLE_BULK_DELETED_ERROR";
