export const ADD_CURRENCIES = "ADD_CURRENCIES";
export const ADD_CURRENCIES_OK = "ADD_CURRENCIES_OK";
export const ADD_CURRENCIES_ERROR = "ADD_CURRENCIES_ERROR";

export const BEGIN_CURRENCIES_DOWNLOAD = "BEGIN_CURRENCIES_DOWNLOAD";
export const CURRENCIES_DOWNLOAD_OK = "CURRENCIES_DOWNLOAD_OK";
export const CURRENCIES_DOWNLOAD_ERROR = "CURRENCIES_DOWNLOAD_ERROR";

export const RETRIEVE_CURRENCIES_DELETE = "RETRIEVE_CURRENCIES_DELETE";
export const CURRENCIES_DELETED_OK = "CURRENCIES_DELETED_OK";
export const CURRENCIES_DELETED_ERROR = "CURRENCIES_DELETED_ERROR";

export const RETRIEVE_CURRENCIES_EDIT = "RETRIEVE_CURRENCIES_EDITED";
export const BEGIN_EDIT_CURRENCIES = "BEGIN_EDIT_CURRENCIES";
export const CURRENCIES_EDITED_OK = "CURRENCIES_EDITED_OK";
export const CURRENCIES_EDITED_ERROR = "CURRENCIES_EDITED_ERROR";

export const RETRIEVE_CURRENCIES_BULK_DELETE = "RETRIEVE_CURRENCIES_BULK_DELETE";
export const CURRENCIES_BULK_DELETED_OK = "CURRENCIES_BULK_DELETED_OK";
export const CURRENCIES_BULK_DELETED_ERROR = "CURRENCIES_BULK_DELETED_ERROR";