import {
  ADD_TNA_ACTIONS,
  ADD_TNA_ACTIONS_ERROR,
  ADD_TNA_ACTIONS_OK,
  BEGIN_EDIT_TNA_ACTIONS,
  BEGIN_TNA_ACTIONS_DOWNLOAD,
  RETRIEVE_TNA_ACTIONS_BULK_DELETE,
  RETRIEVE_TNA_ACTIONS_DELETE,
  RETRIEVE_TNA_ACTIONS_EDIT,
  TNA_ACTIONS_BULK_DELETED_ERROR,
  TNA_ACTIONS_BULK_DELETED_OK,
  TNA_ACTIONS_DELETED_ERROR,
  TNA_ACTIONS_DELETED_OK,
  TNA_ACTIONS_DOWNLOAD_ERROR,
  TNA_ACTIONS_DOWNLOAD_OK,
  TNA_ACTIONS_EDITED_ERROR,
  TNA_ACTIONS_EDITED_OK,
} from "../../constants/modules/tnaActionsConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteTnaActionAction = (item) => ({
  type: RETRIEVE_TNA_ACTIONS_BULK_DELETE,
  payload: true,
  tnaAction: item,
});

export const bulkDeleteTnaActionOkAction = () => ({
  type: TNA_ACTIONS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteTnaActionErrorAction = (state) => ({
  type: TNA_ACTIONS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadTnaActionsAction = () => ({
  type: BEGIN_TNA_ACTIONS_DOWNLOAD,
  payload: true,
});

export const downloadTnaActionsOkAction = (items) => ({
  type: TNA_ACTIONS_DOWNLOAD_OK,
  payload: items,
});

export const downloadTnaActionsErrorAction = () => ({
  type: TNA_ACTIONS_DOWNLOAD_ERROR,
  payload: true,
});

export const addTnaActionAction = (item) => ({
  type: ADD_TNA_ACTIONS,
  payload: true,
  tnaAction: item,
});

export const addTnaActionOkAction = () => ({
  type: ADD_TNA_ACTIONS_OK,
  payload: true,
});

export const addTnaActionErrorAction = (state) => ({
  type: ADD_TNA_ACTIONS_ERROR,
  payload: state,
});

export const deleteTnaActionAction = (id) => ({
  type: RETRIEVE_TNA_ACTIONS_DELETE,
  payload: id,
});

export const deleteTnaActionOkAction = () => ({
  type: TNA_ACTIONS_DELETED_OK,
});

export const deleteTnaActionErrorAction = () => ({
  type: TNA_ACTIONS_DELETED_ERROR,
  payload: true,
});

export const retrieveTnaActionAction = (item) => ({
  type: RETRIEVE_TNA_ACTIONS_EDIT,
  payload: item,
});

export const editTnaActionAction = (item) => ({
  type: BEGIN_EDIT_TNA_ACTIONS,
  tnaAction: item,
});

export const editTnaActionOkAction = (item) => ({
  type: TNA_ACTIONS_EDITED_OK,
  payload: item,
});

export const editTnaActionErrorAction = () => ({
  type: TNA_ACTIONS_EDITED_ERROR,
  payload: true,
});
