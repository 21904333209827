import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_LC_HEADERS,
  BEGIN_EDIT_LC_HEADERS,
  BEGIN_LC_HEADERS_DOWNLOAD,
  BEGIN_LC_HEADER_DOWNLOAD,
  RETRIEVE_LC_HEADERS_BULK_DELETE,
  RETRIEVE_LC_HEADERS_DELETE,
} from "../../constants/modules/lcHeaderConstants";

import {
  addLCHeaderErrorAction,
  addLCHeaderOkAction,
  bulkDeleteLCHeaderErrorAction,
  bulkDeleteLCHeaderOkAction,
  deleteLCHeaderErrorAction,
  deleteLCHeaderOkAction,
  downloadLCHeaderErrorAction,
  downloadLCHeaderOkAction,
  downloadLCHeadersErrorAction,
  downloadLCHeadersOkAction,
  editLCHeaderErrorAction,
  editLCHeaderOkAction,
} from "../../actions/modules/lcHeaderActions";

import {
  addLCHeaderDB,
  bulkDeleteLCHeaderDB,
  deleteLCHeaderDB,
  editLCHeaderDB,
  retrieveLCHeaderData,
  retrieveLCHeadersData,
} from "../../../config/apiCalls/lcHeaderApiCall";

// Retrieve products
// worker saga
export function* retrieveLCHeaders(action) {
  const lcHeader = action.lcHeader;
  try {
    const response = yield call(retrieveLCHeadersData, lcHeader);

    if (response.status === 200) {
      yield put(downloadLCHeadersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadLCHeadersErrorAction());
  }
}

export function* retrieveLCHeader(action) {
  const lcHeader = action.lcHeader;
  try {
    const response = yield call(retrieveLCHeaderData, lcHeader);
    if (response.status === 200) {
      yield put(downloadLCHeaderOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadLCHeaderErrorAction());
  }
}

// Create new product
// worker saga
export function* addLCHeader(action) {
  const lcHeader = action.lcHeader;
  try {
    const response = yield call(addLCHeaderDB, lcHeader);

    if (response.status === 200) {
      yield delay(500);
      yield put(addLCHeaderOkAction(response.data));
      toast.success("LC Header created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addLCHeaderErrorAction(true));
    toast.error("LC Header created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteLCHeader(action) {
  const id = action.payload;
  try {
    yield call(deleteLCHeaderDB, id);
    yield deleteLCHeaderOkAction();
    toast.success("LC Header deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteLCHeaderErrorAction();
  }
}

// Edit product
// worker saga
export function* editLCHeader(action) {
  const lcHeader = action.lcHeader;

  try {
    yield call(editLCHeaderDB, lcHeader);
    yield editLCHeaderOkAction(lcHeader);
    // Alert
    // Alert
    toast.success("LC Header has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editLCHeaderErrorAction();
  }
}

// BULK SAGAS
export function* bulkLCHeaderDelete(action) {
  const lcHeader = action.lcHeader;

  try {
    const response = yield call(bulkDeleteLCHeaderDB, lcHeader);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteLCHeaderOkAction(lcHeader));
      toast.success("Bulk LC Header deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteLCHeaderErrorAction(true));
    toast.error("Bulk LC Header deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchLCHeadersAsync() {
  yield takeLatest(BEGIN_LC_HEADERS_DOWNLOAD, retrieveLCHeaders);
  yield takeLatest(BEGIN_LC_HEADER_DOWNLOAD, retrieveLCHeader);
  yield takeLatest(ADD_LC_HEADERS, addLCHeader);
  yield takeLatest(RETRIEVE_LC_HEADERS_DELETE, deleteLCHeader);
  yield takeLatest(BEGIN_EDIT_LC_HEADERS, editLCHeader);
  yield takeLatest(RETRIEVE_LC_HEADERS_BULK_DELETE, bulkLCHeaderDelete);
}
