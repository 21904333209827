import {
  ADD_EX_FACTORIES,
  ADD_EX_FACTORIES_ERROR,
  ADD_EX_FACTORIES_OK,
  BEGIN_EDIT_EX_FACTORIES,
  BEGIN_EX_FACTORIES_DOWNLOAD,
  EX_FACTORIES_BULK_DELETED_ERROR,
  EX_FACTORIES_BULK_DELETED_OK,
  EX_FACTORIES_DELETED_ERROR,
  EX_FACTORIES_DELETED_OK,
  EX_FACTORIES_DOWNLOAD_ERROR,
  EX_FACTORIES_DOWNLOAD_OK,
  EX_FACTORIES_EDITED_ERROR,
  EX_FACTORIES_EDITED_OK,
  RETRIEVE_EX_FACTORIES_BULK_DELETE,
  RETRIEVE_EX_FACTORIES_DELETE,
  RETRIEVE_EX_FACTORIES_EDIT,
} from "../../constants/modules/exFactoriesConstants";

export const bulkDeleteExFactoryAction = (item) => ({
  type: RETRIEVE_EX_FACTORIES_BULK_DELETE,
  payload: true,
  exFactory: item,
});

export const bulkDeleteExFactoryOkAction = () => ({
  type: EX_FACTORIES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteExFactoryErrorAction = (state) => ({
  type: EX_FACTORIES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadExFactoriesAction = () => ({
  type: BEGIN_EX_FACTORIES_DOWNLOAD,
  payload: true,
});

export const downloadExFactoriesOkAction = (items) => ({
  type: EX_FACTORIES_DOWNLOAD_OK,
  payload: items,
});

export const downloadExFactoriesErrorAction = () => ({
  type: EX_FACTORIES_DOWNLOAD_ERROR,
  payload: true,
});

export const addExFactoryAction = (item) => ({
  type: ADD_EX_FACTORIES,
  payload: true,
  exFactory: item,
});

export const addExFactoryOkAction = () => ({
  type: ADD_EX_FACTORIES_OK,
  payload: true,
});

export const addExFactoryErrorAction = (state) => ({
  type: ADD_EX_FACTORIES_ERROR,
  payload: state,
});

export const deleteExFactoryAction = (id) => ({
  type: RETRIEVE_EX_FACTORIES_DELETE,
  payload: id,
});

export const deleteExFactoryOkAction = () => ({
  type: EX_FACTORIES_DELETED_OK,
});

export const deleteExFactoryErrorAction = () => ({
  type: EX_FACTORIES_DELETED_ERROR,
  payload: true,
});

export const retrieveExFactoryAction = (item) => ({
  type: RETRIEVE_EX_FACTORIES_EDIT,
  payload: item,
});

export const editExFactoryAction = (item) => ({
  type: BEGIN_EDIT_EX_FACTORIES,
  exFactory: item,
});

export const editExFactoryOkAction = (item) => ({
  type: EX_FACTORIES_EDITED_OK,
  payload: item,
});

export const editExFactoryErrorAction = () => ({
  type: EX_FACTORIES_EDITED_ERROR,
  payload: true,
});
