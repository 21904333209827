import {
  ADD_APPROVAL_FORM_SETUPS, ADD_APPROVAL_FORM_SETUPS_ERROR, ADD_APPROVAL_FORM_SETUPS_OK, APPROVAL_FORM_SETUPS_DELETED_ERROR, APPROVAL_FORM_SETUPS_DELETED_OK, APPROVAL_FORM_SETUPS_DOWNLOAD_ERROR, APPROVAL_FORM_SETUPS_DOWNLOAD_OK, APPROVAL_FORM_SETUPS_EDITED_ERROR, APPROVAL_FORM_SETUPS_EDITED_OK, BEGIN_APPROVAL_FORM_SETUPS_DOWNLOAD, BEGIN_EDIT_APPROVAL_FORM_SETUPS, RETRIEVE_APPROVAL_FORM_SETUPS_DELETE, RETRIEVE_APPROVAL_FORM_SETUPS_EDIT
} from "../../constants/modules/approvalFormSetupConstant";
    
    const initialState = {
      approvalFormSetups: [],
      error: false,
      loading: false,
      deleteApprovalFormSetup: null,
    };
    
    export default function (state = initialState, action) {
      switch (action.type) {
        case BEGIN_APPROVAL_FORM_SETUPS_DOWNLOAD:
        case ADD_APPROVAL_FORM_SETUPS:
        case BEGIN_EDIT_APPROVAL_FORM_SETUPS:
          return {
            ...state,
            loading: action.payload,
            approvalFormSetup: action.approvalFormSetup,
          };
    
        case ADD_APPROVAL_FORM_SETUPS_OK:
          return {
            ...state,
            loading: false,
          };
    
        case ADD_APPROVAL_FORM_SETUPS_ERROR:
        case APPROVAL_FORM_SETUPS_DOWNLOAD_ERROR:
        case APPROVAL_FORM_SETUPS_DELETED_ERROR:
        case APPROVAL_FORM_SETUPS_EDITED_ERROR:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case APPROVAL_FORM_SETUPS_DOWNLOAD_OK:
          return {
            ...state,
            loading: false,
            error: null,
            approvalFormSetups: action.payload,
          };
    
        case RETRIEVE_APPROVAL_FORM_SETUPS_DELETE:
          return {
            ...state,
            deleteApprovalFormSetup: action.payload,
          };
    
        case APPROVAL_FORM_SETUPS_DELETED_OK:
          return {
            ...state,
            approvalFormSetups: state.approvalFormSetups.data.filter((approvalFormSetup) => approvalFormSetup.id !== state.deleteApprovalFormSetup),
            deleteApprovalFormSetup: null,
          };
    
        case RETRIEVE_APPROVAL_FORM_SETUPS_EDIT:
          return {
            ...state,
            editApprovalFormSetup: action.payload,
          };
    
        case APPROVAL_FORM_SETUPS_EDITED_OK:
          return {
            ...state,
            editApprovalFormSetup: null,
            approvalFormSetups: state.approvalFormSetups.data.map((approvalFormSetup) =>
            approvalFormSetup.id === action.payload.id ? (approvalFormSetup = action.payload) : approvalFormSetup
            ),
          };
    
        default:
          return state;
      }
    }
    