import {
  ADD_PRODUCT_BRANDS,
  ADD_PRODUCT_BRANDS_ERROR,
  ADD_PRODUCT_BRANDS_OK,
  BEGIN_EDIT_PRODUCT_BRANDS,
  BEGIN_PRODUCT_BRANDS_DOWNLOAD,
  PRODUCT_BRANDS_BULK_DELETED_ERROR,
  PRODUCT_BRANDS_BULK_DELETED_OK,
  PRODUCT_BRANDS_DELETED_ERROR,
  PRODUCT_BRANDS_DELETED_OK,
  PRODUCT_BRANDS_DOWNLOAD_ERROR,
  PRODUCT_BRANDS_DOWNLOAD_OK,
  PRODUCT_BRANDS_EDITED_ERROR,
  PRODUCT_BRANDS_EDITED_OK,
  RETRIEVE_PRODUCT_BRANDS_BULK_DELETE,
  RETRIEVE_PRODUCT_BRANDS_DELETE,
  RETRIEVE_PRODUCT_BRANDS_EDIT,
} from "../../constants/modules/productBrandsConstants";

const initialState = {
  productBrands: [],
  error: false,
  loading: false,
  deleteProductBrand: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_PRODUCT_BRANDS_DOWNLOAD:
    case RETRIEVE_PRODUCT_BRANDS_BULK_DELETE:
    case ADD_PRODUCT_BRANDS:
    case BEGIN_EDIT_PRODUCT_BRANDS:
      return {
        ...state,
        loading: action.payload,
        productBrand: action.productBrand,
      };

    case ADD_PRODUCT_BRANDS_OK:
    case PRODUCT_BRANDS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_PRODUCT_BRANDS_ERROR:
    case PRODUCT_BRANDS_DOWNLOAD_ERROR:
    case PRODUCT_BRANDS_BULK_DELETED_ERROR:
    case PRODUCT_BRANDS_DELETED_ERROR:
    case PRODUCT_BRANDS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PRODUCT_BRANDS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        productBrands: action.payload,
      };

    case RETRIEVE_PRODUCT_BRANDS_DELETE:
      return {
        ...state,
        deleteProductBrand: action.payload,
      };

    case PRODUCT_BRANDS_DELETED_OK:
      return {
        ...state,
        productBrands: state.productBrands.data.filter(
          (productBrand) => productBrand.id !== state.deleteProductBrand
        ),
        deleteProductBrand: null,
      };

    case RETRIEVE_PRODUCT_BRANDS_EDIT:
      return {
        ...state,
        editProductBrand: action.payload,
      };

    case PRODUCT_BRANDS_EDITED_OK:
      return {
        ...state,
        editProductBrand: null,
        productBrands: state.productBrands.data.map((productBrand) =>
          productBrand.id === action.payload.id
            ? (productBrand = action.payload)
            : productBrand
        ),
      };

    default:
      return state;
  }
}
