import {
  ADD_ORDERS,
  ADD_ORDERS_OK,
  ADD_ORDERS_ERROR,
  BEGIN_ORDERS_DOWNLOAD,
  ORDERS_DOWNLOAD_OK,
  ORDERS_DOWNLOAD_ERROR,
  RETRIEVE_ORDERS_DELETE,
  ORDERS_DELETED_OK,
  ORDERS_DELETED_ERROR,
  RETRIEVE_ORDERS_EDIT,
  BEGIN_EDIT_ORDERS,
  ORDERS_EDITED_OK,
  ORDERS_EDITED_ERROR,
  RETRIEVE_ORDERS_BULK_DELETE,
  ORDERS_BULK_DELETED_OK,
  ORDERS_BULK_DELETED_ERROR,
  RESET,
} from "../../constants/modules/ordersConstants";

const initialState = {
  orders: [],
  addOrderResponse: null,
  error: false,
  loading: false,
  deleteOrder: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_ORDERS_DOWNLOAD:
    case ADD_ORDERS:
    case RETRIEVE_ORDERS_BULK_DELETE:
    case BEGIN_EDIT_ORDERS:
      return {
        ...state,
        loading: action.payload,
        order: action.order,
      };

    case ORDERS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_ORDERS_ERROR:
    case ORDERS_DOWNLOAD_ERROR:
    case ORDERS_DELETED_ERROR:
    case ORDERS_BULK_DELETED_ERROR:
    case ORDERS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ORDERS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        orders: action.payload,
      };

    case ADD_ORDERS_OK:
      return {
        ...state,
        loading: false,
        error: null,
        addOrderResponse: action.payload,
      };

    case RETRIEVE_ORDERS_DELETE:
      return {
        ...state,
        deleteOrder: action.payload,
      };

    case ORDERS_DELETED_OK:
      return {
        ...state,
        orders: state.orders.data.filter(
          (order) => order.id !== state.deleteOrder
        ),
        deleteUser: null,
      };

    case RETRIEVE_ORDERS_EDIT:
      return {
        ...state,
        editOrder: action.payload,
      };

    case ORDERS_EDITED_OK:
      return {
        ...state,
        editOrder: null,
        orders: state.orders.data.map((order) =>
          order.id === action.payload.id ? (order = action.payload) : order
        ),
      };

    case RESET:
      return {
        ...state,
        addOrderResponse: null,
      };

    default:
      return state;
  }
}
