export const ADD_RDL_INVOICE_DETAILS = "ADD_RDL_INVOICE_DETAILS";
export const ADD_RDL_INVOICE_DETAILS_OK = "ADD_RDL_INVOICE_DETAILS_OK";
export const ADD_RDL_INVOICE_DETAILS_ERROR = "ADD_RDL_INVOICE_DETAILS_ERROR";

export const BEGIN_RDL_INVOICE_DETAILS_DOWNLOAD = "BEGIN_RDL_INVOICE_DETAILS_DOWNLOAD";
export const RDL_INVOICE_DETAILS_DOWNLOAD_OK = "RDL_INVOICE_DETAILS_DOWNLOAD_OK";
export const RDL_INVOICE_DETAILS_DOWNLOAD_ERROR = "RDL_INVOICE_DETAILS_DOWNLOAD_ERROR";

export const BEGIN_RDL_INVOICE_DETAIL_DOWNLOAD = "BEGIN_RDL_INVOICE_DETAIL_DOWNLOAD";
export const RDL_INVOICE_DETAIL_DOWNLOAD_OK = "RDL_INVOICE_DETAIL_DOWNLOAD_OK";
export const RDL_INVOICE_DETAIL_DOWNLOAD_ERROR = "RDL_INVOICE_DETAIL_DOWNLOAD_ERROR";

export const RETRIEVE_RDL_INVOICE_DETAILS_DELETE = "RETRIEVE_RDL_INVOICE_DETAILS_DELETE";
export const RDL_INVOICE_DETAILS_DELETED_OK = "RDL_INVOICE_DETAILS_DELETED_OK";
export const RDL_INVOICE_DETAILS_DELETED_ERROR = "RDL_INVOICE_DETAILS_DELETED_ERROR";

export const RETRIEVE_RDL_INVOICE_DETAILS_EDIT = "RETRIEVE_RDL_INVOICE_DETAILS_EDITED";
export const BEGIN_EDIT_RDL_INVOICE_DETAILS = "BEGIN_EDIT_RDL_INVOICE_DETAILS";
export const RDL_INVOICE_DETAILS_EDITED_OK = "RDL_INVOICE_DETAILS_EDITED_OK";
export const RDL_INVOICE_DETAILS_EDITED_ERROR = "RDL_INVOICE_DETAILS_EDITED_ERROR";

export const RETRIEVE_RDL_INVOICE_DETAILS_BULK_DELETE =
  "RETRIEVE_RDL_INVOICE_DETAILS_BULK_DELETE";
export const RDL_INVOICE_DETAILS_BULK_DELETED_OK = "RDL_INVOICE_DETAILS_BULK_DELETED_OK";
export const RDL_INVOICE_DETAILS_BULK_DELETED_ERROR =
  "RDL_INVOICE_DETAILS_BULK_DELETED_ERROR";

export const RESET = "RESET";
