export const ADD_EX_FACTORIES = "ADD_EX_FACTORIES";
export const ADD_EX_FACTORIES_OK = "ADD_EX_FACTORIES_OK";
export const ADD_EX_FACTORIES_ERROR = "ADD_EX_FACTORIES_ERROR";

export const BEGIN_EX_FACTORIES_DOWNLOAD = "BEGIN_EX_FACTORIES_DOWNLOAD";
export const EX_FACTORIES_DOWNLOAD_OK = "EX_FACTORIES_DOWNLOAD_OK";
export const EX_FACTORIES_DOWNLOAD_ERROR = "EX_FACTORIES_DOWNLOAD_ERROR";

export const RETRIEVE_EX_FACTORIES_DELETE = "RETRIEVE_EX_FACTORIES_DELETE";
export const EX_FACTORIES_DELETED_OK = "EX_FACTORIES_DELETED_OK";
export const EX_FACTORIES_DELETED_ERROR = "EX_FACTORIES_DELETED_ERROR";

export const RETRIEVE_EX_FACTORIES_EDIT = "RETRIEVE_EX_FACTORIES_EDITED";
export const BEGIN_EDIT_EX_FACTORIES = "BEGIN_EDIT_EX_FACTORIES";
export const EX_FACTORIES_EDITED_OK = "EX_FACTORIES_EDITED_OK";
export const EX_FACTORIES_EDITED_ERROR = "EX_FACTORIES_EDITED_ERROR";

export const RETRIEVE_EX_FACTORIES_BULK_DELETE = "RETRIEVE_EX_FACTORIES_BULK_DELETE";
export const EX_FACTORIES_BULK_DELETED_OK = "EX_FACTORIES_BULK_DELETED_OK";
export const EX_FACTORIES_BULK_DELETED_ERROR = "EX_FACTORIES_BULK_DELETED_ERROR";