import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_SAMPLE_STYLES,
  BEGIN_EDIT_SAMPLE_STYLES,
  BEGIN_SAMPLE_STYLES_DOWNLOAD,
  RETRIEVE_SAMPLE_STYLES_BULK_DELETE,
  RETRIEVE_SAMPLE_STYLES_DELETE
} from "../../constants/modules/sampleStylesConstant";

import {
  addSampleStyleErrorAction,
  addSampleStyleOkAction,
  bulkDeleteSampleStyleErrorAction,
  bulkDeleteSampleStyleOkAction,
  deleteSampleStyleErrorAction,
  deleteSampleStyleOkAction,
  downloadSampleStylesErrorAction,
  downloadSampleStylesOkAction,
  editSampleStyleErrorAction,
  editSampleStyleOkAction
} from "../../actions/modules/sampleStylesActions";

import {
  addSampleStyleDB,
  bulkDeleteSampleStyleDB,
  deleteSampleStyleDB,
  editSampleStyleDB,
  retrieveSampleStylesData,
} from "../../../config/apiCalls/sampleStyleAPICall";

// Retrieve products
// worker saga
export function* retrieveSampleStyle() {
  try {
    const response = yield call(retrieveSampleStylesData);

    if (response.status === 200) {
      yield put(downloadSampleStylesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSampleStylesErrorAction());
  }
}

// Create new product
// worker saga
export function* addSampleStyle(action) {
  const sampleStyle = action.sampleStyle;

  console.log(sampleStyle);

  try {
    const response = yield call(addSampleStyleDB, sampleStyle);

    if (response.status === 201) {
      yield delay(500);

      yield put(addSampleStyleOkAction(sampleStyle));
      toast.success("SampleStyle created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addSampleStyleErrorAction(true));
    toast.error("SampleStyle created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteSampleStyle(action) {
  const id = action.payload;
  try {
    yield call(deleteSampleStyleDB, id);
    yield deleteSampleStyleOkAction();
    toast.success("SampleStyle deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteSampleStyleErrorAction();
  }
}

// Edit product
// worker saga
export function* editSampleStyle(action) {
  const sampleStyle = action.sampleStyle;

  try {
    yield call(editSampleStyleDB, sampleStyle);
    yield editSampleStyleOkAction(sampleStyle);
    // Alert
    // Alert
    toast.success("SampleStyle updated Successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editSampleStyleErrorAction();
  }
}

// BULK SAGAS
export function* bulkSampleStyleDelete(action) {
  const sampleStyle = action.sampleStyle;

  try {
    const response = yield call(bulkDeleteSampleStyleDB, sampleStyle);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteSampleStyleOkAction(sampleStyle));
      toast.success("Bulk SampleStyle deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteSampleStyleErrorAction(true));
    toast.error("Bulk SampleStyle deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchSampleStyleAsync() {
  yield takeLatest(BEGIN_SAMPLE_STYLES_DOWNLOAD, retrieveSampleStyle);
  yield takeLatest(ADD_SAMPLE_STYLES, addSampleStyle);
  yield takeLatest(RETRIEVE_SAMPLE_STYLES_DELETE, deleteSampleStyle);
  yield takeLatest(BEGIN_EDIT_SAMPLE_STYLES, editSampleStyle);
  yield takeLatest(RETRIEVE_SAMPLE_STYLES_BULK_DELETE, bulkSampleStyleDelete);
}
