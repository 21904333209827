import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    ADD_PROCEED_REALIZATIONS,
    BEGIN_EDIT_PROCEED_REALIZATIONS,
    BEGIN_PROCEED_REALIZATIONS_DOWNLOAD,
    BEGIN_PROCEED_REALIZATION_DOWNLOAD, RETRIEVE_PROCEED_REALIZATIONS_DELETE
} from "../../constants/modules/proceedRealizationConstant";

import {
    addProceedRealizationErrorAction,
    addProceedRealizationOkAction, deleteProceedRealizationErrorAction,
    deleteProceedRealizationOkAction,
    downloadProceedRealizationErrorAction,
    downloadProceedRealizationOkAction,
    downloadProceedRealizationsErrorAction,
    downloadProceedRealizationsOkAction,
    editProceedRealizationErrorAction,
    editProceedRealizationOkAction
} from "../../actions/modules/proceedRealizationAction";

import {
    addProceedRealizationDB,
    deleteProceedRealizationDB,
    editProceedRealizationDB,
    retrieveProceedRealizationData,
    retrieveProceedRealizationsData
} from "../../../config/apiCalls/proceedRealizationAPICall";

// Retrieve products
// worker saga
export function* retrieveProceedRealizations() {
  try {
    const response = yield call(retrieveProceedRealizationsData);

    if (response.status === 200) {
      yield put(downloadProceedRealizationsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadProceedRealizationsErrorAction());
  }
}

export function* retrieveProceedRealization(action) {
  const proceedRealization = action.proceedRealization;
  try {
    const response = yield call(retrieveProceedRealizationData, proceedRealization);
    if (response.status === 200) {
      yield put(downloadProceedRealizationOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadProceedRealizationErrorAction());
  }
}

// Create new product
// worker saga
export function* addProceedRealization(action) {
  const proceedRealization = action.proceedRealization;
  try {
    const response = yield call(addProceedRealizationDB, proceedRealization);
    if (response.status === 200) {
      yield delay(500);
      yield put(addProceedRealizationOkAction(response.data));
      toast.success("Proceed Realization created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addProceedRealizationErrorAction(true));
    toast.error("Proceed Realization created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteProceedRealization(action) {
  const id = action.payload;
  try {
    yield call(deleteProceedRealizationDB, id);
    yield deleteProceedRealizationOkAction();
    toast.success("Proceed Realization deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteProceedRealizationErrorAction();
  }
}

// Edit product
// worker saga
export function* editProceedRealization(action) {
  const proceedRealization = action.proceedRealization;

  try {
    yield call(editProceedRealizationDB, proceedRealization);
    yield editProceedRealizationOkAction(proceedRealization);
    // Alert
    // Alert
    toast.success("Proceed Realization has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editProceedRealizationErrorAction();
  }
}


export function* watchProceedRealizationsAsync() {
  yield takeLatest(BEGIN_PROCEED_REALIZATIONS_DOWNLOAD, retrieveProceedRealizations);
  yield takeLatest(BEGIN_PROCEED_REALIZATION_DOWNLOAD, retrieveProceedRealization);
  yield takeLatest(ADD_PROCEED_REALIZATIONS, addProceedRealization);
  yield takeLatest(RETRIEVE_PROCEED_REALIZATIONS_DELETE, deleteProceedRealization);
  yield takeLatest(BEGIN_EDIT_PROCEED_REALIZATIONS, editProceedRealization);
  
}
