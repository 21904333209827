export const ADD_BUYER_DEPT = "ADD_BUYER_DEPT";
export const ADD_BUYER_DEPT_OK = "ADD_BUYER_DEPT_OK";
export const ADD_BUYER_DEPT_ERROR = "ADD_BUYER_DEPT_ERROR";

export const BEGIN_BUYER_DEPT_DOWNLOAD = "BEGIN_BUYER_DEPT_DOWNLOAD";
export const BUYER_DEPT_DOWNLOAD_OK = "BUYER_DEPT_DOWNLOAD_OK";
export const BUYER_DEPT_DOWNLOAD_ERROR = "BUYER_DEPT_DOWNLOAD_ERROR";

export const BEGIN_BUYER_DEPT_BY_BRAND_DOWNLOAD =
  "BEGIN_BUYER_DEPT_BY_BRAND_DOWNLOAD";
export const BUYER_DEPT_BY_BRAND_DOWNLOAD_OK =
  "BUYER_DEPT_BY_BRAND_DOWNLOAD_OK";
export const BUYER_DEPT_BY_BRAND_DOWNLOAD_ERROR =
  "BUYER_DEPT_BY_BRAND_DOWNLOAD_ERROR";

export const RETRIEVE_BUYER_DEPT_EDIT = "RETRIEVE_BUYER_DEPT_EDITED";
export const BEGIN_EDIT_BUYER_DEPT = "BEGIN_EDIT_BUYER_DEPT";
export const BUYER_DEPT_EDITED_OK = "BUYER_DEPT_EDITED_OK";
export const BUYER_DEPT_EDITED_ERROR = "BUYER_DEPT_EDITED_ERROR";

export const RETRIEVE_BUYER_DEPT_DELETE = "RETRIEVE_BUYER_DEPT_DELETE";
export const BUYER_DEPT_DELETED_OK = "BUYER_DEPT_DELETED_OK";
export const BUYER_DEPT_DELETED_ERROR = "BUYER_DEPT_DELETED_ERROR";

export const RETRIEVE_BUYER_DEPT_BULK_DELETE =
  "RETRIEVE_BUYER_DEPT_BULK_DELETE";
export const BUYER_DEPT_BULK_DELETED_OK = "BUYER_DEPT_BULK_DELETED_OK";
export const BUYER_DEPT_BULK_DELETED_ERROR = "BUYERS_BULK_DELETED_ERROR";
