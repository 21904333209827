import {
  ADD_TNA_TEMPLATE_ACTIONS,
  ADD_TNA_TEMPLATE_ACTIONS_ERROR,
  ADD_TNA_TEMPLATE_ACTIONS_OK,
  BEGIN_EDIT_TNA_TEMPLATE_ACTIONS,
  BEGIN_TNA_TEMPLATE_ACTIONS_DOWNLOAD,
  RETRIEVE_TNA_TEMPLATE_ACTIONS_BULK_DELETE,
  RETRIEVE_TNA_TEMPLATE_ACTIONS_DELETE,
  RETRIEVE_TNA_TEMPLATE_ACTIONS_EDIT,
  TNA_TEMPLATE_ACTIONS_BULK_DELETED_ERROR,
  TNA_TEMPLATE_ACTIONS_BULK_DELETED_OK,
  TNA_TEMPLATE_ACTIONS_DELETED_ERROR,
  TNA_TEMPLATE_ACTIONS_DELETED_OK,
  TNA_TEMPLATE_ACTIONS_DOWNLOAD_ERROR,
  TNA_TEMPLATE_ACTIONS_DOWNLOAD_OK,
  TNA_TEMPLATE_ACTIONS_EDITED_ERROR,
  TNA_TEMPLATE_ACTIONS_EDITED_OK,
} from "../../constants/modules/tnaTemplatesActionsConstants";

const initialState = {
  tnaTemplateActions: [],
  error: false,
  loading: false,
  deleteTnaTemplateAction: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_TNA_TEMPLATE_ACTIONS_DOWNLOAD:
    case RETRIEVE_TNA_TEMPLATE_ACTIONS_BULK_DELETE:
    case ADD_TNA_TEMPLATE_ACTIONS:
    case BEGIN_EDIT_TNA_TEMPLATE_ACTIONS:
      return {
        ...state,
        loading: action.payload,
        tnaTemplateAction: action.tnaTemplateAction,
      };

    case ADD_TNA_TEMPLATE_ACTIONS_OK:
    case TNA_TEMPLATE_ACTIONS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_TNA_TEMPLATE_ACTIONS_ERROR:
    case TNA_TEMPLATE_ACTIONS_DOWNLOAD_ERROR:
    case TNA_TEMPLATE_ACTIONS_BULK_DELETED_ERROR:
    case TNA_TEMPLATE_ACTIONS_DELETED_ERROR:
    case TNA_TEMPLATE_ACTIONS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TNA_TEMPLATE_ACTIONS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        tnaTemplateActions: action.payload,
      };

    case RETRIEVE_TNA_TEMPLATE_ACTIONS_DELETE:
      return {
        ...state,
        deleteTnaTemplateAction: action.payload,
      };

    case TNA_TEMPLATE_ACTIONS_DELETED_OK:
      return {
        ...state,
        tnaTemplateActions: state.tnaTemplateActions.data.filter(
          (tnaTemplateAction) =>
            tnaTemplateAction.id !== state.deleteTnaTemplateAction
        ),
        deleteTnaTemplateAction: null,
      };

    case RETRIEVE_TNA_TEMPLATE_ACTIONS_EDIT:
      return {
        ...state,
        editTnaTemplateAction: action.payload,
      };

    case TNA_TEMPLATE_ACTIONS_EDITED_OK:
      return {
        ...state,
        editTnaTemplateAction: null,
        tnaTemplateActions: state.tnaTemplateActions.data.map(
          (tnaTemplateAction) =>
            tnaTemplateAction.id === action.payload.id
              ? (tnaTemplateAction = action.payload)
              : tnaTemplateAction
        ),
      };

    default:
      return state;
  }
}
