import axiosClient from "../axiosClient";

export async function retrieveHeaderDB() {
  return await axiosClient.post("/ex_factory_headers/get_all");
}

export async function retrieveDetailDB(value) {
  var id = {
    id: value["0"]?.id,
  };

  console.log("&&&&&&&777777", id);
  return await axiosClient.post("/ex_factory_details/get", id);
}

export async function addHeaderDB(value) {
  return await axiosClient.post("/ex_factory_headers", value);
}

// export async function deleteOrderDB(id) {
//   return await axiosClient.delete(`/orders/${id}`);
// }

// export async function bulkDeleteOrderDB(ids) {
//   console.log("Valuuuu******, value", ids);

//   return await axiosClient.post(`/orders/delete`, ids);
// }

// export async function editOrderDB(value) {
//   return await axiosClient.put(`/orders/${value.id}`, value);
// }
