import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import {
  useLocation
} from "react-router-dom";
import MyDocument from "./MyDocument";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);

  React.useEffect(() => {
    document.title = "Supplier Ledger";
  }, []);

  const location = useLocation();

  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const getSupplierInfo = localStorage.getItem("supplier_ledger")
    ? JSON.parse(localStorage.getItem("supplier_ledger"))
    : null;


    useEffect(()=>{
        if(location){
          const getData = localStorage.getItem("company-info") ? JSON.parse(localStorage.getItem("company-info")) : null;  
           setCompanyData(getData);
        }  
      },[location]);



    useEffect(()=>{
        fetch("https://step-up-api.clonestudiobd.com/api/companies").then((res)=> res.json()).then((data)=> {
          const filterdData = data.data.filter((item)=> item.id === companyData?.id);
          setCompanyInfo(filterdData);
        })
        
      },[companyData]);





  React.useEffect(() => {
    if (getSupplierInfo) {
      fetch(`https://step-up-api.clonestudiobd.com/api/doc/supplier_ledgers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(getSupplierInfo),
      }).then((response) => {
        response.json().then((result) => {
          setInvoiceData(result);
        });
      });
    }
  }, []);

  console.log("invoiceData", invoiceData, "getSupplierInfo", getSupplierInfo);


  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument
        invoiceData={invoiceData ? invoiceData : []}
        companyInfo={companyInfo}
        getInfo={getInfo}
        getSupplierInfo={getSupplierInfo}
        
      />
    </PDFViewer>
  );
};

export default Invoice;
