import axiosClient from "../axiosClient";
/*-------------------------------------------------*/
/*               CURRENCIES API CALLS              */
/*-------------------------------------------------*/

export async function retrieveCurrenciesData() {
  return await axiosClient.get("/currencies");
}

export async function addCurrencyDB(value) {
  console.log("add currency data", value);

  return await axiosClient.post("/currencies", value);
}

export async function deleteCurrencyDB(id) {
  return await axiosClient.delete(`/currencies/${id}`);
}

export async function bulkDeleteCurrencyDB(ids) {
  return await axiosClient.post(`/currencies/bulk_delete`, ids);
}

export async function editCurrencyDB(value) {
  console.log("edit currency data", value);

  return await axiosClient.put(`/currencies/${value.id}`, value);
}
