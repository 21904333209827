import {
    ADD_APPROVAL_PANELS,
    ADD_APPROVAL_PANELS_ERROR,
    ADD_APPROVAL_PANELS_OK,
    APPROVAL_PANELS_BULK_DELETED_ERROR,
    APPROVAL_PANELS_BULK_DELETED_OK,
    APPROVAL_PANELS_DELETED_ERROR,
    APPROVAL_PANELS_DELETED_OK,
    APPROVAL_PANELS_DOWNLOAD_ERROR,
    APPROVAL_PANELS_DOWNLOAD_OK,
    APPROVAL_PANELS_EDITED_ERROR,
    APPROVAL_PANELS_EDITED_OK,
    BEGIN_APPROVAL_PANELS_DOWNLOAD,
    BEGIN_EDIT_APPROVAL_PANELS,
    RETRIEVE_APPROVAL_PANELS_BULK_DELETE,
    RETRIEVE_APPROVAL_PANELS_DELETE,
    RETRIEVE_APPROVAL_PANELS_EDIT
} from "../../constants/modules/approvalPanelsConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteApprovalPanelAction = (item) => ({
  type: RETRIEVE_APPROVAL_PANELS_BULK_DELETE,
  payload: true,
  approvalPanel: item,
});

export const bulkDeleteApprovalPanelOkAction = () => ({
  type: APPROVAL_PANELS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteApprovalPanelErrorAction = (state) => ({
  type: APPROVAL_PANELS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadApprovalPanelsAction = () => ({
  type: BEGIN_APPROVAL_PANELS_DOWNLOAD,
  payload: true,
});

export const downloadApprovalPanelsOkAction = (items) => ({
  type: APPROVAL_PANELS_DOWNLOAD_OK,
  payload: items,
});

export const downloadApprovalPanelsErrorAction = () => ({
  type: APPROVAL_PANELS_DOWNLOAD_ERROR,
  payload: true,
});

export const addApprovalPanelAction = (item) => ({
  type: ADD_APPROVAL_PANELS,
  payload: true,
  approvalPanel: item,
});

export const addApprovalPanelOkAction = () => ({
  type: ADD_APPROVAL_PANELS_OK,
  payload: true,
});

export const addApprovalPanelErrorAction = (state) => ({
  type: ADD_APPROVAL_PANELS_ERROR,
  payload: state,
});

export const deleteApprovalPanelAction = (id) => ({
  type: RETRIEVE_APPROVAL_PANELS_DELETE,
  payload: id,
});

export const deleteApprovalPanelOkAction = () => ({
  type: APPROVAL_PANELS_DELETED_OK,
});

export const deleteApprovalPanelErrorAction = () => ({
  type: APPROVAL_PANELS_DELETED_ERROR,
  payload: true,
});

export const retrieveApprovalPanelAction = (item) => ({
  type: RETRIEVE_APPROVAL_PANELS_EDIT,
  payload: item,
});

export const editApprovalPanelAction = (item) => ({
  type: BEGIN_EDIT_APPROVAL_PANELS,
  approvalPanel: item,
});

export const editApprovalPanelOkAction = (item) => ({
  type: APPROVAL_PANELS_EDITED_OK,
  payload: item,
});

export const editApprovalPanelErrorAction = () => ({
  type: APPROVAL_PANELS_EDITED_ERROR,
  payload: true,
});
