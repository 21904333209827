import axiosClient from "../axiosClient";

export async function retrieveBuyerBrandDB() {
  return await axiosClient.get("/buyer_brands");
}

export async function addBuyerBrandDB(value) {
  return await axiosClient.post("/buyer_brands", value);
}

export async function retrieveBuyerBrandsByBuyerDB(id) {
  let reId = {
    id: id,
  };
  return await axiosClient.post("/buyer_brands/get_by_id", reId);
}

export async function deleteBuyerBrandDB(id) {
  return await axiosClient.delete(`/buyer_brands/${id}`);
}

export async function bulkDeleteBuyerBrandDB(ids) {
  return await axiosClient.post(`/buyer_brands/bulk_delete`, ids);
}

export async function editBuyerBrandDB(value) {
  console.log("value0000", value);
  return await axiosClient.put(`/buyer_brands/${value.id}`, value);
}
