import {
    ADD_LC_HEADER_DETAILS,
    ADD_LC_HEADER_DETAILS_ERROR,
    ADD_LC_HEADER_DETAILS_OK,
    BEGIN_EDIT_LC_HEADER_DETAILS, BEGIN_LC_HEADER_DETAILS_DOWNLOAD, LC_HEADER_DETAILS_DELETED_ERROR,
    LC_HEADER_DETAILS_DELETED_OK,
    LC_HEADER_DETAILS_DOWNLOAD_ERROR,
    LC_HEADER_DETAILS_DOWNLOAD_OK,
    LC_HEADER_DETAILS_EDITED_ERROR,
    LC_HEADER_DETAILS_EDITED_OK, RETRIEVE_LC_HEADER_DETAILS_DELETE,
    RETRIEVE_LC_HEADER_DETAILS_EDIT
} from "../../constants/modules/lcHeaderDetailsConstants";
  
  const initialState = {
    lcHeaderDetails: [],
    error: false,
    loading: false,
    deleteLCHeaderDetail: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_LC_HEADER_DETAILS_DOWNLOAD:
      case ADD_LC_HEADER_DETAILS:
      case BEGIN_EDIT_LC_HEADER_DETAILS:
        return {
          ...state,
          loading: action.payload,
          lcHeaderDetail: action.lcHeaderDetail,
        };
  
      case ADD_LC_HEADER_DETAILS_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_LC_HEADER_DETAILS_ERROR:
      case LC_HEADER_DETAILS_DOWNLOAD_ERROR:
      case LC_HEADER_DETAILS_DELETED_ERROR:
      case LC_HEADER_DETAILS_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case LC_HEADER_DETAILS_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          lcHeaderDetails: action.payload,
        };
  
      case RETRIEVE_LC_HEADER_DETAILS_DELETE:
        return {
          ...state,
          deleteLCHeaderDetail: action.payload,
        };
  
      case LC_HEADER_DETAILS_DELETED_OK:
        return {
          ...state,
          lcHeaderDetails: state.lcHeaderDetails.data.filter(
            (lcHeaderDetail) =>
              lcHeaderDetail.id !== state.deleteLCHeaderDetail
          ),
          deleteLCHeaderDetail: null,
        };
  
      case RETRIEVE_LC_HEADER_DETAILS_EDIT:
        return {
          ...state,
          editLCHeaderDetail: action.payload,
        };
  
      case LC_HEADER_DETAILS_EDITED_OK:
        return {
          ...state,
          editLCHeaderDetail: null,
          lcHeaderDetails: state.lcHeaderDetails.data.map(
            (lcHeaderDetail) =>
              lcHeaderDetail.id === action.payload.id
                ? (lcHeaderDetail = action.payload)
                : lcHeaderDetail
          ),
        };
  
      default:
        return state;
    }
  }
  