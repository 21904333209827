import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*         Sales Contact ACTIONS API CALLS         */
/*-------------------------------------------------*/

export async function retrieveLCHeaderDetailsData() {
  return await axiosClient.get("/lc_details");
}

export async function addLCHeaderDetailDB(value) {
  return await axiosClient.post("/lc_details", value);
}

export async function deleteLCHeaderDetailDB(id) {
  return await axiosClient.delete(`/lc_details/${id}`);
}

export async function editLCHeaderDetailDB(value) {
  return await axiosClient.put(`/lc_details/${value.id}`, value);
}
