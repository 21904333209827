import {
    ADD_FREIGHT_TERMS,
    ADD_FREIGHT_TERMS_ERROR,
    ADD_FREIGHT_TERMS_OK,
    BEGIN_EDIT_FREIGHT_TERMS,
    BEGIN_FREIGHT_TERMS_DOWNLOAD,
    FREIGHT_TERMS_BULK_DELETED_ERROR,
    FREIGHT_TERMS_BULK_DELETED_OK,
    FREIGHT_TERMS_DELETED_ERROR,
    FREIGHT_TERMS_DELETED_OK,
    FREIGHT_TERMS_DOWNLOAD_ERROR,
    FREIGHT_TERMS_DOWNLOAD_OK,
    FREIGHT_TERMS_EDITED_ERROR,
    FREIGHT_TERMS_EDITED_OK,
    RETRIEVE_FREIGHT_TERMS_BULK_DELETE,
    RETRIEVE_FREIGHT_TERMS_DELETE,
    RETRIEVE_FREIGHT_TERMS_EDIT
} from "../../constants/modules/freightTermConstant";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteFreightTermAction = (item) => ({
  type: RETRIEVE_FREIGHT_TERMS_BULK_DELETE,
  payload: true,
  freightTerm: item,
});

export const bulkDeleteFreightTermOkAction = () => ({
  type: FREIGHT_TERMS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteFreightTermErrorAction = (state) => ({
  type: FREIGHT_TERMS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadFreightTermsAction = () => ({
  type: BEGIN_FREIGHT_TERMS_DOWNLOAD,
  payload: true,
});

export const downloadFreightTermsOkAction = (items) => ({
  type: FREIGHT_TERMS_DOWNLOAD_OK,
  payload: items,
});

export const downloadFreightTermsErrorAction = () => ({
  type: FREIGHT_TERMS_DOWNLOAD_ERROR,
  payload: true,
});

export const addFreightTermAction = (item) => ({
  type: ADD_FREIGHT_TERMS,
  payload: true,
  freightTerm: item,
});

export const addFreightTermOkAction = () => ({
  type: ADD_FREIGHT_TERMS_OK,
  payload: true,
});

export const addFreightTermErrorAction = (state) => ({
  type: ADD_FREIGHT_TERMS_ERROR,
  payload: state,
});

export const deleteFreightTermAction = (id) => ({
  type: RETRIEVE_FREIGHT_TERMS_DELETE,
  payload: id,
});

export const deleteFreightTermOkAction = () => ({
  type: FREIGHT_TERMS_DELETED_OK,
});

export const deleteFreightTermErrorAction = () => ({
  type: FREIGHT_TERMS_DELETED_ERROR,
  payload: true,
});

export const retrieveFreightTermAction = (item) => ({
  type: RETRIEVE_FREIGHT_TERMS_EDIT,
  payload: item,
});

export const editFreightTermAction = (item) => ({
  type: BEGIN_EDIT_FREIGHT_TERMS,
  freightTerm: item,
});

export const editFreightTermOkAction = (item) => ({
  type: FREIGHT_TERMS_EDITED_OK,
  payload: item,
});

export const editFreightTermErrorAction = () => ({
  type: FREIGHT_TERMS_EDITED_ERROR,
  payload: true,
});
