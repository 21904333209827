import {
  ADD_COURIERS,
  ADD_COURIERS_ERROR,
  ADD_COURIERS_OK,
  BEGIN_COURIERS_DOWNLOAD,
  BEGIN_EDIT_COURIERS,
  COURIERS_BULK_DELETED_ERROR,
  COURIERS_BULK_DELETED_OK,
  COURIERS_DELETED_ERROR,
  COURIERS_DELETED_OK,
  COURIERS_DOWNLOAD_ERROR,
  COURIERS_DOWNLOAD_OK,
  COURIERS_EDITED_ERROR,
  COURIERS_EDITED_OK,
  RETRIEVE_COURIERS_BULK_DELETE,
  RETRIEVE_COURIERS_DELETE,
  RETRIEVE_COURIERS_EDIT
} from "../../constants/modules/couriersConstants";

export const bulkDeleteCourierAction = (item) => ({
  type: RETRIEVE_COURIERS_BULK_DELETE,
  payload: true,
  courier: item,
});

export const bulkDeleteCourierOkAction = () => ({
  type: COURIERS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteCourierErrorAction = (state) => ({
  type: COURIERS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadCouriersAction = () => ({
  type: BEGIN_COURIERS_DOWNLOAD,
  payload: true,
});

export const downloadCouriersOkAction = (items) => ({
  type: COURIERS_DOWNLOAD_OK,
  payload: items,
});

export const downloadCouriersErrorAction = () => ({
  type: COURIERS_DOWNLOAD_ERROR,
  payload: true,
});

export const addCourierAction = (item) => ({
  type: ADD_COURIERS,
  payload: true,
  courier: item,
});

export const addCourierOkAction = () => ({
  type: ADD_COURIERS_OK,
  payload: true,
});

export const addCourierErrorAction = (state) => ({
  type: ADD_COURIERS_ERROR,
  payload: state,
});

export const deleteCourierAction = (id) => ({
  type: RETRIEVE_COURIERS_DELETE,
  payload: id,
});

export const deleteCourierOkAction = () => ({
  type: COURIERS_DELETED_OK,
});

export const deleteCourierErrorAction = () => ({
  type: COURIERS_DELETED_ERROR,
  payload: true,
});

export const retrieveCourierAction = (item) => ({
  type: RETRIEVE_COURIERS_EDIT,
  payload: item,
});

export const editCourierAction = (item) => ({
  type: BEGIN_EDIT_COURIERS,
  courier: item,
});

export const editCourierOkAction = (item) => ({
  type: COURIERS_EDITED_OK,
  payload: item,
});

export const editCourierErrorAction = () => ({
  type: COURIERS_EDITED_ERROR,
  payload: true,
});
