import {
  ADD_OVERSEAS_OFFICES, ADD_OVERSEAS_OFFICES_ERROR, ADD_OVERSEAS_OFFICES_OK, BEGIN_EDIT_OVERSEAS_OFFICES, BEGIN_OVERSEAS_OFFICES_DOWNLOAD, OVERSEAS_OFFICES_BULK_DELETED_ERROR, OVERSEAS_OFFICES_BULK_DELETED_OK, OVERSEAS_OFFICES_DELETED_ERROR, OVERSEAS_OFFICES_DELETED_OK, OVERSEAS_OFFICES_DOWNLOAD_ERROR, OVERSEAS_OFFICES_DOWNLOAD_OK, OVERSEAS_OFFICES_EDITED_ERROR, OVERSEAS_OFFICES_EDITED_OK, RETRIEVE_OVERSEAS_OFFICES_BULK_DELETE, RETRIEVE_OVERSEAS_OFFICES_DELETE, RETRIEVE_OVERSEAS_OFFICES_EDIT
} from "../../constants/modules/overseasConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteOverseasOfficeAction = (item) => ({
  type: RETRIEVE_OVERSEAS_OFFICES_BULK_DELETE,
  payload: true,
  overseas: item,
});

export const bulkDeleteOverseasOfficeOkAction = () => ({
  type: OVERSEAS_OFFICES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteOverseasOfficeErrorAction = (state) => ({
  type: OVERSEAS_OFFICES_BULK_DELETED_ERROR,
  payload: state,
});


  
  
  export const downloadOverseasOfficesAction = () => ({
    type: BEGIN_OVERSEAS_OFFICES_DOWNLOAD,
    payload: true,
  });
  
  export const downloadOverseasOfficesOkAction = (items) => ({
    type: OVERSEAS_OFFICES_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadOverseasOfficesErrorAction = () => ({
    type: OVERSEAS_OFFICES_DOWNLOAD_ERROR,
    payload: true,
  });
  
  
  export const addOverseasOfficeAction = (item) => ({
    type: ADD_OVERSEAS_OFFICES,
    payload: true,
    overseas: item,
  });
  
  export const addOverseasOfficeOkAction = () => ({
    type: ADD_OVERSEAS_OFFICES_OK,
    payload: true,
  });
  
  export const addOverseasOfficeErrorAction = (state) => ({
    type: ADD_OVERSEAS_OFFICES_ERROR,
    payload: state,
  });
  
  
  export const deleteOverseasOfficeAction = (id) => ({
    type: RETRIEVE_OVERSEAS_OFFICES_DELETE,
    payload: id,
  });
  
  export const deleteOverseasOfficeOkAction = () => ({
    type: OVERSEAS_OFFICES_DELETED_OK,
  });
  
  export const deleteOverseasOfficeErrorAction = () => ({
    type: OVERSEAS_OFFICES_DELETED_ERROR,
    payload: true,
  });
  
  
  export const retrieveOverseasOfficeAction = (item) => ({
    type: RETRIEVE_OVERSEAS_OFFICES_EDIT,
    payload: item,
  });
  
  export const editOverseasOfficeAction = (item) => ({
    type: BEGIN_EDIT_OVERSEAS_OFFICES,
    overseas: item,
  });
  
  export const editOverseasOfficeOkAction = (item) => ({
    type: OVERSEAS_OFFICES_EDITED_OK,
    payload: item,
  });
  
  export const editOverseasOfficeErrorAction = () => ({
    type: OVERSEAS_OFFICES_EDITED_ERROR,
    payload: true,
  });
  
  