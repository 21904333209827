import {
  BEGIN_SALES_CONTRACTS_AMEND_DOWNLOAD,
  SALES_CONTRACTS_AMEND_DOWNLOAD_OK,
  SALES_CONTRACTS_AMEND_DOWNLOAD_ERROR,
} from "../../constants/modules/SalesContractAmendConstants";

const initialState = {
  salesContractAmends: [],
  getSalesContract: null,
  salesContractAddInfo: null,
  error: false,
  loading: false,
  deleteSalesContract: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_SALES_CONTRACTS_AMEND_DOWNLOAD:
      return {
        ...state,
        loading: action.payload,
        salesContractAmend: action.salesContractAmend,
      };

    case SALES_CONTRACTS_AMEND_DOWNLOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SALES_CONTRACTS_AMEND_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        salesContracts: action.payload,
      };

    default:
      return state;
  }
}
