import {
  ADD_COLORS, ADD_COLORS_ERROR, ADD_COLORS_OK, BEGIN_COLORS_DOWNLOAD, BEGIN_EDIT_COLORS, COLORS_BULK_DELETED_ERROR, COLORS_BULK_DELETED_OK, COLORS_DELETED_ERROR, COLORS_DELETED_OK, COLORS_DOWNLOAD_ERROR, COLORS_DOWNLOAD_OK, COLORS_EDITED_ERROR, COLORS_EDITED_OK, RETRIEVE_COLORS_BULK_DELETE, RETRIEVE_COLORS_DELETE, RETRIEVE_COLORS_EDIT
} from "../../constants/modules/colorsConstants";
  
/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteColorAction = (item) => ({
  type: RETRIEVE_COLORS_BULK_DELETE,
  payload: true,
  color: item,
});

export const bulkDeleteColorOkAction = () => ({
  type: COLORS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteColorErrorAction = (state) => ({
  type: COLORS_BULK_DELETED_ERROR,
  payload: state,
});
  
  export const downloadColorsAction = () => ({
    type: BEGIN_COLORS_DOWNLOAD,
    payload: true,
  });
  
  export const downloadColorsOkAction = (items) => ({
    type: COLORS_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadColorsErrorAction = () => ({
    type: COLORS_DOWNLOAD_ERROR,
    payload: true,
  });
  
  
  export const addColorAction = (item) => ({
    type: ADD_COLORS,
    payload: true,
    color: item,
  });
  
  export const addColorOkAction = () => ({
    type: ADD_COLORS_OK,
    payload: true,
  });
  
  export const addColorErrorAction = (state) => ({
    type: ADD_COLORS_ERROR,
    payload: state,
  });
  
  
  export const deleteColorAction = (id) => ({
    type: RETRIEVE_COLORS_DELETE,
    payload: id,
  });
  
  export const deleteColorOkAction = () => ({
    type: COLORS_DELETED_OK,
  });
  
  export const deleteColorErrorAction = () => ({
    type: COLORS_DELETED_ERROR,
    payload: true,
  });
  
  
  export const retrieveColorAction = (item) => ({
    type: RETRIEVE_COLORS_EDIT,
    payload: item,
  });
  
  export const editColorAction = (item) => ({
    type: BEGIN_EDIT_COLORS,
    color: item,
  });
  
  export const editColorOkAction = (item) => ({
    type: COLORS_EDITED_OK,
    payload: item,
  });
  
  export const editColorErrorAction = () => ({
    type: COLORS_EDITED_ERROR,
    payload: true,
  });
  
  