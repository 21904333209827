import {
    ADD_PAYMENT_TERMS, ADD_PAYMENT_TERMS_ERROR, ADD_PAYMENT_TERMS_OK, BEGIN_EDIT_PAYMENT_TERMS, BEGIN_PAYMENT_TERMS_DOWNLOAD, PAYMENT_TERMS_BULK_DELETED_ERROR, PAYMENT_TERMS_BULK_DELETED_OK, PAYMENT_TERMS_DELETED_ERROR, PAYMENT_TERMS_DELETED_OK, PAYMENT_TERMS_DOWNLOAD_ERROR, PAYMENT_TERMS_DOWNLOAD_OK, PAYMENT_TERMS_EDITED_ERROR, PAYMENT_TERMS_EDITED_OK, RETRIEVE_PAYMENT_TERMS_BULK_DELETE, RETRIEVE_PAYMENT_TERMS_DELETE, RETRIEVE_PAYMENT_TERMS_EDIT
} from "../../constants/modules/paymentTermConstants";
    
  /*-----------------------------------*/
  /*             BULK ACTIONS          */
  /*-----------------------------------*/
  export const bulkDeletePaymentTermAction = (item) => ({
    type: RETRIEVE_PAYMENT_TERMS_BULK_DELETE,
    payload: true,
    paymentTerm: item,
  });
  
  export const bulkDeletePaymentTermOkAction = () => ({
    type: PAYMENT_TERMS_BULK_DELETED_OK,
    payload: true,
  });
  
  export const bulkDeletePaymentTermErrorAction = (state) => ({
    type: PAYMENT_TERMS_BULK_DELETED_ERROR,
    payload: state,
  });
    
    export const downloadPaymentTermsAction = () => ({
      type: BEGIN_PAYMENT_TERMS_DOWNLOAD,
      payload: true,
    });
    
    export const downloadPaymentTermsOkAction = (items) => ({
      type: PAYMENT_TERMS_DOWNLOAD_OK,
      payload: items,
    });
    
    export const downloadPaymentTermsErrorAction = () => ({
      type: PAYMENT_TERMS_DOWNLOAD_ERROR,
      payload: true,
    });
    
    
    export const addPaymentTermAction = (item) => ({
      type: ADD_PAYMENT_TERMS,
      payload: true,
      paymentTerm: item,
    });
    
    export const addPaymentTermOkAction = () => ({
      type: ADD_PAYMENT_TERMS_OK,
      payload: true,
    });
    
    export const addPaymentTermErrorAction = (state) => ({
      type: ADD_PAYMENT_TERMS_ERROR,
      payload: state,
    });
    
    
    export const deletePaymentTermAction = (id) => ({
      type: RETRIEVE_PAYMENT_TERMS_DELETE,
      payload: id,
    });
    
    export const deletePaymentTermOkAction = () => ({
      type: PAYMENT_TERMS_DELETED_OK,
    });
    
    export const deletePaymentTermErrorAction = () => ({
      type: PAYMENT_TERMS_DELETED_ERROR,
      payload: true,
    });
    
    
    export const retrievePaymentTermAction = (item) => ({
      type: RETRIEVE_PAYMENT_TERMS_EDIT,
      payload: item,
    });
    
    export const editPaymentTermAction = (item) => ({
      type: BEGIN_EDIT_PAYMENT_TERMS,
      paymentTerm: item,
    });
    
    export const editPaymentTermOkAction = (item) => ({
      type: PAYMENT_TERMS_EDITED_OK,
      payload: item,
    });
    
    export const editPaymentTermErrorAction = () => ({
      type: PAYMENT_TERMS_EDITED_ERROR,
      payload: true,
    });
    
    