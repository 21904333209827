import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_LC_HEADER_DETAILS,
  BEGIN_EDIT_LC_HEADER_DETAILS,
  BEGIN_LC_HEADER_DETAILS_DOWNLOAD,
  RETRIEVE_LC_HEADER_DETAILS_DELETE,
} from "../../constants/modules/lcHeaderDetailsConstants";

import {
  addLCHeaderDetailErrorAction,
  addLCHeaderDetailOkAction,
  deleteLCHeaderDetailErrorAction,
  deleteLCHeaderDetailOkAction,
  downloadLCHeaderDetailsErrorAction,
  downloadLCHeaderDetailsOkAction,
  editLCHeaderDetailErrorAction,
  editLCHeaderDetailOkAction,
} from "../../actions/modules/lcHeaderDetailsAction";

import {
  addLCHeaderDetailDB,
  deleteLCHeaderDetailDB,
  editLCHeaderDetailDB,
  retrieveLCHeaderDetailsData,
} from "../../../config/apiCalls/lcHeaderDetailsAPICall";

// Retrieve products
// worker saga
export function* retrieveLCHeaderDetails() {
  try {
    const response = yield call(retrieveLCHeaderDetailsData);

    if (response.status === 200) {
      yield put(downloadLCHeaderDetailsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadLCHeaderDetailsErrorAction());
  }
}

// Create new product
// worker saga
export function* addLCHeaderDetails(action) {
  const lcHeaderDetail = action.lcHeaderDetail;

  try {
    const response = yield call(addLCHeaderDetailDB, lcHeaderDetail);

    if (response.status === 200) {
      yield delay(500);

      yield put(addLCHeaderDetailOkAction(lcHeaderDetail));
      toast.success("LC Header Detail created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addLCHeaderDetailErrorAction(true));
    toast.error("LC Header Detail created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteLCHeaderDetail(action) {
  const id = action.payload;
  try {
    yield call(deleteLCHeaderDetailDB, id);
    yield deleteLCHeaderDetailOkAction();
    toast.success("LC Header Detail deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteLCHeaderDetailErrorAction();
  }
}

// Edit product
// worker saga
export function* editLCHeaderDetail(action) {
  const lcHeaderDetail = action.lcHeaderDetail;

  try {
    yield call(editLCHeaderDetailDB, lcHeaderDetail);
    yield editLCHeaderDetailOkAction(lcHeaderDetail);
    // Alert
    // Alert
    toast.success("LC Header Detail has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editLCHeaderDetailErrorAction();
  }
}

export function* watchLCHeaderDetailsAsync() {
  yield takeLatest(BEGIN_LC_HEADER_DETAILS_DOWNLOAD, retrieveLCHeaderDetails);
  yield takeLatest(ADD_LC_HEADER_DETAILS, addLCHeaderDetails);
  yield takeLatest(RETRIEVE_LC_HEADER_DETAILS_DELETE, deleteLCHeaderDetail);
  yield takeLatest(BEGIN_EDIT_LC_HEADER_DETAILS, editLCHeaderDetail);
}
