import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*             SalesContact API CALLS              */
/*-------------------------------------------------*/
export async function bulkDeleteDiscountDB(ids) {
  return await axiosClient.post(`/discounts/bulk_delete`, ids);
}

export async function retrieveDiscountsData() {
  return await axiosClient.post("/discounts/get_all");
}

export async function retrieveDiscountData(value) {
  return await axiosClient.post("/discounts/details", value);
}

export async function addDiscountDB(value) {
  return await axiosClient.post("/discounts", value);
}

export async function deleteDiscountDB(id) {
  return await axiosClient.delete(`/discounts/${id}`);
}

export async function editDiscountDB(value) {  
  return await axiosClient.put(`/discounts/${value.id}`, value);
}
