import {
  ADD_SHIPMENT_DETAILS,
  ADD_SHIPMENT_DETAILS_OK,
  ADD_SHIPMENT_DETAILS_ERROR,
  BEGIN_SHIPMENT_DETAILS_DOWNLOAD,
  SHIPMENT_DETAILS_DOWNLOAD_OK,
  SHIPMENT_DETAILS_DOWNLOAD_ERROR,
  RETRIEVE_SHIPMENT_DETAILS_DELETE,
  SHIPMENT_DETAILS_DELETED_OK,
  SHIPMENT_DETAILS_DELETED_ERROR,
  RETRIEVE_SHIPMENT_DETAILS_EDIT,
  BEGIN_EDIT_SHIPMENT_DETAILS,
  SHIPMENT_DETAILS_EDITED_OK,
  SHIPMENT_DETAILS_EDITED_ERROR,
  RETRIEVE_SHIPMENT_DETAILS_BULK_DELETE,
  SHIPMENT_DETAILS_BULK_DELETED_OK,
  SHIPMENT_DETAILS_BULK_DELETED_ERROR,
} from "../../constants/modules/shipmentDetailsConstants";

const initialState = {
  shipmentDetails: [],
  error: false,
  loading: false,
  deleteShipmentDetail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_SHIPMENT_DETAILS_DOWNLOAD:
    case ADD_SHIPMENT_DETAILS:
    case RETRIEVE_SHIPMENT_DETAILS_BULK_DELETE:
    case BEGIN_EDIT_SHIPMENT_DETAILS:
      return {
        ...state,
        loading: action.payload,
        shipmentDetail: action.shipmentDetail,
      };

    case ADD_SHIPMENT_DETAILS_OK:
    case SHIPMENT_DETAILS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_SHIPMENT_DETAILS_ERROR:
    case SHIPMENT_DETAILS_DOWNLOAD_ERROR:
    case SHIPMENT_DETAILS_DELETED_ERROR:
    case SHIPMENT_DETAILS_BULK_DELETED_ERROR:
    case SHIPMENT_DETAILS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SHIPMENT_DETAILS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        shipmentDetails: action.payload,
      };

    case RETRIEVE_SHIPMENT_DETAILS_DELETE:
      return {
        ...state,
        deleteShipmentDetail: action.payload,
      };

    case SHIPMENT_DETAILS_DELETED_OK:
      return {
        ...state,
        shipmentDetails: state.shipmentDetails.data.filter(
          (shipmentDetail) => shipmentDetail.id !== state.deleteShipmentDetail
        ),
        deleteOrderDetail: null,
      };

    case RETRIEVE_SHIPMENT_DETAILS_EDIT:
      return {
        ...state,
        editShipmentDetail: action.payload,
      };

    case SHIPMENT_DETAILS_EDITED_OK:
      return {
        ...state,
        editShipmentDetail: null,
        shipmentDetails: state.shipmentDetails.data.map((shipmentDetail) =>
          shipmentDetail.id === action.payload.id
            ? (shipmentDetail = action.payload)
            : shipmentDetail
        ),
      };

    default:
      return state;
  }
}
