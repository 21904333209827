import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_ACCOUNTS,
  BEGIN_ACCOUNTS_DOWNLOAD,
  BEGIN_EDIT_ACCOUNTS,
  RETRIEVE_ACCOUNTS_BULK_DELETE,
  RETRIEVE_ACCOUNTS_DELETE
} from "../../constants/modules/accountsConstants";

import {
  addAccountErrorAction,
  addAccountOkAction,
  bulkDeleteAccountErrorAction,
  bulkDeleteAccountOkAction,
  deleteAccountErrorAction,
  deleteAccountOkAction,
  downloadAccountsErrorAction,
  downloadAccountsOkAction,
  editAccountErrorAction,
  editAccountOkAction
} from "../../actions/modules/accountsActions";

import {
  addAccountDB,
  bulkDeleteAccountDB,
  deleteAccountDB,
  editAccountDB,
  retrieveAccountsData
} from "../../../config/apiCalls/accounts";

// Retrieve products
// worker saga
export function* retrieveAccounts() {
  try {
    const response = yield call(retrieveAccountsData);

    if (response.status === 200) {
      yield put(downloadAccountsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadAccountsErrorAction());
  }
}

// Create new product
// worker saga
export function* addAccount(action) {
  const account = action.account;

  try {
    const response = yield call(addAccountDB, account);

    if (response.status === 201) {
      yield delay(2000);

      yield put(addAccountOkAction(account));
      toast.success("Account created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addAccountErrorAction(true));
    toast.error("Account created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkAccountDelete(action) {
  const account = action.account;

  try {
    const response = yield call(bulkDeleteAccountDB, account);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteAccountOkAction(account));
      toast.success("Bulk Account deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteAccountErrorAction(true));
    toast.error("Bulk Account deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteAccount(action) {
  const id = action.payload;
  try {
    yield call(deleteAccountDB, id);
    yield deleteAccountOkAction();
    toast.success("Account deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteAccountErrorAction();
  }
}

// Edit product
// worker saga
export function* editAccount(action) {
  const account = action.account;

  try {
    yield call(editAccountDB, account);
    yield editAccountOkAction(account);
    // Alert
    // Alert
    toast.success("Account updated Successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editAccountErrorAction();
  }
}

export function* watchAccountsAsync() {
  yield takeLatest(BEGIN_ACCOUNTS_DOWNLOAD, retrieveAccounts);
  yield takeLatest(ADD_ACCOUNTS, addAccount);
  yield takeLatest(RETRIEVE_ACCOUNTS_DELETE, deleteAccount);
  yield takeLatest(BEGIN_EDIT_ACCOUNTS, editAccount);
  yield takeLatest(RETRIEVE_ACCOUNTS_BULK_DELETE, bulkAccountDelete);
}
