export const ADD_SUB_CATEGORIES = "ADD_SUB_CATEGORIES";
export const ADD_SUB_CATEGORIES_OK = "ADD_SUB_CATEGORIES_OK";
export const ADD_SUB_CATEGORIES_ERROR = "ADD_SUB_CATEGORIES_ERROR";

export const BEGIN_SUB_CATEGORIES_DOWNLOAD = "BEGIN_SUB_CATEGORIES_DOWNLOAD";
export const SUB_CATEGORIES_DOWNLOAD_OK = "SUB_CATEGORIES_DOWNLOAD_OK";
export const SUB_CATEGORIES_DOWNLOAD_ERROR = "SUB_CATEGORIES_DOWNLOAD_ERROR";

export const RETRIEVE_SUB_CATEGORIES_DELETE = "RETRIEVE_SUB_CATEGORIES_DELETE";
export const SUB_CATEGORIES_DELETED_OK = "SUB_CATEGORIES_DELETED_OK";
export const SUB_CATEGORIES_DELETED_ERROR = "SUB_CATEGORIES_DELETED_ERROR";

export const RETRIEVE_SUB_CATEGORIES_EDIT = "RETRIEVE_SUB_CATEGORIES_EDITED";
export const BEGIN_EDIT_SUB_CATEGORIES = "BEGIN_EDIT_SUB_CATEGORIES";
export const SUB_CATEGORIES_EDITED_OK = "SUB_CATEGORIES_EDITED_OK";
export const SUB_CATEGORIES_EDITED_ERROR = "SUB_CATEGORIES_EDITED_ERROR";

export const RETRIEVE_SUB_CATEGORIES_BULK_DELETE = "RETRIEVE_SUB_CATEGORIES_BULK_DELETE";
export const SUB_CATEGORIES_BULK_DELETED_OK = "SUB_CATEGORIES_BULK_DELETED_OK";
export const SUB_CATEGORIES_BULK_DELETED_ERROR = "SUB_CATEGORIES_BULK_DELETED_ERROR";