import {
  ADD_EX_FACTORY_HEADER,
  ADD_EX_FACTORY_HEADER_OK,
  ADD_EX_FACTORY_HEADER_ERROR,
  BEGIN_EX_FACTORY_HEADER_DOWNLOAD,
  EX_FACTORY_HEADER_DOWNLOAD_OK,
  EX_FACTORY_HEADER_DOWNLOAD_ERROR,
  RETRIEVE_EX_FACTORY_HEADER_DELETE,
  EX_FACTORY_HEADER_DELETED_OK,
  EX_FACTORY_HEADER_DELETED_ERROR,
  ////////
  ADD_EX_FACTORY_DETAIL,
  ADD_EX_FACTORY_DETAIL_OK,
  ADD_EX_FACTORY_DETAIL_ERROR,
  BEGIN_EX_FACTORY_DETAIL_DOWNLOAD,
  EX_FACTORY_DETAIL_DOWNLOAD_OK,
  EX_FACTORY_DETAIL_DOWNLOAD_ERROR,
  RETRIEVE_EX_FACTORY_DETAIL_DELETE,
  EX_FACTORY_DETAIL_DELETED_OK,
  EX_FACTORY_DETAIL_DELETED_ERROR,
  ////////
  ADD_EX_FACTORY_SHIPMENT_DETAIL,
  ADD_EX_FACTORY_SHIPMENT_DETAIL_OK,
  ADD_EX_FACTORY_SHIPMENT_DETAIL_ERROR,
  BEGIN_EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD,
  EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD_OK,
  EX_FACTORY_SHIPMENT_DETAIL_DOWNLOAD_ERROR,
} from "../../constants/modules/exFactoryInfoConstants";

export const downloadHeaderAction = () => ({
  type: BEGIN_EX_FACTORY_HEADER_DOWNLOAD,
  payload: true,
});

export const downloadHeaderOkAction = (items) => ({
  type: EX_FACTORY_HEADER_DOWNLOAD_OK,
  payload: items,
});

export const downloadHeaderErrorAction = () => ({
  type: EX_FACTORY_HEADER_DOWNLOAD_ERROR,
  payload: true,
});

export const addHeaderAction = (item) => ({
  type: ADD_EX_FACTORY_HEADER,
  payload: true,
  exFactoryInfo: item,
});

export const addHeaderOkAction = (item) => ({
  type: ADD_EX_FACTORY_HEADER_OK,
  payload: item,
});

export const addHeaderErrorAction = (state) => ({
  type: ADD_EX_FACTORY_HEADER_ERROR,
  payload: state,
});

export const downloadDetailAction = (item) => ({
  type: BEGIN_EX_FACTORY_DETAIL_DOWNLOAD,
  payload: true,
  exFactoryInfo: item,
});

export const downloadDetailOkAction = (items) => ({
  type: EX_FACTORY_DETAIL_DOWNLOAD_OK,
  payload: items,
});

export const downloadDetailErrorAction = () => ({
  type: EX_FACTORY_DETAIL_DOWNLOAD_ERROR,
  payload: true,
});
