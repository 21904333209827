import {
  BEGIN_COMMISSION_DETAILS_DOWNLOAD,
  COMMISSION_DETAILS_DOWNLOAD_OK,
  COMMISSION_DETAILS_DOWNLOAD_ERROR,
} from "../../constants/modules/commissionDetailsConstants";

export const downloadDetailAction = (item) => ({
  type: BEGIN_COMMISSION_DETAILS_DOWNLOAD,
  payload: true,
  commissionDetail: item,
});

export const downloadHeaderOkAction = (items) => ({
  type: COMMISSION_DETAILS_DOWNLOAD_OK,
  payload: items,
});

export const downloadHeaderErrorAction = () => ({
  type: COMMISSION_DETAILS_DOWNLOAD_ERROR,
  payload: true,
});
