export const ADD_LOADING_POINTS = "ADD_LOADING_POINTS";
export const ADD_LOADING_POINTS_OK = "ADD_LOADING_POINTS_OK";
export const ADD_LOADING_POINTS_ERROR = "ADD_LOADING_POINTS_ERROR";

export const BEGIN_LOADING_POINTS_DOWNLOAD = "BEGIN_LOADING_POINTS_DOWNLOAD";
export const LOADING_POINTS_DOWNLOAD_OK = "LOADING_POINTS_DOWNLOAD_OK";
export const LOADING_POINTS_DOWNLOAD_ERROR = "LOADING_POINTS_DOWNLOAD_ERROR";

export const RETRIEVE_LOADING_POINTS_DELETE = "RETRIEVE_LOADING_POINTS_DELETE";
export const LOADING_POINTS_DELETED_OK = "LOADING_POINTS_DELETED_OK";
export const LOADING_POINTS_DELETED_ERROR = "LOADING_POINTS_DELETED_ERROR";

export const RETRIEVE_LOADING_POINTS_EDIT = "RETRIEVE_LOADING_POINTS_EDITED";
export const BEGIN_EDIT_LOADING_POINTS = "BEGIN_EDIT_LOADING_POINTS";
export const LOADING_POINTS_EDITED_OK = "LOADING_POINTS_EDITED_OK";
export const LOADING_POINTS_EDITED_ERROR = "LOADING_POINTS_EDITED_ERROR";


export const RETRIEVE_LOADING_POINTS_BULK_DELETE = "RETRIEVE_LOADING_POINTS_BULK_DELETE";
export const LOADING_POINTS_BULK_DELETED_OK = "LOADING_POINTS_BULK_DELETED_OK";
export const LOADING_POINTS_BULK_DELETED_ERROR = "LOADING_POINTS_BULK_DELETED_ERROR";