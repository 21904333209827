import {
  ADD_CURRENCIES, ADD_CURRENCIES_ERROR, ADD_CURRENCIES_OK, BEGIN_CURRENCIES_DOWNLOAD, BEGIN_EDIT_CURRENCIES, CURRENCIES_BULK_DELETED_ERROR, CURRENCIES_BULK_DELETED_OK, CURRENCIES_DELETED_ERROR, CURRENCIES_DELETED_OK, CURRENCIES_DOWNLOAD_ERROR, CURRENCIES_DOWNLOAD_OK, CURRENCIES_EDITED_ERROR, CURRENCIES_EDITED_OK, RETRIEVE_CURRENCIES_BULK_DELETE, RETRIEVE_CURRENCIES_DELETE, RETRIEVE_CURRENCIES_EDIT
} from "../../constants/modules/currenciesConstants";
    
  /*-----------------------------------*/
  /*             BULK ACTIONS          */
  /*-----------------------------------*/
  export const bulkDeleteCurrencyAction = (item) => ({
    type: RETRIEVE_CURRENCIES_BULK_DELETE,
    payload: true,
    currency: item,
  });
  
  export const bulkDeleteCurrencyOkAction = () => ({
    type: CURRENCIES_BULK_DELETED_OK,
    payload: true,
  });
  
  export const bulkDeleteCurrencyErrorAction = (state) => ({
    type: CURRENCIES_BULK_DELETED_ERROR,
    payload: state,
  });
    
    export const downloadCurrenciesAction = () => ({
      type: BEGIN_CURRENCIES_DOWNLOAD,
      payload: true,
    });
    
    export const downloadCurrenciesOkAction = (items) => ({
      type: CURRENCIES_DOWNLOAD_OK,
      payload: items,
    });
    
    export const downloadCurrenciesErrorAction = () => ({
      type: CURRENCIES_DOWNLOAD_ERROR,
      payload: true,
    });
    
    
    export const addCurrencyAction = (item) => ({
      type: ADD_CURRENCIES,
      payload: true,
      currency: item,
    });
    
    export const addCurrencyOkAction = () => ({
      type: ADD_CURRENCIES_OK,
      payload: true,
    });
    
    export const addCurrencyErrorAction = (state) => ({
      type: ADD_CURRENCIES_ERROR,
      payload: state,
    });
    
    
    export const deleteCurrencyAction = (id) => ({
      type: RETRIEVE_CURRENCIES_DELETE,
      payload: id,
    });
    
    export const deleteCurrencyOkAction = () => ({
      type: CURRENCIES_DELETED_OK,
    });
    
    export const deleteCurrencyErrorAction = () => ({
      type: CURRENCIES_DELETED_ERROR,
      payload: true,
    });
    
    
    export const retrieveCurrencyAction = (item) => ({
      type: RETRIEVE_CURRENCIES_EDIT,
      payload: item,
    });
    
    export const editCurrencyAction = (item) => ({
      type: BEGIN_EDIT_CURRENCIES,
      currency: item,
    });
    
    export const editCurrencyOkAction = (item) => ({
      type: CURRENCIES_EDITED_OK,
      payload: item,
    });
    
    export const editCurrencyErrorAction = () => ({
      type: CURRENCIES_EDITED_ERROR,
      payload: true,
    });
    
    