import {
  ADD_USER_LEVEL_PRIVILEGES,
  ADD_USER_LEVEL_PRIVILEGES_ERROR,
  ADD_USER_LEVEL_PRIVILEGES_OK,
  BEGIN_EDIT_USER_LEVEL_PRIVILEGES,
  BEGIN_USER_LEVEL_PRIVILEGES_DOWNLOAD,
  RETRIEVE_USER_LEVEL_PRIVILEGES_BULK_DELETE,
  RETRIEVE_USER_LEVEL_PRIVILEGES_DELETE,
  RETRIEVE_USER_LEVEL_PRIVILEGES_EDIT,
  USER_LEVEL_PRIVILEGES_BULK_DELETED_ERROR,
  USER_LEVEL_PRIVILEGES_BULK_DELETED_OK,
  USER_LEVEL_PRIVILEGES_DELETED_ERROR,
  USER_LEVEL_PRIVILEGES_DELETED_OK,
  USER_LEVEL_PRIVILEGES_DOWNLOAD_ERROR,
  USER_LEVEL_PRIVILEGES_DOWNLOAD_OK,
  USER_LEVEL_PRIVILEGES_EDITED_ERROR,
  USER_LEVEL_PRIVILEGES_EDITED_OK
} from "../../constants/modules/userLevelPrivilegesConstant";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteUserLevelPrivilegeAction = (item) => ({
  type: RETRIEVE_USER_LEVEL_PRIVILEGES_BULK_DELETE,
  payload: true,
  userLevelPrivilege: item,
});

export const bulkDeleteUserLevelPrivilegeOkAction = () => ({
  type: USER_LEVEL_PRIVILEGES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteUserLevelPrivilegeErrorAction = (state) => ({
  type: USER_LEVEL_PRIVILEGES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadUserLevelPrivilegesAction = () => ({
  type: BEGIN_USER_LEVEL_PRIVILEGES_DOWNLOAD,
  payload: true,
});

export const downloadUserLevelPrivilegesOkAction = (items) => ({
  type: USER_LEVEL_PRIVILEGES_DOWNLOAD_OK,
  payload: items,
});

export const downloadUserLevelPrivilegesErrorAction = () => ({
  type: USER_LEVEL_PRIVILEGES_DOWNLOAD_ERROR,
  payload: true,
});

export const addUserLevelPrivilegeAction = (item) => ({
  type: ADD_USER_LEVEL_PRIVILEGES,
  payload: true,
  userLevelPrivilege: item,
});

export const addUserLevelPrivilegeOkAction = () => ({
  type: ADD_USER_LEVEL_PRIVILEGES_OK,
  payload: true,
});

export const addUserLevelPrivilegeErrorAction = (state) => ({
  type: ADD_USER_LEVEL_PRIVILEGES_ERROR,
  payload: state,
});

export const deleteUserLevelPrivilegeAction = (id) => ({
  type: RETRIEVE_USER_LEVEL_PRIVILEGES_DELETE,
  payload: id,
});

export const deleteUserLevelPrivilegeOkAction = () => ({
  type: USER_LEVEL_PRIVILEGES_DELETED_OK,
});

export const deleteUserLevelPrivilegeErrorAction = () => ({
  type: USER_LEVEL_PRIVILEGES_DELETED_ERROR,
  payload: true,
});

export const retrieveUserLevelPrivilegeAction = (item) => ({
  type: RETRIEVE_USER_LEVEL_PRIVILEGES_EDIT,
  payload: item,
});

export const editUserLevelPrivilegeAction = (item) => ({
  type: BEGIN_EDIT_USER_LEVEL_PRIVILEGES,
  userLevelPrivilege: item,
});

export const editUserLevelPrivilegeOkAction = (item) => ({
  type: USER_LEVEL_PRIVILEGES_EDITED_OK,
  payload: item,
});

export const editUserLevelPrivilegeErrorAction = () => ({
  type: USER_LEVEL_PRIVILEGES_EDITED_ERROR,
  payload: true,
});
