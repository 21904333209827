import {
    ADD_TT_ADVANCES,
    ADD_TT_ADVANCES_ERROR,
    ADD_TT_ADVANCES_OK,
    BEGIN_EDIT_TT_ADVANCES,
    BEGIN_TT_ADVANCES_DOWNLOAD,
    BEGIN_TT_ADVANCE_DOWNLOAD, RESET, RETRIEVE_TT_ADVANCES_DELETE,
    RETRIEVE_TT_ADVANCES_EDIT, TT_ADVANCES_DELETED_ERROR,
    TT_ADVANCES_DELETED_OK,
    TT_ADVANCES_DOWNLOAD_ERROR,
    TT_ADVANCES_DOWNLOAD_OK,
    TT_ADVANCES_EDITED_ERROR,
    TT_ADVANCES_EDITED_OK,
    TT_ADVANCE_DOWNLOAD_ERROR,
    TT_ADVANCE_DOWNLOAD_OK
} from "../../constants/modules/TTAdvanceConstant";

const initialState = {
  ttAdvances: [],
  getTTAdvance: null,
  ttAdvanceAddInfo: null,
  error: false,
  loading: false,
  deleteTTAdvance: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_TT_ADVANCES_DOWNLOAD:
    case BEGIN_TT_ADVANCE_DOWNLOAD:
    case ADD_TT_ADVANCES:
    case BEGIN_EDIT_TT_ADVANCES:
      return {
        ...state,
        loading: action.payload,
        ttAdvance: action.ttAdvance,
      };


    case ADD_TT_ADVANCES_ERROR:
    case TT_ADVANCES_DOWNLOAD_ERROR:
    case TT_ADVANCE_DOWNLOAD_ERROR:
    case TT_ADVANCES_DELETED_ERROR:
    case TT_ADVANCES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TT_ADVANCES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        ttAdvances: action.payload,
      };

    case TT_ADVANCE_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        getTTAdvance: action.payload,
      };

    case ADD_TT_ADVANCES_OK:
      return {
        ...state,
        loading: false,
        error: null,
        ttAdvanceAddInfo: action.payload,
      };

    case RETRIEVE_TT_ADVANCES_DELETE:
      return {
        ...state,
        deleteTTAdvance: action.payload,
      };

    case TT_ADVANCES_DELETED_OK:
      return {
        ...state,
        ttAdvances: state.ttAdvances.data.filter(
          (ttAdvance) => ttAdvance.id !== state.deleteTTAdvance
        ),
        deleteTTAdvance: null,
      };

    case RETRIEVE_TT_ADVANCES_EDIT:
      return {
        ...state,
        editTTAdvance: action.payload,
      };

    case TT_ADVANCES_EDITED_OK:
      return {
        ...state,
        editTTAdvances: null,
        ttAdvances: state.ttAdvances.data.map((ttAdvance) =>
          ttAdvance.id === action.payload.id
            ? (ttAdvance = action.payload)
            : ttAdvance
        ),
      };

    case RESET:
      return {
        ...state,
        getTTAdvance: null,
        ttAdvanceAddInfo: null,
      };

    default:
      return state;
  }
}
