import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_ORDER_DETAILS,
  BEGIN_ORDER_DETAILS_DOWNLOAD,
  RETRIEVE_ORDER_DETAILS_DELETE,
  BEGIN_EDIT_ORDER_DETAILS,
  RETRIEVE_ORDER_DETAILS_BULK_DELETE,
} from "../../constants/modules/orderDetailsConstants";

import {
  downloadOrderDetailsOkAction,
  downloadOrderDetailsErrorAction,
  addOrderDetailOkAction,
  addOrderDetailErrorAction,
  deleteOrderDetailOkAction,
  deleteOrderDetailErrorAction,
  editOrderDetailOkAction,
  editOrderDetailErrorAction,
  bulkDeleteOrderDetailOkAction,
  bulkDeleteOrderDetailErrorAction,
} from "../../actions/modules/orderDetailsActions";

import {
  retrieveOrderDetailDB,
  addOrderDetailDB,
  deleteOrderDetailDB,
  editOrderDetailDB,
  bulkDeleteOrderDetailDB,
} from "../../../config/apiCalls/orderDetails";

// Retrieve products
// worker saga
export function* retrieveOrderDetails(action) {
  const orderDetail = action.orderDetail;
  try {
    const response = yield call(retrieveOrderDetailDB, orderDetail);
    if (response.status === 200) {
      yield put(downloadOrderDetailsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadOrderDetailsErrorAction());
  }
}

// Create new product
// worker saga
export function* addOrderDetail(action) {
  const orderDetail = action.orderDetail;

  console.log("OrderDetail-11", orderDetail);
  try {
    const response = yield call(addOrderDetailDB, orderDetail);

    console.log("response-11", response);

    if (response.status === 200) {
      yield delay(500);
      yield put(addOrderDetailOkAction(orderDetail));
      toast.success("Order Updated Successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addOrderDetailErrorAction(true));
    toast.error("Order Updated Failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteOrderDetail(action) {
  const id = action.payload;
  try {
    yield call(deleteOrderDetailDB, id);
    yield deleteOrderDetailOkAction();
    toast.success("Order Detail deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteOrderDetailErrorAction());
    toast.error("Order Detail deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkOrderDetailDelete(action) {
  const orderDetail = action.orderDetail;

  try {
    const response = yield call(bulkDeleteOrderDetailDB, orderDetail);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteOrderDetailOkAction(orderDetail));
      toast.success("Order Details deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteOrderDetailErrorAction(true));
    toast.error("Order Details deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editOrderDetail(action) {
  const orderDetail = action.orderDetail;

  try {
    const response = yield call(editOrderDetailDB, orderDetail);

    if (response.status === 201) {
      yield delay(500);
      yield put(editOrderDetailOkAction(orderDetail));
      toast.success("Order Detail edited successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editOrderDetailErrorAction());
    toast.error("Order Detail deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchOrderDetailsAsync() {
  yield takeLatest(BEGIN_ORDER_DETAILS_DOWNLOAD, retrieveOrderDetails);
  yield takeLatest(ADD_ORDER_DETAILS, addOrderDetail);
  yield takeLatest(RETRIEVE_ORDER_DETAILS_DELETE, deleteOrderDetail);
  yield takeLatest(BEGIN_EDIT_ORDER_DETAILS, editOrderDetail);
  yield takeLatest(RETRIEVE_ORDER_DETAILS_BULK_DELETE, bulkOrderDetailDelete);
}
