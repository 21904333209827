import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_MEASURING_UNITS,
  BEGIN_EDIT_MEASURING_UNITS,
  BEGIN_MEASURING_UNITS_DOWNLOAD,
  RETRIEVE_MEASURING_UNITS_BULK_DELETE,
  RETRIEVE_MEASURING_UNITS_DELETE
} from "../../constants/modules/measuringUnitsConstant";

import {
  addMeasuringUnitErrorAction,
  addMeasuringUnitOkAction,
  bulkDeleteMeasuringUnitErrorAction,
  bulkDeleteMeasuringUnitOkAction,
  deleteMeasuringUnitErrorAction,
  deleteMeasuringUnitOkAction,
  downloadMeasuringUnitsErrorAction,
  downloadMeasuringUnitsOkAction,
  editMeasuringUnitErrorAction,
  editMeasuringUnitOkAction
} from "../../actions/modules/measuringUnitsAction";

import {
  addMeasuringUnitDB,
  bulkDeleteMeasuringUnitDB,
  deleteMeasuringUnitDB,
  editMeasuringUnitDB,
  retrieveMeasuringUnitsData
} from "../../../config/apiCalls/measuringUnitsAPICall";

// Retrieve products
// worker saga
export function* retrieveMeasuringUnits() {
  try {
    const response = yield call(retrieveMeasuringUnitsData);

    if (response.status === 200) {
      yield put(downloadMeasuringUnitsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadMeasuringUnitsErrorAction());
  }
}

// Create new product
// worker saga
export function* addMeasuringUnit(action) {
  const measuringUnit = action.measuringUnit;

  try {
    const response = yield call(addMeasuringUnitDB, measuringUnit);

    if (response.status === 201) {
      yield delay(500);

      yield put(addMeasuringUnitOkAction(measuringUnit));
      toast.success("Measuring Unit created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addMeasuringUnitErrorAction(true));
    toast.error("Measuring Unit created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteMeasuringUnit(action) {
  const id = action.payload;
  try {
    yield call(deleteMeasuringUnitDB, id);
    yield deleteMeasuringUnitOkAction();
    toast.success("Measuring Unit deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteMeasuringUnitErrorAction();
  }
}

// Edit product
// worker saga
export function* editMeasuringUnit(action) {
  const measuringUnit = action.measuringUnit;

  try {
    yield call(editMeasuringUnitDB, measuringUnit);
    yield editMeasuringUnitOkAction(measuringUnit);
    // Alert
    // Alert
    toast.success("Measuring Unit has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editMeasuringUnitErrorAction();
  }
}

// BULK SAGAS
export function* bulkMeasuringUnitDelete(action) {
  const measuringUnit = action.measuringUnit;

  try {
    const response = yield call(bulkDeleteMeasuringUnitDB, measuringUnit);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteMeasuringUnitOkAction(measuringUnit));
      toast.success("Bulk Measuring Unit deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteMeasuringUnitErrorAction(true));
    toast.error("Bulk Measuring Unit deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchMeasuringUnitsAsync() {
  yield takeLatest(BEGIN_MEASURING_UNITS_DOWNLOAD, retrieveMeasuringUnits);
  yield takeLatest(ADD_MEASURING_UNITS, addMeasuringUnit);
  yield takeLatest(RETRIEVE_MEASURING_UNITS_DELETE, deleteMeasuringUnit);
  yield takeLatest(BEGIN_EDIT_MEASURING_UNITS, editMeasuringUnit);
  yield takeLatest(RETRIEVE_MEASURING_UNITS_BULK_DELETE, bulkMeasuringUnitDelete);
}
