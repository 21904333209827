import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_OK,
  LOGIN_REQUEST_ERROR,
} from "../../constants/auth/authConstants";

const initialState = {
  loginInfo: null,
  error: false,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: action.payload,
        token: action.token,
      };

    case LOGIN_REQUEST_OK:
      return {
        ...state,
        loading: false,
        error: null,
        loginInfo: action.payload,
      };

    case LOGIN_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
