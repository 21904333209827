export const ADD_USER_LEVELS = "ADD_USER_LEVELS";
export const ADD_USER_LEVELS_OK = "ADD_USER_LEVELS_OK";
export const ADD_USER_LEVELS_ERROR = "ADD_USER_LEVELS_ERROR";

export const BEGIN_USER_LEVELS_DOWNLOAD = "BEGIN_USER_LEVELS_DOWNLOAD";
export const USER_LEVELS_DOWNLOAD_OK = "USER_LEVELS_DOWNLOAD_OK";
export const USER_LEVELS_DOWNLOAD_ERROR = "USER_LEVELS_DOWNLOAD_ERROR";

export const RETRIEVE_USER_LEVELS_DELETE = "RETRIEVE_USER_LEVELS_DELETE";
export const USER_LEVELS_DELETED_OK = "USER_LEVELS_DELETED_OK";
export const USER_LEVELS_DELETED_ERROR = "USER_LEVELS_DELETED_ERROR";

export const RETRIEVE_USER_LEVELS_EDIT = "RETRIEVE_USER_LEVELS_EDITED";
export const BEGIN_EDIT_USER_LEVELS = "BEGIN_EDIT_USER_LEVELS";
export const USER_LEVELS_EDITED_OK = "USER_LEVELS_EDITED_OK";
export const USER_LEVELS_EDITED_ERROR = "USER_LEVELS_EDITED_ERROR";

export const RETRIEVE_USER_LEVELS_BULK_DELETE = "RETRIEVE_USER_LEVELS_BULK_DELETE";
export const USER_LEVELS_BULK_DELETED_OK = "USER_LEVELS_BULK_DELETED_OK";
export const USER_LEVELS_BULK_DELETED_ERROR = "USER_LEVELS_BULK_DELETED_ERROR";