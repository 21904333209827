import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    ADD_RDL_INVOICES,
    BEGIN_EDIT_RDL_INVOICES,
    BEGIN_RDL_INVOICES_DOWNLOAD,
    BEGIN_RDL_INVOICE_DOWNLOAD,
    RETRIEVE_RDL_INVOICES_DELETE
} from "../../constants/modules/RDLInvoiceConstant";

import {
    addRDLInvoiceErrorAction,
    addRDLInvoiceOkAction,
    deleteRDLInvoiceErrorAction,
    deleteRDLInvoiceOkAction,
    downloadRDLInvoiceErrorAction,
    downloadRDLInvoiceOkAction,
    downloadRDLInvoicesErrorAction,
    downloadRDLInvoicesOkAction,
    editRDLInvoiceErrorAction,
    editRDLInvoiceOkAction
} from "../../actions/modules/RDLInvoiceAction";

import {
    addRDLInvoiceDB,
    deleteRDLInvoiceDB,
    editRDLInvoiceDB,
    retrieveRDLInvoiceData,
    retrieveRDLInvoicesData
} from "../../../config/apiCalls/RDLInvoiceAPICall";

// Retrieve products
// worker saga
export function* retrieveRDLInvoices() {
  try {
    const response = yield call(retrieveRDLInvoicesData);

    if (response.status === 200) {
      yield put(downloadRDLInvoicesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadRDLInvoicesErrorAction());
  }
}

export function* retrieveRDLInvoice(action) {
  const rdlInvoice = action.rdlInvoice;
  try {
    const response = yield call(retrieveRDLInvoiceData, rdlInvoice);
    if (response.status === 200) {
      yield put(downloadRDLInvoiceOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadRDLInvoiceErrorAction());
  }
}

// Create new product
// worker saga
export function* addRDLInvoice(action) {
  const rdlInvoice = action.rdlInvoice;
  try {
    const response = yield call(addRDLInvoiceDB, rdlInvoice);
    if (response.status === 200) {
      yield delay(500);
      yield put(addRDLInvoiceOkAction(response.data));
      toast.success("RDL Invoice created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addRDLInvoiceErrorAction(true));
    toast.error("RDL Invoice created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteRDLInvoice(action) {
  const id = action.payload;
  try {
    yield call(deleteRDLInvoiceDB, id);
    yield deleteRDLInvoiceOkAction();
    toast.success("RDL Invoice deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteRDLInvoiceErrorAction();
  }
}

// Edit product
// worker saga
export function* editRDLInvoice(action) {
  const rdlInvoice = action.rdlInvoice;

  try {
    yield call(editRDLInvoiceDB, rdlInvoice);
    yield editRDLInvoiceOkAction(rdlInvoice);
    // Alert
    // Alert
    toast.success("RDL Invoice has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editRDLInvoiceErrorAction();
  }
}

export function* watchRDLInvoicesAsync() {
  yield takeLatest(BEGIN_RDL_INVOICES_DOWNLOAD, retrieveRDLInvoices);
  yield takeLatest(BEGIN_RDL_INVOICE_DOWNLOAD, retrieveRDLInvoice);
  yield takeLatest(ADD_RDL_INVOICES, addRDLInvoice);
  yield takeLatest(RETRIEVE_RDL_INVOICES_DELETE, deleteRDLInvoice);
  yield takeLatest(BEGIN_EDIT_RDL_INVOICES, editRDLInvoice);
}
