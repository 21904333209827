import {
    ADD_FOB_TYPES, ADD_FOB_TYPES_ERROR, ADD_FOB_TYPES_OK, BEGIN_EDIT_FOB_TYPES, BEGIN_FOB_TYPES_DOWNLOAD, FOB_TYPES_BULK_DELETED_ERROR, FOB_TYPES_BULK_DELETED_OK, FOB_TYPES_DELETED_ERROR, FOB_TYPES_DELETED_OK, FOB_TYPES_DOWNLOAD_ERROR, FOB_TYPES_DOWNLOAD_OK, FOB_TYPES_EDITED_ERROR, FOB_TYPES_EDITED_OK, RETRIEVE_FOB_TYPES_BULK_DELETE, RETRIEVE_FOB_TYPES_DELETE, RETRIEVE_FOB_TYPES_EDIT
} from "../../constants/modules/fobTypesConstants";
    
    const initialState = {
      fobTypes: [],
      error: false,
      loading: false,
      deleteFobType: null,
    };
    
    export default function (state = initialState, action) {
      switch (action.type) {
        case BEGIN_FOB_TYPES_DOWNLOAD:
        case RETRIEVE_FOB_TYPES_BULK_DELETE:
        case ADD_FOB_TYPES:
        case BEGIN_EDIT_FOB_TYPES:
          return {
            ...state,
            loading: action.payload,
            fobType: action.fobType,
          };
    
        case ADD_FOB_TYPES_OK:
        case FOB_TYPES_BULK_DELETED_OK:
          return {
            ...state,
            loading: false,
          };
    
        case ADD_FOB_TYPES_ERROR:
        case FOB_TYPES_DOWNLOAD_ERROR:
        case FOB_TYPES_BULK_DELETED_ERROR:
        case FOB_TYPES_DELETED_ERROR:
        case FOB_TYPES_EDITED_ERROR:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case FOB_TYPES_DOWNLOAD_OK:
          return {
            ...state,
            loading: false,
            error: null,
            fobTypes: action.payload,
          };
    
        case RETRIEVE_FOB_TYPES_DELETE:
          return {
            ...state,
            deleteFobType: action.payload,
          };
    
        case FOB_TYPES_DELETED_OK:
          return {
            ...state,
            fobTypes: state.fobTypes.data.filter((fobType) => fobType.id !== state.deleteFobType),
            deleteFobType: null,
          };
    
        case RETRIEVE_FOB_TYPES_EDIT:
          return {
            ...state,
            editFobType: action.payload,
          };
    
        case FOB_TYPES_EDITED_OK:
          return {
            ...state,
            editFobType: null,
            fobTypes: state.fobTypes.data.map((fobType) =>
              fobType.id === action.payload.id ? (fobType = action.payload) : fobType
            ),
          };
    
        default:
          return state;
      }
    }
    