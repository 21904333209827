import {
    ADD_RDL_INVOICES,
    ADD_RDL_INVOICES_ERROR,
    ADD_RDL_INVOICES_OK,
    BEGIN_EDIT_RDL_INVOICES,
    BEGIN_RDL_INVOICES_DOWNLOAD,
    BEGIN_RDL_INVOICE_DOWNLOAD, RDL_INVOICES_BULK_DELETED_ERROR,
    RDL_INVOICES_BULK_DELETED_OK,
    RDL_INVOICES_DELETED_ERROR,
    RDL_INVOICES_DELETED_OK,
    RDL_INVOICES_DOWNLOAD_ERROR,
    RDL_INVOICES_DOWNLOAD_OK,
    RDL_INVOICES_EDITED_ERROR,
    RDL_INVOICES_EDITED_OK,
    RDL_INVOICE_DOWNLOAD_ERROR,
    RDL_INVOICE_DOWNLOAD_OK, RESET,
    RETRIEVE_RDL_INVOICES_BULK_DELETE,
    RETRIEVE_RDL_INVOICES_DELETE,
    RETRIEVE_RDL_INVOICES_EDIT
} from "../../constants/modules/RDLInvoiceConstant";
  
  export const bulkDeleteRDLInvoiceAction = (item) => ({
    type: RETRIEVE_RDL_INVOICES_BULK_DELETE,
    payload: true,
    rdlInvoice: item,
  });
  
  export const bulkDeleteRDLInvoiceOkAction = () => ({
    type: RDL_INVOICES_BULK_DELETED_OK,
    payload: true,
  });
  
  export const bulkDeleteRDLInvoiceErrorAction = (state) => ({
    type: RDL_INVOICES_BULK_DELETED_ERROR,
    payload: state,
  });
  
  export const downloadRDLInvoicesAction = () => ({
    type: BEGIN_RDL_INVOICES_DOWNLOAD,
    payload: true,
  });
  
  export const downloadRDLInvoicesOkAction = (items) => ({
    type: RDL_INVOICES_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadRDLInvoicesErrorAction = () => ({
    type: RDL_INVOICES_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const downloadRDLInvoiceAction = (item) => ({
    type: BEGIN_RDL_INVOICE_DOWNLOAD,
    payload: true,
    rdlInvoice: item,
  });
  
  export const downloadRDLInvoiceOkAction = (items) => ({
    type: RDL_INVOICE_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadRDLInvoiceErrorAction = () => ({
    type: RDL_INVOICE_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const addRDLInvoiceAction = (item) => ({
    type: ADD_RDL_INVOICES,
    payload: true,
    rdlInvoice: item,
  });
  
  export const addRDLInvoiceOkAction = (items) => ({
    type: ADD_RDL_INVOICES_OK,
    payload: items,
  });
  
  export const addRDLInvoiceErrorAction = (state) => ({
    type: ADD_RDL_INVOICES_ERROR,
    payload: state,
  });
  
  export const deleteRDLInvoiceAction = (id) => ({
    type: RETRIEVE_RDL_INVOICES_DELETE,
    payload: id,
  });
  
  export const deleteRDLInvoiceOkAction = () => ({
    type: RDL_INVOICES_DELETED_OK,
  });
  
  export const deleteRDLInvoiceErrorAction = () => ({
    type: RDL_INVOICES_DELETED_ERROR,
    payload: true,
  });
  
  export const retrieveRDLInvoiceAction = (item) => ({
    type: RETRIEVE_RDL_INVOICES_EDIT,
    payload: item,
  });
  
  export const editRDLInvoiceAction = (item) => ({
    type: BEGIN_EDIT_RDL_INVOICES,
    rdlInvoice: item,
  });
  
  export const editRDLInvoiceOkAction = (item) => ({
    type: RDL_INVOICES_EDITED_OK,
    payload: item,
  });
  
  export const editRDLInvoiceErrorAction = () => ({
    type: RDL_INVOICES_EDITED_ERROR,
    payload: true,
  });
  
  export const RDLInvoiceReset = () => ({
    type: RESET,
    payload: true,
  });
  