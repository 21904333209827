import {
  BEGIN_MENUS_DOWNLOAD,
  MENUS_DOWNLOAD_OK,
  MENUS_DOWNLOAD_ERROR,
  BEGIN_GLOBAL_MENUS_DOWNLOAD,
  GLOBAL_MENUS_DOWNLOAD_OK,
  GLOBAL_MENUS_DOWNLOAD_ERROR,
} from "../constants/menuConstants";

export const downloadModulesAction = (item) => ({
  type: BEGIN_MENUS_DOWNLOAD,
  payload: true,
  menus: item,
});

export const downloadModulesOkAction = (modules) => ({
  type: MENUS_DOWNLOAD_OK,
  payload: modules,
});

export const downloadModulesErrorAction = (state) => ({
  type: MENUS_DOWNLOAD_ERROR,
  payload: state,
});
