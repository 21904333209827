import {
    ADD_COMMISSION_INVOICES,
    ADD_COMMISSION_INVOICES_ERROR,
    ADD_COMMISSION_INVOICES_OK,
    BEGIN_COMMISSION_INVOICES_DOWNLOAD,
    BEGIN_EDIT_COMMISSION_INVOICES,
    COMMISSION_INVOICES_BULK_DELETED_ERROR,
    COMMISSION_INVOICES_BULK_DELETED_OK,
    COMMISSION_INVOICES_DELETED_ERROR,
    COMMISSION_INVOICES_DELETED_OK,
    COMMISSION_INVOICES_DOWNLOAD_ERROR,
    COMMISSION_INVOICES_DOWNLOAD_OK,
    COMMISSION_INVOICES_EDITED_ERROR,
    COMMISSION_INVOICES_EDITED_OK,
    RETRIEVE_COMMISSION_INVOICES_BULK_DELETE,
    RETRIEVE_COMMISSION_INVOICES_DELETE,
    RETRIEVE_COMMISSION_INVOICES_EDIT
} from "../../constants/modules/commissionInvoiceConstant";

const initialState = {
  commissionInvoices: [],
  error: false,
  loading: false,
  deleteCommissionInvoice: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_COMMISSION_INVOICES_DOWNLOAD:
    case RETRIEVE_COMMISSION_INVOICES_BULK_DELETE:
    case ADD_COMMISSION_INVOICES:
    case BEGIN_EDIT_COMMISSION_INVOICES:
      return {
        ...state,
        loading: action.payload,
        commissionInvoice: action.commissionInvoice,
      };

    case ADD_COMMISSION_INVOICES_OK:
    case COMMISSION_INVOICES_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_COMMISSION_INVOICES_ERROR:
    case COMMISSION_INVOICES_DOWNLOAD_ERROR:
    case COMMISSION_INVOICES_BULK_DELETED_ERROR:
    case COMMISSION_INVOICES_DELETED_ERROR:
    case COMMISSION_INVOICES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COMMISSION_INVOICES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        commissionInvoices: action.payload,
      };

    case RETRIEVE_COMMISSION_INVOICES_DELETE:
      return {
        ...state,
        deleteCommissionInvoice: action.payload,
      };

    case COMMISSION_INVOICES_DELETED_OK:
      return {
        ...state,
        commissionInvoices: state.commissionInvoices.data.filter(
          (commissionInvoice) =>
            commissionInvoice.id !== state.deleteCommissionInvoice
        ),
        deleteCommissionInvoice: null,
      };

    case RETRIEVE_COMMISSION_INVOICES_EDIT:
      return {
        ...state,
        editCommissionInvoice: action.payload,
      };

    case COMMISSION_INVOICES_EDITED_OK:
      return {
        ...state,
        editCommissionInvoice: null,
        commissionInvoices: state.commissionInvoices.data.map(
          (commissionInvoice) =>
            commissionInvoice.id === action.payload.id
              ? (commissionInvoice = action.payload)
              : commissionInvoice
        ),
      };

    default:
      return state;
  }
}
