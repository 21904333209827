import {
  ADD_FABRIC_TYPES,
  ADD_FABRIC_TYPES_ERROR,
  ADD_FABRIC_TYPES_OK,
  BEGIN_EDIT_FABRIC_TYPES,
  BEGIN_FABRIC_TYPES_DOWNLOAD,
  FABRIC_TYPES_BULK_DELETED_ERROR,
  FABRIC_TYPES_BULK_DELETED_OK,
  FABRIC_TYPES_DELETED_ERROR,
  FABRIC_TYPES_DELETED_OK,
  FABRIC_TYPES_DOWNLOAD_ERROR,
  FABRIC_TYPES_DOWNLOAD_OK,
  FABRIC_TYPES_EDITED_ERROR,
  FABRIC_TYPES_EDITED_OK,
  RETRIEVE_FABRIC_TYPES_BULK_DELETE,
  RETRIEVE_FABRIC_TYPES_DELETE,
  RETRIEVE_FABRIC_TYPES_EDIT,
  BEGIN_FABRIC_TYPES_BY_FK_DOWNLOAD,
  FABRIC_TYPES_BY_FK_DOWNLOAD_OK,
  FABRIC_TYPES_BY_FK_DOWNLOAD_ERROR,
} from "../../constants/modules/fabricsTypeConstants";

export const bulkDeleteFabricTypeAction = (item) => ({
  type: RETRIEVE_FABRIC_TYPES_BULK_DELETE,
  payload: true,
  fabricType: item,
});

export const bulkDeleteFabricTypeOkAction = () => ({
  type: FABRIC_TYPES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteFabricTypeErrorAction = (state) => ({
  type: FABRIC_TYPES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadFabricTypesAction = () => ({
  type: BEGIN_FABRIC_TYPES_DOWNLOAD,
  payload: true,
});

export const downloadFabricTypesOkAction = (items) => ({
  type: FABRIC_TYPES_DOWNLOAD_OK,
  payload: items,
});

export const downloadFabricTypesErrorAction = () => ({
  type: FABRIC_TYPES_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadFabricTypesByFKAction = (item) => ({
  type: BEGIN_FABRIC_TYPES_BY_FK_DOWNLOAD,
  payload: true,
  fabricType: item,
});

export const downloadFabricTypesByFKOkAction = (items) => ({
  type: FABRIC_TYPES_BY_FK_DOWNLOAD_OK,
  payload: items,
});

export const downloadFabricTypesByFKErrorAction = () => ({
  type: FABRIC_TYPES_BY_FK_DOWNLOAD_ERROR,
  payload: true,
});

export const addFabricTypeAction = (item) => ({
  type: ADD_FABRIC_TYPES,
  payload: true,
  fabricType: item,
});

export const addFabricTypeOkAction = () => ({
  type: ADD_FABRIC_TYPES_OK,
  payload: true,
});

export const addFabricTypeErrorAction = (state) => ({
  type: ADD_FABRIC_TYPES_ERROR,
  payload: state,
});

export const deleteFabricTypeAction = (id) => ({
  type: RETRIEVE_FABRIC_TYPES_DELETE,
  payload: id,
});

export const deleteFabricTypeOkAction = () => ({
  type: FABRIC_TYPES_DELETED_OK,
});

export const deleteFabricTypeErrorAction = () => ({
  type: FABRIC_TYPES_DELETED_ERROR,
  payload: true,
});

export const retrieveFabricTypeAction = (item) => ({
  type: RETRIEVE_FABRIC_TYPES_EDIT,
  payload: item,
});

export const editFabricTypeAction = (item) => ({
  type: BEGIN_EDIT_FABRIC_TYPES,
  fabricType: item,
});

export const editFabricTypeOkAction = (item) => ({
  type: FABRIC_TYPES_EDITED_OK,
  payload: item,
});

export const editFabricTypeErrorAction = () => ({
  type: FABRIC_TYPES_EDITED_ERROR,
  payload: true,
});
