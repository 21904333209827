import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_ACCOUNT_TYPES,
  BEGIN_ACCOUNT_TYPES_DOWNLOAD,
  BEGIN_EDIT_ACCOUNT_TYPES,
  RETRIEVE_ACCOUNT_TYPES_BULK_DELETE,
  RETRIEVE_ACCOUNT_TYPES_DELETE
} from "../../constants/modules/accountTypesConstants";

import {
  addAccountTypeErrorAction,
  addAccountTypeOkAction,
  bulkDeleteAccountTypeErrorAction,
  bulkDeleteAccountTypeOkAction,
  deleteAccountTypeErrorAction,
  deleteAccountTypeOkAction,
  downloadAccountTypesErrorAction,
  downloadAccountTypesOkAction,
  editAccountTypeErrorAction,
  editAccountTypeOkAction
} from "../../actions/modules/accountTypesActions";

import {
  addAccountTypeDB,
  bulkDeleteAccountTypeDB,
  deleteAccountTypeDB,
  editAccountTypeDB,
  retrieveAccountTypesData
} from "../../../config/apiCalls/accountTypes";

// Retrieve products
// worker saga
export function* retrieveAccountTypes() {
  try {
    const response = yield call(retrieveAccountTypesData);

    if (response.status === 200) {
      yield put(downloadAccountTypesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadAccountTypesErrorAction());
  }
}

// Create new product
// worker saga
export function* addAccountType(action) {
  const accountType = action.accountType;

  try {
    const response = yield call(addAccountTypeDB, accountType);

    if (response.status === 201) {
      yield delay(2000);

      yield put(addAccountTypeOkAction(accountType));
      toast.success("Account Type created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addAccountTypeErrorAction(true));
    toast.error("Account Type created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkAccountTypeDelete(action) {
  const accountType = action.accountType;

  try {
    const response = yield call(bulkDeleteAccountTypeDB, accountType);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteAccountTypeOkAction(accountType));
      toast.success("Bulk Account Type deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteAccountTypeErrorAction(true));
    toast.error("Bulk Account Type deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteAccountType(action) {
  const id = action.payload;
  try {
    yield call(deleteAccountTypeDB, id);
    yield deleteAccountTypeOkAction();
    toast.success("Account Type deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteAccountTypeErrorAction();
  }
}

// Edit product
// worker saga
export function* editAccountType(action) {
  const accountType = action.accountType;

  try {
    yield call(editAccountTypeDB, accountType);
    yield editAccountTypeOkAction(accountType);
    // Alert
    // Alert
    toast.success("Account Type has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editAccountTypeErrorAction();
  }
}

export function* watchAccountTypesAsync() {
  yield takeLatest(BEGIN_ACCOUNT_TYPES_DOWNLOAD, retrieveAccountTypes);
  yield takeLatest(ADD_ACCOUNT_TYPES, addAccountType);
  yield takeLatest(RETRIEVE_ACCOUNT_TYPES_DELETE, deleteAccountType);
  yield takeLatest(BEGIN_EDIT_ACCOUNT_TYPES, editAccountType);
  yield takeLatest(RETRIEVE_ACCOUNT_TYPES_BULK_DELETE, bulkAccountTypeDelete);
}
