import axiosClient from "../axiosClient";

export async function retrieveExFactoryDetailDB(value) {
  var id = {
    id: value["0"]?.id,
  };

  return await axiosClient.post("/ex_factory_details/get", id);
}

export async function addExFactoryDetailDB(value) {
  return await axiosClient.post("/ex_factory_details", value);
}

export async function deleteExFactoryDetailDB(id) {
  return await axiosClient.delete(`/ex_factory_details/${id}`);
}

export async function bulkDeleteExFactoryDetailDB(ids) {
  return await axiosClient.post(`/ex_factory_details/bulk_delete`, ids);
}

export async function editExFactoryDetailDB(value) {
  return await axiosClient.put(`/ex_factory_details/${value.id}`, value);
}
