import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_EX_FACTORY_HEADER,
  BEGIN_EX_FACTORY_HEADER_DOWNLOAD,
  BEGIN_EX_FACTORY_DETAIL_DOWNLOAD,
  // RETRIEVE_BANKS_DELETE,
  // BEGIN_EDIT_BANKS,
  // RETRIEVE_BANKS_BULK_DELETE,
} from "../../constants/modules/exFactoryInfoConstants";

import {
  downloadHeaderOkAction,
  downloadHeaderErrorAction,
  addHeaderOkAction,
  addHeaderErrorAction,
  downloadDetailOkAction,
  downloadDetailErrorAction,
  //   deleteBankOkAction,
  //   deleteBankErrorAction,
  //   editBankOkAction,
  //   editBankErrorAction,
  //   bulkDeleteBankOkAction,
  //   bulkDeleteBankErrorAction,
} from "../../actions/modules/exFactoryInfoActions";

import {
  retrieveHeaderDB,
  retrieveDetailDB,
  addHeaderDB,
} from "../../../config/apiCalls/exFactoryInfo";

// Retrieve products
// worker saga
export function* retrieveHeaders() {
  try {
    const response = yield call(retrieveHeaderDB);
    if (response.status === 200) {
      yield put(downloadHeaderOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadHeaderErrorAction());
  }
}

export function* retrieveDetails(action) {
  const exFactoryInfo = action.exFactoryInfo;
  try {
    const response = yield call(retrieveDetailDB, exFactoryInfo);
    console.log("response0000000******", response);
    if (response.status === 200) {
      yield put(downloadDetailOkAction(response?.data));
    }
  } catch (error) {
    yield put(downloadDetailErrorAction());
  }
}

// Create new product
// worker saga
export function* addHeader(action) {
  const exFactoryInfo = action.exFactoryInfo;

  try {
    const response = yield call(addHeaderDB, exFactoryInfo);

    if (response.status === 201) {
      yield delay(500);
      yield put(addHeaderOkAction(response.data));
      toast.success("Header created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addHeaderErrorAction(true));
    toast.error("Header created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
// export function* deleteBank(action) {
//   const id = action.payload;
//   try {
//     yield call(deleteBankDB, id);
//     yield deleteBankOkAction();
//     toast.success("Bank deleted successfully!", {
//       position: "top-right",
//       autoClose: 2000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//   } catch (error) {
//     yield put(deleteBankErrorAction());
//     toast.error("Bank deleted failed!", {
//       position: "top-right",
//       autoClose: 2000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//   }
// }

// export function* bulkBankDelete(action) {
//   const bank = action.bank;

//   try {
//     const response = yield call(bulkDeleteBankDB, bank);
//     if (response.status === 200) {
//       yield delay(500);
//       yield put(bulkDeleteBankOkAction(bank));
//       toast.success("Banks deleted successfully!", {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//     }
//   } catch (error) {
//     yield put(bulkDeleteBankErrorAction(true));
//     toast.error("Banks deleted failed!", {
//       position: "top-right",
//       autoClose: 2000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//   }
// }

// Edit product
// worker saga
// export function* editBank(action) {
//   const bank = action.bank;

//   try {
//     const response = yield call(editBankDB, bank);

//     if (response.status === 201) {
//       yield delay(500);
//       yield put(editBankOkAction(bank));
//       toast.success("Bank edited successfully!", {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//     }
//   } catch (error) {
//     yield put(editBankErrorAction());
//     toast.error("Bank deleted failed!", {
//       position: "top-right",
//       autoClose: 2000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//   }
// }

export function* watchExFactoryInfosAsync() {
  yield takeLatest(BEGIN_EX_FACTORY_HEADER_DOWNLOAD, retrieveHeaders);
  yield takeLatest(BEGIN_EX_FACTORY_DETAIL_DOWNLOAD, retrieveDetails);
  yield takeLatest(ADD_EX_FACTORY_HEADER, addHeader);
}
