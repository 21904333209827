export const ADD_FREIGHT_TERMS = "ADD_FREIGHT_TERMS";
export const ADD_FREIGHT_TERMS_OK = "ADD_FREIGHT_TERMS_OK";
export const ADD_FREIGHT_TERMS_ERROR = "ADD_FREIGHT_TERMS_ERROR";

export const BEGIN_FREIGHT_TERMS_DOWNLOAD = "BEGIN_FREIGHT_TERMS_DOWNLOAD";
export const FREIGHT_TERMS_DOWNLOAD_OK = "FREIGHT_TERMS_DOWNLOAD_OK";
export const FREIGHT_TERMS_DOWNLOAD_ERROR = "FREIGHT_TERMS_DOWNLOAD_ERROR";

export const RETRIEVE_FREIGHT_TERMS_DELETE = "RETRIEVE_FREIGHT_TERMS_DELETE";
export const FREIGHT_TERMS_DELETED_OK = "FREIGHT_TERMS_DELETED_OK";
export const FREIGHT_TERMS_DELETED_ERROR = "FREIGHT_TERMS_DELETED_ERROR";

export const RETRIEVE_FREIGHT_TERMS_EDIT = "RETRIEVE_FREIGHT_TERMS_EDITED";
export const BEGIN_EDIT_FREIGHT_TERMS = "BEGIN_EDIT_FREIGHT_TERMS";
export const FREIGHT_TERMS_EDITED_OK = "FREIGHT_TERMS_EDITED_OK";
export const FREIGHT_TERMS_EDITED_ERROR = "FREIGHT_TERMS_EDITED_ERROR";


export const RETRIEVE_FREIGHT_TERMS_BULK_DELETE = "RETRIEVE_FREIGHT_TERMS_BULK_DELETE";
export const FREIGHT_TERMS_BULK_DELETED_OK = "FREIGHT_TERMS_BULK_DELETED_OK";
export const FREIGHT_TERMS_BULK_DELETED_ERROR = "FREIGHT_TERMS_BULK_DELETED_ERROR";