import axiosClient from "../axiosClient";

export async function bulkDeleteUserLevelPrivilegeDB(ids) {
  return await axiosClient.post(`/user_level_privileges/bulk_delete`, ids);
}

export async function retrieveUserLevelPrivilegesData() {
  return await axiosClient.get("/user_level_privileges");
}

export async function addUserLevelPrivilegeDB(value) {
  return await axiosClient.post("/user_level_privileges", value);
}

export async function deleteUserLevelPrivilegeDB(id) {
  return await axiosClient.delete(`/user_level_privileges/${id}`);
}

export async function editUserLevelPrivilegeDB(value) {
  return await axiosClient.post("/user_level_privileges", value);
}
