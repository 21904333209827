import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_SEASONS,
  BEGIN_EDIT_SEASONS,
  BEGIN_SEASONS_DOWNLOAD,
  RETRIEVE_SEASONS_BULK_DELETE,
  RETRIEVE_SEASONS_DELETE
} from "../../constants/modules/seasonsConstants";

import {
  addSeasonErrorAction,
  addSeasonOkAction,
  bulkDeleteSeasonErrorAction,
  bulkDeleteSeasonOkAction,
  deleteSeasonErrorAction,
  deleteSeasonOkAction,
  downloadSeasonsErrorAction,
  downloadSeasonsOkAction,
  editSeasonErrorAction,
  editSeasonOkAction
} from "../../actions/modules/seasonsActions";

import {
  addSeasonDB,
  bulkDeleteSeasonDB,
  deleteSeasonDB,
  editSeasonDB,
  retrieveSeasonsData
} from "../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveSeasons() {
  try {
    const response = yield call(retrieveSeasonsData);

    if (response.status === 200) {
      yield put(downloadSeasonsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSeasonsErrorAction());
  }
}

// Create new product
// worker saga
export function* addSeason(action) {
  const season = action.season;

  try {
    const response = yield call(addSeasonDB, season);

    if (response.status === 201) {
      yield delay(500);

      yield put(addSeasonOkAction(season));
      toast.success("Season created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addSeasonErrorAction(true));
    toast.error("Season created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteSeason(action) {
  const id = action.payload;
  try {
    yield call(deleteSeasonDB, id);
    yield deleteSeasonOkAction();
    toast.success("Season deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteSeasonErrorAction();
  }
}

// Edit product
// worker saga
export function* editSeason(action) {
  const season = action.season;

  try {
    yield call(editSeasonDB, season);
    yield editSeasonOkAction(season);
    // Alert
    toast.success("Season has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editSeasonErrorAction();
  }
}

// BULK SAGAS
export function* bulkSeasonDelete(action) {
  const season = action.season;

  try {
    const response = yield call(bulkDeleteSeasonDB, season);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteSeasonOkAction(season));
      toast.success("Bulk Season deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteSeasonErrorAction(true));
    toast.error("Bulk Season deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchSeasonsAsync() {
  yield takeLatest(BEGIN_SEASONS_DOWNLOAD, retrieveSeasons);
  yield takeLatest(ADD_SEASONS, addSeason);
  yield takeLatest(RETRIEVE_SEASONS_DELETE, deleteSeason);
  yield takeLatest(BEGIN_EDIT_SEASONS, editSeason);
  yield takeLatest(RETRIEVE_SEASONS_BULK_DELETE, bulkSeasonDelete);
}
