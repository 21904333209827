import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    ADD_COMMISSION_INVOICES,
    BEGIN_COMMISSION_INVOICES_DOWNLOAD,
    BEGIN_EDIT_COMMISSION_INVOICES,
    RETRIEVE_COMMISSION_INVOICES_BULK_DELETE,
    RETRIEVE_COMMISSION_INVOICES_DELETE
} from "../../constants/modules/commissionInvoiceConstant";

import {
    addCommissionInvoiceErrorAction,
    addCommissionInvoiceOkAction,
    bulkDeleteCommissionInvoiceErrorAction,
    bulkDeleteCommissionInvoiceOkAction,
    deleteCommissionInvoiceErrorAction,
    deleteCommissionInvoiceOkAction,
    downloadCommissionInvoicesErrorAction,
    downloadCommissionInvoicesOkAction,
    editCommissionInvoiceErrorAction,
    editCommissionInvoiceOkAction
} from "../../actions/modules/commissionInvoiceAction";

import {
    addCommissionInvoiceDB,
    bulkDeleteCommissionInvoiceDB,
    deleteCommissionInvoiceDB,
    editCommissionInvoiceDB,
    retrieveCommissionInvoicesData
} from "../../../config/apiCalls/commissionInvoiceAPICall";

// Retrieve products
// worker saga
export function* retrieveCommissionInvoices() {
  try {
    const response = yield call(retrieveCommissionInvoicesData);

    if (response.status === 200) {
      yield put(downloadCommissionInvoicesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadCommissionInvoicesErrorAction());
  }
}

// Create new product
// worker saga
export function* addCommissionInvoice(action) {
  const commissionInvoice = action.commissionInvoice;

  try {
    const response = yield call(addCommissionInvoiceDB, commissionInvoice);

    if (response.status === 201) {
      yield delay(500);

      yield put(addCommissionInvoiceOkAction(commissionInvoice));
      toast.success("Commission Invoice created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addCommissionInvoiceErrorAction(true));
    toast.error("Commission Invoice created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteCommissionInvoice(action) {
  const id = action.payload;
  try {
    yield call(deleteCommissionInvoiceDB, id);
    yield deleteCommissionInvoiceOkAction();
    toast.success("Commission Invoice deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteCommissionInvoiceErrorAction();
  }
}

// Edit product
// worker saga
export function* editCommissionInvoice(action) {
  const commissionInvoice = action.commissionInvoice;

  try {
    yield call(editCommissionInvoiceDB, commissionInvoice);
    yield editCommissionInvoiceOkAction(commissionInvoice);
    // Alert
    // Alert
    toast.success("Commission Invoice has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editCommissionInvoiceErrorAction();
  }
}

// BULK SAGAS
export function* bulkCommissionInvoiceDelete(action) {
  const commissionInvoice = action.commissionInvoice;

  try {
    const response = yield call(
      bulkDeleteCommissionInvoiceDB,
      commissionInvoice
    );
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteCommissionInvoiceOkAction(commissionInvoice));
      toast.success("Bulk Commission Invoice deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteCommissionInvoiceErrorAction(true));
    toast.error("Bulk Commission Invoice deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchCommissionInvoicesAsync() {
  yield takeLatest(
    BEGIN_COMMISSION_INVOICES_DOWNLOAD,
    retrieveCommissionInvoices
  );
  yield takeLatest(ADD_COMMISSION_INVOICES, addCommissionInvoice);
  yield takeLatest(
    RETRIEVE_COMMISSION_INVOICES_DELETE,
    deleteCommissionInvoice
  );
  yield takeLatest(BEGIN_EDIT_COMMISSION_INVOICES, editCommissionInvoice);
  yield takeLatest(
    RETRIEVE_COMMISSION_INVOICES_BULK_DELETE,
    bulkCommissionInvoiceDelete
  );
}
