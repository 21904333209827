import {
    ADD_DISCOUNTS,
    ADD_DISCOUNTS_ERROR,
    ADD_DISCOUNTS_OK,
    BEGIN_DISCOUNTS_DOWNLOAD,
    BEGIN_DISCOUNT_DOWNLOAD,
    BEGIN_EDIT_DISCOUNTS,
    DISCOUNTS_BULK_DELETED_ERROR,
    DISCOUNTS_BULK_DELETED_OK,
    DISCOUNTS_DELETED_ERROR,
    DISCOUNTS_DELETED_OK,
    DISCOUNTS_DOWNLOAD_ERROR,
    DISCOUNTS_DOWNLOAD_OK,
    DISCOUNTS_EDITED_ERROR,
    DISCOUNTS_EDITED_OK,
    DISCOUNT_DOWNLOAD_ERROR,
    DISCOUNT_DOWNLOAD_OK,
    RESET,
    RETRIEVE_DISCOUNTS_BULK_DELETE,
    RETRIEVE_DISCOUNTS_DELETE,
    RETRIEVE_DISCOUNTS_EDIT
} from "../../constants/modules/discountsContants";

const initialState = {
  discounts: [],
  getDiscount: null,
  discountAddInfo: null,
  error: false,
  loading: false,
  deleteDiscount: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_DISCOUNTS_DOWNLOAD:
    case BEGIN_DISCOUNT_DOWNLOAD:
    case RETRIEVE_DISCOUNTS_BULK_DELETE:
    case ADD_DISCOUNTS:
    case BEGIN_EDIT_DISCOUNTS:
      return {
        ...state,
        loading: action.payload,
        discount: action.discount,
      };

    case DISCOUNTS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_DISCOUNTS_ERROR:
    case DISCOUNTS_DOWNLOAD_ERROR:
    case DISCOUNTS_BULK_DELETED_ERROR:
    case DISCOUNT_DOWNLOAD_ERROR:
    case DISCOUNTS_DELETED_ERROR:
    case DISCOUNTS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DISCOUNTS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        discounts: action.payload,
      };

    case DISCOUNT_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        getDiscount: action.payload,
      };

    case ADD_DISCOUNTS_OK:
      return {
        ...state,
        loading: false,
        error: null,
        discountAddInfo: action.payload,
      };

    case RETRIEVE_DISCOUNTS_DELETE:
      return {
        ...state,
        deleteDiscount: action.payload,
      };

    case DISCOUNTS_DELETED_OK:
      return {
        ...state,
        discounts: state.discounts.data.filter(
          (discount) => discount.id !== state.deleteDiscount
        ),
        deleteDiscount: null,
      };

    case RETRIEVE_DISCOUNTS_EDIT:
      return {
        ...state,
        editDiscount: action.payload,
      };

    case DISCOUNTS_EDITED_OK:
      return {
        ...state,
        editDiscount: null,
        discounts: state.discounts.data.map((discount) =>
          discount.id === action.payload.id
            ? (discount = action.payload)
            : discount
        ),
      };

    case RESET:
      return {
        ...state,
        getDiscount: null,
        discountAddInfo: null,
      };

    default:
      return state;
  }
}
