export const ADD_ACCOUNT_TYPES = "ADD_ACCOUNT_TYPES";
export const ADD_ACCOUNT_TYPES_OK = "ADD_ACCOUNT_TYPES_OK";
export const ADD_ACCOUNT_TYPES_ERROR = "ADD_ACCOUNT_TYPES_ERROR";

export const BEGIN_ACCOUNT_TYPES_DOWNLOAD = "BEGIN_ACCOUNT_TYPES_DOWNLOAD";
export const ACCOUNT_TYPES_DOWNLOAD_OK = "ACCOUNT_TYPES_DOWNLOAD_OK";
export const ACCOUNT_TYPES_DOWNLOAD_ERROR = "ACCOUNT_TYPES_DOWNLOAD_ERROR";

export const RETRIEVE_ACCOUNT_TYPES_DELETE = "RETRIEVE_ACCOUNT_TYPES_DELETE";
export const ACCOUNT_TYPES_DELETED_OK = "ACCOUNT_TYPES_DELETED_OK";
export const ACCOUNT_TYPES_DELETED_ERROR = "ACCOUNT_TYPES_DELETED_ERROR";

export const RETRIEVE_ACCOUNT_TYPES_EDIT = "RETRIEVE_ACCOUNT_TYPES_EDITED";
export const BEGIN_EDIT_ACCOUNT_TYPES = "BEGIN_EDIT_ACCOUNT_TYPES";
export const ACCOUNT_TYPES_EDITED_OK = "ACCOUNT_TYPES_EDITED_OK";
export const ACCOUNT_TYPES_EDITED_ERROR = "ACCOUNT_TYPES_EDITED_ERROR";

export const RETRIEVE_ACCOUNT_TYPES_BULK_DELETE = "RETRIEVE_ACCOUNT_TYPES_BULK_DELETE";
export const ACCOUNT_TYPES_BULK_DELETED_OK = "ACCOUNT_TYPES_BULK_DELETED_OK";
export const ACCOUNT_TYPES_BULK_DELETED_ERROR = "ACCOUNT_TYPES_BULK_DELETED_ERROR";