import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*            Discount-Details API CALLS           */
/*-------------------------------------------------*/

export async function retrieveDiscountDetailsData() {
  return await axiosClient.get("/discount_details");
}

export async function addDiscountDetailDB(value) {
  return await axiosClient.post("/discount_details", value);
}

export async function deleteDiscountDetailDB(id) {
  return await axiosClient.delete(`/discount_details/${id}`);
}

export async function editDiscountDetailDB(value) {
  return await axiosClient.put(`/discount_details/${value.id}`, value);
}
