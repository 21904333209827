import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import {
  useLocation
} from "react-router-dom";
import MyDocument from "./MyDocument";


const fakeData = [
  {
      "data": [
          {
              "id": 0,
              "ac_id": 30,
              "purticulars": "Opening",
              "do_date": "2023-08-01",
              "delivery_date": null,
              "product_name": "",
              "uom": "",
              "quantity": "0.00",
              "rate": "0.00",
              "purchase_amt": "0.00",
              "payment_amt": "0.00",
              "balance_amt": null,
              "doc_no": "",
              "account_name": "DBBL",
              "address": "",
              "phone_no": "",
              "mail_address": "",
              "cumulative_sum": "0.00"
          },
          {
            "id": 0,
            "ac_id": 30,
            "purticulars": "ABC",
            "do_date": "2023-08-01",
            "delivery_date": "2023-08-20",
            "product_name": "XYZ",
            "uom": "TON",
            "quantity": "20",
            "rate": "5",
            "purchase_amt": "2000",
            "payment_amt": "1000",
            "balance_amt": "5000",
            "doc_no": "20",
            "account_name": "DBBL",
            "address": "",
            "phone_no": "",
            "mail_address": "",
            "cumulative_sum": "1000"
        }
      ]
  }
]




const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);

  React.useEffect(() => {
    document.title = "CashBank Book";
  }, []);

  const location = useLocation();

  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const getSupplierInfo = localStorage.getItem("cashBank_book")
    ? JSON.parse(localStorage.getItem("cashBank_book"))
    : null;


    useEffect(()=>{
        if(location){
          const getData = localStorage.getItem("company-info") ? JSON.parse(localStorage.getItem("company-info")) : null;  
           setCompanyData(getData);
        }  
      },[location]);



    useEffect(()=>{
        fetch("https://step-up-api.clonestudiobd.com/api/companies").then((res)=> res.json()).then((data)=> {
          const filterdData = data.data.filter((item)=> item.id === companyData?.id);
          setCompanyInfo(filterdData);
        })
        
      },[companyData]);





  React.useEffect(() => {
    if (getSupplierInfo) {
      fetch(`https://step-up-api.clonestudiobd.com/api/doc/cash_bank_book`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(getSupplierInfo),
      }).then((response) => {
        response.json().then((result) => {
          setInvoiceData(result);
        });
      });
    }
  }, []);

  console.log("invoiceData", invoiceData, "getSupplierInfo", getSupplierInfo);


  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument
        invoiceData={invoiceData ? fakeData : []}
        companyInfo={companyInfo}
        getInfo={getInfo}
        getSupplierInfo={getSupplierInfo}
        
      />
    </PDFViewer>
  );
};

export default Invoice;
