import axiosClient from "../axiosClient";

export async function bulkDeleteProductTypeDB(ids) {
  return await axiosClient.post(`/product_types/bulk_delete`, ids);
}

export async function retrieveProductTypesData() {
  return await axiosClient.get("/product_types");
}

export async function addProductTypeDB(value) {
  return await axiosClient.post("/product_types", value);
}

export async function deleteProductTypeDB(id) {
  return await axiosClient.delete(`/product_types/${id}`);
}

export async function editProductTypeDB(value) {
  return await axiosClient.put(`/product_types/${value.id}`, value);
}
