import {
  ADD_COUNTRIES, ADD_COUNTRIES_ERROR, ADD_COUNTRIES_OK, BEGIN_COUNTRIES_DOWNLOAD, BEGIN_EDIT_COUNTRIES, COUNTRIES_BULK_DELETED_ERROR, COUNTRIES_BULK_DELETED_OK, COUNTRIES_DELETED_ERROR, COUNTRIES_DELETED_OK, COUNTRIES_DOWNLOAD_ERROR, COUNTRIES_DOWNLOAD_OK, COUNTRIES_EDITED_ERROR, COUNTRIES_EDITED_OK, RETRIEVE_COUNTRIES_BULK_DELETE, RETRIEVE_COUNTRIES_DELETE, RETRIEVE_COUNTRIES_EDIT
} from "../../constants/modules/countriesConstants.js";
    
    const initialState = {
      countries: [],
      error: false,
      loading: false,
      deleteCountry: null,
    };
    
    export default function (state = initialState, action) {
      switch (action.type) {
        case BEGIN_COUNTRIES_DOWNLOAD:
        case RETRIEVE_COUNTRIES_BULK_DELETE:
        case ADD_COUNTRIES:
        case BEGIN_EDIT_COUNTRIES:
          return {
            ...state,
            loading: action.payload,
            country: action.country,
          };
    
        case ADD_COUNTRIES_OK:
        case COUNTRIES_BULK_DELETED_OK:
          return {
            ...state,
            loading: false,
          };
    
        case ADD_COUNTRIES_ERROR:
        case COUNTRIES_DOWNLOAD_ERROR:
        case COUNTRIES_BULK_DELETED_ERROR:
        case COUNTRIES_DELETED_ERROR:
        case COUNTRIES_EDITED_ERROR:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case COUNTRIES_DOWNLOAD_OK:
          return {
            ...state,
            loading: false,
            error: null,
            countries: action.payload,
          };
    
        case RETRIEVE_COUNTRIES_DELETE:
          return {
            ...state,
            deleteCountry: action.payload,
          };
    
        case COUNTRIES_DELETED_OK:
          return {
            ...state,
            countries: state.countries.data.filter((country) => country.id !== state.deleteCountry),
            deleteCountry: null,
          };
    
        case RETRIEVE_COUNTRIES_EDIT:
          return {
            ...state,
            editCountry: action.payload,
          };
    
        case COUNTRIES_EDITED_OK:
          return {
            ...state,
            editCountry: null,
            countries: state.countries.data.map((country) =>
              country.id === action.payload.id ? (country = action.payload) : country
            ),
          };
    
        default:
          return state;
      }
    }
    