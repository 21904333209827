import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*             SalesContact API CALLS              */
/*-------------------------------------------------*/
export async function bulkDeleteSalesContractDB(ids) {
  console.log("KKKKKKKK", ids);

  let n_id = {};

  return await axiosClient.post(`/sales_contracts/delete`, ids);
}

export async function retrieveSalesContractsData(value) {
  const postData = {
    p_id: value ? value : null,
  };

  return await axiosClient.post("/sales_contracts/get_all", postData);
}

export async function retrieveSalesContractData(value) {
  return await axiosClient.post("/sales_contracts/details", value);
}

export async function addSalesContractDB(value) {
  return await axiosClient.post("/sales_contracts", value);
}

export async function deleteSalesContractDB(id) {
  return await axiosClient.delete(`/sales_contracts/${id}`);
}

export async function editSalesContractDB(value) {
  console.log("edit******", value);
  return await axiosClient.put(`/sales_contracts/${value.id}`, value);
}
