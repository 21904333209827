import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*               TT Advance API CALLS              */
/*-------------------------------------------------*/

export async function retrieveTTAdvancesData() {
  return await axiosClient.post("/tt_advance_hdr/all");
}

export async function retrieveTTAdvanceData(value) {
  return await axiosClient.post("/tt_advance_hdr/details", value);
}

export async function addTTAdvanceDB(value) {
  return await axiosClient.post("/tt_advance_hdr/create", value);
}

export async function deleteTTAdvanceDB(id) {
  return await axiosClient.delete(`/tt_advance_hdr/delete/${id}`);
}

export async function editTTAdvanceDB(value) {
  return await axiosClient.put(`/tt_advance_hdr/${value.id}`, value);
}
