import {
    ADD_APPROVAL_PANEL_MEMBERS,
    ADD_APPROVAL_PANEL_MEMBERS_ERROR,
    ADD_APPROVAL_PANEL_MEMBERS_OK,
    APPROVAL_PANEL_MEMBERS_BULK_DELETED_ERROR,
    APPROVAL_PANEL_MEMBERS_BULK_DELETED_OK,
    APPROVAL_PANEL_MEMBERS_DELETED_ERROR,
    APPROVAL_PANEL_MEMBERS_DELETED_OK,
    APPROVAL_PANEL_MEMBERS_DOWNLOAD_ERROR,
    APPROVAL_PANEL_MEMBERS_DOWNLOAD_OK,
    APPROVAL_PANEL_MEMBERS_EDITED_ERROR,
    APPROVAL_PANEL_MEMBERS_EDITED_OK,
    BEGIN_APPROVAL_PANEL_MEMBERS_DOWNLOAD,
    BEGIN_EDIT_APPROVAL_PANEL_MEMBERS,
    RETRIEVE_APPROVAL_PANEL_MEMBERS_BULK_DELETE,
    RETRIEVE_APPROVAL_PANEL_MEMBERS_DELETE,
    RETRIEVE_APPROVAL_PANEL_MEMBERS_EDIT
} from "../../constants/modules/approvalPanelMembersContants.js";

/*-------------------------------------------------------*/
/*                        BULK ACTIONS                   */
/*-------------------------------------------------------*/
export const bulkDeleteApprovalPanelMemberAction = (item) => ({
  type: RETRIEVE_APPROVAL_PANEL_MEMBERS_BULK_DELETE,
  payload: true,
  approvalPanelMember: item,
});

export const bulkDeleteApprovalPanelMemberOkAction = () => ({
  type: APPROVAL_PANEL_MEMBERS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteApprovalPanelMemberErrorAction = (state) => ({
  type: APPROVAL_PANEL_MEMBERS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadApprovalPanelMembersAction = () => ({
  type: BEGIN_APPROVAL_PANEL_MEMBERS_DOWNLOAD,
  payload: true,
});

export const downloadApprovalPanelMembersOkAction = (items) => ({
  type: APPROVAL_PANEL_MEMBERS_DOWNLOAD_OK,
  payload: items,
});

export const downloadApprovalPanelMembersErrorAction = () => ({
  type: APPROVAL_PANEL_MEMBERS_DOWNLOAD_ERROR,
  payload: true,
});

export const addApprovalPanelMemberAction = (item) => ({
  type: ADD_APPROVAL_PANEL_MEMBERS,
  payload: true,
  approvalPanelMember: item,
});

export const addApprovalPanelMemberOkAction = () => ({
  type: ADD_APPROVAL_PANEL_MEMBERS_OK,
  payload: true,
});

export const addApprovalPanelMemberErrorAction = (state) => ({
  type: ADD_APPROVAL_PANEL_MEMBERS_ERROR,
  payload: state,
});

export const deleteApprovalPanelMemberAction = (id) => ({
  type: RETRIEVE_APPROVAL_PANEL_MEMBERS_DELETE,
  payload: id,
});

export const deleteApprovalPanelMemberOkAction = () => ({
  type: APPROVAL_PANEL_MEMBERS_DELETED_OK,
});

export const deleteApprovalPanelMemberErrorAction = () => ({
  type: APPROVAL_PANEL_MEMBERS_DELETED_ERROR,
  payload: true,
});

export const retrieveApprovalPanelMemberAction = (item) => ({
  type: RETRIEVE_APPROVAL_PANEL_MEMBERS_EDIT,
  payload: item,
});

export const editApprovalPanelMemberAction = (item) => ({
  type: BEGIN_EDIT_APPROVAL_PANEL_MEMBERS,
  approvalPanelMember: item,
});

export const editApprovalPanelMemberOkAction = (item) => ({
  type: APPROVAL_PANEL_MEMBERS_EDITED_OK,
  payload: item,
});

export const editApprovalPanelMemberErrorAction = () => ({
  type: APPROVAL_PANEL_MEMBERS_EDITED_ERROR,
  payload: true,
});
