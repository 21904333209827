import {
  ADD_FACTORY_ORDER_DETAILS,
  ADD_FACTORY_ORDER_DETAILS_OK,
  ADD_FACTORY_ORDER_DETAILS_ERROR,
  BEGIN_FACTORY_ORDER_DETAILS_DOWNLOAD,
  FACTORY_ORDER_DETAILS_DOWNLOAD_OK,
  FACTORY_ORDER_DETAILS_DOWNLOAD_ERROR,
  RETRIEVE_FACTORY_ORDER_DETAILS_DELETE,
  FACTORY_ORDER_DETAILS_DELETED_OK,
  FACTORY_ORDER_DETAILS_DELETED_ERROR,
  RETRIEVE_FACTORY_ORDER_DETAILS_EDIT,
  BEGIN_EDIT_FACTORY_ORDER_DETAILS,
  FACTORY_ORDER_DETAILS_EDITED_OK,
  FACTORY_ORDER_DETAILS_EDITED_ERROR,
  RETRIEVE_FACTORY_ORDER_DETAILS_BULK_DELETE,
  FACTORY_ORDER_DETAILS_BULK_DELETED_OK,
  FACTORY_ORDER_DETAILS_BULK_DELETED_ERROR,
  BEGIN_ORDER_DETAILS_BY_FO_DOWNLOAD,
  ORDER_DETAILS_BY_FO_DOWNLOAD_OK,
  ORDER_DETAILS_BY_FO_DOWNLOAD_ERROR,
} from "../../constants/modules/factoryOrderDetailsConstants";

export const downloadFactoryOrderDetailsAction = () => ({
  type: BEGIN_FACTORY_ORDER_DETAILS_DOWNLOAD,
  payload: true,
});

export const downloadFactoryOrderDetailsOkAction = (items) => ({
  type: FACTORY_ORDER_DETAILS_DOWNLOAD_OK,
  payload: items,
});

export const downloadFactoryOrderDetailsErrorAction = () => ({
  type: FACTORY_ORDER_DETAILS_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadFactoryOrderDetailsByFoAction = (item) => ({
  type: BEGIN_ORDER_DETAILS_BY_FO_DOWNLOAD,
  payload: true,
  factoryOrderDetail: item,
});

export const downloadFactoryOrderDetailsByFoOkAction = (items) => ({
  type: ORDER_DETAILS_BY_FO_DOWNLOAD_OK,
  payload: items,
});

export const downloadFactoryOrderDetailsByFoErrorAction = () => ({
  type: ORDER_DETAILS_BY_FO_DOWNLOAD_ERROR,
  payload: true,
});

export const addFactoryOrderDetailAction = (item) => ({
  type: ADD_FACTORY_ORDER_DETAILS,
  payload: true,
  factoryOrderDetail: item,
});

export const addFactoryOrderDetailOkAction = () => ({
  type: ADD_FACTORY_ORDER_DETAILS_OK,
  payload: true,
});

export const addFactoryOrderDetailErrorAction = (state) => ({
  type: ADD_FACTORY_ORDER_DETAILS_ERROR,
  payload: state,
});

export const deleteFactoryOrderDetailAction = (id) => ({
  type: RETRIEVE_FACTORY_ORDER_DETAILS_DELETE,
  payload: id,
});

export const deleteFactoryOrderDetailOkAction = () => ({
  type: FACTORY_ORDER_DETAILS_DELETED_OK,
});

export const deleteFactoryOrderDetailErrorAction = () => ({
  type: FACTORY_ORDER_DETAILS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteFactoryOrderDetailAction = (item) => ({
  type: RETRIEVE_FACTORY_ORDER_DETAILS_BULK_DELETE,
  payload: true,
  factoryOrderDetail: item,
});

export const bulkDeleteFactoryOrderDetailOkAction = () => ({
  type: FACTORY_ORDER_DETAILS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteFactoryOrderDetailErrorAction = (state) => ({
  type: FACTORY_ORDER_DETAILS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveFactoryOrderDetailAction = (item) => ({
  type: RETRIEVE_FACTORY_ORDER_DETAILS_EDIT,
  payload: item,
});

export const editFactoryOrderDetailAction = (item) => ({
  type: BEGIN_EDIT_FACTORY_ORDER_DETAILS,
  factoryOrderDetail: item,
});

export const editFactoryOrderDetailOkAction = (item) => ({
  type: FACTORY_ORDER_DETAILS_EDITED_OK,
  payload: item,
});

export const editFactoryOrderDetailErrorAction = () => ({
  type: FACTORY_ORDER_DETAILS_EDITED_ERROR,
  payload: true,
});
