import {
  ADD_MEASURING_UNITS, ADD_MEASURING_UNITS_ERROR, ADD_MEASURING_UNITS_OK,
  BEGIN_EDIT_MEASURING_UNITS,
  BEGIN_MEASURING_UNITS_DOWNLOAD,
  MEASURING_UNITS_BULK_DELETED_ERROR, MEASURING_UNITS_BULK_DELETED_OK, MEASURING_UNITS_DELETED_ERROR, MEASURING_UNITS_DELETED_OK, MEASURING_UNITS_DOWNLOAD_ERROR, MEASURING_UNITS_DOWNLOAD_OK, MEASURING_UNITS_EDITED_ERROR, MEASURING_UNITS_EDITED_OK, RETRIEVE_MEASURING_UNITS_BULK_DELETE, RETRIEVE_MEASURING_UNITS_DELETE, RETRIEVE_MEASURING_UNITS_EDIT
} from "../../constants/modules/measuringUnitsConstant";
    
    const initialState = {
        measuringUnits: [],
      error: false,
      loading: false,
      deleteMeasuringUnit: null,
    };
    
    export default function (state = initialState, action) {
      switch (action.type) {
        case BEGIN_MEASURING_UNITS_DOWNLOAD:
        case RETRIEVE_MEASURING_UNITS_BULK_DELETE:
        case ADD_MEASURING_UNITS:
        case BEGIN_EDIT_MEASURING_UNITS:
          return {
            ...state,
            loading: action.payload,
            measuringUnit: action.measuringUnit,
          };
    
        case ADD_MEASURING_UNITS_OK:
        case MEASURING_UNITS_BULK_DELETED_OK:
          return {
            ...state,
            loading: false,
          };
    
        case ADD_MEASURING_UNITS_ERROR:
        case MEASURING_UNITS_DOWNLOAD_ERROR:
        case MEASURING_UNITS_BULK_DELETED_ERROR:
        case MEASURING_UNITS_DELETED_ERROR:
        case MEASURING_UNITS_EDITED_ERROR:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case MEASURING_UNITS_DOWNLOAD_OK:
          return {
            ...state,
            loading: false,
            error: null,
            measuringUnits: action.payload,
          };
    
        case RETRIEVE_MEASURING_UNITS_DELETE:
          return {
            ...state,
            deleteMeasuringUnit: action.payload,
          };
    
        case MEASURING_UNITS_DELETED_OK:
          return {
            ...state,
            measuringUnits: state.measuringUnits.data.filter((measuringUnit) => measuringUnit.id !== state.deleteMeasuringUnit),
            deleteMeasuringUnit: null,
          };
    
        case RETRIEVE_MEASURING_UNITS_EDIT:
          return {
            ...state,
            editMeasuringUnit: action.payload,
          };
    
        case MEASURING_UNITS_EDITED_OK:
          return {
            ...state,
            editMeasuringUnit: null,
            measuringUnits: state.measuringUnits.data.map((measuringUnit) =>
              measuringUnit.id === action.payload.id ? (measuringUnit = action.payload) : measuringUnit
            ),
          };
    
        default:
          return state;
      }
    }
    