import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*             SAMPLE TYPES API CALLS             */
/*-------------------------------------------------*/

export async function retrieveSampleTypesData() {
    return await axiosClient.get("/sample_types");
  }
  
  export async function addSampleTypeDB(value) {  
    return await axiosClient.post("/sample_types", value);
  }
  
  export async function deleteSampleTypeDB(id) {
    return await axiosClient.delete(`/sample_types/${id}`);
  }
  
  export async function bulkDeleteSampleTypeDB(ids) {
    return await axiosClient.post(`/sample_types/bulk_delete`, ids);
  }
  
  export async function editSampleTypeDB(value) {
    return await axiosClient.put(`/sample_types/${value.id}`, value);
  }
  