import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_SUPPLIERS,
  BEGIN_EDIT_SUPPLIERS,
  BEGIN_SUPPLIERS_DOWNLOAD,
  RETRIEVE_SUPPLIERS_BULK_DELETE,
  RETRIEVE_SUPPLIERS_DELETE
} from "../../constants/modules/suppliersConstants";

import {
  addSupplierErrorAction,
  addSupplierOkAction,
  bulkDeleteSupplierErrorAction,
  bulkDeleteSupplierOkAction,
  deleteSupplierErrorAction,
  deleteSupplierOkAction,
  downloadSuppliersErrorAction,
  downloadSuppliersOkAction,
  editSupplierErrorAction,
  editSupplierOkAction
} from "../../actions/modules/suppliersActions";

import {
  addSupplierDB,
  bulkDeleteSupplierDB,
  deleteSupplierDB,
  editSupplierDB,
  retrieveSupplierDB
} from "../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveSuppliers() {
  try {
    const response = yield call(retrieveSupplierDB);
    if (response.status === 200) {
      yield put(downloadSuppliersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSuppliersErrorAction());
  }
}

// Create new product
// worker saga
export function* addSupplier(action) {
  const supplier = action.supplier;

  try {
    const response = yield call(addSupplierDB, supplier);
    if (response.status === 201) {
      yield delay(500);
      yield put(addSupplierOkAction(supplier));
      toast.success("Supplier created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addSupplierErrorAction(true));
    toast.error("Supplier created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteSupplier(action) {
  const id = action.payload;
  try {
    yield call(deleteSupplierDB, id);
    yield deleteSupplierOkAction();
    toast.success("Supplier deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteSupplierErrorAction());
    toast.error("Supplier deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkSupplierDelete(action) {
  const supplier = action.supplier;

  try {
    const response = yield call(bulkDeleteSupplierDB, supplier);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteSupplierOkAction(supplier));
      toast.success("Bulk Supplier deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteSupplierErrorAction(true));
    toast.error("Bulk Supplier deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editSupplier(action) {
  const supplier = action.supplier;

  try {
    const response = yield call(editSupplierDB, supplier);

    if (response.status === 201) {
      yield delay(500);
      yield put(editSupplierOkAction(supplier));
      toast.success("Supplier updated successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editSupplierErrorAction());
    toast.error("Supplier updated failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchSuppliersAsync() {
  yield takeLatest(BEGIN_SUPPLIERS_DOWNLOAD, retrieveSuppliers);
  yield takeLatest(ADD_SUPPLIERS, addSupplier);
  yield takeLatest(RETRIEVE_SUPPLIERS_DELETE, deleteSupplier);
  yield takeLatest(BEGIN_EDIT_SUPPLIERS, editSupplier);
  yield takeLatest(RETRIEVE_SUPPLIERS_BULK_DELETE, bulkSupplierDelete);
}
