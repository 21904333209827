import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_TNA_TEMPLATES,
  BEGIN_EDIT_TNA_TEMPLATES,
  BEGIN_TNA_TEMPLATES_DOWNLOAD,
  RETRIEVE_TNA_TEMPLATES_BULK_DELETE,
  RETRIEVE_TNA_TEMPLATES_DELETE,
  BEGIN_TNA_TEMPLATE_DOWNLOAD,
} from "../../constants/modules/tnaTemplatesConstants";

import {
  addTnaTemplateErrorAction,
  addTnaTemplateOkAction,
  bulkDeleteTnaTemplateErrorAction,
  bulkDeleteTnaTemplateOkAction,
  deleteTnaTemplateErrorAction,
  deleteTnaTemplateOkAction,
  downloadTnaTemplatesErrorAction,
  downloadTnaTemplatesOkAction,
  editTnaTemplateErrorAction,
  editTnaTemplateOkAction,
  downloadTnaTemplateErrorAction,
  downloadTnaTemplateOkAction,
} from "../../actions/modules/tnaTemplatesActions";

import {
  addTnaTemplateDB,
  bulkDeleteTnaTemplateDB,
  deleteTnaTemplateDB,
  editTnaTemplateDB,
  retrieveTnaTemplatesData,
  retrieveTnaTemplateData,
} from "../../../config/apiCalls/tnaTemplates";

// Retrieve products
// worker saga
export function* retrieveTnaTemplates(action) {
  const tnaTemplate = action.tnaTemplate;
  try {
    const response = yield call(retrieveTnaTemplatesData, tnaTemplate);
    console.log("abcd****", response);

    if (response.status === 200) {
      yield put(downloadTnaTemplatesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTnaTemplatesErrorAction());
  }
}

export function* retrieveTnaTemplate(action) {
  const tnaTemplate = action.tnaTemplate;
  try {
    const response = yield call(retrieveTnaTemplateData, tnaTemplate);
    console.log("*****JJJJJJJJJ*****", response);
    if (response.status === 200) {
      yield put(downloadTnaTemplateOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTnaTemplateErrorAction());
  }
}

// Create new product
// worker saga
export function* addTnaTemplate(action) {
  const tnaTemplate = action.tnaTemplate;

  try {
    const response = yield call(addTnaTemplateDB, tnaTemplate);
    console.log("*****uuuuuuuuu*****", response);
    if (response.status === 200) {
      yield delay(500);
      yield put(addTnaTemplateOkAction(response.data));
      toast.success("Tna Template created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addTnaTemplateErrorAction(true));
    toast.error("TnaTemplate created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteTnaTemplate(action) {
  const id = action.payload;
  try {
    yield call(deleteTnaTemplateDB, id);
    yield deleteTnaTemplateOkAction();
    toast.success("TnaTemplate deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteTnaTemplateErrorAction();
  }
}

// Edit product
// worker saga
export function* editTnaTemplate(action) {
  const tnaTemplate = action.tnaTemplate;

  try {
    yield call(editTnaTemplateDB, tnaTemplate);
    yield editTnaTemplateOkAction(tnaTemplate);
    // Alert
    // Alert
    toast.success("Tna-Action has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editTnaTemplateErrorAction();
  }
}

// BULK SAGAS
export function* bulkTnaTemplateDelete(action) {
  const tnaTemplate = action.tnaTemplate;

  try {
    const response = yield call(bulkDeleteTnaTemplateDB, tnaTemplate);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteTnaTemplateOkAction(tnaTemplate));
      toast.success("Tna Actions deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteTnaTemplateErrorAction(true));
    toast.error("Tna Actions deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchTnaTemplatesAsync() {
  yield takeLatest(BEGIN_TNA_TEMPLATES_DOWNLOAD, retrieveTnaTemplates);
  yield takeLatest(BEGIN_TNA_TEMPLATE_DOWNLOAD, retrieveTnaTemplate);
  yield takeLatest(ADD_TNA_TEMPLATES, addTnaTemplate);
  yield takeLatest(RETRIEVE_TNA_TEMPLATES_DELETE, deleteTnaTemplate);
  yield takeLatest(BEGIN_EDIT_TNA_TEMPLATES, editTnaTemplate);
  yield takeLatest(RETRIEVE_TNA_TEMPLATES_BULK_DELETE, bulkTnaTemplateDelete);
}
