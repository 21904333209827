import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*           TT ADVANCE DETAILS API CALLS          */
/*-------------------------------------------------*/

export async function retrieveTTAdvanceDetailsData() {
  return await axiosClient.post("/tt_advance_dtls/all");
}

export async function retrieveTTAdvanceDetailData(value) {
  return await axiosClient.post("/tt_advance_dtls", value);
}

export async function addTTAdvanceDetailDB(value) {
  return await axiosClient.post("/tt_advance_dtls", value);
}

export async function deleteTTAdvanceDetailDB(id) {
  return await axiosClient.delete(`/tt_advance_dtls/${id}`);
}

export async function editTTAdvanceDetailDB(value) {
  return await axiosClient.put(`/tt_advance_dtls/${value.id}`, value);
}
