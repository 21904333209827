import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
// Create styles
const Quixote = ({ invoiceData, companyInfo, getInfo, getSupplierInfo }) => {
  const reportData = invoiceData && invoiceData.length && invoiceData;
  // const reportHeaderData =
  //   invoiceData && invoiceData.length && invoiceData[0]?.["0"];
  // const tableHeaderData = null;

  console.log("invoiceData18", reportData);

  let grandtotalOrderQty = 0;
  let grandtotalPaymentAmt = 0;
  let grandtotalPurchaseAmt = 0;
  let grandtotalBalanceAmt = 0;

  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  // function inWords(num) {
  //   if ((num = num.toString()).length > 9) return "overflow";
  //   let n = ("000000000" + num)
  //     .substr(-9)
  //     .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  //   if (!n) return;
  //   var str = "";
  //   str +=
  //     n[1] != 0
  //       ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
  //       : "";
  //   str +=
  //     n[2] != 0
  //       ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lac "
  //       : "";
  //   str +=
  //     n[3] != 0
  //       ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
  //       : "";
  //   str +=
  //     n[4] != 0
  //       ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
  //       : "";
  //   str +=
  //     n[5] != 0
  //       ? (str != "" ? "and " : "") +
  //         (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
  //         ""
  //       : "";
  //   return str
  //     .toLowerCase()
  //     .split(" ")
  //     .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
  //     .join(" ");
  // }

  // const totalValueInword = CurrencyToWords(
  //   grandtotalValue ? grandtotalValue.toFixed(2) : 0
  // );

  //   function numberWithCommas(x) {
  //     return x.toString().split(".")[0].length > 3
  //       ? x
  //           .toString()
  //           .substring(0, x.toString().split(".")[0].length - 3)
  //           .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
  //           "," +
  //           x.toString().substring(x.toString().split(".")[0].length - 3)
  //       : x.toString();
  //   }
  // numberWithCommas(parseInt(item.amount))

  let count = 0;

  const BuyerData = ({ newArr, key, i2 }) => {
    return (
      <View
        wrap={false}
        break={count > 13}
        style={{
          borderBottom: "1px solid black",
          borderLeft: "1px solid black",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "8%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.do_date}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "7%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.delivery_date}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "7%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.doc_no}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.transport_no}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.purticulars}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.product_name}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "6%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {Math.round(Number(i2?.quantity))}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "6%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.uom}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "6%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.rate}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.payment_amt}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {Math.abs(Number(i2?.purchase_amt))}
        </Text>
        <Text
          style={{
            padding: "5px 2px 5px 0 ",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "right",
          }}
        >
          {i2?.cumulative_sum}
        </Text>
      </View>
    );
  };

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}

        {reportData &&
          reportData?.map((supplier, index) => {
            const filtering =
              supplier &&
              supplier?.data?.filter(
                (item) => item.purticulars.toLowerCase() === "opening"
              );

            const newArr =
              supplier &&
              supplier?.data?.filter(
                (item) => item.purticulars.toLowerCase() !== "opening"
              );

            const subtotalOrderQty =
              supplier?.data && supplier?.data.length
                ? supplier?.data.reduce(
                    (totalQty, currentQty) =>
                      totalQty + Number(currentQty?.quantity),
                    0
                  )
                : 0;

            grandtotalOrderQty += subtotalOrderQty;

            const subtotalPaymentAmt =
              supplier?.data && supplier?.data.length
                ? supplier?.data.reduce(
                    (totalValue, currentValue) =>
                      totalValue + Number(currentValue?.payment_amt),
                    0
                  )
                : null;

            grandtotalPaymentAmt += subtotalPaymentAmt;

            const subtotalPurchaseAmt =
              supplier?.data && supplier?.data.length
                ? supplier?.data.reduce(
                    (totalValue, currentValue) =>
                      totalValue + Math.abs(Number(currentValue?.purchase_amt)),
                    0
                  )
                : null;

            grandtotalPurchaseAmt += subtotalPurchaseAmt;

            const subtotalBalanceAmt =
              supplier?.data && supplier?.data.length
                ? supplier?.data.reduce(
                    (totalValue, currentValue) =>
                      totalValue + Number(currentValue?.cumulative_sum),
                    0
                  )
                : null;
            grandtotalBalanceAmt += subtotalBalanceAmt;

            return (
              <View key={index} break={index > 0}>
                <View wrap={false}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      backgroundColor: "#dcdcdc",
                      alignItems: "center",
                      // marginTop: -20,
                      // marginBottom: "20px",
                    }}
                  >
                    <View>
                      <Image
                        style={{
                          width: "90px",
                          height: "100px",
                          // textAlign: "center",
                          // margin: "auto",
                          marginTop: -20,
                        }}
                        src={`https://step-up-api.clonestudiobd.com/api/upload-images/${companyInfo?.[0]?.["logo"]}`}
                      />
                    </View>
                    <View>
                      <Text
                        style={{
                          fontSize: 35,
                          fontWeight: "bold",
                          paddingTop: 4,
                        }}
                      >
                        {companyInfo?.[0]?.["company_name"]}
                      </Text>
                    </View>
                    <View style={{ paddingRight: 2 }}>
                      <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                        Office:
                      </Text>
                      <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                        City: {companyInfo?.[0]?.["city"]}
                      </Text>
                      <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                        Street: {companyInfo?.[0]?.["street"]}
                      </Text>
                      <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                        Phone: {companyInfo?.[0]?.["phone_no"]}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      margin: "auto",
                      height: "auto",
                      marginTop: "2px",
                      // marginTop: -20,
                      marginBottom: "20px",
                      textAlign: "center",
                      width: "400px",
                      border: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        borderBottom: "1px solid black",
                        textAlign: "center",
                        padding: "4px",
                      }}
                    >
                      Supplier Ledger
                    </Text>
                    <Text
                      style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        textAlign: "center",
                        padding: "4px",
                      }}
                    >
                      <Text  style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}>From </Text>{" "}{getSupplierInfo && getSupplierInfo?.[0]?.["p_from_date"]}{" "}<Text style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}> To </Text>{" "}{getSupplierInfo && getSupplierInfo?.[0]?.["p_to_dat"]}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      marginTop: "-60px",
                      marginBottom: "20px",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "9px", fontWeight: 900 }}>
                        Print Date :{" "}
                        {new Date(defaultValue).toLocaleDateString([
                          "es-CL",
                          "id",
                        ])}
                      </Text>
                      <Text style={{ fontSize: "9px", fontWeight: 900 }}>
                        Print by : {""}
                      </Text>
                    </View>
                  </View>

                  {/* Header Info */}
                  <View
                    style={{
                      height: "70px",
                      marginTop: "20px",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontSize: "11px",
                          fontWeight: 900,
                          paddingBottom: 2,
                        }}
                      >
                        Account Name
                      </Text>
                      <Text
                        style={{
                          fontSize: "11px",
                          fontWeight: 900,
                          paddingBottom: 2,
                        }}
                      >
                        Address
                      </Text>
                     
                    </View>

                    <View>
                      <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                        : {newArr && newArr?.[0]?.["account_name"]}
                      </Text>
                      <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                        : {newArr && newArr?.[0]?.["address"]}
                      </Text>
                     
                    </View>
                    <View>
                      <Text
                        style={{
                          fontSize: "11px",
                          fontWeight: 900,
                          paddingBottom: 2,
                        }}
                      >
                        Contact
                      </Text>
                      <Text
                        style={{
                          fontSize: "11px",
                          fontWeight: 900,
                          paddingBottom: 2,
                        }}
                      >
                        Proprietor/Ref Name
                      </Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                        : {newArr && newArr?.[0]?.["phone_no"]}
                      </Text>
                      <Text style={{ fontSize: "11px", paddingBottom: 2 }}>
                        : {newArr && newArr?.[0]?.["ref_name"]}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* TABLE PART */}

                <View style={{ marginTop: "20px" }}>
                  <View
                    style={{
                      border: "1px solid black",
                      flexDirection: "row",
                      width: "100%",
                      backgroundColor: "#d3cfcf",
                    }}
                    fixed
                  >
                    <Text
                      style={{
                        padding: "9px 0",
                        width: "8%",
                        fontSize: "9px",
                        fontWeight: "bold",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Transaction Date
                    </Text>
                    <Text
                      style={{
                        padding: "9px 0",
                        width: "7%",
                        fontSize: "9px",
                        fontWeight: "bold",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Received Date
                    </Text>

                    <Text
                      style={{
                        padding: "9px 0 0 0",
                        width: "7%",
                        fontSize: "9px",
                        fontWeight: "bold",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Doc No.
                    </Text>
                    <Text
                      style={{
                        padding: "9px 0 0 0",
                        fontSize: "9px",
                        width: "10%",
                        fontWeight: "bold",
                        alignItems: "center",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Description
                    </Text>
                    <Text
                      style={{
                        padding: "9px 0 0 0",
                        width: "10%",
                        borderRight: "1px solid black",
                        fontSize: "9px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Particulars
                    </Text>
                    <Text
                      style={{
                        padding: "9px 0 0 0",
                        width: "10%",
                        fontSize: "9px",
                        fontWeight: "bold",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Product Name
                    </Text>
                    <Text
                      style={{
                        padding: "9px 0",
                        fontSize: "9px",
                        width: "6%",
                        fontWeight: "bold",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Quantity
                    </Text>
                    <Text
                      style={{
                        padding: "9px 0 0 0",
                        fontSize: "9px",
                        width: "6%",
                        fontWeight: "bold",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      UOM
                    </Text>
                    <Text
                      style={{
                        padding: "9px 0 0 0",
                        width: "6%",
                        fontSize: "9px",
                        fontWeight: "bold",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Rate
                    </Text>
                    <Text
                      style={{
                        padding: "9px 0",
                        fontSize: "9px",
                        width: "10%",
                        fontWeight: "bold",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Payment Amt
                    </Text>
                    <Text
                      style={{
                        padding: "9px 0",
                        fontSize: "9px",
                        width: "10%",
                        fontWeight: "bold",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Purchase Amt
                    </Text>
                    <Text
                      style={{
                        padding: "9px 0",
                        fontSize: "9px",
                        width: "10%",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Balance
                    </Text>
                  </View>

                  <View>
                    <View
                      style={{
                        // flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <View
                        wrap={false}
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          border: "1px solid black",
                          height: "auto",
                        }}
                      >
                        <Text
                          style={{
                            padding: "5px 0 5px 10px",
                            fontSize: "11px",
                            fontWeight: "bold",
                            color: "black",
                            height: "auto",
                          }}
                        >
                          {filtering?.[0]?.["purticulars"]}
                        </Text>
                        <Text
                          style={{
                            padding: "5px 2px 5px 0",
                            fontSize: "11px",
                            fontWeight: "bold",
                            color: "black",
                            height: "auto",
                          }}
                        >
                          {filtering?.[0]?.["cumulative_sum"]
                            ? filtering?.[0]?.["cumulative_sum"]
                            : "0.00"}
                        </Text>
                      </View>

                      {newArr && newArr?.length
                        ? newArr?.map((i2, i) => {
                            count = count + 1;
                            return (
                              <View key={i}>
                                <BuyerData newArr={newArr} key={i} i2={i2} />
                              </View>
                            );
                          })
                        : null}
                      <View
                        style={{
                          borderBottom: "1px solid black",
                          borderLeft: "1px solid black",
                          flexDirection: "row",
                          width: "100%",
                        }}
                        wrap={false}
                      >
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "9px",
                            height: "auto",
                            width: "8%",
                            fontWeight: "bold",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {"Sub Total"}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "7%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "7%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "10%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "10%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "10%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "6%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {subtotalOrderQty}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "6%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "6%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "10%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {subtotalPaymentAmt?.toFixed(2)}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "10%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {subtotalPurchaseAmt?.toFixed(2)}
                        </Text>
                        <Text
                          style={{
                            padding: "5px 2px 5px 0",
                            fontSize: "8px",
                            height: "auto",
                            width: "10%",
                            borderRight: "1px solid black",
                            textAlign: "right",
                          }}
                        >
                          {/* {subtotalBalanceAmt?.toFixed(2)} */}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            );
          })}

        <View
          wrap={false}
          style={{
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Text
            style={{
              padding: "5px",
              fontSize: "9px",
              height: "auto",
              width: "8%",
              fontWeight: "bold",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {"Grand Total"}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "7%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "7%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "10%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "10%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          ></Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "10%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "6%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {grandtotalOrderQty}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "6%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "6%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "10%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {grandtotalPaymentAmt?.toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "10%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {grandtotalPurchaseAmt?.toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "5px 2px 5px 0",
              fontSize: "8px",
              height: "auto",
              width: "10%",
              borderRight: "1px solid black",
              textAlign: "right",
            }}
          >
            {/* {grandtotalBalanceAmt?.toFixed(2)} */}
          </Text>
        </View>

        {/* Signature part */}
        <View
          fixed
          style={{
            flexDirection: "row",
            bottom: 0,
            marginTop: 60,
            justifyContent: "space-around",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Received Name
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Customer Seal & Signature
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Authorised Signature
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/assets/fonts/ARIALN.TTF",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/ARIALN.TTF",
    },
    {
      src: "/assets/fonts/ARIALBD.TTF",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 25,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Arial",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
