
import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*                   USERS CALLS                   */
/*-------------------------------------------------*/

export async function retrieveUsersData() {
    return await axiosClient.get("/users");
  }

export async function addUserDB(value) {
    return await axiosClient.post("/users", value);
  }
  
  export async function deleteUserDB(id) {
    return await axiosClient.delete(`/users/${id}`);
  }
  
  export async function bulkDeleteUserDB(ids) {
    return await axiosClient.post(`/users/bulk_delete`, ids);
  }
  
  export async function editUserDB(value) {
    return await axiosClient.put(`/users/${value.id}`, value);
  }