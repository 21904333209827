import {
    ADD_PAYMENT_TERMS, ADD_PAYMENT_TERMS_ERROR, ADD_PAYMENT_TERMS_OK, BEGIN_EDIT_PAYMENT_TERMS, BEGIN_PAYMENT_TERMS_DOWNLOAD, PAYMENT_TERMS_BULK_DELETED_ERROR, PAYMENT_TERMS_BULK_DELETED_OK, PAYMENT_TERMS_DELETED_ERROR, PAYMENT_TERMS_DELETED_OK, PAYMENT_TERMS_DOWNLOAD_ERROR, PAYMENT_TERMS_DOWNLOAD_OK, PAYMENT_TERMS_EDITED_ERROR, PAYMENT_TERMS_EDITED_OK, RETRIEVE_PAYMENT_TERMS_BULK_DELETE, RETRIEVE_PAYMENT_TERMS_DELETE, RETRIEVE_PAYMENT_TERMS_EDIT
} from "../../constants/modules/paymentTermConstants.js";
    
    const initialState = {
      paymentTerms: [],
      error: false,
      loading: false,
      deletePaymentTerm: null,
    };
    
    export default function (state = initialState, action) {
      switch (action.type) {
        case BEGIN_PAYMENT_TERMS_DOWNLOAD:
        case RETRIEVE_PAYMENT_TERMS_BULK_DELETE:
        case ADD_PAYMENT_TERMS:
        case BEGIN_EDIT_PAYMENT_TERMS:
          return {
            ...state,
            loading: action.payload,
            paymentTerm: action.paymentTerm,
          };
    
        case ADD_PAYMENT_TERMS_OK:
        case PAYMENT_TERMS_BULK_DELETED_OK:
          return {
            ...state,
            loading: false,
          };
    
        case ADD_PAYMENT_TERMS_ERROR:
        case PAYMENT_TERMS_DOWNLOAD_ERROR:
        case PAYMENT_TERMS_BULK_DELETED_ERROR:
        case PAYMENT_TERMS_DELETED_ERROR:
        case PAYMENT_TERMS_EDITED_ERROR:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case PAYMENT_TERMS_DOWNLOAD_OK:
          return {
            ...state,
            loading: false,
            error: null,
            paymentTerms: action.payload,
          };
    
        case RETRIEVE_PAYMENT_TERMS_DELETE:
          return {
            ...state,
            deletePaymentTerm: action.payload,
          };
    
        case PAYMENT_TERMS_DELETED_OK:
          return {
            ...state,
            paymentTerms: state.paymentTerms.data.filter((paymentTerm) => paymentTerm.id !== state.deletePaymentTerm),
            deletePaymentTerm: null,
          };
    
        case RETRIEVE_PAYMENT_TERMS_EDIT:
          return {
            ...state,
            editPaymentTerm: action.payload,
          };
    
        case PAYMENT_TERMS_EDITED_OK:
          return {
            ...state,
            editPaymentTerm: null,
            paymentTerms: state.paymentTerms.data.map((paymentTerm) =>
              paymentTerm.id === action.payload.id ? (paymentTerm = action.payload) : paymentTerm
            ),
          };
    
        default:
          return state;
      }
    }
    