import {
    ADD_PROCEED_REALIZATION_DETAILS,
    ADD_PROCEED_REALIZATION_DETAILS_ERROR,
    ADD_PROCEED_REALIZATION_DETAILS_OK,
    BEGIN_EDIT_PROCEED_REALIZATION_DETAILS,
    BEGIN_PROCEED_REALIZATION_DETAILS_DOWNLOAD,
    BEGIN_PROCEED_REALIZATION_DETAIL_DOWNLOAD,
    PROCEED_REALIZATION_DETAILS_BULK_DELETED_ERROR,
    PROCEED_REALIZATION_DETAILS_BULK_DELETED_OK,
    PROCEED_REALIZATION_DETAILS_DELETED_ERROR,
    PROCEED_REALIZATION_DETAILS_DELETED_OK,
    PROCEED_REALIZATION_DETAILS_DOWNLOAD_ERROR,
    PROCEED_REALIZATION_DETAILS_DOWNLOAD_OK,
    PROCEED_REALIZATION_DETAILS_EDITED_ERROR,
    PROCEED_REALIZATION_DETAILS_EDITED_OK, RESET, RETRIEVE_PROCEED_REALIZATION_DETAILS_BULK_DELETE,
    RETRIEVE_PROCEED_REALIZATION_DETAILS_DELETE,
    RETRIEVE_PROCEED_REALIZATION_DETAILS_EDIT
} from "../../constants/modules/proceedRealizationDetailsConstant";

export const downloadProceedRealizationDetailsAction = () => ({
  type: BEGIN_PROCEED_REALIZATION_DETAILS_DOWNLOAD,
  payload: true,
});

export const downloadProceedRealizationDetailsOkAction = (items) => ({
  type: PROCEED_REALIZATION_DETAILS_DOWNLOAD_OK,
  payload: items,
});

export const downloadProceedRealizationDetailsErrorAction = () => ({
  type: PROCEED_REALIZATION_DETAILS_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadProceedRealizationDetailAction = (item) => ({
  type: BEGIN_PROCEED_REALIZATION_DETAIL_DOWNLOAD,
  payload: true,
  proceedRealizationDetail: item,
});

export const addProceedRealizationDetailAction = (item) => ({
  type: ADD_PROCEED_REALIZATION_DETAILS,
  payload: true,
  proceedRealizationDetail: item,
});

export const addProceedRealizationDetailOkAction = () => ({
  type: ADD_PROCEED_REALIZATION_DETAILS_OK,
  payload: true,
});

export const addProceedRealizationDetailErrorAction = (state) => ({
  type: ADD_PROCEED_REALIZATION_DETAILS_ERROR,
  payload: state,
});

export const deleteProceedRealizationDetailAction = (id) => ({
  type: RETRIEVE_PROCEED_REALIZATION_DETAILS_DELETE,
  payload: id,
});

export const deleteProceedRealizationDetailOkAction = () => ({
  type: PROCEED_REALIZATION_DETAILS_DELETED_OK,
});

export const deleteProceedRealizationDetailErrorAction = () => ({
  type: PROCEED_REALIZATION_DETAILS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteProceedRealizationDetailAction = (item) => ({
  type: RETRIEVE_PROCEED_REALIZATION_DETAILS_BULK_DELETE,
  payload: true,
  proceedRealizationDetail: item,
});

export const bulkDeleteProceedRealizationDetailOkAction = () => ({
  type: PROCEED_REALIZATION_DETAILS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteProceedRealizationDetailErrorAction = (state) => ({
  type: PROCEED_REALIZATION_DETAILS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveProceedRealizationDetailAction = (item) => ({
  type: RETRIEVE_PROCEED_REALIZATION_DETAILS_EDIT,
  payload: item,
});

export const editProceedRealizationDetailAction = (item) => ({
  type: BEGIN_EDIT_PROCEED_REALIZATION_DETAILS,
  proceedRealizationDetail: item,
});

export const editProceedRealizationDetailOkAction = (item) => ({
  type: PROCEED_REALIZATION_DETAILS_EDITED_OK,
  payload: item,
});

export const editProceedRealizationDetailErrorAction = () => ({
  type: PROCEED_REALIZATION_DETAILS_EDITED_ERROR,
  payload: true,
});

export const ProceedRealizationDetailReset = () => ({
    type: RESET,
    payload: true,
  });