export const ADD_PORTS = "ADD_PORTS";
export const ADD_PORTS_OK = "ADD_PORTS_OK";
export const ADD_PORTS_ERROR = "ADD_PORTS_ERROR";

export const BEGIN_PORTS_DOWNLOAD = "BEGIN_PORTS_DOWNLOAD";
export const PORTS_DOWNLOAD_OK = "PORTS_DOWNLOAD_OK";
export const PORTS_DOWNLOAD_ERROR = "PORTS_DOWNLOAD_ERROR";

export const RETRIEVE_PORTS_DELETE = "RETRIEVE_PORTS_DELETE";
export const PORTS_DELETED_OK = "PORTS_DELETED_OK";
export const PORTS_DELETED_ERROR = "PORTS_DELETED_ERROR";

export const RETRIEVE_PORTS_EDIT = "RETRIEVE_PORTS_EDITED";
export const BEGIN_EDIT_PORTS = "BEGIN_EDIT_PORTS";
export const PORTS_EDITED_OK = "PORTS_EDITED_OK";
export const PORTS_EDITED_ERROR = "PORTS_EDITED_ERROR";

export const RETRIEVE_PORTS_BULK_DELETE = "RETRIEVE_PORTS_BULK_DELETE";
export const PORTS_BULK_DELETED_OK = "PORTS_BULK_DELETED_OK";
export const PORTS_BULK_DELETED_ERROR = "PORTS_BULK_DELETED_ERROR";