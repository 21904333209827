export const ADD_CATEGORIES = "ADD_CATEGORIES";
export const ADD_CATEGORIES_OK = "ADD_CATEGORIES_OK";
export const ADD_CATEGORIES_ERROR = "ADD_CATEGORIES_ERROR";

export const BEGIN_CATEGORIES_DOWNLOAD = "BEGIN_CATEGORIES_DOWNLOAD";
export const CATEGORIES_DOWNLOAD_OK = "CATEGORIES_DOWNLOAD_OK";
export const CATEGORIES_DOWNLOAD_ERROR = "CATEGORIES_DOWNLOAD_ERROR";

export const RETRIEVE_CATEGORIES_DELETE = "RETRIEVE_CATEGORIES_DELETE";
export const CATEGORIES_DELETED_OK = "CATEGORIES_DELETED_OK";
export const CATEGORIES_DELETED_ERROR = "CATEGORIES_DELETED_ERROR";

export const RETRIEVE_CATEGORIES_EDIT = "RETRIEVE_CATEGORIES_EDITED";
export const BEGIN_EDIT_CATEGORIES = "BEGIN_EDIT_CATEGORIES";
export const CATEGORIES_EDITED_OK = "CATEGORIES_EDITED_OK";
export const CATEGORIES_EDITED_ERROR = "CATEGORIES_EDITED_ERROR";

export const RETRIEVE_CATEGORIES_BULK_DELETE = "RETRIEVE_CATEGORIES_BULK_DELETE";
export const CATEGORIES_BULK_DELETED_OK = "CATEGORIES_BULK_DELETED_OK";
export const CATEGORIES_BULK_DELETED_ERROR = "CATEGORIES_BULK_DELETED_ERROR";