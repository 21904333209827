export const ADD_TNA_HEADER = "ADD_TNA_HEADER";
export const ADD_TNA_HEADER_OK = "ADD_TNA_HEADER_OK";
export const ADD_TNA_HEADER_ERROR = "ADD_TNA_HEADER_ERROR";

export const BEGIN_TNA_HEADER_DOWNLOAD = "BEGIN_TNA_HEADER_DOWNLOAD";
export const TNA_HEADER_DOWNLOAD_OK = "TNA_HEADER_DOWNLOAD_OK";
export const TNA_HEADER_DOWNLOAD_ERROR = "TNA_HEADER_DOWNLOAD_ERROR";

export const BEGIN_TNA_HEADER_BY_ID_DOWNLOAD =
  "BEGIN_TNA_HEADER_BY_ID_DOWNLOAD";
export const TNA_HEADER_BY_ID_DOWNLOAD_OK = "TNA_HEADER_BY_ID_DOWNLOAD_OK";
export const TNA_HEADER_BY_ID_DOWNLOAD_ERROR =
  "TNA_HEADER_BY_ID_DOWNLOAD_ERROR";

export const RETRIEVE_TNA_HEADER_DELETE = "RETRIEVE_TNA_HEADER_DELETE";
export const TNA_HEADER_DELETED_OK = "TNA_HEADER_DELETED_OK";
export const TNA_HEADER_DELETED_ERROR = "TNA_HEADER_DELETED_ERROR";

export const RETRIEVE_TNA_HEADER_EDIT = "RETRIEVE_TNA_HEADER_EDITED";
export const BEGIN_EDIT_TNA_HEADER = "BEGIN_EDIT_TNA_HEADER";
export const TNA_HEADER_EDITED_OK = "TNA_HEADER_EDITED_OK";
export const TNA_HEADER_EDITED_ERROR = "TNA_HEADER_EDITED_ERROR";

export const RETRIEVE_TNA_HEADER_BULK_DELETE =
  "RETRIEVE_TNA_HEADER_BULK_DELETE";
export const TNA_HEADER_BULK_DELETED_OK = "TNA_HEADER_BULK_DELETED_OK";
export const TNA_HEADER_BULK_DELETED_ERROR = "TNA_HEADER_BULK_DELETED_ERROR";

//factory_by_order
export const BEGIN_TNA_FACTORY_BY_ORDER_DOWNLOAD =
  "BEGIN_TNA_FACTORY_BY_ORDER_DOWNLOAD";
export const TNA_FACTORY_BY_ORDER_DOWNLOAD_OK =
  "TNA_FACTORY_BY_ORDER_DOWNLOAD_OK";
export const TNA_FACTORY_BY_ORDER_DOWNLOAD_ERROR =
  "TNA_FACTORY_BY_ORDER_DOWNLOAD_ERROR";

//style by factory

export const BEGIN_TNA_STYLE_BY_FACTORY_DOWNLOAD =
  "BEGIN_TNA_STYLE_BY_FACTORY_DOWNLOAD";
export const TNA_STYLE_BY_FACTORY_DOWNLOAD_OK =
  "TNA_STYLE_BY_FACTORY_DOWNLOAD_OK";
export const TNA_STYLE_BY_FACTORY_DOWNLOAD_ERROR =
  "TNA_STYLE_BY_FACTORY_DOWNLOAD_ERROR";

//template by buyer

export const BEGIN_TNA_TEMPLATE_BY_BUYER_DOWNLOAD =
  "BEGIN_TNA_TEMPLATE_BY_BUYER_DOWNLOAD";
export const TNA_TEMPLATE_BY_BUYER_DOWNLOAD_OK =
  "TNA_TEMPLATE_BY_BUYER_DOWNLOAD_OK";
export const TNA_TEMPLATE_BY_BUYER_DOWNLOAD_ERROR =
  "TNA_TEMPLATE_BY_BUYER_DOWNLOAD_ERROR";

export const RESET = "RESET";
