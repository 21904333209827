import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*             SalesContact API CALLS              */
/*-------------------------------------------------*/
export async function bulkDeleteLCHeaderDB(ids) {
  return await axiosClient.post(`/lc_headers/bulk_delete`, ids);
}

export async function retrieveLCHeadersData(value) {
  const postData = {
    p_id: value ? value : null,
  };
  return await axiosClient.post("/lc_headers/get_all", postData);
}

export async function retrieveLCHeaderData(value) {
  return await axiosClient.post("/lc_headers/details", value);
}

export async function addLCHeaderDB(value) {
  console.log("post valus", JSON.stringify(value));

  return await axiosClient.post("/lc_headers", value);
}

export async function deleteLCHeaderDB(id) {
  return await axiosClient.delete(`/lc_headers/${id}`);
}

export async function editLCHeaderDB(value) {
  //   console.log("edit values ", value);

  return await axiosClient.put(`/lc_headers/${value.id}`, value);
}
