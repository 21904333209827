import {
  ADD_SUPPLIERS,
  ADD_SUPPLIERS_OK,
  ADD_SUPPLIERS_ERROR,
  BEGIN_SUPPLIERS_DOWNLOAD,
  SUPPLIERS_DOWNLOAD_OK,
  SUPPLIERS_DOWNLOAD_ERROR,
  RETRIEVE_SUPPLIERS_DELETE,
  SUPPLIERS_DELETED_OK,
  SUPPLIERS_DELETED_ERROR,
  RETRIEVE_SUPPLIERS_EDIT,
  BEGIN_EDIT_SUPPLIERS,
  SUPPLIERS_EDITED_OK,
  SUPPLIERS_EDITED_ERROR,
  RETRIEVE_SUPPLIERS_BULK_DELETE,
  SUPPLIERS_BULK_DELETED_OK,
  SUPPLIERS_BULK_DELETED_ERROR,
} from "../../constants/modules/suppliersConstants";

const initialState = {
  suppliers: [],
  error: false,
  loading: false,
  deleteSupplier: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_SUPPLIERS_DOWNLOAD:
    case ADD_SUPPLIERS:
    case RETRIEVE_SUPPLIERS_BULK_DELETE:
    case BEGIN_EDIT_SUPPLIERS:
      return {
        ...state,
        loading: action.payload,
        supplier: action.supplier,
      };

    case ADD_SUPPLIERS_OK:
    case SUPPLIERS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_SUPPLIERS_ERROR:
    case SUPPLIERS_DOWNLOAD_ERROR:
    case SUPPLIERS_DELETED_ERROR:
    case SUPPLIERS_BULK_DELETED_ERROR:
    case SUPPLIERS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SUPPLIERS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        suppliers: action.payload,
      };

    case RETRIEVE_SUPPLIERS_DELETE:
      return {
        ...state,
        deleteSupplier: action.payload,
      };

    case SUPPLIERS_DELETED_OK:
      return {
        ...state,
        suppliers: state.suppliers.data.filter(
          (supplier) => supplier.id !== state.deleteSupplier
        ),
        deleteSupplier: null,
      };

    case RETRIEVE_SUPPLIERS_EDIT:
      return {
        ...state,
        editSupplier: action.payload,
      };

    case SUPPLIERS_EDITED_OK:
      return {
        ...state,
        editSupplier: null,
        suppliers: state.suppliers.data.map((supplier) =>
          supplier.id === action.payload.id
            ? (supplier = action.payload)
            : supplier
        ),
      };

    default:
      return state;
  }
}
