export const ADD_EX_FACTORY_DETAILS = "ADD_EX_FACTORY_DETAILS";
export const ADD_EX_FACTORY_DETAILS_OK = "ADD_EX_FACTORY_DETAILS_OK";
export const ADD_EX_FACTORY_DETAILS_ERROR = "ADD_EX_FACTORY_DETAILS_ERROR";

export const BEGIN_EX_FACTORY_DETAILS_DOWNLOAD =
  "BEGIN_EX_FACTORY_DETAILS_DOWNLOAD";
export const EX_FACTORY_DETAILS_DOWNLOAD_OK = "EX_FACTORY_DETAILS_DOWNLOAD_OK";
export const EX_FACTORY_DETAILS_DOWNLOAD_ERROR =
  "EX_FACTORY_DETAILS_DOWNLOAD_ERROR";

export const RETRIEVE_EX_FACTORY_DETAILS_EDIT =
  "RETRIEVE_EX_FACTORY_DETAILS_EDITED";
export const BEGIN_EDIT_EX_FACTORY_DETAILS = "BEGIN_EDIT_EX_FACTORY_DETAILS";
export const EX_FACTORY_DETAILS_EDITED_OK = "EX_FACTORY_DETAILS_EDITED_OK";
export const EX_FACTORY_DETAILS_EDITED_ERROR =
  "EX_FACTORY_DETAILS_EDITED_ERROR";

export const RETRIEVE_EX_FACTORY_DETAILS_DELETE =
  "RETRIEVE_EX_FACTORY_DETAILS_DELETE";
export const EX_FACTORY_DETAILS_DELETED_OK = "EX_FACTORY_DETAILS_DELETED_OK";
export const EX_FACTORY_DETAILS_DELETED_ERROR =
  "EX_FACTORY_DETAILS_DELETED_ERROR";

export const RETRIEVE_EX_FACTORY_DETAILS_BULK_DELETE =
  "RETRIEVE_EX_FACTORY_DETAILS_BULK_DELETE";
export const EX_FACTORY_DETAILS_BULK_DELETED_OK =
  "EX_FACTORY_DETAILS_BULK_DELETED_OK";
export const EX_FACTORY_DETAILS_BULK_DELETED_ERROR =
  "EX_FACTORY_DETAILS_BULK_DELETED_ERROR";

export const RESET = "RESET";
