export const ADD_COMMISSION = "ADD_COMMISSION";
export const ADD_COMMISSION_OK = "ADD_COMMISSION_OK";
export const ADD_COMMISSION_ERROR = "ADD_COMMISSION_ERROR";

export const BEGIN_COMMISSION_DOWNLOAD = "BEGIN_COMMISSION_DOWNLOAD";
export const COMMISSION_DOWNLOAD_OK = "COMMISSION_DOWNLOAD_OK";
export const COMMISSION_DOWNLOAD_ERROR = "COMMISSION_DOWNLOAD_ERROR";

export const RETRIEVE_COMMISSION_DELETE = "RETRIEVE_COMMISSION_DELETE";
export const COMMISSION_DELETED_OK = "COMMISSION_DELETED_OK";
export const COMMISSION_DELETED_ERROR = "COMMISSION_DELETED_ERROR";
