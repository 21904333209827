import axiosClient from "../axiosClient";

export async function retrieveOrdersDB(value) {
  const postData = {
    p_id: value ? value : null,
  };
  return await axiosClient.post("/orders/all", postData);
}

export async function addOrderDB(value) {
  return await axiosClient.post("/orders", value);
}

export async function deleteOrderDB(id) {
  return await axiosClient.delete(`/orders/${id}`);
}

export async function bulkDeleteOrderDB(ids) {
  console.log("Valuuuu******, value", ids);

  return await axiosClient.post(`/orders/delete`, ids);
}

export async function editOrderDB(value) {
  return await axiosClient.put(`/orders/${value.id}`, value);
}
