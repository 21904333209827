import {
    ADD_TT_ADVANCES,
    ADD_TT_ADVANCES_ERROR,
    ADD_TT_ADVANCES_OK,
    BEGIN_EDIT_TT_ADVANCES,
    BEGIN_TT_ADVANCES_DOWNLOAD,
    BEGIN_TT_ADVANCE_DOWNLOAD,
    RESET,
    RETRIEVE_TT_ADVANCES_DELETE,
    RETRIEVE_TT_ADVANCES_EDIT,
    TT_ADVANCES_DELETED_ERROR,
    TT_ADVANCES_DELETED_OK,
    TT_ADVANCES_DOWNLOAD_ERROR,
    TT_ADVANCES_DOWNLOAD_OK,
    TT_ADVANCES_EDITED_ERROR,
    TT_ADVANCES_EDITED_OK,
    TT_ADVANCE_DOWNLOAD_ERROR,
    TT_ADVANCE_DOWNLOAD_OK
} from "../../constants/modules/TTAdvanceConstant";

export const downloadTTAdvancesAction = () => ({
  type: BEGIN_TT_ADVANCES_DOWNLOAD,
  payload: true,
});

export const downloadTTAdvancesOkAction = (items) => ({
  type: TT_ADVANCES_DOWNLOAD_OK,
  payload: items,
});

export const downloadTTAdvancesErrorAction = () => ({
  type: TT_ADVANCES_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadTTAdvanceAction = (item) => ({
  type: BEGIN_TT_ADVANCE_DOWNLOAD,
  payload: true,
  ttAdvance: item,
});

export const downloadTTAdvanceOkAction = (items) => ({
  type: TT_ADVANCE_DOWNLOAD_OK,
  payload: items,
});

export const downloadTTAdvanceErrorAction = () => ({
  type: TT_ADVANCE_DOWNLOAD_ERROR,
  payload: true,
});

export const addTTAdvanceAction = (item) => ({
  type: ADD_TT_ADVANCES,
  payload: true,
  ttAdvance: item,
});

export const addTTAdvanceOkAction = (items) => ({
  type: ADD_TT_ADVANCES_OK,
  payload: items,
});

export const addTTAdvanceErrorAction = (state) => ({
  type: ADD_TT_ADVANCES_ERROR,
  payload: state,
});

export const deleteTTAdvanceAction = (id) => ({
  type: RETRIEVE_TT_ADVANCES_DELETE,
  payload: id,
});

export const deleteTTAdvanceOkAction = () => ({
  type: TT_ADVANCES_DELETED_OK,
});

export const deleteTTAdvanceErrorAction = () => ({
  type: TT_ADVANCES_DELETED_ERROR,
  payload: true,
});

export const retrieveTTAdvanceAction = (item) => ({
  type: RETRIEVE_TT_ADVANCES_EDIT,
  payload: item,
});

export const editTTAdvanceAction = (item) => ({
  type: BEGIN_EDIT_TT_ADVANCES,
  ttAdvance: item,
});

export const editTTAdvanceOkAction = (item) => ({
  type: TT_ADVANCES_EDITED_OK,
  payload: item,
});

export const editTTAdvanceErrorAction = () => ({
  type: TT_ADVANCES_EDITED_ERROR,
  payload: true,
});

export const TTAdvanceReset = () => ({
  type: RESET,
  payload: true,
});
