import {
  ADD_ORDER_DETAILS,
  ADD_ORDER_DETAILS_OK,
  ADD_ORDER_DETAILS_ERROR,
  BEGIN_ORDER_DETAILS_DOWNLOAD,
  ORDER_DETAILS_DOWNLOAD_OK,
  ORDER_DETAILS_DOWNLOAD_ERROR,
  RETRIEVE_ORDER_DETAILS_DELETE,
  ORDER_DETAILS_DELETED_OK,
  ORDER_DETAILS_DELETED_ERROR,
  RETRIEVE_ORDER_DETAILS_EDIT,
  BEGIN_EDIT_ORDER_DETAILS,
  ORDER_DETAILS_EDITED_OK,
  ORDER_DETAILS_EDITED_ERROR,
  RETRIEVE_ORDER_DETAILS_BULK_DELETE,
  ORDER_DETAILS_BULK_DELETED_OK,
  ORDER_DETAILS_BULK_DELETED_ERROR,
  RESET,
} from "../../constants/modules/orderDetailsConstants";

const initialState = {
  orderDetails: [],
  error: false,
  loading: false,
  deleteOrderDetail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_ORDER_DETAILS_DOWNLOAD:
    case ADD_ORDER_DETAILS:
    case RETRIEVE_ORDER_DETAILS_BULK_DELETE:
    case BEGIN_EDIT_ORDER_DETAILS:
      return {
        ...state,
        loading: action.payload,
        orderDetail: action.orderDetail,
      };

    case ADD_ORDER_DETAILS_OK:
    case ORDER_DETAILS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_ORDER_DETAILS_ERROR:
    case ORDER_DETAILS_DOWNLOAD_ERROR:
    case ORDER_DETAILS_DELETED_ERROR:
    case ORDER_DETAILS_BULK_DELETED_ERROR:
    case ORDER_DETAILS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ORDER_DETAILS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        orderDetails: action.payload,
      };

    case RETRIEVE_ORDER_DETAILS_DELETE:
      return {
        ...state,
        deleteOrderDetail: action.payload,
      };

    case ORDER_DETAILS_DELETED_OK:
      return {
        ...state,
        orderDetails: state.orderDetails.data.filter(
          (orderDetail) => orderDetail.id !== state.deleteorderDetail
        ),
        deleteOrderDetail: null,
      };

    case RETRIEVE_ORDER_DETAILS_EDIT:
      return {
        ...state,
        editOrderDetail: action.payload,
      };

    case ORDER_DETAILS_EDITED_OK:
      return {
        ...state,
        editOrderDetail: null,
        orderDetails: state.orderDetails.data.map((orderDetail) =>
          orderDetail.id === action.payload.id
            ? (orderDetail = action.payload)
            : orderDetail
        ),
      };

    case RESET:
      return {
        ...state,
        orderDetails: [],
      };

    default:
      return state;
  }
}
