import {
  ADD_USER_ACCESS,
  ADD_USER_ACCESS_OK,
  ADD_USER_ACCESS_ERROR,
  BEGIN_USER_ACCESS_DOWNLOAD,
  USER_ACCESS_DOWNLOAD_OK,
  USER_ACCESS_DOWNLOAD_ERROR,
  RETRIEVE_USER_ACCESS_DELETE,
  USER_ACCESS_DELETED_OK,
  USER_ACCESS_DELETED_ERROR,
  RETRIEVE_USER_ACCESS_EDIT,
  BEGIN_EDIT_USER_ACCESS,
  USER_ACCESS_EDITED_OK,
  USER_ACCESS_EDITED_ERROR,
  RETRIEVE_USER_ACCESS_BULK_DELETE,
  USER_ACCESS_BULK_DELETED_OK,
  USER_ACCESS_BULK_DELETED_ERROR,
  RETRIEVE_USER_ACCESS_BY_ID,
  RETRIEVE_USER_ACCESS_BY_ID_OK,
  RETRIEVE_USER_ACCESS_BY_ID_ERROR,
} from "../../constants/modules/userAccessConstants";

const initialState = {
  userAccesses: [],
  error: false,
  loading: false,
  deleteUserAccess: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_USER_ACCESS_DOWNLOAD:
    case ADD_USER_ACCESS:
    case RETRIEVE_USER_ACCESS_BY_ID:
    case RETRIEVE_USER_ACCESS_BULK_DELETE:
    case BEGIN_EDIT_USER_ACCESS:
      return {
        ...state,
        loading: action.payload,
        userAccess: action.userAccess,
      };

    case ADD_USER_ACCESS_OK:
    case USER_ACCESS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_USER_ACCESS_ERROR:
    case USER_ACCESS_DOWNLOAD_ERROR:
    case RETRIEVE_USER_ACCESS_BY_ID_ERROR:
    case USER_ACCESS_DELETED_ERROR:
    case USER_ACCESS_BULK_DELETED_ERROR:
    case USER_ACCESS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USER_ACCESS_DOWNLOAD_OK:
    case RETRIEVE_USER_ACCESS_BY_ID_OK:
      return {
        ...state,
        loading: false,
        error: null,
        userAccesses: action.payload,
      };

    case RETRIEVE_USER_ACCESS_DELETE:
      return {
        ...state,
        deleteUserAccess: action.payload,
      };

    case USER_ACCESS_DELETED_OK:
      return {
        ...state,
        userAccesses: state.userAccesses.data.filter(
          (userAccess) => userAccess.id !== state.deleteUserAccess
        ),
        deleteUserAccess: null,
      };

    case RETRIEVE_USER_ACCESS_EDIT:
      return {
        ...state,
        editUserAccess: action.payload,
      };

    case USER_ACCESS_EDITED_OK:
      return {
        ...state,
        editUserAccess: null,
        userAccesses: state.userAccesses.data.map((userAccess) =>
          userAccess.id === action.payload.id
            ? (userAccess = action.payload)
            : userAccess
        ),
      };

    default:
      return state;
  }
}
