import {
    ADD_FACTORY_INVOICE_DETAILS,
    ADD_FACTORY_INVOICE_DETAILS_ERROR,
    ADD_FACTORY_INVOICE_DETAILS_OK,
    BEGIN_EDIT_FACTORY_INVOICE_DETAILS, BEGIN_FACTORY_INVOICE_DETAILS_DOWNLOAD, BEGIN_FACTORY_INVOICE_DETAIL_DOWNLOAD, FACTORY_INVOICE_DETAILS_BULK_DELETED_ERROR,
    FACTORY_INVOICE_DETAILS_BULK_DELETED_OK,
    FACTORY_INVOICE_DETAILS_DELETED_ERROR,
    FACTORY_INVOICE_DETAILS_DELETED_OK,
    FACTORY_INVOICE_DETAILS_DOWNLOAD_ERROR,
    FACTORY_INVOICE_DETAILS_DOWNLOAD_OK,
    FACTORY_INVOICE_DETAILS_EDITED_ERROR,
    FACTORY_INVOICE_DETAILS_EDITED_OK,
    FACTORY_INVOICE_DETAIL_DOWNLOAD_ERROR,
    FACTORY_INVOICE_DETAIL_DOWNLOAD_OK, RESET,
    RETRIEVE_FACTORY_INVOICE_DETAILS_BULK_DELETE,
    RETRIEVE_FACTORY_INVOICE_DETAILS_DELETE,
    RETRIEVE_FACTORY_INVOICE_DETAILS_EDIT
} from "../../constants/modules/factoryInvoiceDetailConstant";
  
  const initialState = {
    factoryInvoiceDetails: [],
    getFactoryInvoiceDetail: null,
    factoryInvoiceDetailAddInfo: null,
    error: false,
    loading: false,
    deleteFactoryInvoiceDetail: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_FACTORY_INVOICE_DETAILS_DOWNLOAD:
      case BEGIN_FACTORY_INVOICE_DETAIL_DOWNLOAD:
      case RETRIEVE_FACTORY_INVOICE_DETAILS_BULK_DELETE:
      case ADD_FACTORY_INVOICE_DETAILS:
      case BEGIN_EDIT_FACTORY_INVOICE_DETAILS:
        return {
          ...state,
          loading: action.payload,
          factoryInvoiceDetail: action.factoryInvoiceDetail,
        };
  
      case FACTORY_INVOICE_DETAILS_BULK_DELETED_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_FACTORY_INVOICE_DETAILS_ERROR:
      case FACTORY_INVOICE_DETAILS_DOWNLOAD_ERROR:
      case FACTORY_INVOICE_DETAILS_BULK_DELETED_ERROR:
      case FACTORY_INVOICE_DETAIL_DOWNLOAD_ERROR:
      case FACTORY_INVOICE_DETAILS_DELETED_ERROR:
      case FACTORY_INVOICE_DETAILS_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case FACTORY_INVOICE_DETAILS_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          factoryInvoiceDetails: action.payload,
        };
  
      case FACTORY_INVOICE_DETAIL_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          getFactoryInvoiceDetail: action.payload,
        };
  
      case ADD_FACTORY_INVOICE_DETAILS_OK:
        return {
          ...state,
          loading: false,
          error: null,
          factoryInvoiceDetailAddInfo: action.payload,
        };
  
      case RETRIEVE_FACTORY_INVOICE_DETAILS_DELETE:
        return {
          ...state,
          deleteFactoryInvoiceDetail: action.payload,
        };
  
      case FACTORY_INVOICE_DETAILS_DELETED_OK:
        return {
          ...state,
          factoryInvoiceDetails: state.factoryInvoiceDetails.data.filter(
            (factoryInvoiceDetail) => factoryInvoiceDetail.id !== state.deleteFactoryInvoiceDetail
          ),
          deleteFactoryInvoiceDetail: null,
        };
  
      case RETRIEVE_FACTORY_INVOICE_DETAILS_EDIT:
        return {
          ...state,
          editFactoryInvoiceDetail: action.payload,
        };
  
      case FACTORY_INVOICE_DETAILS_EDITED_OK:
        return {
          ...state,
          editFactoryInvoiceDetails: null,
          factoryInvoiceDetails: state.factoryInvoiceDetails.data.map((factoryInvoiceDetail) =>
            factoryInvoiceDetail.id === action.payload.id
              ? (factoryInvoiceDetail = action.payload)
              : factoryInvoiceDetail
          ),
        };
  
      case RESET:
        return {
          ...state,
          getFactoryInvoiceDetail: null,
          factoryInvoiceDetailAddInfo: null,
        };
  
      default:
        return state;
    }
  }
  