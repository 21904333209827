import axiosClient from "../axiosClient";

export async function bulkDeleteFactoryOrderDetailDB(ids) {
  return await axiosClient.post(`/factory_order_details/bulk_delete`, ids);
}

export async function retrieveFactoryOrderDetailData() {
  return await axiosClient.get("/factory_order_details");
}

export async function retrieveFactoryOrderDetailByFoData(value) {
  var reValue = {
    p_id: value["0"]["@new_id"],
  };
  return await axiosClient.post("/factory_order_details/by_fo", reValue);
}

export async function addFactoryOrderDetailDB(value) {
  return await axiosClient.post("/factory_order_details", value);
}

export async function deleteFactoryOrderDetailDB(id) {
  return await axiosClient.delete(`/factory_order_details/${id}`);
}

export async function editFactoryOrderDetailDB(value) {
  return await axiosClient.put(`/factory_order_details/${value.id}`, value);
}
