export const ADD_BANKS = "ADD_BANKS";
export const ADD_BANKS_OK = "ADD_BANKS_OK";
export const ADD_BANKS_ERROR = "ADD_BANKS_ERROR";

export const BEGIN_BANKS_DOWNLOAD = "BEGIN_BANKS_DOWNLOAD";
export const BANKS_DOWNLOAD_OK = "BANKS_DOWNLOAD_OK";
export const BANKS_DOWNLOAD_ERROR = "BANKS_DOWNLOAD_ERROR";

export const RETRIEVE_BANKS_EDIT = "RETRIEVE_BANKS_EDITED";
export const BEGIN_EDIT_BANKS = "BEGIN_EDIT_BANKS";
export const BANKS_EDITED_OK = "BANKS_EDITED_OK";
export const BANKS_EDITED_ERROR = "BANKS_EDITED_ERROR";

export const RETRIEVE_BANKS_DELETE = "RETRIEVE_BANKS_DELETE";
export const BANKS_DELETED_OK = "BANKS_DELETED_OK";
export const BANKS_DELETED_ERROR = "BANKS_DELETED_ERROR";

export const RETRIEVE_BANKS_BULK_DELETE = "RETRIEVE_BANKS_BULK_DELETE";
export const BANKS_BULK_DELETED_OK = "BANKS_BULK_DELETED_OK";
export const BANKS_BULK_DELETED_ERROR = "BANKS_BULK_DELETED_ERROR";
