import {
  ADD_SALES_CONTRACTS,
  ADD_SALES_CONTRACTS_ERROR,
  ADD_SALES_CONTRACTS_OK,
  BEGIN_EDIT_SALES_CONTRACTS,
  BEGIN_SALES_CONTRACTS_DOWNLOAD,
  BEGIN_SALES_CONTRACT_DOWNLOAD,
  RESET,
  RETRIEVE_SALES_CONTRACTS_BULK_DELETE,
  RETRIEVE_SALES_CONTRACTS_DELETE,
  RETRIEVE_SALES_CONTRACTS_EDIT,
  SALES_CONTRACTS_BULK_DELETED_ERROR,
  SALES_CONTRACTS_BULK_DELETED_OK,
  SALES_CONTRACTS_DELETED_ERROR,
  SALES_CONTRACTS_DELETED_OK,
  SALES_CONTRACTS_DOWNLOAD_ERROR,
  SALES_CONTRACTS_DOWNLOAD_OK,
  SALES_CONTRACTS_EDITED_ERROR,
  SALES_CONTRACTS_EDITED_OK,
  SALES_CONTRACT_DOWNLOAD_ERROR,
  SALES_CONTRACT_DOWNLOAD_OK
} from "../../constants/modules/salesContractConstants";

const initialState = {
  salesContracts: [],
  getSalesContract: null,
  salesContractAddInfo: null,
  error: false,
  loading: false,
  deleteSalesContract: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_SALES_CONTRACTS_DOWNLOAD:
    case BEGIN_SALES_CONTRACT_DOWNLOAD:
    case RETRIEVE_SALES_CONTRACTS_BULK_DELETE:
    case ADD_SALES_CONTRACTS:
    case BEGIN_EDIT_SALES_CONTRACTS:
      return {
        ...state,
        loading: action.payload,
        salesContract: action.salesContract,
      };

    case SALES_CONTRACTS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_SALES_CONTRACTS_ERROR:
    case SALES_CONTRACTS_DOWNLOAD_ERROR:
    case SALES_CONTRACTS_BULK_DELETED_ERROR:
    case SALES_CONTRACT_DOWNLOAD_ERROR:
    case SALES_CONTRACTS_DELETED_ERROR:
    case SALES_CONTRACTS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SALES_CONTRACTS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        salesContracts: action.payload,
      };

    case SALES_CONTRACT_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        getSalesContract: action.payload,
      };

    case ADD_SALES_CONTRACTS_OK:
      return {
        ...state,
        loading: false,
        error: null,
        salesContractAddInfo: action.payload,
      };

    case RETRIEVE_SALES_CONTRACTS_DELETE:
      return {
        ...state,
        deleteSalesContract: action.payload,
      };

    case SALES_CONTRACTS_DELETED_OK:
      return {
        ...state,
        salesContracts: state.salesContracts.data.filter(
          (salesContract) => salesContract.id !== state.deleteSalesContract
        ),
        deleteSalesContract: null,
      };

    case RETRIEVE_SALES_CONTRACTS_EDIT:
      return {
        ...state,
        editSalesContract: action.payload,
      };

    case SALES_CONTRACTS_EDITED_OK:
      return {
        ...state,
        editSalesContract: null,
        salesContracts: state.salesContracts.data.map((salesContract) =>
          salesContract.id === action.payload.id
            ? (salesContract = action.payload)
            : salesContract
        ),
      };

    case RESET:
      return {
        ...state,
        getSalesContract: null,
        salesContractAddInfo: null,
      };

    default:
      return state;
  }
}
