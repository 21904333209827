import axiosClient from "../axiosClient";

export async function bulkDeleteTeamDB(ids) {
  return await axiosClient.post(`/teams/bulk_delete`, ids);
}

export async function retrieveTeamsData() {
  return await axiosClient.get("/teams");
}

export async function retrieveTeamsByBuyerData(id) {
  let reId = {
    id: id,
  };
  return await axiosClient.post("/teams/get_by_id", reId);
}

export async function addTeamDB(value) {
  return await axiosClient.post("/teams", value);
}

export async function deleteTeamDB(id) {
  return await axiosClient.delete(`/teams/${id}`);
}

export async function editTeamDB(value) {
  return await axiosClient.put(`/teams/${value.id}`, value);
}
