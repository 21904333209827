import {
  ADD_TNA_HEADER,
  ADD_TNA_HEADER_ERROR,
  ADD_TNA_HEADER_OK,
  BEGIN_EDIT_TNA_HEADER,
  BEGIN_TNA_HEADER_DOWNLOAD,
  RETRIEVE_TNA_HEADER_BULK_DELETE,
  RETRIEVE_TNA_HEADER_DELETE,
  RETRIEVE_TNA_HEADER_EDIT,
  TNA_HEADER_BULK_DELETED_ERROR,
  TNA_HEADER_BULK_DELETED_OK,
  TNA_HEADER_DELETED_ERROR,
  TNA_HEADER_DELETED_OK,
  TNA_HEADER_DOWNLOAD_ERROR,
  TNA_HEADER_DOWNLOAD_OK,
  TNA_HEADER_EDITED_ERROR,
  TNA_HEADER_EDITED_OK,
  BEGIN_TNA_HEADER_BY_ID_DOWNLOAD,
  TNA_HEADER_BY_ID_DOWNLOAD_OK,
  TNA_HEADER_BY_ID_DOWNLOAD_ERROR,
  BEGIN_TNA_FACTORY_BY_ORDER_DOWNLOAD,
  TNA_FACTORY_BY_ORDER_DOWNLOAD_OK,
  TNA_FACTORY_BY_ORDER_DOWNLOAD_ERROR,
  BEGIN_TNA_STYLE_BY_FACTORY_DOWNLOAD,
  TNA_STYLE_BY_FACTORY_DOWNLOAD_OK,
  TNA_STYLE_BY_FACTORY_DOWNLOAD_ERROR,
  BEGIN_TNA_TEMPLATE_BY_BUYER_DOWNLOAD,
  TNA_TEMPLATE_BY_BUYER_DOWNLOAD_OK,
  TNA_TEMPLATE_BY_BUYER_DOWNLOAD_ERROR,
  RESET,
} from "../../constants/modules/tnaPlanHeaderConstants";

export const bulkDeleteTnaHeaderAction = (item) => ({
  type: RETRIEVE_TNA_HEADER_BULK_DELETE,
  payload: true,
  tnaHeader: item,
});

export const bulkDeleteTnaHeaderOkAction = () => ({
  type: TNA_HEADER_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteTnaHeaderErrorAction = (state) => ({
  type: TNA_HEADER_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadTnaHeadersAction = (item) => ({
  type: BEGIN_TNA_HEADER_DOWNLOAD,
  payload: true,
  tnaHeader: item,
});

export const downloadTnaHeadersOkAction = (items) => ({
  type: TNA_HEADER_DOWNLOAD_OK,
  payload: items,
});

export const downloadTnaHeadersErrorAction = () => ({
  type: TNA_HEADER_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadTnaHeaderByIdAction = (item) => ({
  type: BEGIN_TNA_HEADER_BY_ID_DOWNLOAD,
  payload: true,
  tnaHeader: item,
});

export const downloadTnaHeaderByIdOkAction = (items) => ({
  type: TNA_HEADER_BY_ID_DOWNLOAD_OK,
  payload: items,
});

export const downloadTnaHeaderByIdErrorAction = () => ({
  type: TNA_HEADER_BY_ID_DOWNLOAD_ERROR,
  payload: true,
});

export const addTnaHeaderAction = (item) => ({
  type: ADD_TNA_HEADER,
  payload: true,
  tnaHeader: item,
});

export const addTnaHeaderOkAction = (items) => ({
  type: ADD_TNA_HEADER_OK,
  payload: items,
});

export const addTnaHeaderErrorAction = (state) => ({
  type: ADD_TNA_HEADER_ERROR,
  payload: state,
});

export const deleteTnaHeaderAction = (id) => ({
  type: RETRIEVE_TNA_HEADER_DELETE,
  payload: id,
});

export const deleteTnaHeaderOkAction = () => ({
  type: TNA_HEADER_DELETED_OK,
});

export const deleteTnaHeaderErrorAction = () => ({
  type: TNA_HEADER_DELETED_ERROR,
  payload: true,
});

export const retrieveTnaHeaderAction = (item) => ({
  type: RETRIEVE_TNA_HEADER_EDIT,
  payload: item,
});

export const editTnaHeaderAction = (item) => ({
  type: BEGIN_EDIT_TNA_HEADER,
  tnaHeader: item,
});

export const editTnaHeaderOkAction = (item) => ({
  type: TNA_HEADER_EDITED_OK,
  payload: item,
});

export const editTnaHeaderErrorAction = () => ({
  type: TNA_HEADER_EDITED_ERROR,
  payload: true,
});

//factory by order

export const downloadTnaFactoryByOrderAction = (item) => ({
  type: BEGIN_TNA_FACTORY_BY_ORDER_DOWNLOAD,
  payload: true,
  tnaHeader: item,
});

export const downloadTnaFactoryByOrderOkAction = (items) => ({
  type: TNA_FACTORY_BY_ORDER_DOWNLOAD_OK,
  payload: items,
});

export const downloadTnaFactoryByOrderErrorAction = () => ({
  type: TNA_FACTORY_BY_ORDER_DOWNLOAD_ERROR,
  payload: true,
});

//style by factory

export const downloadTnaStyleByFactoryAction = (item) => ({
  type: BEGIN_TNA_STYLE_BY_FACTORY_DOWNLOAD,
  payload: true,
  tnaHeader: item,
});

export const downloadTnaStyleByFactoryOkAction = (items) => ({
  type: TNA_STYLE_BY_FACTORY_DOWNLOAD_OK,
  payload: items,
});

export const downloadTnaStyleByFactoryErrorAction = () => ({
  type: TNA_STYLE_BY_FACTORY_DOWNLOAD_ERROR,
  payload: true,
});

//template by buyer

export const downloadTnaTemplateByBuyerAction = (item) => ({
  type: BEGIN_TNA_TEMPLATE_BY_BUYER_DOWNLOAD,
  payload: true,
  tnaHeader: item,
});

export const downloadTnaTemplateByBuyerOkAction = (items) => ({
  type: TNA_TEMPLATE_BY_BUYER_DOWNLOAD_OK,
  payload: items,
});

export const downloadTnaTemplateByBuyerErrorAction = () => ({
  type: TNA_TEMPLATE_BY_BUYER_DOWNLOAD_ERROR,
  payload: true,
});

export const TnaHeaderReset = () => ({
  type: RESET,
  payload: true,
});
