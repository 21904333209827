import {
  ADD_SAMPLE_STYLES,
  ADD_SAMPLE_STYLES_ERROR,
  ADD_SAMPLE_STYLES_OK,
  BEGIN_EDIT_SAMPLE_STYLES,
  BEGIN_SAMPLE_STYLES_DOWNLOAD,
  RETRIEVE_SAMPLE_STYLES_BULK_DELETE,
  RETRIEVE_SAMPLE_STYLES_DELETE,
  RETRIEVE_SAMPLE_STYLES_EDIT,
  SAMPLE_STYLES_BULK_DELETED_ERROR,
  SAMPLE_STYLES_BULK_DELETED_OK,
  SAMPLE_STYLES_DELETED_ERROR,
  SAMPLE_STYLES_DELETED_OK,
  SAMPLE_STYLES_DOWNLOAD_ERROR,
  SAMPLE_STYLES_DOWNLOAD_OK,
  SAMPLE_STYLES_EDITED_ERROR,
  SAMPLE_STYLES_EDITED_OK,
} from "../../constants/modules/sampleStylesConstant";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteSampleStyleAction = (item) => ({
  type: RETRIEVE_SAMPLE_STYLES_BULK_DELETE,
  payload: true,
  sampleStyle: item,
});

export const bulkDeleteSampleStyleOkAction = () => ({
  type: SAMPLE_STYLES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteSampleStyleErrorAction = (state) => ({
  type: SAMPLE_STYLES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadSampleStylesAction = () => ({
  type: BEGIN_SAMPLE_STYLES_DOWNLOAD,
  payload: true,
});

export const downloadSampleStylesOkAction = (items) => ({
  type: SAMPLE_STYLES_DOWNLOAD_OK,
  payload: items,
});

export const downloadSampleStylesErrorAction = () => ({
  type: SAMPLE_STYLES_DOWNLOAD_ERROR,
  payload: true,
});

export const addSampleStyleAction = (item) => ({
  type: ADD_SAMPLE_STYLES,
  payload: true,
  sampleStyle: item,
});

export const addSampleStyleOkAction = () => ({
  type: ADD_SAMPLE_STYLES_OK,
  payload: true,
});

export const addSampleStyleErrorAction = (state) => ({
  type: ADD_SAMPLE_STYLES_ERROR,
  payload: state,
});

export const deleteSampleStyleAction = (id) => ({
  type: RETRIEVE_SAMPLE_STYLES_DELETE,
  payload: id,
});

export const deleteSampleStyleOkAction = () => ({
  type: SAMPLE_STYLES_DELETED_OK,
});

export const deleteSampleStyleErrorAction = () => ({
  type: SAMPLE_STYLES_DELETED_ERROR,
  payload: true,
});

export const retrieveSampleStyleAction = (item) => ({
  type: RETRIEVE_SAMPLE_STYLES_EDIT,
  payload: item,
});

export const editSampleStyleAction = (item) => ({
  type: BEGIN_EDIT_SAMPLE_STYLES,
  sampleStyle: item,
});

export const editSampleStyleOkAction = (item) => ({
  type: SAMPLE_STYLES_EDITED_OK,
  payload: item,
});

export const editSampleStyleErrorAction = () => ({
  type: SAMPLE_STYLES_EDITED_ERROR,
  payload: true,
});
