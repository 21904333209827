import {
  ADD_BUYERS,
  ADD_BUYERS_OK,
  ADD_BUYERS_ERROR,
  BEGIN_BUYERS_DOWNLOAD,
  BUYERS_DOWNLOAD_OK,
  BUYERS_DOWNLOAD_ERROR,
  RETRIEVE_BUYERS_DELETE,
  BUYERS_DELETED_OK,
  BUYERS_DELETED_ERROR,
  RETRIEVE_BUYERS_EDIT,
  BEGIN_EDIT_BUYERS,
  BUYERS_EDITED_OK,
  BUYERS_EDITED_ERROR,
  RETRIEVE_BUYERS_BULK_DELETE,
  BUYERS_BULK_DELETED_OK,
  BUYERS_BULK_DELETED_ERROR,
} from "../../constants/modules/buyersConstants";

export const downloadBuyersAction = () => ({
  type: BEGIN_BUYERS_DOWNLOAD,
  payload: true,
});

export const downloadBuyersOkAction = (items) => ({
  type: BUYERS_DOWNLOAD_OK,
  payload: items,
});

export const downloadBuyersErrorAction = () => ({
  type: BUYERS_DOWNLOAD_ERROR,
  payload: true,
});

export const addBuyerAction = (item) => ({
  type: ADD_BUYERS,
  payload: true,
  buyer: item,
});

export const addBuyerOkAction = () => ({
  type: ADD_BUYERS_OK,
  payload: true,
});

export const addBuyerErrorAction = (state) => ({
  type: ADD_BUYERS_ERROR,
  payload: state,
});

export const deleteBuyerAction = (id) => ({
  type: RETRIEVE_BUYERS_DELETE,
  payload: id,
});

export const deleteBuyerOkAction = () => ({
  type: BUYERS_DELETED_OK,
});

export const deleteBuyerErrorAction = () => ({
  type: BUYERS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteBuyerAction = (item) => ({
  type: RETRIEVE_BUYERS_BULK_DELETE,
  payload: true,
  buyer: item,
});

export const bulkDeleteBuyerOkAction = () => ({
  type: BUYERS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteBuyerErrorAction = (state) => ({
  type: BUYERS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveBuyerAction = (item) => ({
  type: RETRIEVE_BUYERS_EDIT,
  payload: item,
});

export const editBuyerAction = (item) => ({
  type: BEGIN_EDIT_BUYERS,
  buyer: item,
});

export const editBuyerOkAction = (item) => ({
  type: BUYERS_EDITED_OK,
  payload: item,
});

export const editBuyerErrorAction = () => ({
  type: BUYERS_EDITED_ERROR,
  payload: true,
});
