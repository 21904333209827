export const ADD_SAMPLE_COMMENTS = "ADD_SAMPLE_COMMENTS";
export const ADD_SAMPLE_COMMENTS_OK = "ADD_SAMPLE_COMMENTS_OK";
export const ADD_SAMPLE_COMMENTS_ERROR = "ADD_SAMPLE_COMMENTS_ERROR";

export const BEGIN_SAMPLE_COMMENTS_DOWNLOAD = "BEGIN_SAMPLE_COMMENTS_DOWNLOAD";
export const SAMPLE_COMMENTS_DOWNLOAD_OK = "SAMPLE_COMMENTS_DOWNLOAD_OK";
export const SAMPLE_COMMENTS_DOWNLOAD_ERROR = "SAMPLE_COMMENTS_DOWNLOAD_ERROR";

export const RETRIEVE_SAMPLE_COMMENTS_DELETE = "RETRIEVE_SAMPLE_COMMENTS_DELETE";
export const SAMPLE_COMMENTS_DELETED_OK = "SAMPLE_COMMENTS_DELETED_OK";
export const SAMPLE_COMMENTS_DELETED_ERROR = "SAMPLE_COMMENTS_DELETED_ERROR";

export const RETRIEVE_SAMPLE_COMMENTS_EDIT = "RETRIEVE_SAMPLE_COMMENTS_EDITED";
export const BEGIN_EDIT_SAMPLE_COMMENTS = "BEGIN_EDIT_SAMPLE_COMMENTS";
export const SAMPLE_COMMENTS_EDITED_OK = "SAMPLE_COMMENTS_EDITED_OK";
export const SAMPLE_COMMENTS_EDITED_ERROR = "SAMPLE_COMMENTS_EDITED_ERROR";

export const RETRIEVE_SAMPLE_COMMENTS_BULK_DELETE = "RETRIEVE_SAMPLE_COMMENTS_BULK_DELETE";
export const SAMPLE_COMMENTS_BULK_DELETED_OK = "SAMPLE_COMMENTS_BULK_DELETED_OK";
export const SAMPLE_COMMENTS_BULK_DELETED_ERROR = "SAMPLE_COMMENTS_BULK_DELETED_ERROR";