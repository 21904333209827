import { ADD_LC_HEADER_DETAILS, ADD_LC_HEADER_DETAILS_ERROR, ADD_LC_HEADER_DETAILS_OK, BEGIN_EDIT_LC_HEADER_DETAILS, BEGIN_LC_HEADER_DETAILS_DOWNLOAD, LC_HEADER_DETAILS_DELETED_ERROR, LC_HEADER_DETAILS_DELETED_OK, LC_HEADER_DETAILS_DOWNLOAD_ERROR, LC_HEADER_DETAILS_DOWNLOAD_OK, LC_HEADER_DETAILS_EDITED_ERROR, LC_HEADER_DETAILS_EDITED_OK, RETRIEVE_LC_HEADER_DETAILS_DELETE, RETRIEVE_LC_HEADER_DETAILS_EDIT } from "../../constants/modules/lcHeaderDetailsConstants";
  
  export const downloadLCHeaderDetailsAction = () => ({
    type: BEGIN_LC_HEADER_DETAILS_DOWNLOAD,
    payload: true,
  });
  
  export const downloadLCHeaderDetailsOkAction = (items) => ({
    type: LC_HEADER_DETAILS_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadLCHeaderDetailsErrorAction = () => ({
    type: LC_HEADER_DETAILS_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const addLCHeaderDetailAction = (item) => ({
    type: ADD_LC_HEADER_DETAILS,
    payload: true,
    lcHeaderDetail: item,
  });
  
  export const addLCHeaderDetailOkAction = () => ({
    type: ADD_LC_HEADER_DETAILS_OK,
    payload: true,
  });
  
  export const addLCHeaderDetailErrorAction = (state) => ({
    type: ADD_LC_HEADER_DETAILS_ERROR,
    payload: state,
  });
  
  export const deleteLCHeaderDetailAction = (id) => ({
    type: RETRIEVE_LC_HEADER_DETAILS_DELETE,
    payload: id,
  });
  
  export const deleteLCHeaderDetailOkAction = () => ({
    type: LC_HEADER_DETAILS_DELETED_OK,
  });
  
  export const deleteLCHeaderDetailErrorAction = () => ({
    type: LC_HEADER_DETAILS_DELETED_ERROR,
    payload: true,
  });
  
  export const retrieveLCHeaderDetailAction = (item) => ({
    type: RETRIEVE_LC_HEADER_DETAILS_EDIT,
    payload: item,
  });
  
  export const editLCHeaderDetailAction = (item) => ({
    type: BEGIN_EDIT_LC_HEADER_DETAILS,
    lcHeaderDetail: item,
  });
  
  export const editLCHeaderDetailOkAction = (item) => ({
    type: LC_HEADER_DETAILS_EDITED_OK,
    payload: item,
  });
  
  export const editLCHeaderDetailErrorAction = () => ({
    type: LC_HEADER_DETAILS_EDITED_ERROR,
    payload: true,
  });
  