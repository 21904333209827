import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*               CATEGORIES API CALLS               */
/*-------------------------------------------------*/

export async function retrieveCategoriesData() {
    return await axiosClient.get("/categories");
  }

export async function addCategoryDB(value) {
    var bodyFormData = new FormData();
    
    bodyFormData.append("category_name", value.category_name);
    bodyFormData.append("department_id", value.department_id);
    bodyFormData.append("description", value.description);
    bodyFormData.append("active_status", value.active_status);
    bodyFormData.append("logo", value.logo);
    bodyFormData.append("print_serail", value.print_serail);
    bodyFormData.append("created_by", value.created_by);
    bodyFormData.append("created_date", value.created_date);
    bodyFormData.append("modified_by", value.modified_by);
    bodyFormData.append("modified_date", value.modified_date);
    
  
    return await axiosClient.post("/categories", bodyFormData);
  }
  
  export async function deleteCategoryDB(id) {
    return await axiosClient.delete(`/categories/${id}`);
  }
  
  export async function bulkDeleteCategoryDB(ids) {
    return await axiosClient.post(`/categories/bulk_delete`, ids);
  }
  
  export async function editCategoryDB(value) {
    var bodyFormData = new FormData();
  
    bodyFormData.append("category_name", value.category_name);
    bodyFormData.append("department_id", value.department_id);
    bodyFormData.append("description", value.description);
    bodyFormData.append("active_status", value.active_status);
    bodyFormData.append("logo", value.logo);
    bodyFormData.append("print_serail", value.print_serail);
    bodyFormData.append("created_by", value.created_by);
    bodyFormData.append("created_date", value.created_date);
    bodyFormData.append("modified_by", value.modified_by);
    bodyFormData.append("modified_date", value.modified_date);
    
  
    return await axiosClient.put(`/categories/${value.id}`, bodyFormData);
  }
  