import {
  ADD_CATEGORIES,
  ADD_CATEGORIES_ERROR,
  ADD_CATEGORIES_OK,
  BEGIN_CATEGORIES_DOWNLOAD,
  BEGIN_EDIT_CATEGORIES,
  CATEGORIES_BULK_DELETED_ERROR,
  CATEGORIES_BULK_DELETED_OK,
  CATEGORIES_DELETED_ERROR,
  CATEGORIES_DELETED_OK,
  CATEGORIES_DOWNLOAD_ERROR,
  CATEGORIES_DOWNLOAD_OK,
  CATEGORIES_EDITED_ERROR,
  CATEGORIES_EDITED_OK,
  RETRIEVE_CATEGORIES_BULK_DELETE,
  RETRIEVE_CATEGORIES_DELETE,
  RETRIEVE_CATEGORIES_EDIT,
} from "../../constants/modules/categoriesConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteCategoryAction = (item) => ({
  type: RETRIEVE_CATEGORIES_BULK_DELETE,
  payload: true,
  category: item,
});

export const bulkDeleteCategoryOkAction = () => ({
  type: CATEGORIES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteCategoryErrorAction = (state) => ({
  type: CATEGORIES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadCategoriesAction = () => ({
  type: BEGIN_CATEGORIES_DOWNLOAD,
  payload: true,
});

export const downloadCategoriesOkAction = (items) => ({
  type: CATEGORIES_DOWNLOAD_OK,
  payload: items,
});

export const downloadCategoriesErrorAction = () => ({
  type: CATEGORIES_DOWNLOAD_ERROR,
  payload: true,
});

export const addCategoryAction = (item) => ({
  type: ADD_CATEGORIES,
  payload: true,
  category: item,
});

export const addCategoryOkAction = () => ({
  type: ADD_CATEGORIES_OK,
  payload: true,
});

export const addCategoryErrorAction = (state) => ({
  type: ADD_CATEGORIES_ERROR,
  payload: state,
});

export const deleteCategoryAction = (id) => ({
  type: RETRIEVE_CATEGORIES_DELETE,
  payload: id,
});

export const deleteCategoryOkAction = () => ({
  type: CATEGORIES_DELETED_OK,
});

export const deleteCategoryErrorAction = () => ({
  type: CATEGORIES_DELETED_ERROR,
  payload: true,
});

export const retrieveCategoryAction = (item) => ({
  type: RETRIEVE_CATEGORIES_EDIT,
  payload: item,
});

export const editCategoryAction = (item) => ({
  type: BEGIN_EDIT_CATEGORIES,
  category: item,
});

export const editCategoryOkAction = (item) => ({
  type: CATEGORIES_EDITED_OK,
  payload: item,
});

export const editCategoryErrorAction = () => ({
  type: CATEGORIES_EDITED_ERROR,
  payload: true,
});
