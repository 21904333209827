import {
  ADD_SEASONS, ADD_SEASONS_ERROR, ADD_SEASONS_OK, BEGIN_EDIT_SEASONS, BEGIN_SEASONS_DOWNLOAD, RETRIEVE_SEASONS_BULK_DELETE, RETRIEVE_SEASONS_DELETE, RETRIEVE_SEASONS_EDIT, SEASONS_BULK_DELETED_ERROR, SEASONS_BULK_DELETED_OK, SEASONS_DELETED_ERROR, SEASONS_DELETED_OK, SEASONS_DOWNLOAD_ERROR, SEASONS_DOWNLOAD_OK, SEASONS_EDITED_ERROR, SEASONS_EDITED_OK
} from "../../constants/modules/seasonsConstants";
  
 
/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteSeasonAction = (item) => ({
  type: RETRIEVE_SEASONS_BULK_DELETE,
  payload: true,
  season: item,
});

export const bulkDeleteSeasonOkAction = () => ({
  type: SEASONS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteSeasonErrorAction = (state) => ({
  type: SEASONS_BULK_DELETED_ERROR,
  payload: state,
});



  export const downloadSeasonsAction = () => ({
    type: BEGIN_SEASONS_DOWNLOAD,
    payload: true,
  });
  
  export const downloadSeasonsOkAction = (items) => ({
    type: SEASONS_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadSeasonsErrorAction = () => ({
    type: SEASONS_DOWNLOAD_ERROR,
    payload: true,
  });
  
  
  export const addSeasonAction = (item) => ({
    type: ADD_SEASONS,
    payload: true,
    season: item,
  });
  
  export const addSeasonOkAction = () => ({
    type: ADD_SEASONS_OK,
    payload: true,
  });
  
  export const addSeasonErrorAction = (state) => ({
    type: ADD_SEASONS_ERROR,
    payload: state,
  });
  
  
  export const deleteSeasonAction = (id) => ({
    type: RETRIEVE_SEASONS_DELETE,
    payload: id,
  });
  
  export const deleteSeasonOkAction = () => ({
    type: SEASONS_DELETED_OK,
  });
  
  export const deleteSeasonErrorAction = () => ({
    type: SEASONS_DELETED_ERROR,
    payload: true,
  });
  
  
  export const retrieveSeasonAction = (item) => ({
    type: RETRIEVE_SEASONS_EDIT,
    payload: item,
  });
  
  export const editSeasonAction = (item) => ({
    type: BEGIN_EDIT_SEASONS,
    season: item,
  });
  
  export const editSeasonOkAction = (item) => ({
    type: SEASONS_EDITED_OK,
    payload: item,
  });
  
  export const editSeasonErrorAction = () => ({
    type: SEASONS_EDITED_ERROR,
    payload: true,
  });
  
  