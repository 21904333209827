export const ADD_SAMPLE_TYPES = "ADD_SAMPLE_TYPES";
export const ADD_SAMPLE_TYPES_OK = "ADD_SAMPLE_TYPES_OK";
export const ADD_SAMPLE_TYPES_ERROR = "ADD_SAMPLE_TYPES_ERROR";

export const BEGIN_SAMPLE_TYPES_DOWNLOAD = "BEGIN_SAMPLE_TYPES_DOWNLOAD";
export const SAMPLE_TYPES_DOWNLOAD_OK = "SAMPLE_TYPES_DOWNLOAD_OK";
export const SAMPLE_TYPES_DOWNLOAD_ERROR = "SAMPLE_TYPES_DOWNLOAD_ERROR";

export const RETRIEVE_SAMPLE_TYPES_DELETE = "RETRIEVE_SAMPLE_TYPES_DELETE";
export const SAMPLE_TYPES_DELETED_OK = "SAMPLE_TYPES_DELETED_OK";
export const SAMPLE_TYPES_DELETED_ERROR = "SAMPLE_TYPES_DELETED_ERROR";

export const RETRIEVE_SAMPLE_TYPES_EDIT = "RETRIEVE_SAMPLE_TYPES_EDITED";
export const BEGIN_EDIT_SAMPLE_TYPES = "BEGIN_EDIT_SAMPLE_TYPES";
export const SAMPLE_TYPES_EDITED_OK = "SAMPLE_TYPES_EDITED_OK";
export const SAMPLE_TYPES_EDITED_ERROR = "SAMPLE_TYPES_EDITED_ERROR";

export const RETRIEVE_SAMPLE_TYPES_BULK_DELETE = "RETRIEVE_SAMPLE_TYPES_BULK_DELETE";
export const SAMPLE_TYPES_BULK_DELETED_OK = "SAMPLE_TYPES_BULK_DELETED_OK";
export const SAMPLE_TYPES_BULK_DELETED_ERROR = "SAMPLE_TYPES_BULK_DELETED_ERROR";