export const ADD_COMMISSION_INVOICES = "ADD_COMMISSION_INVOICES";
export const ADD_COMMISSION_INVOICES_OK = "ADD_COMMISSION_INVOICES_OK";
export const ADD_COMMISSION_INVOICES_ERROR = "ADD_COMMISSION_INVOICES_ERROR";

export const BEGIN_COMMISSION_INVOICES_DOWNLOAD = "BEGIN_COMMISSION_INVOICES_DOWNLOAD";
export const COMMISSION_INVOICES_DOWNLOAD_OK = "COMMISSION_INVOICES_DOWNLOAD_OK";
export const COMMISSION_INVOICES_DOWNLOAD_ERROR = "COMMISSION_INVOICES_DOWNLOAD_ERROR";

export const RETRIEVE_COMMISSION_INVOICES_DELETE = "RETRIEVE_COMMISSION_INVOICES_DELETE";
export const COMMISSION_INVOICES_DELETED_OK = "COMMISSION_INVOICES_DELETED_OK";
export const COMMISSION_INVOICES_DELETED_ERROR = "COMMISSION_INVOICES_DELETED_ERROR";

export const RETRIEVE_COMMISSION_INVOICES_EDIT = "RETRIEVE_COMMISSION_INVOICES_EDITED";
export const BEGIN_EDIT_COMMISSION_INVOICES = "BEGIN_EDIT_COMMISSION_INVOICES";
export const COMMISSION_INVOICES_EDITED_OK = "COMMISSION_INVOICES_EDITED_OK";
export const COMMISSION_INVOICES_EDITED_ERROR = "COMMISSION_INVOICES_EDITED_ERROR";


export const RETRIEVE_COMMISSION_INVOICES_BULK_DELETE = "RETRIEVE_COMMISSION_INVOICES_BULK_DELETE";
export const COMMISSION_INVOICES_BULK_DELETED_OK = "COMMISSION_INVOICES_BULK_DELETED_OK";
export const COMMISSION_INVOICES_BULK_DELETED_ERROR = "COMMISSION_INVOICES_BULK_DELETED_ERROR";