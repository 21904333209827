import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*            ProceedRealization API CALLS         */
/*-------------------------------------------------*/

export async function retrieveProceedRealizationsData() {
  return await axiosClient.post("/proceed_realizations/get_all");
}

export async function retrieveProceedRealizationData(value) {
  return await axiosClient.post("/proceed_realizations", value);
}

export async function addProceedRealizationDB(value) {
  return await axiosClient.post("/proceed_realizations/create", value);
}

export async function deleteProceedRealizationDB(id) {
  return await axiosClient.delete(`/proceed_realizations/${id}`);
}

export async function editProceedRealizationDB(value) {
  return await axiosClient.put(`/proceed_realizations/${value.id}`, value);
}
