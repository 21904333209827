import {
  ADD_CATEGORIES,
  ADD_CATEGORIES_ERROR,
  ADD_CATEGORIES_OK,
  BEGIN_CATEGORIES_DOWNLOAD,
  BEGIN_EDIT_CATEGORIES,
  CATEGORIES_BULK_DELETED_ERROR,
  CATEGORIES_BULK_DELETED_OK,
  CATEGORIES_DELETED_ERROR,
  CATEGORIES_DELETED_OK,
  CATEGORIES_DOWNLOAD_ERROR,
  CATEGORIES_DOWNLOAD_OK,
  CATEGORIES_EDITED_ERROR,
  CATEGORIES_EDITED_OK,
  RETRIEVE_CATEGORIES_BULK_DELETE,
  RETRIEVE_CATEGORIES_DELETE,
  RETRIEVE_CATEGORIES_EDIT,
} from "../../constants/modules/categoriesConstants";

const initialState = {
  categories: [],
  error: false,
  loading: false,
  deleteCategory: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_CATEGORIES_DOWNLOAD:
    case RETRIEVE_CATEGORIES_BULK_DELETE:
    case ADD_CATEGORIES:
    case BEGIN_EDIT_CATEGORIES:
      return {
        ...state,
        loading: action.payload,
        category: action.category,
      };

    case ADD_CATEGORIES_OK:
    case CATEGORIES_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_CATEGORIES_ERROR:
    case CATEGORIES_DOWNLOAD_ERROR:
    case CATEGORIES_BULK_DELETED_ERROR:
    case CATEGORIES_DELETED_ERROR:
    case CATEGORIES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CATEGORIES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        categories: action.payload,
      };
      
    case RETRIEVE_CATEGORIES_DELETE:
      return {
        ...state,
        deleteCategory: action.payload,
      };

    case CATEGORIES_DELETED_OK:
      return {
        ...state,
        categories: state.categories.data.filter(
          (category) => category.id !== state.deleteCategory
        ),
        deleteCategory: null,
      };

    case RETRIEVE_CATEGORIES_EDIT:
      return {
        ...state,
        editCategory: action.payload,
      };

    case CATEGORIES_EDITED_OK:
      return {
        ...state,
        editCategory: null,
        categories: state.categories.data.map((category) =>
          category.id === action.payload.id
            ? (category = action.payload)
            : category
        ),
      };

    default:
      return state;
  }
}
