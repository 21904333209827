export const ADD_APPROVAL_PANEL_MEMBERS = "ADD_APPROVAL_PANEL_MEMBERS";
export const ADD_APPROVAL_PANEL_MEMBERS_OK = "ADD_APPROVAL_PANEL_MEMBERS_OK";
export const ADD_APPROVAL_PANEL_MEMBERS_ERROR = "ADD_APPROVAL_PANEL_MEMBERS_ERROR";

export const BEGIN_APPROVAL_PANEL_MEMBERS_DOWNLOAD = "BEGIN_APPROVAL_PANEL_MEMBERS_DOWNLOAD";
export const APPROVAL_PANEL_MEMBERS_DOWNLOAD_OK = "APPROVAL_PANEL_MEMBERS_DOWNLOAD_OK";
export const APPROVAL_PANEL_MEMBERS_DOWNLOAD_ERROR = "APPROVAL_PANEL_MEMBERS_DOWNLOAD_ERROR";

export const RETRIEVE_APPROVAL_PANEL_MEMBERS_DELETE = "RETRIEVE_APPROVAL_PANEL_MEMBERS_DELETE";
export const APPROVAL_PANEL_MEMBERS_DELETED_OK = "APPROVAL_PANEL_MEMBERS_DELETED_OK";
export const APPROVAL_PANEL_MEMBERS_DELETED_ERROR = "APPROVAL_PANEL_MEMBERS_DELETED_ERROR";

export const RETRIEVE_APPROVAL_PANEL_MEMBERS_EDIT = "RETRIEVE_APPROVAL_PANEL_MEMBERS_EDITED";
export const BEGIN_EDIT_APPROVAL_PANEL_MEMBERS = "BEGIN_EDIT_APPROVAL_PANEL_MEMBERS";
export const APPROVAL_PANEL_MEMBERS_EDITED_OK = "APPROVAL_PANEL_MEMBERS_EDITED_OK";
export const APPROVAL_PANEL_MEMBERS_EDITED_ERROR = "APPROVAL_PANEL_MEMBERS_EDITED_ERROR";

export const RETRIEVE_APPROVAL_PANEL_MEMBERS_BULK_DELETE = "RETRIEVE_APPROVAL_PANEL_MEMBERS_BULK_DELETE";
export const APPROVAL_PANEL_MEMBERS_BULK_DELETED_OK = "APPROVAL_PANEL_MEMBERS_BULK_DELETED_OK";
export const APPROVAL_PANEL_MEMBERS_BULK_DELETED_ERROR = "APPROVAL_PANEL_MEMBERS_BULK_DELETED_ERROR";