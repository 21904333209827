import {
  ADD_TNA_TEMPLATES,
  ADD_TNA_TEMPLATES_ERROR,
  ADD_TNA_TEMPLATES_OK,
  BEGIN_EDIT_TNA_TEMPLATES,
  BEGIN_TNA_TEMPLATES_DOWNLOAD,
  RETRIEVE_TNA_TEMPLATES_BULK_DELETE,
  RETRIEVE_TNA_TEMPLATES_DELETE,
  RETRIEVE_TNA_TEMPLATES_EDIT,
  TNA_TEMPLATES_BULK_DELETED_ERROR,
  TNA_TEMPLATES_BULK_DELETED_OK,
  TNA_TEMPLATES_DELETED_ERROR,
  TNA_TEMPLATES_DELETED_OK,
  TNA_TEMPLATES_DOWNLOAD_ERROR,
  TNA_TEMPLATES_DOWNLOAD_OK,
  TNA_TEMPLATES_EDITED_ERROR,
  TNA_TEMPLATES_EDITED_OK,
  BEGIN_TNA_TEMPLATE_DOWNLOAD,
  TNA_TEMPLATE_DOWNLOAD_OK,
  TNA_TEMPLATE_DOWNLOAD_ERROR,
  RESET,
} from "../../constants/modules/tnaTemplatesConstants";

const initialState = {
  tnaTemplates: [],
  getTnaTemplate: null,
  tnaTemplateAddInfo: null,
  error: false,
  loading: false,
  deleteTnaTemplate: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_TNA_TEMPLATES_DOWNLOAD:
    case BEGIN_TNA_TEMPLATE_DOWNLOAD:
    case RETRIEVE_TNA_TEMPLATES_BULK_DELETE:
    case ADD_TNA_TEMPLATES:
    case BEGIN_EDIT_TNA_TEMPLATES:
      return {
        ...state,
        loading: action.payload,
        tnaTemplate: action.tnaTemplate,
      };

    case TNA_TEMPLATES_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_TNA_TEMPLATES_ERROR:
    case TNA_TEMPLATES_DOWNLOAD_ERROR:
    case TNA_TEMPLATES_BULK_DELETED_ERROR:
    case TNA_TEMPLATE_DOWNLOAD_ERROR:
    case TNA_TEMPLATES_DELETED_ERROR:
    case TNA_TEMPLATES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TNA_TEMPLATES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        tnaTemplates: action.payload,
      };

    case TNA_TEMPLATE_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        getTnaTemplate: action.payload,
      };

    case ADD_TNA_TEMPLATES_OK:
      return {
        ...state,
        loading: false,
        error: null,
        tnaTemplateAddInfo: action.payload,
      };

    case RETRIEVE_TNA_TEMPLATES_DELETE:
      return {
        ...state,
        deleteTnaTemplate: action.payload,
      };

    case TNA_TEMPLATES_DELETED_OK:
      return {
        ...state,
        tnaTemplates: state.tnaTemplates.data.filter(
          (tnaTemplate) => tnaTemplate.id !== state.deleteTnaTemplate
        ),
        deleteTnaTemplate: null,
      };

    case RETRIEVE_TNA_TEMPLATES_EDIT:
      return {
        ...state,
        editTnaTemplate: action.payload,
      };

    case TNA_TEMPLATES_EDITED_OK:
      return {
        ...state,
        editTnaTemplate: null,
        tnaTemplates: state.tnaTemplates.data.map((tnaTemplate) =>
          tnaTemplate.id === action.payload.id
            ? (tnaTemplate = action.payload)
            : tnaTemplate
        ),
      };

    case RESET:
      return {
        ...state,
        getTnaTemplate: null,
        tnaTemplateAddInfo: null,
      };

    default:
      return state;
  }
}
