import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*           COMMISSION INVOICES API CALLS         */
/*-------------------------------------------------*/
export async function bulkDeleteCommissionInvoiceDB(ids) {
    return await axiosClient.post(`/commission_invoices/bulk_delete`, ids);
  }
  
  export async function retrieveCommissionInvoicesData() {
    return await axiosClient.get("/commission_invoices");
  }
  
  export async function addCommissionInvoiceDB(value) {
    return await axiosClient.post("/commission_invoices", value);
  }
  
  export async function deleteCommissionInvoiceDB(id) {
    return await axiosClient.delete(`/commission_invoices/${id}`);
  }
  
  export async function editCommissionInvoiceDB(value) {
    console.log("put Data ", value);
    return await axiosClient.put(`/commission_invoices/${value.id}`, value);
  }