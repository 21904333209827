export const ADD_BANK_ACCOUNTS = "ADD_BANK_ACCOUNTS";
export const ADD_BANK_ACCOUNTS_OK = "ADD_BANK_ACCOUNTS_OK";
export const ADD_BANK_ACCOUNTS_ERROR = "ADD_BANK_ACCOUNTS_ERROR";

export const BEGIN_BANK_ACCOUNTS_DOWNLOAD = "BEGIN_BANK_ACCOUNTS_DOWNLOAD";
export const BANK_ACCOUNTS_DOWNLOAD_OK = "BANK_ACCOUNTS_DOWNLOAD_OK";
export const BANK_ACCOUNTS_DOWNLOAD_ERROR = "BANK_ACCOUNTS_DOWNLOAD_ERROR";

export const RETRIEVE_BANK_ACCOUNTS_DELETE = "RETRIEVE_BANK_ACCOUNTS_DELETE";
export const BANK_ACCOUNTS_DELETED_OK = "BANK_ACCOUNTS_DELETED_OK";
export const BANK_ACCOUNTS_DELETED_ERROR = "BANK_ACCOUNTS_DELETED_ERROR";

export const RETRIEVE_BANK_ACCOUNTS_EDIT = "RETRIEVE_BANK_ACCOUNTS_EDITED";
export const BEGIN_EDIT_BANK_ACCOUNTS = "BEGIN_EDIT_BANK_ACCOUNTS";
export const BANK_ACCOUNTS_EDITED_OK = "BANK_ACCOUNTS_EDITED_OK";
export const BANK_ACCOUNTS_EDITED_ERROR = "BANK_ACCOUNTS_EDITED_ERROR";

export const RETRIEVE_BANK_ACCOUNTS_BULK_DELETE = "RETRIEVE_BANK_ACCOUNTS_BULK_DELETE";
export const BANK_ACCOUNTS_BULK_DELETED_OK = "BANK_ACCOUNTS_BULK_DELETED_OK";
export const BANK_ACCOUNTS_BULK_DELETED_ERROR = "BANK_ACCOUNTS_BULK_DELETED_ERROR";