import {
    ADD_PROCEED_REALIZATIONS,
    ADD_PROCEED_REALIZATIONS_ERROR,
    ADD_PROCEED_REALIZATIONS_OK,
    BEGIN_EDIT_PROCEED_REALIZATIONS,
    BEGIN_PROCEED_REALIZATIONS_DOWNLOAD,
    BEGIN_PROCEED_REALIZATION_DOWNLOAD,
    PROCEED_REALIZATIONS_BULK_DELETED_ERROR,
    PROCEED_REALIZATIONS_BULK_DELETED_OK,
    PROCEED_REALIZATIONS_DELETED_ERROR,
    PROCEED_REALIZATIONS_DELETED_OK,
    PROCEED_REALIZATIONS_DOWNLOAD_ERROR,
    PROCEED_REALIZATIONS_DOWNLOAD_OK,
    PROCEED_REALIZATIONS_EDITED_ERROR,
    PROCEED_REALIZATIONS_EDITED_OK, PROCEED_REALIZATION_DOWNLOAD_ERROR,
    PROCEED_REALIZATION_DOWNLOAD_OK, RESET, RETRIEVE_PROCEED_REALIZATIONS_BULK_DELETE,
    RETRIEVE_PROCEED_REALIZATIONS_DELETE,
    RETRIEVE_PROCEED_REALIZATIONS_EDIT
} from "../../constants/modules/proceedRealizationConstant";

export const downloadProceedRealizationsAction = () => ({
  type: BEGIN_PROCEED_REALIZATIONS_DOWNLOAD,
  payload: true,
});

export const downloadProceedRealizationsOkAction = (items) => ({
  type: PROCEED_REALIZATIONS_DOWNLOAD_OK,
  payload: items,
});

export const downloadProceedRealizationsErrorAction = () => ({
  type: PROCEED_REALIZATIONS_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadProceedRealizationAction = (item) => ({
  type: BEGIN_PROCEED_REALIZATION_DOWNLOAD,
  payload: true,
  proceedRealization: item,
});

export const downloadProceedRealizationOkAction = (items) => ({
  type: PROCEED_REALIZATION_DOWNLOAD_OK,
  payload: items,
});

export const downloadProceedRealizationErrorAction = () => ({
  type: PROCEED_REALIZATION_DOWNLOAD_ERROR,
  payload: true,
});

export const addProceedRealizationAction = (item) => ({
  type: ADD_PROCEED_REALIZATIONS,
  payload: true,
  proceedRealization: item,
});

export const addProceedRealizationOkAction = (items) => ({
  type: ADD_PROCEED_REALIZATIONS_OK,
  payload: items,
});

export const addProceedRealizationErrorAction = (state) => ({
  type: ADD_PROCEED_REALIZATIONS_ERROR,
  payload: state,
});

export const deleteProceedRealizationAction = (id) => ({
  type: RETRIEVE_PROCEED_REALIZATIONS_DELETE,
  payload: id,
});

export const deleteProceedRealizationOkAction = () => ({
  type: PROCEED_REALIZATIONS_DELETED_OK,
});

export const deleteProceedRealizationErrorAction = () => ({
  type: PROCEED_REALIZATIONS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteProceedRealizationAction = (item) => ({
  type: RETRIEVE_PROCEED_REALIZATIONS_BULK_DELETE,
  payload: true,
  proceedRealization: item,
});

export const bulkDeleteProceedRealizationOkAction = () => ({
  type: PROCEED_REALIZATIONS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteProceedRealizationErrorAction = (state) => ({
  type: PROCEED_REALIZATIONS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveProceedRealizationAction = (item) => ({
  type: RETRIEVE_PROCEED_REALIZATIONS_EDIT,
  payload: item,
});

export const editProceedRealizationAction = (item) => ({
  type: BEGIN_EDIT_PROCEED_REALIZATIONS,
  proceedRealization: item,
});

export const editProceedRealizationOkAction = (item) => ({
  type: PROCEED_REALIZATIONS_EDITED_OK,
  payload: item,
});

export const editProceedRealizationErrorAction = () => ({
  type: PROCEED_REALIZATIONS_EDITED_ERROR,
  payload: true,
});

export const ProceedRealizationReset = () => ({
    type: RESET,
    payload: true,
  });