import axiosClient from "../axiosClient";

export async function retrieveHeaderDB(value) {
  const postData = {
    p_id: value ? value : null,
  };

  return await axiosClient.post("/commissions/get_all", postData);
}

export async function retrieveDetailDB(value) {
  var id = {
    id: value,
  };
  return await axiosClient.post("/commissions/details", id);
}

export async function addHeaderDB(value) {
  return await axiosClient.post("/commissions", value);
}
