export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const ADD_PRODUCTS_OK = "ADD_PRODUCTS_OK";
export const ADD_PRODUCTS_ERROR = "ADD_PRODUCTS_ERROR";

export const BEGIN_PRODUCTS_DOWNLOAD = "BEGIN_PRODUCTS_DOWNLOAD";
export const PRODUCTS_DOWNLOAD_OK = "PRODUCTS_DOWNLOAD_OK";
export const PRODUCTS_DOWNLOAD_ERROR = "PRODUCTS_DOWNLOAD_ERROR";

export const BEGIN_PRODUCTS_BY_FK_DOWNLOAD = "BEGIN_PRODUCTS_BY_FK_DOWNLOAD";
export const PRODUCTS_BY_FK_DOWNLOAD_OK = "PRODUCTS_BY_FK_DOWNLOAD_OK";
export const PRODUCTS_BY_FK_DOWNLOAD_ERROR = "PRODUCTS_BY_FK_DOWNLOAD_ERROR";

export const RETRIEVE_PRODUCTS_DELETE = "RETRIEVE_PRODUCTS_DELETE";
export const PRODUCTS_DELETED_OK = "PRODUCTS_DELETED_OK";
export const PRODUCTS_DELETED_ERROR = "PRODUCTS_DELETED_ERROR";

export const RETRIEVE_PRODUCTS_EDIT = "RETRIEVE_PRODUCTS_EDITED";
export const BEGIN_EDIT_PRODUCTS = "BEGIN_EDIT_PRODUCTS";
export const PRODUCTS_EDITED_OK = "PRODUCTS_EDITED_OK";
export const PRODUCTS_EDITED_ERROR = "PRODUCTS_EDITED_ERROR";

export const RETRIEVE_PRODUCTS_BULK_DELETE = "RETRIEVE_PRODUCTS_BULK_DELETE";
export const PRODUCTS_BULK_DELETED_OK = "PRODUCTS_BULK_DELETED_OK";
export const PRODUCTS_BULK_DELETED_ERROR = "PRODUCTS_BULK_DELETED_ERROR";

export const RESET = "RESET";
