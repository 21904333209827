import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*            APPROVAL PANELS API CALLS            */
/*-------------------------------------------------*/

export async function bulkDeleteApprovalPanelDB(ids) {
    return await axiosClient.post(`/approval_panels/bulk_delete`, ids);
  }
  
  export async function retrieveApprovalPanelsData() {
    return await axiosClient.get("/approval_panels");
  }
  
  export async function addApprovalPanelDB(value) {
    return await axiosClient.post("/approval_panels", value);
  }
  
  export async function deleteApprovalPanelDB(id) {
    return await axiosClient.delete(`/approval_panels/${id}`);
  }
  
  export async function editApprovalPanelDB(value) {
    return await axiosClient.put(`/approval_panels/${value.id}`, value);
  }