import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";

import {
  addBankAccountErrorAction,
  addBankAccountOkAction,
  bulkDeleteBankAccountErrorAction,
  bulkDeleteBankAccountOkAction,
  deleteBankAccountErrorAction,
  deleteBankAccountOkAction,
  downloadBankAccountsErrorAction,
  downloadBankAccountsOkAction,
  editBankAccountErrorAction,
  editBankAccountOkAction
} from "../../actions/modules/bankAccountsActions";

import {
  addBankAccountDB,
  bulkDeleteBankAccountDB,
  deleteBankAccountDB,
  editBankAccountDB,
  retrieveBankAccountsData
} from "../../../config/apiCalls";
import {
  ADD_BANK_ACCOUNTS,
  BEGIN_BANK_ACCOUNTS_DOWNLOAD,
  BEGIN_EDIT_BANK_ACCOUNTS,
  RETRIEVE_BANK_ACCOUNTS_BULK_DELETE,
  RETRIEVE_BANK_ACCOUNTS_DELETE
} from "../../constants/modules/bankAccountsConstants";

// Retrieve products
// worker saga
export function* retrieveBankAccounts() {
  try {
    const response = yield call(retrieveBankAccountsData);

    if (response.status === 200) {
      yield put(downloadBankAccountsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadBankAccountsErrorAction());
  }
}

// Create new product
// worker saga
export function* addBankAccount(action) {
  const bankAccount = action.bankAccount;

  try {
    const response = yield call(addBankAccountDB, bankAccount);

    if (response.status === 201) {
      yield delay(500);

      yield put(addBankAccountOkAction(bankAccount));
      toast.success("Bank Account created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addBankAccountErrorAction(true));
    toast.error("Bank Account created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteBankAccount(action) {
  const id = action.payload;
  try {
    yield call(deleteBankAccountDB, id);
    yield deleteBankAccountOkAction();
    toast.success("Bank Account deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteBankAccountErrorAction();
  }
}

export function* bulkBankAccountDelete(action) {
  const bankAccount = action.bankAccount;

  try {
    const response = yield call(bulkDeleteBankAccountDB, bankAccount);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteBankAccountOkAction(bankAccount));
      toast.success("Bulk Bank Account deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteBankAccountErrorAction(true));
    toast.error("Bulk Bank Account deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editBankAccount(action) {
  const bankAccount = action.bankAccount;

  try {
    yield call(editBankAccountDB, bankAccount);
    yield editBankAccountOkAction(bankAccount);
    // Alert
    // Alert
    toast.success("Bank Account has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editBankAccountErrorAction();
  }
}

export function* watchBankAccountsAsync() {
  yield takeLatest(BEGIN_BANK_ACCOUNTS_DOWNLOAD, retrieveBankAccounts);
  yield takeLatest(ADD_BANK_ACCOUNTS, addBankAccount);
  yield takeLatest(RETRIEVE_BANK_ACCOUNTS_DELETE, deleteBankAccount);
  yield takeLatest(BEGIN_EDIT_BANK_ACCOUNTS, editBankAccount);
  yield takeLatest(RETRIEVE_BANK_ACCOUNTS_BULK_DELETE, bulkBankAccountDelete);
}
