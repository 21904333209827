import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    ADD_APPROVAL_PANEL_MEMBERS,
    BEGIN_APPROVAL_PANEL_MEMBERS_DOWNLOAD,
    BEGIN_EDIT_APPROVAL_PANEL_MEMBERS,
    RETRIEVE_APPROVAL_PANEL_MEMBERS_BULK_DELETE,
    RETRIEVE_APPROVAL_PANEL_MEMBERS_DELETE
} from "../../constants/modules/approvalPanelMembersContants";

import {
    addApprovalPanelMemberErrorAction,
    addApprovalPanelMemberOkAction,
    bulkDeleteApprovalPanelMemberErrorAction,
    bulkDeleteApprovalPanelMemberOkAction,
    deleteApprovalPanelMemberErrorAction,
    deleteApprovalPanelMemberOkAction,
    downloadApprovalPanelMembersErrorAction,
    downloadApprovalPanelMembersOkAction,
    editApprovalPanelMemberErrorAction,
    editApprovalPanelMemberOkAction
} from "../../actions/modules/approvalPanelMembersActions";

import {
    addApprovalPanelMemberDB,
    bulkDeleteApprovalPanelMemberDB,
    deleteApprovalPanelMemberDB,
    editApprovalPanelMemberDB,
    retrieveApprovalPanelMembersData
} from "../../../config/apiCalls/approvalPanelMembersAPICall";

// Retrieve products
// worker saga
export function* retrieveApprovalPanelMembers() {
  try {
    const response = yield call(retrieveApprovalPanelMembersData);

    if (response.status === 200) {
      yield put(downloadApprovalPanelMembersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadApprovalPanelMembersErrorAction());
  }
}

// Create new product
// worker saga
export function* addApprovalPanelMember(action) {
  const approvalPanelMember = action.approvalPanelMember;

  try {
    const response = yield call(addApprovalPanelMemberDB, approvalPanelMember);

    if (response.status === 201) {
      yield delay(500);

      yield put(addApprovalPanelMemberOkAction(approvalPanelMember));
      toast.success("ApprovalPanelMember created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addApprovalPanelMemberErrorAction(true));
    toast.error("ApprovalPanelMember created failed!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteApprovalPanelMember(action) {
  const id = action.payload;
  try {
    yield call(deleteApprovalPanelMemberDB, id);
    yield deleteApprovalPanelMemberOkAction();
    toast.success("ApprovalPanelMember deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteApprovalPanelMemberErrorAction();
  }
}

// Edit product
// worker saga
export function* editApprovalPanelMember(action) {
  const approvalPanelMember = action.approvalPanelMember;

  try {
    yield call(editApprovalPanelMemberDB, approvalPanelMember);
    yield editApprovalPanelMemberOkAction(approvalPanelMember);
    // Alert
    // Alert
    toast.success("ApprovalPanelMemberMember has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editApprovalPanelMemberErrorAction();
  }
}

// BULK SAGAS
export function* bulkApprovalPanelMemberDelete(action) {
  const approvalPanelMember = action.approvalPanelMember;

  try {
    const response = yield call(bulkDeleteApprovalPanelMemberDB, approvalPanelMember);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteApprovalPanelMemberOkAction(approvalPanelMember));
      toast.success("Bulk ApprovalPanelMember deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteApprovalPanelMemberErrorAction(true));
    toast.error("Bulk ApprovalPanelMember deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchApprovalPanelMembersAsync() {
  yield takeLatest(BEGIN_APPROVAL_PANEL_MEMBERS_DOWNLOAD, retrieveApprovalPanelMembers);
  yield takeLatest(ADD_APPROVAL_PANEL_MEMBERS, addApprovalPanelMember);
  yield takeLatest(RETRIEVE_APPROVAL_PANEL_MEMBERS_DELETE, deleteApprovalPanelMember);
  yield takeLatest(BEGIN_EDIT_APPROVAL_PANEL_MEMBERS, editApprovalPanelMember);
  yield takeLatest(
    RETRIEVE_APPROVAL_PANEL_MEMBERS_BULK_DELETE,
    bulkApprovalPanelMemberDelete
  );
}
