export const ADD_TNA_PLAN_DETAIL = "ADD_TNA_PLAN_DETAIL";
export const ADD_TNA_PLAN_DETAIL_OK = "ADD_TNA_PLAN_DETAIL_OK";
export const ADD_TNA_PLAN_DETAIL_ERROR = "ADD_TNA_PLAN_DETAIL_ERROR";

export const RETRIEVE_TNA_PLAN_DETAIL_DELETE =
  "RETRIEVE_TNA_PLAN_DETAIL_DELETE";
export const TNA_PLAN_DETAIL_DELETED_OK = "TNA_PLAN_DETAIL_DELETED_OK";
export const TNA_PLAN_DETAIL_DELETED_ERROR = "TNA_HEADER_DELETED_ERROR";

export const RESET = "RESET";
