export const ADD_SUPPLIERS = "ADD_SUPPLIERS";
export const ADD_SUPPLIERS_OK = "ADD_SUPPLIERS_OK";
export const ADD_SUPPLIERS_ERROR = "ADD_SUPPLIERS_ERROR";

export const BEGIN_SUPPLIERS_DOWNLOAD = "BEGIN_SUPPLIERS_DOWNLOAD";
export const SUPPLIERS_DOWNLOAD_OK = "SUPPLIERS_DOWNLOAD_OK";
export const SUPPLIERS_DOWNLOAD_ERROR = "SUPPLIERS_DOWNLOAD_ERROR";

export const RETRIEVE_SUPPLIERS_DELETE = "RETRIEVE_SUPPLIERS_DELETE";
export const SUPPLIERS_DELETED_OK = "SUPPLIERS_DELETED_OK";
export const SUPPLIERS_DELETED_ERROR = "SUPPLIERS_DELETED_ERROR";

export const RETRIEVE_SUPPLIERS_EDIT = "RETRIEVE_SUPPLIERS_EDITED";
export const BEGIN_EDIT_SUPPLIERS = "BEGIN_EDIT_SUPPLIERS";
export const SUPPLIERS_EDITED_OK = "SUPPLIERS_EDITED_OK";
export const SUPPLIERS_EDITED_ERROR = "SUPPLIERS_EDITED_ERROR";

export const RETRIEVE_SUPPLIERS_BULK_DELETE = "RETRIEVE_SUPPLIERS_BULK_DELETE";
export const SUPPLIERS_BULK_DELETED_OK = "SUPPLIERS_BULK_DELETED_OK";
export const SUPPLIERS_BULK_DELETED_ERROR = "SUPPLIERS_BULK_DELETED_ERROR";
