import {
  ADD_PRODUCTS,
  ADD_PRODUCTS_ERROR,
  ADD_PRODUCTS_OK,
  BEGIN_EDIT_PRODUCTS,
  BEGIN_PRODUCTS_DOWNLOAD,
  PRODUCTS_BULK_DELETED_ERROR,
  PRODUCTS_BULK_DELETED_OK,
  PRODUCTS_DELETED_ERROR,
  PRODUCTS_DELETED_OK,
  PRODUCTS_DOWNLOAD_ERROR,
  PRODUCTS_DOWNLOAD_OK,
  PRODUCTS_EDITED_ERROR,
  PRODUCTS_EDITED_OK,
  RETRIEVE_PRODUCTS_BULK_DELETE,
  RETRIEVE_PRODUCTS_DELETE,
  RETRIEVE_PRODUCTS_EDIT,
  BEGIN_PRODUCTS_BY_FK_DOWNLOAD,
  PRODUCTS_BY_FK_DOWNLOAD_OK,
  PRODUCTS_BY_FK_DOWNLOAD_ERROR,
  RESET,
} from "../../constants/modules/productConstants.js";

const initialState = {
  products: [],
  productsByFk: [],
  error: false,
  loading: false,
  deleteProduct: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_PRODUCTS_DOWNLOAD:
    case RETRIEVE_PRODUCTS_BULK_DELETE:
    case BEGIN_PRODUCTS_BY_FK_DOWNLOAD:
    case ADD_PRODUCTS:
    case BEGIN_EDIT_PRODUCTS:
      return {
        ...state,
        loading: action.payload,
        product: action.product,
      };

    case ADD_PRODUCTS_OK:
    case PRODUCTS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_PRODUCTS_ERROR:
    case PRODUCTS_DOWNLOAD_ERROR:
    case PRODUCTS_BULK_DELETED_ERROR:
    case PRODUCTS_BY_FK_DOWNLOAD_ERROR:
    case PRODUCTS_DELETED_ERROR:
    case PRODUCTS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PRODUCTS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        products: action.payload,
      };

    case PRODUCTS_BY_FK_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        productsByFk: action.payload,
      };

    case RETRIEVE_PRODUCTS_DELETE:
      return {
        ...state,
        deleteProduct: action.payload,
      };

    case PRODUCTS_DELETED_OK:
      return {
        ...state,
        products: state.products.data.filter(
          (product) => product.id !== state.deleteProduct
        ),
        deleteProduct: null,
      };

    case RETRIEVE_PRODUCTS_EDIT:
      return {
        ...state,
        editProduct: action.payload,
      };

    case PRODUCTS_EDITED_OK:
      return {
        ...state,
        editProduct: null,
        products: state.products.data.map((product) =>
          product.id === action.payload.id
            ? (product = action.payload)
            : product
        ),
      };

    case RESET:
      return {
        ...state,
        productsByFk: [],
      };

    default:
      return state;
  }
}
