export const ADD_BUYER_TERMS = "ADD_BUYER_TERMS";
export const ADD_BUYER_TERMS_OK = "ADD_BUYER_TERMS_OK";
export const ADD_BUYER_TERMS_ERROR = "ADD_BUYER_TERMS_ERROR";

export const BEGIN_BUYER_TERMS_DOWNLOAD = "BEGIN_BUYER_TERMS_DOWNLOAD";
export const BUYER_TERMS_DOWNLOAD_OK = "BUYER_TERMS_DOWNLOAD_OK";
export const BUYER_TERMS_DOWNLOAD_ERROR = "BUYER_TERMS_DOWNLOAD_ERROR";

export const RETRIEVE_BUYER_TERMS_DELETE = "RETRIEVE_BUYER_TERMS_DELETE";
export const BUYER_TERMS_DELETED_OK = "BUYER_TERMS_DELETED_OK";
export const BUYER_TERMS_DELETED_ERROR = "BUYER_TERMS_DELETED_ERROR";

export const RETRIEVE_BUYER_TERMS_EDIT = "RETRIEVE_BUYER_TERMS_EDITED";
export const BEGIN_EDIT_BUYER_TERMS = "BEGIN_EDIT_BUYER_TERMS";
export const BUYER_TERMS_EDITED_OK = "BUYER_TERMS_EDITED_OK";
export const BUYER_TERMS_EDITED_ERROR = "BUYER_TERMS_EDITED_ERROR";

export const RETRIEVE_BUYER_TERMS_BULK_DELETE = "RETRIEVE_BUYER_TERMS_BULK_DELETE";
export const BUYER_TERMS_BULK_DELETED_OK = "BUYER_TERMS_BULK_DELETED_OK";
export const BUYER_TERMS_BULK_DELETED_ERROR = "BUYER_TERMS_BULK_DELETED_ERROR";