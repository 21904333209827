import axiosClient from "../axiosClient";

export async function retrieveShipmentDetailDB(id) {
  console.log("Valuuuu******1122, id", id);
  return await axiosClient.post("/shipment_details/get_by_id", id);
}

export async function addShipmentDetailDB(value) {
  console.log("Valuuuu******, value", value);
  return await axiosClient.post("/shipment_details", value);
}

export async function deleteShipmentDetailDB(id) {
  return await axiosClient.delete(`/shipment_details/${id}`);
}

export async function bulkDeleteShipmentDetailDB(ids) {
  return await axiosClient.post(`/shipment_details/bulk_delete`, ids);
}

export async function editShipmentDetailDB(value) {
  return await axiosClient.put(`/shipment_details/${value.id}`, value);
}
