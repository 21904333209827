export const ADD_SIZE_SETS = "ADD_SIZE_SETS";
export const ADD_SIZE_SETS_OK = "ADD_SIZE_SETS_OK";
export const ADD_SIZE_SETS_ERROR = "ADD_SIZE_SETS_ERROR";

export const GET_SIZE_SET_DETAIL = "GET_SIZE_SET_DETAIL";
export const GET_SIZE_SET_DETAIL_OK = "GET_SIZE_SET_DETAIL_OK";
export const GET_SIZE_SET_DETAIL_ERROR = "GET_SIZE_SET_DETAIL_ERROR";

export const BEGIN_SIZE_SETS_DOWNLOAD = "BEGIN_SIZE_SETS_DOWNLOAD";
export const SIZE_SETS_DOWNLOAD_OK = "SIZE_SETS_DOWNLOAD_OK";
export const SIZE_SETS_DOWNLOAD_ERROR = "SIZE_SETS_DOWNLOAD_ERROR";

export const BEGIN_SIZE_SETS_BY_FK_DOWNLOAD = "BEGIN_SIZE_SETS_BY_FK_DOWNLOAD";
export const SIZE_SETS_BY_FK_DOWNLOAD_OK = "SIZE_SETS_BY_FK_DOWNLOAD_OK";
export const SIZE_SETS_BY_FK_DOWNLOAD_ERROR = "SIZE_SETS_BY_FK_DOWNLOAD_ERROR";

export const RETRIEVE_SIZE_SETS_DELETE = "RETRIEVE_SIZE_SETS_DELETE";
export const SIZE_SETS_DELETED_OK = "SIZE_SETS_DELETED_OK";
export const SIZE_SETS_DELETED_ERROR = "SIZE_SETS_DELETED_ERROR";

export const RETRIEVE_SIZE_SETS_EDIT = "RETRIEVE_SIZE_SETS_EDITED";
export const BEGIN_EDIT_SIZE_SETS = "BEGIN_EDIT_SIZE_SETS";
export const SIZE_SETS_EDITED_OK = "SIZE_SETS_EDITED_OK";
export const SIZE_SETS_EDITED_ERROR = "SIZE_SETS_EDITED_ERROR";

export const RESET = "RESET";
