import {
  ADD_USER_ROLE,
  ADD_USER_ROLE_OK,
  ADD_USER_ROLE_ERROR,
  BEGIN_USER_ROLE_DOWNLOAD,
  USER_ROLE_DOWNLOAD_OK,
  USER_ROLE_DOWNLOAD_ERROR,
  RETRIEVE_USER_ROLE_DELETE,
  USER_ROLE_DELETED_OK,
  USER_ROLE_DELETED_ERROR,
  RETRIEVE_USER_ROLE_EDIT,
  BEGIN_EDIT_USER_ROLE,
  USER_ROLE_EDITED_OK,
  USER_ROLE_EDITED_ERROR,
  RETRIEVE_USER_ROLE_BULK_DELETE,
  USER_ROLE_BULK_DELETED_OK,
  USER_ROLE_BULK_DELETED_ERROR,
} from "../../constants/modules/userRolesConstants";

export const downloadUserRolesAction = () => ({
  type: BEGIN_USER_ROLE_DOWNLOAD,
  payload: true,
});

export const downloadUserRolesOkAction = (items) => ({
  type: USER_ROLE_DOWNLOAD_OK,
  payload: items,
});

export const downloadUserRolesErrorAction = () => ({
  type: USER_ROLE_DOWNLOAD_ERROR,
  payload: true,
});

export const addUserRoleAction = (item) => ({
  type: ADD_USER_ROLE,
  payload: true,
  userRole: item,
});

export const addUserRoleOkAction = () => ({
  type: ADD_USER_ROLE_OK,
  payload: true,
});

export const addUserRoleErrorAction = (state) => ({
  type: ADD_USER_ROLE_ERROR,
  payload: state,
});

export const deleteUserRoleAction = (id) => ({
  type: RETRIEVE_USER_ROLE_DELETE,
  payload: id,
});

export const deleteUserRoleOkAction = () => ({
  type: USER_ROLE_DELETED_OK,
});

export const deleteUserRoleErrorAction = () => ({
  type: USER_ROLE_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteUserRoleAction = (item) => ({
  type: RETRIEVE_USER_ROLE_BULK_DELETE,
  payload: true,
  userRole: item,
});

export const bulkDeleteUserRoleOkAction = () => ({
  type: USER_ROLE_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteUserRoleErrorAction = (state) => ({
  type: USER_ROLE_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveUserRoleAction = (item) => ({
  type: RETRIEVE_USER_ROLE_EDIT,
  payload: item,
});

export const editUserRoleAction = (item) => ({
  type: BEGIN_EDIT_USER_ROLE,
  userRole: item,
});

export const editUserRoleOkAction = (item) => ({
  type: USER_ROLE_EDITED_OK,
  payload: item,
});

export const editUserRoleErrorAction = () => ({
  type: USER_ROLE_EDITED_ERROR,
  payload: true,
});
