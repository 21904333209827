import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_PRODUCTS,
  BEGIN_EDIT_PRODUCTS,
  BEGIN_PRODUCTS_BY_FK_DOWNLOAD,
  BEGIN_PRODUCTS_DOWNLOAD,
  RETRIEVE_PRODUCTS_BULK_DELETE,
  RETRIEVE_PRODUCTS_DELETE
} from "../../constants/modules/productConstants";

import {
  addProductErrorAction,
  addProductOkAction,
  bulkDeleteProductErrorAction,
  bulkDeleteProductOkAction,
  deleteProductErrorAction,
  deleteProductOkAction,
  downloadProductsByFKErrorAction,
  downloadProductsByFKOkAction,
  downloadProductsErrorAction,
  downloadProductsOkAction,
  editProductErrorAction,
  editProductOkAction
} from "../../actions/modules/productsActions";

import {
  addProductDB,
  bulkDeleteProductDB,
  deleteProductDB,
  editProductDB,
  retrieveProductsByFkDB,
  retrieveProductsData
} from "../../../config/apiCalls/products";

// Retrieve products
// worker saga
export function* retrieveProducts() {
  try {
    const response = yield call(retrieveProductsData);

    if (response.status === 200) {
      yield put(downloadProductsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadProductsErrorAction());
  }
}

export function* retrieveProductsByFk(action) {
  const product = action.product;
  try {
    const response = yield call(retrieveProductsByFkDB, product);

    if (response.status === 200) {
      yield put(downloadProductsByFKOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadProductsByFKErrorAction());
  }
}

// Create new product
// worker saga
export function* addProduct(action) {
  const product = action.product;

  try {
    const response = yield call(addProductDB, product);

    if (response.status === 201) {
      yield delay(500);

      yield put(addProductOkAction(product));
      toast.success("Product created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addProductErrorAction(true));
    toast.error("Product created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteProduct(action) {
  const id = action.payload;
  try {
    yield call(deleteProductDB, id);
    yield deleteProductOkAction();
    toast.success("Product deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteProductErrorAction();
  }
}

// Edit product
// worker saga
export function* editProduct(action) {
  const product = action.product;

  try {
    yield call(editProductDB, product);
    yield editProductOkAction(product);
    // Alert
    toast.success("Product has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editProductErrorAction();
  }
}

// BULK SAGAS
export function* bulkProductDelete(action) {
  const product = action.product;

  try {
    const response = yield call(bulkDeleteProductDB, product);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteProductOkAction(product));
      toast.success("Bulk Product deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteProductErrorAction(true));
    toast.error("Bulk Product deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchProductsAsync() {
  yield takeLatest(BEGIN_PRODUCTS_DOWNLOAD, retrieveProducts);
  yield takeLatest(BEGIN_PRODUCTS_BY_FK_DOWNLOAD, retrieveProductsByFk);
  yield takeLatest(ADD_PRODUCTS, addProduct);
  yield takeLatest(RETRIEVE_PRODUCTS_DELETE, deleteProduct);
  yield takeLatest(BEGIN_EDIT_PRODUCTS, editProduct);
  yield takeLatest(RETRIEVE_PRODUCTS_BULK_DELETE, bulkProductDelete);
}
