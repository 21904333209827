import {
  ADD_SIZE_SETS,
  ADD_SIZE_SETS_ERROR,
  ADD_SIZE_SETS_OK,
  BEGIN_SIZE_SETS_DOWNLOAD,
  BEGIN_EDIT_SIZE_SETS,
  SIZE_SETS_DELETED_ERROR,
  SIZE_SETS_DELETED_OK,
  SIZE_SETS_DOWNLOAD_ERROR,
  SIZE_SETS_DOWNLOAD_OK,
  SIZE_SETS_EDITED_ERROR,
  SIZE_SETS_EDITED_OK,
  RETRIEVE_SIZE_SETS_DELETE,
  RETRIEVE_SIZE_SETS_EDIT,
  GET_SIZE_SET_DETAIL,
  GET_SIZE_SET_DETAIL_OK,
  GET_SIZE_SET_DETAIL_ERROR,
  BEGIN_SIZE_SETS_BY_FK_DOWNLOAD,
  SIZE_SETS_BY_FK_DOWNLOAD_OK,
  SIZE_SETS_BY_FK_DOWNLOAD_ERROR,
  RESET,
} from "../../constants/modules/sizeSetsConstants";

export const downloadSizeSetsAction = () => ({
  type: BEGIN_SIZE_SETS_DOWNLOAD,
  payload: true,
});

export const downloadSizeSetsOkAction = (items) => ({
  type: SIZE_SETS_DOWNLOAD_OK,
  payload: items,
});

export const downloadSizeSetsErrorAction = () => ({
  type: SIZE_SETS_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadSizeSetsByFKAction = (item) => ({
  type: BEGIN_SIZE_SETS_BY_FK_DOWNLOAD,
  payload: true,
  sizeSet: item,
});

export const downloadSizeSetsByFKOkAction = (items) => ({
  type: SIZE_SETS_BY_FK_DOWNLOAD_OK,
  payload: items,
});

export const downloadSizeSetsByFKErrorAction = () => ({
  type: SIZE_SETS_BY_FK_DOWNLOAD_ERROR,
  payload: true,
});

export const getSizeSetDetailAction = (item) => ({
  type: GET_SIZE_SET_DETAIL,
  payload: true,
  sizeSet: item,
});

export const getSizeSetDetailOkAction = (items) => ({
  type: GET_SIZE_SET_DETAIL_OK,
  payload: items,
});

export const getSizeSetDetailErrorAction = (state) => ({
  type: GET_SIZE_SET_DETAIL_ERROR,
  payload: state,
});

export const addSizeSetAction = (item) => ({
  type: ADD_SIZE_SETS,
  payload: true,
  sizeSet: item,
});

export const addSizeSetOkAction = () => ({
  type: ADD_SIZE_SETS_OK,
  payload: true,
});

export const addSizeSetErrorAction = (state) => ({
  type: ADD_SIZE_SETS_ERROR,
  payload: state,
});

export const deleteSizeSetAction = (id) => ({
  type: RETRIEVE_SIZE_SETS_DELETE,
  payload: id,
});

export const deleteSizeSetOkAction = () => ({
  type: SIZE_SETS_DELETED_OK,
});

export const deleteSizeSetErrorAction = () => ({
  type: SIZE_SETS_DELETED_ERROR,
  payload: true,
});

export const retrieveSizeSetAction = (item) => ({
  type: RETRIEVE_SIZE_SETS_EDIT,
  payload: item,
});

export const editSizeSetAction = (item) => ({
  type: BEGIN_EDIT_SIZE_SETS,
  sizeSet: item,
});

export const editSizeSetOkAction = (item) => ({
  type: SIZE_SETS_EDITED_OK,
  payload: item,
});

export const editSizeSetErrorAction = () => ({
  type: SIZE_SETS_EDITED_ERROR,
  payload: true,
});

export const sizeSetsByFkReset = () => ({
  type: RESET,
  payload: true,
});
