import {
    ADD_RDL_INVOICES,
    ADD_RDL_INVOICES_ERROR,
    ADD_RDL_INVOICES_OK,
    BEGIN_EDIT_RDL_INVOICES,
    BEGIN_RDL_INVOICES_DOWNLOAD,
    BEGIN_RDL_INVOICE_DOWNLOAD,
    RDL_INVOICES_BULK_DELETED_ERROR,
    RDL_INVOICES_BULK_DELETED_OK,
    RDL_INVOICES_DELETED_ERROR,
    RDL_INVOICES_DELETED_OK,
    RDL_INVOICES_DOWNLOAD_ERROR,
    RDL_INVOICES_DOWNLOAD_OK,
    RDL_INVOICES_EDITED_ERROR,
    RDL_INVOICES_EDITED_OK,
    RDL_INVOICE_DOWNLOAD_ERROR,
    RDL_INVOICE_DOWNLOAD_OK,
    RESET,
    RETRIEVE_RDL_INVOICES_BULK_DELETE,
    RETRIEVE_RDL_INVOICES_DELETE,
    RETRIEVE_RDL_INVOICES_EDIT
} from "../../constants/modules/RDLInvoiceConstant";

const initialState = {
  rdlInvoices: [],
  getRDLInvoice: null,
  rdlInvoiceAddInfo: null,
  error: false,
  loading: false,
  deleteRDLInvoice: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_RDL_INVOICES_DOWNLOAD:
    case BEGIN_RDL_INVOICE_DOWNLOAD:
    case RETRIEVE_RDL_INVOICES_BULK_DELETE:
    case ADD_RDL_INVOICES:
    case BEGIN_EDIT_RDL_INVOICES:
      return {
        ...state,
        loading: action.payload,
        rdlInvoice: action.rdlInvoice,
      };

    case RDL_INVOICES_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_RDL_INVOICES_ERROR:
    case RDL_INVOICES_DOWNLOAD_ERROR:
    case RDL_INVOICES_BULK_DELETED_ERROR:
    case RDL_INVOICE_DOWNLOAD_ERROR:
    case RDL_INVOICES_DELETED_ERROR:
    case RDL_INVOICES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RDL_INVOICES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        rdlInvoices: action.payload,
      };

    case RDL_INVOICE_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        getRDLInvoice: action.payload,
      };

    case ADD_RDL_INVOICES_OK:
      return {
        ...state,
        loading: false,
        error: null,
        rdlInvoiceAddInfo: action.payload,
      };

    case RETRIEVE_RDL_INVOICES_DELETE:
      return {
        ...state,
        deleteRDLInvoice: action.payload,
      };

    case RDL_INVOICES_DELETED_OK:
      return {
        ...state,
        rdlInvoices: state.rdlInvoices.data.filter(
          (rdlInvoice) => rdlInvoice.id !== state.deleteRDLInvoice
        ),
        deleteRDLInvoice: null,
      };

    case RETRIEVE_RDL_INVOICES_EDIT:
      return {
        ...state,
        editRDLInvoice: action.payload,
      };

    case RDL_INVOICES_EDITED_OK:
      return {
        ...state,
        editRDLInvoices: null,
        rdlInvoices: state.rdlInvoices.data.map((rdlInvoice) =>
          rdlInvoice.id === action.payload.id
            ? (rdlInvoice = action.payload)
            : rdlInvoice
        ),
      };

    case RESET:
      return {
        ...state,
        getRDLInvoice: null,
        rdlInvoiceAddInfo: null,
      };

    default:
      return state;
  }
}
