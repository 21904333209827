import {
  ADD_BANK_ACCOUNTS,
  ADD_BANK_ACCOUNTS_ERROR,
  ADD_BANK_ACCOUNTS_OK,
  BANK_ACCOUNTS_BULK_DELETED_ERROR,
  BANK_ACCOUNTS_BULK_DELETED_OK,
  BANK_ACCOUNTS_DELETED_ERROR,
  BANK_ACCOUNTS_DELETED_OK,
  BANK_ACCOUNTS_DOWNLOAD_ERROR,
  BANK_ACCOUNTS_DOWNLOAD_OK,
  BANK_ACCOUNTS_EDITED_ERROR,
  BANK_ACCOUNTS_EDITED_OK,
  BEGIN_BANK_ACCOUNTS_DOWNLOAD,
  BEGIN_EDIT_BANK_ACCOUNTS,
  RETRIEVE_BANK_ACCOUNTS_BULK_DELETE,
  RETRIEVE_BANK_ACCOUNTS_DELETE,
  RETRIEVE_BANK_ACCOUNTS_EDIT,
} from "../../constants/modules/bankAccountsConstants";

export const downloadBankAccountsAction = () => ({
  type: BEGIN_BANK_ACCOUNTS_DOWNLOAD,
  payload: true,
});

export const bulkDeleteBankAccountAction = (item) => ({
  type: RETRIEVE_BANK_ACCOUNTS_BULK_DELETE,
  payload: true,
  bankAccount: item,
});

export const bulkDeleteBankAccountOkAction = () => ({
  type: BANK_ACCOUNTS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteBankAccountErrorAction = (state) => ({
  type: BANK_ACCOUNTS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadBankAccountsOkAction = (items) => ({
  type: BANK_ACCOUNTS_DOWNLOAD_OK,
  payload: items,
});

export const downloadBankAccountsErrorAction = () => ({
  type: BANK_ACCOUNTS_DOWNLOAD_ERROR,
  payload: true,
});

export const addBankAccountAction = (item) => ({
  type: ADD_BANK_ACCOUNTS,
  payload: true,
  bankAccount: item,
});

export const addBankAccountOkAction = () => ({
  type: ADD_BANK_ACCOUNTS_OK,
  payload: true,
});

export const addBankAccountErrorAction = (state) => ({
  type: ADD_BANK_ACCOUNTS_ERROR,
  payload: state,
});

export const deleteBankAccountAction = (id) => ({
  type: RETRIEVE_BANK_ACCOUNTS_DELETE,
  payload: id,
});

export const deleteBankAccountOkAction = () => ({
  type: BANK_ACCOUNTS_DELETED_OK,
});

export const deleteBankAccountErrorAction = () => ({
  type: BANK_ACCOUNTS_DELETED_ERROR,
  payload: true,
});

export const retrieveBankAccountAction = (item) => ({
  type: RETRIEVE_BANK_ACCOUNTS_EDIT,
  payload: item,
});

export const editBankAccountAction = (item) => ({
  type: BEGIN_EDIT_BANK_ACCOUNTS,
  bankAccount: item,
});

export const editBankAccountOkAction = (item) => ({
  type: BANK_ACCOUNTS_EDITED_OK,
  payload: item,
});

export const editBankAccountErrorAction = () => ({
  type: BANK_ACCOUNTS_EDITED_ERROR,
  payload: true,
});
