import {
  ADD_SAMPLE_STATUS,
  ADD_SAMPLE_STATUS_ERROR,
  ADD_SAMPLE_STATUS_OK,
  BEGIN_EDIT_SAMPLE_STATUS,
  BEGIN_SAMPLE_STATUS_DOWNLOAD,
  RETRIEVE_SAMPLE_STATUS_BULK_DELETE,
  RETRIEVE_SAMPLE_STATUS_DELETE,
  RETRIEVE_SAMPLE_STATUS_EDIT,
  SAMPLE_STATUS_BULK_DELETED_ERROR,
  SAMPLE_STATUS_BULK_DELETED_OK,
  SAMPLE_STATUS_DELETED_ERROR,
  SAMPLE_STATUS_DELETED_OK,
  SAMPLE_STATUS_DOWNLOAD_ERROR,
  SAMPLE_STATUS_DOWNLOAD_OK,
  SAMPLE_STATUS_EDITED_ERROR,
  SAMPLE_STATUS_EDITED_OK,
} from "../../constants/modules/sampleStatusConstant";

const initialState = {
  sampleStatuses: [],
  error: false,
  loading: false,
  deleteSampleStatus: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_SAMPLE_STATUS_DOWNLOAD:
    case RETRIEVE_SAMPLE_STATUS_BULK_DELETE:
    case ADD_SAMPLE_STATUS:
    case BEGIN_EDIT_SAMPLE_STATUS:
      return {
        ...state,
        loading: action.payload,
        sampleStatus: action.sampleStatus,
      };

    case ADD_SAMPLE_STATUS_OK:
    case SAMPLE_STATUS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_SAMPLE_STATUS_ERROR:
    case SAMPLE_STATUS_DOWNLOAD_ERROR:
    case SAMPLE_STATUS_BULK_DELETED_ERROR:
    case SAMPLE_STATUS_DELETED_ERROR:
    case SAMPLE_STATUS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SAMPLE_STATUS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        sampleStatuses: action.payload,
      };

    case RETRIEVE_SAMPLE_STATUS_DELETE:
      return {
        ...state,
        deleteSampleStatus: action.payload,
      };

    case SAMPLE_STATUS_DELETED_OK:
      return {
        ...state,
        sampleStatuses: state.sampleStatuses.data.filter(
          (sampleStatus) => sampleStatus.id !== state.deleteSampleStatus
        ),
        deleteSampleStatus: null,
      };

    case RETRIEVE_SAMPLE_STATUS_EDIT:
      return {
        ...state,
        editSampleStatus: action.payload,
      };

    case SAMPLE_STATUS_EDITED_OK:
      return {
        ...state,
        editSampleStatus: null,
        sampleStatuses: state.sampleStatuses.data.map((sampleStatus) =>
          sampleStatus.id === action.payload.id
            ? (sampleStatus = action.payload)
            : sampleStatus
        ),
      };

    default:
      return state;
  }
}
