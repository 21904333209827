import {
  ADD_SUPPLIERS,
  ADD_SUPPLIERS_OK,
  ADD_SUPPLIERS_ERROR,
  BEGIN_SUPPLIERS_DOWNLOAD,
  SUPPLIERS_DOWNLOAD_OK,
  SUPPLIERS_DOWNLOAD_ERROR,
  RETRIEVE_SUPPLIERS_DELETE,
  SUPPLIERS_DELETED_OK,
  SUPPLIERS_DELETED_ERROR,
  RETRIEVE_SUPPLIERS_EDIT,
  BEGIN_EDIT_SUPPLIERS,
  SUPPLIERS_EDITED_OK,
  SUPPLIERS_EDITED_ERROR,
  RETRIEVE_SUPPLIERS_BULK_DELETE,
  SUPPLIERS_BULK_DELETED_OK,
  SUPPLIERS_BULK_DELETED_ERROR,
} from "../../constants/modules/suppliersConstants";

export const downloadSuppliersAction = () => ({
  type: BEGIN_SUPPLIERS_DOWNLOAD,
  payload: true,
});

export const downloadSuppliersOkAction = (items) => ({
  type: SUPPLIERS_DOWNLOAD_OK,
  payload: items,
});

export const downloadSuppliersErrorAction = () => ({
  type: SUPPLIERS_DOWNLOAD_ERROR,
  payload: true,
});

export const addSupplierAction = (item) => ({
  type: ADD_SUPPLIERS,
  payload: true,
  supplier: item,
});

export const addSupplierOkAction = () => ({
  type: ADD_SUPPLIERS_OK,
  payload: true,
});

export const addSupplierErrorAction = (state) => ({
  type: ADD_SUPPLIERS_ERROR,
  payload: state,
});

export const deleteSupplierAction = (id) => ({
  type: RETRIEVE_SUPPLIERS_DELETE,
  payload: id,
});

export const deleteSupplierOkAction = () => ({
  type: SUPPLIERS_DELETED_OK,
});

export const deleteSupplierErrorAction = () => ({
  type: SUPPLIERS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteSupplierAction = (item) => ({
  type: RETRIEVE_SUPPLIERS_BULK_DELETE,
  payload: true,
  supplier: item,
});

export const bulkDeleteSupplierOkAction = () => ({
  type: SUPPLIERS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteSupplierErrorAction = (state) => ({
  type: SUPPLIERS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveSupplierAction = (item) => ({
  type: RETRIEVE_SUPPLIERS_EDIT,
  payload: item,
});

export const editSupplierAction = (item) => ({
  type: BEGIN_EDIT_SUPPLIERS,
  supplier: item,
});

export const editSupplierOkAction = (item) => ({
  type: SUPPLIERS_EDITED_OK,
  payload: item,
});

export const editSupplierErrorAction = () => ({
  type: SUPPLIERS_EDITED_ERROR,
  payload: true,
});
