export const ADD_SALES_CONTRACTS = "ADD_SALES_CONTRACTS";
export const ADD_SALES_CONTRACTS_OK = "ADD_SALES_CONTRACTS_OK";
export const ADD_SALES_CONTRACTS_ERROR = "ADD_SALES_CONTRACTS_ERROR";

export const BEGIN_SALES_CONTRACTS_DOWNLOAD = "BEGIN_SALES_CONTRACTS_DOWNLOAD";
export const SALES_CONTRACTS_DOWNLOAD_OK = "SALES_CONTRACTS_DOWNLOAD_OK";
export const SALES_CONTRACTS_DOWNLOAD_ERROR = "SALES_CONTRACTS_DOWNLOAD_ERROR";

export const BEGIN_SALES_CONTRACT_DOWNLOAD = "BEGIN_SALES_CONTRACT_DOWNLOAD";
export const SALES_CONTRACT_DOWNLOAD_OK = "SALES_CONTRACT_DOWNLOAD_OK";
export const SALES_CONTRACT_DOWNLOAD_ERROR = "SALES_CONTRACT_DOWNLOAD_ERROR";

export const RETRIEVE_SALES_CONTRACTS_DELETE = "RETRIEVE_SALES_CONTRACTS_DELETE";
export const SALES_CONTRACTS_DELETED_OK = "SALES_CONTRACTS_DELETED_OK";
export const SALES_CONTRACTS_DELETED_ERROR = "SALES_CONTRACTS_DELETED_ERROR";

export const RETRIEVE_SALES_CONTRACTS_EDIT = "RETRIEVE_SALES_CONTRACTS_EDITED";
export const BEGIN_EDIT_SALES_CONTRACTS = "BEGIN_EDIT_SALES_CONTRACTS";
export const SALES_CONTRACTS_EDITED_OK = "SALES_CONTRACTS_EDITED_OK";
export const SALES_CONTRACTS_EDITED_ERROR = "SALES_CONTRACTS_EDITED_ERROR";

export const RETRIEVE_SALES_CONTRACTS_BULK_DELETE =
  "RETRIEVE_SALES_CONTRACTS_BULK_DELETE";
export const SALES_CONTRACTS_BULK_DELETED_OK = "SALES_CONTRACTS_BULK_DELETED_OK";
export const SALES_CONTRACTS_BULK_DELETED_ERROR =
  "SALES_CONTRACTS_BULK_DELETED_ERROR";

export const RESET = "RESET";
