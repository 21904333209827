import {
  ADD_BUYERS,
  ADD_BUYERS_OK,
  ADD_BUYERS_ERROR,
  BEGIN_BUYERS_DOWNLOAD,
  BUYERS_DOWNLOAD_OK,
  BUYERS_DOWNLOAD_ERROR,
  RETRIEVE_BUYERS_DELETE,
  BUYERS_DELETED_OK,
  BUYERS_DELETED_ERROR,
  RETRIEVE_BUYERS_EDIT,
  BEGIN_EDIT_BUYERS,
  BUYERS_EDITED_OK,
  BUYERS_EDITED_ERROR,
  RETRIEVE_BUYERS_BULK_DELETE,
  BUYERS_BULK_DELETED_OK,
  BUYERS_BULK_DELETED_ERROR,
} from "../../constants/modules/buyersConstants";

const initialState = {
  buyers: [],
  error: false,
  loading: false,
  deleteBuyer: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_BUYERS_DOWNLOAD:
    case ADD_BUYERS:
    case RETRIEVE_BUYERS_BULK_DELETE:
    case BEGIN_EDIT_BUYERS:
      return {
        ...state,
        loading: action.payload,
        buyer: action.buyer,
      };

    case ADD_BUYERS_OK:
    case BUYERS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_BUYERS_ERROR:
    case BUYERS_DOWNLOAD_ERROR:
    case BUYERS_DELETED_ERROR:
    case BUYERS_BULK_DELETED_ERROR:
    case BUYERS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BUYERS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        buyers: action.payload,
      };

    case RETRIEVE_BUYERS_DELETE:
      return {
        ...state,
        deleteBuyer: action.payload,
      };

    case BUYERS_DELETED_OK:
      return {
        ...state,
        buyers: state.buyers.data.filter(
          (buyer) => buyer.id !== state.deleteBuyer
        ),
        deleteUser: null,
      };

    case RETRIEVE_BUYERS_EDIT:
      return {
        ...state,
        editBuyer: action.payload,
      };

    case BUYERS_EDITED_OK:
      return {
        ...state,
        editBuyer: null,
        buyers: state.buyers.data.map((buyer) =>
          buyer.id === action.payload.id ? (buyer = action.payload) : buyer
        ),
      };

    default:
      return state;
  }
}
