import {
  ADD_PRODUCT_TYPES, ADD_PRODUCT_TYPES_ERROR, ADD_PRODUCT_TYPES_OK, BEGIN_EDIT_PRODUCT_TYPES, BEGIN_PRODUCT_TYPES_DOWNLOAD, PRODUCT_TYPES_BULK_DELETED_ERROR, PRODUCT_TYPES_BULK_DELETED_OK, PRODUCT_TYPES_DELETED_ERROR, PRODUCT_TYPES_DELETED_OK, PRODUCT_TYPES_DOWNLOAD_ERROR, PRODUCT_TYPES_DOWNLOAD_OK, PRODUCT_TYPES_EDITED_ERROR, PRODUCT_TYPES_EDITED_OK, RETRIEVE_PRODUCT_TYPES_BULK_DELETE, RETRIEVE_PRODUCT_TYPES_DELETE, RETRIEVE_PRODUCT_TYPES_EDIT
} from "../../constants/modules/productTypeConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteProductTypeAction = (item) => ({
  type: RETRIEVE_PRODUCT_TYPES_BULK_DELETE,
  payload: true,
  productType: item,
});

export const bulkDeleteProductTypeOkAction = () => ({
  type: PRODUCT_TYPES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteProductTypeErrorAction = (state) => ({
  type: PRODUCT_TYPES_BULK_DELETED_ERROR,
  payload: state,
});
  
  export const downloadProductTypesAction = () => ({
    type: BEGIN_PRODUCT_TYPES_DOWNLOAD,
    payload: true,
  });
  
  export const downloadProductTypesOkAction = (items) => ({
    type: PRODUCT_TYPES_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadProductTypesErrorAction = () => ({
    type: PRODUCT_TYPES_DOWNLOAD_ERROR,
    payload: true,
  });
  
  
  export const addProductTypeAction = (item) => ({
    type: ADD_PRODUCT_TYPES,
    payload: true,
    productType: item,
  });
  
  export const addProductTypeOkAction = () => ({
    type: ADD_PRODUCT_TYPES_OK,
    payload: true,
  });
  
  export const addProductTypeErrorAction = (state) => ({
    type: ADD_PRODUCT_TYPES_ERROR,
    payload: state,
  });
  
  
  export const deleteProductTypeAction = (id) => ({
    type: RETRIEVE_PRODUCT_TYPES_DELETE,
    payload: id,
  });
  
  export const deleteProductTypeOkAction = () => ({
    type: PRODUCT_TYPES_DELETED_OK,
  });
  
  export const deleteProductTypeErrorAction = () => ({
    type: PRODUCT_TYPES_DELETED_ERROR,
    payload: true,
  });
  
  
  export const retrieveProductTypeAction = (item) => ({
    type: RETRIEVE_PRODUCT_TYPES_EDIT,
    payload: item,
  });
  
  export const editProductTypeAction = (item) => ({
    type: BEGIN_EDIT_PRODUCT_TYPES,
    productType: item,
  });
  
  export const editProductTypeOkAction = (item) => ({
    type: PRODUCT_TYPES_EDITED_OK,
    payload: item,
  });
  
  export const editProductTypeErrorAction = () => ({
    type: PRODUCT_TYPES_EDITED_ERROR,
    payload: true,
  });
  
  