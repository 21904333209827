import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    ADD_TT_ADVANCE_DETAILS,
    BEGIN_EDIT_TT_ADVANCE_DETAILS,
    BEGIN_TT_ADVANCE_DETAILS_DOWNLOAD,
    BEGIN_TT_ADVANCE_DETAIL_DOWNLOAD,
    RETRIEVE_TT_ADVANCE_DETAILS_DELETE
} from "../../constants/modules/TTAdvanceDetailConstant";

import {
    addTTAdvanceDetailErrorAction,
    addTTAdvanceDetailOkAction,
    deleteTTAdvanceDetailErrorAction,
    deleteTTAdvanceDetailOkAction,
    downloadTTAdvanceDetailErrorAction,
    downloadTTAdvanceDetailOkAction,
    downloadTTAdvanceDetailsErrorAction,
    downloadTTAdvanceDetailsOkAction,
    editTTAdvanceDetailErrorAction,
    editTTAdvanceDetailOkAction
} from "../../actions/modules/TTAdvanceDetailAction";

import {
    addTTAdvanceDetailDB,
    deleteTTAdvanceDetailDB,
    editTTAdvanceDetailDB,
    retrieveTTAdvanceDetailData,
    retrieveTTAdvanceDetailsData
} from "../../../config/apiCalls/TTAdvanceDetailsAPICall";

// Retrieve products
// worker saga
export function* retrieveTTAdvanceDetails() {
  try {
    const response = yield call(retrieveTTAdvanceDetailsData);

    if (response.status === 200) {
      yield put(downloadTTAdvanceDetailsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTTAdvanceDetailsErrorAction());
  }
}

export function* retrieveTTAdvanceDetail(action) {
  const ttAdvanceDetail = action.ttAdvanceDetail;
  try {
    const response = yield call(retrieveTTAdvanceDetailData, ttAdvanceDetail);
    if (response.status === 200) {
      yield put(downloadTTAdvanceDetailOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTTAdvanceDetailErrorAction());
  }
}

// Create new product
// worker saga
export function* addTTAdvanceDetail(action) {
  const ttAdvanceDetail = action.ttAdvanceDetail;
  try {
    const response = yield call(addTTAdvanceDetailDB, ttAdvanceDetail);
    if (response.status === 200) {
      yield delay(500);
      yield put(addTTAdvanceDetailOkAction(response.data));
      toast.success("TT Advance Detail created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addTTAdvanceDetailErrorAction(true));
    toast.error("TT Advance Detail created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteTTAdvanceDetail(action) {
  const id = action.payload;
  try {
    yield call(deleteTTAdvanceDetailDB, id);
    yield deleteTTAdvanceDetailOkAction();
    toast.success("TT Advance Detail deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteTTAdvanceDetailErrorAction();
  }
}

// Edit product
// worker saga
export function* editTTAdvanceDetail(action) {
  const ttAdvanceDetail = action.ttAdvanceDetail;

  try {
    yield call(editTTAdvanceDetailDB, ttAdvanceDetail);
    yield editTTAdvanceDetailOkAction(ttAdvanceDetail);
    // Alert
    // Alert
    toast.success("TT Advance Detail has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editTTAdvanceDetailErrorAction();
  }
}

export function* watchTTAdvanceDetailsAsync() {
  yield takeLatest(
    BEGIN_TT_ADVANCE_DETAILS_DOWNLOAD,
    retrieveTTAdvanceDetails
  );
  yield takeLatest(BEGIN_TT_ADVANCE_DETAIL_DOWNLOAD, retrieveTTAdvanceDetail);
  yield takeLatest(ADD_TT_ADVANCE_DETAILS, addTTAdvanceDetail);
  yield takeLatest(RETRIEVE_TT_ADVANCE_DETAILS_DELETE, deleteTTAdvanceDetail);
  yield takeLatest(BEGIN_EDIT_TT_ADVANCE_DETAILS, editTTAdvanceDetail);
}
