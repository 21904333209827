import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_BUYER_BRANDS,
  BEGIN_BUYER_BRANDS_DOWNLOAD,
  RETRIEVE_BUYER_BRANDS_DELETE,
  BEGIN_EDIT_BUYER_BRANDS,
  RETRIEVE_BUYER_BRANDS_BULK_DELETE,
  BEGIN_BUYER_BRANDS_BY_BUYER_DOWNLOAD,
} from "../../constants/modules/buyerBrandsConstants";

import {
  downloadBuyerBrandsOkAction,
  downloadBuyerBrandsErrorAction,
  addBuyerBrandOkAction,
  addBuyerBrandErrorAction,
  deleteBuyerBrandOkAction,
  deleteBuyerBrandErrorAction,
  editBuyerBrandOkAction,
  editBuyerBrandErrorAction,
  bulkDeleteBuyerBrandOkAction,
  bulkDeleteBuyerBrandErrorAction,
  downloadBuyerBrandsByBuyerErrorAction,
  downloadBuyerBrandsByBuyerOkAction,
} from "../../actions/modules/buyerBrandsActions";

import {
  retrieveBuyerBrandDB,
  addBuyerBrandDB,
  deleteBuyerBrandDB,
  editBuyerBrandDB,
  bulkDeleteBuyerBrandDB,
  retrieveBuyerBrandsByBuyerDB,
} from "../../../config/apiCalls/buyerBrands";

// Retrieve products
// worker saga
export function* retrieveBuyerBrands() {
  try {
    const response = yield call(retrieveBuyerBrandDB);
    if (response.status === 200) {
      yield put(downloadBuyerBrandsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadBuyerBrandsErrorAction());
  }
}

export function* retrieveBuyerBrandsByBuyer(action) {
  const buyerBrand = action.buyerBrand;

  try {
    const response = yield call(retrieveBuyerBrandsByBuyerDB, buyerBrand);
    if (response.status === 200) {
      yield put(downloadBuyerBrandsByBuyerOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadBuyerBrandsByBuyerErrorAction());
  }
}

// Create new product
// worker saga
export function* addBuyerBrand(action) {
  const buyerBrand = action.buyerBrand;

  try {
    const response = yield call(addBuyerBrandDB, buyerBrand);
    if (response.status === 201) {
      yield delay(500);
      yield put(addBuyerBrandOkAction(buyerBrand));
      toast.success("Buyer Brand created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addBuyerBrandErrorAction(true));
    toast.error("Buyer Brand created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteBuyerBrand(action) {
  const id = action.payload;
  try {
    yield call(deleteBuyerBrandDB, id);
    yield deleteBuyerBrandOkAction();
    toast.success("Buyer Brand deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteBuyerBrandErrorAction());
    toast.error("Buyer Brand deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkBuyerBrandDelete(action) {
  const buyerBrand = action.Brand;

  try {
    const response = yield call(bulkDeleteBuyerBrandDB, buyerBrand);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteBuyerBrandOkAction(buyerBrand));
      toast.success("Buyer Brands deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteBuyerBrandErrorAction(true));
    toast.error("Buyer Brands deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editBuyerBrand(action) {
  const buyerBrand = action.buyerBrand;

  try {
    const response = yield call(editBuyerBrandDB, buyerBrand);

    if (response.status === 201) {
      yield delay(500);
      yield put(editBuyerBrandOkAction(buyerBrand));
      toast.success("Buyer Brand edited successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editBuyerBrandErrorAction());
    toast.error("Buyer Brand edited failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchBuyerBrandsAsync() {
  yield takeLatest(BEGIN_BUYER_BRANDS_DOWNLOAD, retrieveBuyerBrands);
  yield takeLatest(
    BEGIN_BUYER_BRANDS_BY_BUYER_DOWNLOAD,
    retrieveBuyerBrandsByBuyer
  );
  yield takeLatest(ADD_BUYER_BRANDS, addBuyerBrand);
  yield takeLatest(RETRIEVE_BUYER_BRANDS_DELETE, deleteBuyerBrand);
  yield takeLatest(BEGIN_EDIT_BUYER_BRANDS, editBuyerBrand);
  yield takeLatest(RETRIEVE_BUYER_BRANDS_BULK_DELETE, bulkBuyerBrandDelete);
}
