export const ADD_PRODUCT_BRANDS = "ADD_PRODUCT_BRANDS";
export const ADD_PRODUCT_BRANDS_OK = "ADD_PRODUCT_BRANDS_OK";
export const ADD_PRODUCT_BRANDS_ERROR = "ADD_PRODUCT_BRANDS_ERROR";

export const BEGIN_PRODUCT_BRANDS_DOWNLOAD = "BEGIN_PRODUCT_BRANDS_DOWNLOAD";
export const PRODUCT_BRANDS_DOWNLOAD_OK = "PRODUCT_BRANDS_DOWNLOAD_OK";
export const PRODUCT_BRANDS_DOWNLOAD_ERROR = "PRODUCT_BRANDS_DOWNLOAD_ERROR";

export const RETRIEVE_PRODUCT_BRANDS_DELETE = "RETRIEVE_PRODUCT_BRANDS_DELETE";
export const PRODUCT_BRANDS_DELETED_OK = "PRODUCT_BRANDS_DELETED_OK";
export const PRODUCT_BRANDS_DELETED_ERROR = "PRODUCT_BRANDS_DELETED_ERROR";

export const RETRIEVE_PRODUCT_BRANDS_EDIT = "RETRIEVE_PRODUCT_BRANDS_EDITED";
export const BEGIN_EDIT_PRODUCT_BRANDS = "BEGIN_EDIT_PRODUCT_BRANDS";
export const PRODUCT_BRANDS_EDITED_OK = "PRODUCT_BRANDS_EDITED_OK";
export const PRODUCT_BRANDS_EDITED_ERROR = "PRODUCT_BRANDS_EDITED_ERROR";


export const RETRIEVE_PRODUCT_BRANDS_BULK_DELETE = "RETRIEVE_PRODUCT_BRANDS_BULK_DELETE";
export const PRODUCT_BRANDS_BULK_DELETED_OK = "PRODUCT_BRANDS_BULK_DELETED_OK";
export const PRODUCT_BRANDS_BULK_DELETED_ERROR = "PRODUCT_BRANDS_BULK_DELETED_ERROR";