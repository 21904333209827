export const ADD_BUYERS = "ADD_BUYERS";
export const ADD_BUYERS_OK = "ADD_BUYERS_OK";
export const ADD_BUYERS_ERROR = "ADD_BUYERS_ERROR";

export const BEGIN_BUYERS_DOWNLOAD = "BEGIN_BUYERS_DOWNLOAD";
export const BUYERS_DOWNLOAD_OK = "BUYERS_DOWNLOAD_OK";
export const BUYERS_DOWNLOAD_ERROR = "BUYERS_DOWNLOAD_ERROR";

export const RETRIEVE_BUYERS_EDIT = "RETRIEVE_BUYERS_EDITED";
export const BEGIN_EDIT_BUYERS = "BEGIN_EDIT_BUYERS";
export const BUYERS_EDITED_OK = "BUYERS_EDITED_OK";
export const BUYERS_EDITED_ERROR = "BUYERS_EDITED_ERROR";

export const RETRIEVE_BUYERS_DELETE = "RETRIEVE_BUYERS_DELETE";
export const BUYERS_DELETED_OK = "BUYERS_DELETED_OK";
export const BUYERS_DELETED_ERROR = "BUYERS_DELETED_ERROR";

export const RETRIEVE_BUYERS_BULK_DELETE = "RETRIEVE_BUYERS_BULK_DELETE";
export const BUYERS_BULK_DELETED_OK = "BUYERS_BULK_DELETED_OK";
export const BUYERS_BULK_DELETED_ERROR = "BUYERS_BULK_DELETED_ERROR";
