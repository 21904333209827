import {
  ADD_SEASONS, ADD_SEASONS_ERROR, ADD_SEASONS_OK, BEGIN_EDIT_SEASONS, BEGIN_SEASONS_DOWNLOAD, RETRIEVE_SEASONS_BULK_DELETE, RETRIEVE_SEASONS_DELETE, RETRIEVE_SEASONS_EDIT, SEASONS_BULK_DELETED_ERROR, SEASONS_BULK_DELETED_OK, SEASONS_DELETED_ERROR, SEASONS_DELETED_OK, SEASONS_DOWNLOAD_ERROR, SEASONS_DOWNLOAD_OK, SEASONS_EDITED_ERROR, SEASONS_EDITED_OK
} from "../../constants/modules/seasonsConstants.js";
  
  const initialState = {
    seasons: [],
    error: false,
    loading: false,
    deleteSeason: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_SEASONS_DOWNLOAD:
      case RETRIEVE_SEASONS_BULK_DELETE:
      case ADD_SEASONS:
      case BEGIN_EDIT_SEASONS:
        return {
          ...state,
          loading: action.payload,
          season: action.season,
        };
  
      case ADD_SEASONS_OK:
      case SEASONS_BULK_DELETED_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_SEASONS_ERROR:
      case SEASONS_DOWNLOAD_ERROR:
      case SEASONS_BULK_DELETED_ERROR:
      case SEASONS_DELETED_ERROR:
      case SEASONS_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case SEASONS_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          seasons: action.payload,
        };
  
      case RETRIEVE_SEASONS_DELETE:
        return {
          ...state,
          deleteSeason: action.payload,
        };
  
      case SEASONS_DELETED_OK:
        return {
          ...state,
          seasons: state.seasons.data.filter((season) => season.id !== state.deleteSeason),
          deleteSeason: null,
        };
  
      case RETRIEVE_SEASONS_EDIT:
        return {
          ...state,
          editSeason: action.payload,
        };
  
      case SEASONS_EDITED_OK:
        return {
          ...state,
          editSeason: null,
          seasons: state.seasons.data.map((season) =>
          season.id === action.payload.id ? (season = action.payload) : season
          ),
        };
  
      default:
        return state;
    }
  }
  