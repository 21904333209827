import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_COLORS,
  BEGIN_COLORS_DOWNLOAD,
  BEGIN_EDIT_COLORS,
  RETRIEVE_COLORS_BULK_DELETE,
  RETRIEVE_COLORS_DELETE
} from "../../constants/modules/colorsConstants";

import {
  addColorErrorAction,
  addColorOkAction,
  bulkDeleteColorErrorAction,
  bulkDeleteColorOkAction,
  deleteColorErrorAction,
  deleteColorOkAction,
  downloadColorsErrorAction,
  downloadColorsOkAction,
  editColorErrorAction,
  editColorOkAction
} from "../../actions/modules/colorsActions";

import {
  addColorDB,
  bulkDeleteColorDB,
  deleteColorDB,
  editColorDB,
  retrieveColorsData
} from "../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveColors() {
  try {
    const response = yield call(retrieveColorsData);

    if (response.status === 200) {
      yield put(downloadColorsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadColorsErrorAction());
  }
}

// Create new product
// worker saga
export function* addColor(action) {
  const color = action.color;

  try {
    const response = yield call(addColorDB, color);

    if (response.status === 201) {
      yield delay(500);

      yield put(addColorOkAction(color));
      toast.success("Color created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addColorErrorAction(true));
    toast.error("Color created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteColor(action) {
  const id = action.payload;
  try {
    yield call(deleteColorDB, id);
    yield deleteColorOkAction();
    toast.success("Color deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteColorErrorAction();
  }
}

// Edit product
// worker saga
export function* editColor(action) {
  const color = action.color;

  try {
    yield call(editColorDB, color);
    yield editColorOkAction(color);
    // Alert
    // Alert
    toast.success("Color has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editColorErrorAction();
  }
}

// BULK SAGAS
export function* bulkColorDelete(action) {
  const color = action.color;

  try {
    const response = yield call(bulkDeleteColorDB, color);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteColorOkAction(color));
      toast.success("Bulk Color deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteColorErrorAction(true));
    toast.error("Bulk Color deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchColorsAsync() {
  yield takeLatest(BEGIN_COLORS_DOWNLOAD, retrieveColors);
  yield takeLatest(ADD_COLORS, addColor);
  yield takeLatest(RETRIEVE_COLORS_DELETE, deleteColor);
  yield takeLatest(BEGIN_EDIT_COLORS, editColor);
  yield takeLatest(RETRIEVE_COLORS_BULK_DELETE, bulkColorDelete);
}
