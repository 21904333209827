import axiosClient from "../axiosClient";

export async function bulkDeleteProceedRealizationDetailDB(ids) {
  return await axiosClient.post(`/proceed_realization_dtls/bulk_delete`, ids);
}
export async function retrieveProceedRealizationDetailsData() {
  return await axiosClient.get("/proceed_realization_dtls");
}

export async function retrieveProceedRealizationDetailData(value) {
 
  return await axiosClient.post("/proceed_realizations_dtls", value);
}

export async function addProceedRealizationDetailDB(value) {
  return await axiosClient.post("/proceed_realization_dtls", value);
}

export async function deleteProceedRealizationDetailDB(id) {
  return await axiosClient.delete(`/proceed_realizations//${id}`);
}

export async function editProceedRealizationDetailDB(value) {
  return await axiosClient.put(`/proceed_realizations//${value.id}`, value);
}
