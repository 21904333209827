import axiosClient from "../axiosClient";

export async function bulkDeleteFactoryOrderDB(ids) {
  return await axiosClient.post(`/factory_orders/bulk_delete`, ids);
}

export async function retrieveFactoryOrderData(value) {
  const postData = {
    p_id: value ? value : null,
  };
  return await axiosClient.post("/factory_orders/all", postData);
}

export async function retrieveFactoryByOrderData(item) {
  let reId = {
    p_order_id: item,
  };
  return await axiosClient.post("/factory_orders/factory", reId);
}

// export async function retrieveTeamsByBuyerData(id) {
//   let reId = {
//     id: id,
//   };
//   return await axiosClient.post("/teams/get_by_id", reId);
// }

export async function addFactoryOrderDB(value) {
  var reValue = {
    p_order_id: value?.p_order_id,
    p_factory_id: value?.p_factory_id,
    p_factory_order_date: value?.p_factory_order_date,
    p_currency_id: value?.p_currency_id,
    p_created_by: value?.p_created_by,
    p_remarks: value?.p_remarks,
  };

  return await axiosClient.post("/factory_orders/generate", reValue);
}

export async function deleteFactoryOrderDB(id) {
  return await axiosClient.delete(`/factory_orders/${id}`);
}

export async function editFactoryOrderDB(value) {
  return await axiosClient.put(`/factory_orders/${value.id}`, value);
}
