import {
    ADD_APPROVAL_FORM_SETUPS,
    ADD_APPROVAL_FORM_SETUPS_ERROR,
    ADD_APPROVAL_FORM_SETUPS_OK,
    APPROVAL_FORM_SETUPS_DELETED_ERROR,
    APPROVAL_FORM_SETUPS_DELETED_OK,
    APPROVAL_FORM_SETUPS_DOWNLOAD_ERROR,
    APPROVAL_FORM_SETUPS_DOWNLOAD_OK,
    APPROVAL_FORM_SETUPS_EDITED_ERROR,
    APPROVAL_FORM_SETUPS_EDITED_OK,
    BEGIN_APPROVAL_FORM_SETUPS_DOWNLOAD,
    BEGIN_EDIT_APPROVAL_FORM_SETUPS,
    RETRIEVE_APPROVAL_FORM_SETUPS_DELETE,
    RETRIEVE_APPROVAL_FORM_SETUPS_EDIT
} from "../../constants/modules/approvalFormSetupConstant";

export const downloadApprovalFormSetupsAction = () => ({
  type: BEGIN_APPROVAL_FORM_SETUPS_DOWNLOAD,
  payload: true,
});

export const downloadApprovalFormSetupsOkAction = (items) => ({
  type: APPROVAL_FORM_SETUPS_DOWNLOAD_OK,
  payload: items,
});

export const downloadApprovalFormSetupsErrorAction = () => ({
  type: APPROVAL_FORM_SETUPS_DOWNLOAD_ERROR,
  payload: true,
});

export const addApprovalFormSetupAction = (item) => ({
  type: ADD_APPROVAL_FORM_SETUPS,
  payload: true,
  approvalFormSetup: item,
});

export const addApprovalFormSetupOkAction = () => ({
  type: ADD_APPROVAL_FORM_SETUPS_OK,
  payload: true,
});

export const addApprovalFormSetupErrorAction = (state) => ({
  type: ADD_APPROVAL_FORM_SETUPS_ERROR,
  payload: state,
});

export const deleteApprovalFormSetupAction = (id) => ({
  type: RETRIEVE_APPROVAL_FORM_SETUPS_DELETE,
  payload: id,
});

export const deleteApprovalFormSetupOkAction = () => ({
  type: APPROVAL_FORM_SETUPS_DELETED_OK,
});

export const deleteApprovalFormSetupErrorAction = () => ({
  type: APPROVAL_FORM_SETUPS_DELETED_ERROR,
  payload: true,
});

export const retrieveApprovalFormSetupAction = (item) => ({
  type: RETRIEVE_APPROVAL_FORM_SETUPS_EDIT,
  payload: item,
});

export const editApprovalFormSetupAction = (item) => ({
  type: BEGIN_EDIT_APPROVAL_FORM_SETUPS,
  approvalFormSetup: item,
});

export const editApprovalFormSetupOkAction = (item) => ({
  type: APPROVAL_FORM_SETUPS_EDITED_OK,
  payload: item,
});

export const editApprovalFormSetupErrorAction = () => ({
  type: APPROVAL_FORM_SETUPS_EDITED_ERROR,
  payload: true,
});
