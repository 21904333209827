import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_SAMPLE_TYPES,
  BEGIN_EDIT_SAMPLE_TYPES,
  BEGIN_SAMPLE_TYPES_DOWNLOAD,
  RETRIEVE_SAMPLE_TYPES_BULK_DELETE,
  RETRIEVE_SAMPLE_TYPES_DELETE
} from "../../constants/modules/sampleTypesConstant";

import {
  addSampleTypeErrorAction,
  addSampleTypeOkAction,
  bulkDeleteSampleTypeErrorAction,
  bulkDeleteSampleTypeOkAction,
  deleteSampleTypeErrorAction,
  deleteSampleTypeOkAction,
  downloadSampleTypesErrorAction,
  downloadSampleTypesOkAction,
  editSampleTypeErrorAction,
  editSampleTypeOkAction
} from "../../actions/modules/sampleTypesActions";

import {
  addSampleTypeDB,
  bulkDeleteSampleTypeDB,
  deleteSampleTypeDB,
  editSampleTypeDB,
  retrieveSampleTypesData
} from "../../../config/apiCalls/sampleTypesAPICall";

// Retrieve products
// worker saga
export function* retrieveSampleType() {
  try {
    const response = yield call(retrieveSampleTypesData);

    if (response.status === 200) {
      yield put(downloadSampleTypesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSampleTypesErrorAction());
  }
}

// Create new product
// worker saga
export function* addSampleType(action) {
  const sampleType = action.sampleType;

  console.log(sampleType);

  try {
    const response = yield call(addSampleTypeDB, sampleType);

    if (response.status === 201) {
      yield delay(500);

      yield put(addSampleTypeOkAction(sampleType));
      toast.success("SampleType created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addSampleTypeErrorAction(true));
    toast.error("SampleType created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteSampleType(action) {
  const id = action.payload;
  try {
    yield call(deleteSampleTypeDB, id);
    yield deleteSampleTypeOkAction();
    toast.success("SampleType deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteSampleTypeErrorAction();
  }
}

// Edit product
// worker saga
export function* editSampleType(action) {
  const sampleType = action.sampleType;

  try {
    yield call(editSampleTypeDB, sampleType);
    yield editSampleTypeOkAction(sampleType);
    // Alert
    // Alert
    toast.success("SampleType updated Successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editSampleTypeErrorAction();
  }
}

// BULK SAGAS
export function* bulkSampleTypeDelete(action) {
  const sampleType = action.sampleType;

  try {
    const response = yield call(bulkDeleteSampleTypeDB, sampleType);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteSampleTypeOkAction(sampleType));
      toast.success("Bulk SampleType deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteSampleTypeErrorAction(true));
    toast.error("Bulk SampleType deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchSampleTypesAsync() {
  yield takeLatest(BEGIN_SAMPLE_TYPES_DOWNLOAD, retrieveSampleType);
  yield takeLatest(ADD_SAMPLE_TYPES, addSampleType);
  yield takeLatest(RETRIEVE_SAMPLE_TYPES_DELETE, deleteSampleType);
  yield takeLatest(BEGIN_EDIT_SAMPLE_TYPES, editSampleType);
  yield takeLatest(RETRIEVE_SAMPLE_TYPES_BULK_DELETE, bulkSampleTypeDelete);
}
