export const ADD_SALES_CONTRACT_ACTIONS = "ADD_SALES_CONTRACT_ACTIONS";
export const ADD_SALES_CONTRACT_ACTIONS_OK = "ADD_SALES_CONTRACT_ACTIONS_OK";
export const ADD_SALES_CONTRACT_ACTIONS_ERROR =
  "ADD_SALES_CONTRACT_ACTIONS_ERROR";

export const BEGIN_SALES_CONTRACT_ACTIONS_DOWNLOAD =
  "BEGIN_SALES_CONTRACT_ACTIONS_DOWNLOAD";
export const SALES_CONTRACT_ACTIONS_DOWNLOAD_OK =
  "SALES_CONTRACT_ACTIONS_DOWNLOAD_OK";
export const SALES_CONTRACT_ACTIONS_DOWNLOAD_ERROR =
  "SALES_CONTRACT_ACTIONS_DOWNLOAD_ERROR";

export const RETRIEVE_SALES_CONTRACT_ACTIONS_DELETE =
  "RETRIEVE_SALES_CONTRACT_ACTIONS_DELETE";
export const SALES_CONTRACT_ACTIONS_DELETED_OK =
  "SALES_CONTRACT_ACTIONS_DELETED_OK";
export const SALES_CONTRACT_ACTIONS_DELETED_ERROR =
  "SALES_CONTRACT_ACTIONS_DELETED_ERROR";

export const RETRIEVE_SALES_CONTRACT_ACTIONS_EDIT =
  "RETRIEVE_SALES_CONTRACT_ACTIONS_EDITED";
export const BEGIN_EDIT_SALES_CONTRACT_ACTIONS =
  "BEGIN_EDIT_SALES_CONTRACT_ACTIONS";
export const SALES_CONTRACT_ACTIONS_EDITED_OK =
  "SALES_CONTRACT_ACTIONS_EDITED_OK";
export const SALES_CONTRACT_ACTIONS_EDITED_ERROR =
  "SALES_CONTRACT_ACTIONS_EDITED_ERROR";

export const RETRIEVE_SALES_CONTRACT_ACTIONS_BULK_DELETE =
  "RETRIEVE_SALES_CONTRACT_ACTIONS_BULK_DELETE";
export const SALES_CONTRACT_ACTIONS_BULK_DELETED_OK =
  "SALES_CONTRACT_ACTIONS_BULK_DELETED_OK";
export const SALES_CONTRACT_ACTIONS_BULK_DELETED_ERROR =
  "SALES_CONTRACT_ACTIONS_BULK_DELETED_ERROR";
