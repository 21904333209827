import {
  ADD_PRODUCT_BRANDS,
  ADD_PRODUCT_BRANDS_ERROR,
  ADD_PRODUCT_BRANDS_OK,
  BEGIN_EDIT_PRODUCT_BRANDS,
  BEGIN_PRODUCT_BRANDS_DOWNLOAD,
  PRODUCT_BRANDS_BULK_DELETED_ERROR,
  PRODUCT_BRANDS_BULK_DELETED_OK,
  PRODUCT_BRANDS_DELETED_ERROR,
  PRODUCT_BRANDS_DELETED_OK,
  PRODUCT_BRANDS_DOWNLOAD_ERROR,
  PRODUCT_BRANDS_DOWNLOAD_OK,
  PRODUCT_BRANDS_EDITED_ERROR,
  PRODUCT_BRANDS_EDITED_OK,
  RETRIEVE_PRODUCT_BRANDS_BULK_DELETE,
  RETRIEVE_PRODUCT_BRANDS_DELETE,
  RETRIEVE_PRODUCT_BRANDS_EDIT,
} from "../../constants/modules/productBrandsConstants";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteProductBrandAction = (item) => ({
  type: RETRIEVE_PRODUCT_BRANDS_BULK_DELETE,
  payload: true,
  productBrand: item,
});

export const bulkDeleteProductBrandOkAction = () => ({
  type: PRODUCT_BRANDS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteProductBrandErrorAction = (state) => ({
  type: PRODUCT_BRANDS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadProductBrandsAction = () => ({
  type: BEGIN_PRODUCT_BRANDS_DOWNLOAD,
  payload: true,
});

export const downloadProductBrandsOkAction = (items) => ({
  type: PRODUCT_BRANDS_DOWNLOAD_OK,
  payload: items,
});

export const downloadProductBrandsErrorAction = () => ({
  type: PRODUCT_BRANDS_DOWNLOAD_ERROR,
  payload: true,
});

export const addProductBrandAction = (item) => ({
  type: ADD_PRODUCT_BRANDS,
  payload: true,
  productBrand: item,
});

export const addProductBrandOkAction = () => ({
  type: ADD_PRODUCT_BRANDS_OK,
  payload: true,
});

export const addProductBrandErrorAction = (state) => ({
  type: ADD_PRODUCT_BRANDS_ERROR,
  payload: state,
});

export const deleteProductBrandAction = (id) => ({
  type: RETRIEVE_PRODUCT_BRANDS_DELETE,
  payload: id,
});

export const deleteProductBrandOkAction = () => ({
  type: PRODUCT_BRANDS_DELETED_OK,
});

export const deleteProductBrandErrorAction = () => ({
  type: PRODUCT_BRANDS_DELETED_ERROR,
  payload: true,
});

export const retrieveProductBrandAction = (item) => ({
  type: RETRIEVE_PRODUCT_BRANDS_EDIT,
  payload: item,
});

export const editProductBrandAction = (item) => ({
  type: BEGIN_EDIT_PRODUCT_BRANDS,
  productBrand: item,
});

export const editProductBrandOkAction = (item) => ({
  type: PRODUCT_BRANDS_EDITED_OK,
  payload: item,
});

export const editProductBrandErrorAction = () => ({
  type: PRODUCT_BRANDS_EDITED_ERROR,
  payload: true,
});
