import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_PORTS,
  BEGIN_EDIT_PORTS,
  BEGIN_PORTS_DOWNLOAD,
  RETRIEVE_PORTS_BULK_DELETE,
  RETRIEVE_PORTS_DELETE
} from "../../constants/modules/portsConstants";

import {
  addPortErrorAction,
  addPortOkAction,
  bulkDeletePortErrorAction,
  bulkDeletePortOkAction,
  deletePortErrorAction,
  deletePortOkAction,
  downloadPortsErrorAction,
  downloadPortsOkAction,
  editPortErrorAction,
  editPortOkAction
} from "../../actions/modules/portsActions";

import {
  addPortDB,
  bulkDeletePortDB,
  deletePortDB,
  editPortDB,
  retrievePortsData
} from "../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrievePorts() {
  try {
    const response = yield call(retrievePortsData);

    if (response.status === 200) {
      yield put(downloadPortsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadPortsErrorAction());
  }
}

// Create new product
// worker saga
export function* addPort(action) {
  const port = action.port;

  try {
    const response = yield call(addPortDB, port);

    if (response.status === 201) {
      yield delay(2000);

      yield put(addPortOkAction(port));
      toast.success("Port created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addPortErrorAction(true));
    toast.error("Port created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkPortDelete(action) {
  const port = action.port;

  try {
    const response = yield call(bulkDeletePortDB, port);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeletePortOkAction(port));
      toast.success("Bulk Port deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeletePortErrorAction(true));
    toast.error("Bulk Port deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deletePort(action) {
  const id = action.payload;
  try {
    yield call(deletePortDB, id);
    yield deletePortOkAction();
    toast.success("Port deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deletePortErrorAction();
  }
}

// Edit product
// worker saga
export function* editPort(action) {
  const port = action.port;

  try {
    yield call(editPortDB, port);
    yield editPortOkAction(port);
    // Alert
    toast.success("Port has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editPortErrorAction();
  }
}

export function* watchPortsAsync() {
  yield takeLatest(BEGIN_PORTS_DOWNLOAD, retrievePorts);
  yield takeLatest(ADD_PORTS, addPort);
  yield takeLatest(RETRIEVE_PORTS_DELETE, deletePort);
  yield takeLatest(BEGIN_EDIT_PORTS, editPort);
  yield takeLatest(RETRIEVE_PORTS_BULK_DELETE, bulkPortDelete);
}
