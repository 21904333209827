import {
    ADD_FACTORY_INVOICES,
    ADD_FACTORY_INVOICES_ERROR,
    ADD_FACTORY_INVOICES_OK,
    BEGIN_EDIT_FACTORY_INVOICES, BEGIN_FACTORY_INVOICES_DOWNLOAD, BEGIN_FACTORY_INVOICE_DOWNLOAD, FACTORY_INVOICES_BULK_DELETED_ERROR,
    FACTORY_INVOICES_BULK_DELETED_OK,
    FACTORY_INVOICES_DELETED_ERROR,
    FACTORY_INVOICES_DELETED_OK,
    FACTORY_INVOICES_DOWNLOAD_ERROR,
    FACTORY_INVOICES_DOWNLOAD_OK,
    FACTORY_INVOICES_EDITED_ERROR,
    FACTORY_INVOICES_EDITED_OK,
    FACTORY_INVOICE_DOWNLOAD_ERROR,
    FACTORY_INVOICE_DOWNLOAD_OK, RESET,
    RETRIEVE_FACTORY_INVOICES_BULK_DELETE,
    RETRIEVE_FACTORY_INVOICES_DELETE,
    RETRIEVE_FACTORY_INVOICES_EDIT
} from "../../constants/modules/factoryInvoiceConstant";
  
  const initialState = {
    factoryInvoices: [],
    getFactoryInvoice: null,
    factoryInvoiceAddInfo: null,
    error: false,
    loading: false,
    deleteFactoryInvoice: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_FACTORY_INVOICES_DOWNLOAD:
      case BEGIN_FACTORY_INVOICE_DOWNLOAD:
      case RETRIEVE_FACTORY_INVOICES_BULK_DELETE:
      case ADD_FACTORY_INVOICES:
      case BEGIN_EDIT_FACTORY_INVOICES:
        return {
          ...state,
          loading: action.payload,
          factoryInvoice: action.factoryInvoice,
        };
  
      case FACTORY_INVOICES_BULK_DELETED_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_FACTORY_INVOICES_ERROR:
      case FACTORY_INVOICES_DOWNLOAD_ERROR:
      case FACTORY_INVOICES_BULK_DELETED_ERROR:
      case FACTORY_INVOICE_DOWNLOAD_ERROR:
      case FACTORY_INVOICES_DELETED_ERROR:
      case FACTORY_INVOICES_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case FACTORY_INVOICES_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          factoryInvoices: action.payload,
        };
  
      case FACTORY_INVOICE_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          getFactoryInvoice: action.payload,
        };
  
      case ADD_FACTORY_INVOICES_OK:
        return {
          ...state,
          loading: false,
          error: null,
          factoryInvoiceAddInfo: action.payload,
        };
  
      case RETRIEVE_FACTORY_INVOICES_DELETE:
        return {
          ...state,
          deleteFactoryInvoice: action.payload,
        };
  
      case FACTORY_INVOICES_DELETED_OK:
        return {
          ...state,
          factoryInvoices: state.factoryInvoices.data.filter(
            (factoryInvoice) => factoryInvoice.id !== state.deleteFactoryInvoice
          ),
          deleteFactoryInvoice: null,
        };
  
      case RETRIEVE_FACTORY_INVOICES_EDIT:
        return {
          ...state,
          editFactoryInvoice: action.payload,
        };
  
      case FACTORY_INVOICES_EDITED_OK:
        return {
          ...state,
          editFactoryInvoices: null,
          factoryInvoices: state.factoryInvoices.data.map((factoryInvoice) =>
            factoryInvoice.id === action.payload.id
              ? (factoryInvoice = action.payload)
              : factoryInvoice
          ),
        };
  
      case RESET:
        return {
          ...state,
          getFactoryInvoice: null,
          factoryInvoiceAddInfo: null,
        };
  
      default:
        return state;
    }
  }
  