import {
  ADD_FABRIC_TYPES,
  ADD_FABRIC_TYPES_ERROR,
  ADD_FABRIC_TYPES_OK,
  BEGIN_EDIT_FABRIC_TYPES,
  BEGIN_FABRIC_TYPES_DOWNLOAD,
  FABRIC_TYPES_BULK_DELETED_ERROR,
  FABRIC_TYPES_BULK_DELETED_OK,
  FABRIC_TYPES_DELETED_ERROR,
  FABRIC_TYPES_DELETED_OK,
  FABRIC_TYPES_DOWNLOAD_ERROR,
  FABRIC_TYPES_DOWNLOAD_OK,
  FABRIC_TYPES_EDITED_ERROR,
  FABRIC_TYPES_EDITED_OK,
  RETRIEVE_FABRIC_TYPES_BULK_DELETE,
  RETRIEVE_FABRIC_TYPES_DELETE,
  RETRIEVE_FABRIC_TYPES_EDIT,
  BEGIN_FABRIC_TYPES_BY_FK_DOWNLOAD,
  FABRIC_TYPES_BY_FK_DOWNLOAD_OK,
  FABRIC_TYPES_BY_FK_DOWNLOAD_ERROR,
} from "../../constants/modules/fabricsTypeConstants.js";

const initialState = {
  fabricTypes: [],
  fabricTypesByFk: [],
  error: false,
  loading: false,
  deleteFabricType: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_FABRIC_TYPES_DOWNLOAD:
    case BEGIN_FABRIC_TYPES_BY_FK_DOWNLOAD:
    case ADD_FABRIC_TYPES:
    case RETRIEVE_FABRIC_TYPES_BULK_DELETE:
    case BEGIN_EDIT_FABRIC_TYPES:
      return {
        ...state,
        loading: action.payload,
        fabricType: action.fabricType,
      };

    case ADD_FABRIC_TYPES_OK:
    case FABRIC_TYPES_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_FABRIC_TYPES_ERROR:
    case FABRIC_TYPES_DOWNLOAD_ERROR:
    case FABRIC_TYPES_BY_FK_DOWNLOAD_ERROR:
    case FABRIC_TYPES_BULK_DELETED_ERROR:
    case FABRIC_TYPES_DELETED_ERROR:
    case FABRIC_TYPES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FABRIC_TYPES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        fabricType: action.payload,
      };

    case FABRIC_TYPES_BY_FK_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        fabricTypesByFk: action.payload,
      };

    case RETRIEVE_FABRIC_TYPES_DELETE:
      return {
        ...state,
        deleteFabricType: action.payload,
      };

    case FABRIC_TYPES_DELETED_OK:
      return {
        ...state,
        fabricTypes: state.fabricTypes.data.filter(
          (fabricType) => fabricType.id !== state.deleteFabricType
        ),
        deleteFabricType: null,
      };

    case RETRIEVE_FABRIC_TYPES_EDIT:
      return {
        ...state,
        editFabricType: action.payload,
      };

    case FABRIC_TYPES_EDITED_OK:
      return {
        ...state,
        editFabricType: null,
        fabricTypes: state.fabricTypes.data.map((fabricType) =>
          fabricType.id === action.payload.id
            ? (fabricType = action.payload)
            : fabricType
        ),
      };

    default:
      return state;
  }
}
