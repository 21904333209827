import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_PRODUCT_TYPES,
  BEGIN_EDIT_PRODUCT_TYPES,
  BEGIN_PRODUCT_TYPES_DOWNLOAD,
  RETRIEVE_PRODUCT_TYPES_BULK_DELETE,
  RETRIEVE_PRODUCT_TYPES_DELETE
} from "../../constants/modules/productTypeConstants";

import {
  addProductTypeErrorAction,
  addProductTypeOkAction,
  bulkDeleteProductTypeErrorAction,
  bulkDeleteProductTypeOkAction,
  deleteProductTypeErrorAction,
  deleteProductTypeOkAction,
  downloadProductTypesErrorAction,
  downloadProductTypesOkAction,
  editProductTypeErrorAction,
  editProductTypeOkAction
} from "../../actions/modules/productTypeActions";

import {
  addProductTypeDB,
  bulkDeleteProductTypeDB,
  deleteProductTypeDB,
  editProductTypeDB,
  retrieveProductTypesData
} from "../../../config/apiCalls/productTypes";

// Retrieve products
// worker saga
export function* retrieveProductTypes() {
  try {
    const response = yield call(retrieveProductTypesData);

    if (response.status === 200) {
      yield put(downloadProductTypesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadProductTypesErrorAction());
  }
}

// Create new product
// worker saga
export function* addProductType(action) {
  const productType = action.productType;

  try {
    const response = yield call(addProductTypeDB, productType);

    if (response.status === 201) {
      yield delay(500);

      yield put(addProductTypeOkAction(productType));
      toast.success("Product Type created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addProductTypeErrorAction(true));
    toast.error("Product Type created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteProductType(action) {
  const id = action.payload;
  try {
    yield call(deleteProductTypeDB, id);
    yield deleteProductTypeOkAction();
    toast.success("Product Type deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteProductTypeErrorAction();
  }
}

// Edit product
// worker saga
export function* editProductType(action) {
  const productType = action.productType;

  try {
    yield call(editProductTypeDB, productType);
    yield editProductTypeOkAction(productType);
    // Alert
    // Alert
    toast.success("Product Type has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editProductTypeErrorAction();
  }
}
// BULK SAGAS
export function* bulkProductTypeDelete(action) {
  const productType = action.productType;

  try {
    const response = yield call(bulkDeleteProductTypeDB, productType);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteProductTypeOkAction(productType));
      toast.success("Bulk Product Type deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteProductTypeErrorAction(true));
    toast.error("Bulk Product Type deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchProductTypesAsync() {
  yield takeLatest(BEGIN_PRODUCT_TYPES_DOWNLOAD, retrieveProductTypes);
  yield takeLatest(ADD_PRODUCT_TYPES, addProductType);
  yield takeLatest(RETRIEVE_PRODUCT_TYPES_DELETE, deleteProductType);
  yield takeLatest(BEGIN_EDIT_PRODUCT_TYPES, editProductType);
  yield takeLatest(RETRIEVE_PRODUCT_TYPES_BULK_DELETE, bulkProductTypeDelete);
}
