import {
  ADD_PORTS,
  ADD_PORTS_ERROR,
  ADD_PORTS_OK,
  BEGIN_EDIT_PORTS,
  BEGIN_PORTS_DOWNLOAD,
  PORTS_BULK_DELETED_ERROR,
  PORTS_BULK_DELETED_OK,
  PORTS_DELETED_ERROR,
  PORTS_DELETED_OK,
  PORTS_DOWNLOAD_ERROR,
  PORTS_DOWNLOAD_OK,
  PORTS_EDITED_ERROR,
  PORTS_EDITED_OK,
  RETRIEVE_PORTS_BULK_DELETE,
  RETRIEVE_PORTS_DELETE,
  RETRIEVE_PORTS_EDIT,
} from "../../constants/modules/portsConstants.js";

const initialState = {
  ports: [],
  error: false,
  loading: false,
  deletePort: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_PORTS_DOWNLOAD:
    case ADD_PORTS:
    case RETRIEVE_PORTS_BULK_DELETE:
    case BEGIN_EDIT_PORTS:
      return {
        ...state,
        loading: action.payload,
        port: action.port,
      };

    case ADD_PORTS_OK:
    case PORTS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_PORTS_ERROR:
    case PORTS_DOWNLOAD_ERROR:
    case PORTS_DELETED_ERROR:
    case PORTS_BULK_DELETED_ERROR:
    case PORTS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PORTS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        port: action.payload,
      };

    case RETRIEVE_PORTS_DELETE:
      return {
        ...state,
        deletePort: action.payload,
      };

    case PORTS_DELETED_OK:
      return {
        ...state,
        ports: state.ports.data.filter((port) => port.id !== state.deletePort),
        deletePort: null,
      };

    case RETRIEVE_PORTS_EDIT:
      return {
        ...state,
        editPort: action.payload,
      };

    case PORTS_EDITED_OK:
      return {
        ...state,
        editPort: null,
        ports: state.ports.data.map((port) =>
          port.id === action.payload.id ? (port = action.payload) : port
        ),
      };

    default:
      return state;
  }
}
