import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_OK,
  LOGIN_REQUEST_ERROR,
} from "../../constants/auth/authConstants";

export const loginAction = (item) => ({
  type: LOGIN_REQUEST,
  payload: true,
  token: item,
});

export const loginOkAction = (items) => ({
  type: LOGIN_REQUEST_OK,
  payload: items,
});

export const loginErrorAction = (state) => ({
  type: LOGIN_REQUEST_ERROR,
  payload: state,
});
