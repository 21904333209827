import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_SHIPMENT_DETAILS,
  BEGIN_SHIPMENT_DETAILS_DOWNLOAD,
  RETRIEVE_SHIPMENT_DETAILS_DELETE,
  BEGIN_EDIT_SHIPMENT_DETAILS,
  RETRIEVE_SHIPMENT_DETAILS_BULK_DELETE,
} from "../../constants/modules/shipmentDetailsConstants";

import {
  downloadShipmentDetailsOkAction,
  downloadShipmentDetailsErrorAction,
  addShipmentDetailOkAction,
  addShipmentDetailErrorAction,
  deleteShipmentDetailOkAction,
  deleteShipmentDetailErrorAction,
  editShipmentDetailOkAction,
  editShipmentDetailErrorAction,
  bulkDeleteShipmentDetailOkAction,
  bulkDeleteShipmentDetailErrorAction,
} from "../../actions/modules/shipmentDetailsActions";

import {
  retrieveShipmentDetailDB,
  addShipmentDetailDB,
  deleteShipmentDetailDB,
  editShipmentDetailDB,
  bulkDeleteShipmentDetailDB,
} from "../../../config/apiCalls/shipmentDetails";

// Retrieve products
// worker saga
export function* retrieveShipmentDetails(action) {
  const shipmentDetail = action.shipmentDetail;

  try {
    const response = yield call(retrieveShipmentDetailDB, shipmentDetail);
    if (response.status === 200) {
      yield put(downloadShipmentDetailsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadShipmentDetailsErrorAction());
  }
}

// Create new product
// worker saga
export function* addShipmentDetail(action) {
  const shipmentDetail = action.shipmentDetail;

  try {
    const response = yield call(addShipmentDetailDB, shipmentDetail);

    if (response.status === 200) {
      yield delay(500);
      yield put(addShipmentDetailOkAction(shipmentDetail));
      toast.success("Shipment Detail created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addShipmentDetailErrorAction(true));
    toast.error("Shipment Detail created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteShipmentDetail(action) {
  const id = action.payload;
  try {
    yield call(deleteShipmentDetailDB, id);
    yield deleteShipmentDetailOkAction();
    toast.success("ShipmentDetail deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteShipmentDetailErrorAction());
    toast.error("ShipmentDetail deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkShipmentDetailDelete(action) {
  const shipmentDetail = action.shipmentDetail;

  try {
    const response = yield call(bulkDeleteShipmentDetailDB, shipmentDetail);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteShipmentDetailOkAction(shipmentDetail));
      toast.success("Shipment Details deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteShipmentDetailErrorAction(true));
    toast.error("Shipment Details deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editShipmentDetail(action) {
  const shipmentDetail = action.shipmentDetail;

  try {
    const response = yield call(editShipmentDetailDB, shipmentDetail);

    if (response.status === 201) {
      yield delay(500);
      yield put(editShipmentDetailOkAction(shipmentDetail));
      toast.success("Shipment Detail edited successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editShipmentDetailErrorAction());
    toast.error("Shipment Detail deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchShipmentDetailsAsync() {
  yield takeLatest(BEGIN_SHIPMENT_DETAILS_DOWNLOAD, retrieveShipmentDetails);
  yield takeLatest(ADD_SHIPMENT_DETAILS, addShipmentDetail);
  yield takeLatest(RETRIEVE_SHIPMENT_DETAILS_DELETE, deleteShipmentDetail);
  yield takeLatest(BEGIN_EDIT_SHIPMENT_DETAILS, editShipmentDetail);
  yield takeLatest(
    RETRIEVE_SHIPMENT_DETAILS_BULK_DELETE,
    bulkShipmentDetailDelete
  );
}
