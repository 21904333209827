import {
  ADD_SAMPLE_ORDERS,
  ADD_SAMPLE_ORDERS_ERROR,
  ADD_SAMPLE_ORDERS_OK,
  BEGIN_EDIT_SAMPLE_ORDERS,
  BEGIN_SAMPLE_ORDERS_DOWNLOAD,
  RETRIEVE_SAMPLE_ORDERS_BULK_DELETE,
  RETRIEVE_SAMPLE_ORDERS_DELETE,
  RETRIEVE_SAMPLE_ORDERS_EDIT,
  SAMPLE_ORDERS_BULK_DELETED_ERROR,
  SAMPLE_ORDERS_BULK_DELETED_OK,
  SAMPLE_ORDERS_DELETED_ERROR,
  SAMPLE_ORDERS_DELETED_OK,
  SAMPLE_ORDERS_DOWNLOAD_ERROR,
  SAMPLE_ORDERS_DOWNLOAD_OK,
  SAMPLE_ORDERS_EDITED_ERROR,
  SAMPLE_ORDERS_EDITED_OK,
} from "../../constants/modules/sampleOrdersConstants";

const initialState = {
  sampleOrders: [],
  error: false,
  loading: false,
  deleteSampleOrder: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_SAMPLE_ORDERS_DOWNLOAD:
    case ADD_SAMPLE_ORDERS:
    case RETRIEVE_SAMPLE_ORDERS_BULK_DELETE:
    case BEGIN_EDIT_SAMPLE_ORDERS:
      return {
        ...state,
        loading: action.payload,
        sampleOrder: action.sampleOrder,
      };

    case ADD_SAMPLE_ORDERS_OK:
    case SAMPLE_ORDERS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_SAMPLE_ORDERS_ERROR:
    case SAMPLE_ORDERS_DOWNLOAD_ERROR:
    case SAMPLE_ORDERS_DELETED_ERROR:
    case SAMPLE_ORDERS_BULK_DELETED_ERROR:
    case SAMPLE_ORDERS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SAMPLE_ORDERS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        sampleOrders: action.payload,
      };

    case RETRIEVE_SAMPLE_ORDERS_DELETE:
      return {
        ...state,
        deleteSampleOrder: action.payload,
      };

    case SAMPLE_ORDERS_DELETED_OK:
      return {
        ...state,
        sampleOrders: state.sampleOrders.data.filter(
          (sampleOrder) => sampleOrder.id !== state.deleteSampleOrder
        ),
        deleteSampleOrder: null,
      };

    case RETRIEVE_SAMPLE_ORDERS_EDIT:
      return {
        ...state,
        editSampleOrder: action.payload,
      };

    case SAMPLE_ORDERS_EDITED_OK:
      return {
        ...state,
        editSampleOrder: null,
        sampleOrders: state.sampleOrders.data.map((sampleOrder) =>
          sampleOrder.id === action.payload.id
            ? (sampleOrder = action.payload)
            : sampleOrder
        ),
      };

    default:
      return state;
  }
}
