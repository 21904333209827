import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*             SAMPLE STATUS API CALLS             */
/*-------------------------------------------------*/

export async function retrieveSampleStylesData() {
  return await axiosClient.get("/sample_styles");
}

export async function addSampleStyleDB(value) {
  console.log("postValues", value);

  var bodyFormData = new FormData();

  bodyFormData.append("buyer_id", value.buyer_id);
  bodyFormData.append("style_image", value.style_image);
  bodyFormData.append("buyer_user_id", value.buyer_user_id);
  bodyFormData.append("merchandiser_user_id", value.merchandiser_user_id);
  bodyFormData.append("document_no", value.document_no);
  bodyFormData.append("document_date", value.document_date);
  bodyFormData.append("style", value.style);
  bodyFormData.append("remarks", value.remarks);
  bodyFormData.append("style_doc", value.style_doc);
  bodyFormData.append("created_by", value.created_by);
  bodyFormData.append("created_date", value.created_date);
  bodyFormData.append("modified_by", value.modified_by);
  bodyFormData.append("modified_date", value.modified_date);

  return await axiosClient.post("/sample_styles", bodyFormData);
}

export async function deleteSampleStyleDB(id) {
  return await axiosClient.delete(`/sample_styles/${id}`);
}

export async function bulkDeleteSampleStyleDB(ids) {
  return await axiosClient.post(`/sample_styles/bulk_delete`, ids);
}

export async function editSampleStyleDB(value) {
  var bodyFormData = new FormData();

  bodyFormData.append("buyer_id", value.buyer_id);
  bodyFormData.append("style_image", value.style_image);
  bodyFormData.append("buyer_user_id", value.buyer_user_id);
  bodyFormData.append("merchandiser_user_id", value.merchandiser_user_id);
  bodyFormData.append("document_no", value.document_no);
  bodyFormData.append("document_date", value.document_date);
  bodyFormData.append("style", value.style);
  bodyFormData.append("remarks", value.remarks);
  bodyFormData.append("style_doc", value.style_doc);
  bodyFormData.append("created_by", value.created_by);
  bodyFormData.append("created_date", value.created_date);
  bodyFormData.append("modified_by", value.modified_by);
  bodyFormData.append("modified_date", value.modified_date);

  return await axiosClient.put(`/sample_styles/${value.id}`, bodyFormData);
}
