import {
  ADD_SAMPLE_ORDERS,
  ADD_SAMPLE_ORDERS_ERROR,
  ADD_SAMPLE_ORDERS_OK,
  BEGIN_EDIT_SAMPLE_ORDERS,
  BEGIN_SAMPLE_ORDERS_DOWNLOAD,
  RETRIEVE_SAMPLE_ORDERS_BULK_DELETE,
  RETRIEVE_SAMPLE_ORDERS_DELETE,
  RETRIEVE_SAMPLE_ORDERS_EDIT,
  SAMPLE_ORDERS_BULK_DELETED_ERROR,
  SAMPLE_ORDERS_BULK_DELETED_OK,
  SAMPLE_ORDERS_DELETED_ERROR,
  SAMPLE_ORDERS_DELETED_OK,
  SAMPLE_ORDERS_DOWNLOAD_ERROR,
  SAMPLE_ORDERS_DOWNLOAD_OK,
  SAMPLE_ORDERS_EDITED_ERROR,
  SAMPLE_ORDERS_EDITED_OK
} from "../../constants/modules/sampleOrdersConstants";

export const bulkDeleteSampleOrderAction = (item) => ({
  type: RETRIEVE_SAMPLE_ORDERS_BULK_DELETE,
  payload: true,
  sampleOrder: item,
});

export const bulkDeleteSampleOrderOkAction = () => ({
  type: SAMPLE_ORDERS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteSampleOrderErrorAction = (state) => ({
  type: SAMPLE_ORDERS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadSampleOrdersAction = () => ({
  type: BEGIN_SAMPLE_ORDERS_DOWNLOAD,
  payload: true,
});

export const downloadSampleOrdersOkAction = (items) => ({
  type: SAMPLE_ORDERS_DOWNLOAD_OK,
  payload: items,
});

export const downloadSampleOrdersErrorAction = () => ({
  type: SAMPLE_ORDERS_DOWNLOAD_ERROR,
  payload: true,
});

export const addSampleOrderAction = (item) => ({
  type: ADD_SAMPLE_ORDERS,
  payload: true,
  sampleOrder: item,
});

export const addSampleOrderOkAction = () => ({
  type: ADD_SAMPLE_ORDERS_OK,
  payload: true,
});

export const addSampleOrderErrorAction = (state) => ({
  type: ADD_SAMPLE_ORDERS_ERROR,
  payload: state,
});

export const deleteSampleOrderAction = (id) => ({
  type: RETRIEVE_SAMPLE_ORDERS_DELETE,
  payload: id,
});

export const deleteSampleOrderOkAction = () => ({
  type: SAMPLE_ORDERS_DELETED_OK,
});

export const deleteSampleOrderErrorAction = () => ({
  type: SAMPLE_ORDERS_DELETED_ERROR,
  payload: true,
});

export const retrieveSampleOrderAction = (item) => ({
  type: RETRIEVE_SAMPLE_ORDERS_EDIT,
  payload: item,
});

export const editSampleOrderAction = (item) => ({
  type: BEGIN_EDIT_SAMPLE_ORDERS,
  sampleOrder: item,
});

export const editSampleOrderOkAction = (item) => ({
  type: SAMPLE_ORDERS_EDITED_OK,
  payload: item,
});

export const editSampleOrderErrorAction = () => ({
  type: SAMPLE_ORDERS_EDITED_ERROR,
  payload: true,
});
