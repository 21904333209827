import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import { BEGIN_SALES_CONTRACTS_AMEND_DOWNLOAD } from "../../constants/modules/SalesContractAmendConstants";

import {
  downloadSalesContractAmendsOkAction,
  downloadSalesContractAmendsErrorAction,
} from "../../actions/modules/salesContractAmendActions";

import { retrieveSalesContractAmendData } from "../../../config/apiCalls/SCAmend";

// Retrieve products
// worker saga
export function* retrieveSalesContractAmends() {
  try {
    const response = yield call(retrieveSalesContractAmendData);

    if (response.status === 200) {
      yield put(downloadSalesContractAmendsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSalesContractAmendsErrorAction());
  }
}

export function* watchSalesContractAmendsAsync() {
  yield takeLatest(
    BEGIN_SALES_CONTRACTS_AMEND_DOWNLOAD,
    retrieveSalesContractAmends
  );
}
