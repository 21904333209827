import {
    ADD_APPROVAL_PANEL_MEMBERS,
    ADD_APPROVAL_PANEL_MEMBERS_ERROR,
    ADD_APPROVAL_PANEL_MEMBERS_OK,
    APPROVAL_PANEL_MEMBERS_BULK_DELETED_ERROR,
    APPROVAL_PANEL_MEMBERS_BULK_DELETED_OK,
    APPROVAL_PANEL_MEMBERS_DELETED_ERROR,
    APPROVAL_PANEL_MEMBERS_DELETED_OK,
    APPROVAL_PANEL_MEMBERS_DOWNLOAD_ERROR,
    APPROVAL_PANEL_MEMBERS_DOWNLOAD_OK,
    APPROVAL_PANEL_MEMBERS_EDITED_ERROR,
    APPROVAL_PANEL_MEMBERS_EDITED_OK,
    BEGIN_APPROVAL_PANEL_MEMBERS_DOWNLOAD,
    BEGIN_EDIT_APPROVAL_PANEL_MEMBERS,
    RETRIEVE_APPROVAL_PANEL_MEMBERS_BULK_DELETE,
    RETRIEVE_APPROVAL_PANEL_MEMBERS_DELETE,
    RETRIEVE_APPROVAL_PANEL_MEMBERS_EDIT
} from "../../constants/modules/approvalPanelMembersContants.js";

const initialState = {
  approvalPanelMembers: [],
  error: false,
  loading: false,
  deleteApprovalPanelMember: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_APPROVAL_PANEL_MEMBERS_DOWNLOAD:
    case RETRIEVE_APPROVAL_PANEL_MEMBERS_BULK_DELETE:
    case ADD_APPROVAL_PANEL_MEMBERS:
    case BEGIN_EDIT_APPROVAL_PANEL_MEMBERS:
      return {
        ...state,
        loading: action.payload,
        approvalPanelMember: action.approvalPanelMember,
      };

    case ADD_APPROVAL_PANEL_MEMBERS_OK:
    case APPROVAL_PANEL_MEMBERS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_APPROVAL_PANEL_MEMBERS_ERROR:
    case APPROVAL_PANEL_MEMBERS_DOWNLOAD_ERROR:
    case APPROVAL_PANEL_MEMBERS_BULK_DELETED_ERROR:
    case APPROVAL_PANEL_MEMBERS_DELETED_ERROR:
    case APPROVAL_PANEL_MEMBERS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case APPROVAL_PANEL_MEMBERS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        approvalPanelMembers: action.payload,
      };

    case RETRIEVE_APPROVAL_PANEL_MEMBERS_DELETE:
      return {
        ...state,
        deleteApprovalPanelMember: action.payload,
      };

    case APPROVAL_PANEL_MEMBERS_DELETED_OK:
      return {
        ...state,
        approvalPanelMembers: state.approvalPanelMembers.data.filter(
          (approvalPanelMember) => approvalPanelMember.id !== state.deleteApprovalPanelMember
        ),
        deleteApprovalPanelMember: null,
      };

    case RETRIEVE_APPROVAL_PANEL_MEMBERS_EDIT:
      return {
        ...state,
        editApprovalPanelMember: action.payload,
      };

    case APPROVAL_PANEL_MEMBERS_EDITED_OK:
      return {
        ...state,
        editApprovalPanelMember: null,
        approvalPanelMembers: state.approvalPanelMembers.data.map((approvalPanelMember) =>
          approvalPanelMember.id === action.payload.id
            ? (approvalPanelMember = action.payload)
            : approvalPanelMember
        ),
      };

    default:
      return state;
  }
}
