import {
  PERMISSIONS_REQUEST,
  PERMISSIONS_REQUEST_OK,
  PERMISSIONS_REQUEST_ERROR,
} from "../../constants/auth/permissionsConstants";

const initialState = {
  permissions: [],
  error: false,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: action.payload,
        permission: action.permission,
      };

    case PERMISSIONS_REQUEST_OK:
      return {
        ...state,
        loading: false,
        error: null,
        permissions: action.payload,
      };

    case PERMISSIONS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
