import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    ADD_BUYER_TERMS,
    BEGIN_BUYER_TERMS_DOWNLOAD,
    BEGIN_EDIT_BUYER_TERMS,
    RETRIEVE_BUYER_TERMS_BULK_DELETE,
    RETRIEVE_BUYER_TERMS_DELETE
} from "../../constants/modules/buyerTermsConstants";

import {
    addBuyerTermErrorAction,
    addBuyerTermOkAction,
    bulkDeleteBuyerTermErrorAction,
    bulkDeleteBuyerTermOkAction,
    deleteBuyerTermErrorAction,
    deleteBuyerTermOkAction,
    downloadBuyerTermsErrorAction,
    downloadBuyerTermsOkAction,
    editBuyerTermErrorAction,
    editBuyerTermOkAction
} from "../../actions/modules/buyerTermsActions";

import {
    addBuyerTermDB,
    bulkDeleteBuyerTermDB,
    deleteBuyerTermDB,
    editBuyerTermDB,
    retrieveBuyerTermsData
} from "../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveBuyerTerms() {
  try {
    const response = yield call(retrieveBuyerTermsData);

    if (response.status === 200) {
      yield put(downloadBuyerTermsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadBuyerTermsErrorAction());
  }
}

// Create new product
// worker saga
export function* addBuyerTerm(action) {
  const buyerTerm = action.buyerTerm;

  try {
    const response = yield call(addBuyerTermDB, buyerTerm);

    if (response.status === 201) {
      yield delay(2000);

      yield put(addBuyerTermOkAction(buyerTerm));
      toast.success("Buyer Term created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addBuyerTermErrorAction(true));
    toast.error("Buyer Term created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}


// Bulk Delete
export function* bulkBuyerTermDelete(action) {
  const buyerTerm = action.buyerTerm;

  try {
    const response = yield call(bulkDeleteBuyerTermDB, buyerTerm);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteBuyerTermOkAction(buyerTerm));
      toast.success("Buyer Terms deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteBuyerTermErrorAction(true));
    toast.error("Buyer Terms deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}


// Delete product
// worker saga
export function* deleteBuyerTerm(action) {
  const id = action.payload;
  try {
    yield call(deleteBuyerTermDB, id);
    yield deleteBuyerTermOkAction();
    toast.success("Buyer Term deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } catch (error) {
    yield deleteBuyerTermErrorAction();
  }
}

// Edit product
// worker saga
export function* editBuyerTerm(action) {
  const buyerTerm = action.buyerTerm;

  try {
    yield call(editBuyerTermDB, buyerTerm);
    yield editBuyerTermOkAction(buyerTerm);
    // Alert
    // Alert
    toast.success("Buyer Term has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } catch (error) {
    yield editBuyerTermErrorAction();
  }
}

export function* watchBuyerTermsAsync() {
  yield takeLatest(BEGIN_BUYER_TERMS_DOWNLOAD, retrieveBuyerTerms);
  yield takeLatest(ADD_BUYER_TERMS, addBuyerTerm);
  yield takeLatest(RETRIEVE_BUYER_TERMS_DELETE, deleteBuyerTerm);
  yield takeLatest(BEGIN_EDIT_BUYER_TERMS, editBuyerTerm);
  yield takeLatest(RETRIEVE_BUYER_TERMS_BULK_DELETE, bulkBuyerTermDelete);
}
