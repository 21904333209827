import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_PAYMENT_TERMS,
  BEGIN_EDIT_PAYMENT_TERMS,
  BEGIN_PAYMENT_TERMS_DOWNLOAD,
  RETRIEVE_PAYMENT_TERMS_BULK_DELETE,
  RETRIEVE_PAYMENT_TERMS_DELETE
} from "../../constants/modules/paymentTermConstants";

import {
  addPaymentTermErrorAction,
  addPaymentTermOkAction,
  bulkDeletePaymentTermErrorAction,
  bulkDeletePaymentTermOkAction,
  deletePaymentTermErrorAction,
  deletePaymentTermOkAction,
  downloadPaymentTermsErrorAction,
  downloadPaymentTermsOkAction,
  editPaymentTermErrorAction,
  editPaymentTermOkAction
} from "../../actions/modules/paymentTermActions";

import {
  addPaymentTermDB,
  bulkDeletePaymentTermDB,
  deletePaymentTermDB,
  editPaymentTermDB,
  retrievePaymentTermsData
} from "../../../config/apiCalls/paymentTerms";

// Retrieve products
// worker saga
export function* retrievePaymentTerms() {
  try {
    const response = yield call(retrievePaymentTermsData);

    if (response.status === 200) {
      yield put(downloadPaymentTermsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadPaymentTermsErrorAction());
  }
}

// Create new product
// worker saga
export function* addPaymentTerm(action) {
  const paymentTerm = action.paymentTerm;

  try {
    const response = yield call(addPaymentTermDB, paymentTerm);

    if (response.status === 201) {
      yield delay(500);

      yield put(addPaymentTermOkAction(paymentTerm));
      toast.success("Payment Term created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addPaymentTermErrorAction(true));
    toast.error("Payment Term created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deletePaymentTerm(action) {
  const id = action.payload;
  try {
    yield call(deletePaymentTermDB, id);
    yield deletePaymentTermOkAction();
    toast.success("Payment Term deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deletePaymentTermErrorAction();
  }
}

// Edit product
// worker saga
export function* editPaymentTerm(action) {
  const paymentTerm = action.paymentTerm;

  try {
    yield call(editPaymentTermDB, paymentTerm);
    yield editPaymentTermOkAction(paymentTerm);
    // Alert
    // Alert
    toast.success("Payment Term has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editPaymentTermErrorAction();
  }
}

// BULK SAGAS
export function* bulkPaymentTermDelete(action) {
  const paymentTerm = action.paymentTerm;

  try {
    const response = yield call(bulkDeletePaymentTermDB, paymentTerm);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeletePaymentTermOkAction(paymentTerm));
      toast.success("Bulk Payment Term deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeletePaymentTermErrorAction(true));
    toast.error("Bulk Payment Term  deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchPaymentTermsAsync() {
  yield takeLatest(BEGIN_PAYMENT_TERMS_DOWNLOAD, retrievePaymentTerms);
  yield takeLatest(ADD_PAYMENT_TERMS, addPaymentTerm);
  yield takeLatest(RETRIEVE_PAYMENT_TERMS_DELETE, deletePaymentTerm);
  yield takeLatest(BEGIN_EDIT_PAYMENT_TERMS, editPaymentTerm);
  yield takeLatest(RETRIEVE_PAYMENT_TERMS_BULK_DELETE, bulkPaymentTermDelete);
}
