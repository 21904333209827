import {
  ADD_SAMPLE_COMMENTS,
  ADD_SAMPLE_COMMENTS_ERROR,
  ADD_SAMPLE_COMMENTS_OK,
  BEGIN_EDIT_SAMPLE_COMMENTS,
  BEGIN_SAMPLE_COMMENTS_DOWNLOAD,
  RETRIEVE_SAMPLE_COMMENTS_BULK_DELETE,
  RETRIEVE_SAMPLE_COMMENTS_DELETE,
  RETRIEVE_SAMPLE_COMMENTS_EDIT,
  SAMPLE_COMMENTS_BULK_DELETED_ERROR,
  SAMPLE_COMMENTS_BULK_DELETED_OK,
  SAMPLE_COMMENTS_DELETED_ERROR,
  SAMPLE_COMMENTS_DELETED_OK,
  SAMPLE_COMMENTS_DOWNLOAD_ERROR,
  SAMPLE_COMMENTS_DOWNLOAD_OK,
  SAMPLE_COMMENTS_EDITED_ERROR,
  SAMPLE_COMMENTS_EDITED_OK,
} from "../../constants/modules/sampleCommentsConstant";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteSampleCommentAction = (item) => ({
  type: RETRIEVE_SAMPLE_COMMENTS_BULK_DELETE,
  payload: true,
  sampleComment: item,
});

export const bulkDeleteSampleCommentOkAction = () => ({
  type: SAMPLE_COMMENTS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteSampleCommentErrorAction = (state) => ({
  type: SAMPLE_COMMENTS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadSampleCommentsAction = () => ({
  type: BEGIN_SAMPLE_COMMENTS_DOWNLOAD,
  payload: true,
});

export const downloadSampleCommentsOkAction = (items) => ({
  type: SAMPLE_COMMENTS_DOWNLOAD_OK,
  payload: items,
});

export const downloadSampleCommentsErrorAction = () => ({
  type: SAMPLE_COMMENTS_DOWNLOAD_ERROR,
  payload: true,
});

export const addSampleCommentAction = (item) => ({
  type: ADD_SAMPLE_COMMENTS,
  payload: true,
  sampleComment: item,
});

export const addSampleCommentOkAction = () => ({
  type: ADD_SAMPLE_COMMENTS_OK,
  payload: true,
});

export const addSampleCommentErrorAction = (state) => ({
  type: ADD_SAMPLE_COMMENTS_ERROR,
  payload: state,
});

export const deleteSampleCommentAction = (id) => ({
  type: RETRIEVE_SAMPLE_COMMENTS_DELETE,
  payload: id,
});

export const deleteSampleCommentOkAction = () => ({
  type: SAMPLE_COMMENTS_DELETED_OK,
});

export const deleteSampleCommentErrorAction = () => ({
  type: SAMPLE_COMMENTS_DELETED_ERROR,
  payload: true,
});

export const retrieveSampleCommentAction = (item) => ({
  type: RETRIEVE_SAMPLE_COMMENTS_EDIT,
  payload: item,
});

export const editSampleCommentAction = (item) => ({
  type: BEGIN_EDIT_SAMPLE_COMMENTS,
  sampleComment: item,
});

export const editSampleCommentOkAction = (item) => ({
  type: SAMPLE_COMMENTS_EDITED_OK,
  payload: item,
});

export const editSampleCommentErrorAction = () => ({
  type: SAMPLE_COMMENTS_EDITED_ERROR,
  payload: true,
});
