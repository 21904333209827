import {
  ADD_BANKS,
  ADD_BANKS_OK,
  ADD_BANKS_ERROR,
  BEGIN_BANKS_DOWNLOAD,
  BANKS_DOWNLOAD_OK,
  BANKS_DOWNLOAD_ERROR,
  RETRIEVE_BANKS_DELETE,
  BANKS_DELETED_OK,
  BANKS_DELETED_ERROR,
  RETRIEVE_BANKS_EDIT,
  BEGIN_EDIT_BANKS,
  BANKS_EDITED_OK,
  BANKS_EDITED_ERROR,
  RETRIEVE_BANKS_BULK_DELETE,
  BANKS_BULK_DELETED_OK,
  BANKS_BULK_DELETED_ERROR,
} from "../../constants/modules/banksConstants";

export const downloadBanksAction = () => ({
  type: BEGIN_BANKS_DOWNLOAD,
  payload: true,
});

export const downloadBanksOkAction = (items) => ({
  type: BANKS_DOWNLOAD_OK,
  payload: items,
});

export const downloadBanksErrorAction = () => ({
  type: BANKS_DOWNLOAD_ERROR,
  payload: true,
});

export const addBankAction = (item) => ({
  type: ADD_BANKS,
  payload: true,
  bank: item,
});

export const addBankOkAction = () => ({
  type: ADD_BANKS_OK,
  payload: true,
});

export const addBankErrorAction = (state) => ({
  type: ADD_BANKS_ERROR,
  payload: state,
});

export const deleteBankAction = (id) => ({
  type: RETRIEVE_BANKS_DELETE,
  payload: id,
});

export const deleteBankOkAction = () => ({
  type: BANKS_DELETED_OK,
});

export const deleteBankErrorAction = () => ({
  type: BANKS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteBankAction = (item) => ({
  type: RETRIEVE_BANKS_BULK_DELETE,
  payload: true,
  bank: item,
});

export const bulkDeleteBankOkAction = () => ({
  type: BANKS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteBankErrorAction = (state) => ({
  type: BANKS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveBankAction = (item) => ({
  type: RETRIEVE_BANKS_EDIT,
  payload: item,
});

export const editBankAction = (item) => ({
  type: BEGIN_EDIT_BANKS,
  bank: item,
});

export const editBankOkAction = (item) => ({
  type: BANKS_EDITED_OK,
  payload: item,
});

export const editBankErrorAction = () => ({
  type: BANKS_EDITED_ERROR,
  payload: true,
});
