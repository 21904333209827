export const ADD_ORDERS = "ADD_ORDERS";
export const ADD_ORDERS_OK = "ADD_ORDERS_OK";
export const ADD_ORDERS_ERROR = "ADD_ORDERS_ERROR";

export const BEGIN_ORDERS_DOWNLOAD = "BEGIN_ORDERS_DOWNLOAD";
export const ORDERS_DOWNLOAD_OK = "ORDERS_DOWNLOAD_OK";
export const ORDERS_DOWNLOAD_ERROR = "ORDERS_DOWNLOAD_ERROR";

export const RETRIEVE_ORDERS_EDIT = "RETRIEVE_ORDERS_EDITED";
export const BEGIN_EDIT_ORDERS = "BEGIN_EDIT_ORDERS";
export const ORDERS_EDITED_OK = "ORDERS_EDITED_OK";
export const ORDERS_EDITED_ERROR = "ORDERS_EDITED_ERROR";

export const RETRIEVE_ORDERS_DELETE = "RETRIEVE_ORDERS_DELETE";
export const ORDERS_DELETED_OK = "ORDERS_DELETED_OK";
export const ORDERS_DELETED_ERROR = "ORDERS_DELETED_ERROR";

export const RETRIEVE_ORDERS_BULK_DELETE = "RETRIEVE_ORDERS_BULK_DELETE";
export const ORDERS_BULK_DELETED_OK = "ORDERS_BULK_DELETED_OK";
export const ORDERS_BULK_DELETED_ERROR = "ORDERS_BULK_DELETED_ERROR";

export const RESET = "RESET";
