export const ADD_PRODUCT_TYPES = "ADD_PRODUCT_TYPES";
export const ADD_PRODUCT_TYPES_OK = "ADD_PRODUCT_TYPES_OK";
export const ADD_PRODUCT_TYPES_ERROR = "ADD_PRODUCT_TYPES_ERROR";

export const BEGIN_PRODUCT_TYPES_DOWNLOAD = "BEGIN_PRODUCT_TYPES_DOWNLOAD";
export const PRODUCT_TYPES_DOWNLOAD_OK = "PRODUCT_TYPES_DOWNLOAD_OK";
export const PRODUCT_TYPES_DOWNLOAD_ERROR = "PRODUCT_TYPES_DOWNLOAD_ERROR";

export const BEGIN_PRODUCT_TYPES_BY_FK_DOWNLOAD =
  "BEGIN_PRODUCT_TYPES_BY_FK_DOWNLOAD";
export const PRODUCT_TYPES_BY_FK_DOWNLOAD_OK =
  "PRODUCT_TYPES_BY_FK_DOWNLOAD_OK";
export const PRODUCT_TYPES_BY_FK_DOWNLOAD_ERROR =
  "PRODUCT_TYPES_BY_FK_DOWNLOAD_ERROR";

export const RETRIEVE_PRODUCT_TYPES_DELETE = "RETRIEVE_PRODUCT_TYPES_DELETE";
export const PRODUCT_TYPES_DELETED_OK = "PRODUCT_TYPES_DELETED_OK";
export const PRODUCT_TYPES_DELETED_ERROR = "PRODUCT_TYPES_DELETED_ERROR";

export const RETRIEVE_PRODUCT_TYPES_EDIT = "RETRIEVE_PRODUCT_TYPES_EDITED";
export const BEGIN_EDIT_PRODUCT_TYPES = "BEGIN_EDIT_PRODUCT_TYPES";
export const PRODUCT_TYPES_EDITED_OK = "PRODUCT_TYPES_EDITED_OK";
export const PRODUCT_TYPES_EDITED_ERROR = "PRODUCT_TYPES_EDITED_ERROR";

export const RETRIEVE_PRODUCT_TYPES_BULK_DELETE =
  "RETRIEVE_PRODUCT_TYPES_BULK_DELETE";
export const PRODUCT_TYPES_BULK_DELETED_OK = "PRODUCT_TYPES_BULK_DELETED_OK";
export const PRODUCT_TYPES_BULK_DELETED_ERROR =
  "PRODUCT_TYPES_BULK_DELETED_ERROR";
