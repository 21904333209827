import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_SALES_CONTRACTS,
  BEGIN_EDIT_SALES_CONTRACTS,
  BEGIN_SALES_CONTRACTS_DOWNLOAD,
  BEGIN_SALES_CONTRACT_DOWNLOAD,
  RETRIEVE_SALES_CONTRACTS_BULK_DELETE,
  RETRIEVE_SALES_CONTRACTS_DELETE,
} from "../../constants/modules/salesContractConstants";

import {
  addSalesContractErrorAction,
  addSalesContractOkAction,
  bulkDeleteSalesContractErrorAction,
  bulkDeleteSalesContractOkAction,
  deleteSalesContractErrorAction,
  deleteSalesContractOkAction,
  downloadSalesContractErrorAction,
  downloadSalesContractOkAction,
  downloadSalesContractsErrorAction,
  downloadSalesContractsOkAction,
  editSalesContractErrorAction,
  editSalesContractOkAction,
} from "../../actions/modules/salesContractActions";

import {
  addSalesContractDB,
  bulkDeleteSalesContractDB,
  deleteSalesContractDB,
  editSalesContractDB,
  retrieveSalesContractData,
  retrieveSalesContractsData,
} from "../../../config/apiCalls/salesContracts";

// Retrieve products
// worker saga
export function* retrieveSalesContracts(action) {
  const salesContract = action.salesContract;
  try {
    const response = yield call(retrieveSalesContractsData, salesContract);

    if (response.status === 200) {
      yield put(downloadSalesContractsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSalesContractsErrorAction());
  }
}

export function* retrieveSalesContract(action) {
  const salesContract = action.salesContract;
  try {
    const response = yield call(retrieveSalesContractData, salesContract);
    if (response.status === 200) {
      yield put(downloadSalesContractOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSalesContractErrorAction());
  }
}

// Create new product
// worker saga
export function* addSalesContract(action) {
  const salesContract = action.salesContract;
  try {
    const response = yield call(addSalesContractDB, salesContract);
    if (response.status === 200) {
      yield delay(500);
      yield put(addSalesContractOkAction(response.data));
      toast.success("Sales Contract created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addSalesContractErrorAction(true));
    toast.error("Sales Contract created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteSalesContract(action) {
  const id = action.payload;
  try {
    yield call(deleteSalesContractDB, id);
    yield deleteSalesContractOkAction();
    toast.success("Sales Contract deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteSalesContractErrorAction();
  }
}

// Edit product
// worker saga
export function* editSalesContract(action) {
  const salesContract = action.salesContract;

  try {
    yield call(editSalesContractDB, salesContract);
    yield editSalesContractOkAction(salesContract);
    // Alert
    // Alert
    toast.success("Sales Contract has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editSalesContractErrorAction();
  }
}

// BULK SAGAS
export function* bulkSalesContractDelete(action) {
  const salesContract = action.salesContract;

  try {
    const response = yield call(bulkDeleteSalesContractDB, salesContract);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteSalesContractOkAction(salesContract));
      // toast.success("Tna Actions deleted successfully!", {
      toast.success("Bulk Sales Contract deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteSalesContractErrorAction(true));
    // toast.error("Tna Actions deleted failed!", {
    toast.error("Bulk Sales Contract deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchSalesContractsAsync() {
  yield takeLatest(BEGIN_SALES_CONTRACTS_DOWNLOAD, retrieveSalesContracts);
  yield takeLatest(BEGIN_SALES_CONTRACT_DOWNLOAD, retrieveSalesContract);
  yield takeLatest(ADD_SALES_CONTRACTS, addSalesContract);
  yield takeLatest(RETRIEVE_SALES_CONTRACTS_DELETE, deleteSalesContract);
  yield takeLatest(BEGIN_EDIT_SALES_CONTRACTS, editSalesContract);
  yield takeLatest(
    RETRIEVE_SALES_CONTRACTS_BULK_DELETE,
    bulkSalesContractDelete
  );
}
