import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_LOADING_POINTS,
  BEGIN_EDIT_LOADING_POINTS,
  BEGIN_LOADING_POINTS_DOWNLOAD,
  RETRIEVE_LOADING_POINTS_BULK_DELETE,
  RETRIEVE_LOADING_POINTS_DELETE
} from "../../constants/modules/loadingPointConstants";

import {
  addLoadingPointErrorAction,
  addLoadingPointOkAction,
  bulkDeleteLoadingPointErrorAction,
  bulkDeleteLoadingPointOkAction,
  deleteLoadingPointErrorAction,
  deleteLoadingPointOkAction,
  downloadLoadingPointsErrorAction,
  downloadLoadingPointsOkAction,
  editLoadingPointErrorAction,
  editLoadingPointOkAction
} from "../../actions/modules/loadingPointsActions";

import {
  addLoadingPointDB,
  bulkDeleteLoadingPointDB,
  deleteLoadingPointDB,
  editLoadingPointDB,
  retrieveLoadingPointsData
} from "../../../config/apiCalls/loadingPointAPICall";


// Retrieve products
// worker saga
export function* retrieveLoadingPoints() {
  try {
    const response = yield call(retrieveLoadingPointsData);

    if (response.status === 200) {
      yield put(downloadLoadingPointsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadLoadingPointsErrorAction());
  }
}

// Create new product
// worker saga
export function* addLoadingPoint(action) {
  const loadingPoint = action.loadingPoint;

  try {
    const response = yield call(addLoadingPointDB, loadingPoint);

    if (response.status === 201) {
      yield delay(500);

      yield put(addLoadingPointOkAction(loadingPoint));
      toast.success("Loading Point created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addLoadingPointErrorAction(true));
    toast.error("Loading Point created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteLoadingPoint(action) {
  const id = action.payload;
  try {
    yield call(deleteLoadingPointDB, id);
    yield deleteLoadingPointOkAction();
    toast.success("Loading Point deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteLoadingPointErrorAction();
  }
}

// Edit product
// worker saga
export function* editLoadingPoint(action) {
  const loadingPoint = action.loadingPoint;

  try {
    yield call(editLoadingPointDB, loadingPoint);
    yield editLoadingPointOkAction(loadingPoint);
    // Alert
    toast.success("Loading Point has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editLoadingPointErrorAction();
  }
}

// BULK SAGAS
export function* bulkLoadingPointDelete(action) {
  const loadingPoint = action.loadingPoint;

  try {
    const response = yield call(bulkDeleteLoadingPointDB, loadingPoint);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteLoadingPointOkAction(loadingPoint));
      toast.success("Bulk Loading Point deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteLoadingPointErrorAction(true));
    toast.error("Bulk Loading Point deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchLoadingPointsAsync() {
  yield takeLatest(BEGIN_LOADING_POINTS_DOWNLOAD, retrieveLoadingPoints);
  yield takeLatest(ADD_LOADING_POINTS, addLoadingPoint);
  yield takeLatest(RETRIEVE_LOADING_POINTS_DELETE, deleteLoadingPoint);
  yield takeLatest(BEGIN_EDIT_LOADING_POINTS, editLoadingPoint);
  yield takeLatest(RETRIEVE_LOADING_POINTS_BULK_DELETE, bulkLoadingPointDelete);
}
