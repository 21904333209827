import {
  ADD_COMMISSION,
  ADD_COMMISSION_OK,
  ADD_COMMISSION_ERROR,
  BEGIN_COMMISSION_DOWNLOAD,
  COMMISSION_DOWNLOAD_OK,
  COMMISSION_DOWNLOAD_ERROR,
} from "../../constants/modules/commissionConstants";

export const downloadHeaderAction = (item) => ({
  type: BEGIN_COMMISSION_DOWNLOAD,
  payload: true,
  commission: item,
});

export const downloadHeaderOkAction = (items) => ({
  type: COMMISSION_DOWNLOAD_OK,
  payload: items,
});

export const downloadHeaderErrorAction = () => ({
  type: COMMISSION_DOWNLOAD_ERROR,
  payload: true,
});

export const addHeaderAction = (item) => ({
  type: ADD_COMMISSION,
  payload: true,
  commission: item,
});

export const addHeaderOkAction = (item) => ({
  type: ADD_COMMISSION_OK,
  payload: item,
});

export const addHeaderErrorAction = (state) => ({
  type: ADD_COMMISSION_ERROR,
  payload: state,
});
