export const ADD_LC_HEADERS = "ADD_LC_HEADERS";
export const ADD_LC_HEADERS_OK = "ADD_LC_HEADERS_OK";
export const ADD_LC_HEADERS_ERROR = "ADD_LC_HEADERS_ERROR";

export const BEGIN_LC_HEADERS_DOWNLOAD = "BEGIN_LC_HEADERS_DOWNLOAD";
export const LC_HEADERS_DOWNLOAD_OK = "LC_HEADERS_DOWNLOAD_OK";
export const LC_HEADERS_DOWNLOAD_ERROR = "LC_HEADERS_DOWNLOAD_ERROR";

export const BEGIN_LC_HEADER_DOWNLOAD = "BEGIN_LC_HEADER_DOWNLOAD";
export const LC_HEADER_DOWNLOAD_OK = "LC_HEADER_DOWNLOAD_OK";
export const LC_HEADER_DOWNLOAD_ERROR = "LC_HEADER_DOWNLOAD_ERROR";

export const RETRIEVE_LC_HEADERS_DELETE = "RETRIEVE_LC_HEADERS_DELETE";
export const LC_HEADERS_DELETED_OK = "LC_HEADERS_DELETED_OK";
export const LC_HEADERS_DELETED_ERROR = "LC_HEADERS_DELETED_ERROR";

export const RETRIEVE_LC_HEADERS_EDIT = "RETRIEVE_LC_HEADERS_EDITED";
export const BEGIN_EDIT_LC_HEADERS = "BEGIN_EDIT_LC_HEADERS";
export const LC_HEADERS_EDITED_OK = "LC_HEADERS_EDITED_OK";
export const LC_HEADERS_EDITED_ERROR = "LC_HEADERS_EDITED_ERROR";

export const RETRIEVE_LC_HEADERS_BULK_DELETE =
  "RETRIEVE_LC_HEADERS_BULK_DELETE";
export const LC_HEADERS_BULK_DELETED_OK = "LC_HEADERS_BULK_DELETED_OK";
export const LC_HEADERS_BULK_DELETED_ERROR =
  "LC_HEADERS_BULK_DELETED_ERROR";

export const RESET = "RESET";
