import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_SALES_CONTRACT_ACTIONS,
  BEGIN_EDIT_SALES_CONTRACT_ACTIONS,
  BEGIN_SALES_CONTRACT_ACTIONS_DOWNLOAD,
  RETRIEVE_SALES_CONTRACT_ACTIONS_BULK_DELETE,
  RETRIEVE_SALES_CONTRACT_ACTIONS_DELETE
} from "../../constants/modules/salesContractActionConstants";

import {
  addSalesContractActionErrorAction,
  addSalesContractActionOkAction,
  bulkDeleteSalesContractActionErrorAction,
  bulkDeleteSalesContractActionOkAction,
  deleteSalesContractActionErrorAction,
  deleteSalesContractActionOkAction,
  downloadSalesContractActionsErrorAction,
  downloadSalesContractActionsOkAction,
  editSalesContractActionErrorAction,
  editSalesContractActionOkAction
} from "../../actions/modules/salesContractActionActions";

import {
  addSalesContractActionDB,
  bulkDeleteSalesContractActionDB,
  deleteSalesContractActionDB,
  editSalesContractActionDB,
  retrieveSalesContractActionsData
} from "../../../config/apiCalls/salesContractActions";

// Retrieve products
// worker saga
export function* retrieveSalesContractActions() {
  try {
    const response = yield call(retrieveSalesContractActionsData);

    if (response.status === 200) {
      yield put(downloadSalesContractActionsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSalesContractActionsErrorAction());
  }
}

// Create new product
// worker saga
export function* addSalesContractActions(action) {
  const salesContractAction = action.salesContractAction;

  try {
    const response = yield call(addSalesContractActionDB, salesContractAction);

    if (response.status === 200) {
      yield delay(500);

      yield put(addSalesContractActionOkAction(salesContractAction));
      toast.success("Sales Contract Action created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addSalesContractActionErrorAction(true));
    toast.error("Sales Contract Action created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteSalesContractAction(action) {
  const id = action.payload;
  try {
    yield call(deleteSalesContractActionDB, id);
    yield deleteSalesContractActionOkAction();
    toast.success("Sales Contract Action deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteSalesContractActionErrorAction();
  }
}

// Edit product
// worker saga
export function* editSalesContractAction(action) {
  const salesContractAction = action.salesContractAction;

  try {
    yield call(editSalesContractActionDB, salesContractAction);
    yield editSalesContractActionOkAction(salesContractAction);
    // Alert
    // Alert
    toast.success("Sales Contract Action has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editSalesContractActionErrorAction();
  }
}

// BULK SAGAS
export function* bulkSalesContractActionDelete(action) {
  const salesContractAction = action.salesContractAction;

  try {
    const response = yield call(
      bulkDeleteSalesContractActionDB,
      salesContractAction
    );
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteSalesContractActionOkAction(salesContractAction));
      toast.success("Sales Contract Actions deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteSalesContractActionErrorAction(true));
    toast.error("Sales Contract Actions deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchSalesContractActionsAsync() {
  yield takeLatest(
    BEGIN_SALES_CONTRACT_ACTIONS_DOWNLOAD,
    retrieveSalesContractActions
  );
  yield takeLatest(ADD_SALES_CONTRACT_ACTIONS, addSalesContractActions);
  yield takeLatest(
    RETRIEVE_SALES_CONTRACT_ACTIONS_DELETE,
    deleteSalesContractAction
  );
  yield takeLatest(BEGIN_EDIT_SALES_CONTRACT_ACTIONS, editSalesContractAction);
  yield takeLatest(
    RETRIEVE_SALES_CONTRACT_ACTIONS_BULK_DELETE,
    bulkSalesContractActionDelete
  );
}
