import {
    ADD_RDL_INVOICE_DETAILS,
    ADD_RDL_INVOICE_DETAILS_ERROR,
    ADD_RDL_INVOICE_DETAILS_OK,
    BEGIN_EDIT_RDL_INVOICE_DETAILS,
    BEGIN_RDL_INVOICE_DETAILS_DOWNLOAD,
    BEGIN_RDL_INVOICE_DETAIL_DOWNLOAD,
    RDL_INVOICE_DETAILS_BULK_DELETED_ERROR,
    RDL_INVOICE_DETAILS_BULK_DELETED_OK,
    RDL_INVOICE_DETAILS_DELETED_ERROR,
    RDL_INVOICE_DETAILS_DELETED_OK,
    RDL_INVOICE_DETAILS_DOWNLOAD_ERROR,
    RDL_INVOICE_DETAILS_DOWNLOAD_OK,
    RDL_INVOICE_DETAILS_EDITED_ERROR,
    RDL_INVOICE_DETAILS_EDITED_OK,
    RDL_INVOICE_DETAIL_DOWNLOAD_ERROR,
    RDL_INVOICE_DETAIL_DOWNLOAD_OK,
    RESET,
    RETRIEVE_RDL_INVOICE_DETAILS_BULK_DELETE,
    RETRIEVE_RDL_INVOICE_DETAILS_DELETE,
    RETRIEVE_RDL_INVOICE_DETAILS_EDIT
} from "../../constants/modules/RDLInvoiceDetailConstant";

const initialState = {
  rdlInvoiceDetails: [],
  getRDLInvoiceDetail: null,
  rdlInvoiceDetailAddInfo: null,
  error: false,
  loading: false,
  deleteRDLInvoiceDetail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_RDL_INVOICE_DETAILS_DOWNLOAD:
    case BEGIN_RDL_INVOICE_DETAIL_DOWNLOAD:
    case RETRIEVE_RDL_INVOICE_DETAILS_BULK_DELETE:
    case ADD_RDL_INVOICE_DETAILS:
    case BEGIN_EDIT_RDL_INVOICE_DETAILS:
      return {
        ...state,
        loading: action.payload,
        rdlInvoiceDetail: action.rdlInvoiceDetail,
      };

    case RDL_INVOICE_DETAILS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_RDL_INVOICE_DETAILS_ERROR:
    case RDL_INVOICE_DETAILS_DOWNLOAD_ERROR:
    case RDL_INVOICE_DETAILS_BULK_DELETED_ERROR:
    case RDL_INVOICE_DETAIL_DOWNLOAD_ERROR:
    case RDL_INVOICE_DETAILS_DELETED_ERROR:
    case RDL_INVOICE_DETAILS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RDL_INVOICE_DETAILS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        rdlInvoiceDetails: action.payload,
      };

    case RDL_INVOICE_DETAIL_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        getRDLInvoiceDetail: action.payload,
      };

    case ADD_RDL_INVOICE_DETAILS_OK:
      return {
        ...state,
        loading: false,
        error: null,
        rdlInvoiceDetailAddInfo: action.payload,
      };

    case RETRIEVE_RDL_INVOICE_DETAILS_DELETE:
      return {
        ...state,
        deleteRDLInvoiceDetail: action.payload,
      };

    case RDL_INVOICE_DETAILS_DELETED_OK:
      return {
        ...state,
        rdlInvoiceDetails: state.rdlInvoiceDetails.data.filter(
          (rdlInvoiceDetail) =>
            rdlInvoiceDetail.id !== state.deleteRDLInvoiceDetail
        ),
        deleteRDLInvoiceDetail: null,
      };

    case RETRIEVE_RDL_INVOICE_DETAILS_EDIT:
      return {
        ...state,
        editRDLInvoiceDetail: action.payload,
      };

    case RDL_INVOICE_DETAILS_EDITED_OK:
      return {
        ...state,
        editRDLInvoiceDetails: null,
        rdlInvoiceDetails: state.rdlInvoiceDetails.data.map(
          (rdlInvoiceDetail) =>
            rdlInvoiceDetail.id === action.payload.id
              ? (rdlInvoiceDetail = action.payload)
              : rdlInvoiceDetail
        ),
      };

    case RESET:
      return {
        ...state,
        getRDLInvoiceDetail: null,
        rdlInvoiceDetailAddInfo: null,
      };

    default:
      return state;
  }
}
