import {
  ADD_COMPANIES,
  ADD_COMPANIES_ERROR,
  ADD_COMPANIES_OK,
  BEGIN_COMPANIES_DOWNLOAD,
  BEGIN_EDIT_COMPANIES,
  COMPANIES_BULK_DELETED_ERROR,
  COMPANIES_BULK_DELETED_OK,
  COMPANIES_DELETED_ERROR,
  COMPANIES_DELETED_OK,
  COMPANIES_DOWNLOAD_ERROR,
  COMPANIES_DOWNLOAD_OK,
  COMPANIES_EDITED_ERROR,
  COMPANIES_EDITED_OK,
  RETRIEVE_COMPANIES_BULK_DELETE,
  RETRIEVE_COMPANIES_DELETE,
  RETRIEVE_COMPANIES_EDIT,
} from "../../constants/modules/companiesConstants.js";

const initialState = {
  companies: [],
  error: false,
  loading: false,
  deleteCompany: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_COMPANIES_DOWNLOAD:
    case ADD_COMPANIES:
    case RETRIEVE_COMPANIES_BULK_DELETE:
    case BEGIN_EDIT_COMPANIES:
      return {
        ...state,
        loading: action.payload,
        company: action.company,
      };

    case ADD_COMPANIES_OK:
    case COMPANIES_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_COMPANIES_ERROR:
    case COMPANIES_DOWNLOAD_ERROR:
    case COMPANIES_DELETED_ERROR:
    case COMPANIES_BULK_DELETED_ERROR:
    case COMPANIES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COMPANIES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        company: action.payload,
      };

    case RETRIEVE_COMPANIES_DELETE:
      return {
        ...state,
        deleteCompany: action.payload,
      };

    case COMPANIES_DELETED_OK:
      return {
        ...state,
        companies: state.companies.data.filter(
          (company) => company.id !== state.deleteCompany
        ),
        deleteCompany: null,
      };

    case RETRIEVE_COMPANIES_EDIT:
      return {
        ...state,
        editCompany: action.payload,
      };

    case COMPANIES_EDITED_OK:
      return {
        ...state,
        editCompany: null,
        companies: state.companies.data.map((company) =>
          company.id === action.payload.id
            ? (company = action.payload)
            : company
        ),
      };

    default:
      return state;
  }
}
