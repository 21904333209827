import {
  BEGIN_COMMISSION_DETAILS_DOWNLOAD,
  COMMISSION_DETAILS_DOWNLOAD_OK,
  COMMISSION_DETAILS_DOWNLOAD_ERROR,
} from "../../constants/modules/commissionDetailsConstants";

const initialState = {
  commissionDetails: [],
  error: false,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_COMMISSION_DETAILS_DOWNLOAD:
      return {
        ...state,
        loading: action.payload,
        commissionDetail: action.commissionDetail,
      };

    case COMMISSION_DETAILS_DOWNLOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COMMISSION_DETAILS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        commissionDetails: action.payload,
      };

    default:
      return state;
  }
}
