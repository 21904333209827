import {
  ADD_BUYER_DEPT,
  ADD_BUYER_DEPT_OK,
  ADD_BUYER_DEPT_ERROR,
  BEGIN_BUYER_DEPT_DOWNLOAD,
  BUYER_DEPT_DOWNLOAD_OK,
  BUYER_DEPT_DOWNLOAD_ERROR,
  RETRIEVE_BUYER_DEPT_DELETE,
  BUYER_DEPT_DELETED_OK,
  BUYER_DEPT_DELETED_ERROR,
  RETRIEVE_BUYER_DEPT_EDIT,
  BEGIN_EDIT_BUYER_DEPT,
  BUYER_DEPT_EDITED_OK,
  BUYER_DEPT_EDITED_ERROR,
  RETRIEVE_BUYER_DEPT_BULK_DELETE,
  BUYER_DEPT_BULK_DELETED_OK,
  BUYER_DEPT_BULK_DELETED_ERROR,
  BEGIN_BUYER_DEPT_BY_BRAND_DOWNLOAD,
  BUYER_DEPT_BY_BRAND_DOWNLOAD_OK,
  BUYER_DEPT_BY_BRAND_DOWNLOAD_ERROR,
} from "../../constants/modules/buyerDeptConstants";

export const downloadBuyerDepartmentsAction = () => ({
  type: BEGIN_BUYER_DEPT_DOWNLOAD,
  payload: true,
});

export const downloadBuyerDepartmentsOkAction = (items) => ({
  type: BUYER_DEPT_DOWNLOAD_OK,
  payload: items,
});

export const downloadBuyerDepartmentsErrorAction = () => ({
  type: BUYER_DEPT_DOWNLOAD_ERROR,
  payload: true,
});

//by_brands
export const downloadBuyerDepartmentsByBrandAction = (item) => ({
  type: BEGIN_BUYER_DEPT_BY_BRAND_DOWNLOAD,
  payload: true,
  buyerDepartment: item,
});

export const downloadBuyerDepartmentsByBrandOkAction = (items) => ({
  type: BUYER_DEPT_BY_BRAND_DOWNLOAD_OK,
  payload: items,
});

export const downloadBuyerDepartmentsByBrandErrorAction = () => ({
  type: BUYER_DEPT_BY_BRAND_DOWNLOAD_ERROR,
  payload: true,
});

export const addBuyerDepartmentAction = (item) => ({
  type: ADD_BUYER_DEPT,
  payload: true,
  buyerDepartment: item,
});

export const addBuyerDepartmentOkAction = () => ({
  type: ADD_BUYER_DEPT_OK,
  payload: true,
});

export const addBuyerDepartmentErrorAction = (state) => ({
  type: ADD_BUYER_DEPT_ERROR,
  payload: state,
});

export const deleteBuyerDepartmentAction = (id) => ({
  type: RETRIEVE_BUYER_DEPT_DELETE,
  payload: id,
});

export const deleteBuyerDepartmentOkAction = () => ({
  type: BUYER_DEPT_DELETED_OK,
});

export const deleteBuyerDepartmentErrorAction = () => ({
  type: BUYER_DEPT_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteBuyerDepartmentAction = (item) => ({
  type: RETRIEVE_BUYER_DEPT_BULK_DELETE,
  payload: true,
  buyerDepartment: item,
});

export const bulkDeleteBuyerDepartmentOkAction = () => ({
  type: BUYER_DEPT_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteBuyerDepartmentErrorAction = (state) => ({
  type: BUYER_DEPT_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveBuyerDepartmentAction = (item) => ({
  type: RETRIEVE_BUYER_DEPT_EDIT,
  payload: item,
});

export const editBuyerDepartmentAction = (item) => ({
  type: BEGIN_EDIT_BUYER_DEPT,
  buyerDepartment: item,
});

export const editBuyerDepartmentOkAction = (item) => ({
  type: BUYER_DEPT_EDITED_OK,
  payload: item,
});

export const editBuyerDepartmentErrorAction = () => ({
  type: BUYER_DEPT_EDITED_ERROR,
  payload: true,
});
