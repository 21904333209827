export const ADD_PROCEED_REALIZATION_DETAILS = "ADD_PROCEED_REALIZATION_DETAILS";
export const ADD_PROCEED_REALIZATION_DETAILS_OK = "ADD_PROCEED_REALIZATION_DETAILS_OK";
export const ADD_PROCEED_REALIZATION_DETAILS_ERROR =
  "ADD_PROCEED_REALIZATION_DETAILS_ERROR";

export const BEGIN_PROCEED_REALIZATION_DETAILS_DOWNLOAD =
  "BEGIN_PROCEED_REALIZATION_DETAILS_DOWNLOAD";
export const PROCEED_REALIZATION_DETAILS_DOWNLOAD_OK =
  "PROCEED_REALIZATION_DETAILS_DOWNLOAD_OK";
export const PROCEED_REALIZATION_DETAILS_DOWNLOAD_ERROR =
  "PROCEED_REALIZATION_DETAILS_DOWNLOAD_ERROR";

export const BEGIN_PROCEED_REALIZATION_DETAIL_DOWNLOAD =
  "BEGIN_PROCEED_REALIZATION_DETAIL_DOWNLOAD";
export const PROCEED_REALIZATION_DETAIL_DOWNLOAD_OK =
  "PROCEED_REALIZATION_DETAIL_DOWNLOAD_OK";
export const PROCEED_REALIZATION_DETAIL_DOWNLOAD_ERROR =
  "PROCEED_REALIZATION_DETAIL_DOWNLOAD_ERROR";

export const RETRIEVE_PROCEED_REALIZATION_DETAILS_EDIT =
  "RETRIEVE_PROCEED_REALIZATION_DETAILS_EDITED";
export const BEGIN_EDIT_PROCEED_REALIZATION_DETAILS =
  "BEGIN_EDIT_PROCEED_REALIZATION_DETAILS";
export const PROCEED_REALIZATION_DETAILS_EDITED_OK =
  "PROCEED_REALIZATION_DETAILS_EDITED_OK";
export const PROCEED_REALIZATION_DETAILS_EDITED_ERROR =
  "PROCEED_REALIZATION_DETAILS_EDITED_ERROR";

export const RETRIEVE_PROCEED_REALIZATION_DETAILS_DELETE =
  "RETRIEVE_PROCEED_REALIZATION_DETAILS_DELETE";
export const PROCEED_REALIZATION_DETAILS_DELETED_OK =
  "PROCEED_REALIZATION_DETAILS_DELETED_OK";
export const PROCEED_REALIZATION_DETAILS_DELETED_ERROR =
  "PROCEED_REALIZATION_DETAILS_DELETED_ERROR";

export const RETRIEVE_PROCEED_REALIZATION_DETAILS_BULK_DELETE =
  "RETRIEVE_PROCEED_REALIZATION_DETAILS_BULK_DELETE";
export const PROCEED_REALIZATION_DETAILS_BULK_DELETED_OK =
  "PROCEED_REALIZATION_DETAILS_BULK_DELETED_OK";
export const PROCEED_REALIZATION_DETAILS_BULK_DELETED_ERROR =
  "PROCEED_REALIZATION_DETAILS_BULK_DELETED_ERROR";

export const RESET = "RESET";