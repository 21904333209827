import {
  ADD_TNA_PLAN_DETAIL,
  ADD_TNA_PLAN_DETAIL_ERROR,
  ADD_TNA_PLAN_DETAIL_OK,
  RETRIEVE_TNA_PLAN_DETAIL_DELETE,
  TNA_PLAN_DETAIL_DELETED_ERROR,
  TNA_PLAN_DETAIL_DELETED_OK,
} from "../../constants/modules/tnaPlanDetailsConstants";

export const deleteTnaPlanDetailAction = (item) => ({
  type: RETRIEVE_TNA_PLAN_DETAIL_DELETE,
  payload: true,
  tnaPlanDetail: item,
});

export const deleteTnaPlanDetailOkAction = () => ({
  type: TNA_PLAN_DETAIL_DELETED_OK,
  payload: true,
});

export const deleteTnaPlanDetailErrorAction = (state) => ({
  type: TNA_PLAN_DETAIL_DELETED_ERROR,
  payload: state,
});

export const addTnaPlanDetailAction = (item) => ({
  type: ADD_TNA_PLAN_DETAIL,
  payload: true,
  tnaPlanDetail: item,
});

export const addTnaPlanDetailOkAction = (items) => ({
  type: ADD_TNA_PLAN_DETAIL_OK,
  payload: items,
});

export const addTnaPlanDetailErrorAction = (state) => ({
  type: ADD_TNA_PLAN_DETAIL_ERROR,
  payload: state,
});
