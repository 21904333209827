import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_SAMPLE_STATUS,
  BEGIN_EDIT_SAMPLE_STATUS,
  BEGIN_SAMPLE_STATUS_DOWNLOAD,
  RETRIEVE_SAMPLE_STATUS_BULK_DELETE,
  RETRIEVE_SAMPLE_STATUS_DELETE
} from "../../constants/modules/sampleStatusConstant";

import {
  addSampleStatusErrorAction,
  addSampleStatusOkAction,
  bulkDeleteSampleStatusErrorAction,
  bulkDeleteSampleStatusOkAction,
  deleteSampleStatusErrorAction,
  deleteSampleStatusOkAction,
  downloadSampleStatusesErrorAction,
  downloadSampleStatusesOkAction,
  editSampleStatusErrorAction,
  editSampleStatusOkAction
} from "../../actions/modules/sampleStatusActions";

import {
  addSampleStatusDB,
  bulkDeleteSampleStatusDB,
  deleteSampleStatusDB,
  editSampleStatusDB,
  retrieveSampleStatusData
} from "../../../config/apiCalls/sampleStatusAPICall";

// Retrieve products
// worker saga
export function* retrieveSampleStatus() {
  try {
    const response = yield call(retrieveSampleStatusData);

    if (response.status === 200) {
      yield put(downloadSampleStatusesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSampleStatusesErrorAction());
  }
}

// Create new product
// worker saga
export function* addSampleStatus(action) {
  const sampleStatus = action.sampleStatus;

  console.log(sampleStatus);

  try {
    const response = yield call(addSampleStatusDB, sampleStatus);

    if (response.status === 201) {
      yield delay(500);

      yield put(addSampleStatusOkAction(sampleStatus));
      toast.success("SampleStatus created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addSampleStatusErrorAction(true));
    toast.error("SampleStatus created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteSampleStatus(action) {
  const id = action.payload;
  try {
    yield call(deleteSampleStatusDB, id);
    yield deleteSampleStatusOkAction();
    toast.success("SampleStatus deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteSampleStatusErrorAction();
  }
}

// Edit product
// worker saga
export function* editSampleStatus(action) {
  const sampleStatus = action.sampleStatus;

  try {
    yield call(editSampleStatusDB, sampleStatus);
    yield editSampleStatusOkAction(sampleStatus);
    // Alert
    // Alert
    toast.success("SampleStatus updated Successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editSampleStatusErrorAction();
  }
}

// BULK SAGAS
export function* bulkSampleStatusDelete(action) {
  const sampleStatus = action.sampleStatus;

  try {
    const response = yield call(bulkDeleteSampleStatusDB, sampleStatus);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteSampleStatusOkAction(sampleStatus));
      toast.success("Bulk SampleStatus deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteSampleStatusErrorAction(true));
    toast.error("Bulk SampleStatus deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchSampleStatusAsync() {
  yield takeLatest(BEGIN_SAMPLE_STATUS_DOWNLOAD, retrieveSampleStatus);
  yield takeLatest(ADD_SAMPLE_STATUS, addSampleStatus);
  yield takeLatest(RETRIEVE_SAMPLE_STATUS_DELETE, deleteSampleStatus);
  yield takeLatest(BEGIN_EDIT_SAMPLE_STATUS, editSampleStatus);
  yield takeLatest(RETRIEVE_SAMPLE_STATUS_BULK_DELETE, bulkSampleStatusDelete);
}
