export const ADD_TNA_TEMPLATE_ACTIONS = "ADD_TNA_TEMPLATE_ACTIONS";
export const ADD_TNA_TEMPLATE_ACTIONS_OK = "ADD_TNA_TEMPLATE_ACTIONS_OK";
export const ADD_TNA_TEMPLATE_ACTIONS_ERROR = "ADD_TNA_TEMPLATE_ACTIONS_ERROR";

export const BEGIN_TNA_TEMPLATE_ACTIONS_DOWNLOAD =
  "BEGIN_TNA_TEMPLATE_ACTIONS_DOWNLOAD";
export const TNA_TEMPLATE_ACTIONS_DOWNLOAD_OK =
  "TNA_TEMPLATE_ACTIONS_DOWNLOAD_OK";
export const TNA_TEMPLATE_ACTIONS_DOWNLOAD_ERROR =
  "TNA_TEMPLATE_ACTIONS_DOWNLOAD_ERROR";

export const RETRIEVE_TNA_TEMPLATE_ACTIONS_DELETE =
  "RETRIEVE_TNA_TEMPLATE_ACTIONS_DELETE";
export const TNA_TEMPLATE_ACTIONS_DELETED_OK =
  "TNA_TEMPLATE_ACTIONS_DELETED_OK";
export const TNA_TEMPLATE_ACTIONS_DELETED_ERROR =
  "TNA_TEMPLATE_ACTIONS_DELETED_ERROR";

export const RETRIEVE_TNA_TEMPLATE_ACTIONS_EDIT =
  "RETRIEVE_TNA_TEMPLATE_ACTIONS_EDITED";
export const BEGIN_EDIT_TNA_TEMPLATE_ACTIONS =
  "BEGIN_EDIT_TNA_TEMPLATE_ACTIONS";
export const TNA_TEMPLATE_ACTIONS_EDITED_OK = "TNA_TEMPLATE_ACTIONS_EDITED_OK";
export const TNA_TEMPLATE_ACTIONS_EDITED_ERROR =
  "TNA_TEMPLATE_ACTIONS_EDITED_ERROR";

export const RETRIEVE_TNA_TEMPLATE_ACTIONS_BULK_DELETE =
  "RETRIEVE_TNA_TEMPLATE_ACTIONS_BULK_DELETE";
export const TNA_TEMPLATE_ACTIONS_BULK_DELETED_OK =
  "TNA_TEMPLATE_ACTIONS_BULK_DELETED_OK";
export const TNA_TEMPLATE_ACTIONS_BULK_DELETED_ERROR =
  "TNA_TEMPLATE_ACTIONS_BULK_DELETED_ERROR";
