export const ADD_COUNTRIES = "ADD_COUNTRIES";
export const ADD_COUNTRIES_OK = "ADD_COUNTRIES_OK";
export const ADD_COUNTRIES_ERROR = "ADD_COUNTRIES_ERROR";

export const BEGIN_COUNTRIES_DOWNLOAD = "BEGIN_COUNTRIES_DOWNLOAD";
export const COUNTRIES_DOWNLOAD_OK = "COUNTRIES_DOWNLOAD_OK";
export const COUNTRIES_DOWNLOAD_ERROR = "COUNTRIES_DOWNLOAD_ERROR";

export const RETRIEVE_COUNTRIES_DELETE = "RETRIEVE_COUNTRIES_DELETE";
export const COUNTRIES_DELETED_OK = "COUNTRIES_DELETED_OK";
export const COUNTRIES_DELETED_ERROR = "COUNTRIES_DELETED_ERROR";

export const RETRIEVE_COUNTRIES_EDIT = "RETRIEVE_COUNTRIES_EDITED";
export const BEGIN_EDIT_COUNTRIES = "BEGIN_EDIT_COUNTRIES";
export const COUNTRIES_EDITED_OK = "COUNTRIES_EDITED_OK";
export const COUNTRIES_EDITED_ERROR = "COUNTRIES_EDITED_ERROR";

export const RETRIEVE_COUNTRIES_BULK_DELETE = "RETRIEVE_COUNTRIES_BULK_DELETE";
export const COUNTRIES_BULK_DELETED_OK = "COUNTRIES_BULK_DELETED_OK";
export const COUNTRIES_BULK_DELETED_ERROR = "COUNTRIES_BULK_DELETED_ERROR";