import {
  ACCOUNTS_BULK_DELETED_ERROR,
  ACCOUNTS_BULK_DELETED_OK,
  ACCOUNTS_DELETED_ERROR,
  ACCOUNTS_DELETED_OK,
  ACCOUNTS_DOWNLOAD_ERROR,
  ACCOUNTS_DOWNLOAD_OK,
  ACCOUNTS_EDITED_ERROR,
  ACCOUNTS_EDITED_OK,
  ADD_ACCOUNTS,
  ADD_ACCOUNTS_ERROR,
  ADD_ACCOUNTS_OK,
  BEGIN_ACCOUNTS_DOWNLOAD,
  BEGIN_EDIT_ACCOUNTS,
  RETRIEVE_ACCOUNTS_BULK_DELETE,
  RETRIEVE_ACCOUNTS_DELETE,
  RETRIEVE_ACCOUNTS_EDIT,
} from "../../constants/modules/accountsConstants";

const initialState = {
  accounts: [],
  error: false,
  loading: false,
  deleteAccount: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_ACCOUNTS_DOWNLOAD:
    case ADD_ACCOUNTS:
    case RETRIEVE_ACCOUNTS_BULK_DELETE:
    case BEGIN_EDIT_ACCOUNTS:
      return {
        ...state,
        loading: action.payload,
        account: action.account,
      };

    case ADD_ACCOUNTS_OK:
    case ACCOUNTS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_ACCOUNTS_ERROR:
    case ACCOUNTS_DOWNLOAD_ERROR:
    case ACCOUNTS_DELETED_ERROR:
    case ACCOUNTS_BULK_DELETED_ERROR:
    case ACCOUNTS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ACCOUNTS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        accounts: action.payload,
      };

    case RETRIEVE_ACCOUNTS_DELETE:
      return {
        ...state,
        deleteAccount: action.payload,
      };

    case ACCOUNTS_DELETED_OK:
      return {
        ...state,
        accounts: state.accounts.data.filter(
          (account) => account.id !== state.deleteAccount
        ),
        deleteAccount: null,
      };

    case RETRIEVE_ACCOUNTS_EDIT:
      return {
        ...state,
        editAccount: action.payload,
      };

    case ACCOUNTS_EDITED_OK:
      return {
        ...state,
        editAccount: null,
        accounts: state.accounts.data.map((account) =>
          account.id === action.payload.id
            ? (account = action.payload)
            : account
        ),
      };

    default:
      return state;
  }
}
