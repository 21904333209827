import {
  ADD_BUYER_DEPT,
  ADD_BUYER_DEPT_OK,
  ADD_BUYER_DEPT_ERROR,
  BEGIN_BUYER_DEPT_DOWNLOAD,
  BUYER_DEPT_DOWNLOAD_OK,
  BUYER_DEPT_DOWNLOAD_ERROR,
  RETRIEVE_BUYER_DEPT_DELETE,
  BUYER_DEPT_DELETED_OK,
  BUYER_DEPT_DELETED_ERROR,
  RETRIEVE_BUYER_DEPT_EDIT,
  BEGIN_EDIT_BUYER_DEPT,
  BUYER_DEPT_EDITED_OK,
  BUYER_DEPT_EDITED_ERROR,
  RETRIEVE_BUYER_DEPT_BULK_DELETE,
  BUYER_DEPT_BULK_DELETED_OK,
  BUYER_DEPT_BULK_DELETED_ERROR,
  BEGIN_BUYER_DEPT_BY_BRAND_DOWNLOAD,
  BUYER_DEPT_BY_BRAND_DOWNLOAD_OK,
  BUYER_DEPT_BY_BRAND_DOWNLOAD_ERROR,
} from "../../constants/modules/buyerDeptConstants";

const initialState = {
  buyerDepartments: [],
  buyerDepartmentsById: [],
  error: false,
  loading: false,
  deleteBuyerDepartment: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_BUYER_DEPT_DOWNLOAD:
    case BEGIN_BUYER_DEPT_BY_BRAND_DOWNLOAD:
    case ADD_BUYER_DEPT:
    case RETRIEVE_BUYER_DEPT_BULK_DELETE:
    case BEGIN_EDIT_BUYER_DEPT:
      return {
        ...state,
        loading: action.payload,
        buyerDepartment: action.buyerDepartment,
      };

    case ADD_BUYER_DEPT_OK:
    case BUYER_DEPT_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_BUYER_DEPT_ERROR:
    case BUYER_DEPT_DOWNLOAD_ERROR:
    case BUYER_DEPT_BY_BRAND_DOWNLOAD_ERROR:
    case BUYER_DEPT_DELETED_ERROR:
    case BUYER_DEPT_BULK_DELETED_ERROR:
    case BUYER_DEPT_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BUYER_DEPT_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        buyerDepartments: action.payload,
      };

    case BUYER_DEPT_BY_BRAND_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        buyerDepartmentsById: action.payload,
      };

    case RETRIEVE_BUYER_DEPT_DELETE:
      return {
        ...state,
        deleteBuyerDepartment: action.payload,
      };

    case BUYER_DEPT_DELETED_OK:
      return {
        ...state,
        buyerDepartments: state.buyerDepartments.data.filter(
          (buyerDepartment) =>
            buyerDepartment.id !== state.deleteBuyerDepartment
        ),
        deleteBuyerDepartment: null,
      };

    case RETRIEVE_BUYER_DEPT_EDIT:
      return {
        ...state,
        editBuyerDepartment: action.payload,
      };

    case BUYER_DEPT_EDITED_OK:
      return {
        ...state,
        editBuyerDepartment: null,
        buyerDepartments: state.buyerDepartments.data.map((buyerDepartment) =>
          buyerDepartment.id === action.payload.id
            ? (buyerDepartment = action.payload)
            : buyerDepartment
        ),
      };

    default:
      return state;
  }
}
