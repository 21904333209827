export const ADD_FACTORY_ORDER_DETAILS = "ADD_FACTORY_ORDER_DETAILS";
export const ADD_FACTORY_ORDER_DETAILS_OK = "ADD_FACTORY_ORDER_DETAILS_OK";
export const ADD_FACTORY_ORDER_DETAILS_ERROR =
  "ADD_FACTORY_ORDER_DETAILS_ERROR";

export const BEGIN_FACTORY_ORDER_DETAILS_DOWNLOAD =
  "BEGIN_FACTORY_ORDER_DETAILS_DOWNLOAD";
export const FACTORY_ORDER_DETAILS_DOWNLOAD_OK =
  "FACTORY_ORDER_DETAILS_DOWNLOAD_OK";
export const FACTORY_ORDER_DETAILS_DOWNLOAD_ERROR =
  "FACTORY_ORDER_DETAILS_DOWNLOAD_ERROR";

export const BEGIN_ORDER_DETAILS_BY_FO_DOWNLOAD =
  "BEGIN_ORDER_DETAILS_BY_FO_DOWNLOAD";
export const ORDER_DETAILS_BY_FO_DOWNLOAD_OK =
  "ORDER_DETAILS_BY_FO_DOWNLOAD_OK";
export const ORDER_DETAILS_BY_FO_DOWNLOAD_ERROR =
  "ORDER_DETAILS_BY_FO_DOWNLOAD_ERROR";

export const RETRIEVE_FACTORY_ORDER_DETAILS_EDIT =
  "RETRIEVE_FACTORY_ORDER_DETAILS_EDITED";
export const BEGIN_EDIT_FACTORY_ORDER_DETAILS =
  "BEGIN_EDIT_FACTORY_ORDER_DETAILS";
export const FACTORY_ORDER_DETAILS_EDITED_OK =
  "FACTORY_ORDER_DETAILS_EDITED_OK";
export const FACTORY_ORDER_DETAILS_EDITED_ERROR =
  "FACTORY_ORDER_DETAILS_EDITED_ERROR";

export const RETRIEVE_FACTORY_ORDER_DETAILS_DELETE =
  "RETRIEVE_FACTORY_ORDER_DETAILS_DELETE";
export const FACTORY_ORDER_DETAILS_DELETED_OK =
  "FACTORY_ORDER_DETAILS_DELETED_OK";
export const FACTORY_ORDER_DETAILS_DELETED_ERROR =
  "FACTORY_ORDER_DETAILS_DELETED_ERROR";

export const RETRIEVE_FACTORY_ORDER_DETAILS_BULK_DELETE =
  "RETRIEVE_FACTORY_ORDER_DETAILS_BULK_DELETE";
export const FACTORY_ORDER_DETAILS_BULK_DELETED_OK =
  "FACTORY_ORDER_DETAILS_BULK_DELETED_OK";
export const FACTORY_ORDER_DETAILS_BULK_DELETED_ERROR =
  "FACTORY_ORDER_DETAILS_BULK_DELETED_ERROR";
