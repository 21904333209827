import axiosClient from "../axiosClient";
/*-------------------------------------------------*/
/*           ACCOUNT TYPE TYPES API CALLS          */
/*-------------------------------------------------*/

export async function retrieveAccountTypesData() {
  return await axiosClient.get("/account_types");
}

export async function addAccountTypeDB(value) {
  return await axiosClient.post("/account_types", value);
}

export async function deleteAccountTypeDB(id) {
  return await axiosClient.delete(`/account_types/${id}`);
}

export async function bulkDeleteAccountTypeDB(ids) {
  return await axiosClient.post(`/account_types/bulk_delete`, ids);
}

export async function editAccountTypeDB(value) {
  return await axiosClient.put(`/account_types/${value.id}`, value);
}
