import { toast } from "react-toastify";
import {
  call,
  delay,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  ADD_BUYERS,
  BEGIN_BUYERS_DOWNLOAD,
  BEGIN_EDIT_BUYERS,
  RETRIEVE_BUYERS_BULK_DELETE,
  RETRIEVE_BUYERS_DELETE,
} from "../../constants/modules/buyersConstants";

import {
  addBuyerErrorAction,
  addBuyerOkAction,
  bulkDeleteBuyerErrorAction,
  bulkDeleteBuyerOkAction,
  deleteBuyerErrorAction,
  deleteBuyerOkAction,
  downloadBuyersErrorAction,
  downloadBuyersOkAction,
  editBuyerErrorAction,
  editBuyerOkAction,
} from "../../actions/modules/buyersActions";

import {
  addBuyerDB,
  bulkDeleteBuyerDB,
  deleteBuyerDB,
  editBuyerDB,
  retrieveBuyerDB,
} from "../../../config/apiCalls/buyersAPICall";

// Retrieve products
// worker saga
export function* retrieveBuyers() {
  try {
    const response = yield call(retrieveBuyerDB);
    if (response.status === 200) {
      yield put(downloadBuyersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadBuyersErrorAction());
  }
}

// Create new product
// worker saga
export function* addBuyer(action) {
  const buyer = action.buyer;

  try {
    const response = yield call(addBuyerDB, buyer);
    if (response.status === 201) {
      yield delay(500);
      yield put(addBuyerOkAction(buyer));
      toast.success("Buyer created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addBuyerErrorAction(true));
    toast.error("Buyer created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteBuyer(action) {
  const id = action.payload;
  try {
    yield call(deleteBuyerDB, id);
    yield deleteBuyerOkAction();
    toast.success("Buyer deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteBuyerErrorAction());
    toast.error("Buyer deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkBuyerDelete(action) {
  const buyer = action.buyer;

  try {
    const response = yield call(bulkDeleteBuyerDB, buyer);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteBuyerOkAction(buyer));
      toast.success("Bulk Buyer deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteBuyerErrorAction(true));
    toast.error("Bulk Buyer deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editBuyer(action) {
  const buyer = action.buyer;

  try {
    const response = yield call(editBuyerDB, buyer);

    if (response.status === 201) {
      yield delay(500);
      yield put(editBuyerOkAction(buyer));
      toast.success("Buyer Updated successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editBuyerErrorAction());
    toast.error("Buyer Updated failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchBuyersAsync() {
  yield takeLatest(BEGIN_BUYERS_DOWNLOAD, retrieveBuyers);
  yield takeLatest(ADD_BUYERS, addBuyer);
  yield takeLatest(RETRIEVE_BUYERS_DELETE, deleteBuyer);
  yield takeLatest(BEGIN_EDIT_BUYERS, editBuyer);
  yield takeLatest(RETRIEVE_BUYERS_BULK_DELETE, bulkBuyerDelete);
}
