export const ADD_MEASURING_UNITS = "ADD_MEASURING_UNITS";
export const ADD_MEASURING_UNITS_OK = "ADD_MEASURING_UNITS_OK";
export const ADD_MEASURING_UNITS_ERROR = "ADD_MEASURING_UNITS_ERROR";

export const BEGIN_MEASURING_UNITS_DOWNLOAD = "BEGIN_MEASURING_UNITS_DOWNLOAD";
export const MEASURING_UNITS_DOWNLOAD_OK = "MEASURING_UNITS_DOWNLOAD_OK";
export const MEASURING_UNITS_DOWNLOAD_ERROR = "MEASURING_UNITS_DOWNLOAD_ERROR";

export const RETRIEVE_MEASURING_UNITS_DELETE = "RETRIEVE_MEASURING_UNITS_DELETE";
export const MEASURING_UNITS_DELETED_OK = "MEASURING_UNITS_DELETED_OK";
export const MEASURING_UNITS_DELETED_ERROR = "MEASURING_UNITS_DELETED_ERROR";

export const RETRIEVE_MEASURING_UNITS_EDIT = "RETRIEVE_MEASURING_UNITS_EDITED";
export const BEGIN_EDIT_MEASURING_UNITS = "BEGIN_EDIT_MEASURING_UNITS";
export const MEASURING_UNITS_EDITED_OK = "MEASURING_UNITS_EDITED_OK";
export const MEASURING_UNITS_EDITED_ERROR = "MEASURING_UNITS_EDITED_ERROR";

export const RETRIEVE_MEASURING_UNITS_BULK_DELETE = "RETRIEVE_MEASURING_UNITS_BULK_DELETE";
export const MEASURING_UNITS_BULK_DELETED_OK = "MEASURING_UNITS_BULK_DELETED_OK";
export const MEASURING_UNITS_BULK_DELETED_ERROR = "MEASURING_UNITS_BULK_DELETED_ERROR";