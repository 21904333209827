import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*             SAMPLE STATUS API CALLS             */
/*-------------------------------------------------*/

export async function retrieveSampleStatusData() {
    return await axiosClient.get("/sample_status");
  }
  
  export async function addSampleStatusDB(value) {  
    return await axiosClient.post("/sample_status", value);
  }
  
  export async function deleteSampleStatusDB(id) {
    return await axiosClient.delete(`/sample_status/${id}`);
  }
  
  export async function bulkDeleteSampleStatusDB(ids) {
    return await axiosClient.post(`/sample_status/bulk_delete`, ids);
  }
  
  export async function editSampleStatusDB(value) {
    return await axiosClient.put(`/sample_status/${value.id}`, value);
  }
  