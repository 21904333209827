import {
  ADD_OVERSEAS_OFFICES, ADD_OVERSEAS_OFFICES_ERROR, ADD_OVERSEAS_OFFICES_OK, BEGIN_EDIT_OVERSEAS_OFFICES, BEGIN_OVERSEAS_OFFICES_DOWNLOAD, OVERSEAS_OFFICES_BULK_DELETED_ERROR, OVERSEAS_OFFICES_BULK_DELETED_OK, OVERSEAS_OFFICES_DELETED_ERROR, OVERSEAS_OFFICES_DELETED_OK, OVERSEAS_OFFICES_DOWNLOAD_ERROR, OVERSEAS_OFFICES_DOWNLOAD_OK, OVERSEAS_OFFICES_EDITED_ERROR, OVERSEAS_OFFICES_EDITED_OK, RETRIEVE_OVERSEAS_OFFICES_BULK_DELETE, RETRIEVE_OVERSEAS_OFFICES_DELETE, RETRIEVE_OVERSEAS_OFFICES_EDIT
} from "../../constants/modules/overseasConstants";
    
    const initialState = {
      overseas: [],
      error: false,
      loading: false,
      deleteOverseas: null,
    };
    
    export default function (state = initialState, action) {
      switch (action.type) {
        case BEGIN_OVERSEAS_OFFICES_DOWNLOAD:
        case ADD_OVERSEAS_OFFICES:
        case RETRIEVE_OVERSEAS_OFFICES_BULK_DELETE:
        case BEGIN_EDIT_OVERSEAS_OFFICES:
          return {
            ...state,
            loading: action.payload,
            overseas: action.overseas,
          };
    
        case ADD_OVERSEAS_OFFICES_OK:
        case OVERSEAS_OFFICES_BULK_DELETED_OK:
          return {
            ...state,
            loading: false,
          };
    
        case ADD_OVERSEAS_OFFICES_ERROR:
        case OVERSEAS_OFFICES_DOWNLOAD_ERROR:
        case OVERSEAS_OFFICES_BULK_DELETED_ERROR:
        case OVERSEAS_OFFICES_DELETED_ERROR:
        case OVERSEAS_OFFICES_EDITED_ERROR:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case OVERSEAS_OFFICES_DOWNLOAD_OK:
          return {
            ...state,
            loading: false,
            error: null,
            overseas: action.payload,
          };
    
        case RETRIEVE_OVERSEAS_OFFICES_DELETE:
          return {
            ...state,
            deleteOverseas: action.payload,
          };
    
        case OVERSEAS_OFFICES_DELETED_OK:
          return {
            ...state,
            overseas: state.overseas.data.filter((oversea) => oversea.id !== state.deleteOverseas),
            deleteOverseas: null,
          };
    
        case RETRIEVE_OVERSEAS_OFFICES_EDIT:
          return {
            ...state,
            editOverseas: action.payload,
          };
    
        case OVERSEAS_OFFICES_EDITED_OK:
          return {
            ...state,
            editOverseas: null,
            overseas: state.overseas.data.map((oversea) =>
              oversea.id === action.payload.id ? (oversea = action.payload) : oversea
            ),
          };
    
        default:
          return state;
      }
    }
    