import {
    ADD_PROCEED_REALIZATION_DETAILS,
    ADD_PROCEED_REALIZATION_DETAILS_ERROR,
    ADD_PROCEED_REALIZATION_DETAILS_OK,
    BEGIN_EDIT_PROCEED_REALIZATION_DETAILS, BEGIN_PROCEED_REALIZATION_DETAILS_DOWNLOAD, BEGIN_PROCEED_REALIZATION_DETAIL_DOWNLOAD, PROCEED_REALIZATION_DETAILS_BULK_DELETED_ERROR,
    PROCEED_REALIZATION_DETAILS_BULK_DELETED_OK,
    PROCEED_REALIZATION_DETAILS_DELETED_ERROR,
    PROCEED_REALIZATION_DETAILS_DELETED_OK, PROCEED_REALIZATION_DETAILS_DOWNLOAD_ERROR,
    PROCEED_REALIZATION_DETAILS_DOWNLOAD_OK,
    PROCEED_REALIZATION_DETAILS_EDITED_ERROR,
    PROCEED_REALIZATION_DETAILS_EDITED_OK,
    PROCEED_REALIZATION_DETAIL_DOWNLOAD_ERROR,
    PROCEED_REALIZATION_DETAIL_DOWNLOAD_OK, RESET,
    RETRIEVE_PROCEED_REALIZATION_DETAILS_BULK_DELETE,
    RETRIEVE_PROCEED_REALIZATION_DETAILS_DELETE,
    RETRIEVE_PROCEED_REALIZATION_DETAILS_EDIT
} from "../../constants/modules/proceedRealizationDetailsConstant";
  
  const initialState = {
    proceedRealizationDetails: [],
    getProceedRealizationDetail: null,
    proceedRealizationDetailAddInfo: null,
    error: false,
    loading: false,
    deleteProceedRealizationDetail: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_PROCEED_REALIZATION_DETAILS_DOWNLOAD:
      case BEGIN_PROCEED_REALIZATION_DETAIL_DOWNLOAD:
      case RETRIEVE_PROCEED_REALIZATION_DETAILS_BULK_DELETE:
      case ADD_PROCEED_REALIZATION_DETAILS:
      case BEGIN_EDIT_PROCEED_REALIZATION_DETAILS:
        return {
          ...state,
          loading: action.payload,
          proceedRealizationDetail: action.proceedRealizationDetail,
        };
  
      case PROCEED_REALIZATION_DETAILS_BULK_DELETED_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_PROCEED_REALIZATION_DETAILS_ERROR:
      case PROCEED_REALIZATION_DETAILS_DOWNLOAD_ERROR:
      case PROCEED_REALIZATION_DETAILS_BULK_DELETED_ERROR:
      case PROCEED_REALIZATION_DETAIL_DOWNLOAD_ERROR:
      case PROCEED_REALIZATION_DETAILS_DELETED_ERROR:
      case PROCEED_REALIZATION_DETAILS_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case PROCEED_REALIZATION_DETAILS_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          proceedRealizationDetails: action.payload,
        };
  
      case PROCEED_REALIZATION_DETAIL_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          getProceedRealizationDetail: action.payload,
        };
  
      case ADD_PROCEED_REALIZATION_DETAILS_OK:
        return {
          ...state,
          loading: false,
          error: null,
          proceedRealizationDetailAddInfo: action.payload,
        };
  
      case RETRIEVE_PROCEED_REALIZATION_DETAILS_DELETE:
        return {
          ...state,
          deleteProceedRealizationDetail: action.payload,
        };
  
      case PROCEED_REALIZATION_DETAILS_DELETED_OK:
        return {
          ...state,
          proceedRealizationDetails: state.proceedRealizationDetails.data.filter(
            (proceedRealizationDetail) => proceedRealizationDetail.id !== state.deleteProceedRealizationDetail
          ),
          deleteProceedRealizationDetail: null,
        };
  
      case RETRIEVE_PROCEED_REALIZATION_DETAILS_EDIT:
        return {
          ...state,
          editProceedRealizationDetail: action.payload,
        };
  
      case PROCEED_REALIZATION_DETAILS_EDITED_OK:
        return {
          ...state,
          editProceedRealizationDetails: null,
          proceedRealizationDetails: state.proceedRealizationDetails.data.map((proceedRealizationDetail) =>
            proceedRealizationDetail.id === action.payload.id
              ? (proceedRealizationDetail = action.payload)
              : proceedRealizationDetail
          ),
        };
  
      case RESET:
        return {
          ...state,
          getProceedRealizationDetail: null,
          proceedRealizationDetailAddInfo: null,
        };
  
      default:
        return state;
    }
  }
  