export const ADD_ORDER_DETAILS = "ADD_ORDER_DETAILS";
export const ADD_ORDER_DETAILS_OK = "ADD_ORDER_DETAILS_OK";
export const ADD_ORDER_DETAILS_ERROR = "ADD_ORDER_DETAILS_ERROR";

export const BEGIN_ORDER_DETAILS_DOWNLOAD = "BEGIN_ORDER_DETAILS_DOWNLOAD";
export const ORDER_DETAILS_DOWNLOAD_OK = "ORDER_DETAILS_DOWNLOAD_OK";
export const ORDER_DETAILS_DOWNLOAD_ERROR = "ORDER_DETAILS_DOWNLOAD_ERROR";

export const RETRIEVE_ORDER_DETAILS_EDIT = "RETRIEVE_ORDER_DETAILS_EDITED";
export const BEGIN_EDIT_ORDER_DETAILS = "BEGIN_EDIT_ORDER_DETAILS";
export const ORDER_DETAILS_EDITED_OK = "ORDER_DETAILS_EDITED_OK";
export const ORDER_DETAILS_EDITED_ERROR = "ORDER_DETAILS_EDITED_ERROR";

export const RETRIEVE_ORDER_DETAILS_DELETE = "RETRIEVE_ORDER_DETAILS_DELETE";
export const ORDER_DETAILS_DELETED_OK = "ORDER_DETAILS_DELETED_OK";
export const ORDER_DETAILS_DELETED_ERROR = "ORDER_DETAILS_DELETED_ERROR";

export const RETRIEVE_ORDER_DETAILS_BULK_DELETE =
  "RETRIEVE_ORDER_DETAILS_BULK_DELETE";
export const ORDER_DETAILS_BULK_DELETED_OK = "ORDER_DETAILS_BULK_DELETED_OK";
export const ORDER_DETAILS_BULK_DELETED_ERROR =
  "ORDER_DETAILS_BULK_DELETED_ERROR";

export const RESET = "RESET";
