import {
  ADD_COMPANIES,
  ADD_COMPANIES_ERROR,
  ADD_COMPANIES_OK,
  BEGIN_COMPANIES_DOWNLOAD,
  BEGIN_EDIT_COMPANIES,
  COMPANIES_BULK_DELETED_ERROR,
  COMPANIES_BULK_DELETED_OK,
  COMPANIES_DELETED_ERROR,
  COMPANIES_DELETED_OK,
  COMPANIES_DOWNLOAD_ERROR,
  COMPANIES_DOWNLOAD_OK,
  COMPANIES_EDITED_ERROR,
  COMPANIES_EDITED_OK,
  RETRIEVE_COMPANIES_BULK_DELETE,
  RETRIEVE_COMPANIES_DELETE,
  RETRIEVE_COMPANIES_EDIT
} from "../../constants/modules/companiesConstants";

export const bulkDeleteCompanyAction = (item) => ({
  type: RETRIEVE_COMPANIES_BULK_DELETE,
  payload: true,
  company: item,
});

export const bulkDeleteCompanyOkAction = () => ({
  type: COMPANIES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteCompanyErrorAction = (state) => ({
  type: COMPANIES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadCompaniesAction = () => ({
  type: BEGIN_COMPANIES_DOWNLOAD,
  payload: true,
});

export const downloadCompaniesOkAction = (items) => ({
  type: COMPANIES_DOWNLOAD_OK,
  payload: items,
});

export const downloadCompaniesErrorAction = () => ({
  type: COMPANIES_DOWNLOAD_ERROR,
  payload: true,
});

export const addCompanyAction = (item) => ({
  type: ADD_COMPANIES,
  payload: true,
  company: item,
});

export const addCompanyOkAction = () => ({
  type: ADD_COMPANIES_OK,
  payload: true,
});

export const addCompanyErrorAction = (state) => ({
  type: ADD_COMPANIES_ERROR,
  payload: state,
});

export const deleteCompanyAction = (id) => ({
  type: RETRIEVE_COMPANIES_DELETE,
  payload: id,
});

export const deleteCompanyOkAction = () => ({
  type: COMPANIES_DELETED_OK,
});

export const deleteCompanyErrorAction = () => ({
  type: COMPANIES_DELETED_ERROR,
  payload: true,
});

export const retrieveCompanyAction = (item) => ({
  type: RETRIEVE_COMPANIES_EDIT,
  payload: item,
});

export const editCompanyAction = (item) => ({
  type: BEGIN_EDIT_COMPANIES,
  company: item,
});

export const editCompanyOkAction = (item) => ({
  type: COMPANIES_EDITED_OK,
  payload: item,
});

export const editCompanyErrorAction = () => ({
  type: COMPANIES_EDITED_ERROR,
  payload: true,
});
