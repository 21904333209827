import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import backgroundImg from "../../assets/background1.jpeg";
const backgroundImage = require("../../assets/back2.png");

import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Box,
} from "@material-ui/core";

import { downloadCompaniesAction } from "../../redux/actions/modules/companiesActions";

import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../redux/actions/auth/authActions";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://clonestudiobd.com/">
        Clone Studio
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh ",
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  loginGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(35),
  },
  paper: {
    margin: theme.spacing(1, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(2),
    width: theme.spacing(20),
    height: theme.spacing(5),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "40%",
    marginTop: -10,
    marginLeft: -12,
  },
}));

const SignIn = ({ location, history }) => {
  const classes = useStyles();

  const [companyValue, setCompanyValue] = React.useState(null);
  const [inputCompanyValue, setInputCompanyValue] = React.useState("");

  // const [user_id, setUser_id] = useState("");
  // const [password, setPassword] = useState("");

  const [credentials, setCredentials] = useState({
    user_id: "",
    password: "",
    company_id: "",
  });

  const dispatch = useDispatch();

  const [isLoading, setLoading] = React.useState(false);

  const [isError, setSetError] = React.useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const companies = useSelector((state) => state?.companyList?.company?.data);

  const loginInfo = useSelector((state) => state?.loginInfoList?.loginInfo);

  const { loading, error } = useSelector((state) => state?.loginInfoList);

  const redirect = location.search ? location.search.split("=")[1] : "/app";

  useEffect(() => {
    if (loginInfo) {
      history.push(redirect);
    } else if (error) {
      setLoading(false);
    }
  }, [history, loginInfo, error, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (credentials.user_id !== "" && credentials.password !== "") {
      dispatch(loginAction(credentials));
      setLoading(true);
      setSetError(false);
    } else if (credentials.user_id === "" || credentials.password === "") {
      setSetError(true);
    }
  };

  useEffect(() => {
    dispatch(downloadCompaniesAction());
  }, [dispatch]);

  useEffect(() => {
    if (companyValue) {
      setCredentials({
        ...credentials,
        company_id: companyValue.id,
      });
    }
  }, [companyValue]);

  useEffect(() => {
    if (inputCompanyValue === "") {
      setCredentials({
        ...credentials,
        company_id: "",
      });
    }
  }, [inputCompanyValue]);

  // useEffect(() => {
  //   if (companyValue) {
  //     localStorage.setItem("company-info", companyValue);
  //   }
  // }, [companyValue]);

  const headerSX = {
    "& .MuiCardHeader-action": { marginRight: 0 },
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid
              item
              xs={1}
              sm={6}
              style={{
                marginBottom: 0,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: `500px 500px`,
                  backgroundPosition: "center",
                  height: "100vh",
                }}
              ></div>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sm={6}
              style={{ marginBottom: 0 }}
            >
              <Grid item xs={1} sm={2} style={{ marginBottom: 0 }}></Grid>
              <Grid item xs={1} sm={8} style={{ marginBottom: 0 }}>
                <Card
                  // ref={ref}
                  // {...others}
                  style={{
                    border: "1px solid",
                    // borderColor: theme.palette.primary[200] + 25,
                    // ":hover": {
                    //   boxShadow: boxShadow
                    //     ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)"
                    //     : "inherit",
                    // },
                    // ...sx,
                  }}
                >
                  {/* card header and action */}

                  {/* <CardHeader
                  style={headerSX}
                  title={<Typography variant="h3">abcd</Typography>}
                  // action={secondary}
                />

                <Divider /> */}

                  {/* card content */}

                  <CardContent>
                    <Box p={3}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item sx={{ mb: 3 }}>
                          <Link to="#">{/* <Logo /> */}</Link>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction={"row"}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item>
                              <Box
                                alignItems="center"
                                justifyContent="center"
                                spacing={1}
                              >
                                <Typography
                                  // color={theme.palette.secondary.main}
                                  gutterBottom
                                  variant={"h4"}
                                >
                                  Hi, Welcome Back
                                </Typography>
                                <Typography
                                  variant="caption"
                                  fontSize="26px"
                                  textAlign={"center"}
                                >
                                  Enter your credentials to continue
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          {/* <AuthLogin /> */}
                          <form className={classes.form} noValidate>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              size="small"
                              required
                              fullWidth
                              id="email"
                              label="User Name"
                              name="user_id"
                              autoComplete="email"
                              error={
                                isError && credentials.user_id === ""
                                  ? true
                                  : false
                              }
                              defaultValue={credentials.user_id}
                              onChange={handleInputChange}
                            />
                            <TextField
                              variant="outlined"
                              margin="normal"
                              size="small"
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type={showPassword ? "text" : "password"}
                              id="password"
                              autoComplete="current-password"
                              error={
                                isError && credentials.password === ""
                                  ? true
                                  : false
                              }
                              defaultValue={credentials.password}
                              onChange={handleInputChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Grid container>
                              <Grid item xs>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      value="remember"
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <p style={{ fontSize: 14, margin: 0 }}>
                                      Remember me
                                    </p>
                                  }
                                />
                              </Grid>
                              <Grid item>
                                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
                              </Grid>
                            </Grid>
                            <div style={{ position: "relative" }}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={submitHandler}
                              >
                                {loading && (
                                  <CircularProgress size={25} color="inherit" />
                                )}
                                {!loading && "Sign In"}
                              </Button>
                              {/* {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )} */}
                            </div>
                          </form>
                        </Grid>
                        {/* <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          item
                          container
                          direction="column"
                          alignItems="center"
                          xs={12}
                        >
                          <Typography
                            component={Link}
                            to="/pages/register/register3"
                            variant="subtitle1"
                            sx={{ textDecoration: "none" }}
                          >
                            Don&apos;t have an account?
                          </Typography>
                        </Grid>
                      </Grid> */}
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={1} sm={2} style={{ marginBottom: 0 }}></Grid>
            </Grid>
            {/* <Grid item xs={1} sm={2} style={{ marginBottom: 0 }}></Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignIn;
