import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_FACTORY_ORDERS,
  BEGIN_FACTORY_ORDERS_DOWNLOAD,
  RETRIEVE_FACTORY_ORDERS_DELETE,
  BEGIN_EDIT_FACTORY_ORDERS,
  RETRIEVE_FACTORY_ORDERS_BULK_DELETE,
  BEGIN_FACTORY_BY_ORDER_DOWNLOAD,
} from "../../constants/modules/factoryOrdersConstants";

import {
  downloadFactoryOrdersOkAction,
  downloadFactoryOrdersErrorAction,
  addFactoryOrderOkAction,
  addFactoryOrderErrorAction,
  deleteFactoryOrderOkAction,
  deleteFactoryOrderErrorAction,
  editFactoryOrderOkAction,
  editFactoryOrderErrorAction,
  bulkDeleteFactoryOrderOkAction,
  bulkDeleteFactoryOrderErrorAction,
  downloadFactoryByOrdersOkAction,
  downloadFactoryByOrdersErrorAction,
} from "../../actions/modules/factoryOrdersActions";

import {
  retrieveFactoryOrderData,
  addFactoryOrderDB,
  deleteFactoryOrderDB,
  editFactoryOrderDB,
  bulkDeleteFactoryOrderDB,
  retrieveFactoryByOrderData,
} from "../../../config/apiCalls/factoryOrders";

// Retrieve products
// worker saga
export function* retrieveFactoryOrders(action) {
  const factoryOrder = action.factoryOrder;
  try {
    const response = yield call(retrieveFactoryOrderData, factoryOrder);
    if (response.status === 200) {
      yield put(downloadFactoryOrdersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFactoryOrdersErrorAction());
  }
}

export function* retrieveFactoryByOrders(action) {
  const factoryOrder = action.factoryOrder;
  try {
    const response = yield call(retrieveFactoryByOrderData, factoryOrder);
    if (response.status === 200) {
      yield put(downloadFactoryByOrdersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadFactoryByOrdersErrorAction());
  }
}

// Create new product
// worker saga
export function* addFactoryOrder(action) {
  const factoryOrder = action.factoryOrder;

  try {
    const response = yield call(addFactoryOrderDB, factoryOrder);

    if (response.status === 200) {
      yield delay(500);
      yield put(addFactoryOrderOkAction(response.data));
      toast.success("Factory Order Generated!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addFactoryOrderErrorAction(true));
    toast.error("Factory Order Genarate failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteFactoryOrder(action) {
  const id = action.payload;
  try {
    yield call(deleteFactoryOrderDB, id);
    yield deleteFactoryOrderOkAction();
    toast.success("Factory Order deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteFactoryOrderErrorAction());
    toast.error("Factory Order deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkFactoryOrderDelete(action) {
  const factoryOrder = action.factoryOrder;

  try {
    const response = yield call(bulkDeleteFactoryOrderDB, factoryOrder);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteFactoryOrderOkAction(factoryOrder));
      toast.success("Factory Orders deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteFactoryOrderErrorAction(true));
    toast.error("Factory Orders deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editFactoryOrder(action) {
  const factoryOrder = action.factoryOrder;

  try {
    const response = yield call(editFactoryOrderDB, factoryOrder);

    if (response.status === 201) {
      yield delay(500);
      yield put(editFactoryOrderOkAction(factoryOrder));
      toast.success("Factory Order edited successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editFactoryOrderErrorAction());
    toast.error("Factory Order deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchFactoryOrdersAsync() {
  yield takeLatest(BEGIN_FACTORY_ORDERS_DOWNLOAD, retrieveFactoryOrders);
  yield takeLatest(BEGIN_FACTORY_BY_ORDER_DOWNLOAD, retrieveFactoryByOrders);
  yield takeLatest(ADD_FACTORY_ORDERS, addFactoryOrder);
  yield takeLatest(RETRIEVE_FACTORY_ORDERS_DELETE, deleteFactoryOrder);
  yield takeLatest(BEGIN_EDIT_FACTORY_ORDERS, editFactoryOrder);
  yield takeLatest(RETRIEVE_FACTORY_ORDERS_BULK_DELETE, bulkFactoryOrderDelete);
}
