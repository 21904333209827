export const ADD_USER_ACCESS = "ADD_USER_ACCESS";
export const ADD_USER_ACCESS_OK = "ADD_USER_ACCESS_OK";
export const ADD_USER_ACCESS_ERROR = "ADD_USER_ACCESS_ERROR";

export const RETRIEVE_USER_ACCESS_BY_ID = "RETRIEVE_USER_ACCESS_BY_ID";
export const RETRIEVE_USER_ACCESS_BY_ID_OK = "RETRIEVE_USER_ACCESS_BY_ID_OK";
export const RETRIEVE_USER_ACCESS_BY_ID_ERROR =
  "RETRIEVE_USER_ACCESS_BY_ID_ERROR";

export const BEGIN_USER_ACCESS_DOWNLOAD = "BEGIN_USER_ACCESS_DOWNLOAD";
export const USER_ACCESS_DOWNLOAD_OK = "USER_ACCESS_DOWNLOAD_OK";
export const USER_ACCESS_DOWNLOAD_ERROR = "USER_ACCESS_DOWNLOAD_ERROR";

export const RETRIEVE_USER_ACCESS_EDIT = "RETRIEVE_USER_ACCESS_EDITED";
export const BEGIN_EDIT_USER_ACCESS = "BEGIN_EDIT_USER_ACCESS";
export const USER_ACCESS_EDITED_OK = "USER_ACCESS_EDITED_OK";
export const USER_ACCESS_EDITED_ERROR = "USER_ACCESS_EDITED_ERROR";

export const RETRIEVE_USER_ACCESS_DELETE = "RETRIEVE_USER_ACCESS_DELETE";
export const USER_ACCESS_DELETED_OK = "USER_ACCESS_DELETED_OK";
export const USER_ACCESS_DELETED_ERROR = "USER_ACCESS_DELETED_ERROR";

export const RETRIEVE_USER_ACCESS_BULK_DELETE =
  "RETRIEVE_USER_ACCESS_BULK_DELETE";
export const USER_ACCESS_BULK_DELETED_OK = "USER_ACCESS_BULK_DELETED_OK";
export const USER_ACCESS_BULK_DELETED_ERROR = "USER_ACCESS_BULK_DELETED_ERROR";
