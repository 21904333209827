import {
  ACCOUNTS_BULK_DELETED_ERROR,
  ACCOUNTS_BULK_DELETED_OK,
  ACCOUNTS_DELETED_ERROR,
  ACCOUNTS_DELETED_OK,
  ACCOUNTS_DOWNLOAD_ERROR,
  ACCOUNTS_DOWNLOAD_OK,
  ACCOUNTS_EDITED_ERROR,
  ACCOUNTS_EDITED_OK,
  ADD_ACCOUNTS,
  ADD_ACCOUNTS_ERROR,
  ADD_ACCOUNTS_OK,
  BEGIN_ACCOUNTS_DOWNLOAD,
  BEGIN_EDIT_ACCOUNTS,
  RETRIEVE_ACCOUNTS_BULK_DELETE,
  RETRIEVE_ACCOUNTS_DELETE,
  RETRIEVE_ACCOUNTS_EDIT
} from "../../constants/modules/accountsConstants";

export const bulkDeleteAccountAction = (item) => ({
  type: RETRIEVE_ACCOUNTS_BULK_DELETE,
  payload: true,
  account: item,
});

export const bulkDeleteAccountOkAction = () => ({
  type: ACCOUNTS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteAccountErrorAction = (state) => ({
  type: ACCOUNTS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadAccountsAction = () => ({
  type: BEGIN_ACCOUNTS_DOWNLOAD,
  payload: true,
});

export const downloadAccountsOkAction = (items) => ({
  type: ACCOUNTS_DOWNLOAD_OK,
  payload: items,
});

export const downloadAccountsErrorAction = () => ({
  type: ACCOUNTS_DOWNLOAD_ERROR,
  payload: true,
});

export const addAccountAction = (item) => ({
  type: ADD_ACCOUNTS,
  payload: true,
  account: item,
});

export const addAccountOkAction = () => ({
  type: ADD_ACCOUNTS_OK,
  payload: true,
});

export const addAccountErrorAction = (state) => ({
  type: ADD_ACCOUNTS_ERROR,
  payload: state,
});

export const deleteAccountAction = (id) => ({
  type: RETRIEVE_ACCOUNTS_DELETE,
  payload: id,
});

export const deleteAccountOkAction = () => ({
  type: ACCOUNTS_DELETED_OK,
});

export const deleteAccountErrorAction = () => ({
  type: ACCOUNTS_DELETED_ERROR,
  payload: true,
});

export const retrieveAccountAction = (item) => ({
  type: RETRIEVE_ACCOUNTS_EDIT,
  payload: item,
});

export const editAccountAction = (item) => ({
  type: BEGIN_EDIT_ACCOUNTS,
  account: item,
});

export const editAccountOkAction = (item) => ({
  type: ACCOUNTS_EDITED_OK,
  payload: item,
});

export const editAccountErrorAction = () => ({
  type: ACCOUNTS_EDITED_ERROR,
  payload: true,
});
