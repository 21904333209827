import {
  ADD_FACTORY_SHIPMENT_DETAILS,
  ADD_FACTORY_SHIPMENT_DETAILS_OK,
  ADD_FACTORY_SHIPMENT_DETAILS_ERROR,
  BEGIN_FACTORY_SHIPMENT_DETAILS_DOWNLOAD,
  FACTORY_SHIPMENT_DETAILS_DOWNLOAD_OK,
  FACTORY_SHIPMENT_DETAILS_DOWNLOAD_ERROR,
  RETRIEVE_FACTORY_SHIPMENT_DETAILS_DELETE,
  FACTORY_SHIPMENT_DETAILS_DELETED_OK,
  FACTORY_SHIPMENT_DETAILS_DELETED_ERROR,
  RETRIEVE_FACTORY_SHIPMENT_DETAILS_EDIT,
  BEGIN_EDIT_FACTORY_SHIPMENT_DETAILS,
  FACTORY_SHIPMENT_DETAILS_EDITED_OK,
  FACTORY_SHIPMENT_DETAILS_EDITED_ERROR,
  RETRIEVE_FACTORY_SHIPMENT_DETAILS_BULK_DELETE,
  FACTORY_SHIPMENT_DETAILS_BULK_DELETED_OK,
  FACTORY_SHIPMENT_DETAILS_BULK_DELETED_ERROR,
  BEGIN_SHIPMENT_DETAILS_BY_FOD_DOWNLOAD,
  SHIPMENT_DETAILS_BY_FOD_DOWNLOAD_OK,
  SHIPMENT_DETAILS_BY_FOD_DOWNLOAD_ERROR,
} from "../../constants/modules/factoryShipmentDetailsConstants";

export const downloadFactoryShipmentDetailsAction = () => ({
  type: BEGIN_FACTORY_SHIPMENT_DETAILS_DOWNLOAD,
  payload: true,
});

export const downloadFactoryShipmentDetailsOkAction = (items) => ({
  type: FACTORY_SHIPMENT_DETAILS_DOWNLOAD_OK,
  payload: items,
});

export const downloadFactoryShipmentDetailsErrorAction = () => ({
  type: FACTORY_SHIPMENT_DETAILS_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadFactoryShipmentDetailsByFodAction = (item) => ({
  type: BEGIN_SHIPMENT_DETAILS_BY_FOD_DOWNLOAD,
  payload: true,
  factoryShipmentDetail: item,
});

export const downloadFactoryShipmentDetailsByFodOkAction = (items) => ({
  type: SHIPMENT_DETAILS_BY_FOD_DOWNLOAD_OK,
  payload: items,
});

export const downloadFactoryShipmentDetailsByFodErrorAction = () => ({
  type: SHIPMENT_DETAILS_BY_FOD_DOWNLOAD_ERROR,
  payload: true,
});

export const addFactoryShipmentDetailAction = (item) => ({
  type: ADD_FACTORY_SHIPMENT_DETAILS,
  payload: true,
  factoryShipmentDetail: item,
});

export const addFactoryShipmentDetailOkAction = () => ({
  type: ADD_FACTORY_SHIPMENT_DETAILS_OK,
  payload: true,
});

export const addFactoryShipmentDetailErrorAction = (state) => ({
  type: ADD_FACTORY_SHIPMENT_DETAILS_ERROR,
  payload: state,
});

export const deleteFactoryShipmentDetailAction = (id) => ({
  type: RETRIEVE_FACTORY_SHIPMENT_DETAILS_DELETE,
  payload: id,
});

export const deleteFactoryShipmentDetailOkAction = () => ({
  type: FACTORY_SHIPMENT_DETAILS_DELETED_OK,
});

export const deleteFactoryShipmentDetailErrorAction = () => ({
  type: FACTORY_SHIPMENT_DETAILS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteFactoryShipmentDetailAction = (item) => ({
  type: RETRIEVE_FACTORY_SHIPMENT_DETAILS_BULK_DELETE,
  payload: true,
  factoryShipmentDetail: item,
});

export const bulkDeleteFactoryShipmentDetailOkAction = () => ({
  type: FACTORY_SHIPMENT_DETAILS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteFactoryShipmentDetailErrorAction = (state) => ({
  type: FACTORY_SHIPMENT_DETAILS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveFactoryShipmentDetailAction = (item) => ({
  type: RETRIEVE_FACTORY_SHIPMENT_DETAILS_EDIT,
  payload: item,
});

export const editFactoryShipmentDetailAction = (item) => ({
  type: BEGIN_EDIT_FACTORY_SHIPMENT_DETAILS,
  factoryShipmentDetail: item,
});

export const editFactoryShipmentDetailOkAction = (item) => ({
  type: FACTORY_SHIPMENT_DETAILS_EDITED_OK,
  payload: item,
});

export const editFactoryShipmentDetailErrorAction = () => ({
  type: FACTORY_SHIPMENT_DETAILS_EDITED_ERROR,
  payload: true,
});
