import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import { BEGIN_COMMISSION_DETAILS_DOWNLOAD } from "../../constants/modules/commissionDetailsConstants";

import {
  downloadHeaderOkAction,
  downloadHeaderErrorAction,
} from "../../actions/modules/commissionDetailsActions";

import {
  retrieveHeaderDB,
  retrieveDetailDB,
  addHeaderDB,
} from "../../../config/apiCalls/commissions";

export function* retrieveHeaders(action) {
  const commissionDetail = action.commissionDetail;
  try {
    const response = yield call(retrieveDetailDB, commissionDetail);
    if (response.status === 200) {
      yield put(downloadHeaderOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadHeaderErrorAction());
  }
}

export function* watchCommissionDetailsAsync() {
  yield takeLatest(BEGIN_COMMISSION_DETAILS_DOWNLOAD, retrieveHeaders);
}
