import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_TNA_HEADER,
  BEGIN_EDIT_TNA_HEADER,
  BEGIN_TNA_HEADER_DOWNLOAD,
  RETRIEVE_TNA_HEADER_BULK_DELETE,
  RETRIEVE_TNA_HEADER_DELETE,
  BEGIN_TNA_HEADER_BY_ID_DOWNLOAD,
  BEGIN_TNA_FACTORY_BY_ORDER_DOWNLOAD,
  BEGIN_TNA_STYLE_BY_FACTORY_DOWNLOAD,
  BEGIN_TNA_TEMPLATE_BY_BUYER_DOWNLOAD,
} from "../../constants/modules/tnaPlanHeaderConstants";

import {
  addTnaHeaderErrorAction,
  addTnaHeaderOkAction,
  bulkDeleteTnaHeaderErrorAction,
  bulkDeleteTnaHeaderOkAction,
  deleteTnaHeaderErrorAction,
  deleteTnaHeaderOkAction,
  downloadTnaHeadersErrorAction,
  downloadTnaHeadersOkAction,
  editTnaHeaderErrorAction,
  editTnaHeaderOkAction,
  downloadTnaHeaderByIdErrorAction,
  downloadTnaHeaderByIdOkAction,
  downloadTnaFactoryByOrderOkAction,
  downloadTnaFactoryByOrderErrorAction,
  downloadTnaStyleByFactoryOkAction,
  downloadTnaStyleByFactoryErrorAction,
  downloadTnaTemplateByBuyerOkAction,
  downloadTnaTemplateByBuyerErrorAction,
} from "../../actions/modules/tnaPlanHeaderActions";

import {
  addTnaPlanHeaderDB,
  bulkDeleteTnaPlanHeaderDB,
  deleteTnaPlanHeaderDB,
  editTnaPlanHeaderDB,
  retrieveTnaPlanHeadersData,
  retrieveTnaPlanHeaderByIdData,
  getFactoryDB,
  getStyleDB,
  getTemplateDB,
} from "../../../config/apiCalls/tnaPlan";

// Retrieve products
// worker saga
export function* retrieveTnaHeader(action) {
  const tnaHeader = action.tnaHeader;
  try {
    const response = yield call(retrieveTnaPlanHeadersData, tnaHeader);

    if (response.status === 200) {
      yield put(downloadTnaHeadersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTnaHeadersErrorAction());
  }
}

export function* retrieveTnaHeaderById(action) {
  const tnaHeader = action.tnaHeader;
  try {
    const response = yield call(retrieveTnaPlanHeaderByIdData, tnaHeader);

    if (response.status === 200) {
      yield put(downloadTnaHeaderByIdOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTnaHeaderByIdErrorAction());
  }
}

export function* retrieveFactory(action) {
  const tnaHeader = action.tnaHeader;
  try {
    const response = yield call(getFactoryDB, tnaHeader);

    if (response.status === 200) {
      yield put(downloadTnaFactoryByOrderOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTnaFactoryByOrderErrorAction());
  }
}

export function* retrieveStyle(action) {
  const tnaHeader = action.tnaHeader;
  try {
    const response = yield call(getStyleDB, tnaHeader);

    if (response.status === 200) {
      yield put(downloadTnaStyleByFactoryOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTnaStyleByFactoryErrorAction());
  }
}

export function* retrieveTemplate(action) {
  const tnaHeader = action.tnaHeader;
  try {
    const response = yield call(getTemplateDB, tnaHeader);

    if (response.status === 200) {
      yield put(downloadTnaTemplateByBuyerOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadTnaTemplateByBuyerErrorAction());
  }
}

// Create new product
// worker saga
export function* addTnaHeader(action) {
  const tnaHeader = action.tnaHeader;

  try {
    const response = yield call(addTnaPlanHeaderDB, tnaHeader);
    if (response.status === 200) {
      yield delay(500);
      yield put(addTnaHeaderOkAction(response.data));
      toast.success("Tna Plan created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addTnaHeaderErrorAction(true));
    toast.error("TnaHeader created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteTnaHeader(action) {
  const id = action.payload;
  try {
    yield call(deleteTnaPlanHeaderDB, id);
    yield deleteTnaHeaderOkAction();
    toast.success("TnaHeader deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteTnaHeaderErrorAction();
  }
}

// Edit product
// worker saga
export function* editTnaHeader(action) {
  const tnaHeader = action.tnaHeader;

  try {
    yield call(editTnaPlanHeaderDB, tnaHeader);
    yield editTnaHeaderOkAction(tnaHeader);
    // Alert
    // Alert
    toast.success("Tna-Action has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editTnaHeaderErrorAction();
  }
}

// BULK SAGAS
export function* bulkTnaHeaderDelete(action) {
  const tnaHeader = action.tnaHeader;

  try {
    const response = yield call(bulkDeleteTnaPlanHeaderDB, tnaHeader);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteTnaHeaderOkAction(tnaHeader));
      toast.success("Tna Actions deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteTnaHeaderErrorAction(true));
    toast.error("Tna Actions deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchTnaPlanHeaderAsync() {
  yield takeLatest(BEGIN_TNA_FACTORY_BY_ORDER_DOWNLOAD, retrieveFactory);
  yield takeLatest(BEGIN_TNA_STYLE_BY_FACTORY_DOWNLOAD, retrieveStyle);
  yield takeLatest(BEGIN_TNA_TEMPLATE_BY_BUYER_DOWNLOAD, retrieveTemplate);
  yield takeLatest(BEGIN_TNA_HEADER_DOWNLOAD, retrieveTnaHeader);
  yield takeLatest(BEGIN_TNA_HEADER_BY_ID_DOWNLOAD, retrieveTnaHeaderById);
  yield takeLatest(ADD_TNA_HEADER, addTnaHeader);
  yield takeLatest(RETRIEVE_TNA_HEADER_DELETE, deleteTnaHeader);
  yield takeLatest(BEGIN_EDIT_TNA_HEADER, editTnaHeader);
  yield takeLatest(RETRIEVE_TNA_HEADER_BULK_DELETE, bulkTnaHeaderDelete);
}
