export const ADD_APPROVAL_FORM_SETUPS = "ADD_APPROVAL_FORM_SETUPS";
export const ADD_APPROVAL_FORM_SETUPS_OK = "ADD_APPROVAL_FORM_SETUPS_OK";
export const ADD_APPROVAL_FORM_SETUPS_ERROR = "ADD_APPROVAL_FORM_SETUPS_ERROR";

export const BEGIN_APPROVAL_FORM_SETUPS_DOWNLOAD = "BEGIN_APPROVAL_FORM_SETUPS_DOWNLOAD";
export const APPROVAL_FORM_SETUPS_DOWNLOAD_OK = "APPROVAL_FORM_SETUPS_DOWNLOAD_OK";
export const APPROVAL_FORM_SETUPS_DOWNLOAD_ERROR = "APPROVAL_FORM_SETUPS_DOWNLOAD_ERROR";

export const RETRIEVE_APPROVAL_FORM_SETUPS_DELETE = "RETRIEVE_APPROVAL_FORM_SETUPS_DELETE";
export const APPROVAL_FORM_SETUPS_DELETED_OK = "APPROVAL_FORM_SETUPS_DELETED_OK";
export const APPROVAL_FORM_SETUPS_DELETED_ERROR = "APPROVAL_FORM_SETUPS_DELETED_ERROR";

export const RETRIEVE_APPROVAL_FORM_SETUPS_EDIT = "RETRIEVE_APPROVAL_FORM_SETUPS_EDITED";
export const BEGIN_EDIT_APPROVAL_FORM_SETUPS = "BEGIN_EDIT_APPROVAL_FORM_SETUPS";
export const APPROVAL_FORM_SETUPS_EDITED_OK = "APPROVAL_FORM_SETUPS_EDITED_OK";
export const APPROVAL_FORM_SETUPS_EDITED_ERROR = "APPROVAL_FORM_SETUPS_EDITED_ERROR";
