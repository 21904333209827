import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*         APPROVAL PANEL MEMBERS API CALLS        */
/*-------------------------------------------------*/

export async function bulkDeleteApprovalPanelMemberDB(ids) {
    return await axiosClient.post(`/approval_panel_members/bulk_delete`, ids);
  }
  
  export async function retrieveApprovalPanelMembersData() {
    return await axiosClient.get("/approval_panel_members");
  }
  
  export async function addApprovalPanelMemberDB(value) {
    return await axiosClient.post("/approval_panel_members", value);
  }
  
  export async function deleteApprovalPanelMemberDB(id) {
    return await axiosClient.delete(`/approval_panel_members/${id}`);
  }
  
  export async function editApprovalPanelMemberDB(value) {
    return await axiosClient.put(`/approval_panel_members/${value.id}`, value);
  }