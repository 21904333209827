import {
    ADD_FOB_TYPES, ADD_FOB_TYPES_ERROR, ADD_FOB_TYPES_OK, BEGIN_EDIT_FOB_TYPES, BEGIN_FOB_TYPES_DOWNLOAD, FOB_TYPES_BULK_DELETED_ERROR, FOB_TYPES_BULK_DELETED_OK, FOB_TYPES_DELETED_ERROR, FOB_TYPES_DELETED_OK, FOB_TYPES_DOWNLOAD_ERROR, FOB_TYPES_DOWNLOAD_OK, FOB_TYPES_EDITED_ERROR, FOB_TYPES_EDITED_OK, RETRIEVE_FOB_TYPES_BULK_DELETE, RETRIEVE_FOB_TYPES_DELETE, RETRIEVE_FOB_TYPES_EDIT
} from "../../constants/modules/fobTypesConstants";
    
  /*-----------------------------------*/
  /*             BULK ACTIONS          */
  /*-----------------------------------*/
  export const bulkDeleteFobTypeAction = (item) => ({
    type: RETRIEVE_FOB_TYPES_BULK_DELETE,
    payload: true,
    fobType: item,
  });
  
  export const bulkDeleteFobTypeOkAction = () => ({
    type: FOB_TYPES_BULK_DELETED_OK,
    payload: true,
  });
  
  export const bulkDeleteFobTypeErrorAction = (state) => ({
    type: FOB_TYPES_BULK_DELETED_ERROR,
    payload: state,
  });
    
    export const downloadFobTypesAction = () => ({
      type: BEGIN_FOB_TYPES_DOWNLOAD,
      payload: true,
    });
    
    export const downloadFobTypesOkAction = (items) => ({
      type: FOB_TYPES_DOWNLOAD_OK,
      payload: items,
    });
    
    export const downloadFobTypesErrorAction = () => ({
      type: FOB_TYPES_DOWNLOAD_ERROR,
      payload: true,
    });
    
    
    export const addFobTypeAction = (item) => ({
      type: ADD_FOB_TYPES,
      payload: true,
      fobType: item,
    });
    
    export const addFobTypeOkAction = () => ({
      type: ADD_FOB_TYPES_OK,
      payload: true,
    });
    
    export const addFobTypeErrorAction = (state) => ({
      type: ADD_FOB_TYPES_ERROR,
      payload: state,
    });
    
    
    export const deleteFobTypeAction = (id) => ({
      type: RETRIEVE_FOB_TYPES_DELETE,
      payload: id,
    });
    
    export const deleteFobTypeOkAction = () => ({
      type: FOB_TYPES_DELETED_OK,
    });
    
    export const deleteFobTypeErrorAction = () => ({
      type: FOB_TYPES_DELETED_ERROR,
      payload: true,
    });
    
    
    export const retrieveFobTypeAction = (item) => ({
      type: RETRIEVE_FOB_TYPES_EDIT,
      payload: item,
    });
    
    export const editFobTypeAction = (item) => ({
      type: BEGIN_EDIT_FOB_TYPES,
      fobType: item,
    });
    
    export const editFobTypeOkAction = (item) => ({
      type: FOB_TYPES_EDITED_OK,
      payload: item,
    });
    
    export const editFobTypeErrorAction = () => ({
      type: FOB_TYPES_EDITED_ERROR,
      payload: true,
    });
    
    