import {
    ADD_DISCOUNT_DETAILS,
    ADD_DISCOUNT_DETAILS_ERROR,
    ADD_DISCOUNT_DETAILS_OK,
    BEGIN_EDIT_DISCOUNT_DETAILS,
    RETRIEVE_DISCOUNT_DETAILS_DELETE,
    RETRIEVE_DISCOUNT_DETAILS_EDIT,
    DISCOUNT_DETAILS_DELETED_ERROR,
    DISCOUNT_DETAILS_DELETED_OK,
    DISCOUNT_DETAILS_DOWNLOAD_ERROR,
    DISCOUNT_DETAILS_DOWNLOAD_OK,
    DISCOUNT_DETAILS_EDITED_ERROR,
    DISCOUNT_DETAILS_EDITED_OK,
    BEGIN_DISCOUNT_DETAILS_DOWNLOAD
  } from "../../constants/modules/discountDetailsConstants";
  
  const initialState = {
    discountDetails: [],
    error: false,
    loading: false,
    deleteDiscountDetail: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_DISCOUNT_DETAILS_DOWNLOAD:
      case ADD_DISCOUNT_DETAILS:
      case BEGIN_EDIT_DISCOUNT_DETAILS:
        return {
          ...state,
          loading: action.payload,
          discountDetail: action.discountDetail,
        };
  
      case ADD_DISCOUNT_DETAILS_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_DISCOUNT_DETAILS_ERROR:
      case DISCOUNT_DETAILS_DOWNLOAD_ERROR:
      case DISCOUNT_DETAILS_DELETED_ERROR:
      case DISCOUNT_DETAILS_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case DISCOUNT_DETAILS_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          discountDetails: action.payload,
        };
  
      case RETRIEVE_DISCOUNT_DETAILS_DELETE:
        return {
          ...state,
          deleteDiscountDetail: action.payload,
        };
  
      case DISCOUNT_DETAILS_DELETED_OK:
        return {
          ...state,
          discountDetails: state.discountDetails.data.filter(
            (discountDetail) =>
              discountDetail.id !== state.deleteDiscountDetail
          ),
          deleteDiscountDetail: null,
        };
  
      case RETRIEVE_DISCOUNT_DETAILS_EDIT:
        return {
          ...state,
          editDiscountDetail: action.payload,
        };
  
      case DISCOUNT_DETAILS_EDITED_OK:
        return {
          ...state,
          editDiscountDetail: null,
          discountDetails: state.discountDetails.data.map(
            (discountDetail) =>
              discountDetail.id === action.payload.id
                ? (discountDetail = action.payload)
                : discountDetail
          ),
        };
  
      default:
        return state;
    }
  }
  