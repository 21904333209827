import {
  ADD_MEASURING_UNITS, ADD_MEASURING_UNITS_ERROR, ADD_MEASURING_UNITS_OK,
  BEGIN_EDIT_MEASURING_UNITS,
  BEGIN_MEASURING_UNITS_DOWNLOAD,
  MEASURING_UNITS_BULK_DELETED_ERROR, MEASURING_UNITS_BULK_DELETED_OK, MEASURING_UNITS_DELETED_ERROR, MEASURING_UNITS_DELETED_OK, MEASURING_UNITS_DOWNLOAD_ERROR, MEASURING_UNITS_DOWNLOAD_OK, MEASURING_UNITS_EDITED_ERROR, MEASURING_UNITS_EDITED_OK, RETRIEVE_MEASURING_UNITS_BULK_DELETE, RETRIEVE_MEASURING_UNITS_DELETE, RETRIEVE_MEASURING_UNITS_EDIT
} from "../../constants/modules/measuringUnitsConstant";
    
  /*-----------------------------------*/
  /*             BULK ACTIONS          */
  /*-----------------------------------*/
  export const bulkDeleteMeasuringUnitAction = (item) => ({
    type: RETRIEVE_MEASURING_UNITS_BULK_DELETE,
    payload: true,
    measuringUnit: item,
  });
  
  export const bulkDeleteMeasuringUnitOkAction = () => ({
    type: MEASURING_UNITS_BULK_DELETED_OK,
    payload: true,
  });
  
  export const bulkDeleteMeasuringUnitErrorAction = (state) => ({
    type: MEASURING_UNITS_BULK_DELETED_ERROR,
    payload: state,
  });
    
    export const downloadMeasuringUnitsAction = () => ({
      type: BEGIN_MEASURING_UNITS_DOWNLOAD,
      payload: true,
    });
    
    export const downloadMeasuringUnitsOkAction = (items) => ({
      type: MEASURING_UNITS_DOWNLOAD_OK,
      payload: items,
    });
    
    export const downloadMeasuringUnitsErrorAction = () => ({
      type: MEASURING_UNITS_DOWNLOAD_ERROR,
      payload: true,
    });
    
    
    export const addMeasuringUnitAction = (item) => ({
      type: ADD_MEASURING_UNITS,
      payload: true,
      measuringUnit: item,
    });
    
    export const addMeasuringUnitOkAction = () => ({
      type: ADD_MEASURING_UNITS_OK,
      payload: true,
    });
    
    export const addMeasuringUnitErrorAction = (state) => ({
      type: ADD_MEASURING_UNITS_ERROR,
      payload: state,
    });
    
    
    export const deleteMeasuringUnitAction = (id) => ({
      type: RETRIEVE_MEASURING_UNITS_DELETE,
      payload: id,
    });
    
    export const deleteMeasuringUnitOkAction = () => ({
      type: MEASURING_UNITS_DELETED_OK,
    });
    
    export const deleteMeasuringUnitErrorAction = () => ({
      type: MEASURING_UNITS_DELETED_ERROR,
      payload: true,
    });
    
    
    export const retrieveMeasuringUnitAction = (item) => ({
      type: RETRIEVE_MEASURING_UNITS_EDIT,
      payload: item,
    });
    
    export const editMeasuringUnitAction = (item) => ({
      type: BEGIN_EDIT_MEASURING_UNITS,
      measuringUnit: item,
    });
    
    export const editMeasuringUnitOkAction = (item) => ({
      type: MEASURING_UNITS_EDITED_OK,
      payload: item,
    });
    
    export const editMeasuringUnitErrorAction = () => ({
      type: MEASURING_UNITS_EDITED_ERROR,
      payload: true,
    });
    
    