import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    ADD_APPROVAL_PANELS,
    BEGIN_APPROVAL_PANELS_DOWNLOAD,
    BEGIN_EDIT_APPROVAL_PANELS,
    RETRIEVE_APPROVAL_PANELS_BULK_DELETE,
    RETRIEVE_APPROVAL_PANELS_DELETE
} from "../../constants/modules/approvalPanelsConstants";

import {
    addApprovalPanelErrorAction,
    addApprovalPanelOkAction,
    bulkDeleteApprovalPanelErrorAction,
    bulkDeleteApprovalPanelOkAction,
    deleteApprovalPanelErrorAction,
    deleteApprovalPanelOkAction,
    downloadApprovalPanelsErrorAction,
    downloadApprovalPanelsOkAction,
    editApprovalPanelErrorAction,
    editApprovalPanelOkAction
} from "../../actions/modules/approvalPanelsActions";

import {
    addApprovalPanelDB,
    bulkDeleteApprovalPanelDB,
    deleteApprovalPanelDB,
    editApprovalPanelDB,
    retrieveApprovalPanelsData
} from "../../../config/apiCalls/approvalPanelsAPICall";

// Retrieve products
// worker saga
export function* retrieveApprovalPanels() {
  try {
    const response = yield call(retrieveApprovalPanelsData);

    if (response.status === 200) {
      yield put(downloadApprovalPanelsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadApprovalPanelsErrorAction());
  }
}

// Create new product
// worker saga
export function* addApprovalPanel(action) {
  const approvalPanel = action.approvalPanel;

  try {
    const response = yield call(addApprovalPanelDB, approvalPanel);

    if (response.status === 201) {
      yield delay(500);

      yield put(addApprovalPanelOkAction(approvalPanel));
      toast.success("ApprovalPanel created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addApprovalPanelErrorAction(true));
    toast.error("ApprovalPanel created failed!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteApprovalPanel(action) {
  const id = action.payload;
  try {
    yield call(deleteApprovalPanelDB, id);
    yield deleteApprovalPanelOkAction();
    toast.success("ApprovalPanel deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteApprovalPanelErrorAction();
  }
}

// Edit product
// worker saga
export function* editApprovalPanel(action) {
  const approvalPanel = action.approvalPanel;

  try {
    yield call(editApprovalPanelDB, approvalPanel);
    yield editApprovalPanelOkAction(approvalPanel);
    // Alert
    // Alert
    toast.success("ApprovalPanel has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editApprovalPanelErrorAction();
  }
}

// BULK SAGAS
export function* bulkApprovalPanelDelete(action) {
  const approvalPanel = action.approvalPanel;

  try {
    const response = yield call(bulkDeleteApprovalPanelDB, approvalPanel);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteApprovalPanelOkAction(approvalPanel));
      toast.success("Bulk ApprovalPanel deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteApprovalPanelErrorAction(true));
    toast.error("Bulk ApprovalPanel deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchApprovalPanelsAsync() {
  yield takeLatest(BEGIN_APPROVAL_PANELS_DOWNLOAD, retrieveApprovalPanels);
  yield takeLatest(ADD_APPROVAL_PANELS, addApprovalPanel);
  yield takeLatest(RETRIEVE_APPROVAL_PANELS_DELETE, deleteApprovalPanel);
  yield takeLatest(BEGIN_EDIT_APPROVAL_PANELS, editApprovalPanel);
  yield takeLatest(
    RETRIEVE_APPROVAL_PANELS_BULK_DELETE,
    bulkApprovalPanelDelete
  );
}
