export const ADD_COURIERS = "ADD_COURIERS";
export const ADD_COURIERS_OK = "ADD_COURIERS_OK";
export const ADD_COURIERS_ERROR = "ADD_COURIERS_ERROR";

export const BEGIN_COURIERS_DOWNLOAD = "BEGIN_COURIERS_DOWNLOAD";
export const COURIERS_DOWNLOAD_OK = "COURIERS_DOWNLOAD_OK";
export const COURIERS_DOWNLOAD_ERROR = "COURIERS_DOWNLOAD_ERROR";

export const RETRIEVE_COURIERS_DELETE = "RETRIEVE_COURIERS_DELETE";
export const COURIERS_DELETED_OK = "COURIERS_DELETED_OK";
export const COURIERS_DELETED_ERROR = "COURIERS_DELETED_ERROR";

export const RETRIEVE_COURIERS_EDIT = "RETRIEVE_COURIERS_EDITED";
export const BEGIN_EDIT_COURIERS = "BEGIN_EDIT_COURIERS";
export const COURIERS_EDITED_OK = "COURIERS_EDITED_OK";
export const COURIERS_EDITED_ERROR = "COURIERS_EDITED_ERROR";

export const RETRIEVE_COURIERS_BULK_DELETE = "RETRIEVE_COURIERS_BULK_DELETE";
export const COURIERS_BULK_DELETED_OK = "COURIERS_BULK_DELETED_OK";
export const COURIERS_BULK_DELETED_ERROR = "COURIERS_BULK_DELETED_ERROR";