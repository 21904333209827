import {
  ADD_BANK_ACCOUNTS,
  ADD_BANK_ACCOUNTS_ERROR,
  ADD_BANK_ACCOUNTS_OK,
  BANK_ACCOUNTS_BULK_DELETED_ERROR,
  BANK_ACCOUNTS_BULK_DELETED_OK,
  BANK_ACCOUNTS_DELETED_ERROR,
  BANK_ACCOUNTS_DELETED_OK,
  BANK_ACCOUNTS_DOWNLOAD_ERROR,
  BANK_ACCOUNTS_DOWNLOAD_OK,
  BANK_ACCOUNTS_EDITED_ERROR,
  BANK_ACCOUNTS_EDITED_OK,
  BEGIN_BANK_ACCOUNTS_DOWNLOAD,
  BEGIN_EDIT_BANK_ACCOUNTS,
  RETRIEVE_BANK_ACCOUNTS_BULK_DELETE,
  RETRIEVE_BANK_ACCOUNTS_DELETE,
  RETRIEVE_BANK_ACCOUNTS_EDIT,
} from "../../constants/modules/bankAccountsConstants";

const initialState = {
  bankAccounts: [],
  error: false,
  loading: false,
  deleteBankAccount: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_BANK_ACCOUNTS_DOWNLOAD:
    case ADD_BANK_ACCOUNTS:
    case RETRIEVE_BANK_ACCOUNTS_BULK_DELETE:
    case BEGIN_EDIT_BANK_ACCOUNTS:
      return {
        ...state,
        loading: action.payload,
        bankAccount: action.bankAccount,
      };

    case ADD_BANK_ACCOUNTS_OK:
    case BANK_ACCOUNTS_BULK_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_BANK_ACCOUNTS_ERROR:
    case BANK_ACCOUNTS_DOWNLOAD_ERROR:
    case BANK_ACCOUNTS_BULK_DELETED_ERROR:
    case BANK_ACCOUNTS_DELETED_ERROR:
    case BANK_ACCOUNTS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BANK_ACCOUNTS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        bankAccounts: action.payload,
      };

    case RETRIEVE_BANK_ACCOUNTS_DELETE:
      return {
        ...state,
        deleteBankAccount: action.payload,
      };

    case BANK_ACCOUNTS_DELETED_OK:
      return {
        ...state,
        bankAccounts: state.bankAccounts.data.filter(
          (bankAccount) => bankAccount.id !== state.deleteBankAccount
        ),
        deleteBankAccount: null,
      };

    case RETRIEVE_BANK_ACCOUNTS_EDIT:
      return {
        ...state,
        editBankAccount: action.payload,
      };

    case BANK_ACCOUNTS_EDITED_OK:
      return {
        editBankAccount: null,
        bankAccounts: state.bankAccounts.data.map((bankAccount) =>
          bankAccount.id === action.payload.id
            ? (bankAccount = action.payload)
            : bankAccount
        ),
      };

    default:
      return state;
  }
}
