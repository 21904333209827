import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_USER_LEVEL_PRIVILEGES,
  BEGIN_EDIT_USER_LEVEL_PRIVILEGES,
  BEGIN_USER_LEVEL_PRIVILEGES_DOWNLOAD,
  RETRIEVE_USER_LEVEL_PRIVILEGES_BULK_DELETE,
  RETRIEVE_USER_LEVEL_PRIVILEGES_DELETE
} from "../../constants/modules/userLevelPrivilegesConstant";

import {
  addUserLevelPrivilegeErrorAction,
  addUserLevelPrivilegeOkAction,
  bulkDeleteUserLevelPrivilegeErrorAction,
  bulkDeleteUserLevelPrivilegeOkAction,
  deleteUserLevelPrivilegeErrorAction,
  deleteUserLevelPrivilegeOkAction,
  downloadUserLevelPrivilegesErrorAction,
  downloadUserLevelPrivilegesOkAction,
  editUserLevelPrivilegeErrorAction,
  editUserLevelPrivilegeOkAction
} from "../../actions/modules/userLevelPrivilegesAction";

import {
  addUserLevelPrivilegeDB,
  bulkDeleteUserLevelPrivilegeDB,
  deleteUserLevelPrivilegeDB,
  editUserLevelPrivilegeDB,
  retrieveUserLevelPrivilegesData
} from "../../../config/apiCalls/userLevePrivilegessAPICall";

// Retrieve products
// worker saga
export function* retrieveUserLevelPrivileges() {
  try {
    const response = yield call(retrieveUserLevelPrivilegesData);
    if (response.status === 200) {
      yield put(downloadUserLevelPrivilegesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadUserLevelPrivilegesErrorAction());
  }
}

// Create new product
// worker saga
export function* addUserLevelPrivilege(action) {
  const userLevelPrivilege = action.userLevelPrivilege;

  try {
    const response = yield call(addUserLevelPrivilegeDB, userLevelPrivilege);

    if (response.status === 200) {
      yield delay(500);

      yield put(addUserLevelPrivilegeOkAction(userLevelPrivilege));
      toast.success("User Level Privilege created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addUserLevelPrivilegeErrorAction(true));
    toast.error("User Level Privilege created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteUserLevelPrivilege(action) {
  const id = action.payload;
  try {
    yield call(deleteUserLevelPrivilegeDB, id);
    yield deleteUserLevelPrivilegeOkAction();
    toast.success("User Level Privilege deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteUserLevelPrivilegeErrorAction();
  }
}

// Edit product
// worker saga
export function* editUserLevelPrivilege(action) {
  const userLevelPrivilege = action.userLevelPrivilege;

  try {
    yield call(editUserLevelPrivilegeDB, userLevelPrivilege);
    yield editUserLevelPrivilegeOkAction(userLevelPrivilege);
    // Alert
    // Alert
    toast.success("User Level Privilege has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editUserLevelPrivilegeErrorAction();
  }
}

// BULK SAGAS
export function* bulkUserLevelPrivilegeDelete(action) {
  const userLevelPrivilege = action.userLevelPrivilege;

  try {
    const response = yield call(bulkDeleteUserLevelPrivilegeDB, userLevelPrivilege);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteUserLevelPrivilegeOkAction(userLevelPrivilege));
      toast.success("Bulk User Level Privilege deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteUserLevelPrivilegeErrorAction(true));
    toast.error("Bulk User Level Privilege deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchUserLevelPrivilegesAsync() {
  yield takeLatest(BEGIN_USER_LEVEL_PRIVILEGES_DOWNLOAD, retrieveUserLevelPrivileges);
  yield takeLatest(ADD_USER_LEVEL_PRIVILEGES, addUserLevelPrivilege);
  yield takeLatest(RETRIEVE_USER_LEVEL_PRIVILEGES_DELETE, deleteUserLevelPrivilege);
  yield takeLatest(BEGIN_EDIT_USER_LEVEL_PRIVILEGES, editUserLevelPrivilege);
  yield takeLatest(RETRIEVE_USER_LEVEL_PRIVILEGES_BULK_DELETE, bulkUserLevelPrivilegeDelete);
}
