import {
  BEGIN_SALES_CONTRACTS_AMEND_DOWNLOAD,
  SALES_CONTRACTS_AMEND_DOWNLOAD_OK,
  SALES_CONTRACTS_AMEND_DOWNLOAD_ERROR,
} from "../../constants/modules/SalesContractAmendConstants";

export const downloadSalesContractAmendsAction = () => ({
  type: BEGIN_SALES_CONTRACTS_AMEND_DOWNLOAD,
  payload: true,
});

export const downloadSalesContractAmendsOkAction = (items) => ({
  type: SALES_CONTRACTS_AMEND_DOWNLOAD_OK,
  payload: items,
});

export const downloadSalesContractAmendsErrorAction = () => ({
  type: SALES_CONTRACTS_AMEND_DOWNLOAD_ERROR,
  payload: true,
});
