import {
  put,
  takeEvery,
  takeLatest,
  all,
  delay,
  call,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  ADD_USER_ROLE,
  BEGIN_USER_ROLE_DOWNLOAD,
  RETRIEVE_USER_ROLE_DELETE,
  BEGIN_EDIT_USER_ROLE,
  RETRIEVE_USER_ROLE_BULK_DELETE,
} from "../../constants/modules/userRolesConstants";

import {
  downloadUserRolesOkAction,
  downloadUserRolesErrorAction,
  addUserRoleOkAction,
  addUserRoleErrorAction,
  deleteUserRoleOkAction,
  deleteUserRoleErrorAction,
  editUserRoleOkAction,
  editUserRoleErrorAction,
  bulkDeleteUserRoleOkAction,
  bulkDeleteUserRoleErrorAction,
} from "../../actions/modules/userRolesActions";

import {
  retrieveUserRolesData,
  addUserRoleDB,
  deleteUserRoleDB,
  editUserRoleDB,
  bulkDeleteUserRoleDB,
} from "../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveUserRoles() {
  try {
    const response = yield call(retrieveUserRolesData);
    if (response.status === 200) {
      yield put(downloadUserRolesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadUserRolesErrorAction());
  }
}

// Create new product
// worker saga
export function* addUserRole(action) {
  const userRole = action.userRole;

  try {
    const response = yield call(addUserRoleDB, userRole);
    if (response.status === 201) {
      yield delay(500);
      yield put(addUserRoleOkAction(userRole));
      toast.success("User Role created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addUserRoleErrorAction(true));
    toast.error("UserRole created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteUserRole(action) {
  const id = action.payload;
  try {
    yield call(deleteUserRoleDB, id);
    yield deleteUserRoleOkAction();
    toast.success("User Role deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteUserRoleErrorAction());
    toast.error("User Role deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkUserRoleDelete(action) {
  const userRole = action.userRole;

  try {
    const response = yield call(bulkDeleteUserRoleDB, userRole);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteUserRoleOkAction(userRole));
      toast.success("User Roles deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteUserRoleErrorAction(true));
    toast.error("User Roles deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editUserRole(action) {
  const userRole = action.userRole;

  try {
    const response = yield call(editUserRoleDB, userRole);

    if (response.status === 201) {
      yield delay(500);
      yield put(editUserRoleOkAction(userRole));
      toast.success("User Role edited successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editUserRoleErrorAction());
    toast.error("User Role edited failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchUserRolesAsync() {
  yield takeLatest(BEGIN_USER_ROLE_DOWNLOAD, retrieveUserRoles);
  yield takeLatest(ADD_USER_ROLE, addUserRole);
  yield takeLatest(RETRIEVE_USER_ROLE_DELETE, deleteUserRole);
  yield takeLatest(BEGIN_EDIT_USER_ROLE, editUserRole);
  yield takeLatest(RETRIEVE_USER_ROLE_BULK_DELETE, bulkUserRoleDelete);
}
