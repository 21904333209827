import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*              SAMPLE ORDER API CALLS             */
/*-------------------------------------------------*/

export async function retrieveSampleOrdersData(value) {
  let u_id = { p_id: value?.id };

  return await axiosClient.post("/sample_orders/get_all", u_id);
}

export async function addSampleOrderDB(value) {
  return await axiosClient.post("/sample_orders", value);
}

export async function deleteSampleOrderDB(id) {
  return await axiosClient.delete(`/sample_orders/${id}`);
}

export async function bulkDeleteSampleOrderDB(ids) {
  return await axiosClient.post(`/sample_orders/bulk_delete`, ids);
}

export async function editSampleOrderDB(value) {
  return await axiosClient.put(`/sample_orders/${value.id}`, value);
}
