import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_SIZE_SETS,
  BEGIN_SIZE_SETS_DOWNLOAD,
  BEGIN_SIZE_SETS_BY_FK_DOWNLOAD,
  BEGIN_EDIT_SIZE_SETS,
  RETRIEVE_SIZE_SETS_DELETE,
  GET_SIZE_SET_DETAIL,
} from "../../constants/modules/sizeSetsConstants";

import {
  addSizeSetErrorAction,
  addSizeSetOkAction,
  deleteSizeSetErrorAction,
  deleteSizeSetOkAction,
  downloadSizeSetsErrorAction,
  downloadSizeSetsOkAction,
  editSizeSetErrorAction,
  editSizeSetOkAction,
  getSizeSetDetailOkAction,
  getSizeSetDetailErrorAction,
  downloadSizeSetsByFKOkAction,
  downloadSizeSetsByFKErrorAction,
} from "../../actions/modules/sizeSetsActions";

import {
  addSizeSetDB,
  deleteSizeSetDB,
  editSizeSetDB,
  retrieveSizeSetDB,
  sizeSetDetailDB,
  sizeSetByFkDB,
} from "../../../config/apiCalls/sizeSets";

// Retrieve products
// worker saga
export function* retrieveSizeSets() {
  try {
    const response = yield call(retrieveSizeSetDB);
    console.log("retrieveColors");
    if (response.status === 200) {
      yield put(downloadSizeSetsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSizeSetsErrorAction());
  }
}

export function* retrieveSizeSetsByFk(action) {
  const sizeSet = action.sizeSet;
  try {
    const response = yield call(sizeSetByFkDB, sizeSet);
    if (response.status === 200) {
      yield put(downloadSizeSetsByFKOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadSizeSetsByFKErrorAction());
  }
}

export function* getSizeSetDetail(action) {
  const sizeSet = action.sizeSet;

  console.log("value******", sizeSet);

  try {
    const response = yield call(sizeSetDetailDB, sizeSet);
    console.log("sizeSet8888", response);
    if (response.status === 200) {
      yield put(getSizeSetDetailOkAction(response.data));
    }
  } catch (error) {
    yield put(getSizeSetDetailErrorAction());
  }
}

// Create new product
// worker saga
export function* addSizeSet(action) {
  const sizeSet = action.sizeSet;

  try {
    const response = yield call(addSizeSetDB, sizeSet);

    if (response.status === 201) {
      yield delay(500);

      yield put(addSizeSetOkAction(sizeSet));
      toast.success("Size Set created successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addSizeSetErrorAction(true));
    toast.error("Size Set created failed!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteSizeSet(action) {
  const id = action.payload;
  try {
    yield call(deleteSizeSetDB, id);
    yield deleteSizeSetOkAction();
    toast.success("Size Set deleted successfully!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteSizeSetErrorAction();
  }
}

// Edit product
// worker saga
export function* editSizeSet(action) {
  const sizeSet = action.sizeSet;

  try {
    yield call(editSizeSetDB, sizeSet);
    yield editSizeSetOkAction(sizeSet);

    toast.success("Size Set has been updated!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editSizeSetErrorAction();
  }
}

export function* watchSizeSetsAsync() {
  yield takeLatest(BEGIN_SIZE_SETS_DOWNLOAD, retrieveSizeSets);
  yield takeLatest(BEGIN_SIZE_SETS_BY_FK_DOWNLOAD, retrieveSizeSetsByFk);
  yield takeLatest(GET_SIZE_SET_DETAIL, getSizeSetDetail);
  yield takeLatest(ADD_SIZE_SETS, addSizeSet);
  yield takeLatest(RETRIEVE_SIZE_SETS_DELETE, deleteSizeSet);
  yield takeLatest(BEGIN_EDIT_SIZE_SETS, editSizeSet);
}
