import axiosClient from "../axiosClient";
/*-------------------------------------------------*/
/*                ACCOUNTS API CALLS               */
/*-------------------------------------------------*/

export async function retrieveAccountsData() {
  return await axiosClient.get("/accounts");
}

export async function addAccountDB(value) {
  return await axiosClient.post("/accounts", value);
}

export async function deleteAccountDB(id) {
  return await axiosClient.delete(`/accounts/${id}`);
}

export async function bulkDeleteAccountDB(ids) {
  return await axiosClient.post(`/accounts/bulk_delete`, ids);
}

export async function editAccountDB(value) {
  return await axiosClient.put(`/accounts/${value.id}`, value);
}
