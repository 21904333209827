import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*                 BUYERS API CALLS                */
/*-------------------------------------------------*/


export async function retrieveBuyerDB() {
    return await axiosClient.get("/buyers");
  }
  
  export async function addBuyerDB(value) {
    return await axiosClient.post("/buyers", value);
  }
  
  export async function deleteBuyerDB(id) {
    return await axiosClient.delete(`/buyers/${id}`);
  }
  
  export async function bulkDeleteBuyerDB(ids) {
    return await axiosClient.post(`/buyers/bulk_delete`, ids);
  }
  
  export async function editBuyerDB(value) {
    return await axiosClient.put(`/buyers/${value.id}`, value);
  }