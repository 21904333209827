import {
  ADD_TNA_PLAN_DETAIL,
  ADD_TNA_PLAN_DETAIL_ERROR,
  ADD_TNA_PLAN_DETAIL_OK,
  RETRIEVE_TNA_PLAN_DETAIL_DELETE,
  TNA_PLAN_DETAIL_DELETED_ERROR,
  TNA_PLAN_DETAIL_DELETED_OK,
} from "../../constants/modules/tnaPlanDetailsConstants";

const initialState = {
  tnaPdAdd: null,
  error: false,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RETRIEVE_TNA_PLAN_DETAIL_DELETE:
    case ADD_TNA_PLAN_DETAIL:
      return {
        ...state,
        loading: action.payload,
        tnaPlanDetail: action.tnaPlanDetail,
      };

    case TNA_PLAN_DETAIL_DELETED_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_TNA_PLAN_DETAIL_ERROR:
    case TNA_PLAN_DETAIL_DELETED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_TNA_PLAN_DETAIL_OK:
      return {
        ...state,
        loading: false,
        error: null,
        tnaPdAdd: action.payload,
      };

    default:
      return state;
  }
}
