import {
  ADD_SAMPLE_STATUS,
  ADD_SAMPLE_STATUS_ERROR,
  ADD_SAMPLE_STATUS_OK,
  BEGIN_EDIT_SAMPLE_STATUS,
  BEGIN_SAMPLE_STATUS_DOWNLOAD,
  RETRIEVE_SAMPLE_STATUS_BULK_DELETE,
  RETRIEVE_SAMPLE_STATUS_DELETE,
  RETRIEVE_SAMPLE_STATUS_EDIT,
  SAMPLE_STATUS_BULK_DELETED_ERROR,
  SAMPLE_STATUS_BULK_DELETED_OK,
  SAMPLE_STATUS_DELETED_ERROR,
  SAMPLE_STATUS_DELETED_OK,
  SAMPLE_STATUS_DOWNLOAD_ERROR,
  SAMPLE_STATUS_DOWNLOAD_OK,
  SAMPLE_STATUS_EDITED_ERROR,
  SAMPLE_STATUS_EDITED_OK,
} from "../../constants/modules/sampleStatusConstant";

/*-----------------------------------*/
/*             BULK ACTIONS          */
/*-----------------------------------*/
export const bulkDeleteSampleStatusAction = (item) => ({
  type: RETRIEVE_SAMPLE_STATUS_BULK_DELETE,
  payload: true,
  sampleStatus: item,
});

export const bulkDeleteSampleStatusOkAction = () => ({
  type: SAMPLE_STATUS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteSampleStatusErrorAction = (state) => ({
  type: SAMPLE_STATUS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadSampleStatusesAction = () => ({
  type: BEGIN_SAMPLE_STATUS_DOWNLOAD,
  payload: true,
});

export const downloadSampleStatusesOkAction = (items) => ({
  type: SAMPLE_STATUS_DOWNLOAD_OK,
  payload: items,
});

export const downloadSampleStatusesErrorAction = () => ({
  type: SAMPLE_STATUS_DOWNLOAD_ERROR,
  payload: true,
});

export const addSampleStatusAction = (item) => ({
  type: ADD_SAMPLE_STATUS,
  payload: true,
  sampleStatus: item,
});

export const addSampleStatusOkAction = () => ({
  type: ADD_SAMPLE_STATUS_OK,
  payload: true,
});

export const addSampleStatusErrorAction = (state) => ({
  type: ADD_SAMPLE_STATUS_ERROR,
  payload: state,
});

export const deleteSampleStatusAction = (id) => ({
  type: RETRIEVE_SAMPLE_STATUS_DELETE,
  payload: id,
});

export const deleteSampleStatusOkAction = () => ({
  type: SAMPLE_STATUS_DELETED_OK,
});

export const deleteSampleStatusErrorAction = () => ({
  type: SAMPLE_STATUS_DELETED_ERROR,
  payload: true,
});

export const retrieveSampleStatusAction = (item) => ({
  type: RETRIEVE_SAMPLE_STATUS_EDIT,
  payload: item,
});

export const editSampleStatusAction = (item) => ({
  type: BEGIN_EDIT_SAMPLE_STATUS,
  sampleStatus: item,
});

export const editSampleStatusOkAction = (item) => ({
  type: SAMPLE_STATUS_EDITED_OK,
  payload: item,
});

export const editSampleStatusErrorAction = () => ({
  type: SAMPLE_STATUS_EDITED_ERROR,
  payload: true,
});
