import {
  ADD_SHIPMENT_DETAILS,
  ADD_SHIPMENT_DETAILS_OK,
  ADD_SHIPMENT_DETAILS_ERROR,
  BEGIN_SHIPMENT_DETAILS_DOWNLOAD,
  SHIPMENT_DETAILS_DOWNLOAD_OK,
  SHIPMENT_DETAILS_DOWNLOAD_ERROR,
  RETRIEVE_SHIPMENT_DETAILS_DELETE,
  SHIPMENT_DETAILS_DELETED_OK,
  SHIPMENT_DETAILS_DELETED_ERROR,
  RETRIEVE_SHIPMENT_DETAILS_EDIT,
  BEGIN_EDIT_SHIPMENT_DETAILS,
  SHIPMENT_DETAILS_EDITED_OK,
  SHIPMENT_DETAILS_EDITED_ERROR,
  RETRIEVE_SHIPMENT_DETAILS_BULK_DELETE,
  SHIPMENT_DETAILS_BULK_DELETED_OK,
  SHIPMENT_DETAILS_BULK_DELETED_ERROR,
} from "../../constants/modules/shipmentDetailsConstants";

export const downloadShipmentDetailsAction = (item) => ({
  type: BEGIN_SHIPMENT_DETAILS_DOWNLOAD,
  payload: true,
  shipmentDetail: item,
});

export const downloadShipmentDetailsOkAction = (items) => ({
  type: SHIPMENT_DETAILS_DOWNLOAD_OK,
  payload: items,
});

export const downloadShipmentDetailsErrorAction = () => ({
  type: SHIPMENT_DETAILS_DOWNLOAD_ERROR,
  payload: true,
});

export const addShipmentDetailAction = (item) => ({
  type: ADD_SHIPMENT_DETAILS,
  payload: true,
  shipmentDetail: item,
});

export const addShipmentDetailOkAction = () => ({
  type: ADD_SHIPMENT_DETAILS_OK,
  payload: true,
});

export const addShipmentDetailErrorAction = (state) => ({
  type: ADD_SHIPMENT_DETAILS_ERROR,
  payload: state,
});

export const deleteShipmentDetailAction = (id) => ({
  type: RETRIEVE_SHIPMENT_DETAILS_DELETE,
  payload: id,
});

export const deleteShipmentDetailOkAction = () => ({
  type: SHIPMENT_DETAILS_DELETED_OK,
});

export const deleteShipmentDetailErrorAction = () => ({
  type: SHIPMENT_DETAILS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteShipmentDetailAction = (item) => ({
  type: RETRIEVE_SHIPMENT_DETAILS_BULK_DELETE,
  payload: true,
  shipmentDetail: item,
});

export const bulkDeleteShipmentDetailOkAction = () => ({
  type: SHIPMENT_DETAILS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteShipmentDetailErrorAction = (state) => ({
  type: SHIPMENT_DETAILS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveShipmentDetailAction = (item) => ({
  type: RETRIEVE_SHIPMENT_DETAILS_EDIT,
  payload: item,
});

export const editShipmentDetailAction = (item) => ({
  type: BEGIN_EDIT_SHIPMENT_DETAILS,
  shipmentDetail: item,
});

export const editShipmentDetailOkAction = (item) => ({
  type: SHIPMENT_DETAILS_EDITED_OK,
  payload: item,
});

export const editShipmentDetailErrorAction = () => ({
  type: SHIPMENT_DETAILS_EDITED_ERROR,
  payload: true,
});
