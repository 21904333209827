export const ADD_TT_ADVANCE_DETAILS = "ADD_TT_ADVANCE_DETAILS";
export const ADD_TT_ADVANCE_DETAILS_OK = "ADD_TT_ADVANCE_DETAILS_OK";
export const ADD_TT_ADVANCE_DETAILS_ERROR = "ADD_TT_ADVANCE_DETAILS_ERROR";

export const BEGIN_TT_ADVANCE_DETAILS_DOWNLOAD = "BEGIN_TT_ADVANCE_DETAILS_DOWNLOAD";
export const TT_ADVANCE_DETAILS_DOWNLOAD_OK = "TT_ADVANCE_DETAILS_DOWNLOAD_OK";
export const TT_ADVANCE_DETAILS_DOWNLOAD_ERROR = "TT_ADVANCE_DETAILS_DOWNLOAD_ERROR";

export const BEGIN_TT_ADVANCE_DETAIL_DOWNLOAD = "BEGIN_TT_ADVANCE_DETAIL_DOWNLOAD";
export const TT_ADVANCE_DETAIL_DOWNLOAD_OK = "TT_ADVANCE_DETAIL_DOWNLOAD_OK";
export const TT_ADVANCE_DETAIL_DOWNLOAD_ERROR = "TT_ADVANCE_DETAIL_DOWNLOAD_ERROR";

export const RETRIEVE_TT_ADVANCE_DETAILS_DELETE = "RETRIEVE_TT_ADVANCE_DETAILS_DELETE";
export const TT_ADVANCE_DETAILS_DELETED_OK = "TT_ADVANCE_DETAILS_DELETED_OK";
export const TT_ADVANCE_DETAILS_DELETED_ERROR = "TT_ADVANCE_DETAILS_DELETED_ERROR";

export const RETRIEVE_TT_ADVANCE_DETAILS_EDIT = "RETRIEVE_TT_ADVANCE_DETAILS_EDITED";
export const BEGIN_EDIT_TT_ADVANCE_DETAILS = "BEGIN_EDIT_TT_ADVANCE_DETAILS";
export const TT_ADVANCE_DETAILS_EDITED_OK = "TT_ADVANCE_DETAILS_EDITED_OK";
export const TT_ADVANCE_DETAILS_EDITED_ERROR = "TT_ADVANCE_DETAILS_EDITED_ERROR";

export const RESET = "RESET";
