import {
  ADD_BUYER_BRANDS,
  ADD_BUYER_BRANDS_OK,
  ADD_BUYER_BRANDS_ERROR,
  BEGIN_BUYER_BRANDS_DOWNLOAD,
  BUYER_BRANDS_DOWNLOAD_OK,
  BUYER_BRANDS_DOWNLOAD_ERROR,
  RETRIEVE_BUYER_BRANDS_DELETE,
  BUYER_BRANDS_DELETED_OK,
  BUYER_BRANDS_DELETED_ERROR,
  RETRIEVE_BUYER_BRANDS_EDIT,
  BEGIN_EDIT_BUYER_BRANDS,
  BUYER_BRANDS_EDITED_OK,
  BUYER_BRANDS_EDITED_ERROR,
  RETRIEVE_BUYER_BRANDS_BULK_DELETE,
  BUYER_BRANDS_BULK_DELETED_OK,
  BUYER_BRANDS_BULK_DELETED_ERROR,
  BEGIN_BUYER_BRANDS_BY_BUYER_DOWNLOAD,
  BUYER_BRANDS_BY_BUYER_DOWNLOAD_OK,
  BUYER_BRANDS_BY_BUYER_DOWNLOAD_ERROR,
} from "../../constants/modules/buyerBrandsConstants";

export const downloadBuyerBrandsAction = () => ({
  type: BEGIN_BUYER_BRANDS_DOWNLOAD,
  payload: true,
});

export const downloadBuyerBrandsOkAction = (items) => ({
  type: BUYER_BRANDS_DOWNLOAD_OK,
  payload: items,
});

export const downloadBuyerBrandsErrorAction = () => ({
  type: BUYER_BRANDS_DOWNLOAD_ERROR,
  payload: true,
});

//by buyer
export const downloadBuyerBrandsByBuyerAction = (item) => ({
  type: BEGIN_BUYER_BRANDS_BY_BUYER_DOWNLOAD,
  payload: true,
  buyerBrand: item,
});

export const downloadBuyerBrandsByBuyerOkAction = (items) => ({
  type: BUYER_BRANDS_BY_BUYER_DOWNLOAD_OK,
  payload: items,
});

export const downloadBuyerBrandsByBuyerErrorAction = () => ({
  type: BUYER_BRANDS_BY_BUYER_DOWNLOAD_ERROR,
  payload: true,
});

export const addBuyerBrandAction = (item) => ({
  type: ADD_BUYER_BRANDS,
  payload: true,
  buyerBrand: item,
});

export const addBuyerBrandOkAction = () => ({
  type: ADD_BUYER_BRANDS_OK,
  payload: true,
});

export const addBuyerBrandErrorAction = (state) => ({
  type: ADD_BUYER_BRANDS_ERROR,
  payload: state,
});

export const deleteBuyerBrandAction = (id) => ({
  type: RETRIEVE_BUYER_BRANDS_DELETE,
  payload: id,
});

export const deleteBuyerBrandOkAction = () => ({
  type: BUYER_BRANDS_DELETED_OK,
});

export const deleteBuyerBrandErrorAction = () => ({
  type: BUYER_BRANDS_DELETED_ERROR,
  payload: true,
});

export const bulkDeleteBuyerBrandAction = (item) => ({
  type: RETRIEVE_BUYER_BRANDS_BULK_DELETE,
  payload: true,
  buyerBrand: item,
});

export const bulkDeleteBuyerBrandOkAction = () => ({
  type: BUYER_BRANDS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteBuyerBrandErrorAction = (state) => ({
  type: BUYER_BRANDS_BULK_DELETED_ERROR,
  payload: state,
});

export const retrieveBuyerBrandAction = (item) => ({
  type: RETRIEVE_BUYER_BRANDS_EDIT,
  payload: item,
});

export const editBuyerBrandAction = (item) => ({
  type: BEGIN_EDIT_BUYER_BRANDS,
  buyerBrand: item,
});

export const editBuyerBrandOkAction = (item) => ({
  type: BUYER_BRANDS_EDITED_OK,
  payload: item,
});

export const editBuyerBrandErrorAction = () => ({
  type: BUYER_BRANDS_EDITED_ERROR,
  payload: true,
});
