import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  addOverseasOfficeDB,
  bulkDeleteOverseasOfficeDB,
  deleteOverseasOfficeDB,
  editOverseasOfficeDB,
  retrieveOverseasOfficesData
} from "../../../config/apiCalls";

import {
  addOverseasOfficeErrorAction,
  addOverseasOfficeOkAction,
  bulkDeleteOverseasOfficeErrorAction,
  bulkDeleteOverseasOfficeOkAction,
  deleteOverseasOfficeErrorAction,
  deleteOverseasOfficeOkAction,
  downloadOverseasOfficesErrorAction,
  downloadOverseasOfficesOkAction,
  editOverseasOfficeErrorAction,
  editOverseasOfficeOkAction
} from "../../actions/modules/overseasActions";

import {
  ADD_OVERSEAS_OFFICES,
  BEGIN_EDIT_OVERSEAS_OFFICES,
  BEGIN_OVERSEAS_OFFICES_DOWNLOAD,
  RETRIEVE_OVERSEAS_OFFICES_BULK_DELETE,
  RETRIEVE_OVERSEAS_OFFICES_DELETE
} from "../../constants/modules/overseasConstants";

// Retrieve products
// worker saga
export function* retrieveOverseas() {
  try {
    const response = yield call(retrieveOverseasOfficesData);

    if (response.status === 200) {
      yield put(downloadOverseasOfficesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadOverseasOfficesErrorAction());
  }
}

// Create new product
// worker saga
export function* addOverseas(action) {
  const overseas = action.overseas;

  try {
    const response = yield call(addOverseasOfficeDB, overseas);

    if (response.status === 201) {
      yield delay(500);

      yield put(addOverseasOfficeOkAction(overseas));
      toast.success("Overseas Office created successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addOverseasOfficeErrorAction(true));
    toast.error("Overseas Office created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteOverseas(action) {
  const id = action.payload;
  try {
    yield call(deleteOverseasOfficeDB, id);
    yield deleteOverseasOfficeOkAction();
    toast.success("Overseas Office deleted successfully", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteOverseasOfficeErrorAction();
  }
}

// Edit product
// worker saga
export function* editOverseas(action) {
  const oversea = action.overseas;

  try {
    yield call(editOverseasOfficeDB, oversea);
    yield editOverseasOfficeOkAction(oversea);
    // Alert
    toast.success("Overseas Office has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editOverseasOfficeErrorAction();
  }
}

// BULK SAGAS
export function* bulkOverseasOfficeDelete(action) {
  const oversea = action.overseas;

  try {
    const response = yield call(bulkDeleteOverseasOfficeDB, oversea);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteOverseasOfficeOkAction(oversea));
      toast.success("Bulk Overseas Office deleted successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteOverseasOfficeErrorAction(true));
    toast.error("Bulk Overseas Office deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchOverseasOfficesAsync() {
  yield takeLatest(BEGIN_OVERSEAS_OFFICES_DOWNLOAD, retrieveOverseas);
  yield takeLatest(ADD_OVERSEAS_OFFICES, addOverseas);
  yield takeLatest(RETRIEVE_OVERSEAS_OFFICES_DELETE, deleteOverseas);
  yield takeLatest(BEGIN_EDIT_OVERSEAS_OFFICES, editOverseas);
  yield takeLatest(
    RETRIEVE_OVERSEAS_OFFICES_BULK_DELETE,
    bulkOverseasOfficeDelete
  );
}
