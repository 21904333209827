import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*              TNA S API CALLS              */
/*-------------------------------------------------*/
export async function bulkDeleteTnaPlanHeaderDB(ids) {
  return await axiosClient.post(`/tna_plan_header/bulk_delete`, ids);
}

export async function retrieveTnaPlanHeadersData(value) {
  const postData = {
    p_id: value ? value : null,
  };

  return await axiosClient.post("/tna_plan_header/all", postData);
}

export async function retrieveTnaPlanHeaderByIdData(value) {
  return await axiosClient.post("/tna_plan_header/get", value);
}

export async function addTnaPlanHeaderDB(value) {
  console.log("valuessssssss", value);
  return await axiosClient.post("/tna_plan_header", value);
}

export async function deleteTnaPlanHeaderDB(id) {
  return await axiosClient.delete(`/tna_plan_header/${id}`);
}

export async function editTnaPlanHeaderDB(value) {
  return await axiosClient.put(`/tna_templates/${value.id}`, value);
}

//factory
export async function getFactoryDB(value) {
  const p_order_id = {
    p_order_id: value,
  };
  return await axiosClient.post("/tna_plan_header/get_factory", p_order_id);
}

//style
export async function getStyleDB(value) {
  return await axiosClient.post("/tna_plan_header/get_style", value);
}

//template
export async function getTemplateDB(value) {
  const p_order_id = {
    p_order_id: value,
  };
  return await axiosClient.post("/tna_plan_header/get_template", p_order_id);
}
