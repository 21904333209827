import {
  ADD_USER_LEVEL_PRIVILEGES,
  ADD_USER_LEVEL_PRIVILEGES_ERROR,
  ADD_USER_LEVEL_PRIVILEGES_OK,
  BEGIN_EDIT_USER_LEVEL_PRIVILEGES,
  BEGIN_USER_LEVEL_PRIVILEGES_DOWNLOAD,
  RETRIEVE_USER_LEVEL_PRIVILEGES_BULK_DELETE,
  RETRIEVE_USER_LEVEL_PRIVILEGES_DELETE,
  RETRIEVE_USER_LEVEL_PRIVILEGES_EDIT,
  USER_LEVEL_PRIVILEGES_BULK_DELETED_ERROR,
  USER_LEVEL_PRIVILEGES_BULK_DELETED_OK,
  USER_LEVEL_PRIVILEGES_DELETED_ERROR,
  USER_LEVEL_PRIVILEGES_DELETED_OK,
  USER_LEVEL_PRIVILEGES_DOWNLOAD_ERROR,
  USER_LEVEL_PRIVILEGES_DOWNLOAD_OK,
  USER_LEVEL_PRIVILEGES_EDITED_ERROR,
  USER_LEVEL_PRIVILEGES_EDITED_OK
} from "../../constants/modules/userLevelPrivilegesConstant";

  const initialState = {
    userLevelPrivileges: [],
    error: false,
    loading: false,
    deleteUserLevelPrivilege: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case BEGIN_USER_LEVEL_PRIVILEGES_DOWNLOAD:
      case RETRIEVE_USER_LEVEL_PRIVILEGES_BULK_DELETE:
      case ADD_USER_LEVEL_PRIVILEGES:
      case BEGIN_EDIT_USER_LEVEL_PRIVILEGES:
        return {
          ...state,
          loading: action.payload,
          userLevelPrivilege: action.userLevel,
        };
  
      case ADD_USER_LEVEL_PRIVILEGES_OK:
      case USER_LEVEL_PRIVILEGES_BULK_DELETED_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_USER_LEVEL_PRIVILEGES_ERROR:
      case USER_LEVEL_PRIVILEGES_DOWNLOAD_ERROR:
      case USER_LEVEL_PRIVILEGES_BULK_DELETED_ERROR:
      case USER_LEVEL_PRIVILEGES_DELETED_ERROR:
      case USER_LEVEL_PRIVILEGES_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case USER_LEVEL_PRIVILEGES_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          userLevelPrivileges: action.payload,
        };
  
      case RETRIEVE_USER_LEVEL_PRIVILEGES_DELETE:
        return {
          ...state,
          deleteUserLevelPrivilege: action.payload,
        };
  
      case USER_LEVEL_PRIVILEGES_DELETED_OK:
        return {
          ...state,
          userLevels: state.userLevels.data.filter((userLevel) => userLevel.id !== state.deleteUserLevel),
          deleteUserLevelPrivilege: null,
        };
  
      case RETRIEVE_USER_LEVEL_PRIVILEGES_EDIT:
        return {
          ...state,
          editUserLevelPrivilege: action.payload,
        };
  
      case USER_LEVEL_PRIVILEGES_EDITED_OK:
        return {
          ...state,
          editUserLevelPrivilege: null,
          userLevelPrivileges: state.userLevelPrivileges.data.map((userLevelPrivilege) =>
            userLevelPrivilege.id === action.payload.id ? (userLevelPrivilege = action.payload) : userLevelPrivilege
          ),
        };
  
      default:
        return state;
    }
  }
  