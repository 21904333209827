import {
  ADD_PRODUCT_TYPES, ADD_PRODUCT_TYPES_ERROR, ADD_PRODUCT_TYPES_OK, BEGIN_EDIT_PRODUCT_TYPES, BEGIN_PRODUCT_TYPES_DOWNLOAD, PRODUCT_TYPES_BULK_DELETED_ERROR, PRODUCT_TYPES_BULK_DELETED_OK, PRODUCT_TYPES_DELETED_ERROR, PRODUCT_TYPES_DELETED_OK, PRODUCT_TYPES_DOWNLOAD_ERROR, PRODUCT_TYPES_DOWNLOAD_OK, PRODUCT_TYPES_EDITED_ERROR, PRODUCT_TYPES_EDITED_OK, RETRIEVE_PRODUCT_TYPES_BULK_DELETE, RETRIEVE_PRODUCT_TYPES_DELETE, RETRIEVE_PRODUCT_TYPES_EDIT
} from "../../constants/modules/productTypeConstants.js";
  
  const initialState = {
    productTypes: [],
    error: false,
    loading: false,
    deleteProductType: null,
  };
  
  export default function (state = initialState, action) {
   
    switch (action.type) {
      case BEGIN_PRODUCT_TYPES_DOWNLOAD:
      case ADD_PRODUCT_TYPES:
      case RETRIEVE_PRODUCT_TYPES_BULK_DELETE:
      case BEGIN_EDIT_PRODUCT_TYPES:
        return {
          ...state,
          loading: action.payload,
          productType: action.productType,
        };
  
      case ADD_PRODUCT_TYPES_OK:
      case PRODUCT_TYPES_BULK_DELETED_OK:
        return {
          ...state,
          loading: false,
        };
  
      case ADD_PRODUCT_TYPES_ERROR:
      case PRODUCT_TYPES_DOWNLOAD_ERROR:
      case PRODUCT_TYPES_BULK_DELETED_ERROR:
      case PRODUCT_TYPES_DELETED_ERROR:
      case PRODUCT_TYPES_EDITED_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case PRODUCT_TYPES_DOWNLOAD_OK:
        return {
          ...state,
          loading: false,
          error: null,
          productTypes: action.payload,
        };
  
      case RETRIEVE_PRODUCT_TYPES_DELETE:
        return {
          ...state,
          deleteProductType: action.payload,
        };
  
      case PRODUCT_TYPES_DELETED_OK:
        return {
          ...state,
          productTypes: state.productTypes.data.filter((productType) => productType.id !== state.deleteProductType),
          deleteProductType: null,
        };
  
      case RETRIEVE_PRODUCT_TYPES_EDIT:
        return {
          ...state,
          editProductType: action.payload,
        };
  
      case PRODUCT_TYPES_EDITED_OK:
        return {
          ...state,
          editProductType: null,
          productTypes: state.productTypes.data.map((productType) =>
          productType.id === action.payload.id ? (productType = action.payload) : productType
          ),
        };
  
      default:
        return state;
    }
  }
  