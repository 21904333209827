import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*             SAMPLE STATUS API CALLS             */
/*-------------------------------------------------*/

export async function retrieveSampleCommentsData() {
    return await axiosClient.get("/sample_comments");
  }
  
  export async function addSampleCommentDB(value) {
  
  console.log("country values", value);
  
  var bodyFormData = new FormData();
  
    bodyFormData.append("country_name", value.country_name);
    bodyFormData.append("flag", value.flag);
    bodyFormData.append("short_name", value.short_name);
    bodyFormData.append("created_by", value.created_by);
    
  
    return await axiosClient.post("/sample_comments", value);
  }
  
  export async function deleteSampleCommentDB(id) {
    return await axiosClient.delete(`/sample_comments/${id}`);
  }
  
  export async function bulkDeleteSampleCommentDB(ids) {
    console.log("country Ids", ids);
    return await axiosClient.post(`/sample_comments/bulk_delete`, ids);
  }
  
  export async function editSampleCommentDB(value) {
   
    console.log("edit country data33", value);
  
    var bodyFormData = new FormData();
  
    bodyFormData.append("country_name", value.country_name);
    bodyFormData.append("flag", value.flag);
    bodyFormData.append("short_name", value.short_name);
    bodyFormData.append("created_by", value.created_by);
    bodyFormData.append("created_date", value.created_date);
    bodyFormData.append("modified_by", value.modified_by);
  
    return await axiosClient.put(`/sample_comments/${value.id}`, value);
  }
  