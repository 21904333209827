export const ADD_FACTORY_ORDERS = "ADD_FACTORY_ORDERS";
export const ADD_FACTORY_ORDERS_OK = "ADD_FACTORY_ORDERS_OK";
export const ADD_FACTORY_ORDERS_ERROR = "ADD_FACTORY_ORDERS_ERROR";

export const BEGIN_FACTORY_ORDERS_DOWNLOAD = "BEGIN_FACTORY_ORDERS_DOWNLOAD";
export const FACTORY_ORDERS_DOWNLOAD_OK = "FACTORY_ORDERS_DOWNLOAD_OK";
export const FACTORY_ORDERS_DOWNLOAD_ERROR = "FACTORY_ORDERS_DOWNLOAD_ERROR";

export const BEGIN_FACTORY_BY_ORDER_DOWNLOAD =
  "BEGIN_FACTORY_BY_ORDER_DOWNLOAD";
export const FACTORY_BY_ORDER_DOWNLOAD_OK = "FACTORY_BY_ORDER_DOWNLOAD_OK";
export const FACTORY_BY_ORDER_DOWNLOAD_ERROR =
  "FACTORY_BY_ORDER_DOWNLOAD_ERROR";

export const RETRIEVE_FACTORY_ORDERS_EDIT = "RETRIEVE_FACTORY_ORDERS_EDITED";
export const BEGIN_EDIT_FACTORY_ORDERS = "BEGIN_EDIT_FACTORY_ORDERS";
export const FACTORY_ORDERS_EDITED_OK = "FACTORY_ORDERS_EDITED_OK";
export const FACTORY_ORDERS_EDITED_ERROR = "FACTORY_ORDERS_EDITED_ERROR";

export const RETRIEVE_FACTORY_ORDERS_DELETE = "RETRIEVE_FACTORY_ORDERS_DELETE";
export const FACTORY_ORDERS_DELETED_OK = "FACTORY_ORDERS_DELETED_OK";
export const FACTORY_ORDERS_DELETED_ERROR = "FACTORY_ORDERS_DELETED_ERROR";

export const RETRIEVE_FACTORY_ORDERS_BULK_DELETE =
  "RETRIEVE_FACTORY_ORDERS_BULK_DELETE";
export const FACTORY_ORDERS_BULK_DELETED_OK = "FACTORY_ORDERS_BULK_DELETED_OK";
export const FACTORY_ORDERS_BULK_DELETED_ERROR =
  "FACTORY_ORDERS_BULK_DELETED_ERROR";
