import {
  ADD_TNA_TEMPLATES,
  ADD_TNA_TEMPLATES_ERROR,
  ADD_TNA_TEMPLATES_OK,
  BEGIN_EDIT_TNA_TEMPLATES,
  BEGIN_TNA_TEMPLATES_DOWNLOAD,
  RETRIEVE_TNA_TEMPLATES_BULK_DELETE,
  RETRIEVE_TNA_TEMPLATES_DELETE,
  RETRIEVE_TNA_TEMPLATES_EDIT,
  TNA_TEMPLATES_BULK_DELETED_ERROR,
  TNA_TEMPLATES_BULK_DELETED_OK,
  TNA_TEMPLATES_DELETED_ERROR,
  TNA_TEMPLATES_DELETED_OK,
  TNA_TEMPLATES_DOWNLOAD_ERROR,
  TNA_TEMPLATES_DOWNLOAD_OK,
  TNA_TEMPLATES_EDITED_ERROR,
  TNA_TEMPLATES_EDITED_OK,
  BEGIN_TNA_TEMPLATE_DOWNLOAD,
  TNA_TEMPLATE_DOWNLOAD_OK,
  TNA_TEMPLATE_DOWNLOAD_ERROR,
  RESET,
} from "../../constants/modules/tnaTemplatesConstants";

export const bulkDeleteTnaTemplateActionAction = (item) => ({
  type: RETRIEVE_TNA_TEMPLATES_BULK_DELETE,
  payload: true,
  tnaTemplate: item,
});

export const bulkDeleteTnaTemplateOkAction = () => ({
  type: TNA_TEMPLATES_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteTnaTemplateErrorAction = (state) => ({
  type: TNA_TEMPLATES_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadTnaTemplatesAction = (item) => ({
  type: BEGIN_TNA_TEMPLATES_DOWNLOAD,
  payload: true,
  tnaTemplate: item,
});

export const downloadTnaTemplatesOkAction = (items) => ({
  type: TNA_TEMPLATES_DOWNLOAD_OK,
  payload: items,
});

export const downloadTnaTemplatesErrorAction = () => ({
  type: TNA_TEMPLATES_DOWNLOAD_ERROR,
  payload: true,
});

export const downloadTnaTemplateAction = (item) => ({
  type: BEGIN_TNA_TEMPLATE_DOWNLOAD,
  payload: true,
  tnaTemplate: item,
});

export const downloadTnaTemplateOkAction = (items) => ({
  type: TNA_TEMPLATE_DOWNLOAD_OK,
  payload: items,
});

export const downloadTnaTemplateErrorAction = () => ({
  type: TNA_TEMPLATE_DOWNLOAD_ERROR,
  payload: true,
});

export const addTnaTemplateAction = (item) => ({
  type: ADD_TNA_TEMPLATES,
  payload: true,
  tnaTemplate: item,
});

export const addTnaTemplateOkAction = (items) => ({
  type: ADD_TNA_TEMPLATES_OK,
  payload: items,
});

export const addTnaTemplateErrorAction = (state) => ({
  type: ADD_TNA_TEMPLATES_ERROR,
  payload: state,
});

export const deleteTnaTemplateAction = (id) => ({
  type: RETRIEVE_TNA_TEMPLATES_DELETE,
  payload: id,
});

export const deleteTnaTemplateOkAction = () => ({
  type: TNA_TEMPLATES_DELETED_OK,
});

export const deleteTnaTemplateErrorAction = () => ({
  type: TNA_TEMPLATES_DELETED_ERROR,
  payload: true,
});

export const retrieveTnaTemplateAction = (item) => ({
  type: RETRIEVE_TNA_TEMPLATES_EDIT,
  payload: item,
});

export const editTnaTemplateAction = (item) => ({
  type: BEGIN_EDIT_TNA_TEMPLATES,
  tnaTemplate: item,
});

export const editTnaTemplateOkAction = (item) => ({
  type: TNA_TEMPLATES_EDITED_OK,
  payload: item,
});

export const editTnaTemplateErrorAction = () => ({
  type: TNA_TEMPLATES_EDITED_ERROR,
  payload: true,
});

export const TnaTemplateReset = () => ({
  type: RESET,
  payload: true,
});
