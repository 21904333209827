import {
  ADD_TEAMS,
  ADD_TEAMS_ERROR,
  ADD_TEAMS_OK,
  BEGIN_EDIT_TEAMS,
  BEGIN_TEAMS_DOWNLOAD,
  RETRIEVE_TEAMS_BULK_DELETE,
  RETRIEVE_TEAMS_DELETE,
  RETRIEVE_TEAMS_EDIT,
  TEAMS_BULK_DELETED_ERROR,
  TEAMS_BULK_DELETED_OK,
  TEAMS_DELETED_ERROR,
  TEAMS_DELETED_OK,
  TEAMS_DOWNLOAD_ERROR,
  TEAMS_DOWNLOAD_OK,
  TEAMS_EDITED_ERROR,
  TEAMS_EDITED_OK,
  BEGIN_TEAMS_BY_BUYER_DOWNLOAD,
  TEAMS_BY_BUYER_DOWNLOAD_OK,
  TEAMS_BY_BUYER_DOWNLOAD_ERROR,
} from "../../constants/modules/teamConstants";

export const bulkDeleteTeamAction = (item) => ({
  type: RETRIEVE_TEAMS_BULK_DELETE,
  payload: true,
  team: item,
});

export const bulkDeleteTeamOkAction = () => ({
  type: TEAMS_BULK_DELETED_OK,
  payload: true,
});

export const bulkDeleteTeamErrorAction = (state) => ({
  type: TEAMS_BULK_DELETED_ERROR,
  payload: state,
});

export const downloadTeamsAction = () => ({
  type: BEGIN_TEAMS_DOWNLOAD,
  payload: true,
});

export const downloadTeamsOkAction = (items) => ({
  type: TEAMS_DOWNLOAD_OK,
  payload: items,
});

export const downloadTeamsErrorAction = () => ({
  type: TEAMS_DOWNLOAD_ERROR,
  payload: true,
});

//by_buyer
export const downloadTeamsByBuyerAction = (item) => ({
  type: BEGIN_TEAMS_BY_BUYER_DOWNLOAD,
  payload: true,
  team: item,
});

export const downloadTeamsByBuyerOkAction = (items) => ({
  type: TEAMS_BY_BUYER_DOWNLOAD_OK,
  payload: items,
});

export const downloadTeamsByBuyerErrorAction = () => ({
  type: TEAMS_BY_BUYER_DOWNLOAD_ERROR,
  payload: true,
});

export const addTeamAction = (item) => ({
  type: ADD_TEAMS,
  payload: true,
  team: item,
});

export const addTeamOkAction = () => ({
  type: ADD_TEAMS_OK,
  payload: true,
});

export const addTeamErrorAction = (state) => ({
  type: ADD_TEAMS_ERROR,
  payload: state,
});

export const deleteTeamAction = (id) => ({
  type: RETRIEVE_TEAMS_DELETE,
  payload: id,
});

export const deleteTeamOkAction = () => ({
  type: TEAMS_DELETED_OK,
});

export const deleteTeamErrorAction = () => ({
  type: TEAMS_DELETED_ERROR,
  payload: true,
});

export const retrieveTeamAction = (item) => ({
  type: RETRIEVE_TEAMS_EDIT,
  payload: item,
});

export const editTeamAction = (item) => ({
  type: BEGIN_EDIT_TEAMS,
  team: item,
});

export const editTeamOkAction = (item) => ({
  type: TEAMS_EDITED_OK,
  payload: item,
});

export const editTeamErrorAction = () => ({
  type: TEAMS_EDITED_ERROR,
  payload: true,
});
